import React from 'react';
import PropTypes from 'prop-types';
import { useReactTable, getCoreRowModel } from '../../../Utils/ReactTable';

import CustomReportTable from '../../TableReport/CustomReportTable';
import PeriodInfo from './PeriodInfo';

const ReportPeriodTable = ({
  data = [],
  columns,
  scheduleName = '',
  options = {},
}) => {
  const defaultOptions = {
    onChangePageSize: () => null,
    onChangePage: () => null,
    onCleanFilter: () => null,
    onDownloadReport: () => null,
    totalElements: 0,
    totalPages: 1,
    currentPage: 1,
    ...options,
  };

  const handleChangePageSize = pageSize => {
    defaultOptions.onChangePageSize(pageSize);
  };

  const handleChangePage = page => {
    defaultOptions.onChangePage(page);
  };

  const handleOnCleanFilters = () => {
    defaultOptions.onCleanFilter();
  };

  const handleOnDownloadReport = () => {
    defaultOptions.onDownloadReport();
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <CustomReportTable
      table={table}
      period={
        <PeriodInfo
          noResults={defaultOptions.totalElements}
          shedule={scheduleName}
        />
      }
      reportName='Descargar reporte'
      onDownloadReport={handleOnDownloadReport}
      options={{
        sticky: 'order_id',
        onCleanFilter: handleOnCleanFilters,
        pagination: {
          className: 'pagination-section',
          onChangePageSize: handleChangePageSize,
          onChangePage: handleChangePage,
          totalElements: defaultOptions.totalElements,
          totalPages: defaultOptions.totalPages,
          currentPage: defaultOptions.currentPage,
        },
      }}
    />
  );
};

ReportPeriodTable.propTypes = {
  periodInfo: PropTypes.any,
  data: PropTypes.array,
  scheduleName: PropTypes.string,
  columns: PropTypes.any,
  options: PropTypes.shape({
    onCleanFilter: PropTypes.func,
    onChangePageSize: PropTypes.func,
    onChangePage: PropTypes.func,
    totalElements: PropTypes.number,
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
  }),
};

export default ReportPeriodTable;
