import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './baseFilter.scss';
import { Space, Button, Tag, Table, DateInput } from '../../ds';
import { TdResponsive, TrCollapsable } from '../ResponsiveTable';
import { ReactComponent as FilterIcon } from './filter-icon.svg';
import Checkbox from '../Checkbox';
import { formatDateForInput, getDateString } from '../../Utils/formats';

export const BaseFilterInputsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    interface: PropTypes.oneOf(['select', 'date']).isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any.isRequired,
        label: PropTypes.string.isRequired,
        checked: PropTypes.bool.isRequired,
      })
    ),
    icon: PropTypes.node,
    onChange: PropTypes.func,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    setStartDate: PropTypes.func,
    setEndDate: PropTypes.func,
  })
);

export const BaseFilterPropTypes = {
  filterInputs: BaseFilterInputsPropTypes.isRequired,
  onApply: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  title: PropTypes.string,
  submitLabel: PropTypes.string,
  resetLabel: PropTypes.string,
};

const DateFilter = props => {
  const [today] = useState(formatDateForInput(new Date()));
  return (
    <Space direction='vertical'>
      <Space direction='vertical' align='start' className='k-ds-width-full'>
        <div className='k-ds-m-top-sp-04 k-ds-body-01'>Fecha de inicio</div>
        <DateInput
          className='k-ds-width-full'
          value={props.startDate}
          max={props.endDate === '' ? today : props.endDate}
          onChange={e => props.setStartDate(e.target.value)}
        />
      </Space>
      <Space
        direction='vertical'
        align='start'
        className='k-ds-m-right-sp-04 k-ds-width-full'
      >
        <div className='k-ds-m-top-sp-04 k-ds-body-01'>Fecha de fin</div>
        <DateInput
          className='k-ds-width-full'
          value={props.endDate}
          min={props.startDate}
          max={today}
          onChange={e => props.setEndDate(e.target.value)}
        />
      </Space>
    </Space>
  );
};

DateFilter.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
};

const SelectFilter = props => {
  return (
    <Space direction='vertical' className='options'>
      {props.options.map((option, index) => (
        <Space
          key={index}
          className='checkbox-option'
          onClick={e => {
            props.onChange(index);
            e.preventDefault();
          }}
        >
          <Checkbox
            defaultChecked={option.checked}
            onChange={e => e.preventDefault()}
            filled={true}
          ></Checkbox>{' '}
          <span className='option'>{option.label}</span>
        </Space>
      ))}
    </Space>
  );
};

SelectFilter.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.any,
  options: PropTypes.array,
};

const inputTypes = {
  date: attributes => <DateFilter {...attributes} />,
  select: attributes => <SelectFilter {...attributes} />,
};

const BaseFilter = props => {
  const [expandedItem, setExpandedItem] = useState('');
  const selectFilters = props.filterInputs.filter(
    filterInput => filterInput.interface === 'select'
  );
  const dateFilters = props.filterInputs.filter(
    filterInput => filterInput.interface === 'date'
  );
  const selectActiveOptions = selectFilters
    .filter(
      filterInput =>
        filterInput.options &&
        filterInput.options.some(option => option.checked)
    )
    .map(filterInput => filterInput.options.filter(option => option.checked));
  const dateActiveOptions = dateFilters.filter(
    filterInput => filterInput.startDate && filterInput.endDate
  );

  function rowStyle(index, isExpanded) {
    let className = isExpanded ? 'expanded-row ' : '';
    if (index === 0) className = className + 'first-row';
    return className;
  }

  function filterInputActiveFilters(filterInput) {
    if (filterInput.interface === 'select')
      return filterInput.options.filter(option => option.checked).length;
    else if (filterInput.startDate && filterInput.endDate) return 1;
    else return 0;
  }

  return (
    <Space className='base-filter' direction='vertical'>
      <div className='k-ds-heading-04 k-ds-m-bottom-sp-08'>
        {props.title || 'Filtrar por:'}
      </div>
      <Space
        direction='vertical'
        jusify='space-between'
        className='k-ds-height-full main-filter-container'
      >
        <Space direction='vertical' className='info-container'>
          {(selectActiveOptions.length > 0 || dateActiveOptions.length > 0) && (
            <Space className='k-ds-m-bottom-sp-08 tags-container'>
              {selectActiveOptions.map(options =>
                options.map((option, index) => (
                  <Tag
                    key={index}
                    color='orange-outline'
                    className='filter-tag'
                  >
                    {option.label}
                  </Tag>
                ))
              )}
              {dateActiveOptions.map((creationDate, index) => (
                <Tag key={index} color='orange-outline' className='filter-tag'>
                  {getDateString(creationDate.startDate)} -{' '}
                  {getDateString(creationDate.endDate)}
                </Tag>
              ))}
            </Space>
          )}
          <Space direction='vertical' className='filters-container'>
            <Table type='b2b' className='k-ds-width-full filter-table'>
              <tbody>
                {props.filterInputs.map((filterInput, index) => {
                  const isExpanded = expandedItem === index;
                  return (
                    <React.Fragment key={index}>
                      <tr
                        aria-expanded={isExpanded}
                        className={rowStyle(index, isExpanded)}
                      >
                        <TdResponsive
                          onClick={() =>
                            setExpandedItem(isExpanded ? '' : index)
                          }
                          className='td-name'
                        >
                          <Space
                            direction='vertical'
                            className='k-ds-width-full'
                          >
                            <Space
                              justify='space-between'
                              direction='vertical'
                              className='k-ds-width-full'
                            >
                              <Space
                                direction='horizontal'
                                className='name-container'
                              >
                                {filterInput.icon || <FilterIcon />}
                                <Space
                                  direction='vertical'
                                  className='k-ds-m-left-sp-04 k-ds-width-full filter-name-container'
                                >
                                  <Space
                                    className='k-ds-m-left-sp-04 k-ds-body-01 filter-name'
                                    justify='space-between'
                                  >
                                    {filterInput.label}{' '}
                                    {filterInputActiveFilters(filterInput) >
                                      0 &&
                                      !isExpanded && (
                                        <Tag
                                          key={index}
                                          color='orange-outline'
                                          className='filter-tag'
                                        >
                                          {filterInputActiveFilters(
                                            filterInput
                                          )}
                                        </Tag>
                                      )}
                                  </Space>
                                </Space>
                              </Space>
                            </Space>
                          </Space>
                        </TdResponsive>
                        <TdResponsive
                          type='toggle'
                          isCollapsed={!isExpanded}
                          className='toggle-chevron'
                          onClick={() =>
                            setExpandedItem(isExpanded ? '' : index)
                          }
                          devices='desktop tablet mobile'
                        ></TdResponsive>
                      </tr>
                      <TrCollapsable
                        isCollapsed={!isExpanded}
                        devices='desktop tablet mobile'
                      >
                        <td colSpan='2' width='100%' className='td-inputs'>
                          {inputTypes[filterInput.interface]({
                            ...filterInput,
                            key: index,
                          })}
                        </td>
                      </TrCollapsable>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </Table>
          </Space>
        </Space>
        <Space className='buttons-container k-ds-heading-01'>
          <Button className='filter-button' onClick={props.onApply}>
            {props.submitLabel || 'Aplicar'}
          </Button>
          <Button
            type='link'
            className='filter-button reset'
            onClick={props.onReset}
          >
            {props.resetLabel || 'Restablecer'}
          </Button>
        </Space>
      </Space>
    </Space>
  );
};

BaseFilter.propTypes = BaseFilterPropTypes;

export default BaseFilter;
