const prefixes = {
  'color': '--k-ds-color-',
  'font-family': '--k-ds-font-family-',
  'font-size': '--k-ds-font-size-',
  'font-weight': '--k-ds-font-weight-',
  'letter-spacing': '--k-ds-letter-spacing-',
  'line-height': '--k-ds-line-height-',
  'effect': '--k-ds-',
  'spacing': '--k-ds-',
  'color-token': '--k-ds-color-',
  'effect-token': '--k-ds-',
};

const primitives = {
  /* Colors */
  /* - Base Colors - */
  /* -- Extended Palette -- */
  'color': {
    'purple-900': '#4A148C',
    'purple-700': '#7B1FA2',
    'purple-500': '#9C27B0',
    'purple-300': '#E1BEE7',
    'purple-100': '#F3E5F5',

    'deep-purple-900': '#5333D3',
    'deep-purple-700': '#6554E7',
    'deep-purple-500': '#7D6CFF',
    'deep-purple-300': '#A498FF',
    'deep-purple-100': '#CBC4FF',

    'indigo-900': '#1A237E',
    'indigo-700': '#303F9F',
    'indigo-500': '#3F51B5',
    'indigo-300': '#C5CAE9',
    'indigo-100': '#E8EAF6',

    'blue-900': '#0D47A1',
    'blue-700': '#1976D2',
    'blue-500': '#2196F3',
    'blue-300': '#BBDEFB',
    'blue-100': '#E3F2FD',

    'cyan-900': '#006064',
    'cyan-700': '#0097A7',
    'cyan-500': '#00BCD4',
    'cyan-300': '#B2EBF2',
    'cyan-100': '#E0F7FA',

    'teal-900': '#004D40',
    'teal-700': '#00796B',
    'teal-500': '#009688',
    'teal-300': '#B2DFDB',
    'teal-100': '#E0F2F1',

    'green-900': '#1B5E20',
    'green-700': '#388E3C',
    'green-500': '#4CAF50',
    'green-300': '#C8E6C9',
    'green-100': '#E8F5E9',

    'light-green-900': '#33691E',
    'light-green-700': '#689F38',
    'light-green-500': '#8BC34A',
    'light-green-300': '#DCEDC8',
    'light-green-100': '#F1F8E9',

    'lime-900': '#827717',
    'lime-700': '#AFB42B',
    'lime-500': '#CDDC39',
    'lime-300': '#F0F4C3',
    'lime-100': '#F9FBE7',

    'yellow-900': '#F57F17',
    'yellow-700': '#FBC02D',
    'yellow-500': '#FFEB3B',
    'yellow-300': '#FFF9C4',
    'yellow-100': '#FFFDE7',

    'amber-900': '#FF6F00',
    'amber-700': '#FFA000',
    'amber-500': '#FFC107',
    'amber-300': '#FFECB3',
    'amber-100': '#FFF8E1',

    'orange-900': '#E65100',
    'orange-700': '#F57C00',
    'orange-500': '#FF9800',
    'orange-300': '#FFE0B2',
    'orange-100': '#FFF3E0',

    'red-900': '#A11111',
    'red-700': '#CF1616',
    'red-500': '#E93030',
    'red-300': '#F8BABA',
    'red-100': '#FDE8E8',

    'grey-900': '#18223D',
    'grey-700': '#656B7C',
    'grey-500': '#B4BACA',
    'grey-300': '#EBEEF7',
    'grey-100': '#F5F6FB',
    'grey-0': '#FFFFFF',

    'white': '#FFFFFF',
    'transparent': 'transparent',
  },

  /* Typography */
  /* -- Font Family -- */
  'font-family': {
    'font-primary': 'DM Sans',
    'font-secondary': 'Inter',
  },

  /* -- Font Size -- */
  'font-size': {
    'text-xs': '0.75rem',
    'text-sm': '0.875rem',
    'text-base': '1rem',
    'text-lg': '1.125rem',
    'text-xl': '1.25rem',
    'text-2xl': '1.5rem',
    'text-3xl': '1.75rem',
    'text-4xl': '2rem',
    'text-5xl': '2.25rem',
  },

  /* -- Font Weight -- */
  'font-weight': {
    'font-hairline': '100',
    'font-thin': '200',
    'font-light': '300',
    'font-normal': '400',
    'font-medium': '500',
    'font-semibold': '600',
    'font-bold': '700',
  },

  /* -- Letter Spacing -- */
  'letter-spacing': {
    'tracking-tighter': '-0.04em',
    'tracking-tight': '-0.02em',
    'tracking-normal': '0',
    'tracking-wide': '0.02em',
    'tracking-wider': '0.04em',
    'tracking-widest': '0.06em',
  },

  /* -- Line Height -- */
  'line-height': {
    'none': '1',
    'tight': '1.1',
    'snug': '1.3',
    'normal': '1.4',
    'relaxed': '1.5',
    'loose': '2',
  },

  /* Effects */
  /* -- Shadows -- */
  'effect': {
    'shadow-base': '0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)',
    'shadow-md': '0px 2px 7px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.05)',
    'shadow-lg': '0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05)',
    'shadow-lg-negative': '0px -10px 15px rgba(0, 0, 0, 0.1), 0px -4px 6px rgba(0, 0, 0, 0.05)',
    'shadow-xl': '0px 20px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04)',
    'shadow-2xl': '0px 25px 50px rgba(0, 0, 0, 0.25)',
    'shadow-inner': 'inset 2px 2px 2px rgba(11, 11, 13, 0.1)',
  },

  /* Spacing */
  'spacing': {
    /* - Spacing Scale - */
    'spacing-01': '0.125rem',
    'spacing-02': '0.25rem',
    'spacing-03': '0.5rem',
    'spacing-04': '0.75rem',
    'spacing-05': '1rem',
    'spacing-06': '1.5rem',
    'spacing-07': '2rem',
    'spacing-08': '2.5rem',
    'spacing-09': '3rem',

    /* - Layout Scale - */
    'layout-01': '1rem',
    'layout-02': '1.5rem',
    'layout-03': '2rem',
    'layout-04': '3rem',
    'layout-05': '4rem',
    'layout-06': '6rem',
    'layout-07': '10rem',
  },
};

/* - Color tokens - */

/* -- Light Theme -- */

const tokens = {
  'color': {
    'ui-background': 'white',
    /*  Core Color Tokens  */
    'background-default': 'white',
    'interactive-primary': 'blue-500',
    'interactive-primary-bg': 'deep-purple-900',
    'interactive-primary-light-bg': 'deep-purple-100',
    'interactive-secondary': 'grey-700',
    'interactive-alternate': 'grey-500',
    'background-primary': 'grey-100',
    'background-secondary': 'white',
    'background-alternate': 'grey-300',
    'border': 'grey-300',
    'border-alternate': 'grey-500',
    'text-primary': 'grey-900',
    'text-secondary': 'grey-700',
    'text-placeholder': 'grey-500',
    'text-alternate': 'white',
    'text-help': 'grey-700',
    'error': 'red-500',
    'text-link-primary': 'blue-500',
    'text-link-alternate': 'blue-300',
    'icon-primary': 'grey-900',
    'field-primary': 'transparent',
    'inverse-primary': 'white',
    'inverse-background': 'grey-900',
    'successful': 'light-green-500',
    'warning': 'yellow-700',

    /*  Interactive Color Tokens  */
    'focus': 'deep-purple-500',
    'focus-inverse': 'deep-purple-500',
    'interactive-primary-hover': 'deep-purple-700',
    'interactive-primary-active': 'deep-purple-700',
    'interactive-disabled': 'deep-purple-100',
    'disabled': 'grey-100',
  },

  'effect': {
    'shadow-button-default': 'shadow-base',
    'shadow-button-hover': 'shadow-lg',
    'shadow-bottom-tab-bar': 'shadow-lg-negative',
    'shadow-car': 'shadow-base',
  },
};

const getPrefix = (category) => {
  const prefix = prefixes[category];
  if (!prefix) return '';
  return prefix;
};

/* Primitives */
const getPrimitive = (category, property) => {
  const categoryObject = primitives[category];
  if (!categoryObject) return '';
  const value = categoryObject[property];
  if (!value) return '';
  return value;
};

const getSpacing = (property) => {
  const category = 'spacing';
  return getPrimitive(category, property);
};

/* Tokens */
const getColorToken = (property) => {
  const category = 'color';
  return getPrimitive('color', tokens[category][property]);
};

const getEffectToken = (property) => {
  const category = 'effect';
  return getPrimitive('effect', tokens[category][property]);
};

/* Primitives */
// eslint-disable-next-line
const setPrimitiveWithFallback = (prefix, category, value) => {
  return `${prefix}${getPrimitive(category, value)};${prefix}var(${getPrefix(category)}${value});`;
};

const setColorWithFallback = (prefix, value) => {
  const category = 'color';
  return setPrimitiveWithFallback(prefix, category, value);
};

const setFontFamilyWithFallback = (value) => {
  const prefix = 'font-family: ';
  const category = 'font-family';
  return setPrimitiveWithFallback(prefix, category, value);
};

const setFontSizeWithFallback = (value) => {
  const prefix = 'font-size: ';
  const category = 'font-size';
  return setPrimitiveWithFallback(prefix, category, value);
};

const setFontWeightWithFallback = (value) => {
  const prefix = 'font-weight: ';
  const category = 'font-weight';
  return setPrimitiveWithFallback(prefix, category, value);
};

const setLetterSpacingWithFallback = (value) => {
  const prefix = 'letter-spacing: ';
  const category = 'letter-spacing';
  return setPrimitiveWithFallback(prefix, category, value);
};

const setLineHeightWithFallback = (value) => {
  const prefix = 'line-height: ';
  const category = 'line-height';
  return setPrimitiveWithFallback(prefix, category, value);
};

const setTypographyWithFallback = (properties) => {
  let cssProperties = '';
  const fontFamily = properties['font-family'];
  if (fontFamily) {
    cssProperties = `${setFontFamilyWithFallback(fontFamily)};`;
  }
  const fontSize = properties['font-size'];
  if (fontSize) {
    cssProperties = `${cssProperties}${setFontSizeWithFallback(fontSize)};`;
  }
  const fontWeight = properties['font-weight'];
  if (fontWeight) {
    cssProperties = `${cssProperties}${setFontWeightWithFallback(fontWeight)};`;
  }
  const letterSpacing = properties['letter-spacing'];
  if (letterSpacing) {
    cssProperties = `${cssProperties}${setLetterSpacingWithFallback(letterSpacing)};`;
  }
  const lineHeight = properties['line-height'];
  if (lineHeight) {
    cssProperties = `${cssProperties}${setLineHeightWithFallback(lineHeight)};`;
  }
  return cssProperties;
};

const setBoxShadowWithFallback = (value) => {
  const prefix = 'box-shadow: ';
  const category = 'effect';
  return setPrimitiveWithFallback(prefix, category, value);
};

const setSpacingWithFallback = (prefix, top, right, bottom, left) => {
  const category = 'spacing';
  let cssPropertyVar = prefix;
  let cssPropertyFallback = prefix;

  if (top) {
    cssPropertyVar = `${cssPropertyVar}var(${getPrefix(category)}${top})`;
    cssPropertyFallback = `${cssPropertyFallback}${getSpacing(top)}`;
  }

  if (top === 0) {
    cssPropertyVar = `${cssPropertyVar} 0`;
    cssPropertyFallback = `${cssPropertyFallback} 0`;
  }

  if (right) {
    cssPropertyVar = `${cssPropertyVar} var(${getPrefix(category)}${right})`;
    cssPropertyFallback = `${cssPropertyFallback} ${getSpacing(right)}`;
  }

  if (right === 0) {
    cssPropertyVar = `${cssPropertyVar} 0`;
    cssPropertyFallback = `${cssPropertyFallback} 0`;
  }

  if (bottom) {
    cssPropertyVar = `${cssPropertyVar} var(${getPrefix(category)}${bottom})`;
    cssPropertyFallback = `${cssPropertyFallback} ${getSpacing(bottom)}`;
  }

  if (bottom === 0) {
    cssPropertyVar = `${cssPropertyVar} 0`;
    cssPropertyFallback = `${cssPropertyFallback} 0`;
  }

  if (left) {
    cssPropertyVar = `${cssPropertyVar} var(${getPrefix(category)}${left})`;
    cssPropertyFallback = `${cssPropertyFallback} ${getSpacing(left)}`;
  }

  if (left === 0) {
    cssPropertyVar = `${cssPropertyVar} 0`;
    cssPropertyFallback = `${cssPropertyFallback} 0`;
  }

  return `${cssPropertyFallback};${cssPropertyVar};`;
};

/* Tokens */
// eslint-disable-next-line
const setColorTokenWithFallback = (prefix, value) => {
  return `${prefix}${getColorToken(value)};${prefix}var(${getPrefix('color-token')}${value});`;
};

const setBoxShadowTokenWithFallback = (value) => {
  const prefix = 'box-shadow: ';
  return `${prefix}${getEffectToken(value)};${prefix}var(${getPrefix('effect-token')}${value});`;
};

const KDS = {
  prefixes,
  primitives,
  tokens,

  getPrefix,
  /* Primitives */
  getPrimitive,
  getSpacing,
  /* Tokens */
  getColorToken,
  getEffectToken,
  /* Primitives */
  setPrimitiveWithFallback,
  setColorWithFallback,
  setFontFamilyWithFallback,
  setFontSizeWithFallback,
  setFontWeightWithFallback,
  setLetterSpacingWithFallback,
  setLineHeightWithFallback,
  setTypographyWithFallback,
  setBoxShadowWithFallback,
  setSpacingWithFallback,
  /* Tokens */
  setColorTokenWithFallback,
  setBoxShadowTokenWithFallback,
};

export default KDS;
