import React from 'react';
import { SelectOutline } from './SelectOutline';
import { SelectLine } from './SelectLine';
import PropTypes from 'prop-types';
import { TextSelect } from './Text';

const MAP_SELECT_TYPE = {
  outline: SelectOutline,
  line: SelectLine,
  text: TextSelect,
};

const Select = ({ type, ...moreProps }) => {
  const defaultSelectType = 'outline';
  const SelectComponent =
    MAP_SELECT_TYPE[type] || MAP_SELECT_TYPE[defaultSelectType];

  return <SelectComponent {...moreProps} />;
};

Select.propTypes = {
  type: PropTypes.string,
};

export { Select };
