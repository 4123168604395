import React from 'react';
import PropTypes from 'prop-types';
import { useReactTable, getCoreRowModel } from '../../../Utils/ReactTable';
import CustomReportTable from '../../TableReport/CustomReportTable';
import PeriodInfo from '../components/PeriodText/PeriodText';
import TransactionsReportColumns from './TransactionsReportColumns';
import { ACCOUNT_MANAGER } from '../../../Utils/constants';
import Auth from '../../../Utils/Auth/auth';
import AnalyticsEvent from '../../../services/analytics/AnalyticsEvent';

const ReportTable = ({
  data = [],
  onDetailsPage = () => null,
  onCancel = () => null,
  onDownloadReport = () => null,
  options = {},
}) => {
  const { employee } = Auth.userData();
  const defaultOptions = {
    onChangePageSize: () => null,
    onChangePage: () => null,
    onCleanFilter: () => null,
    totalElements: 0,
    totalPages: 1,
    currentPage: 1,
    ...options,
  };

  const handleChangePageSize = pageSize => {
    defaultOptions.onChangePageSize(pageSize);
  };

  const handleChangePage = page => {
    defaultOptions.onChangePage(page);
  };

  const handleOnDetailsPage = id => {
    onDetailsPage && onDetailsPage(id);
  };

  const handleOnCancel = page => {
    if (onCancel) {
      AnalyticsEvent().transactionCancelled({
        storeName: page.store_name,
        storeId: page.store_id,
        paymentId: page.payment_id,
        funnelStep: 'Transaction Report',
      });
      onCancel(page);
    }
  };

  const handleOnCleanFilters = () => {
    defaultOptions.onCleanFilter();
  };

  const handleOnDownloadReport = () => {
    onDownloadReport && onDownloadReport();
  };

  const columns = React.useMemo(
    () =>
      TransactionsReportColumns({
        onCancel: handleOnCancel,
        onDetailsPage: handleOnDetailsPage,
        disableCancelButton: employee.role.name === ACCOUNT_MANAGER,
      }),
    []
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <CustomReportTable
      table={table}
      period={<PeriodInfo noResults={defaultOptions.totalElements} />}
      reportName='Descargar reporte'
      onDownloadReport={handleOnDownloadReport}
      options={{
        onCleanFilter: handleOnCleanFilters,
        pagination: {
          onChangePageSize: handleChangePageSize,
          onChangePage: handleChangePage,
          totalElements: defaultOptions.totalElements,
          totalPages: defaultOptions.totalPages,
          currentPage: defaultOptions.currentPage,
        },
      }}
    />
  );
};

ReportTable.propTypes = {
  data: PropTypes.array,
  onDetailsPage: PropTypes.func,
  onCancel: PropTypes.func,
  onDownloadReport: PropTypes.func,
  options: PropTypes.shape({
    onCleanFilter: PropTypes.func,
    onChangePageSize: PropTypes.func,
    onChangePage: PropTypes.func,
    totalElements: PropTypes.number,
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
  }),
};

export default ReportTable;
