import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { Modal } from '../../../ds';
import styles from './ModalViewer.module.scss';
import { ReactComponent as BackArrowIcon } from './res/back_arrow.svg';
import { ReactComponent as CloseIcon } from './res/close-icon.svg';
import { ReactComponent as PlayIcon } from './res/play.svg';
import { isFeatureEnabled } from '../../../Utils/Feature/feature_flag';
import AnalyticsEvent from '../../../services/analytics/AnalyticsEvent';

const Card = ({ onClick, item }) => {
  const { title = '', description = '', media = [] } = item;

  const firstImage =
    media.filter(item => item.media_type === 'image')?.[0]?.media_url || null;

  return (
    <div className={styles.videoCard}>
      <div className={styles.miniatureContainer} onClick={() => onClick(item)}>
        <img className={styles.miniatureImg} src={firstImage} alt={title} />
        <PlayIcon className={styles.iconPlay} />
      </div>
      <div className={styles.info}>
        <div className={styles.videoTitle}>{title}</div>
        <div className={styles.videoDesc}>{description}</div>
      </div>
    </div>
  );
};

const MiniatureCard = memo(Card);

Card.propTypes = {
  onClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.object,
};

const ModalViewer = ({
  allAssets = [],
  assetSelected = null,
  isOpen = false,
  onRequestClose = () => {},
}) => {
  const SHOW_DESCRIPTION_VIDEO = isFeatureEnabled(
    'home_page_description_videos'
  );
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [assets, setAssets] = useState([]);

  useEffect(() => {
    if (!isOpen) return;
    const initialAssets = assetSelected
      ? allAssets?.filter(item => item.asset_id !== assetSelected?.asset_id)
      : allAssets;
    setAssets(initialAssets);
  }, [isOpen]);

  useEffect(() => {
    if (assetSelected) {
      handleViewView(assetSelected);
    } else {
      setAssets(allAssets);
    }
  }, [assetSelected]);

  const handleViewView = asset => {
    AnalyticsEvent().videoClicked();
    setSelectedVideo(asset);
    const toWatch = allAssets.filter(item => item.asset_id !== asset?.asset_id);
    setAssets(toWatch);
  };

  const handleEvent = () => {
    setSelectedVideo(null);

    if (assetSelected) {
      onRequestClose();
      return;
    }

    if (selectedVideo !== null) {
      setAssets(assets);
      return;
    }
    onRequestClose();
  };

  const close = () => {
    setSelectedVideo(null);
    onRequestClose();
  };

  const mediaUrl =
    selectedVideo?.media?.filter(item => item.media_type === 'video')?.[0]
      .media_url || null;

  return (
    <Modal
      className={styles.modalContainer}
      theme='dark'
      cardType='secondary'
      {...{ isOpen: isOpen }}
    >
      <>
        <div className={styles.headerModal}>
          <div className={styles.actionBack}>
            {selectedVideo ? (
              <BackArrowIcon className={styles.arrow} onClick={handleEvent} />
            ) : (
              <BackArrowIcon
                className={cn(styles.arrow, styles.hideOnDesk)}
                onClick={handleEvent}
              />
            )}
            <span className={styles.hideOnDesk}>Regresar</span>
          </div>

          <div className={styles.title}>¡Explora Kueski Pay!</div>
          <CloseIcon className={styles.closeIcon} onClick={close} />
        </div>
        <div className={styles.area}>
          {selectedVideo && (
            <div className={styles.videoContainer}>
              <ReactPlayer controls width='100%' height='100%' url={mediaUrl} />
              <div className={styles.videoTitle}>{selectedVideo?.title}</div>
              {SHOW_DESCRIPTION_VIDEO ? (
                <div className={styles.videoDescrip}>
                  {selectedVideo?.description}
                </div>
              ) : null}
            </div>
          )}
          <div className={styles.container}>
            {assets?.map((asset, index) => (
              <MiniatureCard
                key={asset.asset_id}
                index={index}
                onClick={handleViewView}
                item={asset}
              />
            ))}
          </div>
        </div>
      </>
    </Modal>
  );
};
ModalViewer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  allAssets: PropTypes.array.isRequired,
  assetSelected: PropTypes.object,
};
export default ModalViewer;
