import styled from 'styled-components';

export const StyledReportTable = styled.div`
  margin-top: 24px;
  width: 100%;

  @media (max-width: 1279px) {
    margin-top: 24px;
  }
`;

export const StyledHead = styled.div`
  display: grid;
  padding-bottom: 24px;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
`;
export const StyledPeriod = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #18223d;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 18.2px */
  letter-spacing: 0.28px;

  @media (max-width: 1280px) {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledDownloadPeriodContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
