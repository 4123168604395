import { Space } from '../../ds';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { ReactComponent as EditIcon } from './edit-icon.svg';
import './importUserForm.scss';
import UserCrudForm from './UserCrudForm';
import { useTranslation } from 'react-i18next';

function EditButton(props) {
  const { onEdit } = props;
  return (
    <Space
      justify='center'
      onClick={handleOnClick}
      className='edit-button k-ds-heading-02'
    >
      <EditIcon className='k-ds-m-right-sp-02' />
      Editar información
    </Space>
  );

  function handleOnClick() {
    onEdit();
  }
}

function InformationBlock(props) {
  const { label, value, isRequired, fieldErrors } = props;
  return (
    <>
      <div className='k-ds-body-02 label block-label'>{label}</div>
      <div className='k-ds-heading-02'>{value || '-'}</div>
      <div className='k-ds-caption-01 error-caption'>
        {' '}
        {getError(fieldErrors, isRequired) || <Space>&nbsp;</Space>}
      </div>
    </>
  );

  function getError(errors, isRequired) {
    if (!errors) return false;
    if (typeof errors === 'string' && isRequired) return errors;
    if (errors && errors[0] && typeof errors[0] === 'string' && isRequired)
      return errors[0];
    return 'Es necesario completar este dato';
  }
}

function PreviewUserInfo(props) {
  const { user, onEditInformation, stores } = props;
  const { t } = useTranslation();

  const viewport = useDeviceContextViewport();
  const isMobileOnly = viewport.width <= 585 || viewport.isMobileOnly;

  const isGenAdmin = user?.role_name === 'Admin General';

  return (
    <Space
      direction={viewport.isMobile ? 'vertical' : 'horizontal'}
      justify='space-between'
      className='k-ds-width-full'
    >
      <Space
        className={
          viewport.isMobile
            ? 'mobile-container left-container'
            : 'desktop-container left-container'
        }
        direction='vertical'
      >
        <Space className='container'>
          <div className='k-ds-body-01 label'>Rol:</div>
          <div className='k-ds-heading-03'>{user.role_name || '-'}</div>
        </Space>
        {!user.role_id && (
          <div className='k-ds-caption-01 error-caption'>
            {user?.errors?.role_id?.[0]
              ? user.errors.role_id?.[0]
              : 'Es necesario completar este dato'}
          </div>
        )}
        {!isGenAdmin && (
          <>
            <Space className='container store-container'>
              <div className='k-ds-body-01 label'>Sucursales asignadas:</div>
              {user.stores.map((item, index) => {
                return (
                  <div key={index} className='k-ds-heading-03'>
                    {stores?.[item] || '-'}
                  </div>
                );
              })}
            </Space>
            {!user?.stores?.[0] && (
              <div className='k-ds-caption-01 error-caption'>
                {user?.errors?.stores?.[0]
                  ? t('users.' + user.errors.stores?.[0])
                  : 'Es necesario completar este dato'}
              </div>
            )}
          </>
        )}
        {!viewport.isMobile && <EditButton onEdit={handleEdit} />}
      </Space>
      <Space
        className='right-container k-ds-width-full'
        direction={isMobileOnly ? 'vertical' : 'horizontal'}
      >
        <Space direction='vertical' className='k-ds-width-full data-container'>
          <div className='k-ds-heading-04 k-ds-m-bottom-sp-07'>
            Datos del usuario
          </div>
          <InformationBlock
            isRequired
            label='Nombre:'
            value={user.first_name}
            fieldErrors={user?.errors?.first_name}
          />
          <InformationBlock
            isRequired
            label='Apellido Paterno:'
            value={user.last_name}
            fieldErrors={user?.errors?.last_name}
          />
          <InformationBlock
            isRequired
            label='Apellido Materno:'
            value={user.maiden_name}
            fieldErrors={user?.errors?.maiden_name}
          />
        </Space>
        <Space direction='vertical' className='k-ds-width-full data-container'>
          <div className='k-ds-heading-04 custom-vertical-sp-07'>
            Información de contacto
          </div>
          <InformationBlock
            isRequired
            label='CURP:'
            value={user.curp}
            fieldErrors={user?.errors?.curp}
          />
          <InformationBlock
            isRequired
            label='Correo electrónico:'
            value={user.email}
            fieldErrors={user?.errors?.email}
          />
          <InformationBlock
            isRequired
            label='Teléfono celular:'
            value={user.phone_number}
            fieldErrors={user?.errors?.phone_number}
          />
        </Space>
      </Space>
      {viewport.isMobile && <EditButton onEdit={handleEdit} />}
    </Space>
  );

  function handleEdit() {
    onEditInformation();
  }
}

const ImportUserForm = props => {
  const { userData: user, onUpdate, stores } = props;
  const [isCrud, setIsCrud] = useState(false);

  const storesFormated = useMemo(() => {
    let branches = {};
    stores.map(item => {
      branches[item.store_id] = item.name;
    });

    return branches;
  }, [stores]);

  return (
    <Space className='import-user-form'>
      {isCrud ? (
        <UserCrudForm
          userData={user}
          stores={stores}
          closeEdit={switchCrudMode}
          onSaveUser={handleSaveUser}
        />
      ) : (
        <PreviewUserInfo
          user={user}
          onEditInformation={switchCrudMode}
          stores={storesFormated}
        />
      )}
    </Space>
  );

  function switchCrudMode() {
    setIsCrud(!isCrud);
  }

  function handleSaveUser(updatedUser) {
    onUpdate(updatedUser);
  }
};

EditButton.propTypes = {
  onEdit: PropTypes.func,
};

InformationBlock.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  isRequired: PropTypes.bool,
  fieldErrors: PropTypes.array,
};

PreviewUserInfo.propTypes = {
  user: PropTypes.object.isRequired,
  onEditInformation: PropTypes.func,
  stores: PropTypes.object,
};

ImportUserForm.propTypes = {
  userData: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
  stores: PropTypes.array,
};

export default ImportUserForm;
