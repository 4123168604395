import React, { useEffect, useState } from 'react';
import { Card } from '../../ds';
import { ReactComponent as Information } from '../../common/assets/icon-information.svg';
import styled from 'styled-components';
import KDS from '../../ds/lib/k-ds-foundation';
import './instoreflowselector.css';
import RadioButton from '../RadioButton';
import PropTypes from 'prop-types';
import { OFFLINE_MODE, ONLINE_MODE } from '../../Utils/constants';
import OfflineInfoButton from './OfflineInfoButton';



const InfoDiv = styled.div`
    display: flex;
    align-items: flex-start;
    border-radius: 16px 16px 0 0;
    align-content: center;
    ${ KDS.setColorWithFallback('background: ', 'blue-100') }
    padding: 1rem;
    ${ KDS.setTypographyWithFallback({
        'font-family': 'font-primary'
    }) }
`;

const RadioButtonCard = styled(Card)`
    border-radius: 0 0 16px 16px;
    padding: 1rem;
    border-top: 0;
`;

const NoConnectionNode = <span>No tiene conexión a internet <b>(Pago sin conexión)</b></span>;

const InStoreFlowSelector = ( {onChange} ) => {
  const [paymentMode, setPaymentMode] = useState('')

  useEffect(() => {
    if (onChange && paymentMode)
      onChange(paymentMode);
  }, [paymentMode]);

  return <div className='instore-flow-selector'>
    <InfoDiv className='k-ds-m-top-sp-06'>
      <Information className={ 'information-icon' }/>
      Ahora tus clientes pueden pagar sin conexión a internet usando Kueski Pay.
      <OfflineInfoButton/>
    </InfoDiv>
    <RadioButtonCard type='secondary' className='k-ds-width-full'>
      <div className='k-ds-heading-03 k-ds-m-bottom-sp-04'>
        Verificar la conexión del cliente
        <p className='k-ds-body-01'>
          Pregunta al cliente si su dispositivo tiene conexión a internet y selecciona una opción. <span className='k-ds-text-color-error'>*</span>
        </p>
      </div>
      <div className='k-ds-m-bottom-sp-04'>
        <RadioButton label='Si tiene conexión a internet'
                     className='k-ds-m-right-sp-08'
                     checked={ paymentMode === ONLINE_MODE }
                     onChange={ () => setPaymentMode(ONLINE_MODE) }
                     value={ ONLINE_MODE }></RadioButton>
        <RadioButton onChange={ () => setPaymentMode(OFFLINE_MODE) }
                     checked={ paymentMode === OFFLINE_MODE }
                     label={ NoConnectionNode }
                     value={ OFFLINE_MODE }/>
      </div>
    </RadioButtonCard>
  </div>;
}

InStoreFlowSelector.propTypes = {
  onChange: PropTypes.func
};

export default InStoreFlowSelector;
