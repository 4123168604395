import React from 'react';
import styled, { css } from 'styled-components';
import KDS from '../../lib/k-ds-foundation';
import { Card } from '../card/index';
import { Space } from '../space/index';
import { ReactComponent as IconClose } from './icon-cross.svg';

const StyledOverlay = styled.div`
  display: flex;
  ${(props) => !(props.isOpen) && css`
    display: none;
  `}
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 2;

  ${(props) => props.overlayBackground && css`
    background: ${props.overlayBackground};
    backdrop-filter: blur(5px);
  `}

  .modal-header {
    ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}
    ${KDS.setSpacingWithFallback('margin: ', 0, 0, 'spacing-03', 0)};
  }

  .modal-sub-header {
    ${KDS.setColorTokenWithFallback('color: ', 'text-secondary')}
    margin: 0;
  }

  .icon-close {
    cursor: pointer;
    padding: 8px;
    flex-shrink: 0;
  }
`;

const overlayBackgroundFromTheme = {
  'light': 'rgba(245, 246, 251, 0.96)',
  'dark': 'rgba(0, 0, 0, 0.3)',
};

const Modal = ({
  className, children, theme, overlayBackground, onOverlayClick,
  cardType, showClose, onClose, header, subHeader, ...moreProps
}) => {
  const defaultTheme = 'light';
  // eslint-disable-next-line
  const overlayBg = overlayBackground || overlayBackgroundFromTheme[theme] || overlayBackgroundFromTheme[defaultTheme];
  const headerJustify = (!header && showClose) ? 'end' : 'space-between';
  const Header = (
    <Space className="k-ds-width-full" direction="horizontal" align="center" justify={headerJustify}>
      {
        header ? (
          <h1 className="modal-header k-ds-heading-04">{header}</h1>
        ) : null
      }
      {
        showClose ? (
          <IconClose className="icon-close" onClick={onClose} aria-hidden="true" />
        ) : null
      }
    </Space>
  );
  return (
    <StyledOverlay overlayBackground={overlayBg} onClick={onOverlayClick} {...moreProps}>
      <Card type={cardType} className={className} onClick={(e) => e.stopPropagation()}>
        {
          (header || showClose) ? (<>{Header}</>) : null
        }
        {
          subHeader ? (
            <h2 className="modal-sub-header k-ds-body-01">{subHeader}</h2>
          ) : null
        }
        {
          (header || subHeader) ? (
            <div className="k-ds-m-bottom-sp-06" />
          ) : null
        }
        <div>
          {children}
        </div>
      </Card>
    </StyledOverlay>
  );
};

export {
  Modal
};

