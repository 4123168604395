import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getLocalStorage } from '../../Utils/useGetMerchant';
import { useToastContext } from '../../contexts/Toast/ToastContext';
import { Button, Space } from '../../ds';
import {
  getStoresLight,
  requestImportUsersCreation,
} from '../../services/Application';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { Message } from '../DS/Message/index';
import Header from '../Header';
import LoaderModal from '../LoaderModal/LoaderModal';
import UserToUpload from './UserUpload';
import './importUsersFile.scss';
import AnalyticsEvent from '../../services/analytics/AnalyticsEvent';

function ErrorMessages(props) {
  const { hasErrors } = props;
  return (
    hasErrors && (
      <Message
        type='error'
        className='k-ds-m-bottom-sp-05 k-ds-width-full'
        message={
          <div className='k-ds-heading-01'>
            <div className='k-ds-m-bottom-sp-03'>¡Información incompleta!</div>
            <div className='k-ds-m-top-sp-06'>
              Algunos datos están incompletos, verifica la información y
              complétala o elimínalos. Al finalizar, da clic en &quot;Crear
              usuarios&quot;.
            </div>
          </div>
        }
      />
    )
  );
}

function WarningMessages(props) {
  const { hasDuplicated } = props;
  return (
    hasDuplicated && (
      <Message
        type='warning'
        className='k-ds-m-bottom-sp-05 k-ds-width-full'
        message={
          <div className='k-ds-heading-01'>
            <div className='k-ds-m-bottom-sp-03'>¡Usuarios duplicados!</div>
            <div className='k-ds-m-top-sp-06'>
              Detectamos que varios usuarios están duplicados, por favor
              elimimina los que se repitan para poder crearlos.
            </div>
          </div>
        }
      />
    )
  );
}

const ImportUsersFile = () => {
  let history = useHistory();
  const [stores, setStores] = useState([]);

  useEffect(() => {
    const merchantSelected = JSON.parse(getLocalStorage('merchantSelected'));
    getStoresLight(merchantSelected?.merchant_id).then(response => {
      setStores(response?.data?.stores || []);
    });
  }, []);

  const usersInfo = history?.location?.data ?? [];
  const [usersState, setUsersState] = useState(usersInfo);
  const completedUsers = usersState.filter(
    user => user.is_valid && !hasUserDuplicated(user)
  );
  const failedUsers = usersState.filter(user => !user.is_valid);
  const duplicatedUsers = usersState.filter(
    user => user.is_valid && hasUserDuplicated(user)
  );
  const hasErrors = failedUsers.length > 0;
  const hasDuplicated = duplicatedUsers.length > 0;
  const [animationsState, setAnimationsState] = useState({
    expandedItem: '',
    isModalOpen: false,
    isLoading: false,
  });
  const addToast = useToastContext();

  return (
    <>
      <Space direction='vertical' className='import-users-file'>
        <Header
          title='Bienvenido al proceso de alta de usuarios'
          subtitle='Verifica la información de los usuarios, puedes editarlos si ves un dato diferente. Da clic en “Crear usuarios” para finalizar el proceso.'
        />
        <Space
          direction='vertical'
          className='k-ds-width-full k-ds-m-bottom-sp-08'
        >
          <ErrorMessages hasErrors={hasErrors} />
          <WarningMessages hasDuplicated={hasDuplicated} />
          <div className='k-ds-subheading-02 k-ds-m-bottom-sp-06'>
            {' '}
            Nuevos usuarios: {usersState.length}{' '}
          </div>
          {hasErrors && (
            <Space
              className='users-box k-ds-m-bottom-sp-08'
              direction='vertical'
            >
              {failedUsers.map((userData, index) => {
                const isExpanded =
                  animationsState.expandedItem === userData.temporal_id;
                return (
                  <UserToUpload
                    key={index}
                    isExpanded={isExpanded}
                    stores={stores}
                    onClick={() =>
                      handleExpand(isExpanded, userData.temporal_id)
                    }
                    userData={userData}
                    onDelete={removeUser}
                    onUpdate={updateUser}
                  />
                );
              })}
            </Space>
          )}
          {hasDuplicated && (
            <Space
              className='users-box k-ds-m-bottom-sp-08'
              direction='vertical'
            >
              {duplicatedUsers.map((userData, index) => {
                const isExpanded =
                  animationsState.expandedItem === userData.temporal_id;
                return (
                  <UserToUpload
                    key={index}
                    isExpanded={isExpanded}
                    stores={stores}
                    onClick={() =>
                      handleExpand(isExpanded, userData.temporal_id)
                    }
                    isDuplicated
                    userData={userData}
                    onDelete={removeUser}
                    onUpdate={updateUser}
                  />
                );
              })}
            </Space>
          )}
          {completedUsers.length > 0 && (
            <Space className='users-box' direction='vertical'>
              {completedUsers.map((userData, index) => {
                const isExpanded =
                  animationsState.expandedItem === userData.temporal_id;
                return (
                  <UserToUpload
                    key={index}
                    isExpanded={isExpanded}
                    stores={stores}
                    onClick={() =>
                      handleExpand(isExpanded, userData.temporal_id)
                    }
                    userData={userData}
                    onDelete={removeUser}
                    onUpdate={updateUser}
                  />
                );
              })}
            </Space>
          )}
        </Space>
        <Space
          className='k-ds-width-full'
          direction='horizontal'
          justify='start'
        >
          <Button
            type='primary-alternate'
            className='k-ds-m-rigth-sp-02 create-button'
            horizontalPadding='1.125rem'
            verticalPadding='1rem'
            size='small'
            onClick={handleClick}
            disabled={hasErrors || hasDuplicated || usersState.length <= 0}
          >
            Crear usuarios
          </Button>
          <Button
            type='simple'
            size='small'
            horizontalPadding='1.375rem'
            verticalPadding='1rem'
            onClick={openModal}
          >
            Cancelar
          </Button>
        </Space>
      </Space>
      <ConfirmModal
        isOpen={animationsState.isModalOpen}
        mode={'Warning'}
        header='¿Deseas cancelar el proceso?'
        content='Esta acción no podrá deshacerse y se perderá la información que ingresaste.'
        onCancel={closeModal}
        onConfirm={handleConfirm}
        setIsOpen={openModal}
      />
      <LoaderModal
        isOpen={animationsState.isLoading}
        header='Estamos agregando a los nuevos usuarios, no actualices la ventana.'
        content='Esto puede tardar unos momentos...'
      />
    </>
  );

  function handleExpand(isExpanded, temporalId) {
    setAnimationsState({
      ...animationsState,
      expandedItem: isExpanded ? '' : temporalId,
    });
  }

  function openModal() {
    setAnimationsState({
      ...animationsState,
      isModalOpen: true,
    });
  }

  function closeModal() {
    setAnimationsState({
      ...animationsState,
      isModalOpen: false,
    });
  }

  function handleConfirm() {
    closeModal();
    goUsers();
  }

  function removeUser(user) {
    setUsersState(usersState.filter(item => item !== user));
  }

  function updateUser(updatedUser) {
    const newState = usersState.map(userItem => {
      if (updatedUser.temporal_id === userItem.temporal_id) {
        return { ...updatedUser };
      }
      return userItem;
    });
    setUsersState(newState);
  }

  function handleClick() {
    const data = {
      users: usersState,
    };
    let errors = [];

    setAnimationsState({
      ...animationsState,
      isLoading: true,
    });
    requestImportUsersCreation(data)
      .then(response => {
        addToast('¡Se crearon nuevos usuarios!', 'success');
        if (response.status === 'success') {
          if (response?.data?.errors) {
            errors = response?.data?.errors;
          }
        }
      })
      .catch(error => {
        AnalyticsEvent().errorTrack({
          errorType: 'user-import-files-error',
          errorDescription: error,
        });
        const errorsResponse = error?.response?.data?.errors?.users || [];
        if (errorsResponse) {
          errors = errorsResponse;
        }
      })
      .finally(() => {
        setAnimationsState({
          ...animationsState,
          isLoading: false,
        });
        goUsers(errors, errors.length === 0, usersState.length);
      });
  }

  function goUsers(data, hasNew = false, usersCount = 0) {
    history.push({
      pathname: '/users',
      data: data,
      search: hasNew ? `?created=true&count=${usersCount}` : '',
    });
    const contentArea = document.getElementById('content-area');
    contentArea.scrollTop = 0;
  }

  function hasUserDuplicated(userData) {
    return usersState.some(user => {
      return (
        user.is_valid &&
        userData.is_valid &&
        user.first_name === userData.first_name &&
        user.last_name === userData.last_name &&
        user.email === userData.email &&
        user.role_id === userData.role_id &&
        user.store_name === userData.store_name &&
        user.phone_number === userData.phone_number &&
        user.curp === userData.curp &&
        user.temporal_id !== userData.temporal_id
      );
    });
  }
};

ErrorMessages.propTypes = {
  hasErrors: PropTypes.bool.isRequired,
};

WarningMessages.propTypes = {
  hasDuplicated: PropTypes.bool.isRequired,
};

export default ImportUsersFile;
