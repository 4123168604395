/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { Space } from '../../../../ds';
import { StyledSelectOutline, StyledButton, HiddenDivInput } from './styled';
import { ReactComponent as IconArrowDown } from './icon-arrow-down.svg';
import Checkbox from '../../../Checkbox/Checkbox';

const allOptions = { label: 'Todos', value: 'all' };

// Gneral Focus Hook
const UseFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};

const MultipleChoice = ({
  className,
  onChange,
  onBlur,
  disabled,
  type = '',
  options = [],
  value = [],
  ...props
}) => {
  const items = options.length > 0 ? [allOptions, ...options] : [];
  const [isActive, setIsActive] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [ignoreBlur, setIgnoreBlur] = useState(false);
  const [inputHiddenRef, setInputHiddenFocus] = UseFocus();
  const [selected, setSelected] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, []);

  useEffect(() => {
    if (!isActive && !isFirstRender) {
      if (onBlur) onBlur();
    }
  }, [isActive]);

  useEffect(() => {
    if (value.length === options.length) {
      setSelectedItems(items);
    } else {
      setSelectedItems(value);
    }
  }, [value]);

  const onSelectClick = () => {
    if (disabled) return;

    setIsActive(!isActive);
    setInputHiddenFocus();
  };

  const onOptionClick = () => {
    if (onChange) {
      onChange(selectedItems.filter(item => item.value !== 'all'));
    }

    setSelected(true);
    setIsActive(false);
    setIgnoreBlur(false);
  };

  const handleBlur = () => {
    if (ignoreBlur) return;
  };

  const handleOnChekElement = element => {
    const indexItem = selectedItems.findIndex(
      item => item.value === element.value
    );
    let newCheckElemets = [...selectedItems];

    if (element.value == 'all' && indexItem == -1) {
      newCheckElemets = [...items];
    } else if (element.value == 'all' && indexItem != -1) {
      newCheckElemets = [];
    } else if (indexItem != -1) {
      newCheckElemets = selectedItems.filter(
        item => !['all', element.value].includes(item.value)
      );
    } else {
      newCheckElemets.push(element);
    }

    if (newCheckElemets.length === options.length) {
      newCheckElemets = [...items];
    }

    setSelectedItems(newCheckElemets);
  };

  const showClass = isActive && !disabled ? 'show' : '';

  return (
    <Space size='x-large' direction='vertical' className={className} {...props}>
      <StyledSelectOutline
        className='k-ds-width-full k-ds-body-01'
        disabled={disabled}
        showPlaceholder={selectedItems && selectedItems.length === 0}
        active={isActive}
      >
        <HiddenDivInput ref={inputHiddenRef} type='radio' onBlur={handleBlur} />
        <Space
          size='medium'
          justify='space-between'
          className={`k-ds-width-full selector ${selected ? 'selected' : ''}`}
          onClick={onSelectClick}
          direction='horizontal'
          align='center'
          aria-hidden
        >
          <div className='control'>
            <span className='k-ds-heading-02'>{`${type}:`}</span>
            <span className='k-ds-body-02 k-ds-m-left-sp-02'>{`Todos (${selectedItems.filter(item => item.value !== 'all').length})`}</span>
          </div>
          <IconArrowDown className={`arrow-icon ${showClass}`} />
        </Space>
        <div className={`options-group ${showClass}`}>
          {items.map((element, index) => (
            <div
              className='k-ds-m-bottom-sp-04 option'
              key={`${element.label}-${element.value}`}
            >
              <Checkbox
                id={`checkbox-${index}`}
                filled
                value={element.value}
                onChange={() => handleOnChekElement(element)}
                defaultChecked={
                  selectedItems.findIndex(
                    item => item.value === element.value
                  ) !== -1
                }
              />
              <span
                className='k-ds-body-02 k-ds-m-left-sp-02'
                onClick={() => handleOnChekElement(element)}
              >
                {element.label}
              </span>
            </div>
          ))}
          <StyledButton
            className='k-ds-m-top-sp-04'
            type='primary-alternate'
            horizontalPadding='1.125rem'
            verticalPadding='1rem'
            size='small'
            onMouseDown={() => setIgnoreBlur(true)}
            onClick={onOptionClick}
          >
            Aplicar
          </StyledButton>
        </div>
      </StyledSelectOutline>
    </Space>
  );
};

MultipleChoice.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    })
  ),
  defaultValue: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    })
  ),
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    })
  ),
};

export default MultipleChoice;
