import { makeAutoObservable } from 'mobx';
import Auth from '../../../Utils/Auth/auth';

export class QrCodeStore {
  storeId
  stores

  constructor() {
    this.reload();
    makeAutoObservable(this);
  }

  setStoreId = (storeId) =>  {
    this.storeId = storeId;
  }

  reload = () => {
    const userStores = Auth?.userData()?.stores;
    if (userStores?.length === 1) {
      this.storeId = userStores[0].store_id;
      this.stores = [];
      return;
    }
    this.storeId = '';
    this.stores = userStores ?? [];
  }

  get selectedStore() {
    const store = this.stores?.find(store => store.store_id === this.storeId);
    if (!store) {
      return null;
    }

    return {
      label: store?.name,
      value: store?.store_id,
    };
  }
}
