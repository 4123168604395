import React from 'react';
import './logoPage.scss';
import LogoWelcome from '../../../components/LogoWelcome/index';
import Header from '../../../components/Header';

function LogoPage() {
  return (
    <div className='k-ds-width-full logo-page'>
      <Header
        title='Información del comercio'
        subtitle='Gestiona la información de tu cuenta para mejorar tu experiencia en KueskiPay.'
      />
      <LogoWelcome />
    </div>
  );
}

export default LogoPage;
