export const ACCOUNT_MANAGER = 'account_manager';
export const STORE_ADMIN = 'store_admin';
export const GENERAL_ADMIN = 'general_admin';
export const SALES_ROLE = 'sales_person';
export const OTP_REQUIRED = 'otp-required';
export const MAX_BULK_ROWS = 80;
export const SECTION_REPORT_NAME = 'Reportes';
export const SECTION_CHANNEL_NAME = 'Canales de cobro';
export const GENERAL_MENU_NAME = 'Menu';
export const CACHE_DURATION_MS = 300000;
export const monthNames = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];
export const MAX_OTP_LENGTH = 6;

export const BANK_LIST = [
  { value: 'abc', label: 'ABC Capital' },
  // { value: "investa", label: "Accendo Banco" },
  // { value: "amex", label: "American Express Bank (México)" },
  { value: 'afirme', label: 'Banca Afirme' },
  { value: 'mifel', label: 'Banca Mifel' },
  { value: 'actinver', label: 'Banco Actinver' },
  { value: 'autofin', label: 'Banco Autofin México' },
  { value: 'azteca', label: 'Banco Azteca' },
  { value: 'bancrea', label: 'Banco Bancrea' },
  { value: 'base', label: 'Banco Base' },
  { value: 'finterra', label: 'Banco Covalto' },
  { value: 'compartamos', label: 'Banco Compartamos' },
  { value: 'creditsuisse', label: 'Banco Credit Suisse (México)' },
  { value: 'inversion afirme', label: 'Banco de Inversión Afirme' },
  { value: 'bajio', label: 'Banco del Bajío' },
  // { value: "finterra", label: "Banco Finterra" },
  { value: 'forjadores', label: 'Banco Forjadores' },
  { value: 'inbursa', label: 'Banco Inbursa' },
  { value: 'inmobiliario', label: 'Banco Inmobiliario Mexicano' },
  { value: 'invex', label: 'Banco Invex' },
  { value: 'jpmorgan', label: 'Banco JP Morgan' },
  { value: 'kebhana', label: 'Banco KEB Hana México' },
  { value: 'monex', label: 'Banco Monex' },
  { value: 'multiva', label: 'Banco Multiva' },
];

export const TAX_REGIME = {
  natural: [
    {
      value: '605',
      label: 'Sueldos y Salarios e Ingresos Asimilados a Salarios',
    },
    {
      value: '606',
      label: 'Arrendamiento',
    },
    {
      value: '607',
      label: 'Régimen de Enajenación o Adquisición de Bienes',
    },
    {
      value: '608',
      label: 'Demás ingresos',
    },
    {
      value: '610',
      label:
        'Residentes en el Extranjero sin Establecimiento Permanente en México',
    },
    {
      value: '611',
      label: 'Ingresos por Dividendos (socios y accionistas)',
    },
    {
      value: '612',
      label: 'Personas Físicas con Actividades Empresariales y Profesionales',
    },
    {
      value: '614',
      label: 'Ingresos por intereses',
    },
    {
      value: '615',
      label: 'Régimen de los ingresos por obtención de premios',
    },
    {
      value: '616',
      label: 'Sin obligaciones fiscales',
    },
    {
      value: '625',
      label:
        'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
    },
    {
      value: '626',
      label: 'Régimen Simplificado de Confianza',
    },
  ],
  legal: [
    {
      value: '601',
      label: 'General de Ley Personas Morales',
    },
    {
      value: '603',
      label: 'Personas Morales con Fines no Lucrativos',
    },
    {
      value: '610',
      label:
        'Residentes en el Extranjero sin Establecimiento Permanente en México',
    },
    {
      value: '612',
      label: 'Personas Físicas con Actividades Empresariales y Profesionales',
    },
    {
      value: '620',
      label:
        'Sociedades Cooperativas de Producción que optan por diferir sus ingresos',
    },
    {
      value: '621',
      label: 'Incorporación Fiscal',
    },
    {
      value: '622',
      label: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras',
    },
    {
      value: '623',
      label: 'Opcional para Grupos de Sociedades',
    },
    {
      value: '624',
      label: 'Coordinados',
    },
  ],
};
const daysOfTheWeek = {
  monday: 'Lunes',
  tuesday: 'Martes',
  wednesday: 'Miércoles',
  thursday: 'Jueves',
  friday: 'Viernes',
  saturday: 'Sábado',
  sunday: 'Domingo',
};

const hoursOfTheDay = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:59',
];

const upperAverageStoresCount = 150;

const orderProductsMaxLimit = 100;

const unauthorizedOtpBlocked = 'unauthorized-otp-blocked';

const loginErrors = {
  unauthorized:
    'Tu correo o contraseña son incorrectos.\n¿Lo puedes volver a intentar?',
  'idp-response-error':
    'Por seguridad, tu cuenta ha sido bloqueada.\nRestablece tu contraseña en la opción ¿Olvidaste tu contraseña?',
  'unauthorized-otp-blocked':
    'Has excedido el número de intentos permitidos. Inténtalo de nuevo en 30 minutos.',
  'unauthorized-invalid-otp': 'El NIP es incorrecto',
  'phone-number-not-registered':
    'Para iniciar sesión, es necesario registrar el teléfono celular.',
};

const mexicanStates = {
  AGU: 'Aguascalientes',
  BCN: 'Baja California',
  BCS: 'Baja California Sur',
  CAM: 'Campeche',
  CHP: 'Chiapas',
  CHH: 'Chihuahua',
  CMX: 'Ciudad de México',
  COA: 'Coahuila',
  COL: 'Colima',
  DUR: 'Durango',
  GUA: 'Guanajuato',
  GRO: 'Guerrero',
  HID: 'Hidalgo',
  JAL: 'Jalisco',
  MIC: 'Michoacán',
  MOR: 'Morelos',
  MEX: 'México',
  NAY: 'Nayarit',
  NLE: 'Nuevo León',
  OAX: 'Oaxaca',
  PUE: 'Puebla',
  QUE: 'Querétaro',
  ROO: 'Quintana Roo',
  SLP: 'San Luis Potosí',
  SIN: 'Sinaloa',
  SON: 'Sonora',
  TAB: 'Tabasco',
  TAM: 'Tamaulipas',
  TLA: 'Tlaxcala',
  VER: 'Veracruz',
  YUC: 'Yucatán',
  ZAC: 'Zacatecas',
};

const excelFileCreatorName = 'Kueski Instore';
const excelFileRowsLimit = 100;
const excelFileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const salesmanRoleLabel = 'Vendedor';

const consumerOrderType = 'instore_consumer';
const notApplicableOrderStaffId = 'NA';

const productionAssitantAllowedAccessList = [
  '59083931652096', // Jose Miguelon
  '33306401968128', // PurchaseFinance Team
];

export const productionPaymentValidationAllowedAccessList = [
  '59083931652096', // Jose Miguelon
  '434465293865363', // Calvin Klein Instore
  '322896010548021', // Devlyn
  '456977796961269', // Más Visión Instore
  '434482868000031', // Promoda Instore
  '348931603175818', // Georgie Boy
  '360592611350032', // Mister Tennis
  '469359357205621', // Timberland Instore
  '528037414403703', // Salomon Instore
  '554141433563067', // NINE WEST Instore
  '448981457048808', // Cklass Instore
  '472882572562679', // Vans Instore
  '550453696196257', // Massmarcas InStore
  '444637991015166', // Aeropostale Instore
  '535303055814126', // Chicos
  '469355058043660', // Atleticos Interlomas
  '469356416995761', // Atleticos Aliana
  '469353829110357', // Atleticos Cuautla
  '241389875106442', // Dorothy Gaynor
  '444634941756202', // Champion México Instore
  '469357159387579', // Atleticos Feliali
  '469357885004749', // Atleticos Ivili
  '509878269082961', // Bath & Body Works Instore
  '509948752267425', // Victorias Secret Instore
  '510682043936124', // Nike Instore
  '509950074906419', // TAF Instore
  '509879455622800', // Tommy Hilfiger Instore
  '473384601393453', // Guess Instore
  '491121906162783', // Urban Store Instore
  '234591587799041', // LOB Footwear
  '155531335569531', // Nuestro Secreto
  '522670767170793', // Gran Via Instore
  '326140506019716', // LOB
  '506267740895172', // Lineas
  '297869873648542', // High Street
  '520790274770871', // Adidas,
  '33306401968128', // PurchaseFinance Team
  '448981457048808', // Cklass Instore
  '521505125464292', // The North Face Instore
];

export const stagingPaymentValidationAllowedAccessList = [
  '193245086879745', // kueskipay shop
  '203341913264128', // Bodega Aurrerá
];

export const whiteListToallowedProductTab = {
  production: [
    '307840409538997', // Bodega Aurrerá
    '495338788099863', // Walmart
  ],
  development: [
    '203341913264128', // Bodega Aurrerá
  ],
};
export const REFERENCE_AUTOFILL_LIST = {
  production: [
    '59083931652096', // Jose Miguelon KUESKI TEST
    '509878269082961', //Bath
    '505930950090839', //Abercrombie
    '509944865580565', //COACH
    '473384601393453', //Guess
    '505929367488611', //Hollister
    '510681105867235', //Laces
    '510682043936124', //Nike
    '591076323775306', //Old
    '434482868000031', //Promoda
    '509883951916972', //Rapsodia
    '491120740146133', //Reduced
    '509879455622800', //Tommy
    '491121906162783', //Urban
    '434465293865363', //Calvin
    '509948752267425', //Victorias
    '509950074906419', //TAF
    '505942161466255', //Lust
    '510681105867235', //Laces
    '509946881210265', //Brooks
  ],
  development: [
    '203341913264128', // Bodega Aurrerá
  ],
};

export const QR_V2_ACCESS_LIST = {
  development: [
    '1517181414494883850', // 11141 Test JH
    '1185595791171436545', // Z Tienda de prueba Daniel
  ],
  production: [
    '1480648534489120778', // Mister Tennis
  ]
}

const whatsNewModalStateItemKey = 'whatsNew_modal_state';

const bankCodeNames = {
  '002': 'BANAMEX',
  '006': 'BANCOMEXT',
  '009': 'BANOBRAS',
  '012': 'BBVA',
  '014': 'SANTANDER',
  '019': 'BANJERCITO',
  '021': 'HSBC',
  '030': 'BAJIO',
  '032': 'IXE',
  '036': 'INBURSA',
  '037': 'INTERACCIONES',
  '042': 'MIFEL',
  '044': 'SCOTIABANK',
  '058': 'BANREGIO',
  '059': 'INVEX',
  '060': 'BANSI',
  '062': 'AFIRME',
  '072': 'BANORTE',
  102: 'THE ROYAL BANK',
  103: 'AMERICAN EXPRESS',
  106: 'BAMSA',
  108: 'TOKYO',
  110: 'JP MORGAN',
  112: 'BMONEX',
  113: 'VE POR MAS',
  116: 'ING',
  124: 'DEUTSCHE',
  126: 'CREDIT SUISSE',
  127: 'AZTECA',
  128: 'AUTOFIN',
  129: 'BARCLAYS',
  130: 'COMPARTAMOS',
  131: 'BANCO FAMSA',
  132: 'BMULTIVA',
  133: 'ACTINVER',
  134: 'WAL-MART',
  135: 'NAFIN',
  136: 'INTERBANCO',
  137: 'BANCOPPEL',
  138: 'ABC CAPITAL',
  139: 'UBS BANK',
  140: 'CONSUBANCO',
  141: 'VOLKSWAGEN',
  143: 'CIBANCO',
  145: 'BBASE',
  166: 'BANSEFI',
  168: 'HIPOTECARIA FEDERAL',
  600: 'MONEXCB',
  601: 'GBM',
  602: 'MASARI',
  605: 'VALUE',
  606: 'ESTRUCTURADORES',
  607: 'TIBER',
  608: 'VECTOR',
  610: 'B y B',
  614: 'ACCIVAL',
  615: 'MERRILL LYNCH',
  616: 'FINAMEX',
  617: 'VALMEX',
  618: 'UNICA',
  619: 'MAPFRE',
  620: 'PROFUTURO',
  621: 'CB ACTINVER',
  622: 'OACTIN',
  623: 'SKANDIA',
  626: 'CBDEUTSCHE',
  627: 'ZURICH',
  628: 'ZURICHVI',
  629: 'SU CASITA',
  630: 'CB INTERCAM ',
  631: 'CI BOLSA',
  632: 'BULLTICK CB',
  633: 'STERLING',
  634: 'FINCOMUN',
  636: 'HDI SEGUROS',
  637: 'ORDER',
  638: 'AKALA',
  640: 'CB JPMORGAN',
  642: 'REFORMA',
  646: 'STP',
  647: 'TELECOMM',
  648: 'EVERCORE',
  649: 'SKANDIA',
  651: 'SEGMTY',
  652: 'ASEA',
  653: 'KUSPIT',
  655: 'SOFIEXPRESS',
  656: 'UNAGRA',
  659: 'OPCIONES EMPRESARIALES DEL NOROESTE',
  901: 'CLS',
  902: 'INDEVAL',
  670: 'LIBERTAD',
};

export {
  consumerOrderType,
  daysOfTheWeek,
  excelFileCreatorName,
  excelFileRowsLimit,
  excelFileType,
  hoursOfTheDay,
  loginErrors,
  mexicanStates,
  notApplicableOrderStaffId,
  orderProductsMaxLimit,
  productionAssitantAllowedAccessList,
  salesmanRoleLabel,
  unauthorizedOtpBlocked,
  upperAverageStoresCount,
  whatsNewModalStateItemKey,
  bankCodeNames,
};

export const MERCHANT_TRANSACTION_TYPE_FILTER = [
  { label: 'Pago', value: 'payment' },
  { label: 'Cancelación', value: 'refund' },
];

export const MERCHANT_CHANNELS_FILTER = [
  { label: 'Tienda en línea', value: 'online' },
  { label: 'Tienda física', value: 'instore' },
];

export const MERCHANT_TRANSACTIONS_TYPE_FILTER = [
  { label: 'Pago', value: 'success' },
  { label: 'Cancelación total', value: 'total_cancel' },
  { label: 'Cancelación parcial', value: 'partial_cancel' },
];

export const REFUND_CREATE_FAIL =
  'The refund cant be created: Cannot create refund because loan has been already paid or canceled';
export const REFUND_ALREADY_PAID =
  'Cannot create refund because loan has been already paid or canceled';
export const PERSIST_STORE_KEYS = {
  merchantTransactionsReport: 'merchant_transactions_report',
};
export const INACTIVE_MERCHANT_ERROR = 'merchant-not-active-error';

export const ONLINE_MODE = 'online';
export const OFFLINE_MODE = 'offline';
