import Requestor from '../Utils/fetchHTTP/Requestor';

// eslint-disable-next-line no-unused-vars
const verifyDataResponseFormat = response => {
  const { data } = response;

  if (!data || !(data.token && data.employee && data.stores && data.merchant)) {
    throw {
      status: 'error',
      message: 'Invalid response format',
      code: 'invalid-response-format',
      response,
    };
  }
};

const verifyFailedResponse = response => {
  const { status } = response;
  if (!['success', 'failed', 'error'].includes(status)) {
    throw {
      status: 'error',
      message:
        'Status is missing in response body or does not contain a valid value',
      code: 'invalid-status',
      response,
    };
  }

  if (status === 'failed' || status === 'error') {
    throw response;
  }
};

const logIn = async loginData => {
  try {
    const response = await Requestor().getUnSignedRequest({
      method: 'POST',
      url: '/api/public/auth/login',
      data: loginData,
    });

    verifyFailedResponse(response);
    verifyDataResponseFormat(response);

    return response.data;
  } catch (error) {
    if (error.status === 'error') {
      throw error;
    }

    if (error && error.data) {
      verifyFailedResponse(error.data);
    }

    throw { status: 'error', message: 'Something Happened', error: error };
  }
};

const passwordRecover = async recoverData => {
  try {
    const response = await Requestor().getUnSignedRequest({
      method: 'POST',
      url: '/api/public/auth/password_recover',
      data: recoverData,
    });

    if (response && response.data) {
      return response.data;
    }

    throw response;
  } catch (error) {
    throw { status: 'error', message: 'Something Happened', ex: error };
  }
};

export default function AuthService() {
  return Object.create({
    passwordRecover,
    logIn,
  });
}
