import React, { useCallback } from 'react';
import { useAuth } from '../../Utils/Auth/use-auth';
import { Button } from '../../ds';
import { ReactComponent as Icon } from './logout-icon.svg';

function redirectHome() {
  window.location.replace('/');
}

function cleanLocalStorage() {
  const keep = localStorage.getItem('payment-channels-modal');
  localStorage.clear();
  if (keep)
    localStorage.setItem(
      'payment-channels-modal',
      JSON.stringify({ show: false })
    );

  redirectHome();
}

function LogoutIcon() {
  const auth = useAuth();

  const handleLogOut = useCallback(() => {
    auth.logout();
  }, [auth]);

  return (
    <Button
      type='simple'
      size='small'
      horizontalPadding='0'
      onClick={handleLogOut}
    >
      <Icon />
      <span className='k-ds-m-left-sp-06'>Cerrar sesión</span>
    </Button>
  );
}

export default LogoutIcon;

export { cleanLocalStorage };
