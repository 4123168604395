import axios from 'axios';
import { cleanLocalStorage } from '../../components/Navbar/LogoutIcon';
import { getStage } from '../environment';

let stage = getStage();
const INVALID_TOKEN_MSG = 'Invalid access token';

const fetchHTTP = (url, params) =>
  new Promise((resolve, reject) => {
    const token = JSON.parse(window.localStorage.getItem('token'));

    const bearer = `${token?.token_type} ${token?.access_token}`;
    axios.defaults.headers.common.Authorization = bearer;

    let host = process.env.REACT_APP_API_PRODUCTION;

    if (stage === 'development') {
      host = process.env.REACT_APP_API_STAGING;
    }

    axios(`${host}${url}`, params)
      .then(response => {
        if (response.data.status === 'fail') {
          reject(response.data);
        } else {
          resolve(response.data);
        }
      })
      .catch(error => {
        const { response } = error;
        if (
          response &&
          response.status === 401 &&
          (response.statusText === 'Unauthorized' ||
            response.data?.message === INVALID_TOKEN_MSG)
        ) {
          cleanLocalStorage();
        } else {
          reject(error);
        }
      });
  });

export default fetchHTTP;
