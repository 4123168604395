import React from 'react';
import { store, StoreContext } from '../../services/store';
import PropTypes from 'prop-types';

const StoreContextProvider = ({ children }) => {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

StoreContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StoreContextProvider;
