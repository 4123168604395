import propTypes from 'prop-types';
import React, { useState } from 'react';
import Auth from '../../Utils/Auth/auth';
import { formatDateForInput } from '../../Utils/formats';
import { ReactComponent as CloseIcon } from '../../common/assets/close-icon.svg';
import {
  paymentLogs,
  merchatTransactionReport,
} from '../../containers/routes/ScopeRoutes';
import { hasPermissionAndScope } from '../../containers/routes/index';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { Button, DateInput, Space } from '../../ds';
import { Select } from '../DS/Select';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { isFeatureEnabled } from '../../Utils/Feature/feature_flag';

const PaymentHistorySearchFilters = ({ onChange, onCancel, filters }) => {
  const { permission_scopes } = Auth.userData();
  const [startDate, setStartDate] = useState(filters.startDate ?? '');
  const [endDate, setEndDate] = useState(filters.endDate ?? '');
  const [channel, setChannel] = useState(filters.channel ?? '');
  const [today] = useState(formatDateForInput(new Date()));
  const [paymentType, setPaymentType] = useState(filters.paymentType ?? '');
  const [openModal, setOpenModal] = useState(false);
  const viewport = useDeviceContextViewport();

  let defaultChannelOptions = [
    { value: 'all', text: 'Todos' },
    { value: 'instore', text: 'En tienda' },
  ];

  const moduleRaport = isFeatureEnabled('merchant_tarnsactions_report')
    ? merchatTransactionReport.module
    : paymentLogs.module;
  if (
    hasPermissionAndScope(
      permission_scopes,
      moduleRaport,
      'list_payments_merchant'
    )
  )
    defaultChannelOptions.push({ value: 'online', text: 'En línea' });

  const defaultPaymentOptions = [
    { value: 'all', text: 'Todos' },
    { value: 'success', text: 'Pagos aceptados' },
    { value: 'partial_cancel', text: 'Cancelaciones parciales' },
    { value: 'total_cancel', text: 'Cancelaciones totales' },
  ];
  const [channelOptions] = useState(defaultChannelOptions);
  const [paymentOptions] = useState(defaultPaymentOptions);

  const reset = () => {
    setStartDate('');
    setEndDate('');
    setChannel('');
    setPaymentType('');
    if (onChange) {
      onChange({});
    }
  };

  const onApply = () => {
    let filter = {};
    if (startDate && endDate) {
      filter = {
        ...filter,
        startDate,
        endDate,
      };
    }
    if (channel && channel !== 'all')
      filter = {
        ...filter,
        channel,
      };
    if (paymentType && paymentType !== 'all')
      filter = {
        ...filter,
        paymentType,
      };
    if (onChange) {
      onChange(filter);
    }
  };

  const onCancelModal = () => {
    reset();
    if (onCancel) {
      onCancel();
    }
  };

  const filterButtonDisabled = () => {
    if (!startDate && !endDate && !channel && !paymentType) {
      return true;
    }
    if ((startDate && !endDate) || (!startDate && endDate)) {
      return true;
    }
  };

  return (
    <div className='filters-container'>
      <Space
        direction='horizontal'
        align='center'
        justify='space-between'
        className='k-ds-m-top-sp-07 k-ds-m-bottom-sp-05'
      >
        <div className='k-ds-heading-04'>Búsqueda avanzada</div>
        <Button
          type='simple'
          horizontalPadding='0px'
          verticalPadding='0px'
          className='close-advanced-search-button'
          onClick={() => setOpenModal(true)}
        >
          <CloseIcon className='k-ds-m-right-sp-04' />
          Cerrar
        </Button>
      </Space>

      <Space
        className='advanced-filters-form'
        direction={viewport.isMobile ? 'vertical' : 'horizontal'}
        align='end'
        justify='space-around'
      >
        <Space
          direction={
            viewport.isMobile && !viewport.isSmallTablet
              ? 'vertical'
              : 'horizontal'
          }
          className='k-ds-width-full'
          justify='space-between'
        >
          <Space
            direction={
              viewport.isMobile && !viewport.isSmallTablet
                ? 'vertical'
                : 'horizontal'
            }
            className='k-ds-width-full'
            justify='space-between'
          >
            <Space
              direction='vertical'
              align='start'
              className='k-ds-width-full'
            >
              <div className='k-ds-m-top-sp-06'>Fecha de inicio</div>
              <DateInput
                className='payment-filter-input'
                value={startDate}
                max={endDate === '' ? today : endDate}
                onChange={e => setStartDate(e.target.value)}
              />
            </Space>
            <Space
              direction='vertical'
              align='center'
              className='date-to-label'
            >
              <div className='k-ds-m-top-sp-06'>a</div>
            </Space>
            <Space
              direction='vertical'
              align='start'
              className='k-ds-m-right-sp-04 k-ds-width-full'
            >
              <div className='k-ds-m-top-sp-06'>Fecha de fin</div>
              <DateInput
                className='payment-filter-input'
                value={endDate}
                min={startDate}
                max={today}
                onChange={e => setEndDate(e.target.value)}
              />
              {viewport.isMobile && !viewport.isSmallTablet && (
                <div className='search-caption k-ds-m-top-sp-02 k-ds-m-bottom-sp-04'>
                  *El rango máximo es de 31 días naturales, desde las 00:00
                  hasta las 23:59.
                </div>
              )}
            </Space>
          </Space>
          <Space
            direction={
              viewport.isMobile && !viewport.isSmallTablet
                ? 'vertical'
                : 'horizontal'
            }
            className='k-ds-width-full'
            justify='space-around'
          >
            <Space
              direction='vertical'
              align='start'
              className='k-ds-m-right-sp-04 k-ds-width-full'
            >
              <div className='k-ds-m-top-sp-06'>Tipos de pago</div>
              <Select
                className='payment-filter-input type-channel-input k-ds-width-full'
                placeholder='Elige aquí'
                value={paymentType}
                options={paymentOptions}
                onChange={e => setPaymentType(e)}
              />
            </Space>
            <Space
              direction='vertical'
              align='start'
              className='k-ds-m-right-sp-07 k-ds-width-full'
            >
              <div className='k-ds-m-top-sp-06'>Canal</div>
              <Select
                className='payment-filter-input type-channel-input k-ds-width-full'
                placeholder='Elige aquí'
                value={channel}
                options={channelOptions}
                onChange={e => setChannel(e)}
              />
            </Space>
          </Space>
        </Space>

        <Space
          direction='horizontal'
          align={viewport.isMobile ? 'center' : 'end'}
          justify={
            viewport.isMobile && !viewport.isSmallTablet
              ? 'center'
              : 'space-between'
          }
          className={
            viewport.isMobile
              ? 'filter-buttons k-ds-m-top-sp-09 k-ds-width-full'
              : 'filter-buttons'
          }
        >
          {!viewport.isDesktop && viewport.isSmallTablet && (
            <div className='search-caption k-ds-m-top-sp-02'>
              *El rango máximo es de 31 días naturales, desde las 00:00 hasta
              las 23:59.
            </div>
          )}
          <Space
            className={
              viewport.isMobile ? 'k-ds-m-top-sp-08 k-ds-m-bottom-sp-08' : ''
            }
            justify='end'
          >
            <Button
              type='primary-alternate'
              disabled={filterButtonDisabled()}
              onClick={onApply}
            >
              Aplicar
            </Button>
            <Button
              type='simple'
              className='clear-filter-button'
              disabled={filterButtonDisabled()}
              horizontalPadding='1rem'
              onClick={reset}
            >
              Borrar filtros
            </Button>
          </Space>
        </Space>
      </Space>
      <Space>
        <p className='search-caption'>
          {(viewport.isDesktop || viewport.isTablet) &&
            '*El rango máximo es de 31 días naturales, desde las 00:00 hasta las 23:59.'}
        </p>
      </Space>
      <ConfirmModal
        isOpen={openModal}
        mode='Warning'
        header='¿Quieres cerrar la búsqueda avanzada?'
        content='Los filtros se eliminarán.'
        cancelButtonText='No, regresar'
        confirmButtonText='Sí, cerrar'
        onConfirm={onCancelModal}
        setIsOpen={setOpenModal}
      />
    </div>
  );
};

PaymentHistorySearchFilters.propTypes = {
  onChange: propTypes.func,
  filters: propTypes.object,
  onCancel: propTypes.func,
};

export default PaymentHistorySearchFilters;
