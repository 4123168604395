import styled from 'styled-components';
import { ButtonDefaults } from './ButtonDefaults';
import KDS from '../../lib/k-ds-foundation';

const ButtonSimple = styled(ButtonDefaults)`
  ${KDS.setColorWithFallback('background: ', 'transparent')}
  ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}
  box-shadow: none;
  border: 0;

  &:enabled {
    &:hover {
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary-hover')}
    }

    &:active {
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary-hover')}
    }
  }

  &:disabled {
    ${KDS.setColorTokenWithFallback('color: ', 'interactive-alternate')}
  }
`;

export {
  ButtonSimple,
};
