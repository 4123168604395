import React, { useEffect } from 'react';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';

import Auth from '../Utils/Auth/auth';
import { useAuth } from '../Utils/Auth/use-auth';
import { isFeatureEnabled } from '../Utils/Feature/feature_flag';
import { ACCOUNT_MANAGER } from '../Utils/constants';
import AccessDenied from '../components/AccessDenied/AccessDenied';
import { useLoaderContext } from '../contexts/Loader/LoaderContext';
import { usePermissionContext } from '../contexts/Role/PermissionContext';
import { Loader } from '../ds';
import { getUser } from '../mockData';
import Benefits from './pages/Benefits';
import CreateAccount from './pages/CreateAccount';
import Home from './pages/Home/Home';
import Layout from './pages/Layout';
import LoaderBlack from './pages/Layout/loader-black-2x.gif';
import Login from './pages/Login';
import PasswordRecover from './pages/Login/PasswordRecover';
import MerchantHomePage from './pages/MerchantHome/MerchantHomePage';
import NotFoundPage from './pages/NotFound';

const Routes = () => {
  const user = getUser();
  const auth = useAuth();

  const { merchant, employee } = Auth.userData();
  const { isLoaderOpen } = useLoaderContext();

  const {
    setOpenPaymentUpgrade,
    setOpenModalRoot,
    setHotjarIdentified,
    hotjarIdentified,
  } = usePermissionContext();

  const isAccountManager = employee?.role?.name === ACCOUNT_MANAGER;

  const merchantSelected = JSON.parse(
    window.localStorage.getItem('merchantSelected')
  );
  const userLoggedIn = Auth.userLoggedIn();

  useEffect(() => {
    if (auth.user || Auth.userLoggedIn()) {
      if (isFeatureEnabled('hotjar') && !hotjarIdentified) {
        setHotjarIdentified(true);
        const { employee_id, role, full_name } = employee;
        const { merchant_id: storeId, name: storeName, channels } = merchant;

        window.hj('identify', employee_id, {
          first_name: full_name,
          role: role.name,
          company: storeName,
          merchant_id: storeId,
          channels: channels.map(item => item.channel).join(' '),
        });
      }

      if (isAccountManager && merchantSelected === null) {
        setOpenModalRoot(true);
      }

      const cachedData = localStorage.getItem('payment-channels-modal');

      if (!cachedData) {
        setOpenPaymentUpgrade(true);
      }
    }
  }, [
    auth.user,
    employee,
    hotjarIdentified,
    merchant,
    merchantSelected,
    setOpenModalRoot,
    setOpenPaymentUpgrade,
    setHotjarIdentified,
  ]);

  const { menus, routes } = usePermissionContext();
  // if path is not login load permissions
  // if (window.location.pathname !== '/login') {
  //   loadPermissions();
  // }

  return (
    <Router>
      <Loader src={LoaderBlack} isOpen={isLoaderOpen} />
      <Switch>
        <Route
          exact
          path={'/login'}
          render={() =>
            userLoggedIn ? <Redirect to={{ pathname: '/me' }} /> : <Login />
          }
        />
        <Route
          exact
          path={'/benefits'}
          render={() =>
            userLoggedIn ? <Redirect to={{ pathname: '/me' }} /> : <Benefits />
          }
        />
        <Route
          exact
          path={'/create-account'}
          render={() =>
            userLoggedIn ? (
              <Redirect to={{ pathname: '/me' }} />
            ) : (
              <CreateAccount />
            )
          }
        />
        <Route
          exact
          path={'/passwordrecover'}
          render={() =>
            userLoggedIn ? (
              <Redirect to={{ pathname: '/me' }} />
            ) : (
              <PasswordRecover />
            )
          }
        />
        <Route
          exact
          path={'/'}
          render={() =>
            userLoggedIn ? (
              <Redirect to={{ pathname: '/me' }} />
            ) : (
              <Redirect to={{ pathname: '/login' }} />
            )
          }
        />
        <Layout menus={menus} user={user}>
          <Switch>
            <Route
              exact
              path='/home'
              render={() => {
                if (!userLoggedIn)
                  return <Redirect to={{ pathname: '/login' }} />;

                return <Home />;
              }}
            />
            <Route
              exact
              path={'/merchant-panel'}
              render={() => {
                if (!userLoggedIn)
                  return <Redirect to={{ pathname: '/login' }} />;

                return isAccountManager ? (
                  <MerchantHomePage />
                ) : (
                  <AccessDenied />
                );
              }}
            />
            {routes.map(route => {
              return (
                <Route
                  path={route.path}
                  exact={route.exact}
                  key={route.path}
                  render={() => {
                    if (!userLoggedIn)
                      return <Redirect to={{ pathname: '/login' }} />;

                    return route.isAuthorized ? (
                      route.component()
                    ) : (
                      <AccessDenied />
                    );
                  }}
                />
              );
            })}
            <Route
              path='*'
              render={() => {
                return userLoggedIn ? (
                  <NotFoundPage />
                ) : (
                  <Redirect to={{ pathname: '/login' }} />
                );
              }}
            />
          </Switch>
        </Layout>
      </Switch>
    </Router>
  );
};

export default Routes;
