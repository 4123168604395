import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  newAlphaNumericValidation,
  phoneInputCleanFormatted,
  phoneSpaceFormatted,
  validateMaxChars,
  validatePhoneNumber,
} from '../../Utils/formValidations';
import { useGetMerchant } from '../../Utils/useGetMerchant';
import { useLoaderContext } from '../../contexts/Loader/LoaderContext';
import { useToastContext } from '../../contexts/Toast/ToastContext';
import { Button, Tag, TextInput } from '../../ds';
import { Divider } from '../../ds/components/divider';
import {
  createStore,
  getMallList,
  getStoreById,
  updateStore,
} from '../../services/Application';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { Select } from '../DS/Select';
import GetBackSection from '../GetBackSection';
import MapContainer, { geocodeZipcode } from '../Maps/MapsContainer';
import RadioButton from '../RadioButton/RadioButton';
import SectionHeading from '../SectionHeading/SectionHeading';
import ZipCodeForm from '../ZipCodeForm/ZipCodeForm';
import './StoreCreateEditComponent.scss';
import {
  ADDRESS_STORE_FIELDS,
  EDIT_STORE_ID_FIELD,
  MALL_STORE_FIELD,
  NAME_STORE_FIELD,
  NAME_STORE_ID_FIELD,
  PHONE_NUMBER_FIELD,
} from './StoreFields';
import StoreSchedule from './StoreSchedule';
import NotFound from '../NotFound';
import { StoreFieldErrors } from './StoreFieldErrors';
import StoreCreateError from './StoreCreateError';
import { isFeatureEnabled } from '../../Utils/Feature/feature_flag';
import AnalyticsEvent, {
  AnalyticsList,
} from '../../services/analytics/AnalyticsEvent';
import { useMerchantRoles } from '../MutateUser/MutateUser';
import Auth from '../../Utils/Auth/auth';
import { ACCOUNT_MANAGER, GENERAL_ADMIN } from '../../Utils/constants';
import Switch from '../DS/Switch/Switch';
import Warning from './Warning/Warning';
import Error from './Error/Error';

const textFormFieldsSettings = [
  NAME_STORE_FIELD,
  PHONE_NUMBER_FIELD,
  MALL_STORE_FIELD,
  ...ADDRESS_STORE_FIELDS,
  NAME_STORE_ID_FIELD,
];

const externalStoreIdEnabled = isFeatureEnabled('external_store_id');

const messageManager = message => {
  if (
    [
      'Name Error: Each pair of store name and merchant_id should be unique, Address coordinates existing store with same address',
      'Name Error: Each pair of store name and merchant_id should be unique',
    ].includes(message)
  ) {
    return 'El nombre de la sucursal ha sido utilizado anteriormente.';
  }

  if (message === 'Address coordinates existing store with same address') {
    return 'La ubicación de la sucursal ha sido utilizada anteriormente.';
  }

  if (StoreFieldErrors[message]) {
    return StoreFieldErrors[message].toast;
  }

  return 'Tuvimos un error, Intenta nuevamente';
};

const StoreCreateEditComponent = () => {
  const router = useHistory();
  const swicthRef = useRef(null);
  const { setIsLoaderOpen } = useLoaderContext();

  const path = router.location.pathname;
  const isNew = path === '/stores/new';
  const [allowedEdit, setAllowedEdit] = useState(true);
  const storeId = path.split('/')[2];

  const addToast = useToastContext();
  const merchantInfo = useGetMerchant();

  const [saveEnabled, setSaveEnabled] = useState(false);
  const [currentZipCode, setCurrentZipCode] = useState('');
  const [zipCodeInfoValid, setZipCodeInfoValid] = useState(false);
  const [scheduleIsValid, setScheduleIsValid] = useState(false);
  const [saveTouched, setSaveTouched] = useState(false);

  const [storeLoaded, setStoreLoaded] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const [mallList, setMallList] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [centerMap, setCenterMap] = useState(null);
  const [enableStoreId, setEnableStoreId] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [openUnauthorized, setUnauthorized] = useState(false);
  const [isUnauthorizedSave, setIsUnauthorizedSave] = useState(false);

  if (!merchantInfo) {
    return '';
  }

  const { employee } = Auth.userData();

  const merchantId = merchantInfo.merchant_id;
  const role = employee.role;
  const rolesByMerchantId = useMerchantRoles(merchantId);
  const isAMROLE = role.name === ACCOUNT_MANAGER;
  const isTracked = useRef(false);

  const isGeneralAdminOrAMRole = () => {
    const roleByFormData =
      rolesByMerchantId.filter(item => item.role_name === GENERAL_ADMIN)
        .length > 0;

    return roleByFormData || role.name === ACCOUNT_MANAGER;
  };

  const [oldexternalStoreId, setOldexternalStoreId] = useState('');
  const [store, setStore] = useState({
    merchant_id: merchantInfo?.merchant_id,
    name: '',
    store_type: 'physical',
    platform: 'kueskipay',
    timezone: 'Guadalajara',
    active: 'true',
    phone_number: '',
    address_attributes: {
      [MALL_STORE_FIELD.name]: '',
      street: '',
      ext_number: '',
      inner_number: '',
      zip_code: '',
      neighborhood: '',
      city: '',
      state: '',
    },
    store_schedule_attributes: {
      schedule: {},
    },
  });

  useEffect(() => {
    const formIsValid = textFormFieldsSettings.every(field =>
      fieldIsValid(field)
    );
    setSaveEnabled(formIsValid && zipCodeInfoValid);
  }, [formErrors, scheduleIsValid, zipCodeInfoValid]);

  useEffect(() => {
    if (isNew) {
      return;
    }

    getStoreById(storeId)
      .then(response => {
        const store = response.data.store;

        if (
          !store ||
          !store.merchant_id ||
          store.merchant_id !== merchantInfo.merchant_id
        ) {
          setAllowedEdit(false);
          return;
        }

        let coordinates = '';
        if (store?.address_attributes?.coordinates) {
          coordinates = `${store?.address_attributes?.coordinates?.x}, ${store?.address_attributes?.coordinates?.y}`;
        }

        setStore({
          ...store,
          phone_number: phoneInputCleanFormatted(store?.phone_number || ''),
          address_attributes: {
            ...store.address_attributes,
            coordinates: coordinates,
          },
        });
        setOldexternalStoreId(store.external_store_id);
        setCurrentZipCode(store.address_attributes.zip_code);
      })
      .catch(error => {
        AnalyticsEvent().errorTrack({
          errorType: 'store-create-get-store-error',
          errorDescription: error,
        });

        setAllowedEdit(false);
        console.log(error);
      })
      .finally(() => {
        setStoreLoaded(true);
      });
  }, []);

  useEffect(() => {
    if (store.address_attributes.city === '') return;

    let cityToSearch = store.address_attributes.city;

    if (store.address_attributes.state === 'Ciudad de México') {
      cityToSearch = store.address_attributes.state;
    }

    getMallList([cityToSearch]).then(response => {
      setMallList(
        response?.data?.map(mall => {
          return {
            value: mall.mall_id,
            text: mall.name,
            city: mall.city,
          };
        }) ?? []
      );
    });
  }, [store.address_attributes.city]);

  useEffect(() => {
    if (!isTracked.current) {
      AnalyticsEvent().pageView({
        page: isNew ? AnalyticsList.storeNew : AnalyticsList.storeEdit,
      });
      isTracked.current = true;
    }
  }, []);

  const clearCoordinates = () => {
    setStore({
      ...store,
      address_attributes: {
        ...store.address_attributes,
        coordinates: null,
      },
    });
  };

  const onZipCodeFormChange = (zipCodeInfo, valid) => {
    setStore({
      ...store,
      address_attributes: {
        ...store.address_attributes,
        zip_code: zipCodeInfo.zipCode,
        neighborhood: zipCodeInfo.neighborhood,
        city: zipCodeInfo.city,
        state: zipCodeInfo.state,
      },
    });
    setZipCodeInfoValid(valid);

    if (zipCodeInfo.zipCode.length >= 5) {
      if (zipCodeInfo.zipCode !== currentZipCode) {
        clearCoordinates();
        setCurrentZipCode(zipCodeInfo.zipCode);
      }

      geocodeZipcode(
        `${zipCodeInfo.zipCode} ${zipCodeInfo.city} ${zipCodeInfo.state}`
      ).then(response => setCenterMap(response));
    }
  };

  const formChangesHandler = event => {
    formErrorsHandler(event);

    const { name, value } = event.target;

    if (
      ['name', 'status', 'phone_number', 'external_store_id'].includes(name)
    ) {
      setStore({ ...store, [name]: value });
    } else {
      let address_attributes = store.address_attributes;
      address_attributes = { ...address_attributes, [name]: value };
      setStore({ ...store, address_attributes });
    }
  };

  const formErrorsHandler = event => {
    const { name, value, type, required } = event.target;

    if (value.length < 1 && required) {
      if (type === 'text') {
        setFormErrors({
          ...formErrors,
          [name]: 'Este campo no puede quedarse vacío',
        });
      } else {
        setFormErrors({
          ...formErrors,
          [name]: 'Se debe seleccionar una opción',
        });
      }
    } else {
      setFormErrors({ ...formErrors, [name]: '' });
    }
  };

  const handlePhoneBlur = event => {
    const inputValue = event.target.value;
    const formattedInputValue = phoneInputCleanFormatted(inputValue);

    setStore({ ...store, ...{ phone_number: formattedInputValue } });
    handleBlur(event);
  };

  const handleBlur = event => {
    const { name, value = '', required } = event.target;

    if (!required && value === '') {
      return;
    }

    if (required && value === '') {
      formErrorsHandler(event);
      return;
    }

    let error = '';
    switch (name) {
      case 'name': {
        //prev valid name
        error = newAlphaNumericValidation(value)
          ? ''
          : 'El nombre solo debe contener caracteres alfanuméricos, espacios y guiones.';
        break;
      }
      case 'street': {
        error = newAlphaNumericValidation(value)
          ? ''
          : 'Este campo solo debe contener caracteres alfanuméricos, espacios y guiones.';
        break;
      }
      case 'ext_number': {
        error = newAlphaNumericValidation(value)
          ? ''
          : 'Este campo no permite caracteres especiales';
        break;
      }
      case 'inner_number': {
        error = newAlphaNumericValidation(value)
          ? ''
          : 'Este campo no permite caracteres especiales';
        break;
      }

      case 'phone_number': {
        error = validatePhoneNumber(value, 'Deben ser 10 dígitos exactos');
        break;
      }
      case 'external_store_id': {
        error = newAlphaNumericValidation(value)
          ? ''
          : 'Este campo no permite caracteres especiales';
        if (error === '')
          error = validateMaxChars(value, NAME_STORE_ID_FIELD.maxChars);
        break;
      }
    }

    setFormErrors({ ...formErrors, [name]: error });
  };

  const fieldIsValid = setting => {
    const error = formErrors[setting.name];
    if (setting.required) {
      return store[setting.name] !== '' && !error;
    }
    return !error;
  };

  const navigateToStoreDetails = storeId => {
    router.push(`/stores/${storeId}`);
    const contentArea = document.getElementById('content-area');
    contentArea.scrollTop = 0;
  };

  const handleStoreError = error => {
    const response = messageManager(error?.response?.data?.message ?? null);

    setErrorModalOpen(true);

    if (StoreFieldErrors[error?.response?.data?.message]) {
      setFormErrors({
        ...formErrors,
        [StoreFieldErrors[error?.response?.data?.message].field]:
          StoreFieldErrors[error?.response?.data?.message].message,
      });
      if (StoreFieldErrors[error?.response?.data?.message].showModal) {
        setErrorModalOpen(true);
        return;
      }
    }
    addToast(response, 'error');
  };

  const onSubmit = () => {
    setSaveTouched(true);
    if (!scheduleIsValid) {
      return;
    }

    let formattedData = {
      ...store,
      phone_number: phoneSpaceFormatted(store?.phone_number || ''),
    };

    const isUnauthorizedAccess =
      (isNew && !isGeneralAdminOrAMRole()) || (!isNew && !isAMROLE);

    if (formattedData.external_store_id !== oldexternalStoreId) {
      if (isUnauthorizedAccess) {
        setUnauthorized(true);
        setIsUnauthorizedSave(true);
        return;
      }
    }

    setIsLoaderOpen(true);
    if (isNew) {
      createStore(formattedData)
        .then(response => {
          if (response.status === 'success') {
            router.push('/stores/');
            const { store_id } = response.data;
            addToast('La sucursal se ha guardado con éxito!', 'success');
            navigateToStoreDetails(store_id);
          }
        })
        .catch(error => {
          AnalyticsEvent().errorTrack({
            errorType: 'store-create-store-error',
            errorDescription: error,
          });
          handleStoreError(error);
        })
        .finally(() => {
          setIsLoaderOpen(false);
        });
    } else {
      updateStore(formattedData, storeId)
        .then(response => {
          if (response.status === 'success') {
            router.push(`/stores/${storeId}`);
            addToast('¡El cambio se ha guardado con éxito!', 'success');
          }
        })
        .catch(error => {
          AnalyticsEvent().errorTrack({
            errorType: 'store-create-update-error',
            errorDescription: error,
          });
          handleStoreError(error);
        })
        .finally(() => {
          setIsLoaderOpen(false);
        });
    }
  };

  const translateStoreStatus = isActive => {
    return isActive ? 'Activa' : 'Inactiva';
  };

  const onScheduleChange = (schedule, isValid) => {
    setStore({
      ...store,
      store_schedule_attributes: {
        ...store.store_schedule_attributes,
        schedule: schedule,
      },
    });
    setScheduleIsValid(isValid);
  };

  const radioButtonHandler = value => {
    setStore({ ...store, active: value.toString() });
  };

  const handleChnageMall = mallId => {
    setStore({
      ...store,
      address_attributes: {
        ...store.address_attributes,
        mall_id: mallId,
      },
    });
  };

  const handleUpdateCoord = coordinates => {
    setStore({
      ...store,
      address_attributes: {
        ...store.address_attributes,
        coordinates: coordinates?.toString() ?? null,
      },
    });
  };

  if (!isNew && !allowedEdit) {
    return <NotFound />;
  }

  const enabledExternalStoreId =
    (isNew && isGeneralAdminOrAMRole()) || (allowedEdit && enableStoreId);

  return (
    (storeLoaded || isNew) && (
      <>
        <GetBackSection render />
        <header>
          <SectionHeading
            title={isNew ? 'Nueva Sucursal' : 'Editar Sucursal'}
            subtitle={
              isNew
                ? 'Ingresa la información necesaria para crear una sucursal'
                : 'Cambia la información que deseas'
            }
          />
        </header>
        <section
          className='store-create-edit-section'
          data-testid='store-address'
        >
          <SectionHeading title='¿Cuál es el nombre completo de la sucursal?' />
          <div className='address-form-container'>
            <TextInput
              type='outline'
              className='form-item'
              name={NAME_STORE_FIELD.name}
              errorMessage={formErrors[NAME_STORE_FIELD.name]}
              required={NAME_STORE_FIELD.required}
              label={NAME_STORE_FIELD.label}
              placeholder={NAME_STORE_FIELD.label}
              value={store[NAME_STORE_FIELD.name]}
              onChange={e => formChangesHandler(e)}
              onBlur={handleBlur}
            />

            {externalStoreIdEnabled && (
              <div className='store-id'>
                <TextInput
                  type='outline'
                  className='form-item'
                  disabled={!enabledExternalStoreId}
                  name={NAME_STORE_ID_FIELD.name}
                  errorMessage={formErrors[NAME_STORE_ID_FIELD.name]}
                  required={NAME_STORE_ID_FIELD.required}
                  label={NAME_STORE_ID_FIELD.label}
                  placeholder={NAME_STORE_ID_FIELD.placeholder}
                  value={store[NAME_STORE_ID_FIELD.name]}
                  maxChars={NAME_STORE_ID_FIELD.maxChars}
                  onChange={e => formChangesHandler(e)}
                  onBlur={handleBlur}
                />
                {!isNew ? (
                  <Switch
                    ref={swicthRef}
                    onChange={e => {
                      if (e.target.checked) {
                        if (isAMROLE) {
                          setOpenWarning(true);
                          return;
                        }

                        setUnauthorized(true);
                        return;
                      }

                      setEnableStoreId(e.target.checked);
                    }}
                    className='enabled-store-id'
                    name={EDIT_STORE_ID_FIELD.name}
                    label={EDIT_STORE_ID_FIELD.label}
                  />
                ) : null}
              </div>
            )}
          </div>

          <Divider className='divider' />

          <SectionHeading title='Dirección de la Sucursal' />
          <div className='address-form-container'>
            {ADDRESS_STORE_FIELDS.map((field, index) => {
              const { name, required, label } = field;
              return (
                <TextInput
                  key={index}
                  className='form-item'
                  name={name}
                  errorMessage={formErrors[name]}
                  required={required}
                  label={label}
                  type='outline'
                  value={store.address_attributes[name]}
                  onChange={e => formChangesHandler(e)}
                  onBlur={handleBlur}
                />
              );
            })}
            <ZipCodeForm
              onChange={onZipCodeFormChange}
              zipCode={store.address_attributes.zip_code}
              city={store.address_attributes.city}
              state={store.address_attributes.state}
              neighborhood={store.address_attributes.neighborhood}
            />

            <TextInput
              type='outline'
              className='form-item'
              name={PHONE_NUMBER_FIELD.name}
              errorMessage={formErrors[PHONE_NUMBER_FIELD.name]}
              required={PHONE_NUMBER_FIELD.required}
              label={PHONE_NUMBER_FIELD.label}
              placeholder='55 55012 0394'
              value={store[PHONE_NUMBER_FIELD.name]}
              onChange={formChangesHandler}
              onBlur={handlePhoneBlur}
            />
          </div>

          <SectionHeading title='¿En qué centro comercial se encuentra la sucursal?' />
          <div className='address-form-container'>
            <Select
              type='outline'
              className='form-item'
              placeholder='Seleccionar'
              key='mall_selector'
              required={MALL_STORE_FIELD.required}
              value={store.address_attributes.mall_id}
              name={MALL_STORE_FIELD.name}
              label={MALL_STORE_FIELD.label}
              options={mallList}
              onChange={handleChnageMall}
            />
          </div>
        </section>
        <SectionHeading
          title='Localización'
          subtitle='Indica en el mapa la ubicación exacta de la sucursal:'
        />

        <MapContainer
          onUpdateCoords={handleUpdateCoord}
          markerCoord={store?.address_attributes?.coordinates}
          centerMap={centerMap}
        />
        {!isNew && (
          <>
            <section className='store-create-edit-section'>
              <SectionHeading
                title='Estatus de la Sucursal'
                subtitle='Activa la sucursal si se encuentra en funcionamiento o desactívala para
                      deshabilitar todas las funciones y limitar el acceso de los usuarios'
              />
              <div className='status-indicator'>
                <span>Estatus: </span>
                <Tag color={store.active === 'true' ? 'cyan' : 'red'}>
                  {translateStoreStatus(store.active === 'true')}
                </Tag>
              </div>
            </section>
            <section className='store-create-edit-section status-change-section'>
              <h4 className='store-create-edit-h4'>
                Cambiar estatus de la sucursal
              </h4>
              <div className='status-radio-element'>
                <RadioButton
                  name='status'
                  checked={store.active === 'true'}
                  onChange={e => {
                    radioButtonHandler(e.target.checked);
                  }}
                  value='active'
                  label='Activa'
                />
              </div>
              <div className='status-radio-element'>
                <RadioButton
                  name='status'
                  checked={store.active === 'false'}
                  onChange={e => {
                    radioButtonHandler(!e.target.checked);
                  }}
                  value='inactive'
                  label='Inactiva'
                />
              </div>
            </section>
          </>
        )}
        {(store.store_schedule_attributes || isNew) && (
          <>
            <StoreSchedule
              isNew={isNew}
              onChange={onScheduleChange}
              schedule={store.store_schedule_attributes.schedule}
            />
            <div className='table-warning-container'>
              {saveTouched && !scheduleIsValid && (
                <span className='empty-week-warning k-ds-text-color-red-700'>
                  Es necesario que tengas al menos un horario de atención
                  disponible para guardar la sucursal
                </span>
              )}
            </div>
          </>
        )}
        <section className='store-create-edit-section button-section'>
          <Button
            type='primary-alternate'
            onClick={() => onSubmit()}
            disabled={!saveEnabled}
          >
            Guardar
          </Button>
          <Button type='ghost' onClick={() => setIsConfirmModalOpen(true)}>
            Cancelar
          </Button>
        </section>
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          mode={isNew ? 'Cancel' : 'Warning'}
          header={
            isNew
              ? '¿Cancelar la creación de la sucursal?'
              : '¿Cancelar la edición de la sucursal?'
          }
          content='La información ingresada se perderá'
          onConfirm={() => router.goBack()}
          setIsOpen={setIsConfirmModalOpen}
        />
        <StoreCreateError
          header='No se puede crear la sucursal'
          content='Porque el ID de la sucursal ya ha sido usado previamente con otra sucursal, y este dato no se puede repetir.  '
          buttonText='Volver a intentar'
          isOpen={errorModalOpen}
          close={() => setErrorModalOpen(false)}
        />
        <Warning
          title='Editar número de tienda'
          content='Este cambio puede afectar la sincronización de datos en el POS del comercio y generar inconsistencias en las transacciones.'
          textButton='Aceptar'
          onClose={() => {
            setOpenWarning(false);
            setEnableStoreId(false);
            swicthRef.current.checked = false;
          }}
          onClick={() => {
            setOpenWarning(false);
            setEnableStoreId(true);
            swicthRef.current.checked = true;
          }}
          isOpen={openWarning}
        />
        <Error
          title='Acción no permitida'
          content='Solo el Account Manager del comercio puede editar este campo.'
          textButton='Cerrar'
          onClose={() => {
            if (isUnauthorizedSave) {
              setIsUnauthorizedSave(false);
              setErrorModalOpen(false);
            }

            swicthRef.current.checked = false;
            setEnableStoreId(false);
            setUnauthorized(false);
          }}
          isOpen={openUnauthorized}
        />
      </>
    )
  );
};

export default StoreCreateEditComponent;
