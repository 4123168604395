import React from 'react';
import PropTypes from 'prop-types';
import styles from './periodInfo.module.scss';

const PeriodInfo = ({ noResults = 0, shedule }) => {
  const scheduleLabel = shedule ? `del ${shedule}` : '';
  return (
    <div className={styles.period}>
      <div>{`${noResults} resultados `}</div>
      <div className={styles.content}>{scheduleLabel}</div>
    </div>
  );
};

PeriodInfo.propTypes = {
  noResults: PropTypes.number,
  shedule: PropTypes.string,
};

export default PeriodInfo;
