import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDeviceContextViewport } from '../../../contexts/Device/DeviceContext';
import { Modal, Space, Button } from '../../../ds';
import IconError from './icon-error.svg';
import './error.scss';

const Error = ({
  title,
  content,
  textButton,
  isOpen,
  onClose = () => null,
  callback = () => null,
}) => {
  const viewport = useDeviceContextViewport();

  const isMobileOnly = useMemo(
    () => viewport.isMobile && !viewport.isSmallTablet,
    [viewport]
  );

  const handleOnClick = () => {
    callback ? callback() : null;
  };

  return (
    <Modal
      showClose
      className={cn('error', {
        ['mobile-modal']: isMobileOnly,
      })}
      theme='dark'
      cardType='secondary'
      onClose={onClose}
      onOverlayClick={onClose}
      {...{ isOpen: isOpen }}
    >
      <div className='error-body k-ds-width-full'>
        <Space justify='center' direction='vertical' align='center'>
          <img width={80} height={80} src={IconError} alt='error...' />
        </Space>
        <Space className='k-ds-heading-05 k-ds-m-top-sp-06' justify='center'>
          {title}
        </Space>
        <Space className='error-content k-ds-m-top-sp-02' justify='center'>
          {content}
        </Space>
        <Space className='k-ds-m-top-sp-03' justify='center'>
          <Button
            type='primary-alternate'
            className='cta-button k-ds-m-top-sp-06'
            horizontalPadding='34.4px'
            verticalPadding='16px'
            size='large'
            onClick={handleOnClick}
          >
            {textButton}
          </Button>
        </Space>
      </div>
    </Modal>
  );
};

Error.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
  textButton: PropTypes.string,
  callback: PropTypes.func,
};

export default Error;
