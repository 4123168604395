import styled, { css } from 'styled-components';
import React from 'react';
import KDS from '../../lib/k-ds-foundation';

/* eslint-disable */
const StyledAvatar = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${KDS.setColorTokenWithFallback('background: ', 'background-alternate')}

  border-radius: 50%;

  ${(props) => props.size && css`
    min-width: ${props.size};
    min-height: ${props.size};
    max-width: ${props.size};
    max-height: ${props.size};
  `}

  ${(props) => props.textSize && css`
    
    ${KDS.setTypographyWithFallback({
      'font-family': 'font-secondary',
      'font-weight': 'font-medium',
      'letter-spacing': 'tracking-wide',
      'line-height': 'relaxed',
    })}
    font-size: ${props.textSize};
    text-align: center;
    ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}
  `}

  ${(props) => props.customColor && css`
    background: ${props.customColor};
  `}

  ${(props) => props.svgSize && css`
    > svg {
      width: ${props.svgSize};
      height: ${props.svgSize};
    }
  `}
`;
/* eslint-enabled */

const StyledImage = styled.img`
  border-radius: 50%;

  ${(props) => props.size && css`
    min-width: ${props.size};
    min-height: ${props.size};
    max-width: ${props.size};
    max-height: ${props.size};
  `}
`;

const defaultSize = 'large';

const MAP_COMPONENT_SIZE = {
  'x-small': '16px',
  'small': '24px',
  'medium': '32px',
  'large': '48px',
  'x-large': '64px',
  '2x-large': '96px',
};

function getComponentSize(size) {
  return MAP_COMPONENT_SIZE[size] || MAP_COMPONENT_SIZE[defaultSize];
}

const MAP_TEXT_SIZE = {
  'x-small': '8px',
  'small': '10px',
  'medium': '12px',
  'large': '16px',
  'x-large': '20px',
  '2x-large': '36px',
};

function getTextSize(size) {
  return MAP_TEXT_SIZE[size] || MAP_TEXT_SIZE[defaultSize];
}

const MAP_SVG_SIZE = {
  'x-small': '14px',
  'small': '20px',
  'medium': '28px',
  'large': '44px',
  'x-large': '60px',
  '2x-large': '90px',
};

function getSVGSize(size) {
  return MAP_SVG_SIZE[size] || MAP_SVG_SIZE[defaultSize];
}

function DefaultAvatar(props, size) {
  let avatarProps = props;
  if (size) {
    avatarProps = { ...props, textSize: getTextSize(size) };
  }
  return (<StyledAvatar {...avatarProps}>user</StyledAvatar>);
}

const Avatar = ({
  className, children, size, type, icon, src, initials, ...moreProps
}) => {
  let props = { className, ...moreProps, size: getComponentSize(size) };

  let AvatarComponent;
  switch (type) {
    case 'default':
      props = { ...props, svgSize: getSVGSize(size) };
      AvatarComponent = icon
        ? <StyledAvatar {...props}>{icon}</StyledAvatar>
        : DefaultAvatar(props);
      break;
    case 'image':
      AvatarComponent = src
        ? <StyledImage src={src} {...props} />
        : DefaultAvatar(props, size);
      break;
    case 'initials':
      props = { ...props, textSize: getTextSize(size) };
      AvatarComponent = initials
        ? <StyledAvatar {...props}>{initials}</StyledAvatar>
        : DefaultAvatar(props);
      break;
    default:
      AvatarComponent = DefaultAvatar(props, size);
      break;
  }
  return AvatarComponent;
};

export {
  Avatar,
};
