import styled, { css } from 'styled-components';
import React from 'react';
import { IconCross } from './icon-cross';
import KDS from '../../lib/k-ds-foundation';

const PRESET_COLORS = {
  'red': {
    background: 'red-100',
    text: 'red-900',
  },
  'red-outline': {
    background: 'transparent',
    text: 'red-900',
    border: 'red-900',
  },
  'blue': {
    background: 'blue-100',
    text: 'blue-700',
  },
  'blue-outline': {
    background: 'transparent',
    text: 'blue-700',
    border: 'blue-700',
  },
  'purple': {
    background: 'deep-purple-100',
    text: 'deep-purple-700',
  },
  'purple-outline': {
    background: 'transparent',
    text: 'purple-700',
    border: 'purple-700',
  },
  'teal': {
    background: 'teal-100',
    text: 'teal-700',
  },
  'teal-outline': {
    background: 'transparent',
    text: 'teal-700',
    border: 'teal-700',
  },
  'green': {
    background: 'light-green-100',
    text: 'light-green-900',
  },
  'green-outline': {
    background: 'transparent',
    text: 'light-green-900',
    border: 'light-green-900',
  },
  'cyan': {
    background: 'cyan-100',
    text: 'cyan-700',
  },
  'cyan-outline': {
    background: 'transparent',
    text: 'cyan-700',
    border: 'cyan-700',
  },
  'grey': {
    background: 'grey-300',
    text: 'grey-900',
  },
  'grey-outline': {
    background: 'transparent',
    text: 'grey-900',
    border: 'grey-900',
  },
  'orange': {
    background: 'orange-100',
    text: 'amber-900',
  },
  'orange-outline': {
    background: 'transparent',
    text: 'amber-900',
    border: 'amber-900',
  },
};

function getColors(color) {
  if (!color) return {};
  const colors = PRESET_COLORS[color];
  return colors || {};
}

const StyledTag = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${(props) => props.isDefault && css`
    ${KDS.setColorTokenWithFallback('background: ', 'background-alternate')}
  `}
  ${(props) => props.colors?.background && css`
    ${KDS.setColorWithFallback('background:', props.colors.background)}
  `}

  ${(props) => props.isDefault && css`
    ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}
  `}
  ${(props) => props.colors?.text && css`
    ${KDS.setColorWithFallback('color:', props.colors.text)}
  `}

  ${KDS.setSpacingWithFallback('padding: ', 'spacing-02', 'spacing-03')};

  border-radius: 8px;
  border: 0;

  ${(props) => props.colors?.border && css`
     ${KDS.setColorWithFallback('border: 1px solid ', props.colors.border)}
  `}

  &:enabled {
    &:focus {
      ${(props) => props.isDefault && css`
        ${KDS.setColorTokenWithFallback('border: 1px solid ', 'inverse-background')}
      `}
    }
  }

  &:disabled {
    opacity: 0.5;
  }
`;

const Tag = ({
  className, children, type, color, ...moreProps
}) => {
  const tagClassName = className ? `${className} k-ds-label-01` : 'k-ds-label-01';
  const colors = getColors(color);
  const isDefault = !(color) || (Object.keys(colors).length === 0);
  let props = { ...moreProps, isDefault };

  if (!isDefault) {
    props = { ...moreProps, colors };
  }

  const showCrossIcon = (type === 'filtered');

  return (
    <StyledTag className={tagClassName} {...props}>
      {children}
      {
        showCrossIcon ? <IconCross className="k-ds-m-left-sp-04" /> : null
      }
    </StyledTag>
  );
};

export {
  Tag,
};
