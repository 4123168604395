/* eslint-disable no-unused-vars */
import React from 'react';
import { saveLogbehavior } from '../../services/Application';
import { getUser } from '../../mockData';
import PropTypes from 'prop-types';
import ErrorModal from '../ErrorModal/ErrorModal';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      isClosed: false,
      error: {},
    };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, isClosed: true };
  }

  componentDidCatch(error, info) {
    this.setState({ error: { ...error, ...info } });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      const Error = (
        <textarea
          id='story'
          name='story'
          rows='5'
          cols='33'
          value={`${JSON.stringify(this.state.error)}}`}
        />
      );

      return (
        <ErrorModal
          defaultOpened={this.state.isClosed}
          close={() => {
            this.setState({ isClosed: false });
          }}
          heading='Error desconocido'
          description={Error}
        />
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  fallback: PropTypes.any,
  children: PropTypes.any,
  history: PropTypes.any,
};

export default ErrorBoundary;
