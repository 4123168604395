const initLogBehavior = userLogBehavior => {
  /*eslint-disable */
  'use strict';
  var timeStampZero = Date.now();
  var queue = [];

  const logBehavior = function (str) {
    if (!userLogBehavior) {
      return queue.push(str);
    }
    userLogBehavior.current = userLogBehavior.current + str;
  };

  var docReady = function (fn) {
    if ('function' !== typeof fn) {
      return;
    }
    if (document.readyState === 'complete') {
      return fn();
    }
    document.addEventListener('DOMContentLoaded', fn, false);
  };

  docReady(() => {
    let outString = '';
    function isPassword(event) {
      return event.target.type === 'password';
    }
    function outToInput() {
      const nextTimeStamp = Date.now() - timeStampZero;
      outString += `@${nextTimeStamp}`;
      outString += '.';
      logBehavior(outString);
      outString = '';
    }
    function sanitizeName(name) {
      if (typeof name?.replaceAll === 'function') {
        return name.replaceAll('.', '_');
      }
      return name;
    }
    function getRecursiveTargetId(target) {
      let out = '';
      if (target.id !== '') {
        out += sanitizeName(target.id);
      } else if (target.className !== '') {
        out += sanitizeName(target.className);
      } else if (target.parentNode != null) {
        out += sanitizeName(getRecursiveTargetId(target.parentNode));
      }
      return out;
    }
    function logTarget(event) {
      outString += `$${event.target.tagName}`;
      outString += '&';
      outString += getRecursiveTargetId(event.target);
      outToInput();
    }
    function onKeydown(event) {
      outString += 'kd';
      if (isPassword(event)) {
        outString += '$*';
      } else {
        outString += `$${event.which}`;
      }
      outToInput();
    }
    function onKeyup(event) {
      outString += 'ku';
      if (isPassword(event)) {
        outString += '$*';
      } else {
        outString += `$${event.which}`;
      }
      outToInput();
    }
    function onFocusout(event) {
      outString += 'fo';
      logTarget(event);
    }
    function onFocusin(event) {
      outString += 'fi';
      logTarget(event);
    }
    function onBlur() {
      outString += 'fo';
      outString += '$Win';
      outToInput();
    }
    function onFocus() {
      outString += 'fi';
      outString += '$Win';
      outToInput();
    }
    function onMousemove() {
      // Empty for the moment
    }
    function onMouseover(event) {
      outString += 'mi';
      logTarget(event);
    }
    function onMouseout(event) {
      outString += 'mo';
      logTarget(event);
    }
    function onMousedown(event) {
      outString += 'md';
      logTarget(event);
    }
    function onMouseup(event) {
      outString += 'mu';
      logTarget(event);
    }
    function addEvent(element, eventName, callback) {
      if (element.addEventListener) {
        element.addEventListener(eventName, callback, false);
      } else if (element.attachEvent) {
        element.attachEvent(`on${eventName}`, callback);
      }
    }

    addEvent(document, 'keydown', onKeydown);
    addEvent(document, 'keyup', onKeyup);
    addEvent(document, 'focusout', onFocusout);
    addEvent(document, 'focusin', onFocusin);
    addEvent(window, 'blur', onBlur);
    addEvent(window, 'focus', onFocus);
    addEvent(document, 'mousemove', onMousemove);
    addEvent(document, 'mouseover', onMouseover);
    addEvent(document, 'mouseout', onMouseout);
    addEvent(document, 'mousedown', onMousedown);
    addEvent(document, 'mouseup', onMouseup);
  });
};

export default initLogBehavior;
