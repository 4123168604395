import React, { useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import { useProvideAuth } from './useProvideAuth';

const authContext = createContext();
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

ProvideAuth.propTypes = {
  children: PropTypes.object.isRequired,
};

export const useAuth = () => {
  return useContext(authContext);
};
