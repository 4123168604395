import PropTypes from 'prop-types';
import React, { memo, useContext, useEffect, useMemo } from 'react';
import { filterRoles } from '../../Utils/filterRoles';
import { Space, TextInput } from '../../ds';
import { Select } from '../DS/Select';
import { MutateUserContext, useMerchantRoles } from './MutateUser';

import './mutateUser.scss';

const RoleSection = props => {
  const { merchantId, formData, formDataErrors, setFormData, role } = props;

  const { setRoles } = useContext(MutateUserContext);
  const rolesContent = useMerchantRoles(merchantId);

  useEffect(() => {
    setRoles(rolesContent);
  }, [rolesContent]);

  const merchantRoles = useMemo(
    () => filterRoles(rolesContent, role, formData.role),
    [rolesContent, role, formData.role]
  );

  const setValue = (props, val) => {
    setFormData({ ...props.formData, ...val });
  };

  const onChangeCodeValue = evt => {
    const value = evt.target.value;
    setFormData({ ...formData, ...{ userCode: value } });
  };

  return (
    <div>
      <div className='k-ds-heading-04 k-ds-m-top-sp-09 k-ds-m-bottom-sp-07'>
        ¿Cuál es el rol del usuario en el portal?
      </div>
      <Space className='field-container'>
        <Select
          required
          placeholder='Seleccionar'
          label='Seleccionar rol del usuario:'
          className='text-field'
          value={formData.role}
          onChange={v => setValue(props, { role: v })}
          id='role'
          name='role'
          options={merchantRoles}
        />

        <TextInput
          placeholder='Escribe aquí'
          label='Código o número de empleado único:'
          helper='Este código será el identificador de cada empleado.'
          className='text-top-0 text-field '
          value={formData.userCode}
          onBlur={onChangeCodeValue}
          onChange={onChangeCodeValue}
          id='employee_code'
          name='employee_code'
          errorMessage={formDataErrors.userCode}
        />
      </Space>
    </div>
  );
};

RoleSection.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  merchantId: PropTypes.string.isRequired,
  formDataErrors: PropTypes.object.isRequired,
  setFormDataErrors: PropTypes.func.isRequired,
  role: PropTypes.object.isRequired,
};

const RoleComponent = memo(RoleSection);
export default RoleComponent;
