import styles from './Home.module.scss';
import PropTypes from 'prop-types';
import React from 'react';

const Section = ({ title, hint, children = null, justifyContent }) => {
  let gridStyles = {};
  if (justifyContent) {
    gridStyles = {
      justifyContent: justifyContent,
    };
  }

  return (
    <div className={styles.section}>
      <div className={styles.header}>
        <div className={styles.sectionTitle}>{title}</div>
        <div className={styles.regularText}>{hint}</div>
      </div>
      {children && (
        <div className={styles.rowGrid} style={gridStyles}>
          {children}
        </div>
      )}
    </div>
  );
};

Section.propTypes = {
  title: PropTypes.string.isRequired,
  hint: PropTypes.string.isRequired,
  children: PropTypes.node,
  justifyContent: PropTypes.string,
};

export default Section;
