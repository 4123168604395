import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '../../../../ds';
import { ReactComponent as BackArrow } from './../res/back-arrow.svg';
import { ReactComponent as KueskiIcon } from './../res/kueski-icon.svg';
import { ReactComponent as PrincipalBanner } from './../res/principal-banner.svg';
import styles from './Layout.module.scss';

const Layout = ({ prevUrl = '/benefits', children, hideArrowBack = false }) => {
  return (
    <div className={styles.container}>
      <div
        className={styles.header}
        style={{ flexDirection: hideArrowBack ? 'row-reverse' : '' }}
      >
        {!hideArrowBack && (
          <a className={styles.backbtn} rel='noreferrer' href={prevUrl}>
            <Button type='simple' size='small'>
              <BackArrow className={styles.customArrow} />
              <span>Regresar</span>
            </Button>
          </a>
        )}

        <KueskiIcon className={styles.customSvg} />
      </div>

      <div className={styles.wrapper}>
        <div className={styles.leftSide}>{children}</div>
        <div className={styles.rightSide}>
          <PrincipalBanner />
        </div>
      </div>
    </div>
  );
};

Layout.propTypes = {
  prevUrl: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  hideArrowBack: PropTypes.bool,
};

export default Layout;
