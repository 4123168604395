import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Space } from '../../../ds';
import PhoneInputOutline from '../../DS/PhoneInput/PhoneInputOutline';
import CodeInput from '../CodeInput';
import './oneTimePasscodeVerification.scss';
import { useForm, useController } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import OneTimePasscodeVerificationSchema from './OneTimePasscodeVerificationSchema';
import { validateOffering } from '../../../services/Application';
import Error from './../Error/Error';
import { goPage } from '../../../Utils/pages';
import { useHistory } from 'react-router-dom';
import { MAX_OTP_LENGTH } from '../../../Utils/constants';
import { useGetMerchant } from '../../../Utils/useGetMerchant';
import ButtonActions from '../ButtonActions';
import AnalyticsEvent from '../../../services/analytics/AnalyticsEvent';

const initialOTPToken = {
  phoneNumber: '',
  token: '',
};

const OneTimePasscodeVerification = ({
  paymentId,
  hasVerdict,
  orderGeneratedService,
  setIsTransactionInProgress,
  cancelOrder,
}) => {
  const history = useHistory();
  const [isOpenError, setIsOpenError] = useState(false);

  const [errorTitle, setErrorTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const merchant = useGetMerchant();

  const {
    handleSubmit,
    control,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: initialOTPToken,
    reValidateMode: 'onChange',
    mode: 'all',
    resolver: zodResolver(OneTimePasscodeVerificationSchema),
  });

  const phoneController = useController({ name: 'phoneNumber', control });
  const tokeController = useController({ name: 'token', control });

  const onProcessOrder = async formData => {
    setIsTransactionInProgress(true);

    validateOffering({
      merchant_id: merchant.merchant_id,
      merchant_rfc: merchant.merchant_rfc,
      amount: Number(orderGeneratedService.total),
      payment_id: paymentId,
      phone_number: formData.phoneNumber,
      otp: formData.token,
    }).catch(error => {
      const errorCode = error.response?.data?.code ?? error.data?.code;
      AnalyticsEvent().errorTrack({
        errorType: 'one-time-passcode-verify-offering-error',
        errorDescription: errorCode,
      });
      switch (errorCode) {
        case '231003':
        case '231005':
          setErrorTitle('Verificación fallida');
          setErrorMessage(
            'Los datos no coinciden. Por favor, pide al cliente\n' +
              'que los verifique e ingresa los correctos.'
          );
          break;
        default:
          setErrorTitle('No pudimos completar la transacción');
          setErrorMessage(
            'Tuvimos problemas de comunicación con el servicio. Puedes solicitar al cliente que lo intente de nuevo.'
          );
          break;
      }
      setIsOpenError(true);
      setIsTransactionInProgress(false);
    });
  };

  return (
    <>
      <Card className='k-ds-width-full one-time-passcode-verification'>
        <div className='k-ds-heading-02'>
          El cliente está pagando sin conexión a internet
        </div>
        <div className='k-ds-subheading-02'>
          Solicita al cliente los siguientes datos y complétalos a continuación:
        </div>
        <div className='k-ds-m-top-sp-06'>
          <form>
            <div className='form-wrapper'>
              <PhoneInputOutline
                id='phoneNumber'
                className='k-ds-m-bottom-sp-06 phone-input-outline'
                helper='Solo números.'
                label='Número de celular'
                placeholder='Ingresa 10 dígitos'
                onChange={value => phoneController.field.onChange(value)}
                onBlur={() => {
                  trigger('phoneNumber');
                }}
                type='outline'
                errorMessage={
                  errors && errors.phoneNumber ? errors.phoneNumber.message : ''
                }
                required
                autoFocus
              />
              <div className='opt-code-input-v2'>
                <Space
                  size='small'
                  justify='space-between'
                  className='k-ds-width-full'
                  direction='horizontal'
                >
                  <label className='k-ds-body-01 k-ds-text-color-primary'>
                    Código de verificación{' '}
                    <span className='k-ds-text-color-error'>{' *'}</span>
                  </label>
                </Space>
                <CodeInput
                  justify='space-between'
                  length={6}
                  onChangeCode={value => {
                    value && value.length === MAX_OTP_LENGTH
                      ? tokeController.field.onChange(value)
                      : null;
                  }}
                  onBlurForm={value => {
                    value && value.length < MAX_OTP_LENGTH
                      ? tokeController.field.onChange(value)
                      : null;
                  }}
                  hasError={Boolean(
                    errors &&
                      errors.token &&
                      Object.keys(errors.token).length > 0
                  )}
                  validCode={false}
                />
                <div>
                  {errors && errors.token && errors.token.message ? (
                    <span className='k-ds-text-color-error k-ds-label-01 '>
                      {errors.token.message}
                    </span>
                  ) : (
                    <span className='k-ds-text-color-grey-700 k-ds-label-01 '>
                      Solo letras (A-Z) y números (0-9)
                    </span>
                  )}
                </div>
              </div>
            </div>
            <ButtonActions
              className='action-container'
              continueAction={handleSubmit(onProcessOrder)}
              cancelAction={cancelOrder}
              disableContinueAction={!isValid}
            />
          </form>
        </div>
      </Card>
      <Error
        isOpen={isOpenError}
        onClose={() => {
          if (!hasVerdict) {
            cancelOrder();
            return;
          }
          goPage('/orders/create-by-qr', history);
        }}
        title={errorTitle}
        content={errorMessage}
        textButton={hasVerdict ? 'Salir' : 'Volver a intentar'}
        callback={() => {
          if (hasVerdict) {
            goPage('/orders/create-by-qr', history);
            return;
          }
          setIsOpenError(false);
        }}
      />
    </>
  );
};

OneTimePasscodeVerification.propTypes = {
  orderGeneratedService: PropTypes.object,
  orderId: PropTypes.string.isRequired,
  selectedStoreId: PropTypes.string.isRequired,
  paymentId: PropTypes.string.isRequired,
  hasVerdict: PropTypes.bool,
  setIsTransactionInProgress: PropTypes.func,
  cancelOrder: PropTypes.func.isRequired,
};

export default OneTimePasscodeVerification;
