import React, { useEffect, useState } from 'react';
import { getStage } from '../../Utils/environment';
import { createConsumer } from '@rails/actioncable';
import TransactionProgress from './TransactionProgress/TransactionProgress';
import SuccessTransaction from './SuccessTransaction/SuccessTransaction';
import Error from './Error/Error';
import { goPage } from '../../Utils/pages';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const PAYMENT_CHANNEL_NAME = 'PaymentChannel';

const OrderStatusHandler = ({
  orderGeneratedService,
  selectedStoreId,
  orderId,
  cancelOrder,
  transactionOnProgress = false,
  setTransactionProgress,
}) => {
  const [hasVerdict, setHasVerdict] = useState(false);

  const [isOpenError, setIsOpenError] = useState(false);
  const [errorTitle, setErrorTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successTransactionOpen, setSuccessTransactionOpen] = useState(false);

  const history = useHistory();

  const paymentId = orderGeneratedService.paymentId;

  useEffect(() => {
    const { status } = orderGeneratedService;
    if (status && status !== 'PENDIENTE') {
      setHasVerdict(true);
      setErrorTitle('Transacción procesada');
      setErrorMessage('Esta transacción ya fue procesada.');
      setIsOpenError(true);
    }
  }, [orderGeneratedService]);

  useEffect(() => {
    if (!paymentId) return;
    const stage = getStage();
    let host = process.env.REACT_APP_API_PRODUCTION;
    if (stage === 'development') {
      host = process.env.REACT_APP_API_STAGING;
    }
    host = `${host}/api/payment_channel`;

    const cable = createConsumer(host);
    const channel = cable.subscriptions.create(
      {
        channel: PAYMENT_CHANNEL_NAME,
        payment_id: paymentId,
      },
      {
        connected: () => {
          console.log('Connected to the channel');
        },
        disconnected: () => {
          console.log('Disconnected from the channel');
        },
        rejected: () => {
          console.log('Rejected from the channel');
        },
        received(data) {
          console.log('Received data');
          onVerdict(data.verdict);
        },
      }
    );

    return () => {
      cable.subscriptions.remove(channel);
      cable.disconnect();
    };
  }, [paymentId]);

  const onVerdict = verdict => {
    setHasVerdict(true);
    switch (verdict) {
      case 'approved':
        setTransactionProgress(false);
        setIsOpenError(false);
        setSuccessTransactionOpen(true);
        break;
      case 'expired':
        setErrorTitle('Tiempo expirado');
        setErrorMessage(
          'La transacción ha excedido el tiempo límite permitido.'
        );
        setIsOpenError(true);
        break;
      case 'canceled':
        setErrorTitle('Transacción cancelada');
        setErrorMessage('El cliente canceló la compra desde su celular.');
        setIsOpenError(true);
        break;
      default:
        setErrorTitle('Transacción no aprobada');
        setErrorMessage(
          'El cliente debe consultar su cuenta de Kueski Pay para más detalles.'
        );
        setIsOpenError(true);
        break;
    }
  };

  const closeSuccessModal = () => {
    goPage('/orders/create-by-qr', history);
    setSuccessTransactionOpen(false);
  };

  return (
    <>
      <TransactionProgress
        isOpen={transactionOnProgress}
        onClose={() => setTransactionProgress(false)}
        header='Transacción en curso'
        content='Espera un momento'
      />
      <SuccessTransaction
        isOpen={successTransactionOpen}
        onClose={() => closeSuccessModal()}
        totalFormatted={orderGeneratedService.totalFormatted}
        itemsCount={orderGeneratedService.items_count}
        orderId={orderId}
        selectedStoreId={selectedStoreId}
        paymentId={paymentId}
      />
      <Error
        isOpen={isOpenError}
        onClose={() => {
          if (!hasVerdict) {
            cancelOrder().then(() => setTransactionProgress(false));
            return;
          }
          goPage('/orders/create-by-qr', history);
        }}
        title={errorTitle}
        content={errorMessage}
        textButton={hasVerdict ? 'Salir' : 'Volver a intentar'}
        callback={() => {
          if (hasVerdict) {
            goPage('/orders/create-by-qr', history);
            return;
          }
          setIsOpenError(false);
        }}
      />
    </>
  );
};

OrderStatusHandler.propTypes = {
  orderGeneratedService: PropTypes.object.isRequired,
  selectedStoreId: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  cancelOrder: PropTypes.func.isRequired,
  transactionOnProgress: PropTypes.bool,
  setTransactionProgress: PropTypes.func,
};

export default OrderStatusHandler;
