import React, { useState, useEffect } from 'react';
import { SearchBar } from '../../../DS/SearchBar';
import PropTypes from 'prop-types';
import styles from './searchReport.module.scss';

const REGEX_FIELD = /^[\d\w\W]+$/;

const SearchReport = ({ onSearch, value = '' }) => {
  const [searchState, setSearchState] = useState(value);
  const [errorSearch, setErrorSearch] = useState(false);

  const handleClean = () => {
    setSearchState('');
    onSearch('');
  };

  useEffect(() => {
    if (value.length === 0) {
      setSearchState('');
      return;
    }

    const isValid = value.length > 0 && REGEX_FIELD.test(value);
    setErrorSearch(!isValid);
    setSearchState(value);
  }, [value]);

  const handleChangeInput = value => {
    if (value.length === 0) {
      setSearchState('');
      onSearch('');
      return;
    }

    const isValid = value.length > 0 && REGEX_FIELD.test(value);
    setErrorSearch(!isValid);
    setSearchState(value);
  };

  const handleSearch = () => {
    const isValid = searchState.length > 0 && REGEX_FIELD.test(searchState);
    setErrorSearch(!isValid);

    if (isValid) {
      onSearch(searchState);
    }
  };

  return (
    <SearchBar
      placeholder='Buscar por ID de transacción o referencia'
      className={styles.search}
      search={searchState}
      setSearch={handleChangeInput}
      onSearch={handleSearch}
      clearSearch={handleClean}
      minSearch={3}
      disabledButton={errorSearch}
      errorMessage={errorSearch ? 'Caractereres invalido.' : ''}
    />
  );
};

SearchReport.propTypes = {
  onSearch: PropTypes.func.isRequired,
  value: PropTypes.any,
};

export default SearchReport;
