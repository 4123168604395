import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Auth from '../../Utils/Auth/auth';
import {
  validateErrorMessagesFromObject,
  validateIfFormIsEmpty,
  validateStrongPassword,
} from '../../Utils/formValidations';
import { useLoaderContext } from '../../contexts/Loader/LoaderContext';
import { Button, Modal, PasswordInput, Space } from '../../ds';
import {
  getMerchantUser,
  updateMerchantUser,
} from '../../services/Application';
import GetBackSection from '../GetBackSection';
import Header from '../Header';
import './changeUserPassword.scss';
import AnalyticsEvent from '../../services/analytics/AnalyticsEvent';

const defaultFormData = {
  password: '',
  repeatPassword: '',
};

function ChangeUserPassword() {
  const { employee } = Auth.userData();
  const { setIsLoaderOpen } = useLoaderContext();
  const [openModal, setOpenModal] = React.useState(false);
  const [user, setUser] = React.useState(null);

  const fetchLoggerUserData = () => {
    setIsLoaderOpen(true);
    getMerchantUser(employee?.employee_id.toString())
      .then(response => {
        const { data } = response;
        setUser({
          merchant_user_id: data?.user.merchant_user_id,
          first_name: data?.user.first_name,
          last_name: data?.user.last_name,
          maiden_name: data?.user.maiden_name,
          user_code: data?.user.user_code,
          email: data?.user.email,
          status: data?.user.status,
          stores: data?.user.stores.map(store => store.store_id),
          role_id: data?.user.role_id,
          merchant_id: data?.user.merchant_id,
          password: '',
          full_name: employee?.full_name,
          role_name: employee?.role,
        });
      })
      .catch(err => {
        AnalyticsEvent().errorTrack({
          errorType: 'change-user-password-get-merchant-user-error',
          errorDescription: err,
        });
        console.log(err);
      })
      .finally(() => setIsLoaderOpen(false));
  };

  React.useState(() => {
    fetchLoggerUserData();
  }, []);

  return (
    <>
      <GetBackSection render redirectUri='/me' />
      <Header
        title='Cambiar contraseña'
        subtitle='Actualiza o cambia tu contraseña.'
      />
      <PasswordChangeForm
        setOpenModal={setOpenModal}
        user={user}
        setUser={setUser}
      />
      <WarningModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        user={user}
      />
    </>
  );
}

function WarningModal(props) {
  const router = useHistory();
  const onCloseModal = () => {
    props.setOpenModal(false);
    router.goBack();
  };

  return (
    <Modal
      theme='dark'
      cardType='secondary'
      isOpen={props.openModal}
      onOverlayClick={onCloseModal}
      className='warning-modal-user'
    >
      <Space
        justify='start'
        direction='vertical'
        align='start'
        className='k-ds-m-horizontal-sp-06'
      >
        <div className='k-ds-heading-05 k-ds-m-bottom-sp-06 k-ds-m-top-sp-04'>
          Contraseña actualizada
        </div>
        <div
          className='k-ds-body-02 k-ds-m-bottom-sp-07'
          id='modal-email-paragraph'
        >
          Hemos enviado un correo a {props.user?.email} para confirmar el cambio
          de tu contraseña.
        </div>
        <Space direction='horizontal' className='k-ds-m-bottom-sp-06'>
          <Button horizontalPadding='3rem' onClick={onCloseModal}>
            Aceptar
          </Button>
        </Space>
      </Space>
    </Modal>
  );
}

WarningModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  user: PropTypes.object,
};

function validatePassword(val) {
  return !validateStrongPassword(val)
    ? 'La contraseña debe cumplir las medidas de seguridad'
    : '';
}

function validateRepeatPassword(password, val) {
  return !(password === val) ? 'Las contraseñas deben coincidir' : '';
}

function validateDataOnChange(
  formData,
  setFormData,
  formDataErrors,
  setFormDataErrors,
  field,
  val
) {
  setFormData({ ...formData, ...val });
  switch (field) {
    case 'password':
      setFormDataErrors({
        ...formDataErrors,
        ...{ password: validatePassword(val.password) },
      });
      break;
    case 'repeat_password':
      setFormDataErrors({
        ...formDataErrors,
        ...{
          repeatPassword: validateRepeatPassword(
            formData.password,
            val.repeatPassword
          ),
        },
      });
      break;
  }
}

function PasswordChangeForm(props) {
  const router = useHistory();
  const { setIsLoaderOpen } = useLoaderContext();
  const [formData, setFormData] = React.useState(defaultFormData);
  const [formDataErrors, setFormDataErrors] = React.useState(defaultFormData);

  const onChangePassword = () => {
    if (!validateIfFormIsEmpty(formData, 0)) {
      setFormDataErrors({
        ...formDataErrors,
        ...{ password: 'La contraseña es requerida' },
      });
      return;
    } else if (validateErrorMessagesFromObject(formDataErrors)) {
      return;
    }

    setIsLoaderOpen(true);
    let user = props.user;
    user = { ...props.user, ...{ password: formData.password } };
    updateMerchantUser(user.merchant_user_id, user)
      .then(() => {
        props.setOpenModal(true);
      })
      .catch(err => {
        AnalyticsEvent().errorTrack({
          errorType: 'change-user-password-update-merchant-user-error',
          errorDescription: err,
        });
        console.log(err?.message);
      })
      .finally(() => setIsLoaderOpen(false));
  };

  return (
    <Space direction='vertical'>
      <PasswordInput
        required
        label='Contraseña nueva'
        placeholder='Crea tu nueva contraseña'
        className='change-password-field k-ds-m-bottom-sp-06'
        value={formData.password}
        onChange={e =>
          validateDataOnChange(
            formData,
            setFormData,
            formDataErrors,
            setFormDataErrors,
            e.target.name,
            { password: e.target.value }
          )
        }
        onBlur={e =>
          validateDataOnChange(
            formData,
            setFormData,
            formDataErrors,
            setFormDataErrors,
            e.target.name,
            { password: e.target.value }
          )
        }
        id='password'
        name='password'
        errorMessage={formDataErrors.password}
      />
      <PasswordInput
        required
        label='Confirmar contraseña nueva'
        value={formData.repeatPassword}
        className='change-password-field k-ds-m-bottom-sp-06'
        placeholder='Repite la nueva contraseña'
        onChange={e =>
          validateDataOnChange(
            formData,
            setFormData,
            formDataErrors,
            setFormDataErrors,
            e.target.name,
            { repeatPassword: e.target.value }
          )
        }
        onBlur={e =>
          validateDataOnChange(
            formData,
            setFormData,
            formDataErrors,
            setFormDataErrors,
            e.target.name,
            { repeatPassword: e.target.value }
          )
        }
        id='repeat_password'
        name='repeat_password'
        errorMessage={formDataErrors.repeatPassword}
      />
      <Space direction='vertical' className='k-ds-body-01 k-ds-m-bottom-sp-07'>
        <div>Tu contraseña deberá tener al menos:</div>
        <ul>
          <li>1 caracter especial, por ejemplo: !@#$%^&*</li>
          <li>1 letra minúscula (a-z).</li>
          <li>1 letra mayúscula (A-Z)</li>
          <li>1 número (0-9).</li>
          <li>8 caracteres como mínimo.</li>
        </ul>
      </Space>
      <Space direction='horizontal'>
        <Button size='large' onClick={onChangePassword}>
          Guardar
        </Button>
        <Button type='simple' size='large' onClick={() => router.push('/me')}>
          Cancelar
        </Button>
      </Space>
    </Space>
  );
}

PasswordChangeForm.propTypes = {
  setOpenModal: PropTypes.func.isRequired,
  user: PropTypes.object,
  setUser: PropTypes.func,
};

export default ChangeUserPassword;
