/* eslint-disable react/prop-types */
import styled from 'styled-components';
import React from 'react';
import { ReactComponent as IconWarning } from './icon-warning.svg';
import KDS from '../../../ds/lib/k-ds-foundation';

const StyledMessageWarning = styled.div`
  display: flex;
  align-items: flex-start;

  border-radius: 4px;
  box-sizing: border-box;
  padding: 16px 20px;
  border: 1px solid #fbc02d;

  ${KDS.setColorWithFallback('background: ', 'yellow-300')}

  .icon {
    min-width: 18px;
  }

  .message {
    overflow: hidden;
    ${KDS.setSpacingWithFallback('margin-left: ', 'spacing-04')};
    overflow-wrap: break-word;
  }
`;

const MessageWarning = ({ message, ...moreProps }) => (
  <StyledMessageWarning {...moreProps}>
    <IconWarning className='icon' />
    <div className='message k-ds-label-01'>{message}</div>
  </StyledMessageWarning>
);

export { MessageWarning };
