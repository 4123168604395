import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CodeInput from '../../../components/CodeInput';
import CountDown from '../../../components/CountDown';
import { Button } from '../../../ds';
import styles from './createaccount.module.scss';

const ActivationAccount = ({ handleNextStep }) => {
  const [code, setCode] = useState('');
  const [countDownVal, setCountDownVal] = useState(0);

  const handleChangeInput = value => {
    setCode(value);
  };

  const handleVerification = () => {
    handleNextStep();
    console.log(code);
    setCountDownVal(0);
  };

  return (
    <div className={styles.containerValidation}>
      <div className={styles.title}>Verificación de seguridad</div>

      <p className={styles.label}>
        Para continuar, ingresa el NIP enviado por <span>WhatsApp</span> a tu
        teléfono celular con terminación <span>4445</span>
      </p>

      <div className={cn(styles.label, styles.blue)}>
        Editar teléfono celular
      </div>
      <p className={styles.label}>Ingresa el NIP</p>
      <CodeInput
        length={6}
        justify='space-between'
        onChangeCode={handleChangeInput}
        hasError={false}
        validCode={false}
      />

      <CountDown
        time={countDownVal}
        messageCounter='Reenviar NIP en'
        messageBtnReset='Reenviar'
      />

      <div style={{ marginTop: '80px' }}>
        <Button
          type='primary-alternate'
          className='k-ds-m-rigth-sp-02 k-ds-width-full'
          horizontalPadding='2.5rem'
          verticalPadding='1rem'
          size='small'
          onClick={handleVerification}
        >
          Verificar teléfono
        </Button>
      </div>
    </div>
  );
};

ActivationAccount.propTypes = {
  handleNextStep: PropTypes.func.isRequired,
};

export default ActivationAccount;
