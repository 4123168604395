import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import MerchantPaymentsService from '../../../services/MerchantPaymentsService';
import Timer from '../../../Utils/Timer';
import { useGetMerchants } from './../../../Utils/useGetMerchants';
import { ACCOUNT_MANAGER } from './../../../Utils/constants';
import Auth from './../../../Utils/Auth/auth';
import { useLoaderContext } from '../../../contexts/Loader/LoaderContext';
import { useStore } from '../../../services/store';
import { useGetMerchant } from './../../../Utils/useGetMerchant';

const useQueryPaymentSchedules = () => {
  const { setIsLoaderOpen } = useLoaderContext();

  const { employee } = Auth.userData();
  const merchants = useGetMerchants();
  const merchantSelected = useGetMerchant();
  const disabledMerchantId =
    merchants.length === 0 && employee.role.name !== ACCOUNT_MANAGER;
  const {
    merchantReportPayments: { paymentSchedules, updatePaymentSchedulesFilter },
  } = useStore();

  useEffect(() => {
    updatePaymentSchedulesFilter({
      merchantId: disabledMerchantId ? '' : merchantSelected.merchant_id,
    });
  }, []);

  const fetchPaymentSchedules = async parameters => {
    setIsLoaderOpen(true);
    const responsePaymentDateSchedules =
      await MerchantPaymentsService().getPaymentDateSchedules(parameters);
    const schedules = {};
    const monthSchedules = {};

    responsePaymentDateSchedules
      .sort((a, b) => Timer().diff(a.range_start, b.range_start).milliseconds)
      .forEach(paymentShedule => {
        const month = Timer().toLocal(paymentShedule.range_start, 'MMMM');
        const dateRange = {
          rangeStart: paymentShedule.range_start,
          rangeEnd: paymentShedule.range_end,
          paymentScheduleId: paymentShedule.payment_schedule_id,
        };

        if (schedules[month]) {
          schedules[month].dateSchedules.push(dateRange);
          monthSchedules[month].paymentScheduleIds.push(
            paymentShedule.payment_schedule_id
          );
          return;
        }

        monthSchedules[month] = {
          month: month,
          paymentScheduleIds: [paymentShedule.payment_schedule_id],
        };

        schedules[month] = {
          month: month,
          dateSchedules: [dateRange],
        };
      });

    setIsLoaderOpen(false);
    return {
      dateSchedules: Object.values(schedules),
      monthSchedules: Object.values(monthSchedules),
    };
  };

  const queryResult = useQuery({
    queryKey: ['dateSchedules', paymentSchedules],
    queryFn: async () => fetchPaymentSchedules(paymentSchedules),
    initialData: {
      dateSchedules: [],
      monthSchedules: [],
    },
    enabled: disabledMerchantId ? true : !!paymentSchedules.merchantId,
  });

  const onUpdateFilters = newFilters => {
    updatePaymentSchedulesFilter(newFilters);
  };

  return {
    filters: paymentSchedules,
    updateFilters: onUpdateFilters,
    queryResult: queryResult,
  };
};

export default useQueryPaymentSchedules;
