import React from 'react';
import PropTypes from 'prop-types';
import CustomGroup from '../../DS/Select/CustomGroup/CustomGroup';
import { useGetMerchants } from '../../../Utils/useGetMerchants';

const Brand = ({ value, onChange = () => null }) => {
  const merchants = useGetMerchants();

  return (
    <CustomGroup
      type='Marca'
      className='filter-by-brand'
      value={() => {
        const result = merchants.filter(item => item.value === value);
        return result.length === 0 ? {} : result[0];
      }}
      groupOptions={merchants}
      onChange={brand => onChange(brand.value)}
    />
  );
};

Brand.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default Brand;
