import React, { useState } from 'react';
import { Button, Modal, Space } from '../../ds';
import { ReactComponent as ClientConnectionSample } from './client-connection-sample.svg';
import './offline-info-button.scss';

const OfflineInfoButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <div className='offline-info-button'>
      <Button
        type='link'
        size='large'
        horizontalPadding='4px'
        verticalPadding='0px'
        onClick={() => setIsModalOpen(true)}
      >
        Conocer más
      </Button>
      <Modal showClose
             onClose={() => setIsModalOpen(false)}
             theme='dark'
             cardType='secondary'
             isOpen={isModalOpen} className='offline-info-modal'>
        <Space className='offline-info-modal-content' direction='vertical' align='center' justify='center'>
          <span className='k-ds-heading-04'>Verifica la conexión del cliente</span>
          <ClientConnectionSample />
        </Space>
      </Modal>
    </div>
  );
}

export default OfflineInfoButton;
