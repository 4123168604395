import React, { useState } from 'react';
import BaseFilter from '../BaseFilter/BaseFilter';
import PropTypes from 'prop-types';
import { ReactComponent as StatusIcon } from './status-icon.svg';
import { ReactComponent as CreationIcon } from './creation-icon.svg';

const PaymentOrderHistoryFilter = props => {
  const initialStatusFilter = [
    {
      value: 1,
      label: 'Todas',
      checked: false,
    },
    {
      value: 2,
      label: 'Aceptada',
      checked: false,
    },
    {
      value: 3,
      label: 'Rechazada',
      checked: false,
    },
  ];
  const [statusFilter, setStatusFilter] = useState(
    props.filters?.statusFilter
      ? props.filters.statusFilter
      : initialStatusFilter
  );
  const [startDate, setStartDate] = useState(
    props.filters?.startDate ? props.filters.startDate : ''
  );
  const [endDate, setEndDate] = useState(
    props.filters?.endDate ? props.filters.endDate : ''
  );

  function onChangeStatusFilter(index) {
    let statusFilterUpdated = [...statusFilter];
    statusFilterUpdated[index] = {
      ...statusFilterUpdated[index],
      checked: !statusFilterUpdated[index].checked,
    };
    setStatusFilter(statusFilterUpdated);
  }

  const filterInputs = [
    {
      interface: 'select',
      label: 'Estatus de la orden',
      name: 'status',
      options: statusFilter,
      onChange: onChangeStatusFilter,
      icon: <StatusIcon />,
    },
    {
      interface: 'date',
      label: 'Fecha de creación',
      name: 'creation_date',
      startDate: startDate,
      endDate: endDate,
      setStartDate: setStartDate,
      setEndDate: setEndDate,
      icon: <CreationIcon />,
    },
  ];

  function onReset() {
    setStartDate('');
    setEndDate('');
    setStatusFilter(initialStatusFilter);
  }

  return (
    <BaseFilter
      onApply={() => {
        props.onFilterChange({
          statusFilter,
          startDate,
          endDate,
        });
      }}
      onReset={onReset}
      filterInputs={filterInputs}
    />
  );
};

PaymentOrderHistoryFilter.propTypes = {
  filters: PropTypes.object,
  onFilterChange: PropTypes.func.isRequired,
};

export default PaymentOrderHistoryFilter;
