import PropTypes from 'prop-types';
import React from 'react';
import { isFeatureEnabled } from '../../../Utils/Feature/feature_flag';
import { ReactComponent as ExportIcon } from '../../../common/assets/excel-icon.svg';
import { Button } from '../../../ds';

const ExportButton = ({ handleExport }) => {
  return (
    isFeatureEnabled('export_payment_history') && (
      <Button
        type='link'
        size='small'
        onClick={handleExport}
        horizontalPadding='0px'
        verticalPadding='0px'
      >
        <ExportIcon className='k-ds-m-right-sp-03' />
        Exportar a Excel
      </Button>
    )
  );
};

ExportButton.propTypes = {
  handleExport: PropTypes.func.isRequired,
};

export default ExportButton;
