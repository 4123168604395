import {
  ACCOUNT_MANAGER,
  GENERAL_ADMIN,
  MERCHANT_CHANNELS_FILTER,
} from './constants';
import Auth from './Auth/auth';

const ONLINE_CHANNEL = 'online';

const getChannelsByRole = () => {
  if (!Auth || !Auth.userData) {
    return [];
  }

  const { employee } = Auth.userData();

  if (!employee || !employee.role) {
    return [];
  }

  if (
    employee.role.name === ACCOUNT_MANAGER ||
    employee.role.name === GENERAL_ADMIN
  ) {
    return MERCHANT_CHANNELS_FILTER;
  }

  return MERCHANT_CHANNELS_FILTER.filter(
    channel => channel.value !== ONLINE_CHANNEL
  );
};

export default getChannelsByRole;
