import styled, { css } from 'styled-components';
import React, { useState, useEffect } from 'react';
import { Space } from '../../../ds/components/space/index';
import KDS from '../../../ds/lib/k-ds-foundation';
import PropTypes from 'prop-types';
import Cleave from 'cleave.js';
import 'cleave.js/dist/addons/cleave-phone.mx';

const StyledInputOutline = styled.input`
  ${KDS.setColorTokenWithFallback(
    'border: 1px solid ',
    'interactive-alternate'
  )}
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  box-sizing: border-box;
  padding: 14px;
  height: 45px;

  ${KDS.setColorTokenWithFallback('color: ', 'interactive-alternate')}

  &:enabled {
    ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}

    &:hover {
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-secondary')}

      ::placeholder {
        ${KDS.setColorTokenWithFallback('color: ', 'interactive-secondary')}
      }
    }

    &:focus-visible {
      outline: 0;
    }

    &:focus {
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary')}
      ${KDS.setColorTokenWithFallback('border-color: ', 'interactive-primary')}
      border-width: 1px;
    }

    ::placeholder {
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-secondary')}
    }

    ::-ms-reveal {
      display: none;
    }

    ${props =>
      props.hasError &&
      css`
        ${KDS.setColorTokenWithFallback('border: 1px solid ', 'error')}
      `}
  }

  &:disabled {
    ${KDS.setColorTokenWithFallback(
      'border: 1px solid ',
      'background-alternate'
    )}

    ::placeholder {
      ${KDS.setColorTokenWithFallback('color: ', 'text-placeholder')}
    }
  }
`;

const InputWrapper = styled.div`
  position: relative;
  max-width: 300px;
  width: 100%;

  .icon {
    position: absolute;
    ${KDS.setSpacingWithFallback('top: ', 'spacing-05')}
    ${KDS.setSpacingWithFallback('right: ', 'spacing-05')}
    ${KDS.setColorWithFallback('background: ', 'white')}

    cursor: pointer;
  }

  .helper-error {
    position: absolute;
    top: 3.5rem;
    left: 0;
    right: 0;

    ${KDS.setColorTokenWithFallback('color: ', 'text-placeholder')}

    ${props =>
      props.hasError &&
      css`
        ${KDS.setColorTokenWithFallback('color: ', 'error')}
      `}
  }
`;

const InputCodeStyle = styled(StyledInputOutline)`
  width: 70px;
  height: 45px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-inline-end: none;

  ${props =>
    props.hasError &&
    css`
      ${KDS.setColorTokenWithFallback('color: ', 'error')}
    `}
`;

const PhoneWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`;

const PhoneInputOutline = ({
  className,
  id,
  label,
  onChange = () => null,
  errorMessage,
  optional,
  helper,
  ...moreProps
}) => {
  const [text, setText] = useState('');
  const hasError = Boolean(errorMessage);
  const { required } = moreProps;
  const props = { ...moreProps, hasError };

  useEffect(() => {
    const cleave = new Cleave('.phone-input', {
      phone: true,
      phoneRegionCode: 'mx',
      blocks: [2, 4, 4],
      delimiter: '-',
      delimiterLazyShow: true,
      onValueChanged: e => {
        if (Boolean(e.target) && Boolean(e.target.rawValue)) {
          setText(e.target.value);
          onChange ? onChange(e.target.rawValue) : null;
        }
      },
    });

    return () => {
      cleave.destroy();
    };
  }, []);

  const labelOrRequired = (
    <Space
      size='small'
      align='start'
      justify='space-between'
      className='k-ds-width-full'
      direction='horizontal'
    >
      <label className='k-ds-body-01 k-ds-text-color-primary' htmlFor={id}>
        {label}
        {required ? (
          <span className='k-ds-text-color-error'>{' *'}</span>
        ) : null}
      </label>
      <span className='k-ds-label-01 k-ds-text-color-interactive-secondary'>
        {optional}
      </span>
    </Space>
  );

  return (
    <Space size='small' direction='vertical' className={className}>
      {label || required ? labelOrRequired : null}
      <InputWrapper hasError={hasError}>
        <PhoneWrapper>
          <InputCodeStyle
            hasError={hasError}
            type='text'
            value='+52'
            className='k-ds-body-01'
            readOnly
          />
          <StyledInputOutline
            id={id}
            type='text'
            value={text}
            className='phone-input k-ds-width-full k-ds-body-01'
            {...props}
          />
        </PhoneWrapper>
        {helper || hasError ? (
          <span className='helper-error k-ds-caption-01'>
            {errorMessage || helper}
          </span>
        ) : null}
      </InputWrapper>
    </Space>
  );
};

PhoneInputOutline.propTypes = {
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  helper: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
  optional: PropTypes.any,
  autoFocus: PropTypes.bool,
  onBlur: PropTypes.func,
};

export default PhoneInputOutline;
