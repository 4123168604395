const desktop = {
  width: 1280,
};

const tablet = {
  width: 1024,
};

const smallTablet = {
  width: 768,
};

const onlyMobile = {
  width: 480,
};

const defaults = {
  width: desktop.width,
  isMobileOnly: false,
  isMobile: false,
  isSmallTablet: false,
  isTablet: false,
  isDesktop: true,
  type: 'desktop',
};

const Device = {
  defaults,
};

const getDeviceInformation = device => {
  const deviceUpdated = { ...defaults, width: device.width };

  if (device.width < tablet.width) {
    deviceUpdated.isMobile = true;
    deviceUpdated.isTablet = deviceUpdated.isDesktop = false;
    deviceUpdated.type = 'mobile';
    if (device.width <= onlyMobile.width) deviceUpdated.isMobileOnly = true;
    if (device.width >= smallTablet.width) deviceUpdated.isSmallTablet = true;
  }

  if (device.width >= tablet.width && device.width < desktop.width) {
    deviceUpdated.isTablet = true;
    deviceUpdated.isMobileOnly = deviceUpdated.isDesktop = false;
    deviceUpdated.isMobile = deviceUpdated.isDesktop = false;
    deviceUpdated.isSmallTablet = deviceUpdated.isDesktop = false;
    deviceUpdated.type = 'tablet';
  }

  if (device.width >= desktop.width) {
    deviceUpdated.isDesktop = true;
    deviceUpdated.isMobile = deviceUpdated.isTablet = false;
    deviceUpdated.type = 'desktop';
  }

  return deviceUpdated;
};

export default Device;
export { getDeviceInformation };
