import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../ds';
import { Space } from '../../ds';
import { Select } from '../../components/DS/Select';
import {
  autoCompleteDecimalNumber,
  numberWithCommas,
} from '../../Utils/formats';
import {
  validateEmptyField,
  validateErrorMessagesFromObject,
  validateDecimal,
  validateName,
  validateReferenceOrSku,
  validateNumerical,
  validateMaxChars,
  validateLessThan,
} from '../../Utils/formValidations';
import { useEffectAfterFirstRender } from '../../Utils/customHooks';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { orderProductsMaxLimit } from '../../Utils/constants';
import { TextInput } from '../DS/TextInput/index';

function ArticleToAdd({ onAddArticle, optionsSelectCategory }) {
  const [disabledAddArticleButton, setDisabledAddArticleButton] =
    React.useState(true);
  const productNameMaxLength = 128;

  const initialArticle = {
    sku: '',
    name: '',
    quantity: '',
    price: '',
    category: '',
    total: '',
  };

  const [newArticle, setNewArticle] = React.useState(initialArticle);

  const initialArticleValidations = {
    name: '',
    category: '',
    quantity: '',
    price: '',
  };

  const [articleValidations, setArticleValidations] = React.useState(
    initialArticleValidations
  );

  const initialWasOnFocus = {
    name: false,
    category: false,
    quantity: false,
    price: false,
  };

  const [wasOnFocus, setWasOnFocus] = React.useState(initialWasOnFocus);
  const [quantityRangeError, setQuantityRangeError] = React.useState('');

  function setValidSKU(value) {
    const maxSKULength = 1024;
    const slicedSKU = value.slice(0, maxSKULength);
    if (validateReferenceOrSku(slicedSKU)) {
      setNewArticle({ ...newArticle, sku: slicedSKU.toUpperCase() });
    }
  }

  function setValidName(value) {
    const maxNameLength = 256;
    const slicedName = value.slice(0, maxNameLength);
    if (validateName(slicedName)) {
      setNewArticle({ ...newArticle, name: slicedName });
    }
  }

  function setValidPrice(value) {
    if (validateDecimal(value)) {
      const price = value === '0' ? '' : value;
      setNewArticle({ ...newArticle, price });
    }
  }

  function priceInputOnBlur(value) {
    const autoCompleteValue = autoCompleteDecimalNumber(value, 2);
    setNewArticle({ ...newArticle, price: autoCompleteValue });
    if (!wasOnFocus.price) {
      setWasOnFocus({ ...wasOnFocus, price: true });
    }
  }

  React.useEffect(() => {
    const total = newArticle.quantity * Number(newArticle.price);
    const autoCompleteTotal = autoCompleteDecimalNumber(total.toFixed(2), 2);
    setNewArticle({ ...newArticle, total: autoCompleteTotal });
  }, [newArticle.quantity, newArticle.price]);

  function clearArticleForm() {
    setNewArticle(initialArticle);
    setArticleValidations(initialArticleValidations);
    setWasOnFocus(initialWasOnFocus);
  }

  const isCategoryDisabled = optionsSelectCategory.length === 0;

  function handleValidations() {
    const { name, category, quantity, price } = newArticle;
    const validation = {
      category: isCategoryDisabled
        ? ''
        : validateEmptyField(category, 'Campo obligatorio'),
      name:
        validateEmptyField(name, 'Campo Obligatorio') ||
        validateMaxChars(
          name,
          productNameMaxLength,
          'Has excedido el número de caracteres permitido'
        ),
      quantity: validateEmptyField(quantity, 'Campo Obligatorio'),
      price: validateEmptyField(price, 'Campo Obligatorio'),
    };
    const min60 = validateLessThan(
      60,
      price,
      'El monto mínimo de compra debe ser 60.'
    );
    if (validation.price === '' && min60 !== '') validation.price = min60;
    setDisabledAddArticleButton(
      validateErrorMessagesFromObject(validation) || quantityRangeError !== ''
    );
    setArticleValidations(validation);
  }

  function categoryOnBlur() {
    if (!wasOnFocus.category) {
      setWasOnFocus({ ...wasOnFocus, category: true });
    }
  }

  function setValidQuantity(value) {
    if (value === '' || validateNumerical(value)) {
      const quantity = value !== '' ? parseInt(value) : value;
      const exceedsLimit = quantity > orderProductsMaxLimit;
      const lessThan0 = quantity < 1;
      setQuantityRangeError(
        exceedsLimit || lessThan0
          ? `La cantidad ${exceedsLimit ? 'máxima' : ' mínima'} es ${exceedsLimit ? orderProductsMaxLimit : 1}`
          : ''
      );
      setNewArticle({ ...newArticle, quantity });
    }
  }

  function quantityInputOnBlur(value) {
    setNewArticle({
      ...newArticle,
      quantity: value !== '' ? parseInt(value) : value,
    });
    if (!wasOnFocus.quantity) {
      setWasOnFocus({ ...wasOnFocus, quantity: true });
    }
  }

  useEffectAfterFirstRender(() => {
    handleValidations();
  }, [
    newArticle.name,
    newArticle.category,
    newArticle.quantity,
    newArticle.price,
    wasOnFocus,
  ]);

  function addArticle() {
    onAddArticle(newArticle);
    clearArticleForm();
  }

  const viewport = useDeviceContextViewport();
  const columnsClass = viewport.isMobile ? 'one-column' : 'two-columns';

  return (
    <>
      <div className={`${columnsClass} k-ds-m-top-sp-06`}>
        <TextInput
          className='k-ds-m-bottom-sp-06'
          label='SKU o código del artículo'
          helper='Puedes ingresar letras, números y guiones'
          value={newArticle.sku}
          id='by_product_sku'
          name='by_product_sku'
          onChange={e => setValidSKU(e.target.value)}
        />

        <TextInput
          className='k-ds-m-bottom-sp-06'
          errorMessage={wasOnFocus.name ? articleValidations.name : ''}
          label='Nombre del producto'
          helper='Además del nombre, puedes agregar talla, color u otras características.'
          value={newArticle.name}
          lengthValidation={productNameMaxLength}
          required
          id='by_product_article_name'
          name='by_product_article_name'
          onChange={e => setValidName(e.target.value)}
          onBlur={() =>
            !wasOnFocus.name
              ? setWasOnFocus({ ...wasOnFocus, name: true })
              : null
          }
        />

        <Select
          errorMessage={wasOnFocus.category ? articleValidations.category : ''}
          options={optionsSelectCategory}
          label='Categoría'
          placeholder='Selecciona'
          required={!isCategoryDisabled}
          disabled={isCategoryDisabled}
          name='by_product_category'
          value={newArticle.category}
          onBlur={() => categoryOnBlur()}
          onChange={value => setNewArticle({ ...newArticle, category: value })}
          className={
            articleValidations.category
              ? 'k-ds-m-bottom-sp-06'
              : 'k-ds-m-bottom-sp-02'
          }
        />

        <TextInput
          className='k-ds-m-bottom-sp-06'
          errorMessage={
            wasOnFocus.quantity
              ? articleValidations.quantity || quantityRangeError
              : ''
          }
          label='Cantidad de artículos'
          onChange={e => setValidQuantity(e.target.value)}
          onBlur={e => quantityInputOnBlur(e.target.value)}
          placeholder='Ingresa la cantidad de artículos'
          type='outline'
          required
          name='by_amount_quantity'
          value={newArticle.quantity}
          helper={`Máximo ${orderProductsMaxLimit} artículos`}
        />

        <TextInput
          errorMessage={wasOnFocus.price ? articleValidations.price : ''}
          label='Precio unitario'
          value={newArticle.price}
          onChange={e => setValidPrice(e.target.value)}
          onBlur={e => priceInputOnBlur(e.target.value)}
          placeholder='$0.00'
          required
          id='by_product_price'
          name='by_product_price'
          className={
            articleValidations.price
              ? 'k-ds-m-bottom-sp-06'
              : 'k-ds-m-bottom-sp-02'
          }
        />

        <Space
          className='k-ds-m-bottom-sp-05 k-ds-height-full'
          direction='horizontal'
          align='center'
        >
          <span className='k-ds-heading-01'>Subtotal:</span>
          <span className='k-ds-body-01 k-ds-text-color-grey-500'>
            ${numberWithCommas(newArticle.total) || '0.00'}
          </span>
        </Space>
      </div>
      <div className='flex-row k-ds-m-top-sp-08'>
        <Button
          type='secondary-alternate'
          size='small'
          onClick={() => addArticle()}
          verticalPadding='14px'
          horizontalPadding='43px'
          disabled={disabledAddArticleButton}
        >
          Agregar
        </Button>
      </div>
    </>
  );
}

ArticleToAdd.propTypes = {
  onAddArticle: PropTypes.func.isRequired,
  optionsSelectCategory: PropTypes.array.isRequired,
};

export default ArticleToAdd;
