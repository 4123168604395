import React from 'react';
import { ButtonGhost } from './ButtonGhost';
import { ButtonLink } from './ButtonLink';
import { ButtonOutline } from './ButtonOutline';
import { ButtonPrimary } from './ButtonPrimary';
import { ButtonPrimaryAlternate } from './ButtonPrimaryAlternate';
import { ButtonSecondary } from './ButtonSecondary';
import { ButtonSecondaryAlternate } from './ButtonSecondaryAlternate';
import { ButtonSimple } from './ButtonSimple';

const MAP_BUTTON_TYPE = {
  'primary': ButtonPrimary,
  'secondary': ButtonSecondary,
  'ghost': ButtonGhost,
  'outline': ButtonOutline,
  'simple': ButtonSimple,
  'link': ButtonLink,
  'primary-alternate': ButtonPrimaryAlternate,
  'secondary-alternate': ButtonSecondaryAlternate,
};

const MAP_TEXT_TYPE = {
  'large': 'k-ds-heading-02',
  'small': 'k-ds-heading-01',
};

const MAP_PADDING = {
  'large': { vertical: '16px', horizontal: '32px' },
  'small': { vertical: '8px', horizontal: '24px' },
};

const Button = React.forwardRef(({
  buttonType,
  className,
  children,
  type,
  size,
  borderWidth,
  verticalPadding,
  horizontalPadding,
  btnType = 'button',
  ...moreProps
}, ref) => {
  const defaultComponentSize = 'large';
  const defaultBorderWidth = '2px';
  const defaultButton = 'primary';

  const ButtonComponent = MAP_BUTTON_TYPE[type] || MAP_BUTTON_TYPE[defaultButton];
  const textClass = MAP_TEXT_TYPE[size] || MAP_TEXT_TYPE[defaultComponentSize];
  const buttonClassName = className ? `${className} ${textClass}` : textClass;
  let props = { ...moreProps, borderWidth: (borderWidth || defaultBorderWidth) };
  const {
    vertical: vPadding,
    horizontal: hPadding,
  } = MAP_PADDING[size] || MAP_PADDING[defaultComponentSize];
  props = {
    ...props,
    verticalPadding: (verticalPadding || vPadding),
    horizontalPadding: (horizontalPadding || hPadding),
  };
  if (buttonType) {
    props = {
      ...props,
      type: buttonType,
    };
  }
  return (
    <ButtonComponent ref={ref} type={btnType} className={buttonClassName} {...props}>
      {children}
    </ButtonComponent>
  );
});

export {
  Button
};

