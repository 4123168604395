import React, { useState } from 'react';
import { mexicanStates } from '../../Utils/constants';
import BaseFilter from '../BaseFilter/BaseFilter';
import PropTypes from 'prop-types';
import { ReactComponent as StateIcon } from './state-icon.svg';
import { ReactComponent as StatusIcon } from './status-icon.svg';
import { ReactComponent as CreationIcon } from './creation-icon.svg';

const StoresFilter = props => {
  const initialStateFilter = Object.keys(mexicanStates).map(key => {
    return {
      value: key,
      label: mexicanStates[key],
      checked: false,
    };
  });
  const initialStatusFilter = [
    {
      value: 1,
      label: 'Todas',
      checked: false,
    },
    {
      value: 2,
      label: 'Activa',
      checked: false,
    },
    {
      value: 3,
      label: 'Inactiva',
      checked: false,
    },
    {
      value: 4,
      label: 'Suspendida',
      checked: false,
    },
  ];
  const [stateFilter, setStateFilter] = useState(() => {
    return props.filters?.stateFilter
      ? props.filters.stateFilter
      : initialStateFilter;
  });
  const [statusFilter, setStatusFilter] = useState(
    props.filters?.statusFilter
      ? props.filters.statusFilter
      : initialStatusFilter
  );
  const [startDate, setStartDate] = useState(
    props.filters?.startDate ? props.filters.startDate : ''
  );
  const [endDate, setEndDate] = useState(
    props.filters?.endDate ? props.filters.endDate : ''
  );

  function onChangeStateFilter(index) {
    let stateFilterUpdated = [...stateFilter];
    stateFilterUpdated[index] = {
      ...stateFilterUpdated[index],
      checked: !stateFilterUpdated[index].checked,
    };
    setStateFilter(stateFilterUpdated);
  }

  function onChangeStatusFilter(index) {
    let statusFilterUpdated = [...statusFilter];
    statusFilterUpdated[index] = {
      ...statusFilterUpdated[index],
      checked: !statusFilterUpdated[index].checked,
    };
    setStatusFilter(statusFilterUpdated);
  }

  const filterInputs = [
    {
      interface: 'select',
      label: 'Estado',
      name: 'state',
      options: stateFilter,
      onChange: onChangeStateFilter,
      icon: <StateIcon />,
    },
    {
      interface: 'select',
      label: 'Estatus',
      name: 'status',
      options: statusFilter,
      onChange: onChangeStatusFilter,
      icon: <StatusIcon />,
    },
    {
      interface: 'date',
      label: 'Fecha de creación',
      name: 'creation_date',
      startDate: startDate,
      endDate: endDate,
      setStartDate: setStartDate,
      setEndDate: setEndDate,
      icon: <CreationIcon />,
    },
  ];

  function onReset() {
    setStartDate('');
    setEndDate('');
    setStatusFilter(initialStatusFilter);
    setStateFilter(initialStateFilter);
  }

  return (
    <BaseFilter
      onApply={() => {
        props.onFilterChange({
          stateFilter,
          statusFilter,
          startDate,
          endDate,
        });
      }}
      onReset={onReset}
      filterInputs={filterInputs}
    />
  );
};

StoresFilter.propTypes = {
  filters: PropTypes.object,
  onFilterChange: PropTypes.func.isRequired,
};

export default StoresFilter;
