import React, { useEffect, useRef } from 'react';
import SearchReport from './components/SearchReport/SearchReport';
import ReportTable from './table/ReportTable';
import { useQueryTransactionReport } from './hooks/useQueryTransactionReport';
import SelectPeriod from './components/SelectPeriod/SelectPeriod';
import styles from './merchantTransactionReport.module.scss';
import MultipleChoice from './../DS/Select/MultipleChoice/MultipleChoice';
import Brand from '../ReportFilters/Brand/Brand';
import { useHistory } from 'react-router-dom';
import { useLoaderContext } from '../../contexts/Loader/LoaderContext';
import MerchantPaymentsService from '../../services/MerchantPaymentsService';
import { useGetMerchants } from './../../Utils/useGetMerchants';
import { useToastContext } from '../../contexts/Toast/ToastContext';
import { MERCHANT_TRANSACTIONS_TYPE_FILTER } from './../../Utils/constants';
import getChannelByRole from './../../Utils/getChannelByRole';
import { observer } from 'mobx-react-lite';
import AnalyticsEvent, {
  AnalyticsList,
} from '../../services/analytics/AnalyticsEvent';

const FORMAT_DATE_RANGE = 'yyyy-MM-dd';

const MerchantTransactionsReport = () => {
  let history = useHistory();
  const isTracked = useRef(false);
  const addToast = useToastContext();
  const merchants = useGetMerchants();
  const channelOptions = getChannelByRole();
  const { setIsLoaderOpen } = useLoaderContext();

  const {
    filters,
    queryResult,
    changePageSize,
    changePage,
    updateFilters,
    initFilters,
  } = useQueryTransactionReport();

  const handleOnCancelTransaction = async payment => {
    setIsLoaderOpen(true);
    if (payment && payment.available_money_to_refund > 0) {
      history.push(`/payments/${payment.payment_id}/reimbursement`);
    } else {
      addToast('Esta transacción no se puede cancelar', 'error');
    }
  };

  const handeOnDetailsPage = item => {
    history.push(`/payments/${item.payment_id}`);
    const contentArea = document.getElementById('content-area');
    contentArea.scrollTop = 0;
  };

  const handleChangePageSize = pageSize => {
    changePageSize(pageSize);
  };

  const handleChangePage = page => {
    changePage(page);
  };

  const handleOnReset = () => {
    initFilters();
  };

  const handleOnSearch = searchTerm => {
    if (searchTerm) {
      updateFilters({ searchTerm: searchTerm.replace(/-/g, '') });
      AnalyticsEvent().informationSearched({
        funnelStep: 'Order Payment',
        searchResults: { searchTerm: searchTerm },
        inputType: 'Search',
      });
    } else {
      initFilters();
    }
  };

  const handleOnDownloadReport = async () => {
    try {
      setIsLoaderOpen(true);
      AnalyticsEvent().paymentRecordExported();
      await MerchantPaymentsService().exportTransactionReport({
        merchantId: filters.merchantId,
        searchTerm: filters.searchTerm,
        paymentStatuses: filters.paymentStatuses,
        channels: filters.channels,
        startDateRange: filters.startDateRange,
        endDateRange: filters.endDateRange,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoaderOpen(false);
    }
  };

  const trackSearch = () => {
    AnalyticsEvent().informationSearched({
      funnelStep: 'Order Payment',
      searchResults: filters,
      inputType: 'Options',
    });
  };

  useEffect(() => {
    if (!isTracked.current) {
      AnalyticsEvent().pageView({ page: AnalyticsList.transactionReport });
      AnalyticsEvent().paymentRecordViewed();
      isTracked.current = true;
    }
  }, []);

  return (
    <div className={`k-ds-width-full ${styles.transactionReport} `}>
      <h1 className='k-ds-heading-05 k-ds-m-bottom-sp-02'>
        Reporte de transacciones
      </h1>
      <div className='k-ds-body-02 k-ds-m-bottom-sp-07'>
        Transacciones con Kueski Pay.
      </div>

      <div className={styles.selectPeriod}>
        <div className='k-ds-heading-02'>Filtrar por:</div>

        {merchants.length > 0 ? (
          <div className={styles.brandFilter}>
            <Brand
              value={filters.merchantId}
              onChange={brand => {
                updateFilters({ merchantId: brand });
                trackSearch();
              }}
            />
          </div>
        ) : null}

        <div className={styles.filter}>
          <SelectPeriod
            formatDate={FORMAT_DATE_RANGE}
            value={{
              start: filters.startDateRange,
              end: filters.endDateRange,
            }}
            className={styles.selectByPeriod}
            onClick={dates => {
              updateFilters({
                searchTerm: '',
                startDateRange: dates.start,
                endDateRange: dates.end,
              });
              trackSearch();
            }}
          />
        </div>

        <div className={styles.filter}>
          <MultipleChoice
            type='Canal'
            value={channelOptions.filter(item =>
              filters.channels.includes(item.value)
            )}
            options={channelOptions}
            onChange={channelsItems => {
              updateFilters({
                searchTerm: '',
                channels: channelsItems.map(item => item.value),
              });
              trackSearch();
            }}
          />
        </div>

        <div className={styles.filter}>
          <MultipleChoice
            type='Transacción'
            options={MERCHANT_TRANSACTIONS_TYPE_FILTER}
            value={MERCHANT_TRANSACTIONS_TYPE_FILTER.filter(item =>
              filters.paymentStatuses.includes(item.value)
            )}
            onChange={transactionTypes => {
              updateFilters({
                searchTerm: '',
                paymentStatuses: transactionTypes.map(item => item.value),
              });
              trackSearch();
            }}
          />
        </div>
      </div>

      <SearchReport
        value={filters.searchTerm}
        onSearch={searchTerm => handleOnSearch(searchTerm)}
      />

      <div className={styles.table}>
        <ReportTable
          data={
            queryResult.data && queryResult.data.payments
              ? queryResult.data.payments
              : []
          }
          onDetailsPage={handeOnDetailsPage}
          onCancel={handleOnCancelTransaction}
          onDownloadReport={handleOnDownloadReport}
          options={{
            onCleanFilter: handleOnReset,
            onChangePageSize: handleChangePageSize,
            onChangePage: handleChangePage,
            totalElements:
              queryResult.data && queryResult.data.total_records
                ? queryResult.data.total_records
                : 0,
            totalPages:
              queryResult.data && queryResult.data.total_pages
                ? queryResult.data.total_pages
                : 1,
            currentPage: filters.page ? filters.page : 1,
          }}
        />
      </div>
    </div>
  );
};

export default observer(MerchantTransactionsReport);
