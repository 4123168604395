import PropTypes from 'prop-types';
import React, { memo, useCallback, useRef, useState, useEffect } from 'react';
import SingleInput from './SingleInput';

import { Space } from '../../../ds';
import { isFeatureEnabled } from '../../../Utils/Feature/feature_flag';

const CodeInputComponent = props => {
  const {
    length,
    isNumberInput,
    disabled,
    onChangeCode = () => null,
    hasError = false,
    validCode,
    justify = 'center',
    onBlurForm = () => null,
  } = props;

  const isFocused = useRef(false);
  const isFirstRender = useRef(true);
  const [activeInput, setActiveInput] = useState(0);
  const [codeValues, setCodeValues] = useState(Array(length).fill(''));
  const isOtpOfflineV2Enabled = isFeatureEnabled('otp_offline_v2');
  const handleCodeChange = useCallback(
    code => {
      onChangeCode && onChangeCode(code.join(''));
    },
    [onChangeCode]
  );

  const getRightValue = useCallback(
    str => {
      let changedValue = str;

      if (!isNumberInput || !changedValue) {
        return changedValue;
      }

      return Number(changedValue) >= 0 ? changedValue : '';
    },
    [isNumberInput]
  );

  const changeCodeAtFocus = useCallback(
    str => {
      const updatedCodeValues = [...codeValues];
      updatedCodeValues[activeInput] = str[0] || '';
      setCodeValues(updatedCodeValues);
      handleCodeChange(updatedCodeValues);
    },
    [activeInput, handleCodeChange, codeValues]
  );

  const focusInput = useCallback(
    inputIndex => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [length]
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  const handleOnFocus = useCallback(
    index => () => {
      focusInput(index);
      isFocused.current = true;
    },
    [focusInput]
  );

  const handleOnChange = useCallback(
    e => {
      const val = getRightValue(e.currentTarget.value);
      if (!val) {
        e.preventDefault();
        return;
      }
      changeCodeAtFocus(val.toUpperCase());
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput, getRightValue]
  );

  const onBlur = useCallback(() => {
    setActiveInput(-1);
    isFocused.current = false;
  }, []);

  const handleOnKeyDown = useCallback(
    e => {
      const pressedKey = e.key;

      switch (pressedKey) {
        case 'Backspace':
        case 'Delete': {
          e.preventDefault();
          if (codeValues[activeInput]) {
            changeCodeAtFocus('');
          } else {
            focusPrevInput();
          }
          break;
        }
        case 'ArrowLeft': {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case 'Tab':
        case 'ArrowRight': {
          e.preventDefault();
          if (codeValues[activeInput] !== '') {
            focusNextInput();
          }
          break;
        }
        default: {
          const isValid = isOtpOfflineV2Enabled
            ? pressedKey.match(/^[^0-9]$/)
            : pressedKey.match(/^[^a-zA-Z0-9]$/);

          if (isValid) {
            e.preventDefault();
          }
          break;
        }
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, codeValues]
  );

  const handleOnPaste = useCallback(
    e => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData('text/plain')
        .toUpperCase()
        .trim()
        .slice(0, length - activeInput)
        .split('');
      if (pastedData) {
        let nextFocusIndex = 0;
        const updatedCodeValues = [...codeValues];
        updatedCodeValues.forEach((val, index) => {
          if (index >= activeInput) {
            const changedValue = getRightValue(pastedData.shift() || val);
            if (changedValue) {
              updatedCodeValues[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });
        setCodeValues(updatedCodeValues);
        handleCodeChange(updatedCodeValues);
        setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
      }
    },
    [activeInput, getRightValue, length, codeValues]
  );

  useEffect(() => {
    if (!isFirstRender.current) {
      onBlurForm && !isFocused.current ? onBlurForm(codeValues.join('')) : null;
    } else {
      isFirstRender.current = false;
    }
  }, [isFocused.current]);

  return (
    <Space
      withAutoMargin={false}
      justify={justify}
      direction='horizontal'
      className='k-ds-width-full'
      size='medium'
    >
      {Array(length)
        .fill('')
        .map((_, index) => (
          <SingleInput
            key={`SingleInput-${index}`}
            type={isNumberInput ? 'number' : 'text'}
            focus={activeInput === index}
            value={codeValues && codeValues[index]}
            onFocus={handleOnFocus(index)}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            onBlur={onBlur}
            onPaste={handleOnPaste}
            disabled={disabled}
            hasError={hasError}
            validCode={validCode}
          />
        ))}
    </Space>
  );
};

CodeInputComponent.propTypes = {
  length: PropTypes.number.isRequired,
  isNumberInput: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  onChangeCode: PropTypes.func,
  hasError: PropTypes.bool.isRequired,
  validCode: PropTypes.bool.isRequired,
  justify: PropTypes.string,
  onBlurForm: PropTypes.func,
};

const CodeInput = memo(CodeInputComponent);
export default CodeInput;
