import React, { useState } from 'react';
import { Divider, Button, Space } from '../../ds';
import './campaignContent.scss';
import PropTypes from 'prop-types';
import { ReactComponent as FileIcon } from './file-icon.svg';
import { ReactComponent as FileImages } from './file-image.svg';
import { ReactComponent as FileEdit } from './file-edit.svg';
import { ReactComponent as FileMedia } from './file-media.svg';
import { ReactComponent as FileOffice } from './file-office.svg';
import { ReactComponent as PreviewIcon } from './simple-preview-icon.svg';
import { ReactComponent as DownloadIcon } from './Download.svg';
import { ReactComponent as FolderIcon } from './Folder.svg';
import { ReactComponent as UpChevronIcon } from './UpChevron.svg';
import { ReactComponent as DownChevronIcon } from './DownChevron.svg';
import ImagePreviewModal from '../ImagePreviewModal/ImagePreviewModal';
import { PathLine } from '../CampaignDirectories/PathLine';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { useLoaderContext } from '../../contexts/Loader/LoaderContext';
import {
  getAssetDownload,
  getCampaignDirectoryDownloadZip,
} from '../../services/Application';
import ErrorModal from '../ErrorModal/ErrorModal';

const CampaignContent = props => {
  const { setIsLoaderOpen } = useLoaderContext();
  const defaultImageSrc = require('./example-image.svg').default;
  const emptyAssetPreview = {
    src: defaultImageSrc,
    name: '',
    isOpen: false,
  };
  const [previewImage, setPreviewImage] = useState(emptyAssetPreview);
  const iconClass = 'k-ds-m-left-sp-05 k-ds-m-right-sp-05 file-type';
  const viewport = useDeviceContextViewport();
  const [expandedItem, setExpandedItem] = useState('');
  const [errorModalState, setErrorModalState] = React.useState(false);
  const [operationError, setOperationError] = React.useState({
    title: undefined,
    description: undefined,
  });

  const allowedImagesExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.tiff'];
  const allowedEditsExtensions = ['.ai', '.psd', '.pdf'];
  const allowedMediaExtensions = ['.aiff', '.mp3', '.mp4', '.mpeg', '.mov'];
  const allowedOfficeExtensions = [
    '.pptx',
    '.docs',
    '.xlsx',
    '.key',
    '.numbers',
    '.pages',
    '.csv',
  ];

  const icon = extension => {
    if (allowedImagesExtensions.includes(extension))
      return <FileImages width={24} height={24} className={iconClass} />;
    if (allowedEditsExtensions.includes(extension))
      return <FileEdit width={24} height={24} className={iconClass} />;
    if (allowedMediaExtensions.includes(extension))
      return <FileMedia width={24} height={24} className={iconClass} />;
    if (allowedOfficeExtensions.includes(extension))
      return <FileOffice width={24} height={24} className={iconClass} />;
    return <FileIcon width={24} height={24} className={iconClass} />;
  };

  function HandlePreview(asset) {
    if (asset.src) {
      const src = asset.src;
      setPreviewImage({
        src: src,
        name: asset.name,
        isOpen: true,
      });
    } else {
      setOperationError({
        title: 'Error al previsualizar',
        description:
          'Ocurrió un error al previsualizar el archivo. ¿Puedes intentarlo de nuevo?',
      });
      return setErrorModalState(true);
    }
  }

  function closeImagePreviewModal() {
    setPreviewImage(emptyAssetPreview);
  }

  const isPreviewEnabled = extension => {
    return extension === '.jpg' || extension === '.svg';
  };

  function handleDownload(type, contentName, contentId, fileExtension = null) {
    const downloadRequest =
      type === 'asset' ? getAssetDownload : getCampaignDirectoryDownloadZip;
    setIsLoaderOpen(true);
    downloadRequest(contentId)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data.src]));
        const link = document.createElement('a');
        link.href = url;
        let date = new Date();
        let timeStamp = date.toLocaleString();
        link.setAttribute(
          'download',
          `${contentName}_${timeStamp}${fileExtension ? fileExtension : '.zip'}`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        setOperationError({
          title: 'Error en la descarga',
          description:
            'Ocurrió un error al descargar el archivo. ¿Puedes intentarlo de nuevo?',
        });
        console.error(error);
        return setErrorModalState(true);
      })
      .finally(() => {
        setIsLoaderOpen(false);
      });
  }

  function closeErrorModal() {
    setErrorModalState(false);
  }

  return (
    <div className='campaign-content-list k-ds-width-full'>
      {props.content.map((content, index) => {
        const isExpanded = expandedItem === content?.directory_id;
        return (
          <React.Fragment key={index}>
            <Space direction='horizontal' justify='space-between'>
              <PathLine className='path-line' height='auto' />
              <Space
                direction='vertical'
                justify='space-between'
                className='k-ds-width-full content-item-container'
              >
                <Divider className='content-divider' />
                {content.type === 'asset' ? (
                  <Space justify='space-between' className='content-item'>
                    <Space
                      className='content-info'
                      direction={
                        viewport.isMobile && !viewport.isSmallTablet
                          ? 'vertical'
                          : 'horizontal'
                      }
                    >
                      <Space className='content-container k-ds-m-rigth-sp-09'>
                        <Space
                          className={
                            props.isLastLevel
                              ? 'content-name content-name-last-level'
                              : 'content-name'
                          }
                        >
                          {icon(content.extension)}
                          <div className='k-ds-body-01'>{content.name}</div>
                        </Space>
                      </Space>
                      <Space
                        direction={
                          props.isLastLevel &&
                          viewport.isMobile &&
                          !viewport.isSmallTablet
                            ? 'vertical'
                            : 'horizontal'
                        }
                        className={
                          viewport.isMobile &&
                          !viewport.isSmallTablet &&
                          !props.isLastLevel
                            ? 'mobile-content-container'
                            : props.isLastLevel
                              ? 'mobile-content-container-last'
                              : ''
                        }
                      >
                        <Space
                          direction='vertical'
                          className='content-container k-ds-m-rigth-sp-09'
                        >
                          <div className='k-ds-body-01 content-text'>
                            {props.isLastLevel &&
                            viewport.isMobile &&
                            viewport.isSmallTablet
                              ? content.size
                              : 'Tamaño: ' + content.size}
                          </div>
                        </Space>
                        <Space
                          direction='vertical'
                          className='content-container'
                        >
                          <div
                            className={
                              viewport.isMobile && !viewport.isSmallTablet
                                ? props.isLastLevel
                                  ? 'k-ds-body-01 content-text k-ds-m-left-sp-01 k-ds-m-top-sp-03'
                                  : 'k-ds-body-01 content-text content-format-label k-ds-m-left-sp-01'
                                : 'k-ds-body-01 content-text content-format-label'
                            }
                          >
                            {props.isLastLevel &&
                            viewport.isMobile &&
                            viewport.isSmallTablet
                              ? content.formatLabel
                              : 'Formato: ' + content.formatLabel}
                          </div>
                        </Space>
                      </Space>
                    </Space>
                    <Space className='content-container-buttons'>
                      {isPreviewEnabled(content.extension) && (
                        <Button
                          type='link'
                          size='small'
                          onClick={() => HandlePreview(content)}
                        >
                          <PreviewIcon /> {!props.simplifiedStyle ? 'Ver' : ''}
                        </Button>
                      )}
                      <Button
                        type='link'
                        size='small'
                        onClick={() =>
                          handleDownload(
                            content.type,
                            content.name,
                            content.asset_id,
                            content.extension
                          )
                        }
                      >
                        <DownloadIcon />{' '}
                        {!props.simplifiedStyle ? 'Descargar' : ''}
                      </Button>
                    </Space>
                  </Space>
                ) : (
                  <>
                    <Space
                      justify='space-between'
                      className='content-item k-ds-width-full content-item-hover'
                      onClick={() =>
                        setExpandedItem(isExpanded ? '' : content.directory_id)
                      }
                    >
                      <Space
                        justify='space-between'
                        className='content-info k-ds-width-full'
                      >
                        <Space
                          direction={
                            viewport.isMobile && !viewport.isSmallTablet
                              ? 'vertical'
                              : 'horizontal'
                          }
                        >
                          <Space
                            direction='vertical'
                            className='content-container k-ds-m-rigth-sp-09'
                          >
                            <Space className='content-name content-name-directory'>
                              <FolderIcon className={iconClass} />
                              <div className='k-ds-body-01'>{content.name}</div>
                            </Space>
                          </Space>
                          <Space
                            direction='vertical'
                            className={
                              viewport.isSmallTablet
                                ? 'content-container content-margin k-ds-m-rigth-sp-09'
                                : 'content-container k-ds-m-rigth-sp-09'
                            }
                          >
                            <div
                              className={
                                viewport.isMobile && !viewport.isSmallTablet
                                  ? 'k-ds-body-01 content-text mobile-dir-container'
                                  : 'k-ds-body-01 content-text'
                              }
                            >
                              Tamaño: {content.totalSize}
                            </div>
                          </Space>
                        </Space>
                        <Space
                          direction='vertical'
                          className={
                            viewport.isMobile
                              ? 'content-container content-download k-ds-m-rigth-sp-09'
                              : 'content-container k-ds-p-right-sp-02 k-ds-m-rigth-sp-09'
                          }
                        >
                          <Button
                            className='k-ds-m-right-sp-07'
                            type='link'
                            size='small'
                            onClick={() =>
                              handleDownload(
                                content.type,
                                content.name,
                                content.directory_id
                              )
                            }
                          >
                            <DownloadIcon className='k-ds-m-right-sp-04' />{' '}
                            Descargar .zip
                          </Button>
                        </Space>
                      </Space>
                      <Space
                        className='content-container-expand k-ds-m-left-sp-03'
                        onClick={() =>
                          setExpandedItem(
                            isExpanded ? '' : content?.directory_id
                          )
                        }
                      >
                        {isExpanded ? (
                          <UpChevronIcon className='k-ds-m-bottom-sp-04 k-ds-m-top-sp-03 k-ds-m-right-sp-06' />
                        ) : (
                          <DownChevronIcon className='k-ds-m-bottom-sp-04 k-ds-m-top-sp-03 k-ds-m-right-sp-06' />
                        )}
                      </Space>
                    </Space>
                    {isExpanded && (
                      <Space className='content-nested-container'>
                        <Space
                          justify='space-between'
                          className='k-ds-width-full'
                        >
                          <Space className='k-ds-width-full'>
                            <Space className='k-ds-width-full'>
                              <CampaignContent
                                content={content.content}
                                simplifiedStyle
                                isLastLevel
                              />
                            </Space>
                          </Space>
                        </Space>
                      </Space>
                    )}
                  </>
                )}
              </Space>
            </Space>
          </React.Fragment>
        );
      })}
      <ImagePreviewModal
        defaultOpened={previewImage.isOpen}
        close={closeImagePreviewModal}
        title={previewImage.name}
        src={previewImage.src}
      />
      <ErrorModal
        defaultOpened={errorModalState}
        close={closeErrorModal}
        heading={operationError.title}
        description={operationError.description}
      />
    </div>
  );
};

CampaignContent.propTypes = {
  content: PropTypes.array.isRequired,
  simplifiedStyle: PropTypes.bool,
  isLastLevel: PropTypes.bool,
};

export default CampaignContent;
