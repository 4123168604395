const articles = [
  {
    sku: '0032748492847321',
    name: 'Producto 1',
    price: '100',
    quantity: '6',
    total: '600',
    category: 'Categoría 1',
  },
  {
    sku: '0032748492847324',
    name: 'Producto 1',
    price: '100',
    quantity: '6',
    total: '600',
    category: 'Categoría 1',
  },
];

const optionsSelect = [
  {
    text: '1',
    value: '1',
  },
  {
    text: '2',
    value: '2',
  },
  {
    text: '3',
    value: '3',
  },
  {
    text: '4',
    value: '4',
  },
  {
    text: '5',
    value: '5',
  },
  {
    text: '6',
    value: '6',
  },
  {
    text: '7',
    value: '7',
  },
  {
    text: '8',
    value: '8',
  },
  {
    text: '9',
    value: '9',
  },
  {
    text: '10',
    value: '10',
  },
];
const optionsSelectCategory = [
  {
    text: 'Discos',
    value: 'Discos',
  },
  {
    text: 'Mascotas',
    value: 'Mascotas',
  },
  {
    text: 'Muebles',
    value: 'Muebles',
  },
  {
    text: 'Juguetes',
    value: 'Juguetes',
  },
];

function UserStore() {
  const employee = JSON.parse(window.localStorage.getItem('employee'));
  const currentStores = JSON.parse(window.localStorage.getItem('stores'));
  const merchant = JSON.parse(window.localStorage.getItem('merchant'));

  function merchantName() {
    return merchant.name;
  }

  function employeeName() {
    return employee?.full_name;
  }

  function employeeId() {
    return employee.employee_id;
  }

  function employeeCode() {
    return employee.employee_code;
  }

  function storeName() {
    return currentStores[0].name;
  }

  function storeId() {
    return currentStores[0].store_id;
  }

  function employeeRoleId() {
    return employee.role.name;
  }

  function employeeRoleName() {
    return employee.role.description;
  }

  function merchantId() {
    return merchant.merchant_id;
  }

  return {
    merchantName,
    employeeName,
    employeeId,
    employeeCode,
    storeName,
    storeId,
    employeeRoleId,
    employeeRoleName,
    merchantId,
  };
}

function getUser() {
  return UserStore();
}

export { articles, getUser, optionsSelect, optionsSelectCategory };
