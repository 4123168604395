import { Space } from '../../ds';
import React from 'react';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import './accessDenied.scss';
import LockImage from './lock.svg';

function AccessDenied() {
  const viewport = useDeviceContextViewport();

  const LockImageComponent = (
    <div className='lock-circle'>
      <img className='lock-image' src={LockImage} alt='lock' />
    </div>
  );
  return (
    <Space
      justify='center'
      direction={viewport.isMobile ? 'vertical' : 'horizontal'}
      className='access-denied-page k-ds-width-full'
    >
      {viewport.isMobile && LockImageComponent}
      <Space
        direction='vertical'
        className='k-ds-m-right-sp-09 k-ds-m-bottom-sp-08'
      >
        <span className='k-ds-heading-08 k-ds-m-bottom-sp-08'>
          Acceso denegado
        </span>
        <span className='access-denied-info k-ds-heading-02 font-weight-normal'>
          No tienes permisos para visualizar el contenido de esta página. En
          caso de error, por favor contactar a <span>mail@kueski.com</span>.
        </span>
      </Space>
      {!viewport.isMobile && LockImageComponent}
    </Space>
  );
}

export default AccessDenied;
