import React from 'react';
import PropTypes from 'prop-types';
import { Button, Space } from '../../ds';
import { Modal } from '../../ds';
import './imagePreviewModal.scss';
import { ReactComponent as CloseIcon } from './close-icon.svg';

function ImagePreviewModal(props) {
  const defaultSrc = require('./Default.svg').default;
  return props.defaultOpened ? (
    <Modal
      className='image-preview-modal'
      theme='dark'
      cardType='secondary'
      onClose={() => props.close()}
      onOverlayClick={() => props.close()}
      {...{ isOpen: props.defaultOpened ? true : false }}
    >
      <div className='k-ds-width-full'>
        <Space
          direction='horizontal'
          className='k-ds-m-bottom-sp-05 close-container'
        >
          <Space className='k-ds-width-full k-ds-m-left-sp-04 k-ds-m-top-sp-04'>
            {props.title}
          </Space>
          <Button
            className='close-button'
            type='link'
            size='small'
            onClick={() => props.close()}
          >
            <CloseIcon />
          </Button>
        </Space>
        <Space className='image-container k-ds-m-top-sp-08'>
          <img
            className='image-preview'
            src={props.src ? props.src : defaultSrc}
            alt={'merchant_image'}
          ></img>
        </Space>
      </div>
    </Modal>
  ) : null;
}

ImagePreviewModal.propTypes = {
  defaultOpened: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  src: PropTypes.string,
  title: PropTypes.string,
};

export default ImagePreviewModal;
