import React from 'react';
import PropTypes from 'prop-types';

const loaderContextDefaultValue = {
  isLoaderOpen: false,
  setIsLoaderOpen: () => {},
};
const LoaderContext = React.createContext(loaderContextDefaultValue);

const useLoaderContext = () => {
  const context = React.useContext(LoaderContext);
  if (context === loaderContextDefaultValue) {
    throw new Error('useLoaderContext must be used within a LoaderProvider');
  }
  return context;
};

const LoaderContextProvider = ({ children }) => {
  const [isLoaderOpen, setIsLoaderOpen] = React.useState(false);

  return (
    <LoaderContext.Provider value={{ isLoaderOpen, setIsLoaderOpen }}>
      {children}
    </LoaderContext.Provider>
  );
};

LoaderContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoaderContext;

export { LoaderContextProvider, useLoaderContext };
