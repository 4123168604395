import React, { useEffect } from 'react';
import { useStore } from '../../../services/store';
import { isFeatureEnabled } from '../../../Utils/Feature/feature_flag';
import { allowedQrv2Store } from '../../../Utils/utils';
import PayOrderByQRv2 from '../../../components/PayOrderByQRV2/PayOrderByQRv2';
import PayOrderByQR from '../../../components/PayOrderByQR/PayOrderByQR';
import { observer } from 'mobx-react-lite';

const payOrderByQrV2Enabled = isFeatureEnabled('qr_offline_v2');

const PayOrderQrVersionSelector = () => {
  const { qrCodeStore: { storeId, reload}} = useStore();

  useEffect(() => {
    reload();
  }, []);

  const enableV2 = payOrderByQrV2Enabled && allowedQrv2Store.includes(storeId);
  return enableV2 ? <PayOrderByQRv2 /> : <PayOrderByQR />;
}

export default observer(PayOrderQrVersionSelector);
