import React from 'react';
import styled, { css } from 'styled-components';
import KDS from '../../lib/k-ds-foundation';

const Shell = styled.div`
  overflow-y: hidden;
  
  margin: 0;
  ${KDS.setColorTokenWithFallback('border: 1px solid ', 'interactive-alternate')};
  box-sizing: border-box;
  height: 100vh;

  ${KDS.setColorTokenWithFallback('background: ', 'background-secondary')};
`;

const StyledSideNav = styled.div`
  float: left;

  overflow-y: hidden;
  z-index: 3;
  margin: 0;
  box-sizing: border-box;
  ${(props) => props.width && css`
    min-width: ${props.width};
    width: ${props.width};
  `}
  height: 100%;
  padding: 0;

  ${KDS.setColorTokenWithFallback('background: ', 'background-primary')};
`;

const SideNav = ({
  className,
  children,
  width,
  ...moreProps
}) => {
  const defaultWidth = '15rem';
  const widthProp = width || defaultWidth;
  return (
    <StyledSideNav className={className} width={widthProp} {...moreProps}>
      { children }
    </StyledSideNav>
  );
};

const StyledContentArea = styled.div`
  overflow-y: auto;

  margin: 0;
  box-sizing: border-box;
  height: 100%;
  ${(props) => props.padding && css`
    padding: ${props.padding};
  `}
`;

const ContentArea = ({
  className,
  children,
  padding,
  ...moreProps
}) => {
  const defaultPadding = '64px 64px 32px 64px';
  const paddingProp = padding || defaultPadding;
  return (
    <StyledContentArea className={className} padding={paddingProp} {...moreProps}>
      { children }
    </StyledContentArea>
  );
};

export { ContentArea, Shell, SideNav };

