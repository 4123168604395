import React from 'react';
import LogoUpload from '../../../components/LogoUpload/index';

function LogoUploadPage() {
  return (
    <div className='k-ds-width-full'>
      <LogoUpload></LogoUpload>
    </div>
  );
}

export default LogoUploadPage;
