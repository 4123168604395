import React, { useEffect, useLayoutEffect, useState } from 'react';
import Auth from '../../Utils/Auth/auth';
import initLogBehavior from '../../services/LogBehavior/LogBehavior';
import { Card } from '../../ds';
import Amount from './Amount/Amount';
import InfoCard from './InfoCard';
import SelectStores from './SelectStores';

import './payOrder.scss';
import { useStore } from '../../services/store';
import { observer } from 'mobx-react-lite';
import AnalyticsEvent, {
  AnalyticsEventDictionary,
  AnalyticsList,
} from '../../services/analytics/AnalyticsEvent';
import { useRef } from 'react';

const initialPaymentData = {
  isModalOpen: false,
  paymentId: '',
  orderId: '',
  errors: '',
  isValid: false,
  amount: 0,
  tokenErrors: '',
  path: '',
  token: '',
};

const PayOrderByQR = () => {
  const isTracked = useRef(false);
  const user = Auth.userData();

  if (!user) {
    return '';
  }

  const {
    qrCodeStore: { storeId, setStoreId, stores, selectedStore },
  } = useStore();
  const userLogBehavior = React.useRef('');
  const [location, setLocation] = useState({});
  const [paymentData, setPaymentData] = useState(initialPaymentData);

  useLayoutEffect(() => {
    if (!isTracked.current) {
      AnalyticsEvent().pageView({ page: AnalyticsList.instorePaymentQR });
      AnalyticsEvent().inStore({
        eventId: AnalyticsEventDictionary.merchantPortalChargeQrViewLoaded,
      });
      isTracked.current = true;
    }
    initLogBehavior(userLogBehavior);
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          setLocation({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          });
        },
        error => {
          console.error('Error getting location:', error.message);
        }
      );
    }
  }, []);

  const resetPaymentData = () => {
    setPaymentData(initialPaymentData);
  };

  return (
    <>
      <div className='k-ds-width-full pay-order'>
        <div className='pay-order-header k-ds-m-bottom-sp-07'>
          Cobrar por Código QR
        </div>
        <InfoCard />
        {stores.length > 0 && (
          <Card type='secondary' className='k-ds-width-full'>
            <div className='sub-header k-ds-m-bottom-sp-05'>Sucursal</div>
            <div>
              <SelectStores
                label='Selecciona una sucursal'
                placeholder='Elige una opción o escribe aquí'
                helper='El cobro se asignará a esta sucursal.'
                required
                name='sucursal'
                stores={stores}
                value={selectedStore}
                onChange={option => (option ? setStoreId(option.value) : null)}
              />
            </div>
          </Card>
        )}
        <Amount
          storeid={storeId}
          location={location}
          logbehavior={userLogBehavior}
          paymentAmount={paymentData.amount}
          isPaymentValidated={paymentData.isValid}
          onClear={resetPaymentData}
        />
      </div>
    </>
  );
};

export default observer(PayOrderByQR);
