import styled, { css } from 'styled-components';
import React from 'react';
import KDS from '../../lib/k-ds-foundation';

const HorizontalDivider = styled.div`
  border-style: solid;

  ${(props) => props.color && css`
    ${KDS.setColorTokenWithFallback('border-color: ', props.color)}
  `}
  ${(props) => props.thickness && css`
    border-width: 0px 0px ${props.thickness} 0px;
  `}

  ${(props) => props.width && css`
    width: ${props.width};
  `}
  ${(props) => ((props.margin && props.margin !== '0' && css`
    ${KDS.setSpacingWithFallback('margin: ', props.margin, 0)};
  `) || (props.margin && props.margin === '0' && css`
    margin: 0 0;
  `))}
`;

const VerticalDivider = styled.div`
  display: inline-block;
  border-style: solid;

  ${(props) => props.color && css`
    ${KDS.setColorTokenWithFallback('border-color: ', props.color)}
  `}
  ${(props) => props.thickness && css`
    border-width: 0px ${props.thickness} 0px 0px;
  `}

  ${(props) => props.height && css`
    height: ${props.height};
  `}
  ${(props) => props.margin && css`
    margin: 0 ${props.margin};
  `}
`;

const Divider = ({
  className, children, color = 'border', thickness = '2px', height = '1.4ch', width = '100%', margin, ...moreProps
}) => {
  let DividerComponent;
  let marginSpace = '0';
  switch (moreProps.type) {
    case 'vertical':
      DividerComponent = VerticalDivider;
      marginSpace = 'spacing-03';
      break;
    case 'horizontal':
    default:
      DividerComponent = HorizontalDivider;
      marginSpace = 'layout-01';
  }

  return (
    <DividerComponent
      className={className}
      {...moreProps}
      color={color}
      thickness={thickness}
      height={height}
      width={width}
      margin={margin || marginSpace}
    >
      {children}
    </DividerComponent>
  );
};

export {
  Divider,
};
