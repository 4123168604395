import React from 'react';
import PropTypes from 'prop-types';
import { Button, Space } from '../../ds';
import { Modal } from '../../ds';
import './errorModal.scss';

function ErrorModal({
  defaultOpened = false,
  heading = 'Error desconocido',
  description = 'Encontramos un error desconocido en el sistema. ¿Puedes intentarlo más tarde?',
  close,
  ...rest
}) {
  return defaultOpened ? (
    <Modal
      className='error-modal'
      theme='dark'
      cardType='secondary'
      onClose={() => close()}
      onOverlayClick={() => close()}
      {...{ isOpen: defaultOpened ? true : false }}
      {...rest}
    >
      <div className='k-ds-width-full'>
        <div className='k-ds-heading-05 k-ds-m-top-sp-05'>{heading}</div>
        <div className='k-ds-m-top-sp-06 k-ds-body-02'>{description}</div>
        <Space
          direction='horizontal'
          className='k-ds-m-top-sp-07 k-ds-m-bottom-sp-05'
        >
          <Button onClick={() => close()}>Entendido</Button>
        </Space>
      </div>
    </Modal>
  ) : null;
}

ErrorModal.propTypes = {
  defaultOpened: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  heading: PropTypes.string,
  description: PropTypes.any,
};

export default ErrorModal;
