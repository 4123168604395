// import { yupResolver } from '@hookform/resolvers/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { TAX_REGIME } from '../../../../Utils/constants';
import {
  createOption,
  onlyUnique,
} from '../../../../components/ZipCodeForm/ZipCodeForm';
import { Button } from '../../../../ds';
import { getZipCodeInfo } from '../../../../services/Application';
import Form, {
  FormInput,
  FormSelect,
  RadioButton,
  useForm,
} from './../../../../components/Form/Form';
import styles from './ActivationForm.module.scss';
import { NATIONALITY, ORIGIN_CHANNEL } from './constants';
import { validationBusinessSchema } from './validationSchema';

const TaxSystem = () => (
  <div className={styles.gridCol}>
    <RadioButton
      name='personType'
      value='natural_person'
      label='Persona física'
    />
    <RadioButton name='personType' value='legal_person' label='Persona moral' />
  </div>
);

const WebSites = () => {
  const { control } = useFormContext();
  const { fields, append } = useFieldArray({
    control,
    name: 'websites',
  });

  const handleAdd = () => {
    if (fields.length < 3) {
      append({ url: '' });
    }
  };

  return (
    <div className={styles.arrayFields}>
      {fields.map((field, index) => (
        <div key={`${field.id}_website`} className={styles.relative}>
          <FormInput
            name={`websites.${index}.url`}
            label='Sitio web'
            placeholder='http://calzadoj.com'
          />
          {index === 0 && (
            <span type='link' className={styles.addBtn} onClick={handleAdd}>
              + Agregar sitio
            </span>
          )}
        </div>
      ))}
    </div>
  );
};

const LegalRepresentative = isLegal => {
  if (!isLegal) return;
  return (
    <>
      <div className={styles.nameForm}>Representante legal</div>

      <FormInput
        name='legal_person_name'
        label='Nombre completo'
        placeholder='Daniel Camacho López'
      />
      <FormInput
        name='legal_person_curp'
        label='CURP'
        placeholder='MAGL240190MDFRYN00'
      />
      <FormInput
        name='legal_person_rfc'
        label='RFC'
        placeholder='GEJA590523YS5'
        hint='Ingresa RFC sin guiones o espacios'
      />
      <FormInput
        name='legal_person_email'
        label='Correo de representante legal '
        placeholder='correo@ejemplo.com.mx'
      />
    </>
  );
};

const BusinessDataForm = ({ handleNextStep }) => {
  const [, setIsChecked] = useState(false);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const methods = useForm({
    resolver: yupResolver(validationBusinessSchema),
    defaultValues: {
      personType: 'natural',
      websites: [{ url: '' }],
      channel: { value: 'all', label: 'Todos' },
      nationality: { value: 'MEX', label: 'Mexicano' },
      tax_regime: null,
    },
  });
  const typePerson = methods.watch('personType');
  const isForeign = methods.watch('nationality')?.value === 'EXT';
  const isLegalPerson = typePerson === 'legal';

  useEffect(() => {
    if (!isLegalPerson) {
      setIsChecked(false);
    }
  }, [isLegalPerson]);

  const handleSubmit = data => {
    handleNextStep(data, 1, 2);
  };

  const debouncedSearch = debounce(async zipCode => {
    try {
      getZipCodeInfo(zipCode).then(response => {
        const zip_codes = response.zip_codes;

        if (zip_codes.length === 0) {
          methods.setError('zip_code', {
            message: 'El codigo postal no existe',
          });
          setNeighborhoods([]);
          setCities([]);
          setStates([]);
          return;
        }

        const fetch_neighborhoods = zip_codes
          .map(value => value.d_asenta)
          .filter(onlyUnique)
          .map(createOption);
        const fetch_cities = zip_codes
          .map(value => value.d_mnpio)
          .filter(onlyUnique)
          .map(createOption);
        const fetch_states = zip_codes
          .map(value => value.d_estado)
          .filter(onlyUnique)
          .map(createOption);

        setNeighborhoods(fetch_neighborhoods);
        setCities(fetch_cities);
        setStates(fetch_states);

        methods.clearErrors('zip_code');
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, 300);

  const handleZipCodeChange = e => {
    const zipCode = e.target.value;
    methods.setValue('zip_code', zipCode);
    if (zipCode.length > 0) debouncedSearch(zipCode);
    if (zipCode.length === 0) {
      setNeighborhoods([]);
      setCities([]);
      setStates([]);
    }
  };

  return (
    <div className={styles.formContainer}>
      <div className={styles.nameForm}>Datos del comercio</div>
      <div className={styles.instructions}>
        Proporciona los datos de tu comercio.
      </div>

      <div className={styles.helper}>Todos los campos son obligatorios</div>
      <Form methods={methods} onSubmit={handleSubmit}>
        <div className={styles.formGrid}>
          <FormInput
            name='business_name'
            label='Nombre del comercio o marca'
            placeholder='Calzado Jiménez'
          />
          <WebSites />

          <FormSelect
            isSearchable
            name='channel'
            label='Canales de venta'
            options={ORIGIN_CHANNEL}
          />

          <FormSelect
            isSearchable
            name='nationality'
            label='Nacionalidad del comercio'
            options={NATIONALITY}
          />

          <div className={styles.nameForm}>Datos fiscales</div>

          <TaxSystem />
          <FormInput
            name='name'
            label={`${isLegalPerson ? 'Razón social' : 'Nombre completo'} (idéntico a la Constancia de Situación Fiscal)`}
            placeholder='Escribe el dato'
          />
          <FormSelect
            isSearchable
            name='tax_regime'
            label='Régimen fiscal'
            options={TAX_REGIME[typePerson]}
            placeholder='Escribe el régimen fiscal'
          />
          <FormInput
            name='rfc'
            hint={isForeign ? '' : 'Ingresa RFC sin guiones o espacios'}
            placeholder={isForeign ? '59052352345690' : 'GEJA590523YS5'}
            label={
              isForeign
                ? 'Número de identificación fiscal'
                : 'RFC (idéntico a la CSF)'
            }
          />

          {LegalRepresentative(isLegalPerson)}

          <div className={styles.nameForm}>Domicilio (idéntico a la CSF)</div>
          <FormInput
            name='zip_code'
            label='Código postal'
            placeholder='07080'
            onChange={handleZipCodeChange}
          />
          <div className={styles.gridCol}>
            <FormSelect
              isSearchable
              options={cities}
              name='city'
              label='Municipio'
              placeholder='Gustavo A Madero'
            />
            <FormSelect
              isSearchable
              options={states}
              name='state'
              label='Localidad'
              placeholder='Ciudad de México'
            />
          </div>
          <FormSelect
            isSearchable
            options={neighborhoods}
            name='neighborhood'
            label='Colonia'
            placeholder='Selecciona la colonia del comercio'
          />

          <FormInput name='street' label='Calle' placeholder='Hidalgo 624' />
          <FormInput name='ext_number' label='Número exterior' placeholder='' />
          <FormInput
            isOptional
            name='inner_number'
            label='Número interior'
            placeholder=''
          />

          {/* <FormSelect name='country' label='País' options={ [{ value: 'Mexico', text: 'Mexico' }] } /> */}
          {/* {isLegalPerson && (
            <div>
              <input type='checkbox' checked={ isChecked } onChange={ handleChange } />
              Soy una Sociedad por Acciones Simplificada (SAS)
            </div>
          )} */}
        </div>

        <div className={styles.action}>
          <Button type='ghost'>Guardar y salir</Button>
          <Button btnType='submit'>Siguiente</Button>
        </div>
      </Form>
    </div>
  );
};

BusinessDataForm.propTypes = {
  defaultFormValues: PropTypes.object,
  handleNextStep: PropTypes.func.isRequired,
};

export default BusinessDataForm;
