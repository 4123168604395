import { Button } from '../../../../ds';
import KDS from '../../../../ds/lib/k-ds-foundation';
import styled from 'styled-components';

const ButtonLinkStyled = styled(Button)`
  padding: 0;

  ${props =>
    props.textLeft &&
    `
    text-align: left !important;
  `}

  /* k-ds-label-01 */
  ${KDS.setTypographyWithFallback({
    'font-family': 'font-secondary',
    'font-weight': 'font-semibold',
    'font-size': 'text-xs',
    'letter-spacing': 'tracking-wide',
    'line-height': 'relaxed',
  })}
`;
export default ButtonLinkStyled;
