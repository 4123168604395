import { isFeatureEnabled } from '../../Utils/Feature/feature_flag';
import {
  SECTION_CHANNEL_NAME,
  SECTION_REPORT_NAME,
} from '../../Utils/constants';

import {
  campaigns,
  faq,
  help,
  historyOrders,
  home,
  merchant_image,
  paymentCode,
  stores,
  userProfile,
  users,
  generateOrders,
  merchantPaymentReport,
  merchatTransactionReport,
  paymentLogs,
} from './ScopeRoutes';

const IS_HOME_ENABLE = isFeatureEnabled('home_page');

const mapScopeChannels = {
  payment_code: paymentCode,
  generate_orders: generateOrders,
};

const mapScopeToMenu = {
  merchant_transactions: isFeatureEnabled('merchant_tarnsactions_report')
    ? merchatTransactionReport
    : paymentLogs,
  history_orders: historyOrders,
  merchants_payments: merchantPaymentReport,
};

const mapScopeToConfigMenu = {
  stores: stores,
  users: users,
  campaigns: campaigns,
};

export const hasPermission = (permission_scopes, module) => {
  return (
    permission_scopes?.filter(item => item.module_name === module).length > 0
  );
};

export const hasPermissionAndScope = (permission_scopes, module, scope) => {
  return permission_scopes?.find(
    item => item.module_name === module && item.scopes.find(s => s === scope)
  );
};

export const hasScopeAccess = (routeScopes, userScopes) => {
  return routeScopes.some(scope => userScopes.indexOf(scope) >= 0);
};

export function hasChannelAccess(scopeChannels, merchantChannels) {
  if (!merchantChannels || !scopeChannels) return false;
  return merchantChannels.some(merchantChannel =>
    scopeChannels.some(
      scopeChannel =>
        merchantChannel.channel === scopeChannel.channel &&
        merchantChannel.active &&
        scopeChannel.active
    )
  );
}

const resolveRoutes = (
  scope,
  mapScope,
  permission,
  merchantChannels,
  routesUnique
) => {
  scope.map(scope => {
    // const scopeMenu = mapScope[scope];
    // let isAllowed = isFeatureEnabled(scope);
    // if (scopeMenu?.isneedWhiteList) {
    //   isAllowed = isAccessAllowed(merchantId, paymentValidationAccessList)
    // }
    if (isFeatureEnabled(scope)) {
      const menu = mapScope[scope];
      const userModule = permission?.filter(
        item => item.module_name === menu.module
      );
      const userScopes =
        userModule && userModule.length > 0 ? userModule[0].scopes : [];
      if (menu) {
        menu.routes.map(route => {
          const isAuthorized =
            hasPermission(permission, menu.module) &&
            hasScopeAccess(route.scopes, userScopes);
          if (hasChannelAccess(route.channels, merchantChannels))
            routesUnique.push({ ...route, isAuthorized: isAuthorized });
        });
      }
    }
  });
};

function generateSection(
  scopes,
  permission_scopes,
  merchantChannels,
  mapScopeMenu,
  name
) {
  if (!scopes) {
    return null;
  }

  const menuLinks = [];
  scopes.forEach(scope => {
    const scopeMenu = mapScopeMenu[scope];

    if (
      scopeMenu &&
      hasPermission(permission_scopes, scopeMenu.module) &&
      isFeatureEnabled(scope) &&
      hasChannelAccess(scopeMenu.routes[0].channels, merchantChannels)
    ) {
      menuLinks.push({
        path: scopeMenu.routes[0].path,
        text: scopeMenu.name,
        icon: scopeMenu.icon,
        iconSelected: scopeMenu.iconSelected,
      });
    }
  });

  if (menuLinks.length > 0) {
    return {
      text: name,
      links: menuLinks || [],
    };
  }
}

function generateMenu(
  scope_channels,
  scopes,
  configScopes,
  permission_scopes,
  merchantChannels
) {
  const menuList = [];

  const HomeMenu = {
    text: '',
    links: [
      {
        path: home.routes[0].path,
        text: home.name,
        icon: home.icon,
        iconSelected: home.iconSelected,
      },
    ],
  };

  if (IS_HOME_ENABLE) {
    menuList.push(HomeMenu);
  }

  const channelsMenu = generateSection(
    scope_channels,
    permission_scopes,
    merchantChannels,
    mapScopeChannels,
    SECTION_CHANNEL_NAME
  );

  if (channelsMenu) {
    menuList.push(channelsMenu);
  }

  const mainMenu = generateSection(
    scopes,
    permission_scopes,
    merchantChannels,
    mapScopeToMenu,
    SECTION_REPORT_NAME
  );
  if (mainMenu) {
    menuList.push(mainMenu);
  }

  const configMenu = generateSection(
    configScopes,
    permission_scopes,
    merchantChannels,
    mapScopeToConfigMenu,
    'Configuraciones'
  );
  if (configMenu) {
    menuList.push(configMenu);
  }

  const linksHelp = [
    {
      path: faq.routes[0].path,
      text: faq.name,
      icon: faq.icon,
      iconSelected: faq.iconSelected,
    },
    {
      path: help.routes[0].path,
      text: help.name,
      icon: help.icon,
      iconSelected: help.iconSelected,
    },
  ];

  menuList.push({
    text: 'Soporte',
    links: linksHelp,
  });
  return menuList;
}

function generateRoutes(
  scope_channels,
  scopes,
  configScopes,
  permission_scopes,
  merchantChannels
) {
  const routesUnique = [
    {
      ...help.routes[0],
      isAuthorized: true,
    },
    {
      ...faq.routes[0],
      isAuthorized: true,
    },
    {
      ...userProfile.routes[0],
      isAuthorized: true,
    },
    {
      ...userProfile.routes[1],
      isAuthorized: true,
    },
  ];

  if (merchant_image) {
    merchant_image.routes.map(route => {
      const userModule = permission_scopes?.filter(
        item => item.module_name === merchant_image.module
      );
      const userScopes =
        userModule && userModule.length > 0 ? userModule[0].scopes : [];
      const isAuthorized =
        hasPermission(permission_scopes, merchant_image.module) &&
        hasScopeAccess(route.scopes, userScopes);
      if (hasChannelAccess(route.channels, merchantChannels))
        routesUnique.push({ ...route, isAuthorized: isAuthorized });
    });
  }

  if (scope_channels) {
    resolveRoutes(
      scope_channels,
      mapScopeChannels,
      permission_scopes,
      merchantChannels,
      routesUnique
    );
  }
  if (scopes) {
    resolveRoutes(
      scopes,
      mapScopeToMenu,
      permission_scopes,
      merchantChannels,
      routesUnique
    );
  }

  if (configScopes) {
    resolveRoutes(
      configScopes,
      mapScopeToConfigMenu,
      permission_scopes,
      merchantChannels,
      routesUnique
    );
  }
  return routesUnique;
}

function myOwnRoutes(
  scope_channels,
  scopes,
  configScopes,
  permission_scopes,
  merchantChannels
) {
  return {
    menus: generateMenu(
      scope_channels,
      scopes,
      configScopes,
      permission_scopes,
      merchantChannels
    ),
    routes: generateRoutes(
      scope_channels,
      scopes,
      configScopes,
      permission_scopes,
      merchantChannels
    ),
  };
}

export default myOwnRoutes;
