import { PaymentValidationModalStore } from './stores/PaymentValidationModalStore';
import { createContext, useContext } from 'react';
import { MutateUserStore } from './stores/MutateUserStore';
import { MerchantReportPaymentsStore } from './stores/MerchantReportPaymentsStore';
import { MerchantTransactionsReportStore } from './stores/MerchantTransactionsReportStore';
import { QrCodeStore } from '../containers/pages/PayOrderByQR/qrCodeStore';

export const store = {
  payOrderStore: new PaymentValidationModalStore(),
  mutateUserStore: new MutateUserStore(),
  merchantReportPayments: new MerchantReportPaymentsStore(),
  merchantTransactionsReport: new MerchantTransactionsReportStore(),
  qrCodeStore: new QrCodeStore(),
};

export const StoreContext = createContext(store);

export const useStore = () => {
  return useContext(StoreContext);
};
