import React from 'react';
import { InputOutline } from './InputOutline';
import { InputLine } from './InputLine';
import PropTypes from 'prop-types';

const MAP_INPUT_TYPE = {
  outline: InputOutline,
  line: InputLine,
};
const GeneralInput = ({ type, ...moreProps }) => {
  const defaultInputType = 'outline'; // TODO: add transparent (set it as default?)
  const InputComponent =
    MAP_INPUT_TYPE[type] || MAP_INPUT_TYPE[defaultInputType];

  return <InputComponent {...moreProps} />;
};

const TextInput = props => <GeneralInput inputType='text' {...props} />;

const PasswordInput = props => <GeneralInput inputType='password' {...props} />;

const DateInput = props => <GeneralInput inputType='date' {...props} />;

GeneralInput.propTypes = {
  type: PropTypes.string,
};

export { TextInput, PasswordInput, DateInput };
