import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTextSpan = styled.span`
  position: relative;
  display: block;
  overflow-x: hidden;
`;

const step = 1;

const UserAutoscrollSpan = ({ text }) => {
  const mainScrollRef = useRef(null);
  const [currentScroll, setCurrentScroll] = useState(0);
  const [maxScroll, setMaxScroll] = useState(0);
  const [canScroll, setCanScroll] = useState(true);
  const [limitToCheck, setLimitToCheck] = useState(null);

  const calculateMaxScroll = () => {
    if (mainScrollRef.current) {
      const max =
        mainScrollRef.current.scrollWidth - mainScrollRef.current.clientWidth;
      setMaxScroll(max);
      setCanScroll(max > 0);
    }
  };

  const changeScroll = () => {
    if (limitToCheck === null) return;

    if (currentScroll === limitToCheck) {
      setLimitToCheck(limitToCheck === maxScroll ? 0 : maxScroll);
    } else {
      setCurrentScroll(
        limitToCheck === maxScroll ? currentScroll + step : currentScroll - step
      );
    }
  };

  const scrollTo = left => {
    if (mainScrollRef.current) mainScrollRef.current.scrollLeft = left;
  };
  // component render
  useEffect(() => {
    const timeoutID = window.setTimeout(() => {
      calculateMaxScroll();
    }, 2000);

    return () => window.clearTimeout(timeoutID);
  }, []);

  // when currentScroll changes
  useEffect(() => {
    scrollTo(currentScroll);
    const timeoutID = window.setTimeout(() => {
      changeScroll();
    }, 15);

    return () => window.clearTimeout(timeoutID);
  }, [currentScroll]);

  // when currentScroll changes
  useEffect(() => {
    const timeoutID = window.setTimeout(() => {
      if (limitToCheck !== null) changeScroll();
    }, 2000);

    return () => window.clearTimeout(timeoutID);
  }, [limitToCheck]);

  // when maxScroll changes
  useEffect(() => {
    if (maxScroll && maxScroll > 0) {
      setLimitToCheck(maxScroll);
    }
  }, [maxScroll]);

  return canScroll ? (
    <StyledTextSpan className='main-scroll' ref={mainScrollRef}>
      <span className='inner-scroll'>{text}</span>
    </StyledTextSpan>
  ) : (
    <span>{text}</span>
  );
};

UserAutoscrollSpan.propTypes = {
  text: PropTypes.string.isRequired,
};

export default UserAutoscrollSpan;
