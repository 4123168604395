import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { isFeatureEnabled } from '../../Utils/Feature/feature_flag';
import { CACHE_DURATION_MS } from '../../Utils/constants';
import {
  validateDecimal,
  validateEmptyField,
  validateErrorMessagesFromObject,
  validateLessThan,
  validateMinChars,
  validateReferenceOrSku,
} from '../../Utils/formValidations';
import {
  autoCompleteDecimalNumber,
  numberWithCommas,
} from '../../Utils/formats';
import { goPage } from '../../Utils/pages';
import useFormWithCache from '../../Utils/useFormWithCache';
import {
  getCategoryDescription,
  useCategoriesContext,
} from '../../contexts/Categories/CategoriesContext';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { useLoaderContext } from '../../contexts/Loader/LoaderContext';
import {
  Button,
  Divider,
  Space,
  Table,
  TableMobile,
  TextInput,
} from '../../ds';
import { getUser } from '../../mockData';
import { createOrder, saveLogbehavior } from '../../services/Application';
import { Message } from '../DS/Message';
import ErrorModal from '../ErrorModal/ErrorModal';
import ArticleToAdd from './ArticleToAdd';
import ModalRedirect from './ModalRedirect';
import './Product.scss';
import { ReactComponent as DeleteIcon } from './delete-icon.svg';
import ErrorText from './order-errors.json';
import AnalyticsEvent from '../../services/analytics/AnalyticsEvent';

const initialPayOrder = {
  amount: 0,
  reference: '',
  shippingAmount: '',
  articles: [],
};
function Product(props) {
  const {
    couponAmount = 0,
    paymentId = '',
    paymentByCoupon = false,
    isValidCoupon = false,
    validateFillCoupon,
    storeid,
    paymentAmount,
    isPaymentValidated,
    onClear,
    isPaymentRequired,
    location,
    logbehavior,
  } = props;
  const [storeId, setStoreId] = useState('');
  const user = getUser();
  const history = useHistory();

  const [disabledCreateOrderButton, setDisabledCreateOrderButton] =
    useState(true);
  const [errorModalState, setErrorModalState] = useState(false);
  const [operationError, setOperationError] = useState({
    title: undefined,
    description: undefined,
  });
  const [modalOrderPath, setModalOrderPath] = useState('');
  const [showTimer, setShowTimer] = useState(false);

  const { setIsLoaderOpen } = useLoaderContext();
  const { t } = useTranslation();

  // const [newPayOrder, setNewPayOrder] = React.useState(initialPayOrder);

  const {
    formData: newPayOrder,
    handleChange: setNewPayOrder,
    handleBlur,
    resetForm,
  } = useFormWithCache(initialPayOrder, 'product_form', CACHE_DURATION_MS);

  const isMoreThanAllowed = useMemo(() => {
    if (!paymentByCoupon) return false;
    return newPayOrder.amount > couponAmount;
  }, [couponAmount, newPayOrder.amount, paymentByCoupon]);

  const initialOrderValidations = {
    articles: '',
    reference: '',
    shippingAmount: '',
  };

  const [orderValidations, setOrderValidations] = React.useState(
    initialOrderValidations
  );

  const initialWasOnFocus = {
    reference: false,
    shippingAmount: false,
  };

  const [wasOnFocus, setWasOnFocus] = React.useState(initialWasOnFocus);

  function getTotalItems(articles) {
    return articles.reduce(
      (countItems, article) => countItems + Number(article.quantity),
      0
    );
  }

  const [amountEndpointError, setAmountEndpointError] = React.useState('');
  const [referenceEndpointError, setReferenceEndpointError] =
    React.useState('');

  function createPayOrder() {
    setIsLoaderOpen(true);
    let order = {
      store_id: storeId,
      order_reference: newPayOrder.reference.toString(),
      items_count: getTotalItems(newPayOrder.articles),
      shipping_amount: newPayOrder.shippingAmount,
      total: newPayOrder.amount,
      items_attributes: newPayOrder.articles,
    };

    const data = {
      staff_id: user.employeeId().toString(),
      order: order,
    };

    if (paymentByCoupon) {
      data['payment_id'] = paymentId;
    }

    const translationPrefix = 'order.';

    saveLogbehavior({
      userId: user.employeeId().toString(),
      log_behavior_data: logbehavior.current,
    }).then(() => {
      saveLogbehavior({
        userId: user.employeeId().toString(),
        log_behavior_data: `$Loc${location?.lat ?? 0},${location?.lon ?? 0}`,
      });
      logbehavior.current = '';
    });

    createOrder(data)
      .then(response => {
        resetForm();
        setIsLoaderOpen(false);
        if (paymentByCoupon) {
          setShowTimer(true);
          setModalOrderPath(`/orders/${response.data.order_id}/${storeId}`);
        } else {
          goPage(
            `/orders/v1/generated/${response.data.order_id}/${storeId}`,
            history
          );
        }
      })
      .catch(error => {
        setIsLoaderOpen(false);
        const errorCode = error?.response?.data?.code;
        const responseMessage = error?.response?.data?.message;
        const translationMessage = t(translationPrefix + responseMessage, {
          nsSeparator: false,
        });

        AnalyticsEvent().errorTrack({
          errorType: 'pay-order-create-order-error',
          errorDescription: {
            code: errorCode,
            responseMessage: responseMessage,
          },
        });
        switch (errorCode) {
          case 'AmountNotInRange':
            setAmountEndpointError(
              'El monto está fuera del rango permitido por la tienda.'
            );
            return;
          case 'OrderReferenceUsed':
            setReferenceEndpointError('Esta referencia ya existe.');
            return;
          case 'StoreClosed':
          case 'StoreOpenRetrieveFail':
            setOperationError(ErrorText[errorCode]);
            return setErrorModalState(true);
          default:
            setOperationError({
              title: 'Órdenes deshabilitadas',
              description: !translationMessage.includes(translationPrefix)
                ? translationMessage
                : 'Por el momento no puedes generar órdenes. Acude a tu ejecutivo de cuenta para más información.',
            });
            return setErrorModalState(true);
        }
      });
  }

  const handleUpdateForm = () => {
    if (paymentByCoupon && !isValidCoupon) {
      validateFillCoupon(true);
    }
  };

  function setValidReference(value) {
    setWasOnFocus({ ...wasOnFocus, reference: true });
    if (validateReferenceOrSku(value)) {
      setNewPayOrder('reference', value.toUpperCase());
      handleBlur('reference', value.toUpperCase());
      setReferenceEndpointError('');
    }
    handleUpdateForm();
  }

  function setValidShipping(value) {
    if (validateDecimal(value)) {
      setNewPayOrder('shippingAmount', value);
    }
  }

  function shippingInputOnBlur(value) {
    const autoCompleteValue = autoCompleteDecimalNumber(value, 2);
    handleBlur('shippingAmount', autoCompleteValue);
    if (!wasOnFocus.shippingAmount) {
      setWasOnFocus({ ...wasOnFocus, shippingAmount: true });
    }
  }

  function handleValidations() {
    const { articles, reference, shippingAmount, amount } = newPayOrder;
    const validation = {
      articles: articles.length === 0 ? 'No hay artículos agregados' : '',
      reference: validateMinChars(reference, 4, 'Mínimo 4 caracteres'),
      shippingAmount: validateEmptyField(
        shippingAmount,
        'Campo obligatorio, si no aplica escribe "0".'
      ),
      amount: '',
    };
    if (isFeatureEnabled('payment_validator') && isPaymentValidated) {
      validation['amount'] = validateLessThan(
        amount,
        paymentAmount,
        'El monto de la compra excede al disponible.'
      );
      setAmountEndpointError(validation['amount']);
    }
    setDisabledCreateOrderButton(
      validateErrorMessagesFromObject(validation) || storeId === ''
    );
    setOrderValidations(validation);
  }

  React.useEffect(() => {
    if (isPaymentValidated) handleValidations();
  }, [isPaymentValidated, newPayOrder.amount]);

  React.useEffect(() => {
    handleValidations();
  }, [
    newPayOrder.articles,
    newPayOrder.reference,
    newPayOrder.shippingAmount,
    storeId,
  ]);

  React.useEffect(() => {
    setStoreId(storeid);
  });

  function deleteArticle(index_deleted) {
    setNewPayOrder(
      'articles',
      newPayOrder.articles.filter((_article, index) => index !== index_deleted)
    );
  }

  function addArticle(newArticle) {
    setNewPayOrder('articles', [...newPayOrder.articles, newArticle]);
    handleBlur('articles', [...newPayOrder.articles, newArticle]);
    handleUpdateForm();
  }

  React.useEffect(() => {
    const total = handleTotal();
    const autoCompleteTotal = autoCompleteDecimalNumber(total.toFixed(2), 2);
    setNewPayOrder('amount', autoCompleteTotal);
    handleValidations();
  }, [newPayOrder.articles, newPayOrder.shippingAmount]);

  function handleTotal() {
    let sum = newPayOrder.articles.reduce(
      (sum, article) => sum + Number(article.total),
      0
    );
    sum = sum + Number(newPayOrder.shippingAmount);
    return sum;
  }

  const categoriesContext = useCategoriesContext();
  const optionsSelectCategory = categoriesContext.areCategoriesLoaded
    ? categoriesContext?.categories.map(category => {
        return {
          text: category.description,
          value: category.code,
        };
      })
    : [];

  const viewport = useDeviceContextViewport();

  const tableHeaders = {
    SKU: 'SKU o código del artículo',
    productName: 'Nombre del producto',
    category: 'Categoría',
    quantity: 'Cantidad',
    unitaryPrice: 'Precio unitario',
    total: 'Total',
    erase: 'Eliminar',
  };

  if (viewport.isMobile) {
    tableHeaders.SKU = 'SKU o código';
  }

  const TableComponent = viewport.isMobile ? TableMobile : Table;

  const referencePlaceholder = !viewport.isDesktop
    ? 'Ingresa el número de ref.'
    : '';

  const referenceInput = (
    <TextInput
      errorMessage={
        wasOnFocus.reference
          ? orderValidations.reference || referenceEndpointError
          : ''
      }
      placeholder={referencePlaceholder}
      className='reference-input'
      id='by_product_reference'
      name='by_product_reference'
      type='outline'
      label='Referencia de orden'
      required
      value={newPayOrder.reference}
      onChange={e => setValidReference(e.target.value)}
      onBlur={() =>
        !wasOnFocus.reference
          ? setWasOnFocus({ ...wasOnFocus, reference: true })
          : null
      }
      helper='Letras, números y guiones.'
    />
  );

  const tableSize = viewport.isMobile ? 'small' : 'medium';

  const tableForArticles = (
    <TableComponent
      type='b2b'
      size={tableSize}
      className='k-ds-m-bottom-lyt-01 k-ds-width-full k-ds-m-top-sp-07'
    >
      <thead>
        <tr>
          <th>{tableHeaders.SKU}</th>
          <th>{tableHeaders.productName}</th>
          <th>{tableHeaders.category}</th>
          <th>{tableHeaders.quantity}</th>
          <th>{tableHeaders.unitaryPrice}</th>
          <th>{tableHeaders.total}</th>
          <th>{tableHeaders.erase}</th>
        </tr>
      </thead>
      <tbody>
        {newPayOrder.articles.map((item, index) => (
          <tr key={`${index}${item.sku}${item.name}`}>
            <td data-title={tableHeaders.SKU} className='word-break-all'>
              {item.sku}
            </td>
            <td
              data-title={tableHeaders.productName}
              className='word-break-all'
            >
              {item.name}
            </td>
            <td data-title={tableHeaders.category} className='word-break-all'>
              {getCategoryDescription(
                categoriesContext.categories,
                item.category
              )}
            </td>
            <td data-title={tableHeaders.quantity}>{item.quantity}</td>
            <td data-title={tableHeaders.unitaryPrice}>{`$${numberWithCommas(
              item.price
            )}`}</td>
            <td data-title={tableHeaders.total}>{`$${numberWithCommas(
              item.total
            )}`}</td>
            <td className='pointer'>
              {viewport.isMobile ? (
                <Space className='k-ds-width-full'>
                  <Button
                    type='primary-alternate'
                    className='k-ds-m-rigth-sp-02'
                    horizontalPadding='2rem'
                    verticalPadding='0.375rem'
                    size='small'
                    onClick={() => deleteArticle(index)}
                  >
                    {tableHeaders.erase}
                  </Button>
                </Space>
              ) : (
                <Space className='k-ds-width-full' justify='center'>
                  <DeleteIcon onClick={() => deleteArticle(index)} />
                </Space>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </TableComponent>
  );

  const shippingInputClassName = viewport.isMobile
    ? 'mobile-shipping-width'
    : '';

  function closeErrorModal() {
    setErrorModalState(false);
  }

  const shippingInput = (
    <div className='shipping-input'>
      <TextInput
        className={shippingInputClassName}
        errorMessage={
          wasOnFocus.shippingAmount ? orderValidations.shippingAmount : ''
        }
        id='by_product_shipping_cost'
        name='by_product_shipping_cost'
        placeholder='$'
        helper='Escribe "0" si no aplica.'
        value={newPayOrder.shippingAmount}
        onChange={e => setValidShipping(e.target.value)}
        onBlur={e => shippingInputOnBlur(e.target.value)}
      />
    </div>
  );

  const TableProductDesktop = () => {
    if (viewport.isMobile) return;

    return (
      <div>
        <Space
          className='k-ds-width-full k-ds-m-top-sp-06'
          direction='horizontal'
          justify='space-between'
          align='center'
        >
          <div className='k-ds-heading-04'>Resumen de orden</div>
          <Space
            direction='horizontal'
            align='center'
            className='reference-container'
          >
            {referenceInput}
          </Space>
        </Space>
        {tableForArticles}
        <Space
          direction='horizontal'
          align='center'
          className='k-ds-m-top-sp-06'
        >
          <span className='k-ds-heading-02'>
            Costo del envío <span className='k-ds-text-color-error'>*</span>
          </span>
          {shippingInput}

          <Divider
            color='border-alternate'
            thickness='1px'
            type='vertical'
            height='40px'
            margin='2px -7px 2px'
          />
          <div>
            <Space direction='horizontal'>
              <div className='k-ds-heading-02'>Total:</div>
              <div
                className={`k-ds-subheading-02 font-weight-bold ${
                  amountEndpointError
                    ? 'k-ds-text-color-error'
                    : 'k-ds-text-color-interactive-primary'
                }`}
              >
                ${numberWithCommas(newPayOrder.amount)}
              </div>
            </Space>
            <div className='k-ds-label-01 k-ds-text-color-error'>
              {amountEndpointError}
            </div>
          </div>
        </Space>
      </div>
    );
  };

  const TableProductMobile = () => {
    if (!viewport.isMobile) return;
    return (
      <div className='k-ds-width-full' id='product-component'>
        <Divider
          color='interactive-alternate'
          thickness='0.5px'
          margin='spacing-07'
        />
        <div className='k-ds-heading-02 k-ds-m-bottom-sp-06'>
          Artículos agregados
        </div>
        <Space direction='vertical'>{referenceInput}</Space>
        {tableForArticles}
        <Divider
          color='interactive-alternate'
          thickness='0.5px'
          margin='spacing-05'
        />
        <Space
          direction='horizontal'
          align='center'
          className='k-ds-m-top-sp-06'
        >
          <div className='k-ds-heading-02'>
            Costo del envío <span className='k-ds-text-color-error'>*</span>
          </div>
          {shippingInput}
        </Space>
        <Space
          direction='horizontal'
          align='center'
          className='k-ds-m-top-sp-05'
        >
          <div className='k-ds-heading-02'>Total:</div>
          <div
            className={`k-ds-body-02 font-weight-bold ${
              amountEndpointError
                ? 'k-ds-text-color-error'
                : 'k-ds-text-color-interactive-primary'
            }`}
          >
            ${numberWithCommas(newPayOrder.amount)}
          </div>
        </Space>
        <div className='k-ds-label-01 k-ds-text-color-error'>
          {amountEndpointError}
        </div>
      </div>
    );
  };

  return (
    <div className='k-ds-width-full' id='product-component'>
      <div
        className='k-ds-width-full k-ds-m-top-sp-06'
        style={{
          paddingBottom: '20px',
          borderBottom: '1px solid var(--k-ds-color-border)',
        }}
      >
        <ArticleToAdd
          onAddArticle={addArticle}
          optionsSelectCategory={optionsSelectCategory}
        />
      </div>
      {isMoreThanAllowed && isValidCoupon && (
        <Message
          type='error'
          className='k-ds-m-top-sp-08 k-ds-m-bottom-sp-08'
          message={
            <div className='k-ds-heading-01'>
              <div className='font-weight-normal'>
                <span className='font-weight-bold'>
                  El monto total excede el límite preaprobado del cliente.
                </span>{' '}
                Por favor, infórmale que el monto de su compra debe ser igual o
                menor al que tiene preaprobado.
              </div>
            </div>
          }
        />
      )}
      {TableProductMobile()}
      {TableProductDesktop()}

      <Space
        className='k-ds-width-full k-ds-m-top-sp-08 k-ds-m-bottom-sp-09'
        direction='horizontal'
      >
        <Button
          type='primary-alternate'
          className='k-ds-m-rigth-sp-02'
          horizontalPadding='1.125rem'
          verticalPadding='1rem'
          size='small'
          onClick={handleOnClick}
          disabled={
            (!paymentId.length && paymentByCoupon) ||
            isMoreThanAllowed ||
            disabledCreateOrderButton ||
            (isFeatureEnabled('payment_validator') &&
              !isPaymentValidated &&
              isPaymentRequired)
          }
        >
          Cobrar
        </Button>
        <Button
          type='simple'
          size='small'
          horizontalPadding='1.125rem'
          verticalPadding='1rem'
          onClick={handleOnClear}
        >
          Cancelar
        </Button>
      </Space>
      <ErrorModal
        defaultOpened={errorModalState}
        close={closeErrorModal}
        heading={operationError.title}
        description={operationError.description}
      />
      {showTimer && <ModalRedirect path={modalOrderPath} />}
    </div>
  );

  function handleOnClick() {
    createPayOrder();
  }

  function handleOnClear() {
    setOrderValidations(initialOrderValidations);
    setDisabledCreateOrderButton(true);
    setWasOnFocus(initialWasOnFocus);
    setAmountEndpointError('');
    setReferenceEndpointError('');
    resetForm();
    onClear();
  }
}

Product.propTypes = {
  storeid: PropTypes.string.isRequired,
  isPaymentRequired: PropTypes.bool,
  isPaymentValidated: PropTypes.bool,
  onClear: PropTypes.func,
  openPaymentValidationModal: PropTypes.func,
  validateFillCoupon: PropTypes.func,
  isValidCoupon: PropTypes.bool,
  location: PropTypes.object,
  logbehavior: PropTypes.object,
  paymentAmount: PropTypes.number,
  paymentId: PropTypes.string,
  couponAmount: PropTypes.number,
  paymentByCoupon: PropTypes.bool,
};

export default Product;
