import { makeAutoObservable } from 'mobx';
import { MERCHANT_TRANSACTION_TYPE_FILTER } from './../../Utils/constants';
import getChannelByRole from './../../Utils/getChannelByRole';

const initFilters = {
  paymentSchedules: {
    merchantId: '',
  },
  paymentSummary: {
    paymentScheduleId: '',
  },
  paymentReport: {
    page: 1,
    pageSize: 15,
    paymentScheduleId: '',
    transactionType: [],
    channel: [],
    orderId: '',
    merchantId: '',
  },
};

export class MerchantReportPaymentsStore {
  paymentSchedules = {
    merchantId: '',
  };

  paymentSummary = {
    paymentScheduleId: '',
  };

  paymentReport = {
    page: 1,
    pageSize: 15,
    paymentScheduleId: '',
    transactionType: [],
    channel: [],
    orderId: '',
    merchantId: '',
  };

  constructor() {
    makeAutoObservable(this);
    this.initPaymentSchedulesFilter();
    this.initPaymentSummaryFilter();
    this.initPaymentReportFilter();
  }

  initPaymentSchedulesFilter = (otherFilters = {}) => {
    this.paymentSchedules = {
      ...initFilters.paymentSchedules,
      ...otherFilters,
    };
  };

  initPaymentSummaryFilter = (otherFilters = {}) => {
    this.paymentSummary = { ...initFilters.paymentSummary, ...otherFilters };
  };

  initPaymentReportFilter = (otherFilters = {}) => {
    const channelResult = getChannelByRole();

    this.paymentReport = {
      ...initFilters.paymentReport,
      ...otherFilters,
      channel: channelResult.map(item => item.value),
      transactionType: MERCHANT_TRANSACTION_TYPE_FILTER.map(item => item.value),
    };
  };

  updatePaymentSchedulesFilter = (newFilters = {}) => {
    this.paymentSchedules = {
      ...this.paymentSchedules,
      ...newFilters,
    };
  };

  updatePaymentSummaryFilter = (newFilters = {}) => {
    this.paymentSummary = {
      ...this.paymentSummary,
      ...newFilters,
    };
  };

  updatepaymentReportFilter = (newFilters = {}) => {
    this.paymentReport = {
      ...this.paymentReport,
      ...newFilters,
    };
  };
}
