import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { validateLength, validateNumerical } from '../../Utils/formValidations';
import { TextInput } from '../../ds';
import { getZipCodeInfo } from '../../services/Application';
import { Select } from '../DS/Select';

export const isValidZipCode = zipCode => {
  return validateLength(zipCode, 5) && validateNumerical(zipCode);
};

export const createOption = value => {
  return { value, label: value };
};

export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

const ZipCodeForm = ({
  onChange,
  onError,
  errors,
  zipCode,
  neighborhood,
  city,
  state,
}) => {
  const [formErrors, setFormErrors] = useState({
    zipCode: errors?.zip_code
      ? 'Este campo no puede quedar vacío. ¿Lo puedes completar?'
      : '',
    neighborhood: errors?.neighborhood?.length
      ? 'Este campo no puede quedar vacío. ¿Lo puedes completar?'
      : '',
    city: errors?.city?.length
      ? 'Este campo no puede quedar vacío. ¿Lo puedes completar?'
      : '',

    state: errors?.state?.length
      ? 'Este campo no puede quedar vacío. ¿Lo puedes completar?'
      : '',
  });
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);

  const [componentState, setComponentState] = useState({
    zipCode: zipCode ?? '',
    neighborhood: neighborhood ?? '',
    city: city ?? '',
    state: state ?? '',
  });

  const validZipCodeDefaultValue = isValidZipCode(zipCode ?? '');
  const [validZipCode, setValidZipCode] = useState(validZipCodeDefaultValue);

  const isValid = () => {
    return (
      validZipCode &&
      componentState.neighborhood !== '' &&
      componentState.city !== '' &&
      componentState.state !== ''
    );
  };

  useEffect(() => {
    if (onChange) onChange(componentState, isValid());
  }, [componentState]);

  useEffect(() => {
    if (onError) onError(formErrors);
  }, [formErrors]);

  useEffect(() => {
    if (!validZipCode) {
      const defaultState = {
        city: '',
        neighborhood: '',
        state: '',
      };
      setCities([]);
      setNeighborhoods([]);
      setStates([]);
      setComponentState({ ...componentState, ...defaultState });
      return;
    }
    getZipCodeInfo(componentState.zipCode).then(response => {
      const zip_codes = response.zip_codes;
      if (zip_codes.length === 0) {
        setFormErrors({ ...formErrors, zipCode: 'El codigo postal no existe' });
        return;
      }
      const fetch_neighborhoods = zip_codes
        .map(value => value.d_asenta)
        .filter(onlyUnique)
        .map(createOption);
      const fetch_cities = zip_codes
        .map(value => value.d_mnpio)
        .filter(onlyUnique)
        .map(createOption);
      const fetch_states = zip_codes
        .map(value => value.d_estado)
        .filter(onlyUnique)
        .map(createOption);

      setNeighborhoods(fetch_neighborhoods);
      setCities(fetch_cities);
      setStates(fetch_states);

      const defaultValues = {};

      if (fetch_neighborhoods.length === 1 && !componentState.neighborhood)
        defaultValues.neighborhood = fetch_neighborhoods[0].value;
      else defaultValues.neighborhood = componentState.neighborhood;

      if (fetch_cities.length === 1 && !componentState.city)
        defaultValues.city = fetch_cities[0].value;
      else defaultValues.city = componentState.city;

      if (fetch_states.length === 1 && !componentState.state)
        defaultValues.state = fetch_states[0].value;
      else defaultValues.state = componentState.state;

      setComponentState({ ...componentState, ...defaultValues });
    });
  }, [validZipCode]);

  const handleZipCode = event => {
    const zipCode = event.target.value;
    setComponentState({ ...componentState, zipCode });
    if (!isValidZipCode(zipCode)) {
      setValidZipCode(false);
      setFormErrors({
        ...formErrors,
        zipCode: 'El código postal debe ser de 5 dígitos',
      });
      return;
    }
    setValidZipCode(true);
    setFormErrors({ ...formErrors, zipCode: '' });
  };

  return (
    <>
      <TextInput
        className='form-item'
        name='zipCode'
        errorMessage={formErrors['zipCode']}
        required
        label='Código postal'
        placeholder='11520'
        type='outline'
        value={componentState.zipCode}
        onChange={handleZipCode}
      />
      <Select
        className='form-item'
        name='neighborhood'
        errorMessage={formErrors['neighborhood']}
        options={neighborhoods}
        label='Colonia o barrio'
        placeholder='Selecciona'
        type='outline'
        disabled={neighborhoods.length === 0}
        required
        value={componentState.neighborhood}
        onChange={value =>
          setComponentState({ ...componentState, neighborhood: value })
        }
      />
      <Select
        className='form-item'
        name='city'
        errorMessage={formErrors['city']}
        options={cities}
        label='Ciudad'
        placeholder='Selecciona'
        type='outline'
        disabled={cities.length === 0}
        required
        value={componentState.city}
        onChange={value =>
          setComponentState({ ...componentState, city: value })
        }
      />
      <Select
        className='form-item'
        name='state'
        errorMessage={formErrors['state']}
        options={states}
        label='Estado'
        placeholder='Selecciona'
        type='outline'
        disabled={states.length === 0}
        required
        value={componentState.state}
        onChange={value =>
          setComponentState({ ...componentState, state: value })
        }
      />
    </>
  );
};

ZipCodeForm.propTypes = {
  onChange: PropTypes.func,
  onError: PropTypes.func,
  zipCode: PropTypes.string,
  neighborhood: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  errors: PropTypes.object,
};

export default ZipCodeForm;
