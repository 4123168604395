import styled, { css } from 'styled-components';
import React from 'react';
import KDS from '../../lib/k-ds-foundation';

const StyledCard = styled.div`
  border-radius: 16px;
  box-sizing: border-box;
  padding: 24px;

  ${(props) => props.shadow && css`
    ${KDS.setBoxShadowWithFallback('shadow-base')}
  `}

  ${(props) => ((props.border && props.border !== '0' && css`
    ${KDS.setColorTokenWithFallback('border: 1px solid ', props.border)}
  `) || (props.border && props.border === '0' && css`
    border: 0;
  `))}

  ${(props) => props.background && css`
    ${`background: ${props.background};`}
  `}

  .title {
    margin: 0 0 24px 0;
  }
`;

const MAP_CARD_BACKGROUND = {
  'primary': 'background-primary',
  'secondary': 'background-secondary',
};

const MAP_CARD_BORDER = {
  'primary': '0',
  'secondary': 'border',
};

const Card = ({
  className, children, type, backgroundColor, title, subHeader, ...moreProps
}) => {
  const defaultCardType = 'primary';
  const bgColor = KDS.getColorToken(backgroundColor) || KDS.getPrimitive('color', backgroundColor) || backgroundColor;
  const background = bgColor || KDS.getColorToken(MAP_CARD_BACKGROUND[type]) || KDS.getColorToken(MAP_CARD_BACKGROUND[defaultCardType]);
  const border = MAP_CARD_BORDER[type] || MAP_CARD_BORDER[defaultCardType];

  const props = { ...moreProps, background, border };
  const cardClassName = className ? `${className} k-ds-body-01` : 'k-ds-body-01';

  return (
    <StyledCard className={cardClassName} {...props}>
      { title ? (<h1 className="title k-ds-heading-04">{title}</h1>) : null }
      { subHeader ? (<h2 className="k-ds-body-02 k-ds-text-color-secondary">{subHeader}</h2>) : null }
      {children}
    </StyledCard>
  );
};

export {
  Card,
};
