import React from 'react';
import { ToastError } from './ToastError';
import { ToastInformation } from './ToastInformation';
import { ToastSuccess } from './ToastSuccess';
import { ToastWarning } from './ToastWarning';

const MAP_TOAST_TYPE = {
  'error': ToastError,
  'information': ToastInformation,
  'success': ToastSuccess,
  'warning': ToastWarning,
};

const Toast = ({
  type, ...moreProps
}) => {
  const defaultToastType = 'information';
  const Component = MAP_TOAST_TYPE[type] || MAP_TOAST_TYPE[defaultToastType];

  return (
    <Component {...moreProps} />
  );
};

export {
  Toast,
};
