import React, { useState } from 'react';
import BaseFilter from '../BaseFilter/BaseFilter';
import PropTypes from 'prop-types';
import { ReactComponent as StatusIcon } from './status-icon.svg';
import { ReactComponent as LoggedIcon } from './logged-icon.svg';
import { ReactComponent as RoleIcon } from './role-icon.svg';
import { ReactComponent as CreationIcon } from './creation-icon.svg';

const UsersFilter = props => {
  const initialStatusFilter = [
    {
      value: 1,
      label: 'Todos',
      checked: false,
    },
    {
      value: 2,
      label: 'Activo',
      checked: false,
    },
    {
      value: 3,
      label: 'Inactivo',
      checked: false,
    },
    {
      value: 4,
      label: 'Suspendido',
      checked: false,
    },
  ];
  const initialLoggedFilter = [
    {
      value: 1,
      label: 'Todos',
      checked: false,
    },
    {
      value: 2,
      label: 'Aceptado',
      checked: false,
    },
    {
      value: 3,
      label: 'Pendiente',
      checked: false,
    },
  ];
  const initalRoleFilter = [
    {
      value: 1,
      label: 'Todos',
      checked: false,
    },
    {
      value: 2,
      label: 'Administrador de sucursal',
      checked: false,
    },
    {
      value: 3,
      label: 'Vendedor',
      checked: false,
    },
  ];
  const [statusFilter, setStatusFilter] = useState(
    props.filters?.statusFilter
      ? props.filters.statusFilter
      : initialStatusFilter
  );
  const [loggedFilter, setLoggedFilter] = useState(
    props.filters?.loggedFilter
      ? props.filters.loggedFilter
      : initialLoggedFilter
  );
  const [roleFilter, setRoleFilter] = useState(
    props.filters?.roleFilter ? props.filters.roleFilter : initalRoleFilter
  );
  const [startDate, setStartDate] = useState(
    props.filters?.startDate ? props.filters.startDate : ''
  );
  const [endDate, setEndDate] = useState(
    props.filters?.endDate ? props.filters.endDate : ''
  );

  function onChangeStatusFilter(index) {
    let statusFilterUpdated = [...statusFilter];
    statusFilterUpdated[index] = {
      ...statusFilterUpdated[index],
      checked: !statusFilterUpdated[index].checked,
    };
    setStatusFilter(statusFilterUpdated);
  }

  function onChangeLoggedFilter(index) {
    let loggedFilterUpdated = [...loggedFilter];
    loggedFilterUpdated[index] = {
      ...loggedFilterUpdated[index],
      checked: !loggedFilterUpdated[index].checked,
    };
    setLoggedFilter(loggedFilterUpdated);
  }

  function onChangeRoleFilter(index) {
    let roleFilterUpdated = [...roleFilter];
    roleFilterUpdated[index] = {
      ...roleFilterUpdated[index],
      checked: !roleFilterUpdated[index].checked,
    };
    setRoleFilter(roleFilterUpdated);
  }

  const filterInputs = [
    {
      interface: 'select',
      label: 'Estatus',
      name: 'status',
      options: statusFilter,
      onChange: onChangeStatusFilter,
      icon: <StatusIcon />,
    },
    {
      interface: 'select',
      label: 'Usuario logeado',
      name: 'logged',
      options: loggedFilter,
      onChange: onChangeLoggedFilter,
      icon: <LoggedIcon />,
    },
    {
      interface: 'select',
      label: 'Roles',
      name: 'role',
      options: roleFilter,
      onChange: onChangeRoleFilter,
      icon: <RoleIcon />,
    },
    {
      interface: 'date',
      label: 'Fecha de creación',
      name: 'creation_date',
      startDate: startDate,
      endDate: endDate,
      setStartDate: setStartDate,
      setEndDate: setEndDate,
      icon: <CreationIcon />,
    },
  ];

  function onReset() {
    setStartDate('');
    setEndDate('');
    setStatusFilter(initialStatusFilter);
    setLoggedFilter(initialLoggedFilter);
    setRoleFilter(initalRoleFilter);
  }

  return (
    <BaseFilter
      onApply={() => {
        props.onFilterChange({
          statusFilter,
          loggedFilter,
          roleFilter,
          startDate,
          endDate,
        });
      }}
      onReset={onReset}
      filterInputs={filterInputs}
    />
  );
};

UsersFilter.propTypes = {
  filters: PropTypes.object,
  onFilterChange: PropTypes.func.isRequired,
};

export default UsersFilter;
