const ExcelJS = require('exceljs');
import { saveAs } from 'file-saver';
import { excelFileCreatorName, excelFileRowsLimit } from './constants';

const excelFileCreator = (headers = []) => {
  const workbook = new ExcelJS.Workbook();
  workbook.creator = excelFileCreatorName;
  workbook.created = new Date(Date.now());

  const sheet = workbook.addWorksheet('Hoja 1');

  sheet.columns = headers.map(header => ({
    header: header,
  }));

  sheet.columns.forEach(column => {
    column.width = column.header.length < 10 ? 10 : column.header.length;
  });

  return workbook;
};

const excelFileDownloader = (workbook, title) => {
  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, `${title}.xlsx`);
  });
};

const getJsonFromExcel = async (file, sheetName) => {
  return new Promise(resolve => {
    const exportedJson = [];
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = async () => {
      const buffer = reader.result;
      const wbLoad = new ExcelJS.Workbook();
      const workbook = await wbLoad.xlsx.load(buffer);
      const worksheet = workbook.getWorksheet(sheetName ?? 1);
      const rowNames = [];
      worksheet.getRow(1).eachCell(function (cell, colNumber) {
        rowNames.push({ name: cell.value, index: colNumber });
      });
      worksheet.eachRow(function (row, rowNumber) {
        if (rowNumber > 1) {
          const rowObj = {};
          row.eachCell(function (cell, colNumber) {
            rowNames.forEach(function (rowName) {
              if (rowName.index === colNumber) {
                rowObj[rowName.name] = cell.value.hyperlink
                  ? cell.value.text
                  : cell.value;
              }
            });
          });
          exportedJson.push(rowObj);
        }
      });
      resolve(exportedJson);
    };
  });
};

const excelAddRowDropdown = (workbook, sheetNr, rowNr, colNr, values) => {
  const worksheet = workbook.worksheets[sheetNr];
  const row = worksheet.getRow(rowNr);
  const cell = row.getCell(colNr);
  cell.protection = {
    locked: false,
  };
  cell.dataValidation = {
    type: 'list',
    allowBlank: false,
    formulae: [values],
    showErrorMessage: true,
    errorStyle: 'error',
    errorTitle: 'Valor inválido',
    error: `El valor debe estra dentro de los valores permitidos ${values}`,
  };
  return workbook;
};

const excelAddColumnDropdown = (workbook, sheetNr, colNr, values) => {
  for (let i = 2; i <= excelFileRowsLimit; i++) {
    workbook = excelAddRowDropdown(workbook, sheetNr, i, colNr, values);
  }

  return workbook;
};

const validateExcelFile = async (file, fixedHeaders, sheetName = '') => {
  ///agregar nombre de hoja, en caso de tener instrucciones
  const workbook = new ExcelJS.Workbook();
  const reader = new FileReader();
  const data = await new Promise(resolve => {
    reader.onload = e => resolve(new Uint8Array(e.target.result));
    reader.readAsArrayBuffer(file);
  });
  await workbook.xlsx.load(data);
  let sheetNumber = 0;
  if (workbook.worksheets.length > 1) {
    const indx = workbook.worksheets.findIndex(item => item.name === sheetName);
    sheetNumber = indx !== -1 ? indx : 0;
  }
  const worksheet = workbook.worksheets[sheetNumber];
  const headers = worksheet.getRow(1).values;
  const expectedHeaders = fixedHeaders;

  const isValidFile = expectedHeaders.every(header => headers.includes(header));

  return isValidFile;
};

export {
  validateExcelFile,
  excelFileCreator,
  excelFileDownloader,
  excelAddRowDropdown,
  excelAddColumnDropdown,
  getJsonFromExcel,
};
