import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styles from './carousel.module.scss';

const Carousel = ({
  banners,
  segTimer = 5000,
  stopInEnd = false,
  hideArrows = false,
  height = 'auto',
  handleUpdateParent = null,
}) => {
  const [isIntervalActive, setIsIntervalActive] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    handleUpdateParent && handleUpdateParent(currentIndex);
  }, [handleUpdateParent, currentIndex]);

  useEffect(() => {
    if (isIntervalActive) {
      const interval = setInterval(() => {
        setCurrentIndex(prevIndex => {
          const nextIndex = (prevIndex + 1) % banners.length;
          if (stopInEnd && nextIndex === 0) {
            clearInterval(interval); // Stop the interval when reaching the end
            setIsIntervalActive(false); // Set the interval as inactive
            return prevIndex;
          }
          return nextIndex;
        });
      }, segTimer);

      return () => clearInterval(interval);
    }
  }, [banners, currentIndex, segTimer, stopInEnd, isIntervalActive]);

  const handleDotClick = index => {
    setIsIntervalActive(false); // Stop the interval when a dot is clicked
    setCurrentIndex(index);
  };

  const handleNextSlide = () =>
    setCurrentIndex(prevIndex => (prevIndex + 1) % banners.length);

  const handlePrevSlide = () =>
    setCurrentIndex(prevIndex => {
      const newIndex = prevIndex - 1;
      return newIndex >= 0 ? newIndex : banners.length - 1;
    });

  const LeftArrow = () => (
    <svg
      className={styles.leftArrow}
      onClick={handlePrevSlide}
      stroke='currentColor'
      fill='currentColor'
      strokeWidth='0'
      viewBox='0 0 24 24'
      height='1em'
      width='1em'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path fill='none' d='M0 0h24v24H0V0z' opacity='.87'></path>
      <path d='M17.51 3.87L15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13z'></path>
    </svg>
  );

  const RightArrow = () => (
    <svg
      className={styles.rightArrow}
      onClick={handleNextSlide}
      stroke='currentColor'
      fill='currentColor'
      strokeWidth='0'
      viewBox='0 0 24 24'
      height='1em'
      width='1em'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path fill='none' d='M0 0h24v24H0V0z'></path>
      <path d='M6.23 20.23L8 22l10-10L8 2 6.23 3.77 14.46 12z'></path>
    </svg>
  );

  return (
    <div className={styles['carousel-container']}>
      <div
        className={cn(styles['carousel-slider'])}
        style={{ height, transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {banners.map((banner, index) => (
          <div
            key={index}
            className={cn(styles['carousel-item'], {
              [styles.active]: index === currentIndex,
            })}
          >
            {banner}
          </div>
        ))}
      </div>
      {!hideArrows && (
        <>
          <LeftArrow />
          <RightArrow />
        </>
      )}
      <div className={styles['carousel-dots']}>
        {banners.map((_, index) => (
          <span
            key={index}
            className={cn(styles.dot, {
              [styles.active]: index === currentIndex,
            })}
            onClick={() => handleDotClick(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

Carousel.propTypes = {
  banners: PropTypes.node,
  segTimer: PropTypes.number,
  height: PropTypes.string,
  stopInEnd: PropTypes.bool,
  hideArrows: PropTypes.bool,
  handleUpdateParent: PropTypes.func,
};

export default Carousel;
