import { cleanLocalStorage } from '../../components/Navbar/LogoutIcon';
import { getStage } from '../environment';
import axios from 'axios';
let stage = getStage();
const INVALID_TOKEN_MSG = 'Invalid access token';

const RequestorProptypes = {
  client: null,
  initClient: async function () {
    this.client = axios.create();
  },
  getRequest: async function (req, options) {
    const newOptions = {
      disableToken: Boolean(options) && options.disableToken,
      disableLogger: Boolean(options) && options.disableLogger,
    };

    try {
      if (this.client === null) {
        await this.initClient();
      }

      let host = process.env.REACT_APP_API_PRODUCTION;

      if (stage === 'development') {
        host = process.env.REACT_APP_API_STAGING;
      }

      let authHeaders = {};
      if (!newOptions.disableToken) {
        const token = JSON.parse(window.localStorage.getItem('token'));
        authHeaders = {
          Authorization: `${token?.token_type} ${token?.access_token}`,
        };
      }

      req.headers = {
        ...(req.headers || {}),
        ...authHeaders,
      };

      const response = await this.client.request({
        ...req,
        url: `${host}${req.url}`,
      });

      if (response.data.status === 'fail') {
        throw response.data;
      }

      return response.data;
    } catch (error) {
      if (!newOptions.disableLogger) {
        console.error(error);
      }
      const { response } = error;
      if (
        response &&
        response.status === 401 &&
        (response.statusText === 'Unauthorized' ||
          response.data?.message === INVALID_TOKEN_MSG)
      ) {
        cleanLocalStorage();
        return;
      }

      throw error;
    }
  },
  getUnSignedRequest: async function (req) {
    if (this.client === null) {
      await this.initClient();
    }

    this.client.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error && error.response) {
          return Promise.reject(error.response);
        }

        return Promise.reject(error);
      }
    );

    let host = process.env.REACT_APP_API_PRODUCTION;

    if (stage === 'development') {
      host = process.env.REACT_APP_API_STAGING;
    }

    const response = await this.client.request({
      ...req,
      url: `${host}${req.url}`,
    });

    return response.data;
  },
  getNotHostRequest: async function (req) {
    if (this.client === null) {
      await this.initClient();
    }

    this.client.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error && error.response) {
          return Promise.reject(error.response);
        }

        return Promise.reject(error);
      }
    );

    const response = await this.client.request({
      ...req,
      url: req.url,
    });

    return response.data;
  },
};

export default function Requestor() {
  return Object.create(RequestorProptypes);
}
