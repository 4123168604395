import React from 'react';
import { createColumnHelper } from '../../../Utils/ReactTable';
import { hyphenEveryFourChars } from '../../../Utils/formats';
import { currencyFormatter } from '../../../Utils/formats';
import ButtonLinkStyled from './../components/ButtonLink/ButtonLinkStyled';
import Timer from '../../../Utils/Timer';
import { Tag } from '../../../ds';

const columnHelper = createColumnHelper();

const tableHeaders = {
  channel: 'Canal',
  transactionId: 'ID de Transacción',
  depositDate: 'Fecha de transacción',
  storeId: 'ID de tienda',
  paymentId: 'ID de pago',
  orderReference: 'Referencia de compra',
  status: 'Transacción',
  total: 'Monto',
  storeName: 'Tienda',
};

const TransactionsReportColumns = ({
  onCancel = () => null,
  onDetailsPage = () => null,
  disableCancelButton = false,
}) => {
  const columns = [
    columnHelper.accessor('channel', {
      header: tableHeaders.channel,
      cell: props => {
        const value = props.getValue() || '';
        if (['online', 'ecommerce'].includes(value)) {
          return 'Tienda en línea';
        }

        return 'Tienda fisica';
      },
    }),
    columnHelper.accessor('order_id', {
      header: tableHeaders.transactionId,
      cell: props => {
        return (
          <>
            {props.getValue() ? (
              <ButtonLinkStyled
                type='link'
                onClick={() => onDetailsPage(props.row.original)}
                textLeft
              >
                {props.getValue()}
              </ButtonLinkStyled>
            ) : (
              ''
            )}
          </>
        );
      },
    }),
    columnHelper.accessor('payment_created_at', {
      header: tableHeaders.depositDate,
      size: 100,
      cell: props =>
        props.getValue()
          ? Timer().toLocal(props.getValue(), 'dd/MM/yyyy HH:mm:ss')
          : '',
    }),
    columnHelper.accessor('store_id', {
      header: tableHeaders.storeId,
      size: 140,
      cell: props =>
        props.getValue()
          ? hyphenEveryFourChars(props.getValue().toString())
          : '-',
    }),
    columnHelper.accessor('payment_id', {
      header: tableHeaders.paymentId,
      size: 120,
      cell: props =>
        props.getValue()
          ? hyphenEveryFourChars(props.getValue().toString())
          : '-',
    }),
    columnHelper.accessor('order_reference', {
      header: tableHeaders.orderReference,
    }),
    columnHelper.accessor('status', {
      header: tableHeaders.status,
      size: 100,
      cell: props => {
        const value = props.getValue();
        const { row } = props;

        if (value === 'success') {
          if (row.original.refunded_amount > 0) {
            return (
              <Tag type='default' color='red'>
                Cancelación parcial
              </Tag>
            );
          }

          return (
            <Tag type='default' color='blue'>
              Pago
            </Tag>
          );
        }

        if (value === 'refunded') {
          return (
            <Tag type='default' color='red'>
              Cancelación total
            </Tag>
          );
        }

        return (
          <Tag type='default' color='cyan'>
            {value}
          </Tag>
        );
      },
    }),
    columnHelper.accessor('total', {
      header: tableHeaders.total,
      size: 100,
      cell: props =>
        props.getValue() ? currencyFormatter(props.getValue()) : '$0',
    }),
    columnHelper.accessor('store_name', {
      header: tableHeaders.storeName,
      size: 170,
    }),
  ];

  if (!disableCancelButton) {
    columns.push(
      columnHelper.accessor('action', {
        header: 'Cancelar',
        size: 100,
        cell: ({ row }) => {
          if (row.original.status === 'refunded') {
            return <div>-</div>;
          }

          if (row.original.available_money_to_refund > 0) {
            return (
              <ButtonLinkStyled
                type='link'
                onClick={() => onCancel(row.original)}
              >
                Cancelar
              </ButtonLinkStyled>
            );
          }

          return <div>-</div>;
        },
      })
    );
  }

  return columns;
};

export default TransactionsReportColumns;
