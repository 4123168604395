import React from 'react';
import styles from './banners.module.scss';
import Magento from './res/magento.png';
import Nube from './res/nube.png';
import Presta from './res/prestashop.png';
import Shopify from './res/shopify.png';
import Vtext from './res/vtex.png';
import Woo from './res/woo.png';
const Banner3 = () => {
  return (
    <div className={styles.banner3}>
      <div className={styles.appIntegration}>
        <div className={styles.appLogo}>
          <img src={Shopify} />
        </div>
        <div className={styles.appLogo}>
          <img src={Magento} />
        </div>
        <div className={styles.appLogo}>
          <img src={Vtext} />
        </div>
        <div className={styles.appLogo}>
          <img src={Nube} />
        </div>
        <div className={styles.appLogo}>
          <img src={Presta} />
        </div>
        <div className={styles.appLogo}>
          <img src={Woo} />
        </div>
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.header}>
          Integrar a Kueski Pay es fácil y sin intermediarios
        </div>
        <div className={styles.infoText}>
          <ul>
            <li>Súmalo a tu plataforma de e-commerce.</li>
            <li>Agréganos a tu pasarela de pagos a través de un SDK.</li>
          </ul>
        </div>
        <span className={styles.subText}>
          Más de 10,000 tiendas ya usan Kueski Pay
        </span>
      </div>
    </div>
  );
};

export default Banner3;
