import styled, { css } from 'styled-components';
import React from 'react';
import { ReactComponent as IconSearch } from './icon-search.svg';
import KDS from '../../lib/k-ds-foundation';

const StyledDiv = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 14px;

  background: white;
  border: 1px solid ${KDS.getColorToken('interactive-alternate')};

  border-radius: 50px;
  padding-left: 14px;

  width: ${(props) => (props.width ? props.width : '100%')};

  svg {
    flex-shrink: 0;
  }

  &:focus-within {
    border: 1px solid ${KDS.getColorToken('interactive-primary')};
  }
`;

const StyledInput = styled.input`
  display: flex;
  flex-grow: 1;
  border: 0;
  width: 100%;

  ${KDS.setColorWithFallback('background: ', 'transparent')}
  ${KDS.setColorTokenWithFallback('color: ', 'interactive-alternate')}

  &:enabled {
    ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}

    &:hover {
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-secondary')}
      ::placeholder {
        ${KDS.setColorTokenWithFallback('color: ', 'interactive-secondary')}
      }
    }

    &:focus-visible {
      outline: 0;
    }

    &:focus {
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary')}
    }

    ::placeholder {
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-alternate')}
    }
  }

  &:disabled {
    ::placeholder {
      ${KDS.setColorTokenWithFallback('color: ', 'text-placeholder')}
    }
  }
`;

const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border: 0;
  border-radius: 50px;
  padding: 14px 32px;

  ${KDS.setColorTokenWithFallback('background: ', 'interactive-primary')}
  cursor: pointer;

  ${KDS.setTypographyWithFallback({
    'font-family': 'font-primary',
    'font-weight': 'font-bold',
    'font-size': 'text-sm',
    'letter-spacing': 'tracking-wide',
    'line-height': 'snug',
  })}
  font-style: normal;
  text-align: center;
  ${KDS.setColorTokenWithFallback('color: ', 'text-alternate')}

  &:enabled {
    &:hover {
      ${KDS.setColorTokenWithFallback('background: ', 'interactive-primary-hover')}
    }

    &:active:not(focus) {
      ${KDS.setColorTokenWithFallback('background: ', 'interactive-primary-hover')}
      border: 0;
    }
  }

  &:disabled {
    ${KDS.setColorTokenWithFallback('background: ', 'background-alternate')}
    cursor: default;

    ${KDS.setColorTokenWithFallback('color: ', 'interactive-alternate')}
  }
`;

const SearchBar = ({
  className, search, setSearch, placeholder, disabled, onSearch, ...moreProps
}) => {
  const disabledProp = disabled ? { disabled: true } : {};
  return (
    <StyledDiv className={className} {...moreProps}>
      <IconSearch />
      <StyledInput
        className="k-ds-body-01"
        value={search}
        placeholder={placeholder}
        onChange={(e) => (setSearch ? setSearch(e.target.value) : null)}
        {...disabledProp}
      />
      <StyledButton onClick={onSearch} {...disabledProp}>Buscar</StyledButton>
    </StyledDiv>
  );
};

export {
  SearchBar,
};
