import { DateTime, Interval } from 'luxon';

const TimerProptypes = {
  toLocal: function (dateISO, formmat) {
    if (!dateISO) return '';

    if (!formmat) {
      return DateTime.fromISO(dateISO).toLocal().toFormat('yyyy-MM-dd');
    }

    return DateTime.fromISO(dateISO).toLocal().toFormat(formmat);
  },
  toISOString: function (dateTex, format = '') {
    if (!dateTex) return '';

    const dateTime = dateTex.split('-');
    const dateLocal = DateTime.local(
      Number(dateTime[0]),
      Number(dateTime[1]),
      Number(dateTime[2])
    );

    if (format) {
      return DateTime.fromISO(dateLocal.toISO()).toFormat(format);
    }

    return dateLocal.toISO();
  },
  toNowFormat: function (format) {
    return this.toLocal(DateTime.now().toISO(), format);
  },
  toNowISO: function (format = '') {
    if (format) {
      return DateTime.fromISO(DateTime.now().toISO()).toFormat(format);
    }

    return DateTime.now().toISO();
  },
  toNowUTC: function () {
    return DateTime.utc().toISO();
  },
  hasSame: function (dateOne, dateTwo, unit) {
    const end = DateTime.fromISO(dateOne);
    const start = DateTime.fromISO(dateTwo);

    return start.hasSame(end, unit);
  },
  diff: function (dateStart, dateEnd, unit) {
    const end = DateTime.fromISO(dateEnd);
    const start = DateTime.fromISO(dateStart);

    return end.diff(start, unit).toObject();
  },
  fromDateTimes: function (dateStart, dateEnd, lengths) {
    const dateISOStart = DateTime.fromISO(dateStart);
    const dateISOEnd = DateTime.fromISO(dateEnd);

    const interval = Interval.fromDateTimes(dateISOStart, dateISOEnd);
    return interval.length(lengths);
  },
  toUTC: function (dateISO, formmat) {
    if (!dateISO) return '';

    if (!formmat) {
      return DateTime.fromISO(dateISO)
        .toUTC()
        .toFormat('dd-MM-yyyy HH:mm:ss \'UTC\'');
    }

    return DateTime.fromISO(dateISO).toUTC().toFormat(formmat);
  },
  toFormat: function (dateISO, formmat, locale = 'es-MX') {
    if (!dateISO) return '';

    if (!formmat) {
      return DateTime.fromISO(dateISO, { locale: locale }).toFormat(
        'yyyy-MM-dd'
      );
    }

    return DateTime.fromISO(dateISO, { locale: locale }).toFormat(formmat);
  },
};

export default function Timer() {
  return Object.create(TimerProptypes);
}
