// import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import React from 'react';
import { BANK_LIST } from '../../../../Utils/constants';
import Form, {
  FormInput,
  FormSelect,
  useForm,
} from '../../../../components/Form/Form';
import { Button } from '../../../../ds';
import styles from './ActivationForm.module.scss';
// import { validationBankSchema } from './validationSchema';

const BankDataForm = ({ handleNextStep }) => {
  const methods = useForm({
    // resolver: yupResolver(validationBankSchema),
    defaultValues: {
      bankTitular: '',
      bank: '',
      clabe: '',
      bankmailtitular: '',
    },
  });

  const {
    formState: { isValid },
  } = methods;
  const handleSubmit = data => {
    handleNextStep(data, 2, 3);
  };

  return (
    <div className={styles.formContainer}>
      <div className={styles.nameForm}>Datos bancarios</div>
      <div className={styles.instructions}>
        Proporciona los datos bancarios.
      </div>
      <div className={styles.helper}>Todos los campos son obligatorios</div>
      <Form methods={methods} onSubmit={handleSubmit}>
        <div className={styles.formGrid}>
          <FormInput
            name='bankTitular'
            label='Nombre del titular de la cuenta bancaria'
            placeholder='Escribe el nombre del títular de la cuenta'
          />
          <FormSelect
            isSearchable
            options={BANK_LIST}
            name='bank'
            label='Banco'
            placeholder='BBVA'
          />
          <FormInput
            name='clabe'
            label=' CLABE interbancaria (18 dígitos)'
            placeholder='567890123456725678'
            hint='Ingresa CLABE sin guiones o espacios'
          />
          <FormInput
            name='bankmailtitular'
            label='Correo del titular de la cuenta bancaria'
            placeholder='correo@ejemplo.com.mx'
          />
        </div>
        <div className={styles.action}>
          <Button type='ghost'>Guardar y salir</Button>
          <Button btnType='submit' disabled={!isValid}>
            Siguiente
          </Button>
        </div>
      </Form>
    </div>
  );
};

BankDataForm.propTypes = {
  defaultFormValues: PropTypes.object,
  handleNextStep: PropTypes.func.isRequired,
};

export default BankDataForm;
