import styled, { css } from 'styled-components';
import React, { useState } from 'react';
import { Space } from '../../../ds';
import KDS from '../../../ds/lib/k-ds-foundation';
import { ReactComponent as EyeOffLineIcon } from './eye-off-line.svg';
import { ReactComponent as EyeLineIcon } from './eye-line.svg';
import PropTypes from 'prop-types';

const StyledInputOutline = styled.input`
  ${KDS.setColorTokenWithFallback(
    'border: 1px solid ',
    'interactive-alternate'
  )}
  border-radius: 8px;
  box-sizing: border-box;
  padding: 14px;
  ${props =>
    props.lengthValidation &&
    css`
      padding-right: 80px;
    `}

  ${KDS.setColorWithFallback('background: ', 'transparent')}

  ${KDS.setColorTokenWithFallback('color: ', 'interactive-alternate')}

  &:enabled {
    ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}

    &:hover {
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-secondary')}

      ::placeholder {
        ${KDS.setColorTokenWithFallback('color: ', 'interactive-secondary')}
      }
    }

    &:focus-visible {
      outline: 0;
    }

    &:focus {
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary')}
      ${KDS.setColorTokenWithFallback('border-color: ', 'interactive-primary')}
      border-width: 1px;
    }

    ::placeholder {
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-secondary')}
    }

    ::-ms-reveal {
      display: none;
    }

    ${props =>
      props.hasError &&
      props.isFilled &&
      css`
        ${KDS.setColorTokenWithFallback('border: 1px solid ', 'error')}
      `}
  }

  &:disabled {
    ${KDS.setColorTokenWithFallback(
      'border: 1px solid ',
      'background-alternate'
    )}

    ::placeholder {
      ${KDS.setColorTokenWithFallback('color: ', 'text-placeholder')}
    }
  }
`;

const InputWrapper = styled.div`
  position: relative;

  .icon {
    position: absolute;
    ${KDS.setSpacingWithFallback('top: ', 'spacing-05')}
    ${KDS.setSpacingWithFallback('right: ', 'spacing-05')}
    ${KDS.setColorWithFallback('background: ', 'white')}

    cursor: pointer;
  }

  .counter-length {
    right: 0px;
    padding-right: 10px;
    top: 16px;
    position: absolute;

    ${KDS.setColorTokenWithFallback('color: ', 'text-placeholder')}

    ${props =>
      props.hasError &&
      css`
        ${KDS.setColorTokenWithFallback('color: ', 'error')}
      `}
  }

  .helper-error {
    position: absolute;
    top: 3.5rem;
    left: 0;
    right: 0;

    ${KDS.setColorTokenWithFallback('color: ', 'text-placeholder')}

    ${props =>
      props.hasError &&
      css`
        ${KDS.setColorTokenWithFallback('color: ', 'error')}
      `}
  }
`;

const InputOutline = ({
  className,
  id,
  inputType,
  label,
  value,
  onChange,
  errorMessage,
  optional,
  helper,
  lengthValidation,
  ...moreProps
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const hasError = Boolean(errorMessage);
  const isFilled = Boolean(value);
  const { required } = moreProps;

  const props = { ...moreProps, hasError, isFilled };

  const labelOrRequired = (
    <Space
      size='small'
      justify='space-between'
      className='k-ds-width-full'
      direction='horizontal'
    >
      <label className='k-ds-body-01 k-ds-text-color-primary' htmlFor={id}>
        {label}
        {required ? (
          <span className='k-ds-text-color-error'>{' *'}</span>
        ) : null}
      </label>
      <span className='k-ds-label-01 k-ds-text-color-interactive-secondary'>
        {optional}
      </span>
    </Space>
  );

  const showHidePasswordIcon = () => {
    if (showPassword) {
      return (
        <EyeLineIcon
          onClick={() => setShowPassword(!showPassword)}
          className='icon'
        />
      );
    }

    return (
      <EyeOffLineIcon
        onClick={() => setShowPassword(!showPassword)}
        className='icon'
      />
    );
  };

  return (
    <Space size='small' direction='vertical' className={className}>
      {label || required ? labelOrRequired : null}
      <InputWrapper hasError={hasError}>
        <StyledInputOutline
          type={showPassword ? 'text' : inputType}
          value={value}
          onChange={onChange}
          className='k-ds-width-full k-ds-body-01'
          lengthValidation={lengthValidation}
          {...props}
        />
        {lengthValidation && (
          <div className='counter-length k-ds-caption-01'>
            {(value.length >= 0 ? value.length : 0) + '/' + lengthValidation}
          </div>
        )}
        {inputType === 'password' ? showHidePasswordIcon() : []}
        {helper || hasError ? (
          <span className='helper-error k-ds-caption-01'>
            {errorMessage || helper}
          </span>
        ) : null}
      </InputWrapper>
    </Space>
  );
};

InputOutline.propTypes = {
  className: PropTypes.any,
  id: PropTypes.any,
  inputType: PropTypes.any,
  label: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.any,
  errorMessage: PropTypes.any,
  optional: PropTypes.any,
  helper: PropTypes.any,
  lengthValidation: PropTypes.number,
};

export { InputOutline };
