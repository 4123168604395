import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Auth from '../../Utils/Auth/auth';
import {
  orderProductsMaxLimit,
  productionAssitantAllowedAccessList,
} from '../../Utils/constants';
import { useEffectAfterFirstRender } from '../../Utils/customHooks';
import {
  validateDecimal,
  validateEmptyField,
  validateErrorMessagesFromObject,
  validateLessThan,
  validateMinChars,
  validateNumerical,
  validateReferenceOrSku,
} from '../../Utils/formValidations';
import { autoCompleteDecimalNumber } from '../../Utils/formats';
import { goPage } from '../../Utils/pages';
import useFormWithCache from '../../Utils/useFormWithCache';
import {
  allowedMerchantFillDateReference,
  getCurrentDateTime,
} from '../../Utils/utils';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { useLoaderContext } from '../../contexts/Loader/LoaderContext';
import { Button, Space, TextInput } from '../../ds';
import { getUser } from '../../mockData';
import { createOrder, saveLogbehavior } from '../../services/Application';
import Checkbox from '../Checkbox/Checkbox';
import { Message } from '../DS/Message';
import ErrorModal from '../ErrorModal/ErrorModal';
import ModalRedirect from './ModalRedirect';
import ErrorText from './order-errors.json';
import AnalyticsEvent from '../../services/analytics/AnalyticsEvent';
import {
  DISABLED_PAYMENTS_DESCRIPTION,
  DISABLED_PAYMENTS_TITLE,
} from '../../Utils/payment_constants';
import Error from '../PaymentGeneratedByQR/Error/Error';

const cacheKey = 'amount_form';
const cacheDurationMs = 300000;

const initialPayOrder = {
  quantity: '',
  amount: '',
  reference: '',
  assistant: '',
};

const errorValidations = {
  quantity: '',
  amount: '',
  reference: '',
  assistant: '',
};

const initialWasOnFocus = {
  quantity: false,
  amount: false,
  reference: false,
  assistant: false,
};

function Amount(props) {
  const {
    storeid,
    location,
    paymentAmount,
    isPaymentValidated,
    couponAmount = 0,
    paymentId = '',
    paymentByCoupon = false,
    isValidCoupon = false,
    validateFillCoupon,
    onClear,
    logbehavior,
  } = props;
  const user = getUser();
  const history = useHistory();

  const { merchant } = Auth.userData();
  const merchantId = merchant.merchant_id;

  const { setIsLoaderOpen } = useLoaderContext();
  const { t } = useTranslation();

  // const [newPayOrder, setNewPayOrder] = useState(initialPayOrder);
  const [validations, setValidations] = useState(errorValidations);
  const [wasOnFocus, setWasOnFocus] = useState(initialWasOnFocus);

  const { formData, handleChange, handleBlur, resetForm } = useFormWithCache(
    initialPayOrder,
    cacheKey,
    cacheDurationMs,
    setWasOnFocus
  );

  const [disabledButton, setDisabledButton] = useState(true);
  const [errorModalState, setErrorModalState] = useState(false);
  const [operationError, setOperationError] = useState({
    title: undefined,
    description: undefined,
  });

  const [isCheckedFillDate, setIsCheckedFillDate] = useState(false);

  const [amountEndpointError, setAmountEndpointError] = useState('');
  const [assistantEndpointError, setAssistantEndpointError] = useState('');
  const [referenceEndpointError, setReferenceEndpointError] = useState('');
  const [quantityRangeError, setQuantityRangeError] = useState('');
  const [modalOrderPath, setModalOrderPath] = useState('');
  const [showTimer, setShowTimer] = useState(false);
  const [isDateTimeOpenError, setIsDateTimeOpenError] = useState(false);
  const [isReferenceOpenError, setIsReferenceOpenError] = useState(false);

  const isMoreThanAllowed = useMemo(() => {
    if (!paymentByCoupon) return false;
    return formData.amount > couponAmount;
  }, [couponAmount, formData.amount, paymentByCoupon]);

  const handleUpdateForm = () => {
    if (paymentByCoupon && !isValidCoupon) {
      validateFillCoupon(true);
    }
  };

  function createPayOrder() {
    setIsLoaderOpen(true);
    let order = {
      store_id: storeid,
      order_reference: isCheckedFillDate
        ? getCurrentDateTime({ setMinutes: true, setSeconds: false })
        : formData.reference,
      items_count: formData.quantity,
      shipping_amount: 0,
      total: Number(formData.amount),
      order_assistant: formData.assistant,
    };

    let data = {
      staff_id: user.employeeId().toString(),
      order: order,
    };

    if (paymentByCoupon) {
      data['payment_id'] = paymentId;
    }

    const translationPrefix = 'order.';

    saveLogbehavior({
      userId: user.employeeId().toString(),
      log_behavior_data: logbehavior.current,
    }).then(() => {
      saveLogbehavior({
        userId: user.employeeId().toString(),
        log_behavior_data: `$Loc${location?.lat ?? 0},${location?.lon ?? 0}`,
      });
      logbehavior.current = '';
    });

    createOrder(data)
      .then(response => {
        setIsLoaderOpen(false);
        resetForm();
        if (paymentByCoupon) {
          AnalyticsEvent().orderCreated();
          setShowTimer(true);
          setModalOrderPath(`/orders/${response.data.order_id}/${storeid}`);
        } else {
          AnalyticsEvent().codeQRCreadted();
          goPage(
            `/orders/v1/generated/${response.data.order_id}/${storeid}`,
            history
          );
        }
      })
      .catch(error => {
        setIsLoaderOpen(false);
        const errorCode = error?.response?.data?.code;
        const responseMessage = error?.response?.data?.message;
        const translationMessage = t(translationPrefix + responseMessage, {
          nsSeparator: false,
        });

        AnalyticsEvent().errorTrack({
          errorType: 'pay-order-create-order-error',
          errorDescription: {
            code: errorCode,
            responseMessage: responseMessage,
          },
        });

        switch (errorCode) {
          case 'invalid-payment-data':
          case 'update-payment-failed':
          case 'updatePaymentFailed':
          case 'AmountNotInRange':
            setAmountEndpointError(
              'El monto está fuera del rango permitido por la tienda.'
            );
            return;
          case 'StoreClosed':
          case 'StoreOpenRetrieveFail':
            setOperationError(ErrorText[errorCode]);
            return setErrorModalState(true);
          case 'OrderReferenceUsed':
          case 'ParamInvalid': {
            let defaultError = 'Ingresa una nueva referencia de compra.';

            if (isCheckedFillDate) {
              setIsDateTimeOpenError(true);
              defaultError = 'Añade de nuevo la fecha y hora';
              setIsCheckedFillDate(!isCheckedFillDate);
            } else {
              setIsReferenceOpenError(true);
            }

            setReferenceEndpointError(defaultError);
            return;
          }
          case 'paymentValidationFailed':
          case 'payment-validation-failed':
            setOperationError({
              title: 'Error Interno ',
              description: 'Error en validación de cobro',
            });
            return setErrorModalState(true);
          default:
            setOperationError({
              title: DISABLED_PAYMENTS_TITLE,
              description: !translationMessage.includes(translationPrefix)
                ? translationMessage
                : DISABLED_PAYMENTS_DESCRIPTION,
            });
            return setErrorModalState(true);
        }
      });
  }

  function setValidQuantity(value) {
    if (value === '' || validateNumerical(value)) {
      const quantity = value !== '' ? parseInt(value) : value;
      const exceedsLimit = quantity > orderProductsMaxLimit;
      const lessThan0 = quantity < 1;
      setQuantityRangeError(
        exceedsLimit || lessThan0
          ? `La cantidad ${exceedsLimit ? 'máxima' : ' mínima'} es ${
              exceedsLimit ? orderProductsMaxLimit : 1
            }`
          : ''
      );
      handleChange('quantity', quantity);
    }
  }

  function setValidAssistant(assistant) {
    handleBlur('assistant', assistant);
  }

  function setValidAmount(value) {
    if (validateDecimal(value)) {
      const amount = value === '0' ? '' : value;
      handleChange('amount', amount);
      setAmountEndpointError('');
      handleUpdateForm();
    }
  }

  function amountInputOnBlur(value) {
    const autoCompleteValue = autoCompleteDecimalNumber(value, 2);
    handleBlur('amount', autoCompleteValue);
    if (!wasOnFocus.amount) {
      setWasOnFocus({ ...wasOnFocus, amount: true });
    }
  }

  function quantityInputOnBlur(value) {
    handleBlur('quantity', value !== '' ? parseInt(value) : value);
    if (!wasOnFocus.quantity) {
      setWasOnFocus({ ...wasOnFocus, quantity: true });
    }
  }

  function setValidReference(value) {
    setReferenceEndpointError('');
    setWasOnFocus({ ...wasOnFocus, reference: true });

    if (value === 'NO_DATE_FILLED') {
      handleBlur('reference', '');
      return;
    }

    if (isCheckedFillDate) {
      handleBlur(
        'reference',
        getCurrentDateTime({ setMinutes: true, setSeconds: false }).toString()
      );
    } else if (validateReferenceOrSku(value)) {
      handleBlur('reference', value.toUpperCase());
    }
    if (!value && referenceEndpointError) setReferenceEndpointError('');
    handleValidations();
    handleUpdateForm();
  }

  function handleValidations() {
    const { amount, reference, quantity } = formData;
    const validation = {
      quantity: validateEmptyField(quantity, 'Campo Obligatorio'),
      amount: validateEmptyField(amount, 'Campo Obligatorio'),
      reference: reference
        ? validateMinChars(reference, 4, 'Mínimo 4 caracteres')
        : '',
    };

    const min60 = validateLessThan(
      60,
      amount,
      'El monto mínimo de compra debe ser $60 m.n.'
    );
    if (validation.amount === '' && min60 !== '') validation.amount = min60;

    setValidations(validation);
    setDisabledButton(
      validateErrorMessagesFromObject(validation) ||
        storeid === '' ||
        quantityRangeError !== '' ||
        (isPaymentValidated && paymentAmount < formData.amount)
    );
  }

  useEffectAfterFirstRender(() => {
    handleValidations();
  }, [
    formData.quantity,
    formData.amount,
    formData.reference,
    wasOnFocus,
    storeid,
  ]);

  const viewport = useDeviceContextViewport();
  const { isMobile } = viewport;
  const columnsClass = isMobile ? 'one-column' : 'two-columns';

  function handleOnClear() {
    resetForm();
    // setNewPayOrder(initialPayOrder);
    setValidations(errorValidations);
    setWasOnFocus(initialWasOnFocus);
    setAmountEndpointError('');
    setAssistantEndpointError('');
    setReferenceEndpointError('');
    onClear();
  }

  function handleOnClick() {
    createPayOrder();
  }

  useEffect(() => {
    if (isCheckedFillDate) {
      setValidReference();
    } else {
      setValidReference('NO_DATE_FILLED');
    }
  }, [isCheckedFillDate]);

  return (
    <div id='amount-component'>
      <ErrorModal
        defaultOpened={errorModalState}
        close={() => setErrorModalState(false)}
        heading={operationError.title}
        description={operationError.description}
      />
      <div className={`${columnsClass} k-ds-m-top-sp-06`}>
        <TextInput
          className='k-ds-m-bottom-sp-06'
          errorMessage={
            wasOnFocus.quantity
              ? validations.quantity || quantityRangeError
              : ''
          }
          label='Cantidad de artículos'
          onChange={e => setValidQuantity(e.target.value)}
          onBlur={e => quantityInputOnBlur(e.target.value)}
          placeholder='Ingresa la cantidad de artículos'
          type='outline'
          required
          name='by_amount_quantity'
          value={formData.quantity}
          helper={`Máximo ${orderProductsMaxLimit} artículos`}
        />
        <TextInput
          className='k-ds-m-bottom-sp-06'
          errorMessage={
            wasOnFocus.amount ? validations.amount || amountEndpointError : ''
          }
          label='Monto total'
          onChange={e => setValidAmount(e.target.value)}
          onBlur={e => amountInputOnBlur(e.target.value)}
          helper='Incluir costo de envío si aplica.'
          placeholder='$0.00'
          type='outline'
          value={formData.amount}
          required
          id='by_amount_amount'
          name='by_amount_amount'
        />

        <TextInput
          disabled={isCheckedFillDate}
          errorMessage={
            wasOnFocus.reference
              ? validations.reference || referenceEndpointError
              : ''
          }
          label='Referencia de compra'
          onChange={e => setValidReference(e.target.value)}
          helper='Puedes ingresar letras, números y guiones.'
          placeholder='(Opcional)'
          type='outline'
          value={formData.reference}
          required={formData.reference}
          id='by_amount_reference'
          name='by_amount_reference'
          className='k-ds-m-bottom-sp-06'
        />

        {allowedMerchantFillDateReference.includes(merchantId) && (
          <div className='center-checkbox'>
            <Checkbox
              filled
              defaultChecked={isCheckedFillDate}
              onChange={() => {
                setIsCheckedFillDate(!isCheckedFillDate);
              }}
            />{' '}
            Añadir fecha y hora
          </div>
        )}
        {productionAssitantAllowedAccessList.includes(merchantId) && (
          <TextInput
            errorMessage={
              wasOnFocus.assistant
                ? validations.assistant || assistantEndpointError
                : ''
            }
            label='Alguien ayudo a la compra'
            onChange={e => setValidAssistant(e.target.value)}
            helper='Codigo de vendedor que ayudo'
            placeholder='(Opcional)'
            type='outline'
            value={formData.assistant}
            required={formData.assistant}
            id='by_amount_asistant'
            name='by_amount_asistant'
            className='k-ds-m-bottom-sp-06'
          />
        )}
        <div />
      </div>

      {isMoreThanAllowed && isValidCoupon && (
        <Message
          type='error'
          className='k-ds-m-top-sp-08 k-ds-m-bottom-sp-08'
          message={
            <div className='k-ds-heading-01'>
              <div className='font-weight-normal'>
                <span className='font-weight-bold'>
                  El monto total excede el límite preaprobado del cliente.
                </span>{' '}
                Por favor, infórmale que el monto de su compra debe ser igual o
                menor al que tiene preaprobado.
              </div>
            </div>
          }
        />
      )}

      <Space className='k-ds-width-full k-ds-m-top-sp-06 k-ds-m-bottom-sp-04'>
        <Button
          type='primary-alternate'
          className='k-ds-m-rigth-sp-02'
          horizontalPadding='22px'
          verticalPadding='16px'
          size='small'
          onClick={handleOnClick}
          disabled={
            (!paymentId.length && paymentByCoupon) ||
            isMoreThanAllowed ||
            disabledButton
          }
        >
          <span style={{ width: '180px' }}> Cobrar </span>
        </Button>
        <Button type='simple' size='small' onClick={handleOnClear}>
          Cancelar
        </Button>
      </Space>
      <Error
        isOpen={isDateTimeOpenError}
        onClose={() => setIsDateTimeOpenError(false)}
        title='No se realizó el cobro'
        content='La fecha y hora de la referencia de compra ya ha sido usada.'
        textButton='Volver a intentar'
        callback={() => setIsDateTimeOpenError(false)}
      />
      <Error
        isOpen={isReferenceOpenError}
        onClose={() => setIsReferenceOpenError(false)}
        title='No se realizó el cobro'
        content='La referencia de compra ya ha sido usada.'
        textButton='Volver a intentar'
        callback={() => setIsReferenceOpenError(false)}
      />
      {showTimer && <ModalRedirect path={modalOrderPath} />}
    </div>
  );
}

Amount.propTypes = {
  storeid: PropTypes.string.isRequired,
  paymentAmount: PropTypes.number,
  paymentId: PropTypes.string,
  couponAmount: PropTypes.number,
  paymentByCoupon: PropTypes.bool,
  isPaymentValidated: PropTypes.bool,
  isValidCoupon: PropTypes.bool,
  onClear: PropTypes.func,
  validateFillCoupon: PropTypes.func,
  location: PropTypes.object.isRequired,
  logbehavior: PropTypes.object.isRequired,
};

export default Amount;
