import { getStage } from '../environment';
import FeatureRegistry from './feature_registry.json';

export function isFeatureEnabled(module) {
  if (!module) {
    return false;
  }
  let stage = getStage();
  let featureFlag = FeatureRegistry.features.find(
    feature => feature.module === module
  );
  if (!featureFlag) {
    return true;
  }
  let environment = featureFlag.environments.find(
    environment => environment.name === stage
  );
  if (!environment) {
    return true;
  }
  return environment.enabled;
}
