import PropTypes from 'prop-types';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { Toast } from '../../ds';

const ToastContext = createContext();

const useToastContext = () => {
  const context = React.useContext(ToastContext);
  return context;
};

const ToastContextProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  useEffect(() => {
    if (toasts.length > 0) {
      const timer = setTimeout(
        () => setToasts(toasts => toasts.slice(1)),
        8000
      );
      return () => clearTimeout(timer);
    }
  }, [toasts]);

  const addToast = useCallback(
    function (toast, type) {
      setToasts(toasts => [...toasts, { message: toast, type: type }]);
    },
    [setToasts]
  );

  function removeToast(toast) {
    var array = [...toasts];
    var index = array.indexOf(toast);
    if (index !== -1) {
      array.splice(index, 1);
      setToasts(array);
    }
  }

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      <div className='toasts-wrapper'>
        {toasts.map((toast, i) => (
          <Toast
            key={i}
            message={toast.message}
            type={toast.type}
            id={i}
            onClick={() => removeToast(toast)}
          />
        ))}
      </div>
    </ToastContext.Provider>
  );
};

ToastContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ToastContext;

export { ToastContextProvider, useToastContext };
