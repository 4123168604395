import { object, string } from 'yup';

export const defaultSchemaHeaders = {
  0: 'Apellido',
  1: 'Segundo Apellido',
  2: 'Correo',
  3: 'Teléfono Celular',
  4: 'CURP',
  5: 'Rol',
  6: 'Sucursal',
};

let userExportSchema = object({
  Nombre: string().required('El campo es requerido'),
  Apellido: string().required('El campo es requerido'),
  'Segundo Apellido': string(),
  Correo: string()
    .required('El campo es requerido')
    .email('El correo no es válido'),
  'Teléfono Celular': string(),
  CURP: string(),
  Rol: string().required('El campo es requerido'),
  Sucursal: string().required('El campo es requerida'),
});

export default userExportSchema;
