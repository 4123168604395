import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '../../../../ds';

const BlockedAccountMessage = ({ onAccept, message }) => {
  return (
    <div className='greeting'>
      <div className='k-ds-heading-07 align-self-start'>
        ¡Cuenta congelada! 🥶
      </div>
      <p>{message}</p>
      <Button
        type='primary-alternate'
        className='k-ds-m-rigth-sp-02 k-ds-m-top-lyt-05'
        horizontalPadding='2.5rem'
        verticalPadding='1rem'
        size='small'
        onClick={onAccept}
      >
        Entendido
      </Button>
    </div>
  );
};

BlockedAccountMessage.propTypes = {
  onAccept: PropTypes.func.isRequired,
  message: PropTypes.string,
};

export default BlockedAccountMessage;
