/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react';
import './switch.scss';

const Switch = forwardRef(function SwitchCustom(
  {
    className,
    id,
    name,
    label,
    onChange,
    disabled,
    defaultChecked,
    ...moreProps
  },
  ref
) {
  return (
    <div disabled={disabled} className={`switch-wrapper ${className}`}>
      <label className='switch'>
        <input
          ref={ref}
          name={name}
          defaultChecked={defaultChecked}
          onChange={onChange}
          className={`switch-input ${className}`}
          type='checkbox'
          {...moreProps}
        />
        <span className='slider round'></span>
      </label>
      {label ? (
        <label
          className='switch-label k-ds-body-01 k-ds-text-color-primary'
          htmlFor={id}
        >
          {label}
        </label>
      ) : null}
    </div>
  );
});

export default Switch;
