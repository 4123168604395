import * as z from 'zod';
import {
  isOnlyDigits,
  validateAlphaNumeric,
} from '../../../Utils/formValidations';

const KUESKI_OTP_TOKEN = 6;

const OneTimePasscodeVerificationSchema = z.object({
  phoneNumber: z
    .string({
      required_error: 'Campo obligatorio.',
      invalid_type_error: 'Este campo es invalido.',
    })
    .length(10, 'Este campo es invalido.')
    .refine(value => isOnlyDigits(value), 'Número incorrecto o no existe'),
  token: z
    .string({
      required_error: 'Código incorrecto.',
      invalid_type_error: 'Este campo es invalido.',
    })
    .length(KUESKI_OTP_TOKEN, 'Este campo es invalido.')
    .refine(
      value => validateAlphaNumeric(value),
      'Solo letras (A-Z) y números (0-9)'
    ),
});

export default OneTimePasscodeVerificationSchema;
