import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';

const ThStyled = styled.th`
  ${props => (props.minWidth ? `min-width: ${props.minWidth};` : '')}

  ${props => (props.noWrap ? 'white-space: nowrap;' : '')}
`;

const ThResponsive = ({
  children = null,
  devices = 'mobile tablet desktop',
  ...props
}) => {
  const { type } = useDeviceContextViewport();
  if (!devices?.split(' ').includes(type)) {
    return null;
  }
  return <ThStyled {...props}>{children}</ThStyled>;
};

ThResponsive.propTypes = {
  children: PropTypes.node,
  devices: PropTypes.string,
};

export default ThResponsive;
