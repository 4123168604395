import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDeviceContextViewport } from '../../../contexts/Device/DeviceContext';
import { Modal, Space } from '../../../ds';
import LoaderBlue from './loader-blue.gif';
import './transactionProgress.scss';

const TransactionProgress = ({ isOpen, onClose = () => null }) => {
  const viewport = useDeviceContextViewport();

  const isMobileOnly = useMemo(
    () => viewport.isMobile && !viewport.isSmallTablet,
    [viewport]
  );

  return (
    <Modal
      className={cn('transaction-progress', {
        ['mobile-modal']: isMobileOnly,
      })}
      theme='dark'
      cardType='secondary'
      onClose={onClose}
      onOverlayClick={onClose}
      {...{ isOpen: isOpen }}
    >
      <div className='transaction-progres-content k-ds-width-full'>
        <div>
          <Space justify='center' direction='vertical' align='center'>
            <img src={LoaderBlue} alt='loading...' />
          </Space>
          <Space direction='vertical' justify='center' align='center'>
            <div className='k-ds-heading-05'>Transacción en curso</div>
          </Space>
          <Space
            className='k-ds-heading-03 k-ds-body-02 k-ds-m-top-sp-03'
            justify='center'
          >
            Espera un momento
          </Space>
        </div>
      </div>
    </Modal>
  );
};

TransactionProgress.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default TransactionProgress;
