/* eslint-disable react/jsx-key */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SearchBar } from '../../../DS/SearchBar';

const REGEX_FIELD = /^[\d\w\W]+$/;

const SearchByOrderId = ({ onSearch, value = '' }) => {
  const [searchState, setSearchState] = useState(value);
  const [errorSearch, setErrorSearch] = useState(false);

  useEffect(() => {
    if (value.length === 0) {
      setSearchState('');
      return;
    }

    const isValid = value.length > 0 && REGEX_FIELD.test(value);
    setErrorSearch(!isValid);
    setSearchState(value);
  }, [value]);

  const handleClean = () => {
    setSearchState('');
    onSearch('');
  };

  const handleChangeInput = value => {
    if (value.length === 0) {
      setSearchState('');
      onSearch('');
      return;
    }

    const isValid = value.length > 0 && REGEX_FIELD.test(value);
    setErrorSearch(!isValid);
    setSearchState(value);
  };

  const handleSearch = () => {
    const isValid = searchState.length > 0 && REGEX_FIELD.test(searchState);
    setErrorSearch(!isValid);

    if (isValid) {
      onSearch(searchState);
    }
  };

  return (
    <SearchBar
      placeholder='Buscar por ID Orden'
      className='k-ds-width-full k-ds-m-bottom-sp-02 k-ds-m-top-sp-09'
      search={searchState}
      setSearch={handleChangeInput}
      onSearch={handleSearch}
      clearSearch={handleClean}
      minSearch={3}
      disabledButton={errorSearch}
      errorMessage={errorSearch ? 'Caractereres invalido.' : ''}
    />
  );
};

SearchByOrderId.propTypes = {
  onSearch: PropTypes.func.isRequired,
  value: PropTypes.any,
};

export default SearchByOrderId;
