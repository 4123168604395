import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Button, Modal } from '../../../ds';
import { ReactComponent as CloseIcon } from './res/close-icon.svg';

const CustomModal = styled(Modal)`
  max-width: 350px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Message = styled.div`
  color: var(--light-text-primary, #18223d);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: 0.32px;
`;

const BtnClose = styled(CloseIcon)`
  cursor: pointer;
`;

const ModalNotInternet = ({ isOpen, handleClose }) => {
  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <CustomModal theme='dark' isOpen={isOpen} cardType='secondary'>
      <Container>
        <Header>
          <BtnClose onClick={handleClose} />
        </Header>
        <Message>
          Se perdió la señal de internet, por favor verifica tu conexión.
        </Message>
        <Button onClick={reloadPage}>Volver a cargar</Button>
      </Container>
    </CustomModal>
  );
};

ModalNotInternet.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ModalNotInternet;
