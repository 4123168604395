import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import Auth from '../../Utils/Auth/auth';
import { isFeatureEnabled } from '../../Utils/Feature/feature_flag';
import {
  consumerOrderType,
  notApplicableOrderStaffId,
  REFUND_CREATE_FAIL,
} from '../../Utils/constants';
import { hyphenEveryFourChars, numberWithCommas } from '../../Utils/formats';
import isValidTranslation from '../../Utils/isValidTranslation';
import {
  paymentLogs,
  merchatTransactionReport,
} from '../../containers/routes/ScopeRoutes';
import { hasScopeAccess } from '../../containers/routes/index';
import {
  getCategoryDescription,
  useCategoriesContext,
} from '../../contexts/Categories/CategoriesContext';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { useLoaderContext } from '../../contexts/Loader/LoaderContext';
import { useToastContext } from '../../contexts/Toast/ToastContext';
import {
  Button,
  Divider,
  Modal,
  Space,
  Table,
  TableMobile,
  Tag,
} from '../../ds';
import {
  getOrderDetail,
  orderVerdict,
  requestOrderRefund,
} from '../../services/Application';
import { Message } from '../DS/Message';
import ErrorModal from '../ErrorModal/ErrorModal';
import GetBackSection from '../GetBackSection';
import NotFound from '../NotFound';
import TokenValidationModal from '../PayOrder/TokenValidationModal';
import RenderBarcode from '../RenderBarcode';
import CancelPaymentModal from './CancelPaymentModal';
import { ReactComponent as OrderAcceptedIcon } from './order-accepted-icon.svg';
import './paymentOrderDetail.scss';
import AnalyticsEvent, {
  AnalyticsList,
} from '../../services/analytics/AnalyticsEvent';
import { useRef } from 'react';
import { REIMBURSEMENT_TOTAL } from '../PaymentReimbursement/PaymentReimbursement';

function PaymentOrderDetail() {
  const isTracked = useRef(false);
  const user = Auth.userData();
  const { permission_scopes } = Auth.userData();
  if (!user || !user.stores) {
    return '';
  }
  const { orderId, storeId } = useParams();
  const autoAcceptOrderEnabled = isFeatureEnabled('auto_accept_order');
  const cancel_payment_scopes = ['cancel_payment', 'cancel_payment_merchant'];
  const moduleRaport = isFeatureEnabled('merchant_tarnsactions_report')
    ? merchatTransactionReport.module
    : paymentLogs.module;
  const payments_permission_scopes =
    permission_scopes?.find(item => item.module_name === moduleRaport)
      ?.scopes || [];

  const selectedStoreId = storeId ? storeId : user.stores[0].store_id;
  const storeName = user.stores.filter(store => {
    return store.store_id === selectedStoreId;
  })[0].name;

  const firstRender = useRef(true);
  const [isOrderLoaded, setIsOrderLoaded] = useState(false);
  const [isOpenAccepted, setIsOpenAccepted] = useState(false);
  const [isOpenCancel, setIsOpenCancel] = useState(false);
  const [isOpenCancelReimbursement, setIsOpenCancelReimbursement] =
    useState(false);

  const [errorModalState, setErrorModalState] = React.useState(false);
  const [operationError] = React.useState({
    title: 'Error desconocido',
    description:
      'Encontramos un error desconocido en el sistema. ¿Lo puedes volver a intentar?',
  });

  const { setIsLoaderOpen } = useLoaderContext();
  const addToast = useToastContext();
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(true);
  const [orderDetailsService, setOrderDetailsService] = React.useState({
    staffId: '',
    user_code: '',
    order_reference: '',
    order_type: '',
    total: '',
    items_count: 0,
    paymentUrl: '',
    paymentQR: '',
    createdAt: '',
    origin: '',
    statusKueski: '',
    statusStore: '',
    items: [],
    shippingAmount: '',
    totalFormatted: '',
    shippingAmountFormatted: '',
  });
  const initialPaymentData = {
    isModalOpen: false,
    paymentId: '',
    tokenErrors: '',
    token: '',
  };
  const [paymentData, setPaymentData] = useState(initialPaymentData);
  const [isOpenalreadyPaidErrorInfoModal, setIsOpenAlreadyPaidErrorInfoModal] =
    useState(false);

  const statusColorTag = {
    AUTORIZADO: 'teal',
    CANCELADO: 'red',
    EXPIRADO: 'purple',
    PENDIENTE: 'orange',
    'N/A': 'grey',
  };

  const canCancelOrderBasedOnStatus = status => {
    return ['AUTORIZADO', 'PENDIENTE'].includes(status);
  };

  const canReimburseOrderBasedOnStatus = (kueski, merchant) => {
    return (
      autoAcceptOrderEnabled &&
      kueski === 'AUTORIZADO' &&
      merchant === 'ACEPTADO'
    );
  };

  const buttonAceptedIsDisabled = (kueski, merchant) => {
    if (kueski === 'PENDIENTE') return true;

    if (
      [
        'CANCELADO',
        'RECHAZADO',
        'ACEPTADO',
        'EXPIRADO',
        'REEMBOLSADO',
      ].includes(merchant)
    ) {
      return true;
    }

    return kueski !== 'AUTORIZADO';
  };

  const buttonCancelIsDisabled = (kueski, merchant) => {
    if (
      [
        'CANCELADO',
        'RECHAZADO',
        'ACEPTADO',
        'EXPIRADO',
        'REEMBOLSADO',
      ].includes(merchant) ||
      kueski === 'EXPIRADO'
    )
      return true;
    if (canCancelOrderBasedOnStatus(kueski)) return false;
    return true;
  };

  const getOrder = () => {
    setIsLoaderOpen(true);
    getOrderDetail(orderId, selectedStoreId)
      .then(response => {
        const { order } = response.data;

        if (firstRender.current) {
          AnalyticsEvent().orderPaymentViewed({
            storeName: storeName,
            storeId: selectedStoreId,
            paymentId: order.payment_attributes.payment,
            paymentReference: order.order_reference,
            totalPayment: order.total,
            totalItems: order.items_count,
            paymentLink: order.payment_attributes.payment_url,
          });
          firstRender.current = false;
        }

        setOrderDetailsService({
          staffId:
            order.staff_id !== notApplicableOrderStaffId ? order.staff_id : '',
          user_code: order.user_code,
          order_reference: order.order_reference,
          total: order.total,
          order_type: order.order_type,
          items_count: order.items_count,
          clientName: order.client_attributes.name,
          clientEmail: order.client_attributes.email,
          clientPhoneNumber: order.client_attributes.mobile,
          paymentId: order.payment_attributes.payment,
          paymentUrl: order.payment_attributes.payment_url,
          paymentQR: order.payment_attributes.payment_url_qr,
          createdAt: order.created_at_formatted,
          origin: order.origin_label,
          statusKueski: order.kueski_authorization,
          statusStore: order.store_authorization,
          items: order.items_attributes,
          shippingAmount: order.shipping_amount,
          buttonAceptedIsDisabled: buttonAceptedIsDisabled(
            order.kueski_authorization,
            order.store_authorization
          ),
          buttonCancelIsDisabled: buttonCancelIsDisabled(
            order.kueski_authorization,
            order.store_authorization
          ),
          totalFormatted: order.total_formatted,
          shippingAmountFormatted: order.shipping_amount_formatted,
          canCancelOrderBasedOnStatus: canCancelOrderBasedOnStatus(
            order.kueski_authorization
          ),
          canReimburse: canReimburseOrderBasedOnStatus(
            order.kueski_authorization,
            order.store_authorization
          ),
        });
        if (isConsumerOrder)
          setPaymentData({
            ...paymentData,
            isModalOpen: false,
            paymentId: order.payment_attributes.payment,
          });
        setIsLoading(false);
        setIsLoaderOpen(false);
        setIsOrderLoaded(true);
      })
      .catch(error => {
        AnalyticsEvent().errorTrack({
          errorType: 'payment-order-details-get-order-detail-error',
          errorDescription: error,
        });
        console.log(error);
        setIsLoaderOpen(false);
        setIsLoading(false);
        setIsOrderLoaded(false);
      });
  };

  useEffect(() => {
    getOrder();
  }, []);

  useEffect(() => {
    if (!isTracked.current) {
      AnalyticsEvent().pageView({ page: AnalyticsList.paymentOrderDetail });
      isTracked.current = true;
    }
  }, []);

  const tableHeaders = {
    SKU: 'SKU o código del artículo',
    productName: 'Nombre del producto',
    category: 'Categoría',
    quantity: 'Cantidad',
    unitaryPrice: 'Precio unitario',
    total: 'Total',
  };

  const categoriesContext = useCategoriesContext();

  const viewport = useDeviceContextViewport();
  const TableComponent = viewport.isMobile ? TableMobile : Table;
  const tableSize = viewport.isMobile ? 'small' : 'medium';

  if (viewport.isMobile) {
    tableHeaders.SKU = 'SKU o código';
  }

  const device = viewport.type;
  const orderDetailsDirection = viewport.isMobile ? 'vertical' : 'horizontal';
  const orderDetailsAlign = viewport.isMobile ? 'stretch' : 'start';

  const modalSvgClassName = viewport.isMobile ? 'svg-mobile' : '';
  const modalHeaderTypography = viewport.isMobile
    ? 'k-ds-heading-02'
    : 'k-ds-heading-05';
  const modalBodyTypography = viewport.isMobile
    ? 'text-w-400-s-12'
    : 'text-w-400-s-16';
  const modalButtonSize = viewport.isMobile ? 'small' : 'large';
  const modalButtonClassName = viewport.isMobile
    ? 'button-mobile'
    : 'button-lg';
  const { t } = useTranslation();
  const isConsumerOrder = orderDetailsService?.order_type === consumerOrderType;
  const isOrderOk =
    orderDetailsService.statusStore === 'ACEPTADO' &&
    orderDetailsService.statusKueski === 'AUTORIZADO';

  if (isLoading) return '';

  function setOrderVerdict(verdict) {
    const { staffId } = orderDetailsService;
    const data = {
      staff_id: staffId != notApplicableOrderStaffId ? staffId : '',
      store_id: selectedStoreId,
      verdict,
    };

    setIsLoading(true);

    orderVerdict(orderId, data)
      .then(() => {
        getOrder();
      })
      .catch(error => {
        AnalyticsEvent().errorTrack({
          errorType: 'payment-order-details-order-veredict-error',
          errorDescription: error,
        });
        setIsLoading(false);
        setErrorModalState(true);
      })
      .finally(() => {
        setIsOpenAccepted(false);
        setIsOpenCancel(false);
      });
  }

  const refundOrder = paymentId => {
    setIsLoaderOpen(true);
    const refundRequest = {
      order_id: orderId,
      staff_id:
        orderDetailsService.staffId !== notApplicableOrderStaffId
          ? orderDetailsService.staffId
          : '',
      amount: parseFloat(orderDetailsService.total),
    };

    AnalyticsEvent().cancellationSelected({
      storeName: storeName,
      storeId: storeId,
      paymentId: paymentId,
      funnelStep: 'Payment Details',
      reimbursementType: REIMBURSEMENT_TOTAL,
    });

    requestOrderRefund(refundRequest)
      .then(response => {
        if (response.status === 'success') {
          addToast('La orden ha sido cancelada', 'success');
          AnalyticsEvent().cancellationConfirmed({
            storeName: storeName,
            storeId: storeId,
            paymentId: paymentId,
            funnelStep: 'Payment Details',
            reimbursementType: REIMBURSEMENT_TOTAL,
          });
        }

        history.goBack();
        setIsLoaderOpen(false);
      })
      .catch(error => {
        const responseMessage = error?.response?.data?.message;
        AnalyticsEvent().errorTrack({
          errorType: 'payment-order-details-request-refund-error',
          errorDescription: error,
        });

        if (responseMessage === REFUND_CREATE_FAIL) {
          setIsOpenCancelReimbursement(false);
          setIsOpenAlreadyPaidErrorInfoModal(true);
        } else {
          history.push(`/payments/${paymentId}/reimbursement`);
          var message = 'Ocurrió un error, ¿Podrías intentarlo de nuevo?';
          const translationPrefix = 'refund.';
          let responseMessageTranslation = '';

          if (responseMessage) {
            responseMessageTranslation = t(
              translationPrefix + responseMessage,
              { nsSeparator: false }
            );
          }

          if (
            responseMessageTranslation &&
            isValidTranslation(translationPrefix, responseMessageTranslation)
          ) {
            message = responseMessageTranslation;
          }

          addToast(message, 'error');
          history.goBack();
          setIsLoaderOpen(false);
        }

        console.log(error);
        setIsLoaderOpen(false);
      });
  };

  function closeErrorModal() {
    setErrorModalState(false);
  }

  return !isOrderLoaded ? (
    <NotFound />
  ) : (
    <>
      <div className='payment-detail'>
        <div className='k-ds-width-full'>
          <GetBackSection redirectUri='/orders' render={true} />
          <Space
            direction={orderDetailsDirection}
            size='large'
            justify='space-around'
            align={orderDetailsAlign}
            className='k-ds-width-full'
          >
            <Space direction='vertical' className='flex-grow-1' size='medium'>
              <div className='header'>Detalle de cobro del cliente</div>
              {!viewport.isMobile ? (
                <div className='k-ds-subheading-02'>
                  Consulta el detalle del pago de tu cliente
                </div>
              ) : null}
            </Space>
            <Space direction='horizontal' justify='space-around'>
              {orderDetailsService.canCancelOrderBasedOnStatus &&
                orderDetailsService.statusStore &&
                orderDetailsService.statusStore !== 'ACEPTADO' && (
                  <>
                    <Button
                      className='action-button'
                      size='small'
                      type='primary-alternate'
                      verticalPadding='1rem'
                      onClick={() => setIsOpenAccepted(true)}
                      disabled={orderDetailsService.buttonAceptedIsDisabled}
                    >
                      Aceptar Pago
                    </Button>
                    <Button
                      className='action-button'
                      type='secondary-alternate'
                      onClick={() => setIsOpenCancel(true)}
                      size='small'
                      verticalPadding='1rem'
                      disabled={orderDetailsService.buttonCancelIsDisabled}
                    >
                      Rechazar pago
                    </Button>
                  </>
                )}
              {orderDetailsService.canReimburse &&
                hasScopeAccess(
                  cancel_payment_scopes,
                  payments_permission_scopes
                ) && (
                  <Button
                    className='action-button'
                    type='primary-alternate'
                    onClick={() => {
                      AnalyticsEvent().transactionCancelled({
                        storeName: storeName,
                        storeId: storeId,
                        paymentId: orderDetailsService.paymentId,
                        funnelStep: 'Payment Details',
                      });

                      setIsOpenCancelReimbursement(true);
                    }}
                    size='small'
                    verticalPadding='1rem'
                  >
                    Cancelar orden
                  </Button>
                )}
            </Space>
          </Space>
          {isConsumerOrder && isOrderOk && (
            <Message
              type='success'
              className='k-ds-m-top-sp-09'
              message={
                <div className='k-ds-heading-01'>
                  <div className='message-content k-ds-heading-01'>
                    <div className='k-ds-m-bottom-sp-03 font-weight-bold'>
                      ¡Orden completada!
                    </div>
                    <div className='k-ds-m-top-sp-06 font-weight-normal'>
                      Ya puedes entregar los productos
                    </div>
                  </div>
                </div>
              }
            />
          )}
          <Space
            direction='vertical'
            className='k-ds-m-top-sp-08 k-ds-m-bottom-sp-08'
          >
            <span className='k-ds-heading-04'>{user.merchant.name}</span>
            <div>
              <span className='k-ds-heading-01 font-weight-normal'>
                Sucursal:
              </span>
              <span className='k-ds-subheading-01 font-weight-bold'>
                {` ${storeName}`}
              </span>
            </div>
            <div>
              <span className='k-ds-subheading-01 font-weight-normal'>
                Código de empleado:
              </span>
              <span className='k-ds-subheading-01 font-weight-bold k-ds-m-left-sp-02'>
                {orderDetailsService.user_code
                  ? orderDetailsService.user_code
                  : 'N/A'}
              </span>
            </div>
          </Space>
        </div>
        <Divider
          color='inverse-background'
          thickness='0.5px'
          margin='spacing-06'
        />
        <div
          className={`general-data-${device} k-ds-m-top-sp-05 k-ds-heading-01 font-weight-normal k-ds-m-bottom-sp-05`}
        >
          <Space direction='vertical'>
            <div className='k-ds-m-bottom-sp-03 k-ds-heading-02'>
              Datos del cliente
            </div>
            <div>
              {'Cliente: '}
              <span className='k-ds-body-01 word-break-all'>
                {orderDetailsService.clientName || '_'}
              </span>
            </div>
            <div>
              {'Correo: '}
              <span className='k-ds-body-01 word-break-all'>
                {orderDetailsService.clientEmail || '_'}
              </span>
            </div>
            <div>
              {'Teléfono: '}
              <span className='k-ds-body-01 word-break-all'>
                {orderDetailsService.clientPhoneNumber || '_'}
              </span>
            </div>
          </Space>
          <Space direction='vertical'>
            <div className='k-ds-m-bottom-sp-03 k-ds-heading-02'>
              Datos del pago
            </div>
            <div>
              {'ID de pago: '}
              <span className='k-ds-text-color-interactive-primary k-ds-body-01'>
                {hyphenEveryFourChars(orderDetailsService.paymentId)}
              </span>
            </div>
            <div>
              Referencia de orden:
              <span className='k-ds-body-01 word-break-all'>
                {' '}
                {orderDetailsService.order_reference}
              </span>
            </div>
            <div>
              {'Fecha y hora del pago: '}
              <span className='k-ds-body-01'>
                {orderDetailsService.createdAt}
              </span>
            </div>
            <div>
              Canal:
              <span className='k-ds-body-01'>
                {' '}
                {orderDetailsService.origin}
              </span>
            </div>
          </Space>
          <Space direction='vertical' style={{ width: 250 }}>
            <div className='k-ds-m-bottom-sp-03 k-ds-heading-02'>
              Autorización del pago
            </div>
            <div>
              {'Kueski: '}
              <Tag color={statusColorTag[orderDetailsService.statusKueski]}>
                {orderDetailsService.statusKueski}
              </Tag>
            </div>
          </Space>
        </div>
        <Divider
          color='inverse-background'
          thickness='0.5px'
          margin='spacing-06'
        />
        <div className='k-ds-m-top-sp-05 k-ds-width-full'>
          <div className='subheader k-ds-m-bottom-sp-05'>Orden de pago</div>
          {orderDetailsService.items.length > 0 ? (
            <>
              <TableComponent
                type='b2b'
                size={tableSize}
                className='k-ds-m-bottom-lyt-01 k-ds-width-full'
                style={{ width: '100%' }}
              >
                <thead>
                  <tr>
                    <th>{tableHeaders.SKU}</th>
                    <th>{tableHeaders.productName}</th>
                    <th>{tableHeaders.category}</th>
                    <th>{tableHeaders.quantity}</th>
                    <th>{tableHeaders.unitaryPrice}</th>
                    <th>{tableHeaders.total}</th>
                  </tr>
                </thead>
                <tbody>
                  {orderDetailsService.items.map((item, index) => {
                    return (
                      <tr key={`${index}${item.sku}${item.name}`}>
                        <td
                          data-title={tableHeaders.SKU}
                          className='word-break-all'
                        >
                          {item.sku || ''}
                        </td>
                        <td
                          data-title={tableHeaders.productName}
                          className='word-break-all'
                        >
                          {item.name}
                        </td>
                        <td
                          data-title={tableHeaders.category}
                          className='word-break-all'
                        >
                          {getCategoryDescription(
                            categoriesContext.categories,
                            item.category
                          )}
                        </td>
                        <td data-title={tableHeaders.quantity}>
                          {item.quantity}
                        </td>
                        <td
                          data-title={tableHeaders.unitaryPrice}
                        >{`$${item.price_formatted}`}</td>
                        <td
                          data-title={tableHeaders.total}
                        >{`$ ${numberWithCommas(
                          (item.price * item.quantity).toFixed(2)
                        )}`}</td>
                      </tr>
                    );
                  })}
                  {!viewport.isMobile ? (
                    <tr>
                      <td colSpan='3' />
                      <td colSpan='2'>
                        <Space
                          direction='vertical'
                          justify='end'
                          align='end'
                          className='k-ds-width-full'
                        >
                          <div className='k-ds-heading-01'>
                            Costo del envío:
                          </div>
                          <div className='text-w-700-s-18'>Total:</div>
                        </Space>
                      </td>
                      <td style={{ minWidth: '5.8rem' }}>
                        <Space direction='vertical'>
                          <div className='text-w-400-s-14'>
                            {`$ ${orderDetailsService.shippingAmountFormatted}`}
                          </div>
                          <div className='text-w-700-s-18 k-ds-text-color-interactive-primary'>
                            {`$ ${orderDetailsService.totalFormatted}`}
                          </div>
                        </Space>
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </TableComponent>
              {viewport.isMobile ? (
                <>
                  <Divider
                    color='interactive-alternate'
                    thickness='0.5px'
                    margin='spacing-05'
                  />
                  <div className='two-columns k-ds-m-top-sp-06'>
                    <div className='k-ds-heading-01'>Costo del envío:</div>
                    <div className='k-ds-subheading-01'>
                      {`$ ${orderDetailsService.shippingAmountFormatted}`}
                    </div>
                    <div className='k-ds-heading-02'>Total:</div>
                    <div className='k-ds-heading-02 k-ds-text-color-interactive-primary'>
                      {`$ ${orderDetailsService.totalFormatted}`}
                    </div>
                  </div>
                  <Divider
                    color='interactive-alternate'
                    thickness='0.5px'
                    margin='spacing-06'
                  />
                </>
              ) : null}
            </>
          ) : (
            <>
              <Space direction='vertical' className='k-ds-heading-01'>
                <div>
                  Subtotal:
                  <span className='k-ds-body-01'>
                    {` $${orderDetailsService.totalFormatted}`}
                  </span>
                </div>
                <div>
                  {'Costo del envío: '}
                  <span className='k-ds-body-01'>
                    {`$${orderDetailsService.shippingAmount}`}
                  </span>
                </div>
                <div>
                  Monto total:
                  <span className='k-ds-subheading-02 k-ds-text-color-interactive-primary'>
                    {` $${orderDetailsService.totalFormatted}`}
                  </span>
                </div>
                <div>
                  {'Cantidad de productos: '}
                  <span className='k-ds-body-01'>
                    {orderDetailsService.items_count}
                  </span>
                </div>
              </Space>
              <Divider
                color='interactive-alternate'
                thickness='0.5px'
                margin='spacing-06'
              />
            </>
          )}
          {!isConsumerOrder && (
            <div>
              {!viewport.isMobile ? (
                <div className='k-ds-heading-04 k-ds-m-bottom-sp-07'>
                  Código QR de referencia para pago
                </div>
              ) : null}
              <RenderBarcode barcode={orderDetailsService.paymentQR} />
              <div className='k-ds-m-vertical-sp-07 k-ds-m-bottom-sp-09'>
                <span className='k-ds-body-01 k-ds-text-color-interactive-primary k-ds-m-top-sp-05 word-break-all'>
                  {orderDetailsService.paymentUrl}
                </span>
              </div>
            </div>
          )}
        </div>
        <Modal
          className='modal'
          theme='dark'
          cardType='secondary'
          onClose={() => setIsOpenAccepted(false)}
          onOverlayClick={() => setIsOpenAccepted(false)}
          {...{ isOpen: isOpenAccepted }}
        >
          <div className='flex-centered k-ds-width-full k-ds-m-sp-02'>
            <OrderAcceptedIcon className={modalSvgClassName} />
            <div className={`${modalHeaderTypography} k-ds-m-top-sp-08`}>
              ¿Aceptar la orden de pago?
            </div>
            <div className={`${modalBodyTypography} k-ds-m-top-sp-05`}>
              Estarás autorizando la salida de los productos de la tienda.
            </div>
            <Space direction='horizontal' className='k-ds-m-top-sp-07'>
              <Button
                size={modalButtonSize}
                className={modalButtonClassName}
                type='primary-alternate'
                onClick={() => setOrderVerdict('accept')}
              >
                Aceptar pago
              </Button>

              <Button
                size={modalButtonSize}
                className={modalButtonClassName}
                type='secondary-alternate'
                onClick={() => setIsOpenAccepted(false)}
              >
                Regresar
              </Button>
            </Space>
          </div>
        </Modal>
        <CancelPaymentModal
          isOpen={isOpenCancel}
          setIsOpenCancel={setIsOpenCancel}
          header='¿Rechazar orden de pago?'
          content='Una vez rechazada, tu cliente perderá su crédito y no podrá sacar los productos de la tienda.'
          acceptButtonText='Sí, rechazar'
          rejectButtonText='No, regresar'
          onAccept={() => setOrderVerdict('reject')}
        />
        <CancelPaymentModal
          isOpen={isOpenCancelReimbursement}
          header='El crédito Kueski será cancelado.'
          content='Esta acción no podrá ser revertida. ¿Deseas continuar?'
          acceptButtonText={'Sí, cancelar'}
          rejectButtonText={'No, regresar'}
          onAccept={() => refundOrder(orderDetailsService.paymentId)}
          setIsOpenCancel={setIsOpenCancelReimbursement}
        />
        <ErrorModal
          defaultOpened={errorModalState}
          close={closeErrorModal}
          heading={operationError.title}
          description={operationError.description}
        />
        <ErrorModal
          heading=''
          description={
            <div>
              {
                'Para cancelar esta transacción que ya fue liquidada por el cliente, envía ID de pago, monto y tipo de cancelación (parcial/total) a '
              }
              <a
                href='mailto:cancelacioneskueskipay@kueski.com'
                className='k-ds-text-color-interactive-primary'
              >
                cancelacioneskueskipay@kueski.com
              </a>
              .
            </div>
          }
          defaultOpened={isOpenalreadyPaidErrorInfoModal}
          close={setIsOpenAlreadyPaidErrorInfoModal}
          showClose
        />
      </div>
      <TokenValidationModal
        isOpen={paymentData.isModalOpen}
        close={closeModal}
        onValidate={handleValidateToken}
        paymentData={paymentData}
        onChange={setTokenState}
      />
    </>
  );

  function closeModal() {
    setPaymentData({
      ...paymentData,
      isModalOpen: false,
    });
  }

  function setTokenState(value) {
    setPaymentData({
      ...paymentData,
      token: value,
    });
  }

  function handleValidateToken() {
    const { staffId } = orderDetailsService;
    const data = {
      staff_id: staffId != notApplicableOrderStaffId ? staffId : '',
      store_id: selectedStoreId,
      verdict: 'accept',
      token: paymentData.token,
    };
    setIsLoaderOpen(true);
    orderVerdict(orderId, data)
      .then(response => {
        if (response.status === 'success') {
          addToast(
            '¡Orden creada con éxito! Ya puedes entregar los productos',
            'success'
          );
          getOrder();
        }
      })
      .catch(error => {
        AnalyticsEvent().errorTrack({
          errorType: 'payment-order-details-order-veredict-error',
          errorDescription: error,
        });
        console.log(error);
        setPaymentData({
          ...paymentData,
          tokenErrors: 'No se pudo validar el token',
        });
      })
      .finally(() => setIsLoaderOpen(false));
  }
}

export default PaymentOrderDetail;
