import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowIcon } from './icon-arrow-down.svg';
import './collapsible.scss';

const Collapsible = ({
  open = false,
  title = null,
  onOpen = () => null,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const [height, setHeight] = useState(open ? undefined : 0);
  const ref = useRef(null);

  useEffect(() => {
    if (!height || !isOpen || !ref.current) return undefined;

    onOpen(isOpen);
    const resizeObserver = new ResizeObserver(el => {
      setHeight(el[0].contentRect.height);
    });

    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [height, isOpen]);

  useEffect(() => {
    if (isOpen) {
      setHeight(ref.current?.getBoundingClientRect().height);
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  const handleFilterOpening = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <div className='collapsible'>
      <div className='collapsible-header' onClick={handleFilterOpening}>
        {title}
        <ArrowIcon
          className={`collapsible-arrow ${isOpen ? 'collapsible-arrow-down' : ''}`}
        />
      </div>

      <div
        className={`collapsible-content ${isOpen ? 'open' : ''} `}
        style={{ height }}
      >
        <div ref={ref}>
          <div className='collapsible-content-padding'>{children}</div>
        </div>
      </div>
    </div>
  );
};

Collapsible.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.any,
  children: PropTypes.any,
  onOpen: PropTypes.func,
};

export default Collapsible;
