const canBeEmpty = (value, callback) => {
  if (value === '') return '';

  return callback;
};

const validateEmptyField = (value, messageError) => {
  if (!value || value.length === 0) {
    return messageError;
  }
  return '';
};

const validateMinChars = (
  string,
  numberOfChars,
  messageError = 'No cumple con caracteres mínimos'
) => {
  return string.length < numberOfChars ? messageError : '';
};

const validateMaxChars = (
  string,
  numberOfChars,
  messageError = 'Excede el máximo de caracteres'
) => {
  return string.length > numberOfChars ? messageError : '';
};

const emailRegex =
  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validateEmailField = (email, messageError = 'Invalid Email') => {
  if (!emailRegex.test(email)) {
    return messageError;
  }
  return '';
};

const validatePasswordField = (
  password,
  messageError = 'Min length: 6 characters'
) => {
  if (password.length > 5) {
    return '';
  }

  return messageError;
};

const validateStrongPassword = value => {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%\\^&\\*])(?=.{8,})/.test(
    value
  );
};

function validateIfFormIsEmpty(formData, skipItems = 0) {
  const entries = Object.entries(formData);
  let count = skipItems;
  entries.forEach(([, value]) => {
    if (value?.length != 0 || value?.required) {
      count++;
    }
  });

  return entries.length <= count;
}

const validateErrorMessagesFromObject = object => {
  let valid = false;
  Object.entries(object).forEach(([, value]) => {
    if (value.length != 0) {
      valid = true;
    }
  });
  return valid;
};

const isOnlyDigits = value => {
  return /^[0-9]*$/.test(value);
};
const validateAlphaNumeric = value => {
  return /^[A-Za-z0-9]*$/.test(value);
};

const newAlphaNumericValidation = value => {
  const pattern = /^[a-zA-Z0-9\s-áéíóúÁÉÍÓÚ]+$/;
  return pattern.test(value);
};

const validateAlphaNumericHyphenAndUnderscore = value => {
  return /^[A-Za-z0-9_-]*$/.test(value);
};

const validateAlphaNumericWithSpaces = value => {
  return /^[A-Za-z0-9\s]*$/.test(value);
};

const validateDecimal = value => {
  return /^\d*\.?\d{0,2}$/.test(value);
};

const validateName = (value = '') => {
  return /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi.test(value);
};

const validateNumerical = value => {
  return /^\d*$/.test(value);
};

const validateReferenceOrSku = value => {
  return /^[A-Za-z0-9-]*$/.test(value);
};

const validateLength = (value, length) => {
  return value.length === length;
};

const phoneSpaceFormatted = inputValue => {
  if (!inputValue) return null;
  return inputValue?.replace(/\s/g, '');
};

const phoneInputCleanFormatted = inputValue => {
  if (!inputValue) return '';
  const digitsOnly = phoneSpaceFormatted(inputValue);
  const formattedPhoneNumber = digitsOnly.replace(
    /(\d{3})(\d{3})(\d{4})(\d{0,4})/,
    '$1 $2 $3$4'
  );
  return formattedPhoneNumber;
};

const validatePhoneNumber = (value, errorMessage) => {
  if (!value) return 'Campo Requerido';

  const digitsOnly = value.replace(/\s/g, '');

  if (!isOnlyDigits(digitsOnly)) {
    return 'Caracteres no válidos';
  }

  const isValidInput = /^\d{10}$/.test(digitsOnly);
  return isValidInput ? '' : errorMessage;
  //   if ()
  //     !value ||
  //       !validateNumerical(value) ||
  //       value.length < 4 ||
  //       value.length > 15
  //   )
  // return errorMessage;
  // return '';
};

const validateCurp = (value, errorMessage) => {
  const isValidCurp =
    /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0\d|1[0-2])(?:[0-2]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/.test(
      value
    );
  if (!value || !isValidCurp) return errorMessage;
  return '';
};

function getErrorMessageTranslation(t, translationPrefix, message) {
  let errorMessage = t(translationPrefix + message, { nsSeparator: false });
  if (!errorMessage) errorMessage = 'Es necesario completar este dato';
  return errorMessage;
}

function validateLessThan(value, limit, message = 'El valor excede el límite') {
  return value <= limit ? '' : message;
}

export {
  canBeEmpty,
  getErrorMessageTranslation,
  isOnlyDigits,
  newAlphaNumericValidation,
  phoneInputCleanFormatted,
  phoneSpaceFormatted,
  validateAlphaNumeric,
  validateAlphaNumericHyphenAndUnderscore,
  validateAlphaNumericWithSpaces,
  validateCurp,
  validateDecimal,
  validateEmailField,
  validateEmptyField,
  validateErrorMessagesFromObject,
  validateIfFormIsEmpty,
  validateLength,
  validateLessThan,
  validateMaxChars,
  validateMinChars,
  validateName,
  validateNumerical,
  validatePasswordField,
  validatePhoneNumber,
  validateReferenceOrSku,
  validateStrongPassword,
};
