import { getTutorial, updateTutorial } from './../../Tutorial/storage';

const onClose = (instance, step) => {
  const tutorial = getTutorial('merchantPaymentReport');
  updateTutorial({
    module: 'merchantPaymentReport',
    content: {
      ...tutorial,
      lastStep: step,
    },
  });

  //instance._setupModal();
  if (tutorial.number <= 1) {
    return instance.show('continue');
  } else if (tutorial.number === 2) {
    return instance.show('activate-tutorial');
  }
};

const continueStep = instance => {
  const tutorial = getTutorial('merchantPaymentReport');

  if (tutorial.lastStep === '') {
    return instance.show('filbrandter-by-', true);
  }

  instance.show(tutorial.lastStep, true);
};

const showFaqHelper = () => {
  const element = document.querySelector('.faq-helper');

  if (element) {
    element.style.display = null;
  }
};

export const reportPaymentTutorialSteps = function (
  shepherd,
  enableMultiBrand
) {
  const stepCount = enableMultiBrand ? 5 : 4;
  let step = 0;
  const getStepNumber = () => {
    step++;
    return `${step} de ${stepCount}`;
  };
  return [
    {
      id: 'welcome',
      title: 'Reporte de pagos a comercio',
      text: 'Explora y conoce las funciones de esta sección.',
      buttons: [
        {
          text: 'Continuar',
          action: () => {
            enableMultiBrand
              ? shepherd.show('filter-by-brand', true)
              : shepherd.show('filter-by-period', true);
          },
        },
      ],
      when: {
        cancel: () => {
          onClose(shepherd, '');
        },
      },
    },
    {
      id: 'continue',
      title: 'Antes de irte',
      text: 'Asegúrate de completar el tutorial para aprovechar al máximo esta sección.',
      buttons: [
        {
          text: 'Completar',
          action: () => {
            continueStep(shepherd);
          },
        },
      ],
      when: {
        cancel: () => {
          shepherd.show('activate-tutorial', true);
        },
      },
    },
    {
      id: 'activate-tutorial',
      title: 'Mira el tutorial',
      text: 'Presiona el ícono para consultar el tutorial cuando lo necesites.',
      attachTo: { element: '#activate-tutorial', on: 'bottom' },
      arrow: true,
      modalOverlayOpeningRadius: 8,
      buttons: [
        {
          text: 'Terminar',
          action: () => {
            showFaqHelper();
            shepherd.complete();
          },
        },
      ],
      when: {
        cancel: () => {
          showFaqHelper();
          shepherd.complete();
        },
      },
    },
    {
      id: 'filter-by-brand',
      title: 'Filtra por marca',
      text: [
        `
      <div>Busca por marca para ver los pagos asociados.</div>
      <div class="shepherd-step">${enableMultiBrand ? getStepNumber() : ''}</div>
      `,
      ],
      attachTo: { element: '.filter-by-brand', on: 'bottom' },
      arrow: true,
      modalOverlayOpeningRadius: 8,
      buttons: [
        {
          type: 'next',
          text: 'Continuar',
        },
      ],
      when: {
        cancel: () => {
          onClose(shepherd, 'filter-by-period');
        },
      },
    },
    {
      id: 'filter-by-period',
      title: 'Filtra por fecha',
      text: [
        `
      <div>Ajusta las fechas para ver los pagos de ese periodo.</div>
      <div class="shepherd-step">${getStepNumber()}</div>
      `,
      ],
      attachTo: { element: '.filter-by-period', on: 'bottom' },
      arrow: true,
      modalOverlayOpeningRadius: 8,
      buttons: [
        {
          type: 'next',
          text: 'Continuar',
        },
      ],
      when: {
        cancel: () => {
          onClose(shepherd, 'filter-by-period');
        },
      },
    },
    {
      id: 'filter-by-channel',
      title: 'Filtra por canal de venta',
      text: [
        `
      <div>Selecciona los pagos de las transacciones hechas en línea o en tienda física.</div>
      <div class="shepherd-step">${getStepNumber()}</div>
      `,
      ],
      attachTo: { element: '.filter-by-channel', on: 'bottom' },
      arrow: true,
      modalOverlayOpeningRadius: 8,
      buttons: [
        {
          type: 'next',
          text: 'Continuar',
        },
      ],
      when: {
        cancel: () => {
          onClose(shepherd, 'filter-by-channel');
        },
      },
    },
    {
      id: 'filter-by-transaction',
      title: 'Filtra por tipo de transacción',
      text: [
        `
      <div>Elige el estado de las transacciones que necesitas visualizar.</div>
      <div class="shepherd-step">${getStepNumber()}</div>
      `,
      ],
      attachTo: { element: '.filter-by-transaction', on: 'bottom' },
      arrow: true,
      modalOverlayOpeningRadius: 8,
      buttons: [
        {
          type: 'next',
          text: 'Continuar',
        },
      ],
      when: {
        cancel: () => {
          onClose(shepherd, 'filter-by-transaction');
        },
      },
    },
    {
      id: 'pagination',
      title: 'Navega todas las columnas',
      text: [
        `
      <div>Desliza la barra para ver todas las columnas disponibles.</div>
      <div class="shepherd-step">${getStepNumber()}</div>
      `,
      ],
      attachTo: { element: '.scroll-overlay', on: 'top' },
      arrow: true,
      modalOverlayOpeningRadius: 8,
      buttons: [
        {
          text: 'Continuar',
          action: () => {
            showFaqHelper();
            shepherd.complete();
          },
        },
      ],
      when: {
        cancel: () => {
          onClose(shepherd, 'pagination');
        },
      },
    },
  ];
};
