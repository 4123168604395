import React from 'react';
import PropTypes from 'prop-types';
import './renderBarcode.scss';
function RenderBarcode({ barcode, className = '' }) {
  return (
    <img
      className={` barcode ${className ? className : ''}`}
      src={barcode}
      alt='barcode'
    />
  );
}

RenderBarcode.propTypes = {
  barcode: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default RenderBarcode;
