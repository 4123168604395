import React from 'react';
import CampaignPromotionsPage from '../../components/CampaignPromotions/CampaignPromotionsPage';
import CampaignsPage from '../../components/Campaigns/CampaignsPage';
import { ReactComponent as CampaignIcon } from '../../components/Navbar/campaign-icon.svg';
import { ReactComponent as FaqIcon } from '../../components/Navbar/faq-icon.svg';
import { ReactComponent as HelpIcon } from '../../components/Navbar/help-icon.svg';
import { ReactComponent as HelpSupportIcon } from '../../components/Navbar/help-support.svg';
import { ReactComponent as HistoryIcon } from '../../components/Navbar/history-orders-icon.svg';
import { ReactComponent as HomeIcon } from '../../components/Navbar/home-icon.svg';
import { ReactComponent as LogoIcon } from '../../components/Navbar/logo-icon.svg';
import { ReactComponent as PaymentCodeIcon } from '../../components/Navbar/payment-code-icon.svg';
import { ReactComponent as PaymentLogIcon } from '../../components/Navbar/payment-log-icon.svg';
import { ReactComponent as PaymentQRIcon } from '../../components/Navbar/payment-qr-icon.svg';
import { ReactComponent as StoreIcon } from '../../components/Navbar/store-icon.svg';
import { ReactComponent as UserIcon } from '../../components/Navbar/user-icon.svg';
import ChangeUserPasswordPage from '../pages/ChangeUserPassword';
import CreateUserPage from '../pages/CreateUser';
import EditUserPage from '../pages/EditUser';
import FaqPage from '../pages/Faq';
import HelpPage from '../pages/Help';
import HomePage from '../pages/Home/Home';
import ImportUsers from '../pages/ImportUsers';
import LogoPage from '../pages/Logo';
import LogoUploadPage from '../pages/LogoUpload';
// import PayOrderPage from '../pages/PayOrder';
import PayOrderByQRPage from '../pages/PayOrderByQR';
import PayOrderCouponPage from '../pages/PayOrder/PayOrderCouponPage';
import PaymentDetailPage from '../pages/PaymentDetail';
import PaymentGeneratedPage from '../pages/PaymentGenerated';
import PaymentGeneratedByQRPage from '../pages/PaymentGeneratedByQR';
import PaymentHistoryPage from '../pages/PaymentHistory';
import PaymentOrderDetailPage from '../pages/PaymentOrderDetail';
import PaymentOrderHistoryPage from '../pages/PaymentOrderHistory';
import PaymentReimbursementPage from '../pages/PaymentReimbursement';
import StoreBulkPage from '../pages/StoreBulk/StoreBulkPage';
import StoreCreateEdit from '../pages/StoreCreateEdit';
import StoreDetailPage from '../pages/StoreDetail';
import StoreUsersPage from '../pages/StoreUsers';
import StoresPage from '../pages/Stores';
import UserProfilePage from '../pages/UserProfile';
import UsersPage from '../pages/Users';
import MerchantPaymentReport from '../pages/MerchantPaymentReport';
import MerchantTransactionsReport from '../pages/MerchantTransactionsReport';

const paymentCode = {
  name: 'Código de pago',
  module: 'create_order',
  isneedWhiteList: true,
  routes: [
    {
      path: '/orders/create-by-code',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: false,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['create_by_amount', 'create_by_amount', 'create_order_merchant'],
      component: PayOrderCouponPage,
    },
    {
      path: '/orders/v1/generated/:orderId/:storeId',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: false,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['create_by_amount', 'create_by_amount', 'create_order_merchant'],
      component: PaymentGeneratedPage,
    },
  ],
  icon: <PaymentCodeIcon className='icon-unselected' />,
  iconSelected: <PaymentCodeIcon />,
};

const generateOrders = {
  name: 'Código QR',
  module: 'create_order',
  routes: [
    {
      path: '/orders/create-by-qr',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: false,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['create_by_amount', 'create_by_amount', 'create_order_merchant'],
      component: PayOrderByQRPage,
    },
    {
      path: '/orders/generated/:orderId/:storeId',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: false,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['create_by_amount', 'create_by_amount', 'create_order_merchant'],
      component: PaymentGeneratedByQRPage,
    },
    {
      path: '/orders/generated/:orderId/:storeId/:paymentMode',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: false,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['create_by_amount', 'create_by_amount', 'create_order_merchant'],
      component: PaymentGeneratedByQRPage,
    },
    {
      path: '/orders/generated/:orderId',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: true,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['create_by_amount', 'create_by_amount', 'create_order_merchant'],
      component: PaymentGeneratedByQRPage,
    },
  ],
  icon: <PaymentQRIcon className='icon-unselected' />,
  iconSelected: <PaymentQRIcon />,
};

/* const generateOrders = {
  name: 'Código QR',
  module: 'create_order',
  routes: [
    {
      path: '/orders/create-by-qr',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: false,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['create_by_amount', 'create_by_amount', 'create_order_merchant'],
      component: PayOrderPage,
    },
    {
      path: '/orders/generated/:orderId/:storeId',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: false,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['create_by_amount', 'create_by_amount', 'create_order_merchant'],
      component: PaymentGeneratedPage,
    },
    {
      path: '/orders/generated/:orderId',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: false,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['create_by_amount', 'create_by_amount', 'create_order_merchant'],
      component: PaymentGeneratedPage,
    },
  ],
  icon: <PaymentQRIcon className='icon-unselected' />,
  iconSelected: <PaymentQRIcon />,
}; */

/* const generateOrdersOld = {
  name: 'Crear orden',
  module: 'create_order',
  routes: [
    {
      path: '/orders/create',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: false,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['create_by_amount', 'create_by_amount', 'create_order_merchant'],
      component: PayOrderPage,
    },
    {
      path: '/orders/generated/:orderId/:storeId',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: false,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['create_by_amount', 'create_by_amount', 'create_order_merchant'],
      component: PaymentGeneratedPage,
    },
    {
      path: '/orders/generated/:orderId',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: false,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['create_by_amount', 'create_by_amount', 'create_order_merchant'],
      component: PaymentGeneratedPage,
    },
  ],
  icon: <PaymentQRIcon className='icon-unselected' />,
  iconSelected: <PaymentQRIcon />,
} */

const historyOrders = {
  name: 'Historial de cobros',
  module: 'get_order',
  routes: [
    {
      path: '/orders',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: false,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['list_orders', 'list_orders_merchant'],
      component: PaymentOrderHistoryPage,
    },
    {
      path: '/orders/:orderId',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: false,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['view_order_details', 'view_order_details_merchant'],
      component: PaymentOrderDetailPage,
    },
    {
      path: '/orders/:orderId/:storeId',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: false,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['view_order_details', 'view_order_details_merchant'],
      component: PaymentOrderDetailPage,
    },
  ],
  icon: <HistoryIcon className='icon-unselected' />,
  iconSelected: <HistoryIcon />,
};

const historyOrdersOld = {
  ...historyOrders,
  name: 'Historial de órdenes',
};

const paymentLogs = {
  name: 'Historial de ventas',
  module: 'payments',
  routes: [
    {
      path: '/payments',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: true,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['list_payments', 'list_payments_merchant'],
      component: PaymentHistoryPage,
    },
    {
      path: '/payments/:paymentId',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: true,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['view_payment_details', 'view_payment_details_merchant'],
      component: PaymentDetailPage,
    },
    {
      path: '/payments/:paymentId/reimbursement',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: true,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['cancel_payment', 'cancel_payment_merchant'],
      component: PaymentReimbursementPage,
    },
  ],
  icon: <PaymentLogIcon className='icon-unselected' />,
  iconSelected: <PaymentLogIcon />,
};

const merchatTransactionReport = {
  name: 'Reporte de transacciones',
  module: 'payments',
  routes: [
    {
      path: '/payments',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: true,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['list_payments', 'list_payments_merchant'],
      component: MerchantTransactionsReport,
    },
    {
      path: '/payments/:paymentId',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: true,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['view_payment_details', 'view_payment_details_merchant'],
      component: PaymentDetailPage,
    },
    {
      path: '/payments/:paymentId/reimbursement',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: true,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['cancel_payment', 'cancel_payment_merchant'],
      component: PaymentReimbursementPage,
    },
  ],
  icon: <PaymentLogIcon className='icon-unselected' />,
  iconSelected: <PaymentLogIcon />,
};

const merchantPaymentReport = {
  name: 'Reporte de pagos a comercios',
  module: 'merchants_payments',
  routes: [
    {
      path: '/merchant-payments-report',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: true,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: [
        'list_payment_schedules',
        'list_payments',
        'list_payments_merchant',
      ],
      component: MerchantPaymentReport,
    },
    {
      path: '/payments/:paymentId',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: true,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: [
        'list_payment_schedules',
        'view_payment_details',
        'view_payment_details_merchant',
      ],
      component: PaymentDetailPage,
    },
    {
      path: '/payments/:paymentId/reimbursement',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: true,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: [
        'list_payment_schedules',
        'cancel_payment',
        'cancel_payment_merchant',
      ],
      component: PaymentReimbursementPage,
    },
  ],
  icon: <PaymentLogIcon className='icon-unselected' />,
  iconSelected: <PaymentLogIcon />,
};

const stores = {
  name: 'Sucursales',
  module: 'stores',
  routes: [
    {
      path: '/stores',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: false,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['list_stores', 'list_stores_merchant'],
      component: StoresPage,
    },
    {
      path: '/stores/new',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: false,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['create_store', 'create_store_merchant'],
      component: StoreCreateEdit,
    },
    {
      path: '/stores/:storeId',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: false,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['view_store_details', 'view_store_details_merchant'],
      component: StoreDetailPage,
    },
    {
      path: '/stores/:storeId/users',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: false,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['view_store_details', 'view_store_details_merchant'],
      component: StoreUsersPage,
    },
    {
      path: '/stores/:storeId/edit',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: false,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['edit_store', 'edit_store_merchant'],
      component: StoreCreateEdit,
    },
    {
      path: '/stores/bulk/confirmation',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: false,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['create_store', 'create_store_merchant'],
      component: StoreBulkPage,
    },
  ],
  icon: <StoreIcon className='icon-unselected' />,
  iconSelected: <StoreIcon />,
};

const users = {
  name: 'Usuarios',
  module: 'users',
  routes: [
    {
      path: '/users',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: false,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['list_users', 'list_users_merchant'],
      component: UsersPage,
    },
    {
      path: '/user/new',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: false,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['create_user', 'create_user_merchant'],
      component: CreateUserPage,
    },
    {
      path: '/users/:userId/edit',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: false,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['edit_user', 'edit_user_merchant'],
      component: EditUserPage,
    },
    {
      path: '/users/import',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: false,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['create_user', 'create_user_merchant'],
      component: ImportUsers,
    },
  ],
  icon: <UserIcon className='icon-unselected' />,
  iconSelected: <UserIcon />,
};

const help = {
  name: 'Contacto',
  module: 'help',
  routes: [
    {
      path: '/help',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: true,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      component: HelpPage,
    },
  ],
  icon: <HelpSupportIcon className='icon-unselected' />,
  iconSelected: <HelpSupportIcon />,
};

const faq = {
  name: 'Preguntas frecuentes',
  module: 'faq',
  routes: [
    {
      path: '/faq',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: true,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      component: FaqPage,
    },
  ],
  icon: <FaqIcon className='icon-unselected' />,
  iconSelected: <FaqIcon />,
};

const home = {
  name: 'Inicio',
  module: 'Home',
  routes: [
    {
      path: '/home',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: true,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['home'],
      component: HomePage,
    },
  ],
  icon: <HomeIcon className='icon-unselected' />,
  iconSelected: <HomeIcon />,
};

const merchant_image = {
  name: 'Imagen de comercio',
  module: 'merchant_image',
  routes: [
    {
      path: '/logos',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: true,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['view_logos'],
      component: LogoPage,
    },
    {
      path: '/logos/upload/:type',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: true,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['upload_logos'],
      component: LogoUploadPage,
    },
  ],
  icon: <LogoIcon className='icon-unselected' />,
  iconSelected: <LogoIcon />,
};

const userProfile = {
  name: 'user',
  module: 'user_profile',
  routes: [
    {
      path: '/me',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: true,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      component: UserProfilePage,
    },
    {
      path: '/me/password',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: true,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      component: ChangeUserPasswordPage,
    },
  ],
  icon: <HelpIcon className='icon-unselected' />,
  iconSelected: <HelpIcon />,
};

const campaigns = {
  name: 'Campañas',
  module: 'campaigns',
  routes: [
    {
      path: '/campaigns',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: true,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['list_campaigns'],
      component: CampaignsPage,
    },
    {
      path: '/campaigns/:campaign_id',
      exact: true,
      channels: [
        {
          channel: 'online',
          active: true,
        },
        {
          channel: 'instore',
          active: true,
        },
      ],
      scopes: ['view_campaign_details'],
      component: CampaignPromotionsPage,
    },
  ],
  icon: <CampaignIcon className='icon-unselected' />,
  iconSelected: <CampaignIcon />,
};

export {
  campaigns,
  paymentLogs,
  faq,
  help,
  historyOrders,
  historyOrdersOld,
  home,
  merchant_image,
  paymentCode,
  stores,
  userProfile,
  users,
  generateOrders,
  merchantPaymentReport,
  merchatTransactionReport,
};
