import React from 'react';
import { PaginationMobileB2B } from './PaginationMobileB2B';

const MAP_PAGINATION_TYPE = {
  'b2b': PaginationMobileB2B,
};

const PaginationMobile = ({ type, ...moreProps }) => {
  const defaultPaginationMobileType = 'b2b';

  // eslint-disable-next-line
  const PaginationMobileComponent = MAP_PAGINATION_TYPE[type] || MAP_PAGINATION_TYPE[defaultPaginationMobileType];

  return (<PaginationMobileComponent {...moreProps} />);
};

export {
  PaginationMobile,
};
