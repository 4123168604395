import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Cleave from 'cleave.js';
import { TextInput } from '../../../ds';
import { autoCompleteDecimalNumber } from '../../../Utils/formats';
import { forwardRef } from 'react';

const CurrencyInput = forwardRef(function CustomInput(
  {
    onChange = () => null,
    onBlur = () => null,
    className = '',
    errorMessage,
    label = '',
    helper = '',
    placeholder = '',
    type = 'outline',
    required = false,
    id = '',
    name = '',
    ...rest
  },
  ref
) {
  const [text, setText] = useState('');
  const [rawValue, setRawValue] = useState(0);

  useEffect(() => {
    const cleave = new Cleave(`[name="${name}"]`, {
      numeral: true,
      prefix: '$',
      noImmediatePrefix: true,
      numeralDecimalScale: 2,
      rawValueTrimPrefix: true,
      onValueChanged: e => {
        if (Boolean(e.target) && Boolean(e.target.rawValue)) {
          setText(e.target.value);
          setRawValue(e.target.rawValue);
          onChange(e.target.rawValue);
        }
      },
    });

    return () => {
      cleave.destroy();
    };
  }, []);

  return (
    <TextInput
      ref={ref}
      enabledIdx
      inputmode='decimal'
      className={`${className}`}
      errorMessage={errorMessage}
      label={label}
      helper={helper}
      placeholder={placeholder}
      onBlur={() => {
        if (rawValue && text) {
          const autoCompleteValue = autoCompleteDecimalNumber(text.slice(1), 2);
          setText(`$${autoCompleteValue}`);
          onBlur(rawValue);
        }
      }}
      type={type}
      required={required}
      id={id}
      name={name}
      value={text}
      {...rest}
    />
  );
});

CurrencyInput.propTypes = {
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  helper: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  onBlur: PropTypes.func,
};

export default CurrencyInput;
