import React from 'react';
import { ReactComponent as SearchIcon } from '../../../common/assets/search-icon.svg';

import PropTypes from 'prop-types';
import { Button } from '../../../ds';

const AdvancedSearchButton = ({ handleOpen }) => {
  return (
    <Button
      className='search-advanced k-ds-width-full'
      type='link'
      size='small'
      horizontalPadding='0px'
      verticalPadding='0px'
      onClick={() => handleOpen(true)}
    >
      <SearchIcon className='k-ds-m-right-sp-03' />
      <div>Búsqueda avanzada</div>
    </Button>
  );
};

AdvancedSearchButton.propTypes = {
  handleOpen: PropTypes.func.isRequired,
};

export default AdvancedSearchButton;
