/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Auth from '../../../Utils/Auth/auth';
import { orderProductsMaxLimit } from '../../../Utils/constants';
import { goPage } from '../../../Utils/pages';
import {
  allowedMerchantFillDateReference,
  getCurrentDateTime,
} from '../../../Utils/utils';
import { useDeviceContextViewport } from '../../../contexts/Device/DeviceContext';
import { useLoaderContext } from '../../../contexts/Loader/LoaderContext';
import { Button, Space, TextInput, Card } from '../../../ds';
import { getUser } from '../../../mockData';
import { createOrder, saveLogbehavior } from '../../../services/Application';
import Checkbox from '../../Checkbox/Checkbox';
import CurrencyInput from '../../DS/CurrencyInput/CurrencyInput';
import ErrorModal from '../../ErrorModal/ErrorModal';
import ErrorText from '../order-errors.json';
import { useForm, useController } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import AmountSchema from './AmountSchema';
import ButtonActions from '../../PaymentGeneratedByQRv2/ButtonActions';
import AnalyticsEvent from '../../../services/analytics/AnalyticsEvent';
import { DISABLED_PAYMENTS_DESCRIPTION, DISABLED_PAYMENTS_TITLE } from '../../../Utils/payment_constants';
import { useStore } from '../../../services/store';
import { observer } from 'mobx-react-lite';

const initialPayOrder = {
  quantity: '',
  amount: '',
  reference: '',
};

function Amount(props) {
  const {
    storeid,
    location,
    logbehavior,
    paymentAmount,
    isPaymentValidated,
    paymentMode,
    onClear,
  } = props;
  const user = getUser();
  const { t } = useTranslation();
  const history = useHistory();
  const { merchant } = Auth.userData();
  const { qrCodeStore: { reload }}  = useStore();
  const { setIsLoaderOpen } = useLoaderContext();
  const viewport = useDeviceContextViewport();
  const { isMobile } = viewport;
  const columnsClass = isMobile ? 'one-column' : 'two-columns';
  const [errorModalState, setErrorModalState] = useState(false);
  const [isCheckedFillDate, setIsCheckedFillDate] = useState(false);
  const [isAmountValid, setIsAmountValid] = useState(false);
  const [amountEndpointError, setAmountEndpointError] = useState('');
  const [referenceEndpointError, setReferenceEndpointError] = useState('');
  const [operationError, setOperationError] = useState({
    title: undefined,
    description: undefined,
  });

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    trigger,
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: initialPayOrder,
    reValidateMode: 'onChange',
    mode: 'all',
    resolver: zodResolver(AmountSchema),
  });

  const { field } = useController({ name: 'amount', control });

  function handleOnClear() {
    reset();
    setAmountEndpointError('');
    setReferenceEndpointError('');
    onClear();
    reload();
  }

  const onCreatePayOrder = formData => {
    setIsLoaderOpen(true);
    let order = {
      store_id: storeid,
      order_reference: isCheckedFillDate
        ? getCurrentDateTime({ setMinutes: true, setSeconds: false })
        : formData.reference,
      items_count: formData.quantity,
      shipping_amount: 0,
      total: Number(formData.amount),
      order_assistant: '',
    };

    let data = {
      staff_id: user.employeeId().toString(),
      order: order,
    };

    const translationPrefix = 'order.';

    saveLogbehavior({
      userId: user.employeeId().toString(),
      log_behavior_data: logbehavior.current,
    }).then(() => {
      saveLogbehavior({
        userId: user.employeeId().toString(),
        log_behavior_data: `$Loc${location?.lat ?? 0},${location?.lon ?? 0}`,
      });
      logbehavior.current = '';
    });

    createOrder(data)
      .then(response => {
        setIsLoaderOpen(false);
        AnalyticsEvent().codeQRCreadted();
        goPage(
          `/orders/generated/${response.data.order_id}/${storeid}/${paymentMode}`,
          history
        );
      })
      .catch(error => {
        setIsLoaderOpen(false);
        const errorCode = error?.response?.data?.code;
        const responseMessage = error?.response?.data?.message;
        const translationMessage = t(translationPrefix + responseMessage, {
          nsSeparator: false,
        });

        AnalyticsEvent().errorTrack({
          errorType: 'pay-order-by-qr-v2-create-order-error',
          errorDescription: {
            code: errorCode,
            responseMessage: responseMessage,
          },
        });
        switch (errorCode) {
          case 'invalid-payment-data':
          case 'update-payment-failed':
          case 'updatePaymentFailed':
          case 'AmountNotInRange':
            setAmountEndpointError(
              'El monto está fuera del rango permitido por la tienda.'
            );
            return;
          case 'StoreClosed':
          case 'StoreOpenRetrieveFail':
            setOperationError(ErrorText[errorCode]);
            return setErrorModalState(true);
          case 'OrderReferenceUsed':
          case 'ParamInvalid':
            setReferenceEndpointError('Esta referencia ya existe.');
            return;
          case 'paymentValidationFailed':
          case 'payment-validation-failed':
            setOperationError({
              title: 'Error Interno ',
              description: 'Error en validación de cobro',
            });
            return setErrorModalState(true);
          default:
            setOperationError({
              title: DISABLED_PAYMENTS_TITLE,
              description: !translationMessage.includes(translationPrefix)
                ? translationMessage
                : DISABLED_PAYMENTS_DESCRIPTION,
            });
            return setErrorModalState(true);
        }
      });
  };

  return (
    <div id='amount-component'>
      <Card type='secondary' className='k-ds-width-full k-ds-m-top-sp-06'>
        <div className='sub-header k-ds-m-top-sp-10'>
          Cobra por monto o producto
        </div>
        <ErrorModal
          defaultOpened={errorModalState}
          close={() => setErrorModalState(false)}
          heading={operationError.title}
          description={operationError.description}
        />
        <div className={`${columnsClass} k-ds-m-top-sp-06`}>
          <TextInput
            {...register('quantity')}
            id='quantity'
            className='k-ds-m-bottom-sp-06'
            label='Cantidad de artículos'
            placeholder='Ingresa la cantidad de artículos'
            type='outline'
            helper={`Máximo ${orderProductsMaxLimit} artículos`}
            errorMessage={
              errors && errors.quantity ? errors.quantity.message : ''
            }
            required
          />

          <CurrencyInput
            id='amount'
            name='amount'
            className='k-ds-m-bottom-sp-06'
            label='Monto total'
            helper='Incluir costo de envío si aplica.'
            placeholder='$0.00'
            type='outline'
            required
            onChange={value => {
              field.onChange(value);
            }}
            onBlur={value => {
              field.onChange(value);
              setIsAmountValid(paymentAmount < value);
            }}
            errorMessage={
              amountEndpointError
                ? amountEndpointError
                : errors && errors.amount
                  ? errors.amount.message
                  : ''
            }
          />

          <TextInput
            {...register('reference')}
            className='k-ds-m-bottom-sp-06'
            disabled={isCheckedFillDate}
            label='Referencia de compra'
            helper='Puedes ingresar letras, números y guiones.'
            placeholder='(Opcional)'
            type='outline'
            required={watch('reference') !== ''}
            errorMessage={
              referenceEndpointError
                ? referenceEndpointError
                : errors && errors.reference
                  ? errors.reference.message
                  : ''
            }
          />

          {allowedMerchantFillDateReference.includes(merchant.merchant_id) && (
            <div className='center-checkbox'>
              <Checkbox
                {...register('checkedFillDate')}
                filled
                defaultChecked={isCheckedFillDate}
                onChange={() => {
                  setIsCheckedFillDate(!isCheckedFillDate);
                  const date = getCurrentDateTime({
                    setMinutes: true,
                    setSeconds: false,
                  }).toString();
                  setValue('reference', date);
                  trigger('reference');

                  if (referenceEndpointError) {
                    setReferenceEndpointError('');
                  }
                }}
              />{' '}
              Añadir fecha y hora
            </div>
          )}
        </div>
      </Card>
      <ButtonActions
        cancelAction={handleOnClear}
        continueAction={handleSubmit(onCreatePayOrder)}
        disableContinueAction={
          storeid === '' ||
          !isValid ||
          (isPaymentValidated && isAmountValid) ||
          paymentMode === ''
        }
      />
    </div>
  );
}

Amount.propTypes = {
  storeid: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  logbehavior: PropTypes.object.isRequired,
  paymentAmount: PropTypes.number,
  isPaymentValidated: PropTypes.bool,
  paymentId: PropTypes.string,
  onClear: PropTypes.func,
  paymentMode: PropTypes.string,
};

export default observer(Amount);
