import {
  excelAddColumnDropdown,
  excelFileCreator,
  excelFileDownloader,
} from '../../Utils/excelUtils';
import { getErrorMessageTranslation } from '../../Utils/formValidations';
import userExportSchema, {
  defaultSchemaHeaders,
} from '../../services/userExportSchema';

export function getHeaders() {
  const schemaKeys = userExportSchema?._nodes ?? defaultSchemaHeaders;

  const headers = {
    storeName: schemaKeys[0],
    role: schemaKeys[1],
    UPRC: schemaKeys[2],
    phone: schemaKeys[3],
    email: schemaKeys[4],
    secondLastName: schemaKeys[5],
    lastName: schemaKeys[6],
    name: schemaKeys[7],
  };

  return headers;
}

export function downloadTemplate(rolesNames) {
  let workbook = excelFileCreator([
    'Nombre',
    'Apellido',
    'Segundo Apellido',
    'Correo',
    'Teléfono Celular',
    'CURP',
    'Rol',
    'Sucursal',
  ]);
  workbook = excelAddColumnDropdown(
    workbook,
    0,
    7,
    `"${rolesNames.join(',')}"`
  );
  excelFileDownloader(workbook, 'Template para Alta de varios usuarios');
}

export function translatedErrors(t, prefix, errors) {
  let translatedErrors = [];

  if (errors) {
    for (const [key] of Object.entries(errors)) {
      if (Array.isArray(errors[key])) {
        let keyErrors = [];
        errors[key].map(error => {
          let keyError = getErrorMessageTranslation(t, prefix, error);
          keyErrors.push(keyError);
        });
        translatedErrors[key] = keyErrors;
      } else if (errors[key]) {
        translatedErrors[key] = getErrorMessageTranslation(
          t,
          prefix,
          errors[key]
        );
      }
    }
  }

  return translatedErrors;
}
