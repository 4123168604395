export function itemsToCsv(items) {
  let csv = '';
  let temporalPercentageArray = [];
  let cumulativePercentage = 0;
  const total = items.length;
  const toAdd = 100 / total;
  for (let row = 0; row < items.length; row++) {
    let keysAmount = Object.keys(items[row]).length;
    let keysCounter = 0;
    if (row === 0 && row !== undefined) {
      for (let key in items[row]) {
        if (key !== undefined)
          csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n');
        keysCounter++;
      }
      for (let key in items[row]) {
        if (key !== undefined)
          csv += items[row][key] + (keysCounter + 1 > keysAmount ? ',' : '');
        keysCounter++;
      }
      csv = csv.slice(0, -1) + '\r\n';
    } else {
      for (let key in items[row]) {
        if (key !== undefined)
          csv +=
            items[row][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n');
        keysCounter++;
      }
    }
    keysCounter = 0;
    cumulativePercentage = cumulativePercentage + toAdd;
    temporalPercentageArray[row] = cumulativePercentage;
  }
  return {
    csv,
    temporalPercentageArray,
  };
}
