import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styles from './ActivationForm.module.scss';
import BankDataForm from './BankDataForm';
import BusinessDataForm from './BusinessDataForm';
import FilesDataForm from './FilesDataForm';
import SentFormView from './SentFormView';
import { ReactComponent as CheckIcon } from './res/check.svg';

const Stepper = ({ steps }) => (
  <div className={styles.steps}>
    <div className={styles.stepper}>
      <div
        className={cn(styles.step, {
          [styles.active]: steps[0].current,
          [styles.done]: steps[0].done,
        })}
      >
        <div className={styles.stepNumber}>
          {' '}
          <span> {steps[0].done ? <CheckIcon /> : steps[0].step} </span>
        </div>
        <div className={styles.stepLabel}>Datos del comercio</div>
      </div>
      <div className={styles.connector_1} />
      <div
        className={cn(styles.step, {
          [styles.active]: steps[1].current,
          [styles.done]: steps[1].done,
        })}
      >
        <div className={styles.stepNumber}>
          {' '}
          <span>{steps[1].done ? <CheckIcon /> : steps[1].step}</span>
        </div>
        <div className={styles.stepLabel}>Datos bancarios</div>
      </div>
      <div className={styles.connector_2} />
      <div
        className={cn(styles.step, {
          [styles.active]: steps[2].current,
          [styles.done]: steps[1].done,
        })}
      >
        <div className={styles.stepNumber}>
          {' '}
          <span>{steps[2].done ? <CheckIcon /> : steps[2].step}</span>
        </div>
        <div className={styles.stepLabel}>Verificación de identidad</div>
      </div>
    </div>
  </div>
);

Stepper.propTypes = {
  steps: PropTypes.array.isRequired,
};

const Form = ({ handleNextView }) => {
  const [isSentForm, setIsSentForm] = useState(false);

  const [steps, setSteps] = useState([
    { step: 1, done: false, current: true },
    { step: 2, done: false, current: false },
    { step: 3, done: false, current: false },
  ]);

  const [formData, setFormData] = useState({
    taxType: 'legal',
    isSas: false,
  });

  const handleNextStep = (data, done, nextStep) => {
    setFormData({ ...formData, ...data });
    setSteps(prev => {
      return prev.map(item => {
        if (item.step === done) {
          return { ...item, done: true, current: false };
        }

        if (item.step === nextStep) {
          return { ...item, done: false, current: true };
        }
        return item;
      });
    });
  };

  return (
    <div className={styles.container}>
      <Stepper steps={steps} />
      <div className={styles.wrapper}>
        <div
          className={cn({
            [styles.titleModal]: !isSentForm,
            [styles['titleModal-center']]: isSentForm,
          })}
        >
          {isSentForm
            ? '¡Formulario de afiliación enviado!'
            : 'Formulario de afiliación'}
        </div>
        {steps[0].current && (
          <BusinessDataForm
            defaultFormValues={formData}
            handleNextStep={handleNextStep}
          />
        )}
        {steps[1].current && (
          <BankDataForm
            defaultFormValues={formData}
            handleNextStep={handleNextStep}
          />
        )}
        {steps[2].current && (
          <FilesDataForm
            handleSuccess={() => {
              handleNextStep({}, 3, 4);
              setIsSentForm(true);
            }}
          />
        )}
        {isSentForm && <SentFormView handleButton={handleNextView} />}
      </div>
    </div>
  );
};
export default Form;

Form.propTypes = {
  handleNextView: PropTypes.func.isRequired,
};
