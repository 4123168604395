import styled, { css } from 'styled-components';
import KDS from './../../../../ds/lib/k-ds-foundation';

export const StyledSelectOutline = styled.div`
  position: relative;

  .arrow-icon {
    fill: ${KDS.getColorToken('interactive-alternate')};
  }

  .selector {
    ${KDS.setColorTokenWithFallback(
      'border: 1px solid ',
      'interactive-alternate'
    )}

    border-radius: 8px;
    box-sizing: border-box;
    padding: 14px;
    cursor: pointer;
    height: 52px;
    ${KDS.setColorTokenWithFallback('background-color: ', 'white')}
    ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}

    &.selected {
      ${KDS.setColorTokenWithFallback(
        'border: 1px solid ',
        'interactive-primary'
      )}

      svg {
        ${KDS.setColorTokenWithFallback('fill: ', 'interactive-primary')}
      }
    }

    .control {
      min-width: 0;

      .control-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .show {
      transform: rotateX(180deg);
    }

    ${props =>
      props.disabled &&
      css`
        cursor: not-allowed;
        ${KDS.setColorTokenWithFallback('background-color: ', 'grey-100')}
        ${KDS.setColorTokenWithFallback('border: 1px solid ', 'grey-300')}
        ${KDS.setColorTokenWithFallback('color: ', 'text-placeholder')}

        svg path {
          ${KDS.setColorTokenWithFallback('stroke: ', 'grey-500')}
        }
      `}

    ${props =>
      props.showPlaceholder &&
      css`
        ${KDS.setColorTokenWithFallback('color: ', 'text-secondary')}
      `}

    ${props =>
      props.active &&
      css`
        ${KDS.setColorTokenWithFallback(
          'border: 1px solid',
          'interactive-primary'
        )}

        .arrow-icon {
          fill: ${KDS.getColorToken('interactive-primary')};
        }
      `}
  }

  .options-group {
    position: absolute;
    padding: 16px;
    z-index: 1;
    display: none;
    transform: translate(0, ${KDS.getSpacing('spacing-01')});
    transform: translate(0, var(--k-ds-spacing-01));
    overflow-y: auto;
    max-height: 270px;
    border-radius: 8px;
    box-sizing: border-box;
    width: 100%;

    ${KDS.setColorWithFallback('background: ', 'white')}
    ${KDS.setColorTokenWithFallback(
      'border: 1px solid ',
      'interactive-alternate'
    )}
    ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}

    &.show {
      display: block;
    }
  }

  .group-option {
    display: grid;
    grid-gap: 16px;
  }

  .list-option {
    display: grid;
    grid-gap: 4px;
    flex: 1;
    min-width: 0;

    .option {
      height: 34px;
      display: flex;
      align-items: center;
      padding: 0 8px;
      border-radius: 4px;
      min-width: 0;

      .text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:hover,
      &.selected {
        cursor: pointer;
        background: #e3f2fd;
      }
    }
  }
`;

export const HiddenDivInput = styled.input`
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  top: 50%;
  left: 50%;
`;

export const StyledNoResults = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .not-found-icon {
    width: 48px;
    height: 48px;
    margin-bottom: 16px;
  }

  .button-clear {
    font-weight: 500;
    padding-bottom: 8px;
  }
`;
