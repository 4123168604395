import _ from 'lodash';
import { decycle } from './cycles';

export function JsonUtil() {
  return {
    parseCircularJSON: function (obj) {
      if (
        _.isObject(obj) &&
        !_.isEmpty(obj) &&
        !_.isNull(obj) &&
        !_.isUndefined(obj)
      ) {
        return {};
      }

      return decycle(obj);
    },
  };
}
