import { Button, Space } from '../../ds';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { isFeatureEnabled } from '../../Utils/Feature/feature_flag';
import './addStoreSelectionModal.scss';
function SelectionStep(props) {
  const { onClick, onClose, downloadTemplate } = props;
  let history = useHistory();
  const viewport = useDeviceContextViewport();
  const [isFirstView, setIsFirstView] = useState(true);

  const isMobileOnly = useMemo(
    () => viewport.isMobile && !viewport.isSmallTablet,
    [viewport]
  );

  return (
    <Space direction='vertical' className='selection-container'>
      {isFirstView ? (
        <div>
          <div
            className={cn(' k-ds-heading-04 k-ds-m-bottom-sp-05 header-title', {
              'k-ds-heading-04 ': isMobileOnly,
            })}
          >
            ¿Cuántas sucursales deseas crear?
          </div>
          <div className='button-option'>
            <Button className='button' type='outline' onClick={goToAddStore}>
              1 sucursal
            </Button>

            {isFeatureEnabled('import_stores') && (
              <Button
                className='button'
                type='outline'
                onClick={() => setIsFirstView(false)}
              >
                Varias sucursales a la vez
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div>
          <div className='k-ds-heading-04 k-ds-m-bottom-sp-09'>
            ¿Ya cuentas con la plantilla .xlsx para agregar tus sucursales?
          </div>
          <div className='button-option'>
            <Button
              className='button'
              type='outline'
              onClick={goToUploadStores}
            >
              Sí, adjuntar archivo
            </Button>
            <Button
              className='button'
              type='outline'
              onClick={downloadTemplate}
            >
              No, descargar plantilla
            </Button>
          </div>
        </div>
      )}
      <Space
        direction='vertical'
        align='center'
        className={
          isMobileOnly
            ? 'k-ds-body-01 k-ds-m-bottom-sp-05 close-container'
            : 'k-ds-body-02 k-ds-m-bottom-sp-06 close-container'
        }
      >
        <Button
          type='link'
          size='small'
          onClick={handleClose}
          className='close-button'
        >
          Cancelar
        </Button>
      </Space>
    </Space>
  );

  function goToAddStore() {
    history.push('/stores/new');
    handleClose();
  }

  function goToUploadStores() {
    onClick();
  }

  function handleClose() {
    onClose();
  }
}

SelectionStep.propTypes = {
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  downloadTemplate: PropTypes.func.isRequired,
};

export default SelectionStep;
