import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToastContext } from '../../contexts/Toast/ToastContext';
import { Button, Space } from '../../ds';
import { createBulkStore } from '../../services/Application';
import { Message } from '../DS/Message';
import Header from '../Header';
import LoaderModal from '../LoaderModal/LoaderModal';
import DetailsCard from './partials/DetailsCard';
import FormEditStore from './partials/FormEditStore';
import HeaderCard from './partials/HeaderCard';

import './styles.scss';
import AnalyticsEvent from '../../services/analytics/AnalyticsEvent';

export const CoordinatesErrors = [
  'duplicated store address',
  // 'invalid address',
  'existing store with same address',
];

const duplicateByCoordinates = addressAttributes => {
  return CoordinatesErrors.some(error =>
    addressAttributes?.coordinates?.includes(error)
  );
};

const duplicateNameInDB = storeNameError => {
  return storeNameError.some(item => item.includes('duplicated'));
};

const isDuplicated = (data, item) => {
  const { name: nameErrors = [], address_attributes } = item?.errors ?? {};

  if (
    duplicateByCoordinates(address_attributes) ||
    duplicateNameInDB(nameErrors)
  ) {
    return true;
  }

  return data?.some(store => {
    const {
      street = '',
      ext_number = '',
      inner_number = '',
      zip_code,
    } = store.address_attributes;
    return (
      (store?.unique !== item?.unique &&
        street === item?.address_attributes?.street &&
        ext_number === item?.address_attributes?.ext_number &&
        inner_number === item?.address_attributes?.inner_number &&
        zip_code === item?.address_attributes?.zip_code) ||
      (store.unique !== item.unique && store.name === item.name)
    );
  });
};

const CardComponent = ({
  item,
  isCompleted = false,
  isDuplicated = false,
  updateStores,
  onRemove,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const handleExpanded = useCallback(() => {
    setIsExpanded(!isExpanded);
    setIsEditMode(false);
  }, [setIsExpanded, isExpanded]);

  const handleActiveForm = useCallback(() => {
    setIsEditMode(true);
  }, [setIsEditMode]);

  const closeForm = useCallback(() => {
    setIsEditMode(false);
  }, [setIsEditMode]);

  const handleUpdate = store => {
    setIsEditMode(false);
    setIsExpanded(false);
    updateStores(store);
  };

  const handleRemove = () => {
    onRemove(item.unique);
  };

  return (
    <div className='box-card'>
      <HeaderCard
        name={item.name}
        isExpanded={isExpanded}
        isCompleted={isCompleted}
        isDuplicated={isDuplicated}
        onClick={handleExpanded}
        onRemove={handleRemove}
      />
      {isExpanded && !isEditMode && (
        <DetailsCard data={item} onEditForm={handleActiveForm} />
      )}
      {isEditMode && (
        <FormEditStore
          data={item}
          updateStores={handleUpdate}
          closeForm={closeForm}
        />
      )}
    </div>
  );
};

CardComponent.propTypes = {
  item: PropTypes.object,
  isCompleted: PropTypes.bool,
  isDuplicated: PropTypes.bool,
  updateStores: PropTypes.func,
  onRemove: PropTypes.func,
};

const StoreBulkConfirmation = () => {
  let history = useHistory();
  const navigate = useHistory();
  const toast = useToastContext();
  const [isCreating, setIsCreating] = useState(false);

  const [data, setData] = useState(() => {
    return history?.location?.data ?? [];
  });

  useEffect(() => {
    setData(history?.location?.data ?? []);
  }, [history?.location?.data]);

  const storeFailed = useMemo(
    () =>
      data?.filter(item => {
        return (
          (!item.is_valid && !isDuplicated(data, item)) ||
          Object.keys(item.store_schedule_attributes.schedule).length === 0
        );
      }),
    [data]
  );
  const hasStoreFailed = useMemo(() => storeFailed.length > 0, [storeFailed]);

  const storeDuplicates = useMemo(() => {
    return data?.filter(item => {
      return isDuplicated(data, item);
    });
  }, [data]);

  const storeCompleted = data?.filter(
    item =>
      item.is_valid &&
      !isDuplicated(data, item) &&
      Object.keys(item.store_schedule_attributes.schedule).length > 0
  );

  const hasStoreDuplicates = useMemo(
    () => storeDuplicates.length > 0,
    [storeDuplicates]
  );

  const updateStores = store => {
    const newState = data.map(obj => {
      if (obj.unique === store.unique) return store;

      return obj;
    });

    setData(newState);
  };

  const onRemove = storeId => {
    const newState = data.filter(obj => {
      if (obj.unique !== storeId) return obj;
    });

    setData(newState);
  };

  const onSubmit = () => {
    if (storeCompleted.length !== data.length) {
      toast('No todas las sucursales estan listas para crear', 'warning');
      return;
    }
    setIsCreating(true);

    createBulkStore({ stores: data })
      .then(() => {
        navigate.push({
          pathname: '/stores',
        });
        toast('¡Sucursales creadas con éxito!', 'success');
      })
      .catch(error => {
        AnalyticsEvent().errorTrack({
          errorType: 'store-bulk-create-error',
          errorDescription: error,
        });
        toast('¡Error al generar sucursales!', 'error');
      })
      .finally(() => setIsCreating(false));
  };

  const handleCancel = () => {
    navigate.push({ pathname: '/stores' });
  };

  return (
    <Space direction='vertical' className='import-users-file'>
      <Header
        title='Bienvenido al proceso de alta de sucursales'
        subtitle='Verifica la información de las sucursales, puedes editarla si ves un dato diferente. Da clic en “Crear sucursales” para finalizar el proceso.'
      />
      {hasStoreFailed && (
        <Message
          type='error'
          className='k-ds-m-bottom-sp-05 k-ds-width-full'
          message={
            <div className='k-ds-heading-01'>
              <div className='k-ds-m-bottom-sp-03'>
                ¡Información incompleta!
              </div>
              <div className='k-ds-m-top-sp-06'>
                Detectamos que cierta información está incompleta, por favor,
                revísala y complétala, de lo contrario no se podrá registrar las
                sucursales.
              </div>
            </div>
          }
        />
      )}
      {hasStoreDuplicates && (
        <Message
          type='warning'
          className='k-ds-m-bottom-sp-05 k-ds-width-full'
          message={
            <div className='k-ds-heading-01'>
              <div className='k-ds-m-bottom-sp-03'>¡Sucursales duplicados!</div>
              <div className='k-ds-m-top-sp-06'>
                Detectamos que varias sucursales están duplicados, por favor
                elimimina las que se repitan para poder crearlos.
              </div>
            </div>
          }
        />
      )}

      <div className='k-ds-subheading-02 k-ds-m-bottom-sp-06'>
        Sucursales nuevas a agregar: {data.length}
      </div>

      <Space className='users-box k-ds-m-bottom-sp-08' direction='vertical'>
        <div className='container-cards'>
          {storeFailed.map((item, index) => (
            <CardComponent
              key={item.merchant_id + index}
              item={item}
              updateStores={updateStores}
              onRemove={onRemove}
            />
          ))}

          {storeDuplicates.map((item, index) => (
            <CardComponent
              key={item.merchant_id + index}
              item={item}
              isCompleted
              isDuplicated
              updateStores={updateStores}
              onRemove={onRemove}
            />
          ))}

          {storeCompleted.map((item, index) => (
            <CardComponent
              key={item.merchant_id + index}
              item={item}
              isCompleted
              updateStores={updateStores}
              onRemove={onRemove}
            />
          ))}
        </div>
      </Space>
      <section className='store-create-edit-section button-section'>
        <Button
          type='primary-alternate'
          onClick={onSubmit}
          disabled={
            isCreating ||
            storeCompleted.length !== data.length ||
            data.length === 0
          }
        >
          Guardar
        </Button>
        <Button type='ghost' onClick={handleCancel}>
          Cancelar
        </Button>
      </section>
      <LoaderModal
        isOpen={isCreating}
        header='Estamos creando a tus sucursales, no actualices la ventana.'
        content='Esto puede tardar unos momentos...'
      />
    </Space>
  );
};

export default StoreBulkConfirmation;
