import { Modal } from '../../ds';
import PropTypes from 'prop-types';
import React from 'react';
import Restricted from '../../containers/Restricted';
import './UserStoresModal.scss';
import { ReactComponent as CloseIcon } from './close-icon.svg';
import { ReactComponent as RightArrow } from './right-arrow.svg';

function UserStoresModal({ defaultOpened = false, stores = [], close }) {
  const goToStoreManagement = () => {
    // @TODO replace for router when state current navitem is globally available
    window.location.href = '/stores';
  };

  return defaultOpened ? (
    <Modal
      className='user-stores-modal'
      theme='dark'
      cardType='secondary'
      onOverlayClick={() => close()}
      {...{ isOpen: defaultOpened ? true : false }}
    >
      <div className='k-ds-width-full user-stores-modal-content'>
        <CloseIcon
          className='user-stores-modal-content-close-icon'
          onClick={() => {
            close();
          }}
        />
        <div className='k-ds-heading-03 k-ds-m-top-sp-01 user-stores-modal-content-item'>
          Sucursales asignadas: {stores.length}
        </div>
        <div className='k-ds-body-02 user-stores-modal-content-item k-ds-m-top-sp-06 store-list'>
          <div className='store-list-item'></div>
          {stores.map((store, idx) => (
            <div key={idx} className='store-list-item'>
              {store.name}
            </div>
          ))}
        </div>
        <div className='k-ds-body-02 user-stores-modal-content-item k-ds-m-top-sp-07'>
          <Restricted toModule='stores' withScope='list_stores'>
            <button
              className='link-button'
              onClick={() => goToStoreManagement()}
            >
              <span>Ir a Sucursales</span>
              <RightArrow className='k-ds-m-left-sp-03' />
            </button>
          </Restricted>
        </div>
      </div>
    </Modal>
  ) : null;
}

UserStoresModal.propTypes = {
  defaultOpened: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  stores: PropTypes.array.isRequired,
};

export default UserStoresModal;
