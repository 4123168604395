import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import React from 'react';
import Form, {
  FormInput,
  FormPassword,
  FormPhone,
  useForm,
} from '../../../components/Form/Form';
import { Button } from '../../../ds';
import styles from './createaccount.module.scss';
import { ValidationSchema } from './validationSchema';

const FormAccount = ({ handleNextStep }) => {
  const methods = useForm({
    resolver: yupResolver(ValidationSchema),
    defaultValues: {
      name: '',
      lastName: '',
      secondLastName: '',
      contactEmail: '',
      phoneNumber: '',
      password: '',
      confirmPassword: '',
      accepted: false,
    },
  });
  const handleSubmit = () => {
    handleNextStep();
  };

  const isChecked = methods.watch('accepted');
  const handleChange = () => {
    methods.setValue('accepted', !isChecked);
  };

  return (
    <div className={styles.formContainer}>
      <div className={styles.boldText}>Crea una cuenta</div>
      <div className={styles.lightText}>
        Regístrate para crear tu cuenta de acceso al Portal.
      </div>
      <div className={styles.errorText}>Todos los campos son obligatorios</div>
      <Form methods={methods} onSubmit={handleSubmit} className={styles.form}>
        <FormInput
          label='Nombre (s)'
          name='name'
          placeholder='Samuel Alberto'
        />
        <div className={styles.gridCol}>
          <FormInput
            label='Primer apellido'
            name='lastName'
            placeholder='Rodríguez'
          />
          <FormInput
            label='Segundo apellido'
            name='secondLastName'
            placeholder='Madrigal'
          />
        </div>
        <FormInput
          label='Correo de contacto principal'
          name='contactEmail'
          placeholder='correo@ejemplo.com.mx'
        />
        <FormPhone
          label='Teléfono celular (10 dígitos)'
          name='phoneNumber'
          placeholder='(55) 3442 5678'
        />
        <FormPassword label='Contraseña' name='password' />
        <div className={styles.helper}>
          Tu contraseña deberá tener al menos:
          <ul>
            <li>8 caracteres como mínimo.</li>
            <li>1 caracter especial, por ejemplo: !@#$%^&*</li>
            <li>1 letra minúscula (a-z).</li>
            <li>1 letra mayúscula (A-Z).</li>
            <li>1 número (0-9).</li>
          </ul>
        </div>
        <FormPassword label='Confirmar contraseña' name='confirmPassword' />
        <div className={styles.checkTermCond}>
          <input type='checkbox' checked={isChecked} onChange={handleChange} />
          <div className={styles.text}>
            Al continuar, aceptas los{' '}
            <a className={styles['text-link']}>Términos del Servicio</a> y
            <a className={styles['text-link']}> Aviso de Privacidad</a> de
            Kueski.
          </div>
        </div>
        <Button btnType='submit' disabled={!isChecked}>
          Crear cuenta
        </Button>
      </Form>

      <div className={styles.loginLink}>
        <span>¿Ya tienes cuenta?</span>
        <a rel='noreferrer' href='/login'>
          <Button type='link' size='small' href='/login'>
            Inicia sesión
          </Button>
        </a>
      </div>
    </div>
  );
};

FormAccount.propTypes = {
  handleNextStep: PropTypes.func.isRequired,
};

export default FormAccount;
