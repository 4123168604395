import React from 'react';
import { useDeviceContextViewport } from '../../../contexts/Device/DeviceContext';
import { ReactComponent as LeftArrow } from './icon-left-arrow.svg';
import { ReactComponent as RightArrow } from './icon-right-arrow.svg';
import './paginationPortal.css';

function PaginationPortal({ currentPage, totalPages, onPageChange, maxVisibleNumbers }) {
  const viewport = useDeviceContextViewport();

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };

  const generatePagination = () => {
    const pageNumbers = [];
    const maxPagesToShow = maxVisibleNumbers || 5; // Maximum number of page numbers to show
    let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
    let endPage = startPage + maxPagesToShow - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      );
    }
    return pageNumbers;
  };

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
            <div className='paginate-arrows'>
              <LeftArrow className={currentPage === 1 ? 'disabled' : ''} />
              {viewport.isMobile ? null : 'Anterior'}
            </div>
          </button>
        </li>
        {viewport.isMobile ? null : generatePagination()}
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
            <div className='paginate-arrows'>
              {viewport.isMobile ? null : 'Siguiente'}
              <RightArrow className={currentPage === totalPages ? 'disabled' : ''} />
            </div>
          </button>
        </li>
      </ul>
    </nav>
  );
}

export {
  PaginationPortal
};
