import React, { useState } from 'react';
import { Button, Space } from '../../ds';
import { ReactComponent as Information } from '../../common/assets/icon-information.svg';
import PropTypes from 'prop-types';
import PaymentLinkShareModal from './PaymentLinkShareModal/PaymentLinkShareModal';


const PaymentLinkShare = ({ paymentUrl }) => {
  const [shareModalOpen, setShareModalOpen] = useState(false);

  return (
    <>
      <Space
        className='share-link-box k-ds-width-full'
        justify='center'
        align='center'
      >
        <div className='share-icon k-ds-m-right-sp-03'>
          <Information />
        </div>
        <div className='share-link'>
          <div className='share-link-text'>
            ¿El cliente no puede escanear el código?
            <Button
              className='share-link-btn'
              type='link'
              size='small'
              horizontalPadding='4px'
              verticalPadding='0px'
              onClick={() => setShareModalOpen(true)}
            >
              Ver solución
            </Button>
          </div>
        </div>
      </Space>
      <PaymentLinkShareModal isOpen={ shareModalOpen } paymentUrl={paymentUrl} onClose={() => setShareModalOpen(false)}/>
    </>
  );
}

PaymentLinkShare.propTypes = {
  paymentUrl: PropTypes.string,
};


export default PaymentLinkShare;
