import { Button, Card, Space, TextCopy } from '../../ds';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { useLoaderContext } from '../../contexts/Loader/LoaderContext';
import { getUser } from '../../mockData';
import { getOrderGenerated } from '../../services/Application';
import { hyphenEveryFourChars } from '../../Utils/formats';
import { goPage } from '../../Utils/pages';
import { Message } from '../DS/Message';
import RenderBarcode from '../RenderBarcode';
import './paymentGenerated.scss';
import AnalyticsEvent from '../../services/analytics/AnalyticsEvent';

function PaymentGenerated() {
  const user = getUser();
  const history = useHistory();

  const [isLoading, setIsLoading] = React.useState(true);
  const { setIsLoaderOpen } = useLoaderContext();

  const [orderGeneratedService, setOrderGeneratedService] = React.useState({
    order_reference: '',
    total: '',
    items_count: 0,
    paymentId: '',
    paymentUrl: '',
    paymentQR: '',
    totalFormatted: '',
  });

  const { orderId, storeId } = useParams();
  const selectedStoreId = storeId ? storeId : user.storeId();

  React.useEffect(() => {
    setIsLoaderOpen(true);
    getOrderGenerated(orderId, selectedStoreId)
      .then(response => {
        const { order } = response.data;
        setOrderGeneratedService({
          order_reference: order.order_reference,
          total: order.total,
          totalFormatted: order.total_formatted,
          items_count: order.items_count,
          paymentId: order.payment_attributes.payment,
          paymentUrl: order.payment_attributes.payment_url,
          paymentQR: order.payment_attributes.payment_url_qr,
        });
        setIsLoading(false);
        setIsLoaderOpen(false);
      })
      .catch(
        error => {
          AnalyticsEvent().errorTrack({
            errorType: 'payment-generate-generate-order-error',
            errorDescription: error,
          });
          setIsLoaderOpen(false);
          console.log(error);
        } //TODO
      );
  }, []);

  const viewport = useDeviceContextViewport();
  const { isMobile } = viewport;

  const maxChars = isMobile ? Math.ceil(viewport.width / 25) : 0;

  const informationTabletDesktop = (
    <>
      <Space className='k-ds-width-full' direction='horizontal'>
        <Space className='k-ds-width-full flex-shrink-1' justify='end'>
          <span className='k-ds-heading-02'>ID de pago:</span>
        </Space>
        <Space className='k-ds-width-full flex-shrink-1' justify='start'>
          <span className='k-ds-heading-02 k-ds-text-color-interactive-primary'>
            {hyphenEveryFourChars(orderGeneratedService.paymentId)}
          </span>
        </Space>
      </Space>
      <Space
        className='k-ds-width-full k-ds-m-top-sp-02'
        direction='horizontal'
      >
        <Space className='k-ds-width-full flex-shrink-1' justify='end'>
          <span className='k-ds-heading-02'>Referencia de compra:</span>
        </Space>
        <Space className='k-ds-width-full flex-shrink-1' justify='start'>
          <span className='k-ds-heading-02 word-break-all'>
            {orderGeneratedService.order_reference}
          </span>
        </Space>
      </Space>
      <Space
        className='k-ds-width-full k-ds-m-top-sp-02'
        direction='horizontal'
      >
        <Space className='k-ds-width-full flex-shrink-1' justify='end'>
          <span className='k-ds-heading-02'>Total a pagar:</span>
        </Space>
        <Space className='k-ds-width-full flex-shrink-1' justify='start'>
          <span className='k-ds-heading-02'>
            ${orderGeneratedService.totalFormatted}
          </span>
        </Space>
      </Space>
      <Space
        className='k-ds-width-full k-ds-m-top-sp-02'
        direction='horizontal'
      >
        <Space className='k-ds-width-full flex-shrink-1' justify='end'>
          <span className='k-ds-heading-02'>Artículos:</span>
        </Space>
        <Space className='k-ds-width-full flex-shrink-1' justify='start'>
          <span className='k-ds-heading-02'>
            {orderGeneratedService.items_count}
          </span>
        </Space>
      </Space>
    </>
  );

  const informationMobile = (
    <>
      <div>
        <span className='k-ds-heading-01'>{'ID de pago: '}</span>
        <span className='k-ds-heading-01 k-ds-text-color-interactive-primary'>
          {hyphenEveryFourChars(orderGeneratedService.paymentId)}
        </span>
      </div>
      <div className='k-ds-m-top-sp-04'>
        <span className='k-ds-heading-01'>Referencia de compra: </span>
        <span className='k-ds-heading-01 word-break-all'>
          {orderGeneratedService.order_reference}
        </span>
      </div>
      <div className='k-ds-m-top-sp-04'>
        <span className='k-ds-heading-01'>{'Total a pagar: '}</span>
        <span className='k-ds-heading-01'>
          ${orderGeneratedService.totalFormatted}
        </span>
      </div>
      <div className='k-ds-m-top-sp-04'>
        <span className='k-ds-heading-01'>{'Artículos: '}</span>
        <span className='k-ds-heading-01'>
          {orderGeneratedService.items_count}
        </span>
      </div>
    </>
  );

  if (isLoading) return '';

  return (
    <div className='k-ds-width-full payment-generated'>
      <div className='header k-ds-m-bottom-sp-05'>Orden de pago generada</div>
      {!isMobile ? (
        <div className='k-ds-subheading-01 k-ds-m-bottom-sp-07'>
          Muéstrale este código QR a tu cliente para que pueda completar la
          transacción en su dispositivo.
        </div>
      ) : null}
      <Space direction='vertical'>
        <Message
          message='Esta orden de pago tiene una vigencia de 2 horas, después de este tiempo tendrás que generar una nueva referencia de pago.'
          type='information'
          className='k-ds-width-full'
        />
        <Card className='k-ds-width-full k-ds-m-top-sp-05'>
          {isMobile ? informationMobile : informationTabletDesktop}
        </Card>
        <Card className='k-ds-width-full k-ds-m-top-sp-05'>
          <Space className='k-ds-width-full' justify='center'>
            <span className='qr-card-header'>
              Código QR de referencia para pago
            </span>
          </Space>
          <Space
            className='k-ds-width-full k-ds-m-top-sp-05 k-ds-m-bottom-sp-06'
            justify='center'
          >
            <RenderBarcode barcode={orderGeneratedService.paymentQR} />
          </Space>
          <Space className='k-ds-width-full' justify='center'>
            <span className='qr-card-body max-width-416'>
              Solicítale a tu cliente que escaneé el código QR con la cámara de
              su teléfono, o bien copia y compártele el link a continuación:
            </span>
          </Space>
          <Space className='k-ds-width-full k-ds-m-top-sp-06' justify='center'>
            <TextCopy
              buttonText='Copiar'
              textToCopy={orderGeneratedService.paymentUrl}
              maxChars={maxChars}
              type='b2b'
            />
          </Space>
        </Card>
      </Space>
      <Button
        type='primary-alternate'
        className='k-ds-m-top-sp-05'
        horizontalPadding='34.4px'
        verticalPadding='16px'
        size='small'
        onClick={() => goPage(`/orders/${orderId}/${selectedStoreId}`, history)}
      >
        Ver detalles
      </Button>
    </div>
  );
}

export default PaymentGenerated;
