import React from 'react';
import { Button, Modal, Space } from '../../ds';
import PropTypes from 'prop-types';
import './idleModal.scss';
import { secondsToHms } from '../../Utils/formats';

const IdleModal = ({
  isOpen = false,
  setIsOpen = () => {},
  header,
  idleSecTimer,
  onContinue = () => {},
  onLogout = () => {},
  onClose = () => {},
  isTimeOut,
  setIsTimeOut,
}) => {
  const [initialTime, setInitialTime] = React.useState(
    idleSecTimer ? idleSecTimer : 180
  );

  const closeModal = () => {
    setIsOpen(false);
    onClose();
  };

  const logOutModal = () => {
    setIsOpen(false);
    onLogout();
  };

  const continueModal = () => {
    setIsOpen(false);
    setInitialTime(idleSecTimer ? idleSecTimer : 180);
    onContinue();
  };

  React.useEffect(() => {
    if (initialTime > 0 && isOpen) {
      const timeoutId = setTimeout(() => {
        setInitialTime(initialTime - 1);
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
    if (initialTime <= 0) setIsTimeOut(true);
  }, [initialTime, isOpen]);

  React.useEffect(() => {
    window.onbeforeunload = function (e) {
      handleEndSession(e);
    };

    return () => {
      window.onbeforeunload = null;
    };
  });

  const handleEndSession = e => {
    if (isTimeOut) {
      e.preventDefault();
      onLogout();
    }
  };

  const content = isTimeOut ? (
    <>
      <div className='k-ds-heading-05 k-ds-m-bottom-sp-07'>
        La sesión ha expirado
      </div>
      <div className='k-ds-subheading-02 k-ds-m-bottom-sp-06'>
        Por favor, inicia sesión nuevamente.
      </div>
      <Space direction='horizontal'>
        <Button horizontalPadding='3rem' onClick={logOutModal}>
          Aceptar
        </Button>
      </Space>
    </>
  ) : (
    <>
      <div className='k-ds-heading-05 k-ds-m-bottom-sp-07'>{header}</div>
      <div className='k-ds-subheading-02 k-ds-m-bottom-sp-06'>
        La sesión expirará en:
      </div>
      <div className='k-ds-heading-02 k-ds-m-bottom-sp-06 remining-time'>
        <span className='info'>{secondsToHms(initialTime)} </span>
      </div>
      <div className='k-ds-subheading-02 k-ds-m-bottom-sp-06 help'>
        Si deseas permanecer en la sesión selecciona “Continuar” o “Cerrar
        sesión” para salir de tu cuenta.
      </div>
      <Space direction='horizontal'>
        <Button
          horizontalPadding='3rem'
          className='k-ds-m-right-sp-05'
          onClick={continueModal}
        >
          Continuar
        </Button>
        <Button
          type='secondary-alternate'
          horizontalPadding='3rem'
          onClick={logOutModal}
        >
          Cerrar sesión
        </Button>
      </Space>
    </>
  );

  return (
    <>
      <Modal
        theme='dark'
        cardType='secondary'
        isOpen={isOpen}
        onClose={closeModal}
        className='idle-modal'
      >
        <Space
          justify='center'
          direction='vertical'
          align='left'
          className='k-ds-m-sp-05'
        >
          {content}
        </Space>
      </Modal>
    </>
  );
};

IdleModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func.isRequired,
  header: PropTypes.string,
  idleSecTimer: PropTypes.number,
  onContinue: PropTypes.func,
  onLogout: PropTypes.func,
  onClose: PropTypes.func,
  isTimeOut: PropTypes.bool.isRequired,
  setIsTimeOut: PropTypes.func.isRequired,
};

export default IdleModal;
