import React, { useEffect, useRef, useState } from 'react';
import Auth from '../../Utils/Auth/auth';
import Header from '../Header';
import './userProfile.scss';
// import { useHistory } from 'react-router-dom';
import { getUserInitials } from '../../Utils/formats';
import { useLoaderContext } from '../../contexts/Loader/LoaderContext';
import { Avatar, Space } from '../../ds';
import { getMerchantUser } from '../../services/Application';
// import Restricted from '../../containers/Restricted/Restricted';
import { ACCOUNT_MANAGER } from '../../Utils/constants';
import Modal from '../../containers/pages/Login/ActivationModal';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { Card } from '../../ds';
import UserStoresModal from '../UserStoresModal/UserStoresModal';
import AnalyticsEvent, {
  AnalyticsList,
} from '../../services/analytics/AnalyticsEvent';

function UserProfile() {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      {openModal && <Modal handleClose={() => setOpenModal(false)} />}
      <Header title='Perfil de usuario' />
      <UserInformation />
    </>
  );
}

function UserInformation() {
  const isTracked = useRef(false);
  const viewport = useDeviceContextViewport();
  const { setIsLoaderOpen } = useLoaderContext();
  const [user, setUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { employee, stores } = Auth.userData();

  const isRegularUser = employee?.role?.name !== ACCOUNT_MANAGER;

  const fetchLoggerUserData = () => {
    setIsLoaderOpen(true);
    getMerchantUser(employee?.employee_id.toString())
      .then(response => {
        const { data } = response;
        setUser({
          first_name: data?.user.first_name,
          last_name: data?.user.last_name,
          email: data?.user.email,
          full_name: employee?.full_name,
          role_name: employee?.role?.description,
          user_code: data?.user.user_code === '' ? 'N/A' : data?.user.user_code,
          stores: data?.user.stores.length > 0 ? data?.user.stores : stores,
        });
      })
      .catch(err => {
        AnalyticsEvent().errorTrack({
          errorType: 'user-profile-fetch-merchant-user-data-error',
          errorDescription: err,
        });

        console.log(err);
      })
      .finally(() => setIsLoaderOpen(false));
  };

  useEffect(() => {
    if (!isTracked.current) {
      AnalyticsEvent().pageView({ page: AnalyticsList.userInformation });
      isTracked.current = true;
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      fetchLoggerUserData();
    }, 200);
  }, []);

  const columnClass = viewport.isMobile ? 'one-column' : 'two-columns';

  return (
    user && (
      <>
        <UserStoresModal
          defaultOpened={isModalOpen}
          close={() => {
            setIsModalOpen(false);
          }}
          stores={user.stores}
        />
        <div className={`user-profile k-ds-body-01 ${columnClass}`}>
          <div className='k-ds-width-full'>
            <Card className='k-ds-width-full user-profile-name-card'>
              <Space direction='horizontal' align='center'>
                <div className='user-profile-name-card-image'>
                  <Avatar
                    id='user-avatar'
                    className='k-ds-m-rigth-sp-09 k-ds-text-color-interactive-secondary'
                    size='x-large'
                    type='initials'
                    initials={getUserInitials(
                      user?.first_name,
                      user?.last_name
                    )}
                  />
                </div>
                <div className='user-profile-name-card-text'>
                  <p className='salute'>Hola,</p>
                  <p className='name k-ds-heading-04'>{user?.full_name}</p>
                </div>
              </Space>
            </Card>
            <div className='user-profile-section k-ds-width-full'></div>
            <div className='user-profile-section k-ds-width-full'>
              <span className='user-profile-section-title'>Usuario:</span>
              <span className='user-profile-section-value'>{user?.email}</span>
            </div>
            <div className='user-profile-section k-ds-width-full'>
              <span className='user-profile-section-title'>Rol:</span>
              <span className='user-profile-section-value'>
                {user?.role_name}
              </span>
            </div>
            <div className='user-profile-section k-ds-width-full'>
              <span className='user-profile-section-title'>
                Código de empleado:
              </span>
              <span className='user-profile-section-value'>
                {user?.user_code}
              </span>
            </div>
            {user.stores && user.stores.length > 0 && isRegularUser ? (
              <div className='user-profile-section k-ds-width-full'>
                <span className='user-profile-section-title'>
                  Sucursales asignadas:
                </span>
                <Space direction='horizontal' justify='space-between'>
                  <span className='user-profile-section-value'>
                    {user.stores.length > 1
                      ? user.stores.length
                      : user.stores[0].name}
                  </span>
                  {user.stores.length > 1 && (
                    <button
                      className='link-button'
                      onClick={() => {
                        setIsModalOpen(true);
                      }}
                    >
                      Ver Sucursales
                    </button>
                  )}
                </Space>
              </div>
            ) : null}
          </div>
        </div>
      </>
    )
  );
}

export default UserProfile;
