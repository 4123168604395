import styled from 'styled-components';
import React from 'react';
import { ReactComponent as IconWarning } from './icon-warning.svg';
import { ReactComponent as IconClose } from './icon-close.svg';
import KDS from '../../lib/k-ds-foundation';
import { ToastBase } from './ToastBase';

const StyledToastWarning = styled(ToastBase)`
  ${KDS.setColorTokenWithFallback('border: 2px solid ', 'warning')};
`;

const ToastWarning = ({
  message,
  onClick,
  ...moreProps
}) => (
  <StyledToastWarning {...moreProps}>
    <IconWarning className="toast-icon" />
    <p className="k-ds-heading-01 toast-message">{message}</p>
    <button type="button" className="toast-close" onClick={onClick}>
      <IconClose />
    </button>
  </StyledToastWarning>
);

export {
  ToastWarning,
};
