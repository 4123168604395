import React from 'react';
import { createColumnHelper } from './../../../Utils/ReactTable';
import { Button } from '../../../ds';
import { currencyFormatter, toPercentage } from './../../../Utils/formats';
import Timer from './../../../Utils/Timer';
import { Tag } from '../../../ds';

const columnHelper = createColumnHelper();

export const ReportByMonthColumns = ({ onToogleDetails = () => null }) => [
  columnHelper.accessor('cheduledDate', {
    header: 'Periodo de pago',
    size: 200,
  }),
  columnHelper.accessor('depositDate', {
    size: 180,
    header: 'Fecha de pago',
  }),
  columnHelper.accessor('capital', {
    size: 180,
    header: 'Bruto a capital',
  }),
  columnHelper.accessor('commission', {
    size: 120,
    header: 'Comisiones',
  }),
  columnHelper.accessor('subtotal', {
    size: 120,
    header: 'Subtotal',
  }),
  columnHelper.accessor('cancel', {
    size: 120,
    header: 'Cancelaciones',
  }),
  columnHelper.accessor('total', {
    size: 120,
    header: 'Total neto',
  }),
  columnHelper.accessor('currency', {
    size: 120,
    header: 'Moneda',
  }),
  columnHelper.accessor('expander', {
    header: 'Detalle',
    size: 120,
    cell: ({ row }) => {
      return (
        <Button
          style={{ padding: '0' }}
          type='link'
          onClick={() => {
            const expand = row.getToggleExpandedHandler();
            expand && expand();
            onToogleDetails(row.original);
          }}
        >
          {`${row.getIsExpanded() ? 'Cerrar detalle' : 'Ver detalle'}`}
        </Button>
      );
    },
  }),
];

export const ReportByPeriodColumns = [
  columnHelper.accessor('merchant_name', {
    size: 100,
    header: 'Comercio',
  }),
  columnHelper.accessor('order_id', {
    header: 'ID Orden',
    size: 80,
    cell: props => {
      const value = props.getValue();
      return (
        <div style={{ width: '80px', overflowWrap: 'break-word' }}>{value}</div>
      );
    },
  }),
  columnHelper.accessor('payment_id', {
    header: 'ID Pago',
    size: 80,
    cell: props => {
      return (
        <div style={{ width: '80px', overflowWrap: 'break-word' }}>
          {props.getValue() ? props.getValue().toString() : ''}
        </div>
      );
    },
  }),
  columnHelper.accessor('payment_period', {
    size: 300,
    header: 'Periodo',
  }),
  columnHelper.accessor('deposit_date', {
    size: 126,
    header: 'Fecha de depósito',
    cell: props => (props.getValue() ? Timer().toUTC(props.getValue()) : ''),
  }),
  columnHelper.accessor('loan_id', {
    size: 100,
    header: 'ID Préstamo',
    cell: props => {
      const value = props.getValue();
      return (
        <div style={{ width: '100px', overflowWrap: 'break-word' }}>
          {value}
        </div>
      );
    },
  }),
  columnHelper.accessor('transaction_type', {
    size: 120,
    header: 'Tipo de Transacción',
    cell: props => {
      const value = props.getValue();
      if (value === 'payment') {
        return (
          <Tag type='default' color='blue'>
            Pago
          </Tag>
        );
      }

      if (value === 'refund') {
        return (
          <Tag type='default' color='red'>
            Cancelación total
          </Tag>
        );
      }

      return (
        <Tag type='default' color='cyan'>
          {value}
        </Tag>
      );
    },
  }),
  columnHelper.accessor('total', {
    header: 'Monto de la transacción',
    size: 100,
    cell: props =>
      props.getValue() ? currencyFormatter(props.getValue()) : '$0',
  }),
  columnHelper.accessor('currency', {
    size: 100,
    header: 'Moneda',
  }),
  columnHelper.accessor('transaction_fee', {
    header: () => <div>Cuota por Transacción</div>,
    size: 100,
    cell: props => (props.getValue() ? toPercentage(props.getValue()) : '0%'),
  }),
  columnHelper.accessor('fee', {
    size: 100,
    header: 'Total MDR',
    cell: props =>
      props.getValue() ? currencyFormatter(props.getValue()) : '$0',
  }),
  columnHelper.accessor('tax', {
    size: 100,
    header: 'IVA MDR',
    cell: props =>
      props.getValue() ? currencyFormatter(props.getValue()) : '$0',
  }),
  columnHelper.accessor('surcharge', {
    size: 100,
    header: 'Cuota de la sobretasa',
    cell: props => (props.getValue() ? toPercentage(props.getValue()) : '0%'),
  }),
  columnHelper.accessor('surcharge_amount', {
    header: 'Total de la sobretasa',
    size: 100,
    cell: props =>
      props.getValue() ? currencyFormatter(props.getValue()) : '$0',
  }),
  columnHelper.accessor('surcharge_tax', {
    header: 'Iva Sobretasa',
    size: 100,
    cell: props =>
      props.getValue() ? currencyFormatter(props.getValue()) : '$0',
  }),
  columnHelper.accessor('subtotal', {
    header: 'Total',
    size: 100,
    cell: props =>
      props.getValue() ? currencyFormatter(props.getValue()) : '',
  }),
  columnHelper.accessor('channel', {
    size: 100,
    header: 'Canal',
    cell: props => {
      const value = props.getValue() || '';
      if (['online', 'ecommerce'].includes(value)) {
        return 'Tienda en línea';
      }

      return 'Tienda fisica';
    },
  }),
  columnHelper.accessor('store', {
    size: 100,
    header: 'Tienda',
  }),
  columnHelper.accessor('user_id', {
    size: 100,
    header: 'ID de Usuario',
    cell: props => {
      const value = props.getValue();
      return (
        <div style={{ width: '100px', overflowWrap: 'break-word' }}>
          {value}
        </div>
      );
    },
  }),
  columnHelper.accessor('user_code', {
    size: 100,
    header: 'Código de Vendedor',
    cell: props => {
      const value = props.getValue();
      return (
        <div style={{ width: '100px', overflowWrap: 'break-word' }}>
          {value}
        </div>
      );
    },
  }),
  columnHelper.accessor('reference', {
    size: 100,
    header: 'Referencia de Compra',
    cell: props => {
      const value = props.getValue();
      return (
        <div style={{ width: '100px', overflowWrap: 'break-word' }}>
          {value}
        </div>
      );
    },
  }),
  columnHelper.accessor('processed_date', {
    size: 126,
    header: 'Fecha de pago',
    cell: props => (props.getValue() ? Timer().toUTC(props.getValue()) : ''),
  }),
  columnHelper.accessor('fee_total', {
    header: 'MDR + IVA',
    size: 100,
    cell: (props) => props.getValue() ? currencyFormatter(props.getValue()) : ''
  }),
];
