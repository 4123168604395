import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '../../../../ds';
import styles from './SentFormView.module.scss';
import { ReactComponent as RocketIcon } from './res/rocket.svg';

const SentFormView = ({ handleButton }) => {
  return (
    <div className={styles.sentFormView}>
      <RocketIcon className={styles.icon} />
      <div className={styles.container}>
        <div className={styles.hint}>
          Revisaremos tu registro para garantizar que cumpla con todos los
          requisitos y condiciones necesarios para ser un comercio afiliado.
        </div>
        <div className={styles.bold}>
          Puedes consultar el estatus de tu proceso de afiliación desde aquí.
        </div>
      </div>

      <Button className={styles.fullBtn} size='large' onClick={handleButton}>
        Aceptar
      </Button>
    </div>
  );
};

export default SentFormView;

SentFormView.propTypes = {
  handleButton: PropTypes.func.isRequired,
};
