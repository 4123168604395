import cn from 'classnames';
import React from 'react';
import Carousel from '../../../components/Carousel';
import { Button, Space } from '../../../ds';
import Banner1 from './banners/banner1';
import Banner2 from './banners/banner2';
import Banner3 from './banners/banner3';
import { ReactComponent as AccountIcon } from './res/account.svg';
import { ReactComponent as BackArrow } from './res/back-arrow.svg';
import { ReactComponent as FormIcon } from './res/form.svg';
import { ReactComponent as Website } from './res/website.svg';
import styles from './styles.module.scss';

const Banners = [<Banner1 key={1} />, <Banner2 key={2} />, <Banner3 key={3} />];

const LINK_FORM =
  'https://www.kueskipay.com/para-comercios/como-funciona?utm_source=ngkpbb&utm_medium=o_web&utm_campaign=form-b2b_12may2023#registra-a-tu-comercio';

const Membership = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <a className={styles.backbtn} rel='noreferrer' href='/login'>
          <Button type='simple' size='small'>
            <BackArrow className={styles.customArrow} />
            <span>Regresar</span>
          </Button>
        </a>

        <div className={styles.btnLogin}>
          <span className={styles.helperMsg}>¿Ya tienes cuenta?</span>
          <a rel='noreferrer' href='/login'>
            <Button size='small' href='/login'>
              Inicia sesión
            </Button>
          </a>
        </div>
      </div>

      <div className={styles.area}>
        <div className={styles.content}>
          <div className={cn(styles.boldT, styles.padding8B)}>
            Afiliarse a Kueski Pay es muy fácil
          </div>
          <div className={styles.regularT}>
            Conoce algunos de los beneficios que tenemos para ti
          </div>
        </div>
        <div className={styles.dividerMob}>
          <Carousel banners={Banners} />
        </div>

        <div className={styles.boldT} style={{ padding: '40px 24px 0' }}>
          Conoce el proceso para comenzar la afiliación
        </div>
        <div className={styles.cardContainer}>
          <div className={styles.card}>
            <Website />
            <div className={styles.bold}>Tener sitio web</div>
            <div className={styles.message}>
              Es necesario tener un sitio web y que ésta tenga mínimo 3 meses
              activa.
            </div>
          </div>
          <div className={styles.card}>
            <AccountIcon />
            <div className={styles.bold}>
              Llena el{' '}
              <a className={styles.link} href={LINK_FORM}>
                formulario
              </a>
            </div>
            <div className={styles.message}>
              Proporciona los datos de tu comercio.
            </div>
          </div>

          <div className={styles.card}>
            <FormIcon />
            <div className={styles.bold}>Adjuntar la documentación</div>
            <div className={styles.message}>
              Adjunta los documentos que aquí se solicitan.
            </div>
          </div>
        </div>
        <div className={styles.banner}>
          <div className={styles.tandc}>
            Al término del registro, evaluaremos que tu perfil cumpla con todos
            los{' '}
            <a
              target='_blank'
              rel='noreferrer'
              href='/AY-VEN-04InformaciónmerchantsRev01.pdf'
            >
              requisitos y condiciones
            </a>{' '}
            para ser un comercio afiliado.
          </div>
        </div>
        <Space
          className={cn('k-ds-width-full', styles.nextBtn)}
          direction='horizontal'
        >
          <a href={LINK_FORM}>
            <Button size='large'>Comenzar</Button>
          </a>
        </Space>
      </div>
    </div>
  );
};

export default Membership;
