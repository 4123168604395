import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import DropZone from '../../../../components/DropZone/DropZone';
import { Button, Select } from '../../../../ds';
import styles from './ActivationForm.module.scss';
import AccountImg from './res/account.png';
import IdentificationImg from './res/identificacion.png';

const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png'];

const UploadFileZone = ({
  hint,
  dropZoneName,
  handleFilesChange,
  imagePath,
  showHelper = false,
}) => {
  const dropZoneRef = useRef(null);

  const [errorMessage, setErrorMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);
  const [file, setFile] = useState(null);

  const openFileInput = () => {
    dropZoneRef.current.openFileInput();
  };

  const onChangeFile = files => {
    const file = files[0];
    console.log(allowedTypes.includes(file.type));
    if (allowedTypes.includes(file.type)) {
      setErrorMessage('');
      setFile(file);
      handleFilesChange && handleFilesChange(file);
    } else {
      setErrorMessage('Please select a valid PDF, JPG, or PNG file.');
    }
  };

  const handleRemove = () => {
    setFile(null);
  };
  return (
    <div className={styles.fileContainer}>
      {isModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            alignItems: 'center',
            zIndex: 2,
          }}
        >
          <div className={styles.helpModal}>
            <svg
              width='54'
              style={{ cursor: 'pointer' }}
              height='54'
              viewBox='0 0 54 54'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              onClick={() => setIsModalOpen(false)}
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M11.1054 41.4807C10.7149 41.8712 10.7149 42.5044 11.1054 42.8949C11.4959 43.2854 12.1291 43.2854 12.5196 42.8949L27.0001 28.4144L41.4807 42.895C41.8712 43.2855 42.5044 43.2855 42.8949 42.895C43.2854 42.5045 43.2854 41.8713 42.8949 41.4808L28.4143 27.0002L42.8949 12.5196C43.2854 12.1291 43.2854 11.4959 42.8949 11.1054C42.5044 10.7149 41.8712 10.7149 41.4807 11.1054L27.0001 25.586L12.5196 11.1055C12.1291 10.715 11.4959 10.715 11.1054 11.1055C10.7149 11.496 10.7149 12.1292 11.1054 12.5197L25.5859 27.0002L11.1054 41.4807Z'
                fill='#282C38'
              />
            </svg>

            <img src={imagePath} className={styles.imageModal} />
          </div>
        </div>
      )}

      <div className={styles.fileTitle}>
        <span>{dropZoneName}</span>
        {showHelper && (
          <span>
            <svg
              width='24'
              style={{ cursor: 'pointer' }}
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              onClick={() => setIsModalOpen(true)}
            >
              <path
                d='M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM11 15H13V17H11V15ZM13 13.355V14H11V12.5C11 12.2348 11.1054 11.9804 11.2929 11.7929C11.4804 11.6054 11.7348 11.5 12 11.5C12.2841 11.5 12.5623 11.4193 12.8023 11.2673C13.0423 11.1154 13.2343 10.8984 13.3558 10.6416C13.4773 10.3848 13.5234 10.0988 13.4887 9.81684C13.454 9.53489 13.34 9.26858 13.1598 9.04891C12.9797 8.82924 12.7409 8.66523 12.4712 8.57597C12.2015 8.48671 11.912 8.47587 11.6364 8.54471C11.3608 8.61354 11.1104 8.75923 10.9144 8.96482C10.7183 9.1704 10.5847 9.42743 10.529 9.706L8.567 9.313C8.68863 8.70508 8.96951 8.14037 9.38092 7.67659C9.79233 7.2128 10.3195 6.86658 10.9086 6.67332C11.4977 6.48006 12.1275 6.44669 12.7337 6.57661C13.3399 6.70654 13.9007 6.99511 14.3588 7.41282C14.8169 7.83054 15.1559 8.36241 15.3411 8.95406C15.5263 9.54572 15.5511 10.1759 15.4129 10.7803C15.2747 11.3847 14.9785 11.9415 14.5545 12.3939C14.1306 12.8462 13.5941 13.1779 13 13.355Z'
                fill='#282C38'
              />
            </svg>
          </span>
        )}
      </div>
      <div
        className={cn({
          [styles.disabled]: file?.name,
        })}
      >
        <DropZone
          ref={dropZoneRef}
          onDragActiveChange={setIsDragActive}
          onFilesChange={onChangeFile}
        >
          <div
            className={cn(styles['drop-zone'], {
              [styles['drop-zone-active']]: isDragActive,
              ['border-error']: errorMessage !== '',
            })}
          >
            <div className={styles.hint}>
              Arrastra tu archivo PDF, JPG, PNG o{' '}
              <span className={styles.btn} onClick={openFileInput}>
                búscalo
              </span>
            </div>
            {errorMessage ? (
              <div className={styles.errorMessage}>{errorMessage}</div>
            ) : (
              <div className={styles.message}>Peso máximo 15 MB</div>
            )}
          </div>
        </DropZone>
      </div>

      <div className={styles.hint}>{hint}</div>

      {file?.name && (
        <div className={styles.card}>
          <div className={styles.iconWithName}>
            <svg
              width='21'
              height='20'
              viewBox='0 0 21 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M12.7773 3.33329H4.44401V16.6666H16.1107V6.66663H12.7773V3.33329ZM2.77734 2.49329C2.77734 2.03663 3.14984 1.66663 3.60984 1.66663H13.6107L17.7773 5.83329V17.4941C17.7781 17.6036 17.7573 17.7121 17.7161 17.8135C17.675 17.9149 17.6142 18.0072 17.5374 18.0851C17.4606 18.163 17.3691 18.225 17.2683 18.2676C17.1675 18.3102 17.0593 18.3325 16.9498 18.3333H3.60484C3.38599 18.3318 3.17652 18.2442 3.02169 18.0895C2.86686 17.9349 2.77909 17.7255 2.77734 17.5066V2.49329ZM11.1107 9.99996V13.3333H9.44401V9.99996H6.94401L10.2773 6.66663L13.6107 9.99996H11.1107Z'
                fill='#18223D'
              />
            </svg>
            {file?.name}
          </div>
          <button onClick={handleRemove}>
            <svg
              width='17'
              height='16'
              viewBox='0 0 17 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M4.91146 2.66671V1.33337H11.5781V2.66671H14.9115V4.00004H13.5781V14C13.5781 14.1769 13.5079 14.3464 13.3829 14.4714C13.2578 14.5965 13.0883 14.6667 12.9115 14.6667H3.57812C3.40131 14.6667 3.23174 14.5965 3.10672 14.4714C2.9817 14.3464 2.91146 14.1769 2.91146 14V4.00004H1.57812V2.66671H4.91146ZM4.24479 4.00004V13.3334H12.2448V4.00004H4.24479ZM6.24479 6.00004H7.57812V11.3334H6.24479V6.00004ZM8.91146 6.00004H10.2448V11.3334H8.91146V6.00004Z'
                fill='#0075FF'
              />
            </svg>
            Eliminar
          </button>
        </div>
      )}
    </div>
  );
};

const FilesDataForm = ({ handleSuccess }) => {
  const [typeFile, setTypeFile] = useState('ine');
  const [files, setFiles] = useState(() => {
    return [
      {
        identificationFrontFile: null,
        isRequired: true,
        filled: false,
      },
      {
        identificationBackFile: null,
        isRequired: false,
        filled: false,
      },
      // {
      //   csfFile: null,
      //   isRequired: true,
      //   filled: false,
      // },
      // {
      //   constitutiveFile: null,
      //   isRequired: isLegalPerson,
      //   filled: false,
      // },
      // {
      //   SASFile: null,
      //   isRequired: isSas,
      //   filled: false,
      // }
    ];
  });

  useEffect(() => {
    let copyFiles = files;
    copyFiles[1].isRequired = typeFile === 'ine';

    setFiles(copyFiles);
  }, [typeFile]);

  const handleChangeFile = (file, name) => {
    setFiles(prev =>
      prev.map(prevFile => {
        if (Object.keys(prevFile)[0] === name) {
          return {
            ...prevFile,
            [name]: file,
            filled: true,
          };
        }
        return prevFile;
      })
    );
  };

  const requiredFilesCount = files?.reduce((count, file) => {
    if (file.isRequired) {
      return count + 1;
    }
    return count;
  }, 0);

  const filledFilesCount = files?.reduce((count, file) => {
    if (file.filled) {
      return count + 1;
    }
    return count;
  }, 0);

  console.log(requiredFilesCount, filledFilesCount);
  const handleChange = value => {
    setTypeFile(value);
  };

  const isRequiredFile = useMemo(() => typeFile === 'ine', [typeFile]);

  const sendDocuments = () => {
    handleSuccess(true);
  };
  return (
    <div>
      <div className={styles.nameForm}>Verificación de identidad</div>
      <div className={styles.instructions}>
        Por favor, ayúdanos a verificar tu identidad
      </div>
      <div className={styles.legalBankDetails}>
        <div className={styles.helper}>Todos los campos son obligatorios</div>

        <Select
          label='Selecciona la identificación oficial a verificar.'
          className={styles.simpleSelect}
          onChange={handleChange}
          value={typeFile}
          id='store-to-add'
          name='store-to-add'
          options={[
            { value: 'ine', text: 'Credencial para votar (INE)' },
            { value: 'license', text: 'Licencia de manejo ' },
            { value: 'pasaporte', text: 'Pasaporte' },
          ]}
          type='text'
        />
        <UploadFileZone
          dropZoneName='Adjunta el frente de la identificación oficial.'
          hint='La identificación debe estar vigente'
          showHelper={typeFile === 'ine'}
          imagePath={IdentificationImg}
          handleFilesChange={file =>
            handleChangeFile(file, 'identificationFrontFile')
          }
        />
        {isRequiredFile && (
          <UploadFileZone
            dropZoneName='Adjunta el reverso de la identificación oficial.'
            hint='La identificación debe estar vigente'
            showHelper={typeFile === 'ine'}
            imagePath={AccountImg}
            handleFilesChange={file =>
              handleChangeFile(file, 'identificationBackFile')
            }
          />
        )}

        {/* <UploadFileZone
          dropZoneName='Constancia de situación fiscal del dueño del comercio'
          hint='Actualizada en los últimos 6 meses'
          imagePath={ CSFImg }
          handleFilesChange={ (file) => handleChangeFile(file, 'csfFile') }
        /> */}
        {/* {isLegalPerson && (
          <UploadFileZone
            dropZoneName='Acta constitutiva'
            hint='Actualizada en los últimos 6 meses'
            imagePath={ constitutiveImg }
            handleFilesChange={ (file) => handleChangeFile(file, 'constitutiveFile') }
          />
        )} */}

        {/* {isSas && (
          <UploadFileZone
            dropZoneName='Contrato Social de SAS'
            imagePath={ SASImg }
            handleFilesChange={ (file) => handleChangeFile(file, 'SASFile') }

          />
        )} */}

        <div className={styles.actionFinal}>
          <Button type='ghost'>Guardar y salir</Button>
          <Button
            onClick={sendDocuments}
            disabled={requiredFilesCount !== filledFilesCount}
          >
            Enviar y terminar
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FilesDataForm;

UploadFileZone.propTypes = {
  hint: PropTypes.string,
  dropZoneName: PropTypes.string.isRequired,
  handleFilesChange: PropTypes.func,
  imagePath: PropTypes.string,
  showHelper: PropTypes.bool,
};

FilesDataForm.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  // isSas: PropTypes.bool.isRequired
};
