import React from 'react';
import { TextCopyB2B } from './TextCopyB2B';

const MAP_TEXTCOPY_TYPE = {
  'b2b': TextCopyB2B,
};

const TextCopy = ({ type, ...moreProps }) => {
  const defaultType = 'b2b';
  const Component = MAP_TEXTCOPY_TYPE[type] || MAP_TEXTCOPY_TYPE[defaultType];

  return (
    <Component {...moreProps} />
  );
};

export {
  TextCopy,
};
