import PropTypes from 'prop-types';
import React, { forwardRef, useImperativeHandle } from 'react';
import './dropZone.scss';

const DropZone = forwardRef(
  ({ onFilesChange, onDragActiveChange, children, disabled = false }, ref) => {
    const fileInputRef = React.useRef(null);

    useImperativeHandle(ref, () => ({
      openFileInput: () => {
        fileInputRef.current.click();
      },
    }));

    const handleDrag = event => {
      event.preventDefault();
      event.stopPropagation();
      if (event.type === 'dragenter' || event.type === 'dragover') {
        onDragActiveChange(true);
      } else if (event.type === 'dragleave') {
        onDragActiveChange(false);
      }
    };

    const handleDrop = function (e) {
      e.preventDefault();
      e.stopPropagation();
      onDragActiveChange(false);
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        onFilesChange(e.dataTransfer.files);
      }
    };

    const handleChange = function (e) {
      e.preventDefault();
      if (e.target.files && e.target.files[0]) {
        onFilesChange(e.target.files);
      }
    };

    return (
      <div
        onDragEnter={handleDrag}
        className='drop-zone'
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <form onDragEnter={handleDrag} onSubmit={e => e.preventDefault()}>
          <input
            ref={fileInputRef}
            type='file'
            className='hidden-input'
            multiple={true}
            onChange={handleChange}
            disabled={disabled}
          />
          {children}
        </form>
      </div>
    );
  }
);

DropZone.propTypes = {
  onFilesChange: PropTypes.func,
  children: PropTypes.node,
  onDragActiveChange: PropTypes.func,
  disabled: PropTypes.bool,
};

DropZone.displayName = 'DropZone';

export default DropZone;
