import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Footer from '../../../components/Footer';
import { ReactComponent as KueskiPayLogo } from '../../../components/Navbar/kueski-pay-logo.svg';
import { useDeviceContext } from '../../../contexts/Device/DeviceContext';
import { Button } from '../../../ds';

import { isFeatureEnabled } from '../../../Utils/Feature/feature_flag';

import { OTP_REQUIRED, unauthorizedOtpBlocked } from '../../../Utils/constants';
import { generateOrders } from '../../routes/ScopeRoutes';
import { hasChannelAccess, hasScopeAccess } from '../../routes/index';
import { ReactComponent as BackGraphic } from './BackGraphic.svg';
import FormLogin from './LoginForm';
import BlockedAccountMessage from './OTP/BlockedAccountMessage';
import OTPVerification from './OTP/OTPVerification';
import { ReactComponent as LoginGraphic } from './login-graphic.svg';
import './login.scss';

const isCaptchaEnabled = isFeatureEnabled('captcha');
const isHomePageEnabled = isFeatureEnabled('home_page');

const create_order_scopes = [
  'create_by_amount',
  'create_by_amount',
  'create_order_merchant',
];

const loginDataDefaultState = {
  email: '',
  password: '',
  g_recaptcha_response: isCaptchaEnabled ? '' : '-',
};

const Login = () => {
  const history = useHistory();
  const { viewport } = useDeviceContext();
  const [showMobileForm, setShowMobileForm] = useState(false);
  const [isOTPRequired, setIsOTPRequired] = useState(false);
  const [optValues, setOtpValues] = useState({});

  const [isBlocked, setIsBlocked] = useState(false);

  const [loginData, setLoginData] = useState(loginDataDefaultState);

  const handleMobileLogin = () => setShowMobileForm(!showMobileForm);

  const handleLoginErrors = response => {
    if (response.error.message === OTP_REQUIRED) {
      setOtpValues(response.error);
      setIsOTPRequired(true);
      return true;
    } else if (response.data.code === unauthorizedOtpBlocked) {
      setIsBlocked(true);
      return true;
    }
    return false;
  };

  const resetLoginData = () => {
    setLoginData(loginDataDefaultState);
    setIsOTPRequired(false);
    setIsBlocked(false);
  };

  const redirectAfterLogin = response => {
    if (isHomePageEnabled) {
      history.push('/home');
      return;
    }

    const orders_permission_scopes =
      response?.permission_scopes?.find(
        item => item.module_name === generateOrders.module
      )?.scopes || [];
    const merchantChannels = response?.merchant?.channels || [];
    const path =
      hasChannelAccess(generateOrders.routes[0].channels, merchantChannels) &&
      hasScopeAccess(create_order_scopes, orders_permission_scopes)
        ? '/orders/create-by-code'
        : '/me';

    history.push(path);
  };

  const renderForm = () => {
    if (isBlocked)
      return (
        <BlockedAccountMessage
          onAccept={resetLoginData}
          message='Tu cuenta sigue congelada. Inténtalo más tarde.'
        />
      );
    if (isOTPRequired)
      return (
        <OTPVerification
          otp={optValues}
          handleRedirect={redirectAfterLogin}
          loginData={loginData}
          onCancel={resetLoginData}
        />
      );
    return (
      <FormLogin
        handleRedirect={redirectAfterLogin}
        handleErrors={handleLoginErrors}
        loginData={loginData}
        setLoginData={setLoginData}
      />
    );
  };

  return (
    <>
      <div className='login-views'>
        {(!viewport.isMobile || !showMobileForm) && (
          <div className='welcome'>
            {viewport.isMobile && (
              <div className='welcome-logo'>
                <KueskiPayLogo />
              </div>
            )}
            <div className='welcome-text k-ds-m-vertical-sp-06 k-ds-m-horizontal-sp-07'>
              {!viewport.isMobile && <KueskiPayLogo />}
              <p>
                ¡Incrementa tus ventas hasta en un 40% y haz que nuevos clientes
                conozcan tu marca con Kueski Pay!{' '}
                <a
                  // target='_blank'
                  rel='noreferrer'
                  className='link'
                  href='/benefits'
                  // href='https://www.kueskipay.com/para-comercios/como-funciona'
                >
                  Conoce más.
                </a>
              </p>
            </div>
            <div className='login-graphic-wrapper'>
              <LoginGraphic className='login-graphic k-ds-width-full' />
            </div>
            {viewport.isMobile && (
              <div className='mobile-welcome-button'>
                <Button
                  className='k-ds-m-top-sp-05'
                  borderWidth=''
                  horizontalPadding=''
                  size='small'
                  type='primary'
                  verticalPadding='16px'
                  onClick={handleMobileLogin}
                >
                  Iniciar Sesión
                </Button>
              </div>
            )}
          </div>
        )}
        {(!viewport.isMobile || showMobileForm) && (
          <div className='login'>
            {viewport.isMobile && (
              <div className='back-button-wrapper'>
                <Button type='simple' onClick={handleMobileLogin}>
                  <div>
                    <BackGraphic className='k-ds-width-full' />
                  </div>
                </Button>
              </div>
            )}
            {renderForm()}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Login;
