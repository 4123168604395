import styled, { css } from 'styled-components';
import React from 'react';
import { Space } from '../space/index';
import KDS from '../../lib/k-ds-foundation';

const StyledRadioB2CDiv = styled.div`

  input[type=radio] {
    position: absolute;
    opacity: 0;
    &:not(:checked) + label {
       font-weight: 500;
    }

    &:checked + label,
    &:focus + label {
      ${KDS.setColorTokenWithFallback('background: ', 'interactive-primary-light-bg')}
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary')}
    }
  }

  label {
    display: flex;
    align-items: flex-start;

    ${KDS.setColorTokenWithFallback('border: 1px solid ', 'border')}
    border-radius: 8px;
    box-sizing: border-box;
    padding: 10px 26px;

    ${KDS.setColorWithFallback('background: ', 'white')}

    ${KDS.setColorTokenWithFallback('color: ', 'text-secondary')}
  }

  option {
    display: none;
  }
`;

const InputWrapper = styled.div`
  position: relative;

  .helper-error {
    ${KDS.setColorTokenWithFallback('color: ', 'text-placeholder')}

    ${(props) => props.hasError && css`
      ${KDS.setColorTokenWithFallback('color: ', 'error')}
    `}
  }
`;

const RadioB2C = ({
  className, name, form, label, options, disabled, errorMessage, optional, helper, required, ...moreProps
}) => {
  const optionsData = options.map((option) => {
    let id = option.text.toLowerCase().replace(/ /g, '_');
    id = `${name || ''}_${id}`;
    return {
      id, text: option.text, value: option.value,
    };
  });

  const hasError = Boolean(errorMessage);

  const labelOrRequired = (
    <Space size="small" justify="space-between" className="k-ds-width-full" direction="horizontal">
      <label className="k-ds-heading-02 k-ds-text-color-primary">
        {label}
        { required ? <span className="k-ds-text-color-error">{' *'}</span> : null }
      </label>
      <span className="k-ds-label-01 k-ds-text-color-interactive-secondary">{optional}</span>
    </Space>
  );

  return (
    <Space size="small" direction="vertical" className={className}>
      {
        label || required ? (
          labelOrRequired
        ) : null
      }
      <InputWrapper className="k-ds-width-full" hasError={ hasError }>
        <Space size="medium" direction="horizontal" className="k-ds-width-full" {...moreProps}>
          {
            options.length > 0
              ? ( optionsData.map((option) => (
                    <StyledRadioB2CDiv key={option.id} className="option" >
                      <input type="radio" id={option.id} name={name} value={option.value} form={form} disabled={disabled}/>
                      <label htmlFor={option.id} className='k-ds-heading-01' aria-hidden="true">
                        {option.text}
                      </label>
                    </StyledRadioB2CDiv>
                  ))
              ) : null
          }
        </Space>
        {
          helper || hasError ? (
            <span className="helper-error k-ds-caption-01">
              {errorMessage || helper}
            </span>
          ) : null
        }
      </InputWrapper>
    </Space>
  );
};

export {
  RadioB2C,
};
