import { useEffect, useReducer } from 'react';

const formReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FORM_DATA':
      return { ...state, ...action.payload };
    case 'CLEAR_FORM_DATA':
      return action.payload;
    default:
      return state;
  }
};

function useFormWithCache(
  initialFormData,
  cacheKey,
  cacheDurationMs = 0,
  setWasOnFocus = () => {}
) {
  const [formData, dispatch] = useReducer(formReducer, initialFormData);

  const saveOnBlur = (name, value) => {
    const updatedFormData = { ...formData, [name]: value };
    dispatch({ type: 'SET_FORM_DATA', payload: updatedFormData });
    localStorage.setItem(cacheKey, JSON.stringify(updatedFormData));
  };

  const loadDataFromCache = () => {
    try {
      const cachedData = JSON.parse(localStorage.getItem(cacheKey));
      if (cachedData) {
        setWasOnFocus({
          quantity: cachedData?.quantity !== '' ?? false,
          amount: cachedData?.amount !== '' ?? false,
          reference: cachedData?.reference !== '' ?? false,
        });
        dispatch({ type: 'SET_FORM_DATA', payload: cachedData });
      }
    } catch (error) {
      console.error('Error parsing cached data:', error);
    }
  };

  useEffect(() => {
    const cachedTimestamp = localStorage.getItem(`${cacheKey}_timestamp`);
    if (
      cachedTimestamp &&
      Date.now() - Number(cachedTimestamp) <= cacheDurationMs
    ) {
      loadDataFromCache();
    }
  }, [cacheKey, cacheDurationMs]);

  useEffect(() => {
    localStorage.setItem(`${cacheKey}_timestamp`, Date.now());
  }, [cacheKey, formData]);

  const handleChange = (name, value) => {
    dispatch({
      type: 'SET_FORM_DATA',
      payload: { ...formData, [name]: value },
    });
  };

  const handleBlur = (name, value) => {
    saveOnBlur(name, value);
  };

  const resetForm = () => {
    dispatch({ type: 'CLEAR_FORM_DATA', payload: initialFormData });
    localStorage.removeItem(cacheKey);
    localStorage.removeItem(`${cacheKey}_timestamp`);
  };

  const clearCache = () => {
    dispatch({ type: 'CLEAR_FORM_DATA', payload: initialFormData });
    localStorage.removeItem(cacheKey);
    localStorage.removeItem(`${cacheKey}_timestamp`);
  };

  return {
    formData,
    handleChange,
    handleBlur,
    resetForm,
    clearCache,
  };
}

export default useFormWithCache;
