import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Auth from '../../Utils/Auth/auth';
import {
  phoneInputCleanFormatted,
  validateEmptyField,
  validateName,
} from '../../Utils/formValidations';
import { getLocalStorage, useGetMerchant } from '../../Utils/useGetMerchant';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { useLoaderContext } from '../../contexts/Loader/LoaderContext';
import { Divider, Space, Tag } from '../../ds';
import { getMerchantRoles } from '../../services/Application';
import { useStore } from '../../services/store';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import GetBackSection from '../GetBackSection';
import Header from '../Header';
import NotFound from '../NotFound';
import RadioButton from '../RadioButton';
import BusinessSection from './BusinessSection';
import RoleSection from './RoleSection';
import SubmitSection from './SubmitSection';
import UserSection from './UserSection';
import './mutateUser.scss';
import { isEmptyObject } from '../../Utils/utils';
import MerchantPaymentsService from '../../services/MerchantPaymentsService';
import { ACCOUNT_MANAGER } from '../../Utils/constants';
import AnalyticsEvent, {
  AnalyticsList,
} from '../../services/analytics/AnalyticsEvent';

const defaultFormData = {
  firstname: '',
  lastname: '',
  maidenName: '',
  curp: '',
  email: '',
  store: '',
  stores: [],
  role: '',
  userCode: '',
  cellphone: '',
};

export const MutateUserContext = createContext();

export const useMerchantRoles = merchantId => {
  const [merchantRoles, setMerchantRoles] = useState([]);

  useEffect(() => {
    getMerchantRoles(merchantId)
      .then(response => {
        const { data } = response;
        setMerchantRoles(data?.roles);
      })
      .catch(err => {
        AnalyticsEvent().errorTrack({
          errorType: 'user-update-get-merchant-roles-error',
          errorDescription: err,
        });
        console.log(err);
      });
  }, []);

  return merchantRoles;
};

function MutateUser() {
  const history = useHistory();
  const { userId } = useParams();
  const [formData, setFormData] = useState(defaultFormData);
  const [formDataErrors, setFormDataErrors] = useState(defaultFormData);
  const [openModal, setOpenModal] = useState(false);
  const viewport = useDeviceContextViewport();
  const { setIsLoaderOpen } = useLoaderContext();

  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    maiden_name: '',
    email: '',
    user_code: '',
    status: '',
    role_id: '',
    stores: [],
  });

  const {
    mutateUserStore: { setStores },
  } = useStore();
  const [isLoading, setIsLoading] = useState(false);

  const [roles, setRoles] = useState([]);

  const merchantInfo = useGetMerchant();

  const { employee } = Auth.userData();

  const isEdit = useMemo(() => userId !== undefined, [userId]);
  const [allowedEdit, setAllowedEdit] = useState(isEdit);
  const isTracked = useRef(false);

  const heading = useMemo(() => {
    if (isEdit) return 'Editar usuario';
    return 'Agregar usuario';
  }, [isEdit]);

  const subtitle = useMemo(() => {
    if (isEdit) return 'Edita la información del usuario.';

    return 'Ingresa la información necesaria para registrar un nuevo usuario.';
  }, [isEdit]);

  if (!merchantInfo) {
    return null;
  }

  const merchantId = merchantInfo.merchant_id;
  const role = employee.role;

  useEffect(() => {
    async function fetchMerchantInfo(userId) {
      setIsLoaderOpen(true);

      if (isEmptyObject(employee) || !employee.employee_id) {
        setAllowedEdit(false);
        setIsLoaderOpen(false);
        return;
      }

      const editUserInfo =
        await MerchantPaymentsService().getMerchantUser(userId);
      if (
        isEmptyObject(editUserInfo) ||
        editUserInfo.merchant_user_id === employee.employee_id
      ) {
        setAllowedEdit(false);
        setIsLoaderOpen(false);
        return;
      }

      if (merchantId !== editUserInfo.merchant_id) {
        setAllowedEdit(false);
        setIsLoaderOpen(false);
        return;
      }

      const currentUser = await MerchantPaymentsService().getMerchantUser(
        employee.employee_id
      );
      if (
        isEmptyObject(currentUser) ||
        editUserInfo.role_id === currentUser.role_id
      ) {
        setAllowedEdit(false);
        setIsLoaderOpen(false);
        return;
      }

      let rolesContent = [];
      try {
        const response = await getMerchantRoles(merchantId);
        rolesContent = response && response.data ? response.data.roles : [];
      } catch (err) {
        console.log(err);
      }

      const editUserRole = rolesContent.find(
        r => r.role_id === editUserInfo.role_id
      );
      if (role.name !== ACCOUNT_MANAGER && editUserRole.role_hierarchy <= 0) {
        setAllowedEdit(false);
        setIsLoaderOpen(false);
        return;
      }

      setUser(editUserInfo);
      setIsLoaderOpen(false);
    }

    if (!userId) {
      return;
    }

    fetchMerchantInfo(userId);
  }, []);

  useEffect(() => {
    async function fetchStoresLight() {
      setIsLoading(true);
      const merchantSelected = JSON.parse(getLocalStorage('merchantSelected'));

      let merchantId = null;
      if (isEmptyObject(merchantSelected) || !merchantSelected.merchant_id) {
        console.error('Missing merchantSelected');
      } else {
        merchantId = merchantSelected.merchant_id;
      }

      const responseStores =
        await MerchantPaymentsService().getStoresLight(merchantId);
      setStores(responseStores);
      setIsLoading(false);
    }

    fetchStoresLight();
  }, []);

  useEffect(() => {
    if (!isEmptyObject(user) && isEdit) {
      const getDropDownStore = () => {
        if (typeof user.stores === 'object' && !Array.isArray(user.stores)) {
          return user.stores.store_id;
        }

        if (typeof user.stores === 'object' && Array.isArray(user.stores)) {
          return user.stores.length > 0 ? user.stores[0].store_id : '';
        }

        return '';
      };

      const getStores = except => {
        if (user.stores.length === 1) return [];

        return user.stores
          .filter(item => item.store_id !== except)
          .map(item => item.store_id);
      };

      const errorMsn = 'Este campo no puede quedarse vacío';
      const storeSelected = getDropDownStore();
      const additionalStores = getStores(storeSelected);

      setFormDataErrors({
        firstname: user?.first_name ? '' : errorMsn,
        lastname: user?.last_name ? '' : errorMsn,
      });

      setFormData({
        firstname: user.first_name || '',
        lastname: user.last_name || '',
        maidenName: user.maiden_name || '',
        email: user.email,
        store: storeSelected,
        stores: additionalStores,
        role: user.role_id,
        userCode: user.user_code,
        status: user.status,
        curp: user.curp,
        cellphone: phoneInputCleanFormatted(user?.phone_number || ''),
      });
    }
  }, [user, isEdit]);

  useEffect(() => {
    if (!isTracked.current) {
      AnalyticsEvent().pageView({
        page: isEdit ? AnalyticsList.userEdit : AnalyticsList.userNew,
      });
      isTracked.current = true;
    }
  }, []);

  if (isLoading) return null;

  if (isEdit && !user) {
    return <NotFound />;
  }

  if (isEdit && !allowedEdit) {
    return <NotFound />;
  }

  return (
    <MutateUserContext.Provider value={{ role, roles, setRoles, setFormData }}>
      <div className='k-ds-width-full k-ds-text-color-primary'>
        <GetBackSection render />
        <Header title={heading} subtitle={subtitle} />
        <div
          className={
            viewport.isMobile ? 'k-ds-width-full' : 'viewport-width-90'
          }
        >
          <RoleSection
            formData={formData}
            setFormData={setFormData}
            viewport={viewport}
            formDataErrors={formDataErrors}
            setFormDataErrors={setFormDataErrors}
            merchantId={merchantId}
            role={role}
          />

          <Divider />
          <UserSection
            formData={formData}
            setFormData={setFormData}
            viewport={viewport}
            formDataErrors={formDataErrors}
            setFormDataErrors={setFormDataErrors}
            isEdit={isEdit}
          />

          <BusinessSection
            formData={formData}
            setFormData={setFormData}
            viewport={viewport}
            formDataErrors={formDataErrors}
            setFormDataErrors={setFormDataErrors}
            merchantId={merchantId}
            role={role}
          />
          <StatusSection
            formData={formData}
            setFormData={setFormData}
            viewport={viewport}
            formDataErrors={formDataErrors}
            setFormDataErrors={setFormDataErrors}
            isEdit={isEdit}
          />
          <SubmitSection
            formData={formData}
            setFormData={setFormData}
            setOpenModal={setOpenModal}
            formDataErrors={formDataErrors}
            setFormDataErrors={setFormDataErrors}
            isEdit={isEdit}
            userId={userId}
            merchantId={merchantId}
          />
        </div>
        <ConfirmModal
          isOpen={openModal}
          mode={isEdit ? 'Warning' : 'Cancel'}
          header={
            isEdit
              ? '¿Cancelar la edición del usuario?'
              : '¿Cancelar la creación del usuario?'
          }
          content='La información ingresada se perderá'
          onConfirm={() => history.goBack()}
          setIsOpen={setOpenModal}
        />
      </div>
    </MutateUserContext.Provider>
  );
}

export const verifyEmpty = (value, callback) => {
  const error = validateEmptyField(value, 'Este campo no puede quedarse vacío');
  if (error !== '') return error;

  return callback;
};

export const validateNameInput = val => {
  return verifyEmpty(
    val,
    !validateName(val) ? 'Uso de caracteres no permitidos' : ''
  );
};

function EmptySpace(props) {
  return props?.render ? (
    <span className='k-ds-width-full k-ds-m-bottom-sp-06' />
  ) : null;
}

EmptySpace.propTypes = {
  render: PropTypes.bool,
};
function StatusSection(props) {
  if (!props.isEdit) {
    return null;
  }
  return (
    <>
      <div className='k-ds-heading-04 k-ds-m-top-sp-09 k-ds-m-bottom-sp-05'>
        Estatus del usuario
      </div>
      <div className='k-ds-subheading-02 k-ds-m-bottom-sp-07'>
        Selecciona “Activo” si se encuentra en operación el empleado, o
        “Inactivo” para deshabilitar todas las funciones de usuario.
      </div>
      <div className='k-ds-heading-02'>
        Estatus:{' '}
        <Tag color={props.formData.status === 'active' ? 'cyan' : 'red'}>
          {props.formData.status === 'active' ? 'ACTIVO' : 'INACTIVO'}
        </Tag>
      </div>
      <div>
        <div className='k-ds-heading-02 k-ds-m-top-sp-06'>
          Cambiar estatus del usuario
        </div>
        <Space direction='horizontal' className='k-ds-m-top-sp-04'>
          <RadioButton
            className='k-ds-m-rigth-sp-06'
            label='Activo'
            value='active'
            onChange={e =>
              props.setFormData({
                ...props.formData,
                ...{ status: e.target.value },
              })
            }
            checked={props.formData.status === 'active'}
          />
          <RadioButton
            label='Inactivo'
            value='inactive'
            onChange={e =>
              props.setFormData({
                ...props.formData,
                ...{ status: e.target.value },
              })
            }
            checked={props.formData.status === 'inactive'}
          />
        </Space>
      </div>
    </>
  );
}

StatusSection.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
};

export default observer(MutateUser);
