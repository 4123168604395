import cn from 'classnames';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import Auth from '../../Utils/Auth/auth';
import { validateAlphaNumeric } from '../../Utils/formValidations';
import { currencyFormatter } from '../../Utils/formats';
import useFormWithCache from '../../Utils/useFormWithCache';
import { allowedMerchantsToViewProductTab } from '../../Utils/utils';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { Card, TabGroup, TabItem } from '../../ds';
import { requestKueskiCouponValidation } from '../../services/Application';
import initLogBehavior from '../../services/LogBehavior/LogBehavior';
import CodeInput from '../CodeInput';
import { Message } from '../DS/Message';
import { Select } from '../DS/Select';
import Amount from './Amount';
import InfoCard from './InfoCard';
import styles from './PaymentQr.module.scss';
import Product from './Product';
import './payOrder.scss';
import AnalyticsEvent, {
  AnalyticsList,
} from '../../services/analytics/AnalyticsEvent';

const translationPrefix = 'order.';

const KUESKI_COUPON_DIG = 6;

const InitialData = {
  paymentId: '',
  errors: '',
  isValid: false,
  amount: 0,
  path: '',
  token: '',
};

const INITAL_CODE_STATE = {
  coupon: '',
  couponMessage: '',
  amount: 0,
  payment_id: '',
  isCouponValidated: false,
  incomplete: false,
  noAlphaNumeric: false,
};

const returnActiveTab = (active, actived) => {
  return active === actived;
};

function PayOrderCoupon() {
  const isTracked = useRef(false);
  const { isMobile } = useDeviceContextViewport();
  const { t } = useTranslation();

  const { formData, handleChange, handleNaturalBlur } = useFormWithCache(
    { storeId: '' },
    'store_form',
    300000
  );
  const user = Auth.userData();
  const userLogBehavior = useRef('');
  const [stores, setStores] = useState([]);
  const [tabActive, setTabActive] = useState('amount');
  const [paymentData, setPaymentData] = useState(InitialData);
  const [couponState, setCouponState] = useState(INITAL_CODE_STATE);
  const [isDirtyForm, setIsDirtyForm] = useState(false);
  const [location, setLocation] = useState({});

  const merchantId = user.merchant.merchant_id;

  useEffect(() => {
    if (!isTracked.current) {
      AnalyticsEvent().pageView({ page: AnalyticsList.instorePaymentCode });
      isTracked.current = true;
    }
  }, []);

  useLayoutEffect(() => {
    initLogBehavior(userLogBehavior);
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          setLocation({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          });
        },
        error => {
          console.error('Error getting location:', error.message);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (user && user.stores) {
      const hasMoreThanOneStore = user.stores.length > 1;
      handleChange(
        'storeId',
        hasMoreThanOneStore ? '' : user.stores[0].store_id ?? ''
      );
      setStores(
        hasMoreThanOneStore
          ? user.stores.map(store => ({
              text: store.name,
              value: store.store_id,
            }))
          : []
      );
    }
  }, []);

  const handleUpdateInputCoupon = value => {
    if (
      value &&
      !couponState.isCouponValidated &&
      (couponState.coupon === '' || couponState.coupon.length < 6)
    ) {
      setIsDirtyForm(true);
      setCouponState({
        ...couponState,
        incomplete: true,
        couponMessage: 'El número del Código de Pago no puede quedar vacio.',
      });
    }
  };

  const resetPaymentData = () => {
    setPaymentData(InitialData);
  };

  function handleValidationRequest(coupon) {
    // setIsLoading(true);
    requestKueskiCouponValidation(coupon, merchantId)
      .then(response => {
        if (response.status === 'success') {
          AnalyticsEvent().paymentCodeCreated();
          const paymentResponse = response?.data?.payment_id;
          setCouponState({
            ...couponState,
            couponMessage: 'Código de pago validado exitosamente',
            amount: Number.parseFloat(response?.data?.amount) || 0,
            payment_id: paymentResponse,
            isCouponValidated: true,
            incomplete: false,
          });
        }
      })
      .catch(e => {
        const errorMessage = e?.response?.data?.message;
        AnalyticsEvent().errorTrack({
          errorType: 'pay-order-coupon-validation-error',
          errorDescription: errorMessage,
        });

        setCouponState({
          ...couponState,
          couponMessage: t(`${translationPrefix}${errorMessage}`, {
            defaultValue: t(translationPrefix + 'Error getting payment'),
          }),
          amount: 0,
          payment_id: '',
          isCouponValidated: false,
          incomplete: false,
        });
        // setIsLoading(false);
      });
  }

  const onKueskiCouponChange = coupon => {
    const isValid = validateAlphaNumeric(coupon);
    setCouponState({
      ...couponState,
      coupon: coupon.toUpperCase(),
      couponMessage: '',
      payment_id: '',
      amount: 0,
      isCouponValidated: false,
      incomplete: false,
      noAlphaNumeric: !isValid,
    });

    if (KUESKI_COUPON_DIG === coupon.length && isValid) {
      handleValidationRequest(coupon);
    }
  };

  if (!user) {
    return '';
  }
  const tabItemClassName = isMobile ? 'width-half align-items-center' : '';
  const storeSelectorColumnsClass = isMobile ? 'one-column' : 'two-columns';

  const allowedToShow = allowedMerchantsToViewProductTab.includes(merchantId);

  return (
    <div className='k-ds-width-full pay-order'>
      <div className='pay-order-header k-ds-m-bottom-sp-07'>
        Cobrar por Código de pago o{' '}
        <Link to='/orders/create-by-qr' className='link-button'>
          Cobrar por Código QR
        </Link>
      </div>
      <InfoCard />

      <div className='select-store-dropdown'>
        {stores.length > 0 && (
          <div className={storeSelectorColumnsClass}>
            <Select
              type='text'
              label='Selecciona una sucursal'
              helper='El cobro se asignará a esta sucursal'
              placeholder='Elige una opción o escribe aquí'
              required
              name='sucursal'
              options={stores}
              value={formData.storeId}
              onChange={value => handleChange('storeId', value)}
              onBlur={() => handleNaturalBlur()}
            />
          </div>
        )}
      </div>
      <Card
        type='secondary'
        className='k-ds-width-full k-ds-m-top-sp-06 card-mobile'
      >
        {TitleSection('Código de pago')}
        <div className={styles['flex-2-column-reverse']}>
          <div className={styles['containerBox']}>
            <div
              className={cn(
                'k-ds-body-01 k-ds-m-top-lyt-02 k-ds-m-bottom-sp-03'
              )}
            >
              {isMobile
                ? 'Ingresa los 6 dígitos del Código de pago del cliente'
                : 'Ingresa el Código de pago manualmente o escanea el QR del celular del cliente para autorellenar.'}
            </div>
            <div className={styles['codeinputWidth']}>
              <CodeInput
                justify='space-between'
                length={6}
                hasError={
                  (!!couponState.couponMessage &&
                    !couponState.isCouponValidated) ||
                  (isDirtyForm && couponState.incomplete)
                }
                validCode={couponState.isCouponValidated}
                onChangeCode={onKueskiCouponChange}
              />
              <div>
                <span
                  className={cn({
                    ['k-ds-text-color-error k-ds-label-01']:
                      (couponState.couponMessage &&
                        !couponState.isCouponValidated) ||
                      (isDirtyForm && couponState.incomplete) ||
                      couponState.noAlphaNumeric,
                    ['k-ds-text-color-light-green-500 k-ds-label-01']:
                      couponState.couponMessage &&
                      couponState.isCouponValidated,
                    ['k-ds-text-color-grey-700 k-ds-label-01']:
                      !couponState.couponMessage,
                  })}
                >
                  {couponState.couponMessage
                    ? couponState.couponMessage
                    : 'Este campo no acepta caracteres especiales ni espacios.'}
                </span>
              </div>
            </div>
            {couponState.isCouponValidated && (
              <Message
                type='information'
                className='k-ds-m-bottom-sp-05'
                message={
                  <div className='k-ds-heading-01'>
                    <div className='font-weight-normal'>
                      Código de pago preapobado por:{' '}
                      <span className='font-weight-bold'>
                        {currencyFormatter(couponState?.amount ?? 0)} m.n.
                      </span>
                    </div>
                  </div>
                }
              />
            )}
          </div>
          {/* <CodeSkeleton /> */}
        </div>
      </Card>

      <Card
        type='secondary'
        className='k-ds-width-full k-ds-m-top-sp-06 card-mobile'
      >
        <div className='sub-header k-ds-m-top-sp-10'>
          Cobra por monto
          {allowedToShow && ' o producto'}
        </div>
        {allowedToShow && (
          <TabGroup
            role='tabGruop'
            className='k-ds-width-full k-ds-m-top-sp-04'
          >
            <TabItem
              className={tabItemClassName}
              selected={returnActiveTab(tabActive, 'amount')}
              onClick={() => setTabActive('amount')}
            >
              Monto
            </TabItem>
            <TabItem
              className={tabItemClassName}
              selected={returnActiveTab(tabActive, 'product')}
              onClick={() => setTabActive('product')}
            >
              Producto
            </TabItem>
          </TabGroup>
        )}

        {tabActive === 'amount' && (
          <Amount
            paymentByCoupon
            storeid={formData.storeId}
            location={location}
            paymentId={couponState.payment_id}
            isValidCoupon={couponState.isCouponValidated}
            couponAmount={couponState.amount}
            paymentAmount={paymentData.amount}
            isPaymentValidated={paymentData.isValid}
            onClear={resetPaymentData}
            validateFillCoupon={handleUpdateInputCoupon}
            logbehavior={userLogBehavior}
          />
        )}

        {allowedToShow && tabActive === 'product' && (
          <Product
            paymentByCoupon
            storeid={formData.storeId}
            location={location}
            paymentId={couponState.payment_id}
            couponAmount={couponState.amount}
            paymentAmount={paymentData.amount}
            isValidCoupon={couponState.isCouponValidated}
            isPaymentValidated={paymentData.isValid}
            paymentValidationErrors={paymentData.errors}
            validateFillCoupon={handleUpdateInputCoupon}
            onClear={resetPaymentData}
            logbehavior={userLogBehavior}
          />
        )}
      </Card>
    </div>
  );

  function TitleSection(title) {
    return <div className='sub-header k-ds-m-top-sp-10'>{title}</div>;
  }
}

export default PayOrderCoupon;
