/* eslint-disable react/jsx-key */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { flexRender } from './../../Utils/ReactTable';
import {
  StyledTableContainer,
  StyledTable,
  StyledThead,
  StyledTR,
} from './styled';
import NoResults from './NoResults';
import Pagination from './Pagination';
import styled from 'styled-components';

const ScrollOverlay = styled.div`
    background-color: transparent;
    height: 1rem;
    position: relative;
    margin-top: -1rem;
    z-index: -10;
  `

const TableCustom = ({
  table,
  sticky = '',
  renderSubComponent = () => null,
  onCleanFilter = () => null,
  scrollOverlayClass = 'scroll-overlay',
  pagination: {
    onChangePageSize = () => null,
    onChangePage = () => null,
    totalElements = 0,
    totalPages = 0,
    currentPage = 0,
    className = '',
  },
}) => {
  return (
    <>
      <StyledTableContainer>
        <StyledTable style={{ width: table.getCenterTotalSize() }}>
          <StyledThead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={sticky === header.id ? 'sticky' : ''}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </StyledThead>
          {table.getRowModel().rows.length > 0 ? (
            <tbody>
              {table.getRowModel().rows.map(row => (
                <Fragment key={row.id}>
                  <StyledTR paddingSize='spacing-04'>
                    {row.getVisibleCells().map(cell => (
                      <td
                        key={cell.id}
                        style={{ width: cell.column.getSize() }}
                        className={sticky === cell.column.id ? 'sticky' : ''}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </StyledTR>
                  {row.getIsExpanded() && (
                    <StyledTR>
                      <td colSpan={row.getVisibleCells().length}>
                        {renderSubComponent({ row })}
                      </td>
                    </StyledTR>
                  )}
                </Fragment>
              ))}
            </tbody>
          ) : null}
        </StyledTable>
        {table.getRowModel().rows.length === 0 ? (
          <NoResults onCleanFilter={onCleanFilter} />
        ) : null}
      </StyledTableContainer>
      <ScrollOverlay className={ scrollOverlayClass }/>
      <Pagination
        onChangePageSize={onChangePageSize}
        onChangePage={onChangePage}
        className={className}
        options={{
          totalElements,
          totalPages,
          currentPage,
        }}
      />
    </>
  );
};

TableCustom.propTypes = {
  table: PropTypes.any.isRequired,
  sticky: PropTypes.string,
  renderSubComponent: PropTypes.func,
  onCleanFilter: PropTypes.func,
  scrollOverlayClass: PropTypes.string,
  pagination: PropTypes.shape({
    onChangePageSize: PropTypes.func,
    onChangePage: PropTypes.func,
    totalElements: PropTypes.number,
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    className: PropTypes.string,
  }),
};

export default TableCustom;
