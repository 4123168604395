import { Modal, Space } from '../../ds';
import PropTypes from 'prop-types';
import React from 'react';
import LoaderBlack from '../../containers/pages/Layout/loader-black-2x.gif';
import './loaderModal.scss';

const LoaderModal = ({ isOpen = false, customLoader, header, content }) => {
  return (
    <>
      <Modal
        theme='dark'
        cardType='secondary'
        isOpen={isOpen}
        className='loader-modal'
      >
        <Space
          justify='center'
          direction='vertical'
          align='center'
          className='k-ds-m-horizontal-sp-09 k-ds-m-bottom-sp-09'
        >
          {customLoader ? (
            customLoader
          ) : (
            <img width={200} height={240} src={LoaderBlack} alt='loading...' />
          )}
          <div className='k-ds-body-02 k-ds-m-bottom-sp-05'>{header}</div>
          <div className='k-ds-body-02 k-ds-m-bottom-sp-09 content'>
            {content}
          </div>
        </Space>
      </Modal>
    </>
  );
};

LoaderModal.propTypes = {
  isOpen: PropTypes.bool,
  customLoader: PropTypes.element,
  header: PropTypes.string,
  content: PropTypes.string,
};

export default LoaderModal;
