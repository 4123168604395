import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { Space } from '../../../../ds';
import {
  StyledSelectOutline,
  StyledButton,
  StyledDateInput,
  HiddenDivInput,
} from './styled';
import { ReactComponent as IconArrowDown } from './icon-arrow-down.svg';
import Timer from '../../../../Utils/Timer';
import { useDeviceContextViewport } from '../../../../contexts/Device/DeviceContext';

const FORMAT_DATE = 'd/MM/yy';

// Gneral Focus Hook
const UseFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};

const PlaceHolderLabel = ({ startDate, endDate, defaultValue }) => {
  if (!startDate || !endDate) {
    return defaultValue;
  }

  if (Timer().diff(startDate, endDate, 'day').days === 0) {
    return (
      <>
        <div>
          <span className='k-ds-heading-02'>Fecha:</span>
          <span className='k-ds-body-02'>{` ${Timer().toLocal(startDate, FORMAT_DATE)}`}</span>
        </div>
      </>
    );
  }

  if (Timer().toLocal(startDate, 'L') === Timer().toLocal(endDate, 'L')) {
    const endDay = Timer().toLocal(endDate, 'd');
    // eslint-disable-next-line no-useless-escape
    const startDateFormant = Timer().toLocal(
      startDate,
      // eslint-disable-next-line no-useless-escape
      `d \'al\' ${endDay} \'de\' MMMM \'del\' yyyy`
    );

    return (
      <>
        <div>
          <span className='k-ds-heading-02'>Fecha:</span>
          <span className='k-ds-body-02'>{` ${startDateFormant}`}</span>
        </div>
      </>
    );
  }

  return (
    <>
      <div>
        <span className='k-ds-heading-02'>Fecha:</span>
        <span className='k-ds-body-02'>{` ${Timer().toLocal(startDate, FORMAT_DATE)}`}</span>
      </div>
      <div className='k-ds-body-02 text-date'>{`al ${Timer().toLocal(endDate, FORMAT_DATE)}`}</div>
    </>
  );
};

PlaceHolderLabel.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
};

const SearchByPeriod = ({
  className,
  name,
  label,
  placeholder,
  onBlur,
  disabled,
  errorMessage,
  helper,
  formatDate = '',
  value,
  onClick = () => null,
  ...moreProps
}) => {
  const hasError = Boolean(errorMessage);
  const { required } = moreProps;
  const props = { ...moreProps, hasError };

  const [isActive, setIsActive] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [ignoreBlur, setIgnoreBlur] = useState(false);
  const [inputHiddenRef, setInputHiddenFocus] = UseFocus();

  const [selected, setSelected] = useState(false);

  const viewport = useDeviceContextViewport();
  const [date, setDate] = useState({ start: '', end: '' });
  const [today] = useState(Timer().toNowFormat());

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, []);

  const onSelectClick = () => {
    if (disabled) return;

    setIsActive(!isActive);
    setInputHiddenFocus();
  };

  const onOptionClick = () => {
    if (onClick) {
      onClick({
        start: Timer().toISOString(date.start, formatDate),
        end: Timer().toISOString(date.end, formatDate),
      });
    }
    setSelected(true);
    setIsActive(false);
    setIgnoreBlur(false);
  };

  const handleBlur = () => {
    if (ignoreBlur) return;
  };

  useEffect(() => {
    if (!isActive && !isFirstRender) {
      if (onBlur) onBlur();
    }
  }, [isActive]);

  useEffect(() => {
    if (value.start && value.end) {
      setDate({
        start: Timer().toLocal(value.start),
        end: Timer().toLocal(value.end),
      });
    }
  }, [value]);

  const showClass = isActive && !disabled ? 'show' : '';

  return (
    <Space size='x-large' direction='vertical' className={className} {...props}>
      {label && (
        <Space
          size='small'
          justify='space-between'
          className='k-ds-width-full'
          direction='horizontal'
        >
          <label
            className='k-ds-body-01 k-ds-text-color-primary'
            htmlFor={name}
          >
            {label}
            {required ? (
              <span className='k-ds-text-color-error'>{' *'}</span>
            ) : null}
          </label>
        </Space>
      )}
      <StyledSelectOutline
        className='k-ds-width-full k-ds-body-01'
        hasError={hasError}
        disabled={disabled}
        showPlaceholder={date.end && date.start === ''}
        active={isActive}
      >
        <HiddenDivInput ref={inputHiddenRef} type='radio' onBlur={handleBlur} />
        <Space
          size='medium'
          justify='space-between'
          className={`k-ds-width-full selector ${selected ? 'selected' : ''}`}
          onClick={onSelectClick}
          direction='horizontal'
          align='center'
          aria-hidden
        >
          <div className='control'>
            <PlaceHolderLabel
              startDate={date.start}
              endDate={date.end}
              defaultValue={placeholder && ''}
            />
          </div>
          <IconArrowDown className={`arrow-icon ${showClass}`} />
        </Space>
        <div className={`options-group ${showClass}`}>
          <Space
            direction={
              viewport.isMobile && !viewport.isSmallTablet
                ? 'vertical'
                : 'horizontal'
            }
            className='k-ds-width-full k-ds-m-top-sp-05 k-ds-m-bottom-sp-05'
            justify='center'
          >
            <Space
              direction={
                viewport.isMobile && !viewport.isSmallTablet
                  ? 'vertical'
                  : 'horizontal'
              }
              align='center'
            >
              <div>Del</div>
              <StyledDateInput
                className='payment-filter-input'
                value={date.start}
                max={date.end === '' ? today : date.end}
                onChange={e => {
                  setDate({
                    start: e.target.value,
                    end: date.end,
                  });
                }}
              />
            </Space>
            <Space
              direction={
                viewport.isMobile && !viewport.isSmallTablet
                  ? 'vertical'
                  : 'horizontal'
              }
              align='center'
            >
              <div>al</div>
              <StyledDateInput
                className='payment-filter-input'
                value={date.end}
                min={date.start}
                max={today}
                onChange={e => {
                  setDate({
                    start: date.start,
                    end: e.target.value,
                  });
                }}
              />
            </Space>
          </Space>
          {viewport.isMobile && !viewport.isSmallTablet && (
            <div className='search-caption k-ds-m-top-sp-02 k-ds-m-bottom-sp-04'>
              *El rango máximo es de 31 días naturales, desde las 00:00 hasta
              las 23:59.
            </div>
          )}
          <StyledButton
            className='k-ds-m-top-sp-03'
            type='primary-alternate'
            horizontalPadding='1.125rem'
            verticalPadding='1rem'
            size='small'
            onMouseDown={() => setIgnoreBlur(true)}
            onClick={onOptionClick}
          >
            Aplicar
          </StyledButton>
        </div>
        {helper || hasError ? (
          <span className='helper-error k-ds-caption-01'>
            {errorMessage || helper}
          </span>
        ) : null}
      </StyledSelectOutline>
    </Space>
  );
};

SearchByPeriod.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  helper: PropTypes.string,
  formatDate: PropTypes.string,
  value: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
};

export default SearchByPeriod;
