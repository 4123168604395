import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';

const CollapsableRowWrapper = styled.tr`
  background-color: ${props => props.backgroundColor || '#FAFAFA'};
  ${props => (props.noBorderBottom ? 'border: none !important' : '')}
`;

const TrCollapsable = ({ children, isCollapsed, devices, ...props }) => {
  const { type } = useDeviceContextViewport();

  if (!devices?.split(' ').includes(type) || isCollapsed) {
    return null;
  }

  return <CollapsableRowWrapper {...props}>{children}</CollapsableRowWrapper>;
};

TrCollapsable.propTypes = {
  children: PropTypes.node,
  isCollapsed: PropTypes.bool,
  devices: PropTypes.string,
};

export default TrCollapsable;
