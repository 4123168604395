/* eslint-disable react/prop-types */
import styled from 'styled-components';
import React from 'react';
import { ReactComponent as IconInformation } from './icon-information.svg';
import KDS from '../../../ds/lib/k-ds-foundation';

const StyledMessageInformation = styled.div`
  display: flex;
  align-items: flex-start;

  border-radius: 4px;
  box-sizing: border-box;
  padding: 16px 20px;
  border: 1px solid #2196f3;

  ${KDS.setColorWithFallback('background: ', 'blue-100')}

  .icon {
    min-width: 18px;
  }

  .message {
    overflow: hidden;
    ${KDS.setSpacingWithFallback('margin-left: ', 'spacing-04')};
    overflow-wrap: break-word;
  }
`;

const MessageInformation = ({ message, ...moreProps }) => (
  <StyledMessageInformation {...moreProps}>
    <IconInformation className='icon' />
    <div className='message k-ds-label-01'>{message}</div>
  </StyledMessageInformation>
);

export { MessageInformation };
