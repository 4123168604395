import { useEffect, useState } from 'react';

export const useFormatStores = merchantStores => {
  const [storesFormatted, setStoresFormatted] = useState({});

  useEffect(() => {
    if (merchantStores.length === 0) {
      setStoresFormatted({});
      return;
    }

    merchantStores.map(item => {
      setStoresFormatted(prev => ({ ...prev, [item.value]: item.text }));
    });
  }, [merchantStores]);

  return storesFormatted;
};
