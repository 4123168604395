import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledClippedDiv = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
`;

const InfoLabel = ({ label, text, hasError, message }) => (
  <div className='info'>
    <div className='label'>{label}</div>
    <StyledClippedDiv className='text' title={ text }>{text}</StyledClippedDiv>
    {hasError && (
      <span className='message-error'>
        {message || 'Es necesario completar este dato'}
      </span>
    )}
  </div>
);

InfoLabel.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  message: PropTypes.string,
};

export default InfoLabel;
