import React, { useState, useEffect, useRef } from 'react';
import { Button, Card, Space } from '../../ds';
import { useParams } from 'react-router-dom';
import { useLoaderContext } from '../../contexts/Loader/LoaderContext';
import { getOrderGenerated } from '../../services/Application';
import RenderBarcode from '../RenderBarcode';
import './paymentGeneratedByQR.scss';
import { ReactComponent as Information } from './icon-information.svg';
import { ReactComponent as Clock } from './icon-clock.svg';
import OneTimePasscodeVerification from './OneTimePasscodeVerification/OneTimePasscodeVerification';
import SharePayLink from './SharePayLink/SharePayLink';
import { goPage } from '../../Utils/pages';
import Error from './Error/Error';
import GetBackSection from './../GetBackSection';
import { useHistory } from 'react-router-dom';
import { orderVerdict } from '../../services/Application';
import { notApplicableOrderStaffId } from './../../Utils/constants';
import ScrollToTop from './../ScrollToTop/ScrollToTop';
import NotFound from '../NotFound';
import Auth from '../../Utils/Auth/auth';
import { useStore } from '../../services/store';
import AnalyticsEvent, {
  AnalyticsEventDictionary,
  AnalyticsList,
} from '../../services/analytics/AnalyticsEvent';

const INIT_ORDER_GENERATED_SERVICE = {
  order_reference: '',
  total: '',
  items_count: 0,
  paymentId: '',
  paymentUrl: '',
  paymentQR: '',
  totalFormatted: '',
  status: '',
};

const PaymentGeneratedByQR = () => {
  const isTracked = useRef(false);
  const { stores } = Auth.userData();
  const history = useHistory();
  const [isOpenPaymentUrl, setisOpenPaymentUrl] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  const { setIsLoaderOpen } = useLoaderContext();

  const [orderGeneratedService, setOrderGeneratedService] = useState(
    INIT_ORDER_GENERATED_SERVICE
  );
  const { orderId } = useParams();
  const {
    qrCodeStore: { storeId, reload },
  } = useStore();
  const selectedStoreId = storeId;

  const goCreateQR = async () => {
    try {
      AnalyticsEvent().inStore({
        eventId:
          AnalyticsEventDictionary.merchantPortalQRBasedOrderCreatedViewCancelButtonClicked,
      });

      const { staffId } = orderGeneratedService;
      const data = {
        staff_id: staffId,
        store_id: selectedStoreId,
        verdict: 'reject',
      };
      orderVerdict(orderId, data).then(() => {
        reload();
        goPage('/orders/create-by-qr', history);
      });
    } catch (error) {
      setIsOpenError(true);
      reload();
      console.error(error.response);
    }
  };

  useEffect(() => {
    if (stores && !stores.find(store => store.store_id === storeId)) {
      setUnauthorized(true);
      return;
    }

    setIsLoaderOpen(true);
    getOrderGenerated(orderId, selectedStoreId)
      .then(response => {
        const { order } = response.data;
        setOrderGeneratedService({
          staffId:
            order.staff_id !== notApplicableOrderStaffId ? order.staff_id : '',
          order_reference: order.order_reference,
          total: order.total,
          totalFormatted: order.total_formatted,
          items_count: order.items_count,
          paymentId: order.payment_attributes.payment,
          paymentUrl: order.payment_attributes.payment_url,
          paymentQR: order.payment_attributes.payment_url_qr,
          status: order.kueski_authorization,
        });
        setIsLoading(false);
        setIsLoaderOpen(false);
      })
      .catch(
        error => {
          AnalyticsEvent().errorTrack({
            errorType: 'payment-generate-by-qr-generate-order-error',
            errorDescription: error,
          });
          console.log(error);
          setUnauthorized(true);
        } //TODO
      )
      .finally(() => {
        setIsLoaderOpen(false);
      });
  }, []);

  useEffect(() => {
    if (!isTracked.current) {
      AnalyticsEvent().pageView({
        page: AnalyticsList.instoreGenerateQR,
      });
      AnalyticsEvent().inStore({
        eventId:
          AnalyticsEventDictionary.merchantPortalChargeQROrderCreatedViewLoaded,
      });
      isTracked.current = true;
    }
  }, []);

  const getBackSection = async () => {
    await goCreateQR();
    goPage('/orders/create-by-qr', history);
  };

  const onClickPaymentUrl = () => {
    AnalyticsEvent().inStore({
      eventId:
        AnalyticsEventDictionary.merchantPortalChargeQROrderCreatedViewCopyUrlButtonClicked,
    });
    setisOpenPaymentUrl(true);
  };

  const onCancelPaymentUrl = () => {
    setIsOpenError(true);
  };

  if (unauthorized) {
    return <NotFound />;
  }

  if (isLoading) return '';

  return (
    <>
      <ScrollToTop />
      <GetBackSection callback={() => getBackSection()} render />
      <div className='k-ds-width-full payment-generated'>
        <div className='header'>Cobrar por Código QR</div>

        <div className='payment-generated-content k-ds-m-top-sp-07'>
          <div className='k-ds-width-full'>
            <Card className='k-ds-width-full'>
              <div className='k-ds-heading-03'>
                Muestra el código QR al cliente
              </div>
              <div className='k-ds-subheading-02'>
                Permítele al cliente escanear este código para continuar la
                compra en su celular.
              </div>

              <div className='payment-generated-payment k-ds-width-full k-ds-m-top-sp-06'>
                <RenderBarcode
                  className='payment-generated-qr'
                  barcode={orderGeneratedService.paymentQR}
                />

                <Space
                  className='share-link-box k-ds-width-full'
                  justify='center'
                  align='center'
                >
                  <div className='share-icon k-ds-m-right-sp-03'>
                    <Information />
                  </div>
                  <div className='share-link'>
                    <div className='share-link-text'>
                      ¿El cliente no puede escanear el código?
                      <Button
                        className='share-link-btn'
                        type='link'
                        size='small'
                        horizontalPadding='4px'
                        verticalPadding='0px'
                        onClick={onClickPaymentUrl}
                      >
                        Ver solución
                      </Button>
                    </div>
                  </div>
                </Space>
              </div>
            </Card>

            <Card className='k-ds-width-full k-ds-m-top-sp-06'>
              <Space align='center'>
                <Clock className='k-ds-m-right-sp-03' />
                <div className='payment-generated-offline-helper'>
                  <span className='k-ds-subheading-03'>
                    Espera a que validemos la transacción automáticamente. Si el
                    cliente no tiene conexión a internet, usa el proceso de{' '}
                    <span className='k-ds-heading-03'>Pago sin conexión.</span>
                  </span>
                </div>
              </Space>
            </Card>

            <OneTimePasscodeVerification
              orderGeneratedService={orderGeneratedService}
              selectedStoreId={selectedStoreId}
              orderId={orderId}
              paymentId={orderGeneratedService.paymentId}
            />
          </div>

          <Card className='payment-generated-summary'>
            <div className='k-ds-heading-03 k-ds-m-bottom-sp-04'>
              Resumen de la transacción
            </div>
            <div className='summary-row'>
              <div className='k-ds-subheading-01'>Nº de productos:</div>
              <div className='k-ds-heading-01'>
                {orderGeneratedService.items_count}
              </div>
            </div>
            <div className='summary-row'>
              <div className='k-ds-heading-01'>Monto total:</div>
              <div className='k-ds-heading-01'>{`$${orderGeneratedService.totalFormatted} m.n.`}</div>
            </div>
          </Card>
          <Space className='k-ds-width-full k-ds-m-bottom-sp-04'>
            <Button type='simple' size='small' onClick={onCancelPaymentUrl}>
              Cancelar
            </Button>
          </Space>
        </div>
        <SharePayLink
          onClose={() => setisOpenPaymentUrl(false)}
          paymentUrl={orderGeneratedService.paymentUrl}
          isOpen={isOpenPaymentUrl}
        />
        <Error
          isOpen={isOpenError}
          onClose={() => setIsOpenError(false)}
          title='¿Deseas cancelar?'
          content='Al cancelar, deberás iniciar nuevamente el proceso'
          textButton='Cancelar'
          callback={goCreateQR}
        />
      </div>
    </>
  );
};

export default PaymentGeneratedByQR;
