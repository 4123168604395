import { useMemo } from 'react';
import Shepherd from 'shepherd.js';
import { getTutorial, updateTutorial } from './storage';
import './tutorial.scss';

const addSteps = (steps, tour, enableMultiBrand) => {
  // Return nothing if there are no steps
  if (!steps.length) {
    return [];
  }

  let customSteps = typeof steps === 'function' ? steps(tour, enableMultiBrand) : steps;

  const parsedStepsforAction = customSteps.map(step => {
    const { buttons } = step;

    if (buttons) {
      step.buttons = buttons.map(button => {
        const { action, classes, disabled, label, secondary, text, type } =
          button;
        return {
          action: type ? tour[type] : action,
          classes,
          disabled,
          label,
          secondary,
          text,
          type,
        };
      });
    }

    return step;
  });

  return parsedStepsforAction.forEach(step => tour.addStep(step));
};

const defaultStepOptions = {
  defaultStepOptions: {
    scrollTo: true,
    cancelIcon: {
      enabled: true,
    },
  },
  scrollTo: { behavior: 'smooth', block: 'center' },
  useModalOverlay: true,
};

export const useTutorial = ({ options = {}, steps = [], enableMultiBrand = false }) => {
  const userDefaultOptions =
    options && options.defaultStepOptions ? options.defaultStepOptions : {};
  const defaultOptions = {
    ...defaultStepOptions,
    defaultStepOptions: {
      ...defaultStepOptions.defaultStepOptions,
      ...userDefaultOptions,
    },
    ...options,
  };

  const tourObject = useMemo(() => {
    const tourInstance = new Shepherd.Tour(defaultOptions);

    addSteps(steps, tourInstance, enableMultiBrand);

    return tourInstance;
  }, [steps, defaultOptions, enableMultiBrand]);

  const initTutorial = ({ module = '', noShowTutorial = 2 }) => {
    const tutorial = getTutorial(module);

    if (!tutorial) {
      console.error('cannot start tutorial');
      return;
    }

    if (tutorial.number < noShowTutorial) {
      if (!tutorial.visited) {
        tourObject.start();

        updateTutorial({
          module: module,
          content: {
            number: Number(tutorial.number) + 1,
            lastStep: tutorial.lastStep,
            visited: true,
          },
        });
      }
    }
  };

  return Object.assign(tourObject, { initTutorial: initTutorial });
};
