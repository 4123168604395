import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './SingleInput.scss';

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const SingleInput = props => {
  const {
    focus,
    autofocus = true,
    hasError,
    validCode,
    value,
    ...rest
  } = props;
  const inputRef = useRef(null);
  const prevFocus = usePrevious(!!focus);
  const [hasErrorKey, setHasErrorKey] = useState(false);
  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autofocus) {
        inputRef.current.focus();
      }
      if (focus && autofocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autofocus, focus, prevFocus]);

  useEffect(() => {
    const notAllowedKey = !!value.match(/^[^a-zA-Z0-9]$/);
    setHasErrorKey(notAllowedKey);
  }, [value]);

  return (
    <input
      ref={inputRef}
      value={value}
      style={{ border: hasErrorKey ? '1px solid red' : '' }}
      className={cn(
        'input-initial k-ds-text-color-interactive-alternate k-ds-width-full k-ds-body-01-roboto text-center',
        {
          ['input-sucess']: validCode,
          ['input-error']: hasError,
        }
      )}
      {...rest}
    />
  );
};

SingleInput.propTypes = {
  focus: PropTypes.bool,
  autofocus: PropTypes.bool,
  hasError: PropTypes.bool.isRequired,
  validCode: PropTypes.bool.isRequired,
  value: PropTypes.any,
};

export default SingleInput;
