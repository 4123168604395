import React, { useState, useEffect } from 'react';
import { Space, Tag, Table } from '../../ds';
import './CampaignPromotions.scss';
import GetBackSection from '../GetBackSection';
import { TdResponsive, TrCollapsable } from '../ResponsiveTable';
import { ReactComponent as FolderIcon } from './Folder.svg';
import CampaignDirectories from '../CampaignDirectories/CampaignDirectories';
import { useLoaderContext } from '../../contexts/Loader/LoaderContext';
import { getCampaignById } from '../../services/Application';
import { useParams } from 'react-router-dom';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';

const CampaignPromotions = () => {
  const { setIsLoaderOpen } = useLoaderContext();
  const [campaign, setCampaign] = useState([]);
  const { campaign_id } = useParams();
  const viewport = useDeviceContextViewport();

  useEffect(() => {
    setIsLoaderOpen(true);

    getCampaignById(campaign_id)
      .then(response => {
        setCampaign(response.data.campaign);
      })
      .catch(console.error)
      .finally(() => {
        setIsLoaderOpen(false);
      });
  }, []);

  const [expandedItem, setExpandedItem] = useState('');
  const tableHeaders = {
    name: 'Directorio de promociones y assets',
    count: '(' + campaign.promotions_count + ')',
  };

  return (
    <div
      className={
        !viewport.isDesktop
          ? 'k-ds-m-top-sp-07 campaign-directories'
          : 'campaign-directories'
      }
    >
      <GetBackSection redirectUri='/campaigns' render />
      <div className='k-ds-heading-04 k-ds-m-bottom-sp-04'>{campaign.name}</div>
      <div className='k-ds-body-01 k-ds-m-bottom-sp-09'>
        {campaign.description}
      </div>
      <Table
        type='b2b'
        className='k-ds-width-full k-ds-m-top-sp-07 campaign-directories-list'
      >
        <thead>
          <tr>
            <th>{tableHeaders.name}</th>
            <th className='promotions-count'>{tableHeaders.count}</th>
          </tr>
        </thead>
        <tbody>
          {campaign?.promotions?.map((promotion, index) => {
            const isExpanded = expandedItem === promotion.promotion_id;
            return (
              <React.Fragment key={index}>
                <tr
                  aria-expanded={isExpanded}
                  className={
                    isExpanded
                      ? 'bg-white promotion-container promotion-container-uncollapsed'
                      : 'bg-white promotion-container'
                  }
                >
                  <TdResponsive
                    className='main-dir-row'
                    onClick={() =>
                      setExpandedItem(isExpanded ? '' : promotion.promotion_id)
                    }
                  >
                    <Space direction='vertical' className='k-ds-width-full'>
                      <Space
                        justify='space-between'
                        direction={
                          viewport.isMobile && !viewport.isSmallTablet
                            ? 'vertical'
                            : 'horizontal'
                        }
                        className='k-ds-width-full'
                      >
                        <Space direction='horizontal'>
                          <Space direction='vertical'>
                            <div className='k-ds-body-02'>
                              <FolderIcon />
                            </div>
                          </Space>
                          <Space
                            direction='vertical'
                            className='promotion-description-container k-ds-m-left-sp-04 k-ds-width-full'
                          >
                            <div className='k-ds-m-left-sp-06 k-ds-heading-01'>
                              {viewport.isDesktop ? 'Promoción: ' : ''}
                              {promotion.name}
                            </div>
                            <div className='k-ds-m-left-sp-06 k-ds-caption-01 promotion-description'>
                              {promotion.description}
                            </div>
                          </Space>
                        </Space>
                        <Space
                          direction='horizontal'
                          className={
                            viewport.isMobile && !viewport.isSmallTablet
                              ? 'promotion-info-container promo-left-padding'
                              : 'promotion-info-container'
                          }
                        >
                          <Space
                            direction='vertical'
                            className='promotion-info k-ds-m-rigth-sp-09'
                          >
                            <div className='k-ds-m-left-sp-06 k-ds-heading-01'>
                              ID del cupón:{' '}
                            </div>
                            <div className='k-ds-m-left-sp-06 k-ds-caption-01'>
                              {promotion.promotion_code ? (
                                <Tag color='green-outline'>
                                  {promotion.promotion_code}
                                </Tag>
                              ) : (
                                <Tag color='grey'>N/A</Tag>
                              )}
                            </div>
                          </Space>
                          <Space
                            direction='vertical'
                            className='promotion-info k-ds-m-rigth-sp-09'
                          >
                            <div className='k-ds-m-left-sp-06 k-ds-heading-01'>
                              Vigencia:{' '}
                            </div>
                            <div className='k-ds-m-left-sp-06 k-ds-caption-01 k-ds-width-full'>
                              <Tag color='blue-outline'>
                                {promotion.start_date} - {promotion.end_date}
                              </Tag>
                            </div>
                          </Space>
                        </Space>
                      </Space>
                    </Space>
                  </TdResponsive>
                  <TdResponsive
                    className='chevron-details promotions-count'
                    type='toggle'
                    isCollapsed={!isExpanded}
                    dataTitle={tableHeaders.count}
                    onClick={() =>
                      setExpandedItem(isExpanded ? '' : promotion.promotion_id)
                    }
                    devices='desktop tablet mobile'
                  ></TdResponsive>
                </tr>
                <TrCollapsable
                  className='bg-white directory-container'
                  isCollapsed={!isExpanded}
                  devices='desktop tablet mobile'
                >
                  <td
                    className='campaigns-directories-td'
                    colSpan='2'
                    width='100%'
                  >
                    <CampaignDirectories directories={promotion.directories} />
                  </td>
                </TrCollapsable>
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default CampaignPromotions;
