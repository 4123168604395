import { Card } from '../../ds';
import React from 'react';
import Auth from '../../Utils/Auth/auth';

function InfoCard() {
  const { employee, stores } = Auth.userData();
  if (!employee || !stores) {
    return '';
  }

  if (stores.length > 1 && employee.employee_code === '') {
    return '';
  }

  return (
    <Card className='k-ds-width-full info-card'>
      {stores.length === 1 && (
        <div className='k-ds-m-bottom-sp-04'>
          <span className='card-body-label'>Sucursal: </span>
          <span className='card-body-information'>{stores[0].name}</span>
        </div>
      )}
      {employee.employee_code !== '' && (
        <div>
          <span className='card-body-label'>Código de empleado: </span>
          <span className='card-body-information'>
            {employee.employee_code}
          </span>
        </div>
      )}
    </Card>
  );
}

export default InfoCard;
