import { useMemo } from 'react';
import Auth from './Auth/auth';

export const useGetMerchants = () => {
  const { merchant_structure } = Auth.userData();

  const merchants = useMemo(() => {
    if (
      !merchant_structure ||
      !merchant_structure.merchants ||
      merchant_structure.merchants.length === 0
    ) {
      return [];
    }

    return merchant_structure.merchants.map(merchant => {
      return {
        label: merchant.merchant_name,
        value: merchant.merchant_id,
      };
    });
  }, []);

  return merchants;
};
