import React from 'react';
import { usePermissionContext } from '../../contexts/Role/PermissionContext';
import { isFeatureEnabled } from '../../Utils/Feature/feature_flag';
import { PropTypes } from 'prop-types';

const Restricted = ({ toModule, withScope, fallback, children }) => {
  const { getPermission } = usePermissionContext();

  const permission = getPermission(toModule);
  if (!permission) {
    if (fallback) {
      return <>{fallback}</>;
    } else {
      return null;
    }
  }

  if (withScope) {
    if (!permission.hasScope(withScope)) {
      if (fallback) {
        return <>{fallback}</>;
      } else {
        return null;
      }
    }
  }

  if (!isFeatureEnabled(toModule)) {
    return null;
  }

  return <>{children}</>;
};

Restricted.propTypes = {
  toModule: PropTypes.string.isRequired,
  withScope: PropTypes.string,
  fallback: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default Restricted;
