import React from 'react';
import styled from 'styled-components';
import KDS from '../../lib/k-ds-foundation';
import { ToastBase } from './ToastBase';
import { ReactComponent as IconClose } from './icon-close.svg';
import { ReactComponent as IconError } from './icon-error.svg';

const StyledToastError = styled(ToastBase)`
  ${KDS.setColorTokenWithFallback('border: 2px solid ', 'error')};
`;

const ToastError = ({ message, onClick, ...moreProps }) => (
  <StyledToastError {...moreProps}>
    <IconError className='toast-icon' />
    <p className='k-ds-heading-01 toast-message'>{message}</p>
    <button type='button' className='toast-close' onClick={onClick}>
      <IconClose />
    </button>
  </StyledToastError>
);

export { ToastError };
