import { datadogRum } from '@datadog/browser-rum';
import React from 'react';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import App from './App';
import { isFeatureEnabled } from './Utils/Feature/feature_flag';
import { getStage as getEnv } from './Utils/environment';
import hotjar from './Utils/hotjar';
import './Utils/i18n/i18n';
import './index.scss';
import AmplitudeInitializer from './services/analytics/amplitude/AmplitudeInitializer';

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APP_ID,
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  site: process.env.REACT_APP_DATADOG_SITE,
  service: process.env.REACT_APP_DATADOG_SERVICE,
  env: getEnv(),
  // Specify a version number to identify the deployed version of your application in Datadog
  version: '1.0.0',
  sampleRate: 100,
  premiumSampleRate: 0,
  trackInteractions: true,
});

datadogRum.stopSessionReplayRecording();

if (isFeatureEnabled('hotjar')) {
  hotjar(
    process.env.REACT_APP_PRODUCTION_HOTJAR_ID,
    process.env.REACT_APP_PRODUCTION_HOTJAR_SV
  );
}

AmplitudeInitializer().run();

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};
TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
