import PropTypes from 'prop-types';
import React from 'react';
import BadgeStatus from '../../BadgeStatus/BadgeStatus';
import { ReactComponent as DownChevronIcon } from '../res/chevron-down.svg';
import { ReactComponent as UpChevronIcon } from '../res/chevron-up.svg';
import { ReactComponent as DeleteIcon } from '../res/delete-icon.svg';

const HeaderCard = props => {
  const {
    name,
    isExpanded,
    onClick,
    onRemove,
    isCompleted = false,
    isDuplicated = false,
  } = props;
  return (
    <div className='box-store-container'>
      <p className='k-ds-heading-05'>{name}</p>
      <div className='box-info'>
        <BadgeStatus
          isInfoCompleted={isCompleted}
          isInfoDuplicated={isDuplicated}
        />
        <div className='box-actions'>
          <DeleteIcon onClick={onRemove} />
          {isExpanded ? (
            <UpChevronIcon onClick={onClick} />
          ) : (
            <DownChevronIcon onClick={onClick} />
          )}
        </div>
      </div>
    </div>
  );
};

HeaderCard.propTypes = {
  name: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isCompleted: PropTypes.bool,
  isDuplicated: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default HeaderCard;
