import React, { useState, useEffect } from 'react';
import './LogoUpload.scss';
import GetBackSection from '../GetBackSection';
import { Space, Button } from '../../ds';
import PropTypes from 'prop-types';
import { ReactComponent as CheckLogo } from './check-logo.svg';
import { ReactComponent as CancelLogo } from './cancel-logo.svg';
import { ReactComponent as ExampleLogo } from './example-logo.svg';
import { ReactComponent as PixeledLogo } from './pixel-logo.svg';
import { ReactComponent as BackgroundLogo } from './background-logo.svg';
import { ReactComponent as ContrastLogo } from './contrast-logo.svg';
import { ReactComponent as FileIcon } from './file-icon.svg';
import { ReactComponent as PreviewIcon } from './preview-icon.svg';
import { ReactComponent as DeleteIcon } from './delete-icon.svg';
import { useHistory } from 'react-router-dom';
import ImagePreviewModal from '../ImagePreviewModal/ImagePreviewModal';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import ErrorModal from '../ErrorModal/ErrorModal';
import { useLoaderContext } from '../../contexts/Loader/LoaderContext';
import Auth from '../../Utils/Auth/auth';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import {
  getMerchantImages,
  createMerchantImage,
  updateMerchantImage,
  deleteMerchantImage,
} from '../../services/Application';
import { useParams } from 'react-router-dom';
import Header from '../Header';

let colorInterval = undefined;
let monoInterval = undefined;
const LogoUpload = () => {
  let history = useHistory();
  const { setIsLoaderOpen } = useLoaderContext();
  const viewport = useDeviceContextViewport();
  const { merchant } = Auth.userData();
  if (!merchant) {
    return '';
  }
  const merchantId = merchant.merchant_id;
  const { type } = useParams();
  const vectorLabel = 'vector';
  const imageLabel = 'image';
  const uploadType = type === imageLabel ? type : vectorLabel;

  const [previewImage, setPreviewImage] = useState(
    require('./example-image.svg').default
  );
  const [imagePreviewModalState, setImagePreviewModalState] =
    React.useState(false);
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const [errorModalState, setErrorModalState] = React.useState(false);
  const [confirmationModalState, setConfirmationModalState] =
    React.useState(false);
  const [modalInfo, setModalInfo] = React.useState({
    title: undefined,
    description: undefined,
  });

  const [colorRunning, setColorRunning] = useState(false);
  const [colorProgress, setColorProgress] = useState(0);
  const [colorProgressBarInterval, setColorProgressInterval] = useState(1);
  const [monoRunning, setMonoRunning] = useState(false);
  const [monoProgress, setMonoProgress] = useState(0);
  const [monoProgressBarInterval, setMonoProgressInterval] = useState(1);

  const MonoType = 'Mono';
  const ColorType = 'Color';

  const emptyFile = {
    name: undefined,
    file: undefined,
    src: undefined,
  };
  const progressBarMs = 10;
  const hundredPercent = 100;
  const maxImageWidth = 1000;
  const maxImageHeight = 1000;
  const maxImageSize = 2e6;
  const minProgressBarInterval = 1;

  const [monoLogoOriginal, setMonoLogoOriginal] = useState(emptyFile);
  const [colorLogoOriginal, setColorLogoOriginal] = useState(emptyFile);
  const [monoLogoSelected, setMonoLogoSelected] = useState(emptyFile);
  const [colorLogoSelected, setColorLogoSelected] = useState(emptyFile);

  const allowedFileFormat = uploadType === vectorLabel ? '.svg' : '.jpg o .png';

  useEffect(() => {
    setIsLoaderOpen(true);
    getMerchantImages(merchantId)
      .then(response => {
        if (response.data.monocromatic) {
          setMonoLogoOriginal({
            ...response.data.monocromatic,
            file: undefined,
          });
          setMonoLogoSelected({
            ...response.data.monocromatic,
            file: undefined,
          });
          setMonoProgress(100);
        }
        if (response.data.color) {
          setColorLogoOriginal({
            ...response.data.color,
            file: undefined,
          });
          setColorLogoSelected({
            ...response.data.color,
            file: undefined,
          });
          setColorProgress(100);
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoaderOpen(false));
  }, []);

  useEffect(() => {
    if (colorRunning) {
      colorInterval = setInterval(() => {
        setColorProgress(prev => prev + colorProgressBarInterval);
      }, progressBarMs);
    } else {
      clearInterval(colorInterval);
    }
    if (monoRunning) {
      monoInterval = setInterval(() => {
        setMonoProgress(prev => prev + monoProgressBarInterval);
      }, progressBarMs);
    } else {
      clearInterval(monoInterval);
    }
  }, [colorRunning, monoRunning]);

  useEffect(() => {
    if (colorProgress >= hundredPercent) {
      setColorRunning(false);
      clearInterval(colorInterval);
    }
    if (monoProgress >= hundredPercent) {
      setMonoRunning(false);
      clearInterval(monoInterval);
    }
  }, [colorProgress, monoProgress]);

  function RestartInterval(logoType) {
    if (logoType === MonoType) {
      setMonoProgress(0);
      setMonoRunning(false);
      clearInterval(monoInterval);
    } else if (logoType === ColorType) {
      setColorProgress(0);
      setColorRunning(false);
      clearInterval(colorInterval);
    }
  }

  function closeImagePreviewModal() {
    setImagePreviewModalState(false);
  }

  function SelectFileButton(props) {
    return (
      <Button
        type='outline'
        size='small'
        className='file-button'
        disabled={monoRunning || colorRunning}
        onClick={() => document.getElementById(props.inputId).click()}
      >
        {props.label}
      </Button>
    );
  }

  SelectFileButton.propTypes = {
    inputId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  };

  function UpdateProgressBar(logoType, fileSize) {
    RestartInterval(logoType);
    let progressBarInterval =
      hundredPercent / fileSize > minProgressBarInterval
        ? hundredPercent / fileSize
        : minProgressBarInterval;
    if (logoType === ColorType) {
      setColorRunning(true);
      setColorProgressInterval(progressBarInterval);
    } else if (logoType === MonoType) {
      setMonoRunning(true);
      setMonoProgressInterval(progressBarInterval);
    }
  }

  async function UploadImage(e, logoType) {
    let file = e.target.files[0];

    if (file) {
      if (file.size > maxImageSize) {
        setModalInfo({
          title: 'Peso máximo excedido',
          description:
            'El peso del archivo seleccionado no está permitido, debe de ser máximo 2MB por archivo. Inténtalo de nuevo.',
        });
        RestartInterval(logoType);
        return setErrorModalState(true);
      }
      UpdateProgressBar(logoType, file.size);

      const allowedExtensions = /\.(svg|jpg|png)$/i;
      if (!allowedExtensions.exec(file.name)) {
        setModalInfo({
          title: 'Formato no permitido',
          description:
            'El formato que has seleccionado no está permitido. Inténtalo de nuevo.',
        });
        if (logoType === MonoType) setMonoLogoSelected(emptyFile);
        else if (logoType === ColorType) setColorLogoSelected(emptyFile);
        RestartInterval(logoType);
        return setErrorModalState(true);
      }

      (async () => {
        const imageDimensions = await imageSize(file);

        if (
          imageDimensions &&
          (imageDimensions.height > maxImageHeight ||
            imageDimensions.width > maxImageWidth)
        ) {
          setModalInfo({
            title: 'Tamaño no permitido',
            description:
              'El tamaño de tu archivo es muy pequeño, debe tener como mínimo 1000 X 1000 px.',
          });
          if (logoType === MonoType) setMonoLogoSelected(emptyFile);
          else if (logoType === ColorType) setColorLogoSelected(emptyFile);
          RestartInterval(logoType);
          return setErrorModalState(true);
        }
      })();

      let selectedFile = {
        name: file.name,
        file: file,
        src: URL.createObjectURL(file),
      };

      if (logoType === MonoType) {
        setMonoLogoSelected(selectedFile);
      } else if (logoType === ColorType) {
        setColorLogoSelected(selectedFile);
      }
    }
  }

  function imageSize(file) {
    const promise = new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        var image = new Image();
        image.src = reader.result || e.target.result;
        image.onload = function () {
          const width = image.naturalWidth;
          const height = image.naturalHeight;
          resolve({ width, height });
        };
        image.onerror = reject;
      };
    });

    return promise;
  }

  async function HandleSubmit() {
    setIsLoaderOpen(true);
    try {
      if (
        monoLogoOriginal !== monoLogoSelected &&
        monoLogoSelected.file !== undefined &&
        monoLogoOriginal.src === undefined
      ) {
        createMerchantImage(monoLogoSelected);
      } else if (
        monoLogoOriginal !== monoLogoSelected &&
        monoLogoSelected.file !== undefined &&
        monoLogoOriginal.src !== undefined
      ) {
        updateMerchantImage(monoLogoSelected);
      } else if (
        monoLogoOriginal !== monoLogoSelected &&
        monoLogoSelected === undefined
      ) {
        deleteMerchantImage(monoLogoOriginal);
      }

      if (
        colorLogoOriginal !== colorLogoSelected &&
        colorLogoSelected.file !== undefined &&
        colorLogoOriginal.src === undefined
      ) {
        createMerchantImage(colorLogoSelected);
      } else if (
        colorLogoOriginal !== colorLogoSelected &&
        colorLogoSelected.file !== undefined &&
        colorLogoOriginal.src !== undefined
      ) {
        updateMerchantImage(colorLogoSelected);
      } else if (
        colorLogoOriginal !== colorLogoSelected &&
        colorLogoSelected === undefined
      ) {
        deleteMerchantImage(colorLogoOriginal);
      }
      setModalInfo({
        title: '¡Se guardaron los logotipos con éxito!',
        description:
          'Tus logotipos se verán reflejados dentro del portal en un lapso de 24 a 48 horas.',
      });
      setIsLoaderOpen(false);
      setConfirmationModalState(true);
    } catch (error) {
      setErrorModalState(true);
      setIsLoaderOpen(false);
      console.log(error);
    }
  }

  function handleConfirm() {
    setConfirmationModalState(false);
    setIsLoaderOpen(false);
    history.push('/logos');
  }

  function HandlePreview(logoType) {
    if (logoType === MonoType) {
      setPreviewImage(monoLogoSelected);
    } else if (logoType === ColorType) {
      setPreviewImage(colorLogoSelected);
    }
    setImagePreviewModalState(true);
  }

  function HandleDelete(logoType) {
    RestartInterval(logoType);
    setMonoLogoSelected(emptyFile);
  }

  function closeErrorModal() {
    setErrorModalState(false);
  }

  function HandleDisabled() {
    if (
      (!ShallowEqual(monoLogoOriginal, monoLogoSelected) ||
        !ShallowEqual(colorLogoOriginal, colorLogoSelected)) &&
      !monoRunning &&
      !colorRunning
    )
      return false;
    return true;
  }

  function ShallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
    return true;
  }

  return (
    <div className={!viewport.isDesktop ? 'k-ds-m-top-sp-07' : ''}>
      <GetBackSection render />
      <Space className='logo-upload-container' direction='vertical'>
        <Header title='Agregar logotipo' />
        <Space
          justify='space-between'
          className='k-ds-width-full k-ds-text-color-primary logo-upload'
        >
          <Space
            direction={
              viewport.isMobile || viewport.isSmallTablet
                ? 'vertical'
                : 'horizontal'
            }
          >
            <Space className='k-ds-width-full' direction='vertical'>
              <div className='instructions k-ds-m-bottom-sp-09'>
                <div className='k-ds-heading-03 k-ds-m-bottom-sp-05'>
                  Instrucciones
                </div>
                <div className='k-ds-subheading-02 instructions-subheading k-ds-m-bottom-sp-08'>
                  Tu negocio necesita tener dos estilos de logotipo para la
                  página web de Kueski Pay. Necesitamos que subas{' '}
                  <span>al menos uno</span> de los siguientes archivos:
                </div>
                <div className='k-ds-subheading-02 instructions-subheading k-ds-m-bottom-sp-03'>
                  1. Logotipo <span>a color</span>.
                </div>
                <div className='k-ds-subheading-01 instructions-subheading k-ds-m-bottom-sp-08'>
                  Formatos permitidos: {allowedFileFormat} (Peso máximo por
                  archivo de 2 MB).
                </div>
                {!colorLogoSelected.src ? (
                  <>
                    <input
                      type='file'
                      id='colorFile'
                      className='file-input'
                      accept={
                        uploadType === vectorLabel ? '.svg' : '.jpg, .png'
                      }
                      onChange={e => UploadImage(e, ColorType)}
                    />
                    <SelectFileButton
                      label='Seleccionar archivo'
                      inputId='colorFile'
                    />
                  </>
                ) : (
                  <div className='file-card-preview'>
                    <div className='actions-container'>
                      <FileIcon className='k-ds-m-sp-06' />
                      <span className='k-ds-caption-01 k-ds-m-top-sp-06 selected-name'>
                        {colorLogoSelected.name}
                      </span>
                    </div>
                    <div className='actions-container'>
                      {colorLogoSelected && colorProgress < hundredPercent && (
                        <progress
                          id='progressBarColor'
                          value={colorProgress}
                          max={hundredPercent}
                        ></progress>
                      )}
                    </div>
                    <div className='actions-container'>
                      {colorProgress >= hundredPercent && (
                        <>
                          <Button
                            type='link'
                            size='small'
                            onClick={() => HandlePreview(ColorType)}
                          >
                            <PreviewIcon />
                          </Button>
                          <Button
                            type='link'
                            size='small'
                            onClick={() => setColorLogoSelected(emptyFile)}
                          >
                            <DeleteIcon />
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                )}
                <div className='k-ds-subheading-02 instructions-subheading k-ds-m-bottom-sp-03 k-ds-m-top-sp-08'>
                  2. Logotipo <span>en negro o monocromático</span>.
                </div>
                <div className='k-ds-subheading-01 instructions-subheading k-ds-m-bottom-sp-08'>
                  Formatos permitidos: {allowedFileFormat} (Peso máximo por
                  archivo de 2 MB).
                </div>
                {!monoLogoSelected.src ? (
                  <>
                    <input
                      type='file'
                      id='MonoFile'
                      className='file-input'
                      accept={
                        uploadType === vectorLabel ? '.svg' : '.jpg, .png'
                      }
                      onChange={e => UploadImage(e, MonoType)}
                    />
                    <SelectFileButton
                      label='Seleccionar archivo'
                      inputId='MonoFile'
                    />
                  </>
                ) : (
                  <div className='file-card-preview'>
                    <div className='actions-container'>
                      <FileIcon className='k-ds-m-sp-06' />
                      <span className='k-ds-caption-01 k-ds-m-top-sp-06'>
                        {monoLogoSelected.name}
                      </span>
                    </div>
                    <div className='actions-container'>
                      {monoLogoSelected && monoProgress < hundredPercent && (
                        <progress
                          id='progressBarMono'
                          value={monoProgress}
                          max={hundredPercent}
                        ></progress>
                      )}
                    </div>
                    <div className='actions-container'>
                      {monoProgress >= hundredPercent && (
                        <>
                          <Button
                            type='link'
                            size='small'
                            onClick={() => HandlePreview(MonoType)}
                          >
                            <PreviewIcon />
                          </Button>
                          <Button
                            type='link'
                            size='small'
                            onClick={() => HandleDelete(MonoType)}
                          >
                            <DeleteIcon />
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {!viewport.isMobile && (
                <Space className='k-ds-width-full'>
                  <div className='k-ds-m-top-sp-07'>
                    <Button
                      type='primary-alternate'
                      className='k-ds-m-rigth-sp-02'
                      horizontalPadding='2.5rem'
                      verticalPadding='1rem'
                      size='small'
                      onClick={() => HandleSubmit()}
                      disabled={HandleDisabled()}
                    >
                      Guardar
                    </Button>
                    <Button
                      type='simple'
                      size='small'
                      onClick={() => setOpenConfirmModal(true)}
                    >
                      Cancelar
                    </Button>
                  </div>
                </Space>
              )}
            </Space>
            <Space>
              <Space
                className='recomendations k-ds-width-full'
                direction='vertical'
              >
                <div className='k-ds-heading-03 k-ds-m-bottom-sp-05'>
                  Recomendaciones
                </div>
                <Space direction='vertical'>
                  <div className='k-ds-subheading-02 k-ds-m-bottom-sp-07'>
                    Tu logotipo debe verse sin fondo, claro y bien definido.
                  </div>
                  <Space className='k-ds-m-bottom-sp-07 logo-correct'>
                    <CheckLogo className='k-ds-m-right-sp-07' />
                    <ExampleLogo></ExampleLogo>
                  </Space>
                  <div className='k-ds-subheading-02 k-ds-m-top-sp-07'>
                    Evita lo siguiente:
                  </div>
                  <Space
                    justify='space-between'
                    className='not-allowed-logos-container k-ds-width-full'
                    direction={
                      viewport.isMobile || viewport.isSmallTablet
                        ? 'horizontal'
                        : 'vertical'
                    }
                  >
                    <Space
                      justify='space-between'
                      className='k-ds-m-top-sp-07 example-container k-ds-width-full'
                      direction={
                        viewport.isMobile || viewport.isSmallTablet
                          ? 'horizontal'
                          : 'vertical'
                      }
                    >
                      <Space className='cancel-logo-container'>
                        <Space>
                          <CancelLogo className='k-ds-m-top-sp-08 k-ds-m-right-sp-07 cancel-logo' />
                        </Space>
                        <Space direction='vertical'>
                          <PixeledLogo className='example-logo' />
                          {!viewport.isMobile && !viewport.isSmallTablet && (
                            <Space
                              justify='space-around'
                              className='k-ds-subheading-02 k-ds-m-top-sp-04 k-ds-m-bottom-sp-05'
                            >
                              Imágenes pixeladas
                            </Space>
                          )}
                        </Space>
                      </Space>
                      <Space>
                        {!viewport.isMobile && !viewport.isSmallTablet && (
                          <Space>
                            <CancelLogo className='k-ds-m-top-sp-08 k-ds-m-right-sp-07' />
                          </Space>
                        )}
                        <Space direction='vertical'>
                          <BackgroundLogo className='example-logo' />
                          {!viewport.isMobile && !viewport.isSmallTablet && (
                            <Space
                              justify='space-around'
                              className='k-ds-subheading-02 k-ds-m-top-sp-04 k-ds-m-bottom-sp-05'
                            >
                              Con fondo
                            </Space>
                          )}
                        </Space>
                      </Space>
                      <Space>
                        {!viewport.isMobile && !viewport.isSmallTablet && (
                          <Space>
                            <CancelLogo className='k-ds-m-top-sp-08 k-ds-m-right-sp-07' />
                          </Space>
                        )}
                        <Space direction='vertical'>
                          <ContrastLogo className='example-logo' />
                          {!viewport.isMobile && !viewport.isSmallTablet && (
                            <Space
                              justify='space-around'
                              className='k-ds-subheading-02 k-ds-m-top-sp-04 k-ds-m-bottom-sp-05'
                            >
                              Bajo contraste
                            </Space>
                          )}
                        </Space>
                      </Space>
                    </Space>
                  </Space>
                  {(viewport.isMobile || viewport.isSmallTablet) && (
                    <Space className='k-ds-subheading-02 k-ds-m-top-sp-07 k-ds-m-left-sp-06 k-ds-width-full'>
                      <div className='k-ds-subheading-02 k-ds-p-left-sp-05 k-ds-m-left-sp-09 k-ds-m-top-sp-05'>
                        Imágenes pixeladas, con fondo o bajo contraste.
                      </div>
                    </Space>
                  )}
                </Space>
              </Space>
            </Space>
            {viewport.isMobile && (
              <Space className='k-ds-width-full'>
                <div className='k-ds-m-top-sp-07'>
                  <Button
                    type='primary-alternate'
                    className='k-ds-m-rigth-sp-02'
                    horizontalPadding='2.5rem'
                    verticalPadding='1rem'
                    size='small'
                    onClick={() => HandleSubmit()}
                    disabled={HandleDisabled()}
                  >
                    Guardar
                  </Button>
                  <Button
                    type='simple'
                    size='small'
                    onClick={() => setOpenConfirmModal(true)}
                  >
                    Cancelar
                  </Button>
                </div>
              </Space>
            )}
          </Space>
        </Space>
      </Space>
      <ErrorModal
        defaultOpened={confirmationModalState}
        close={handleConfirm}
        heading={modalInfo.title}
        description={modalInfo.description}
      />
      <ErrorModal
        defaultOpened={errorModalState}
        close={closeErrorModal}
        heading={modalInfo.title}
        description={modalInfo.description}
      />
      <ConfirmModal
        isOpen={openConfirmModal}
        mode={'Warning'}
        header={'¿Seguro que deseas cancelar?'}
        content='Los archivos cargados se perderán.'
        onConfirm={() => history.goBack()}
        setIsOpen={setOpenConfirmModal}
      />
      <ImagePreviewModal
        defaultOpened={imagePreviewModalState}
        close={closeImagePreviewModal}
        src={previewImage.src}
        title={previewImage.name}
      />
    </div>
  );
};

export default LogoUpload;
