import {
  REFERENCE_AUTOFILL_LIST,
  monthNames,
  productionPaymentValidationAllowedAccessList,
  stagingPaymentValidationAllowedAccessList,
  whiteListToallowedProductTab, QR_V2_ACCESS_LIST,
} from './constants';
import { getStage } from './environment';
import { keysToSnakeCase } from './formats';

export const paymentValidationAccessList =
  getStage() === 'production'
    ? productionPaymentValidationAllowedAccessList
    : stagingPaymentValidationAllowedAccessList;

export const allowedMerchantsToViewProductTab =
  whiteListToallowedProductTab[getStage()];

export const allowedMerchantFillDateReference =
  REFERENCE_AUTOFILL_LIST[getStage()];

export const allowedQrv2Store =
  QR_V2_ACCESS_LIST[getStage()];

export const getCurrentDateTime = ({ setMinutes, setSeconds }) => {
  let currentDate = new Date();
  let day = currentDate.getDate();
  let month = monthNames[currentDate.getMonth()];
  let year = currentDate.getFullYear();
  let hours = currentDate.getHours();
  let minutes = currentDate.getMinutes();
  let seconds = currentDate.getSeconds();

  day = day < 10 ? '0' + day : day;
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  let formattedDate = `${day}-${month}-${year} ${hours}`;

  if (setMinutes) {
    formattedDate += `:${minutes}`;
  }

  if (setSeconds) {
    formattedDate += `:${seconds}`;
  }

  return formattedDate;
};

export const serializeParameters = params => {
  if (!params) {
    return '';
  }

  const parameters = keysToSnakeCase(params);
  const str = Object.keys(parameters).reduce((accumulator, currentValue) => {
    if (accumulator === '') {
      return `${currentValue}=${parameters[currentValue]}`;
    }

    return `${accumulator}&${currentValue}=${parameters[currentValue]}`;
  }, '');

  return str ? `?${str}` : '';
};

export const cleanEmptyValues = obj => {
  if (obj === undefined || obj === null || Object.keys(obj).length === 0) {
    return undefined;
  }

  if (Array.isArray(obj)) {
    return obj
      .map(v => (v && typeof v === 'object' ? cleanEmptyValues(v) : v))
      .filter(v => !(v == null));
  } else {
    return Object.entries(Object.assign({}, obj))
      .map(([k, v]) => [
        k,
        v && typeof v === 'object' ? cleanEmptyValues(v) : v,
      ])
      .reduce(
        (a, [k, v]) =>
          v == null || v === undefined || v === '' ? a : ((a[k] = v), a),
        {}
      );
  }
};

export const isEmptyObject = obj => {
  const result = cleanEmptyValues(obj);

  return result === undefined || Object.keys(result).length === 0;
};
