import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Space, TextInput } from '../../ds';
import './tokenValidationModal.scss';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { hyphenEveryFourChars } from '../../Utils/formats';
import { validateNumerical } from '../../Utils/formValidations';
import { ReactComponent as CloseIcon } from './close-icon.svg';

function TokenValidationModal(props) {
  const { close, isOpen, onChange, onValidate, paymentData } = props;
  const viewport = useDeviceContextViewport();
  const isMobileOnly = viewport.isMobile && !viewport.isSmallTablet;
  return (
    isOpen && (
      <Modal
        className={`${isMobileOnly ? 'mobile-modal' : ''} token-validation-modal`}
        theme='dark'
        cardType='secondary'
        onClose={handleClose}
        {...{ isOpen: isOpen }}
      >
        <div className='k-ds-width-full'>
          <Space
            direction='horizontal'
            justify='end'
            className='k-ds-width-full'
          >
            <Button type='link' size='small' onClick={handleClose}>
              <CloseIcon />
            </Button>
          </Space>
          <Space direction='vertical'>
            <div className='k-ds-heading-05 k-ds-m-bottom-sp-05'>
              Antes de terminar
            </div>
            <div className='k-ds-body-02 k-ds-m-bottom-sp-08 description'>
              Solicita al cliente el código de seguridad que recibió por mensaje
              de texto e ingrésalo.
            </div>
            <Space
              className='token-container k-ds-m-bottom-sp-08'
              justify='space-around'
            >
              <div className='k-ds-heading-03'>
                Id de pago: {hyphenEveryFourChars(paymentData.paymentId)}
              </div>
            </Space>
            <Space className='k-ds-m-bottom-sp-08'>
              <TextInput
                className='text-input k-ds-width-full'
                errorMessage={paymentData.tokenErrors}
                label='Ingresa el código de seguridad:'
                onChange={setTokenValue}
                onBlur={setTokenValue}
                placeholder='Escribe el token aquí'
                type='outline'
                name='token_validation'
                value={paymentData.token}
              />
            </Space>
            <Space justify='end'>
              <Button
                className='validate-button'
                disabled={!paymentData.token || paymentData.token.length < 3}
                onClick={handleClick}
              >
                Validar
              </Button>
            </Space>
          </Space>
        </div>
      </Modal>
    )
  );

  function setTokenValue(e) {
    const value = e.target.value;
    if (value === '' || validateNumerical(value)) {
      if (value.length > 3) onChange(value.substring(0, 3));
      else onChange(value);
    }
  }

  function handleClose() {
    close();
  }

  function handleClick() {
    onValidate();
  }
}

TokenValidationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  paymentData: PropTypes.object.isRequired,
};

export default TokenValidationModal;
