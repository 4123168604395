import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  ACCOUNT_MANAGER,
  GENERAL_ADMIN,
  STORE_ADMIN,
} from '../../Utils/constants';
import { useLoaderContext } from '../../contexts/Loader/LoaderContext';
import { useToastContext } from '../../contexts/Toast/ToastContext';
import { Button, Space } from '../../ds';
import { useStore } from '../../services/store';
import { Select } from '../DS/Select';
import TagComponent from './BusinesComponents/TagStore';
import './BusinessSection.scss';
import ModalAddStore from './ModalAddStore';
import { MutateUserContext } from './MutateUser';
import { ReactComponent as PlusIcon } from './res/plus-icon.svg';

const BusinessSection = props => {
  const { formData, setFormData, role } = props;
  const {
    mutateUserStore: { filteredStores },
  } = useStore();

  const { roles } = useContext(MutateUserContext);
  const { isLoaderOpen } = useLoaderContext();
  const toast = useToastContext();

  const [isOpen, setIsOpen] = useState(false);

  const roleFound = roles.filter(element => element.role_id === formData.role);

  const isGeneralAdminByAMRole = () => {
    if (roleFound.length === 0) return false;
    return (
      roleFound[0].role_name === GENERAL_ADMIN && role.name === ACCOUNT_MANAGER
    );
  };

  const showAddMoreStore = useMemo(() => {
    if (roleFound.length === 0) return false;
    return roleFound[0].role_name === STORE_ADMIN;
  }, [roleFound]);

  useEffect(() => {
    if (!roles.length) return;

    if (!showAddMoreStore) {
      setFormData({ ...formData, stores: [] });
      return;
    }
  }, [showAddMoreStore]);

  const setOptionSelected = optionVal =>
    setFormData({ ...formData, store: optionVal });

  const handleModal = () => setIsOpen(!isOpen);

  const addMoreStore = () => {
    if (formData.store === '') {
      toast('Necesitas primero seleccionar una sucursal asignada', 'warning');
      return;
    }

    handleModal();
  };

  const removeStore = index => {
    const arrayRemove = (arr, value) => {
      return arr.filter(function (ele) {
        return ele != value;
      });
    };

    setFormData(prev => ({
      ...prev,
      stores: arrayRemove(formData.stores, index),
    }));
  };

  if (isLoaderOpen || isGeneralAdminByAMRole()) return null;

  return (
    <>
      <div className='k-ds-heading-04 k-ds-m-top-sp-09 k-ds-m-bottom-sp-07'>
        Datos para el negocio
      </div>
      <Space
        direction={props.viewport.isMobile ? 'vertical' : 'horizontal'}
        justifyContent='space-between'
        className='field-container'
      >
        <Select
          required
          placeholder='Seleccionar'
          label='Sucursal asignada'
          className='text-field'
          value={formData.store}
          onChange={v => setOptionSelected(v)}
          id='store'
          name='store'
          options={filteredStores}
          type='text'
        />

        {showAddMoreStore ? (
          <div className='modal-add-store text-field k-ds-text-color-interactive-primary k-ds-heading-02'>
            <Button
              type='link'
              className='button-add-store'
              onClick={addMoreStore}
            >
              <PlusIcon />
              Agregar otra sucursal
            </Button>
          </div>
        ) : null}
      </Space>

      {showAddMoreStore && (
        <div className='rowStores'>
          {formData.stores.map((item, index) => (
            <TagComponent
              key={index}
              storeId={item}
              merchantStores={filteredStores}
              remove={() => removeStore(item)}
            />
          ))}
        </div>
      )}

      {isOpen && (
        <ModalAddStore
          storesSaved={formData.stores}
          merchantStores={filteredStores}
          closeModal={handleModal}
        />
      )}
    </>
  );
};

BusinessSection.propTypes = {
  viewport: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  formDataErrors: PropTypes.object.isRequired,
  setFormDataErrors: PropTypes.func.isRequired,
  merchantId: PropTypes.string.isRequired,
  role: PropTypes.object.isRequired,
};

export default observer(BusinessSection);
