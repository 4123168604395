import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Space } from '../../../../ds';
import {
  StyledNotFoundLabel,
  StyledFormatGroupLabel,
  StyledSelectOutline,
  StyledButton,
  HiddenDivInput,
} from './styled';
import { ReactComponent as IconArrowDown } from './icon-arrow-down.svg';
import RadioButton from './../../../RadioButton';
import Timer from '../../../../Utils/Timer';

const formatSchedules = { start: 'd \'de\' MMMM', end: 'd \'de\' MMMM \'del\' y' };
const defaultFormat = 'dd/MM/yyyy';

// Gneral Focus Hook
const UseFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};

const SelectShedule = ({
  className = '',
  inputClassName = '',
  name,
  label,
  placeholder = 'Seleccione una opción',
  onChange,
  onBlur,
  disabled,
  errorMessage,
  helper,
  groupOptions = [],
  value = {
    rangeStart: '',
    rangeEnd: '',
    paymentScheduleId: 0,
  },
  ...moreProps
}) => {
  const hasError = Boolean(errorMessage);
  const { required } = moreProps;
  const props = { ...moreProps, hasError };
  const [isActive, setIsActive] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [ignoreBlur, setIgnoreBlur] = useState(false);
  const [inputHiddenRef, setInputHiddenFocus] = UseFocus();
  const [selected, setSelected] = useState(false);
  const [option, setOption] = useState({
    rangeStart: '',
    rangeEnd: '',
    paymentScheduleId: 0,
  });

  const onSelectClick = () => {
    if (disabled) return;

    setIsActive(!isActive);
    setInputHiddenFocus();
  };

  const onOptionClick = () => {
    if (onChange) {
      onChange(option);
    }
    setSelected(true);
    setIsActive(false);
    setIgnoreBlur(false);
  };

  const handleBlur = () => {
    if (ignoreBlur) return;
  };

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, []);

  useEffect(() => {
    if (!isActive && !isFirstRender) {
      if (onBlur) onBlur();
    }
  }, [isActive]);

  useEffect(() => {
    setOption(value);
  }, [value.paymentScheduleId]);

  const showClass = isActive && !disabled ? 'show' : '';

  return (
    <Space size='x-large' direction='vertical' className={className} {...props}>
      {label && (
        <Space
          size='small'
          justify='space-between'
          className='k-ds-width-full'
          direction='horizontal'
        >
          <label
            className='k-ds-body-01 k-ds-text-color-primary'
            htmlFor={name}
          >
            {label}
            {required ? (
              <span className='k-ds-text-color-error'>{' *'}</span>
            ) : null}
          </label>
        </Space>
      )}
      <StyledSelectOutline
        className={`${inputClassName} k-ds-body-01`}
        hasError={hasError}
        disabled={disabled}
        showPlaceholder={!option || Object.keys(option).length === 0}
        active={isActive}
      >
        <HiddenDivInput ref={inputHiddenRef} type='radio' onBlur={handleBlur} />
        <Space
          size='medium'
          justify='space-between'
          className={`k-ds-width-full selector ${selected ? 'selected' : ''}`}
          onClick={onSelectClick}
          direction='horizontal'
          align='center'
          aria-hidden
        >
          {option.paymentScheduleId === 0 ? (
            <div className='k-ds-body-02 k-ds-text-color-primary'>
              {placeholder}
            </div>
          ) : null}
          {option.paymentScheduleId > 0 ? (
            <div className='control'>
              <span className='k-ds-heading-02'>Periodo:</span>
              <span className='k-ds-body-02 k-ds-m-left-sp-02'>
                {`${Timer().toLocal(option.rangeStart, defaultFormat)} - ${Timer().toLocal(option.rangeEnd, defaultFormat)}`}
              </span>
            </div>
          ) : null}
          <IconArrowDown className={`arrow-icon ${showClass}`} />
        </Space>
        <div className={`options-group ${showClass}`}>
          {groupOptions.length === 0 ? (
            <div className='group-option'>
              <StyledNotFoundLabel className='k-ds-body-02'>
                No hay resultados.
              </StyledNotFoundLabel>
            </div>
          ) : null}
          {groupOptions.map(item => (
            <div className='group-option' key={item.month}>
              <StyledFormatGroupLabel className='k-ds-heading-02'>
                {item.month}
              </StyledFormatGroupLabel>
              <div>
                {item.dateSchedules.map((element, index) => {
                  return (
                    <div
                      onClick={() => setOption(element)}
                      key={element.label + '_' + index}
                      className='option'
                    >
                      <RadioButton
                        className='radio-button'
                        id={`${name || ''}_${element.paymentScheduleId}`}
                        name={name}
                        value={
                          element && element.paymentScheduleId
                            ? element.paymentScheduleId.toString()
                            : ''
                        }
                        checked={
                          option &&
                          option.paymentScheduleId === element.paymentScheduleId
                        }
                        onChange={() => null}
                      />
                      <label
                        className='k-ds-body-02 k-ds-text-color-primary'
                        htmlFor={element.paymentScheduleId}
                        aria-hidden='true'
                      >
                        {`${Timer().toLocal(element.rangeStart, formatSchedules.start)} - ${Timer().toLocal(element.rangeEnd, formatSchedules.end)}`}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
          {groupOptions && groupOptions.length > 0 ? (
            <div className='sticky-btn'>
              <StyledButton
                className='k-ds-m-top-sp-03'
                type='primary-alternate'
                horizontalPadding='1.125rem'
                verticalPadding='1rem'
                size='small'
                onMouseDown={() => setIgnoreBlur(true)}
                onClick={onOptionClick}
              >
                Aplicar
              </StyledButton>
            </div>
          ) : null}
        </div>
        {helper ? (
          <div className='helper k-ds-subheading-01'>{helper}</div>
        ) : null}
        {hasError ? (
          <span className='helper-error k-ds-caption-01'>{errorMessage}</span>
        ) : null}
      </StyledSelectOutline>
    </Space>
  );
};

SelectShedule.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  helper: PropTypes.string,
  controlLabel: PropTypes.any,
  groupOptions: PropTypes.arrayOf(
    PropTypes.shape({
      month: PropTypes.string,
      dateSchedules: PropTypes.arrayOf(
        PropTypes.shape({
          rangeStart: PropTypes.string,
          rangeEnd: PropTypes.string,
          paymentScheduleId: PropTypes.number,
        })
      ),
    })
  ),
  value: PropTypes.shape({
    rangeStart: PropTypes.string,
    rangeEnd: PropTypes.string,
    paymentScheduleId: PropTypes.number,
  }),
  inputClassName: PropTypes.string,
};

export default SelectShedule;
