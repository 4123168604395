import React from 'react';
import { TableB2B } from './TableB2B';
import { TableDefault } from './TableDefault';

const defaultSize = 'medium';
const defaultType = 'default';

const MAP_PADDING_SIZE = {
  small: 'spacing-03',
  medium: 'spacing-04',
  large: 'spacing-05',
};

const MAP_TABLE_TYPE = {
  default: TableDefault,
  b2b: TableB2B,
};

const Table = ({ className, children, type, size, ...moreProps }) => {
  const TableComponent = MAP_TABLE_TYPE[type] || MAP_TABLE_TYPE[defaultType];
  const paddingSize = MAP_PADDING_SIZE[size] || MAP_PADDING_SIZE[defaultSize];
  const props = { ...moreProps, paddingSize };

  return (
    <TableComponent className={className} {...props}>
      {children}
    </TableComponent>
  );
};

export { Table };
