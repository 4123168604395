/* eslint-disable no-unused-vars */
import Requestor from '../Utils/fetchHTTP/Requestor';
import { cleanEmptyValues } from '../Utils/utils';
import { keysToSnakeCase } from '../Utils/formats';
import ApiResponses from './dummy_api_responses.json';
import { getLocalStorage } from '../Utils/useGetMerchant';
import axios from 'axios';

const getTransactions = async ({ paymentScheduleId = '', ...parameters }) => {
  const defaultResponse = {
    page: 1,
    page_size: 1,
    total_pages: 1,
    total_records: 0,
    transactions: [],
  };
  const params = parameters
    ? { params: keysToSnakeCase(cleanEmptyValues(parameters)) }
    : {};
  try {
    const response = await Requestor().getRequest({
      method: 'GET',
      url: `/api/v1/protected/merchants_payments/payment_schedules/${paymentScheduleId}/transactions`,
      ...params,
    });

    if (response && response.data && response.data) {
      return response.data;
    }

    return defaultResponse;
  } catch (error) {
    console.error(error);
    return defaultResponse;
  }
};

const getTransactionById = async ({
  paymentScheduleId = '',
  transactionId = '',
}) => {
  const defaultResponse = {
    page: 1,
    page_size: 1,
    total_pages: 1,
    total_records: 0,
    transactions: [],
  };

  try {
    const response = await Requestor().getRequest({
      method: 'GET',
      url: `/api/v1/protected/merchants_payments/payment_schedules/${paymentScheduleId}/transactions`,
      params: {
        transaction_id: transactionId,
      },
    });

    if (response && response.data && response.data) {
      return response.data;
    }

    return defaultResponse;
  } catch (error) {
    console.error(error);
    return defaultResponse;
  }
};

const getPaymentDateSchedules = async parameters => {
  try {
    const params = parameters
      ? keysToSnakeCase(cleanEmptyValues(parameters))
      : {};
    const response = await Requestor().getRequest({
      method: 'GET',
      url: '/api/v1/protected/merchants_payments/payment_schedules',
      params: {
        status: 'completed',
        ...params,
      },
    });

    if (response && response.data) {
      return response.data;
    }

    return [];
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getSummaryPaymentSchedule = async parameters => {
  try {
    const response = await Requestor().getRequest({
      method: 'GET',
      url: `/api/v1/protected/merchants_payments/payment_schedules/${parameters.paymentScheduleId}`,
    });

    if (response && response.data) {
      return response.data;
    }

    return {
      details: {},
    };
  } catch (error) {
    console.error(error);
    return {
      details: {},
    };
  }
};

const exportPaymentReport = async ({ paymentScheduleId, ...rest }) => {
  try {
    const response = await Requestor().getRequest({
      method: 'GET',
      url: `/api/v1/protected/merchants_payments/payment_schedules/${paymentScheduleId}/transactions/export`,
      params: keysToSnakeCase(cleanEmptyValues(rest)),
    });

    if (response && response.data) {
      const data = JSON.parse(response.data);
      const blob = new Blob([data], { type: 'text/csv; charset=utf-18' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'payments.csv';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }

    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getMerchantUser = async id => {
  try {
    const response = await Requestor().getRequest({
      method: 'GET',
      url: `/api/protected/merchant_users/${id}`,
    });

    if (response && response.data && response.data.user) {
      return response.data.user;
    }

    throw response;
  } catch (error) {
    if (error?.response?.status === 404 || error?.response?.status === 400) {
      return null;
    }

    return {
      first_name: '',
      last_name: '',
      maiden_name: '',
      email: '',
      user_code: '',
      status: '',
      role_id: '',
      stores: [],
    };
  }
};

const getStoresLight = async merchantId => {
  let parameters = {
    disable_pagination: true,
    light_response: true,
  };

  if (merchantId) {
    parameters = {
      ...parameters,
      merchant_id: merchantId,
    };
  }

  try {
    const response = await Requestor().getRequest({
      method: 'GET',
      url: '/api/protected/stores',
      params: parameters,
    });

    if (response && response.data && response.data.stores) {
      return response.data.stores;
    }

    return [];
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getPaymentsMovementsAggregate = async parameters => {
  const defaultResponse = {
    page: 1,
    page_size: 1,
    total_pages: 1,
    total_records: 0,
    payments: [],
    summary: {
      payments_total: 0.0,
      payments_count: 0,
      cancelations_total: 0.0,
      cancelations_count: 0,
    },
  };

  try {
    const response = await Requestor().getRequest({
      method: 'GET',
      url: '/api/protected/merchant_sales/payments_movements_aggregates',
      params: {
        order_by: 'created_at',
        ...keysToSnakeCase(cleanEmptyValues(parameters)),
      },
    });

    if (response && response.data) {
      return {
        page: response.data.pagination.actual_page,
        page_size: parameters.pageSize,
        total_pages: response.data.pagination.total_pages,
        total_records: response.data.pagination.total_items,
        payments: response.data.payments,
        summary: response.data.summary,
      };
    }

    return defaultResponse;
  } catch (error) {
    console.error(error);
    return defaultResponse;
  }
};

const exportTransactionReport = async parameters => {
  const { merchant_id, ...rest } = parameters;

  try {
    const response = await Requestor().getRequest({
      method: 'GET',
      url: '/api/protected/merchant_sales/payments_movements_aggregates/export',
      responseType: 'blob',
      params: {
        order_by: 'payment_created_at_desc',
        merchant_id: merchant_id,
        ...keysToSnakeCase(cleanEmptyValues(rest)),
      },
    });

    if (response) {
      const url = window.URL.createObjectURL(response);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'payments.csv';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }

    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default function MerchantPaymentsService() {
  return Object.create({
    getTransactions,
    getPaymentDateSchedules,
    getSummaryPaymentSchedule,
    getTransactionById,
    exportPaymentReport,
    getMerchantUser,
    getStoresLight,
    getPaymentsMovementsAggregate,
    exportTransactionReport,
  });
}
