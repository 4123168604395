import React from 'react';

const MerchantNameSelected = () => {
  //get type user if user is not a root type when use early return || if user has not selected
  const merchantData = JSON.parse(localStorage.getItem('merchantSelected'));

  if (merchantData === null) return null;

  return (
    <div className='k-ds-m-top-sp-05 k-ds-heading-04'>{merchantData.name}</div>
  );
};

export default MerchantNameSelected;
