/**
 * @param {Array} roles array of roles
 * @param {Object} currentUserRole object role
 * @param {String} currentRole string role
 */
export function filterRoles(roles, currentUserRole, currentRole) {
  const role_hierarchy =
    currentUserRole?.hierarchy ?? currentUserRole?.role_hierarchy;

  return roles
    .filter(r => {
      return r.role_hierarchy > role_hierarchy || r.role_id === currentRole;
    })
    .map(role => ({
      value: role.role_id,
      text: role.role_description,
      name: role.role_name,
    }));
}
