import { useQuery, keepPreviousData } from '@tanstack/react-query';
import MerchantPaymentsService from '../../../services/MerchantPaymentsService';
import { useLoaderContext } from '../../../contexts/Loader/LoaderContext';
import { useStore } from '../../../services/store';

const useQueryPaymentReport = () => {
  const { setIsLoaderOpen } = useLoaderContext();
  const {
    merchantReportPayments: {
      paymentReport,
      updatepaymentReportFilter,
      initPaymentReportFilter,
    },
  } = useStore();

  const fetchTransactions = async transactionParams => {
    setIsLoaderOpen(true);
    const respose =
      await MerchantPaymentsService().getTransactions(transactionParams);

    setIsLoaderOpen(false);
    return respose;
  };

  const queryResult = useQuery({
    queryKey: ['paymentReport', paymentReport],
    queryFn: async () => fetchTransactions(paymentReport),
    placeholderData: keepPreviousData,
    initialData: {
      page: 1,
      page_size: 1,
      total_pages: 1,
      total_records: 0,
      transactions: [],
    },
    enabled: !!paymentReport.paymentScheduleId,
  });

  const onInitFilters = filters => {
    initPaymentReportFilter(filters);
  };

  const onUpdateFilters = newFilters => {
    updatepaymentReportFilter(newFilters);
  };

  const onChangePageSize = pageSize => {
    updatepaymentReportFilter({ pageSize: Number(pageSize) });
  };

  const onChangePage = page => {
    updatepaymentReportFilter({ page: Number(page) });
  };

  return {
    initFilters: onInitFilters,
    filters: paymentReport,
    updateFilters: onUpdateFilters,
    changePage: onChangePage,
    changePageSize: onChangePageSize,
    queryResult: queryResult,
  };
};

export default useQueryPaymentReport;
