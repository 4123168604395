import cn from 'classnames';
import React from 'react';
import styled, { css } from 'styled-components';
import KDS from '../../lib/k-ds-foundation';
import { Space } from '../space/index';

const StyledSelectLineDiv = styled.div`
  position: relative;

  width: 100%;

  select {
    ${KDS.setColorTokenWithFallback('border: 1px solid ', 'interactive-alternate')}
    border-radius: 0;
    box-sizing: border-box;
    padding: 14px 14px 14px 0;
    border-width: 0 0 1px 0;
    ${(props) => props.hasError && css`
      ${KDS.setColorTokenWithFallback('border-color: ', 'error')}
    `}

    ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}

    cursor: pointer;
  }
 
  .helper-error {
    ${KDS.setColorTokenWithFallback('color: ', 'text-placeholder')}

    ${(props) => props.hasError && css`
      ${KDS.setColorTokenWithFallback('color: ', 'error')}
    `}
  }
`;

const SelectLine = ({
  selectClassName = '',
  className, name, form, label, placeholder, options,
  disabled, errorMessage, optional, helper, ...moreProps
}) => {
  const hasError = Boolean(errorMessage);
  const { required } = moreProps;

  const props = { ...moreProps, hasError };

  const selectOptions = options || [];
  const optionsData = selectOptions.map((option) => {
    let id = option.text.toLowerCase().replace(/ /g, '_');
    id = `${name || ''}_${id}`;
    return {
      id, text: option.text, value: option.value,
    };
  });

  return (
    <Space size="small" direction="vertical" className={className} {...props}>
      {label && (
        <Space size="small" justify="space-between" className="k-ds-width-full" direction="horizontal">

          <label className="k-ds-heading-02 k-ds-text-color-primary" htmlFor={name}>
            {label}
            {required ? <span className="k-ds-text-color-error">{' *'}</span> : null}
          </label>
          <span className="k-ds-label-01 k-ds-text-color-interactive-secondary">{optional}</span>
        </Space>
      )}
      <StyledSelectLineDiv hasError={hasError}>
        <select className={cn(selectClassName ?? "k-ds-body-02")} defaultValue="" disabled={disabled}>
          <option key={placeholder} id={placeholder} name={name} disabled value="" form={form}>
            {placeholder}
          </option>
          {
            selectOptions.length > 0
              ? (optionsData.map((option) => (
                <option key={option.id} id={option.id} name={name} value={option.value} form={form}>
                  {option.text}
                </option>
              ))) : null
          }
        </select>
        {
          helper || hasError ? (
            <span className="helper-error k-ds-caption-01">
              {errorMessage || helper}
            </span>
          ) : null
        }
      </StyledSelectLineDiv>
    </Space>
  );
};

export {
  SelectLine
};

