import React from 'react';
import PropTypes from 'prop-types';
import BoxInfo, { RowBoxInfo } from '../DS/BoxInfo/BoxInfo';
import styles from './styles.module.scss';
import Timer from '../../Utils/Timer';

const TextItem = ({ title, amount }) => {
  return (
    <div className={styles.textItem}>
      <div className='k-ds-heading-02'>{amount}</div>
      {title}
    </div>
  );
};

TextItem.propTypes = {
  title: PropTypes.string,
  amount: PropTypes.string,
};

export const Period = ({ startDate, endDate }) => {
  if (!startDate || !endDate) {
    return '';
  }

  const FORMAT_DATE = 'd \'de\' MMMM \'del\' yyyy';
  if (Timer().diff(startDate, endDate, 'day').days === 0) {
    return Timer().toLocal(startDate, FORMAT_DATE);
  }

  if (Timer().toLocal(startDate, 'L') === Timer().toLocal(endDate, 'L')) {
    const endDay = Timer().toLocal(endDate, 'd');
    const startDateFormant = Timer().toLocal(
      startDate,
      // eslint-disable-next-line no-useless-escape
      `d \'al\' ${endDay} \'de\' MMMM \'del\' yyyy`
    );

    return startDateFormant;
  }

  return (
    <>
      <div>{`${Timer().toLocal(startDate, FORMAT_DATE)} al`}</div>
      <div>{`${Timer().toLocal(endDate, FORMAT_DATE)}`}</div>
    </>
  );
};

Period.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

const PaymentSummary = ({
  sales = [],
  cancellations = [],
  period = '',
  className = '',
}) => {
  return (
    <div className={`${styles.paymentSummary} ${className}`}>
      <BoxInfo color='green' header={{ title: 'Ventas', subTitle: period }}>
        {sales.map(item => (
          <RowBoxInfo
            key={item.title}
            text={<TextItem title={item.title} amount={item.amount} />}
            content={item.content}
          />
        ))}
      </BoxInfo>
      <BoxInfo
        color='red'
        header={{ title: 'Cancelaciones', subTitle: period }}
      >
        {cancellations.map(item => (
          <RowBoxInfo
            key={item.title}
            text={<TextItem title={item.title} amount={item.amount} />}
            content={item.content}
          />
        ))}
      </BoxInfo>
    </div>
  );
};

PaymentSummary.propTypes = {
  period: PropTypes.any,
  sales: PropTypes.array,
  cancellations: PropTypes.array,
  className: PropTypes.string,
};

export default PaymentSummary;
