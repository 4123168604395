import React from 'react';
import PropTypes from 'prop-types';
import BoxInfo, { RowBoxInfo } from '../../DS/BoxInfo/BoxInfo';
import styles from './../styles.module.scss';

const SummaryInfo = props => (
  <div className={styles.parentColumns}>
    <BoxInfo
      color='gray'
      header={{ title: 'Información del pago', subTitle: props.scheduleName }}
    >
      <RowBoxInfo text='Frecuencia de pago:' content={props.paymentFrequency} />
      <RowBoxInfo text='Fecha de pago:' content={props.paymentDate} />
      <RowBoxInfo
        text='Cuenta / banco emisor:'
        content={props.disbursementSender}
      />
      <RowBoxInfo
        text='Cuenta / banco receptor:'
        content={props.disbursementRecipient}
      />
      <RowBoxInfo text='Próximo pago:' content={props.nextPaymentDate} />
    </BoxInfo>

    <BoxInfo
      color='blue'
      header={{ title: 'Detalle del pago', subTitle: props.scheduleName }}
    >
      <RowBoxInfo text='Bruto a capital:' content={props.paymentsTotal} />
      <RowBoxInfo text='Comisiones:' content={props.paymentsCommission} />
      <RowBoxInfo text='Subtotal:' content={props.paymentsSubtotal} />
      <RowBoxInfo
        text={`${props.cancellations} Cancelaciones:`}
        content={props.refundsTotal}
      />
      <RowBoxInfo
        text='Total neto:'
        content={props.disbursementAmount}
        color='blue'
      />
    </BoxInfo>
  </div>
);

SummaryInfo.propTypes = {
  scheduleName: PropTypes.string,
  paymentFrequency: PropTypes.string,
  paymentDate: PropTypes.string,
  disbursementSender: PropTypes.string,
  disbursementRecipient: PropTypes.string,
  nextPaymentDate: PropTypes.string,
  paymentsTotal: PropTypes.string,
  paymentsCommission: PropTypes.string,
  paymentsSubtotal: PropTypes.string,
  cancellations: PropTypes.number,
  refundsTotal: PropTypes.string,
  disbursementAmount: PropTypes.string,
};

export default SummaryInfo;
