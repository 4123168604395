import React from 'react';
import PropTypes from 'prop-types';
import styles from './periodText.module.scss';

const PeriodText = ({ noResults = 0 }) => {
  return (
    <div className={styles.period}>
      <div>{`${noResults} resultados `}</div>
    </div>
  );
};

PeriodText.propTypes = {
  noResults: PropTypes.number,
  shedule: PropTypes.string,
};

export default PeriodText;
