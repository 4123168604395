import { Button, Pagination, PaginationMobile, Space, Tag } from '../../ds';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as GridViewIconSelected } from '../../common/assets/gridViewIcon-selected.svg';
import { ReactComponent as GridViewIcon } from '../../common/assets/gridViewIcon.svg';
import { ReactComponent as ListViewIconSelected } from '../../common/assets/listViewIcon-selected.svg';
import { ReactComponent as ListViewIcon } from '../../common/assets/listViewIcon.svg';
import { ReactComponent as PlusSign } from '../../common/assets/plus-sign.svg';
import Restricted from '../../containers/Restricted';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { useLoaderContext } from '../../contexts/Loader/LoaderContext';
import { getStores } from '../../services/Application';
import { isFeatureEnabled } from '../../Utils/Feature/feature_flag';
import { getDateString } from '../../Utils/formats';
import AddStoreSelectionModal from '../AddStoreSelectionModal/AddStoreSelectionModal';
import DownloadCsvModal from '../DownloadCsvModal/DownloadCsvModal';
import { SearchBar } from '../DS/SearchBar';
import EmptySearchResults from '../EmptySearchResults';
import Header from '../Header';
import MerchantNameSelected from '../MerchantNameSelected/MerchantNameSelected';
import { TdResponsive, ThResponsive } from '../ResponsiveTable';
import { ResponsiveTable } from '../ResponsiveTable/ResponsiveTable';
import StoresFilter from '../StoresFilter/StoresFilter';
import ToolBar from '../ToolBar/ToolBar';
import { ReactComponent as ExportIconMobile } from './export-icon-mobile.svg';
import { ReactComponent as ExportIcon } from './export-icon.svg';
import { ReactComponent as FilterIconMobile } from './filter-icon-mobile.svg';
import { ReactComponent as FilterIcon } from './filter-icon.svg';
import StoreCard from './StoreCard';
import './stores.scss';
import AnalyticsEvent, {
  AnalyticsList,
} from '../../services/analytics/AnalyticsEvent';

const StoreManagement = () => {
  const viewport = useDeviceContextViewport();
  const orderDetailsDirection = viewport.isMobile ? 'vertical' : 'horizontal';
  const orderDetailsAlign = viewport.isMobile ? 'stretch' : 'start';
  const searchBarWidth = viewport.isMobile ? '100%' : '437px';
  const PaginationComponent = viewport.isMobile ? PaginationMobile : Pagination;

  const [isListMode, setIsListMode] = useState(true);
  const [searchState, setSearchState] = useState('');
  const [searchBy, setSearchBy] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [stores, setStores] = useState([]);
  const [pagination, setPagination] = useState({
    total_pages: 1,
    total_items: 0,
    actual_page: 1,
  });
  const [errorSearch, setErrorSearch] = useState(false);
  const limit = isListMode ? 20 : 9;
  let history = useHistory();

  const { setIsLoaderOpen } = useLoaderContext();
  const [filterBarOpen, setFilterBarOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [storesToExport, setStoresToExport] = useState(undefined);
  const [selectionModalOpen, setSelectionModalOpen] = useState(false);

  const tableHeaders = {
    name: 'Nombre',
    address: 'Dirección',
    status: 'Estatus',
    detail: 'Detalle',
  };

  const stateFilter = filters.stateFilter || [];
  const statusFilter = filters.statusFilter || [];
  const stateActiveFilters = stateFilter.filter(state => state.checked);
  const statusActiveFilters = statusFilter.filter(status => status.checked);
  const isTracked = useRef(false);

  const creationDateActiveFilters =
    filters.startDate && filters.endDate
      ? {
          startDate: filters.startDate,
          endDate: filters.endDate,
        }
      : undefined;

  useEffect(() => {
    if (!isTracked.current) {
      AnalyticsEvent().pageView({
        page: AnalyticsList.storeManagement,
      });
      isTracked.current = true;
    }
  }, []);

  useEffect(() => {
    setIsLoaderOpen(true);

    const merchantInfo = JSON.parse(localStorage?.getItem('merchantSelected'));
    const filterParams = {
      stateActiveFilters,
      statusActiveFilters,
      creationDateActiveFilters,
      merchant_id: merchantInfo?.merchant_id,
    };

    getStores(searchBy, currentPage, limit, filterParams)
      .then(response => {
        setStores(response.data.stores);
        setPagination(response.data.pagination);
      })
      .catch(error => {
        AnalyticsEvent().errorTrack({
          errorType: 'store-management-get-stores-error',
          errorDescription: error,
        });
        console.error(error);
      })
      .finally(() => {
        setIsLoaderOpen(false);
      });
  }, [currentPage, searchBy, filters]);

  const getStatusTag = status => {
    const text = getStatusText(status);
    switch (status) {
      case 'true':
      case 'active':
        return (
          <Tag className='store-tag' color='teal'>
            {text}
          </Tag>
        );
      case 'false':
      case 'inactive':
        return (
          <Tag className='store-tag' color='red'>
            {text}
          </Tag>
        );
      case 'deleted':
        return (
          <Tag className='store-tag' color='red'>
            {text}
          </Tag>
        );
      default:
        return (
          <Tag className='store-tag' color='orange'>
            {text}
          </Tag>
        );
    }
  };

  const getStatusText = status => {
    switch (status) {
      case 'true':
      case 'active':
        return 'ACTIVA';
      case 'false':
      case 'inactive':
        return 'INACTIVA';
      case 'deleted':
        return 'ELIMINADA';
      default:
        return status;
    }
  };

  const search = search => {
    setCurrentPage(1);
    const isValid = search.length === 0 || search.length >= 5;
    setErrorSearch(!isValid);
    setStoresToExport(undefined);
    if (isValid) {
      setSearchBy(search);
    }
  };

  const clearSearch = () => {
    setSearchState('');
    setSearchBy('');
    setErrorSearch(false);
    setStoresToExport(undefined);
  };

  const goDetails = path => {
    history.push(path);
    const contentArea = document.getElementById('content-area');
    contentArea.scrollTop = 0;
  };

  function handleCsvDownload() {
    setStoresToExport(undefined);
    setIsLoaderOpen(true);

    const merchantInfo = JSON.parse(localStorage?.getItem('merchantSelected'));

    const filterParams = {
      stateActiveFilters,
      statusActiveFilters,
      creationDateActiveFilters,
      merchant_id: merchantInfo?.merchant_id,
    };
    const getAllStores = true;

    getStores(
      searchBy,
      null,
      pagination.total_items,
      filterParams,
      getAllStores
    )
      .then(response => {
        const storesData = response?.data?.stores.map(store => {
          return {
            Nombre: store.name,
            Estatus: getStatusText(store.active),
            Dirección:
              store.address_attributes.street +
              ' ' +
              store.address_attributes.ext_number +
              ' ' +
              store.address_attributes.neighborhood +
              ' C.P. ' +
              store.address_attributes.zip_code +
              ' ' +
              store.address_attributes.city,
          };
        });
        setStoresToExport(storesData);
        setOpenDownloadModal(true);
      })
      .catch(error => {
        AnalyticsEvent().errorTrack({
          errorType: 'store-management-get-stores-error',
          errorDescription: error,
        });
        console.error(error);
      })
      .finally(() => {
        setIsLoaderOpen(false);
      });
  }

  const StoresTable = () => {
    return (
      <>
        <ResponsiveTable
          type='b2b'
          className='k-ds-width-full stores-table k-ds-m-bottom-lyt-04'
        >
          <thead>
            <tr>
              <th>{tableHeaders.name}</th>
              <th>{tableHeaders.address}</th>
              <ThResponsive devices='mobile tablet desktop'>
                {tableHeaders.status}
              </ThResponsive>
              <th className='centered-column'>{tableHeaders.detail}</th>
            </tr>
          </thead>
          <tbody>
            {stores.map((store, index) => {
              return (
                <React.Fragment key={index}>
                  <tr key={store.store_id} className='row-hover'>
                    <td
                      className='store-name-td'
                      data-title={tableHeaders.name}
                    >
                      {store.name}
                    </td>
                    <td data-title={tableHeaders.address}>
                      <span className='store-address'>
                        <span>
                          {store.address_attributes.street}{' '}
                          {store.address_attributes.ext_number},{' '}
                        </span>
                        <span>
                          {store.address_attributes.neighborhood}, C.P.{' '}
                          {store.address_attributes.zip_code}{' '}
                        </span>
                        <span>{store.address_attributes.city}</span>
                      </span>
                    </td>
                    <TdResponsive
                      devices='mobile tablet desktop'
                      data-title={tableHeaders.status}
                    >
                      {getStatusTag(store.active)}
                    </TdResponsive>
                    <td
                      className='centered-column'
                      data-title={tableHeaders.detail}
                    >
                      <Button
                        className='store-details-button'
                        type='link'
                        size='small'
                        onClick={() => goDetails(`/stores/${store.store_id}`)}
                      >
                        Ver detalle
                      </Button>
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </ResponsiveTable>
      </>
    );
  };

  const StoresGrid = () => {
    return (
      <div className='store-grid'>
        {stores.map(store => {
          return (
            <StoreCard
              key={store.store_id}
              store_id={store.store_id}
              name={store.name}
              status={store.active}
              address={store.address_attributes}
              goToDetail={goDetails}
              getStatusTag={getStatusTag}
            />
          );
        })}
      </div>
    );
  };

  const onFilterChange = filters => {
    setSearchBy('');
    setSearchState('');
    setFilters(filters);
    setFilterBarOpen(false);
  };

  const isAnyFilterActive =
    stateActiveFilters.length > 0 ||
    statusActiveFilters.length > 0 ||
    creationDateActiveFilters;

  const TagsContainer = () => {
    return (
      <Space className='tags-container'>
        {stateActiveFilters.map((state, index) => (
          <Tag
            key={index}
            color='orange-outline'
            className='k-ds-m-right-sp-04 tag'
            onClick={() => setFilterBarOpen(true)}
          >
            ESTADO: {state.label}
          </Tag>
        ))}
        {statusActiveFilters.map((status, index) => (
          <Tag
            key={index}
            color='orange-outline'
            className='k-ds-m-right-sp-04 tag'
            onClick={() => setFilterBarOpen(true)}
          >
            ESTATUS: {status.label}
          </Tag>
        ))}
        {creationDateActiveFilters && (
          <Tag
            color='orange-outline'
            className='k-ds-m-right-sp-04 tag'
            onClick={() => setFilterBarOpen(true)}
          >
            FECHA DE CREACIÓN:{' '}
            {getDateString(creationDateActiveFilters.startDate)} -{' '}
            {getDateString(creationDateActiveFilters.endDate)}
          </Tag>
        )}
      </Space>
    );
  };

  return (
    <>
      <div className='store-list'>
        <div className='k-ds-width-full'>
          <Space
            direction={orderDetailsDirection}
            size='large'
            justify='space-around'
            align={orderDetailsAlign}
            className='k-ds-width-full'
          >
            <Header
              title='Gestión de sucursales'
              subtitle='Elige la sucursal que deseas consultar.'
              alignChildCenter
            >
              <Restricted toModule={'stores'} withScope={'create_store'}>
                <Button
                  size='small'
                  type='primary'
                  horizontalPadding='4rem'
                  className='action-button-store'
                  verticalPadding='1rem'
                  onClick={OpenSelectionModal}
                >
                  <PlusSign className='store-plus-icon' /> Agregar sucursales
                </Button>
              </Restricted>
            </Header>
          </Space>

          <MerchantNameSelected />
          <Space
            direction='vertical'
            className='flex-grow-1 store-search k-ds-m-vertical-sp-07'
          >
            <SearchBar
              placeholder='Nombre de tienda'
              search={searchState}
              setSearch={setSearchState}
              onSearch={() => search(searchState)}
              clearSearch={() => clearSearch()}
              width={searchBarWidth}
              errorMessage={
                errorSearch
                  ? 'Necesitas escribir al menos cinco caracteres para realizar una búsqueda.'
                  : ''
              }
            />
          </Space>
          <Space
            direction='horizontal'
            align='center'
            justify='space-between'
            className='k-ds-m-top-sp-06 k-ds-m-bottom-sp-08 store-filters'
          >
            <Space
              direction='horizontal'
              align='stretch'
              justify='space-around'
            >
              {isListMode ? (
                <>
                  <ListViewIconSelected />
                  <GridViewIcon
                    onClick={() => setIsListMode(false)}
                    className='gird-icon'
                  />
                </>
              ) : (
                <>
                  <ListViewIcon onClick={() => setIsListMode(true)} />
                  <GridViewIconSelected className='gird-icon' />
                </>
              )}
            </Space>
            <Space>
              {isFeatureEnabled('filter_stores') && (
                <Button type='link' onClick={() => setFilterBarOpen(true)}>
                  <div className='filter-icon'>
                    <FilterIcon className='k-ds-m-right-sp-04' /> Filtrar
                  </div>
                  <FilterIconMobile className='k-ds-m-right-sp-04 filter-icon-mobile' />
                </Button>
              )}
              {isFeatureEnabled('export_stores') && (
                <Button
                  className='export-button'
                  type='link'
                  onClick={() => handleCsvDownload()}
                >
                  <div className='export-icon'>
                    <ExportIcon className='k-ds-m-right-sp-04' /> Exportar a
                    excel
                  </div>
                  <ExportIconMobile className='export-icon-mobile' />
                </Button>
              )}
            </Space>
          </Space>
          {isAnyFilterActive && (
            <Space
              align='center'
              justify='space-between'
              className='k-ds-m-top-sp-06 k-ds-m-bottom-sp-07 store-filter-tags'
            >
              <Space className='tags-info k-ds-width-full'>
                <Space justify='space-between'>
                  <Space
                    direction='vertical'
                    className='k-ds-m-bottom-sp-06 applied-filters'
                  >
                    <span className='k-ds-body-01'> Filtros aplicados:</span>
                  </Space>
                  <Space className='k-ds-body-01 k-ds-m-top-sp-03 filter-result'>
                    Mostrando: {stores.length || 0} resultados
                  </Space>
                </Space>
                <TagsContainer />
              </Space>
              <Space className='k-ds-body-01 k-ds-m-top-sp-03 filter-result-mobile'>
                Mostrando: {stores.length || 0} resultados
              </Space>
            </Space>
          )}
          <ToolBar
            isOpen={filterBarOpen}
            onClose={() => setFilterBarOpen(false)}
          >
            <StoresFilter
              filters={filters}
              onFilterChange={filters => onFilterChange(filters)}
            />
          </ToolBar>
          {isListMode ? <StoresTable /> : <StoresGrid />}
          {stores.length > 0 ? (
            <div className='store-list-pagination'>
              <PaginationComponent
                totalPages={pagination.total_pages}
                totalElements={pagination.total_items}
                maxVisibleNumbers={6}
                currentPage={pagination.actual_page}
                elementsPerPage={limit}
                changePage={page => setCurrentPage(page)}
              />
            </div>
          ) : (
            <EmptySearchResults clearAction={() => clearSearch()} />
          )}
        </div>
      </div>
      {storesToExport && (
        <DownloadCsvModal
          defaultOpened={openDownloadModal}
          filename='reporte_sucursales'
          format='.csv'
          content={storesToExport}
          close={() => {
            setStoresToExport(undefined);
            setOpenDownloadModal(false);
          }}
        />
      )}
      <AddStoreSelectionModal
        isOpen={selectionModalOpen}
        close={CloseSelectionModal}
      />
    </>
  );

  function OpenSelectionModal() {
    setSelectionModalOpen(true);
  }

  function CloseSelectionModal() {
    setSelectionModalOpen(false);
  }
};

export default StoreManagement;
