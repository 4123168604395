import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Modal } from '../../../../ds';
import styles from './ActivationForm.module.scss';
import FormActivation from './Form';

// import './skeleton.scss';
import { ReactComponent as CheckIcon } from './res/check.svg';
import { ReactComponent as FolderIcon } from './res/folder.svg';

const Stepper = ({ steps }) => (
  <div className={styles.steps}>
    <div className={styles.stepper}>
      <div className={cn(styles.step, styles.done)}>
        <div className={styles.stepNumber}>
          {' '}
          <span>
            {' '}
            <CheckIcon />{' '}
          </span>
        </div>
        <div className={styles.stepLabel}>Formulario de afiliación enviado</div>
      </div>
      <div className={styles.connector_1} />
      <div
        className={cn(styles.step, {
          [styles.active]: steps[1].current,
          [styles.done]: steps[1].done,
        })}
      >
        <div className={styles.stepNumber}>
          {' '}
          <span>{steps[1].done ? <CheckIcon /> : steps[1].step}</span>
        </div>
        <div className={styles.stepLabel}>
          Evaluando formulario
          <span>3 a 5 días hábiles</span>
        </div>
      </div>
      <div className={styles.connector_2} />
      <div
        className={cn(styles.step, {
          [styles.active]: steps[2].current,
          [styles.done]: steps[1].done,
        })}
      >
        <div className={styles.stepNumber}>
          {' '}
          <span>{steps[2].done ? <CheckIcon /> : steps[2].step}</span>
        </div>
        <div className={styles.stepLabel}>Resultado de afiliación</div>
      </div>
    </div>
  </div>
);

Stepper.propTypes = {
  steps: PropTypes.object.isRequired,
};

const FormModal = ({ handleClose }) => {
  const [isValidationActive, setIsValidationActive] = useState(false);
  const [steps] = useState([
    { step: 1, done: true, current: false },
    { step: 2, done: false, current: true },
    { step: 3, done: false, current: false },
  ]);

  return (
    <Modal className={styles.modal} theme='dark' {...{ isOpen: true }}>
      {/* <div className='skeleton'>
        <div className='skeleton-content'></div>
        <div className='skeleton-logo'></div>
        <div className='skeleton-content'></div>
        <div className='skeleton-content'></div>
      </div> */}

      {isValidationActive && (
        <div className={styles.container}>
          <Stepper steps={steps} />
          <div className={styles.wrapper}>
            <div className={styles.formContainer}>
              <div className={styles['titleModal-center']}>
                En proceso de evaluación
              </div>
              <div className={styles['instructions-bold']}>
                Estamos revisando que tu comercio cumpla con los requisitos para
                ser afiliado.
              </div>
              <div className={styles.centerCode}>
                <FolderIcon className={styles.code} />
                {/* <img src={ ImageQR }  /> */}

                <div className={styles['instructions-center']}>
                  <a href='https://api.whatsapp.com/send?phone=523324704180'>
                    Click aquí
                  </a>{' '}
                  para consultar el estatus de tu solicitud, o escríbenos por
                  WhatsApp al 33 2470 4180.
                </div>
                <div className={styles['instructions-center']}>
                  Nuestro asistente virtual Tobi estará ahí para brindarte
                  información.
                </div>
                <Button onClick={handleClose} size='large'>
                  Salir
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isValidationActive && (
        <FormActivation handleNextView={() => setIsValidationActive(true)} />
      )}
    </Modal>
  );
};
export default FormModal;

FormModal.propTypes = {
  handleClose: PropTypes.func,
};
