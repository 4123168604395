import styled, { css } from 'styled-components';
import React from 'react';
import { Card } from '../card/index';
import KDS from '../../lib/k-ds-foundation';

/* eslint-disable */
const StyledTableDefault = styled.table`
  /* k-ds-body-01 */
  ${KDS.setTypographyWithFallback({
    'font-family': 'font-secondary',
    'font-weight': 'font-medium',
    'font-size': 'text-sm',
    'letter-spacing': 'tracking-wide',
    'line-height': 'snug',
  })}
  ${KDS.setColorTokenWithFallback('color: ', 'text-primary')};
  text-align: left;
  vertical-align: middle;

  width: 100%;

  border-collapse: collapse;

  & > thead > tr {
    ${KDS.setColorTokenWithFallback('border-bottom: 1px solid ', 'color-border')};

    & > th {
      ${KDS.setTypographyWithFallback({
        'font-weight': 'font-semibold',
        'font-size': 'text-xs',
        'line-height': 'relaxed',
      })}
      ${KDS.setColorTokenWithFallback('color: ', 'text-secondary')};

      &:not(:first-child) {
        ${KDS.setSpacingWithFallback('padding: ', 0, 'spacing-09', 'spacing-05', 'spacing-09')};
      }
      &:first-child {
        ${KDS.setSpacingWithFallback('padding: ', 0, 'spacing-09', 'spacing-05', 'spacing-05')};
      }
      &:last-child {
        ${KDS.setSpacingWithFallback('padding: ', 0, 'spacing-05', 'spacing-05', 'spacing-09')};
      }
    }
  }

  & > tbody > tr {
    &:not(:last-child) {
      ${KDS.setColorTokenWithFallback('border-bottom: 1px solid ', 'color-border')};
    }

    & > td {
      ${(props) => props.paddingSize && css`
        &:not(:first-child) {
          ${KDS.setSpacingWithFallback('padding: ', props.paddingSize, 'spacing-09', props.paddingSize, 'spacing-09')};
        }
        &:first-child {
          ${KDS.setSpacingWithFallback('padding: ', props.paddingSize, 'spacing-09', props.paddingSize, 'spacing-05')};
        }
        &:last-child {
          ${KDS.setSpacingWithFallback('padding: ', props.paddingSize, 'spacing-05', props.paddingSize, 'spacing-09')};
        }
      `}

      & > a {
        text-align: right;
        ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary')};
        text-decoration: none;
      }
    }
  }
`;
/* eslint-enable */

const TableDefault = ({ className, children, ...moreProps }) => (
  <Card type="secondary" className={className}>
    <StyledTableDefault {...moreProps}>
      { children }
    </StyledTableDefault>
  </Card>
);

export {
  TableDefault,
};
