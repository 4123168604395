import React from 'react';
import { InputOutline } from './InputOutline';
import { InputLine } from './InputLine';
import { forwardRef } from 'react';

const MAP_INPUT_TYPE = {
  'outline': InputOutline,
  'line': InputLine
};
const GeneralInput = forwardRef(({ type, ...moreProps }, ref) => {
  const defaultInputType = 'outline'; // TODO: add transparent (set it as default?)
  const InputComponent = MAP_INPUT_TYPE[type] || MAP_INPUT_TYPE[defaultInputType];

  return (<InputComponent ref={ref} {...moreProps} />);
});

const TextInput = forwardRef((props, ref) => (<GeneralInput ref={ref} inputType="text" {...props} />));

const PasswordInput = (props) => (<GeneralInput inputType="password" {...props} />);

const DateInput = (props) => (<GeneralInput inputType="date" {...props} />);

export {
  TextInput, PasswordInput, DateInput,
};
