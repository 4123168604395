import styled, { css } from 'styled-components';
import KDS from '../../../../ds/lib/k-ds-foundation';
import { Button } from '../../../../ds';

export const StyledSelectOutline = styled.div`
  position: relative;
  width: 350px;

  .arrow-icon {
    fill: ${KDS.getColorToken('interactive-alternate')};
  }

  .selector {
    ${KDS.setColorTokenWithFallback(
  'border: 1px solid ',
  'interactive-alternate'
)}
    height: 52px;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 14px;
    cursor: pointer;
    ${KDS.setColorTokenWithFallback('background-color: ', 'white')}
    ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}

    &.selected {
      ${KDS.setColorTokenWithFallback(
  'border: 1px solid ',
  'interactive-primary'
)}

      svg {
        ${KDS.setColorTokenWithFallback('fill: ', 'interactive-primary')}
      }
    }

    .show {
      transform: rotateX(180deg);
    }

    ${props =>
    props.disabled &&
    css`
        cursor: not-allowed;

        ${KDS.setColorTokenWithFallback('background-color: ', 'grey-100')}
        ${KDS.setColorTokenWithFallback('border: 1px solid ', 'grey-300')}
        ${KDS.setColorTokenWithFallback('color: ', 'text-placeholder')}

        svg path {
          ${KDS.setColorTokenWithFallback('stroke: ', 'grey-500')}
        }
      `}

    ${props =>
    props.showPlaceholder &&
    css`
        ${KDS.setColorTokenWithFallback('color: ', 'text-secondary')}
      `}

    ${props =>
    props.active &&
    css`
        ${KDS.setColorTokenWithFallback(
      'border: 1px solid',
      'interactive-primary'
    )}

        .arrow-icon {
          fill: ${KDS.getColorToken('interactive-primary')};
        }
      `}
    }
  }

  .options-group {
    position: absolute;
    padding-top: 24px;
    z-index: 99;
    display: none;
    transform: translate(0, ${KDS.getSpacing('spacing-01')});
    transform: translate(0, var(--k-ds-spacing-01));
    overflow-y: auto;
    max-height: 506px;
    border-radius: 8px;
    box-sizing: border-box;

    ${KDS.setColorWithFallback('background: ', 'white')}
    ${KDS.setColorTokenWithFallback(
      'border: 1px solid ',
      'interactive-alternate'
    )}

    ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}

    &.show {
      display: block;
      width: max-content;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .group-option {
    margin: 24px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 16px;
    }
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    padding: 8px 24px;

    .radio-button {
      label {
        padding: 0;
      }

      span {
        margin: 0;
        width: 1rem;
        height: 1rem;
        display: block;
      }
    }

    label {
      display: block;
      padding-left: 16px;
      cursor: pointer;
    }

    &:hover {
      ${KDS.setColorTokenWithFallback('background: ', 'background-alternate')}
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary')}
    }
  }

  .helper {
    font-size: 14px;
    margin-top: 8px;
    color: #636e8c;
  }

  .helper-error {
    position: absolute;
    top: 3.5rem;
    left: 0;
    right: 0;

    ${KDS.setColorTokenWithFallback('color: ', 'text-placeholder')}

    ${props =>
    props.hasError &&
    css`
        ${KDS.setColorTokenWithFallback('color: ', 'error')}
      `}
  }

  .sticky-btn {
    position: sticky;
    bottom: 0;
    display: inline-block;
    background: #fff;
    padding: 24px 16px 16px 16px;
  }
`;

export const HiddenDivInput = styled.input`
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  top: 50%;
  left: 50%;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  max-width: 122px;
  margin: 0 24px;
`;

export const StyledFormatGroupLabel = styled.div`
  text-transform: capitalize;
  margin: 0 24px 8px 24px;
`;

export const StyledNotFoundLabel = styled.div`
  margin: 0 24px 8px 24px;
  width: 300px;
`;
