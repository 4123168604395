import React, { useEffect, useRef, useState } from 'react';
import LoginLayout from './LoginLayout';
import { TextInput, Button } from '../../../ds';
import ReCAPTCHA from 'react-google-recaptcha';
import { isFeatureEnabled } from '../../../Utils/Feature/feature_flag';
import { useDeviceContext } from '../../../contexts/Device/DeviceContext';
import BackPageButton from '../../../components/BackPageButton';
import { useLoaderContext } from '../../../contexts/Loader/LoaderContext';
import { useAuth } from '../../../Utils/Auth/use-auth';
import PasswordRecovered from './PasswordRecovered';
import { validateEmailField } from '../../../Utils/formValidations';
import AnalyticsEvent, {
  AnalyticsList,
} from '../../../services/analytics/AnalyticsEvent';

const isCaptchaEnabled = isFeatureEnabled('captcha');
const initialState = {
  email: '',
};

if (isCaptchaEnabled) {
  initialState.g_recaptcha_response = '';
}

const PasswordRecover = () => {
  const isTracked = useRef(true);
  const { setIsLoaderOpen } = useLoaderContext();
  const auth = useAuth();
  const { viewport } = useDeviceContext();
  const [recoverFormData, setRecoverFormData] = useState({ ...initialState });
  const [recoverFormErrors, setRecoverFormErrors] = useState({
    ...initialState,
  });

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const recaptchaRef = useRef();
  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const submitDisabled = () => {
    const noErrors = Object.values(recoverFormErrors).every(
      value => value === ''
    );
    const allFieldsFilled = Object.values(recoverFormData).every(
      value => value !== ''
    );
    return !(noErrors && allFieldsFilled);
  };

  const handleEmailChange = e => {
    const email = e.target.value;
    setRecoverFormData({ ...recoverFormData, email });
    if (!email) {
      setRecoverFormErrors({
        ...recoverFormErrors,
        email: 'Este campo no puede quedar vacío. ¿Lo puedes completar?',
      });
    } else {
      setRecoverFormErrors({ ...recoverFormErrors, email: '' });
    }
  };

  const handleEmailBlur = e => {
    const email = e.target.value;
    if (email.length > 0) {
      setRecoverFormErrors({
        ...recoverFormErrors,
        email: validateEmailField(
          email,
          'Asegúrate de que tu correo tenga el siguiente formato: hola@ejemplo.com'
        ),
      });
    } else {
      setRecoverFormErrors({ ...recoverFormErrors, email: '' });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setIsLoaderOpen(true);
    AnalyticsEvent().passwordRecovery();
    auth
      .passwordRecover(recoverFormData)
      .then(response => {
        if (response && response.status !== 'success') {
          setShowSuccessMessage(true);
        }
      })
      .finally(() => {
        setIsLoaderOpen(false);
      });
  };

  const onCaptchaChange = captchaValue => {
    setRecoverFormData({
      ...recoverFormData,
      g_recaptcha_response: captchaValue || '',
    });
  };

  useEffect(() => {
    if (isTracked.current) {
      AnalyticsEvent().pageView({ page: AnalyticsList.passwordRecover });
      AnalyticsEvent().passwordForgot();
      isTracked.current = false;
    }
  }, []);

  return (
    <LoginLayout showLogo={!viewport.isMobile}>
      <LoginLayout.Desktop>
        {showSuccessMessage ? (
          <PasswordRecovered handleSubmit={handleSubmit} />
        ) : (
          <div className='login'>
            <div className='recover-greeting'>
              <div className='recover-back-button k-ds-m-top-sp-09 k-ds-m-left-sp-01'>
                <BackPageButton />
              </div>
              <div className='k-ds-heading-05'>¿Olvidaste tu contraseña?</div>
              <p>No te preocupes a todos nos pasa.</p>
              <p>
                Ingresa el correo electrónico asociado con tu cuenta y te
                enviaremos las instrucciones para restablecer tu contraseña.
              </p>
              <form className='login-form k-ds-m-top-sp-07'>
                <TextInput
                  className='k-ds-width-full'
                  errorMessage={recoverFormErrors.email}
                  label='Correo electrónico'
                  onChange={handleEmailChange}
                  onBlur={handleEmailBlur}
                  placeholder='correo@ejemplo.com.mx'
                  type='outline'
                  value={recoverFormData.email}
                />
                {isCaptchaEnabled && (
                  <ReCAPTCHA
                    className='k-ds-m-top-sp-07'
                    sitekey={recaptchaSiteKey}
                    onChange={onCaptchaChange}
                    ref={recaptchaRef}
                  />
                )}
                <Button
                  className='k-ds-m-top-sp-05'
                  borderWidth=''
                  horizontalPadding=''
                  size='small'
                  type={submitDisabled() ? 'secondary' : 'primary'}
                  verticalPadding='16px'
                  onClick={handleSubmit}
                  disabled={submitDisabled()}
                >
                  Enviar instrucciones
                </Button>
              </form>
            </div>
          </div>
        )}
      </LoginLayout.Desktop>
    </LoginLayout>
  );
};

export default PasswordRecover;
