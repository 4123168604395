import { makeAutoObservable } from 'mobx';
import {
  MERCHANT_TRANSACTIONS_TYPE_FILTER,
  PERSIST_STORE_KEYS,
} from '../../Utils/constants';
import getChannelByRole from '../../Utils/getChannelByRole';
import Timer from './../../Utils/Timer';
import {
  clearPersistedStore,
  getPersistedStore,
  makePersistable,
  stopPersisting,
} from 'mobx-persist-store';

const FORMAT_DATE_RANGE = 'yyyy-MM-dd';

const initFilters = {
  page: 1,
  pageSize: 15,
  merchantId: '',
  searchTerm: '',
  paymentStatuses: [],
  channels: [],
  startDateRange: '',
  endDateRange: '',
};

export class MerchantTransactionsReportStore {
  isFirstLoad = true;
  transactionsReport = {
    page: 1,
    pageSize: 15,
    merchantId: '',
    searchTerm: '',
    paymentStatuses: [],
    channels: [],
    startDateRange: '',
    endDateRange: '',
  };

  getDefaultFilters() {
    const channelResult = getChannelByRole();

    return {
      ...initFilters,
      channels: channelResult.map(item => item.value),
      paymentStatuses: MERCHANT_TRANSACTIONS_TYPE_FILTER.map(
        item => item.value
      ),
      startDateRange: Timer().toNowISO(FORMAT_DATE_RANGE),
      endDateRange: Timer().toNowISO(FORMAT_DATE_RANGE),
    };
  }

  constructor() {
    stopPersisting(this);

    makeAutoObservable(this, this.getDefaultFilters(), { autoBind: true });

    makePersistable(this, {
      name: PERSIST_STORE_KEYS.merchantTransactionsReport,
      properties: ['transactionsReport', 'isFirstLoad'],
      storage: window.localStorage,
      expireIn: 180000,
      removeOnExpiration: true,
    });
  }

  onInitMerchant = ({ merchantId }) => {
    this.isFirstLoad = false;
    this.initFilter({ merchantId });
  };

  initFilter = (otherFilters = {}) => {
    const channelResult = getChannelByRole();

    const initFilters = {
      ...initFilters,
      ...otherFilters,
      channels: channelResult.map(item => item.value),
      paymentStatuses: MERCHANT_TRANSACTIONS_TYPE_FILTER.map(
        item => item.value
      ),
      startDateRange: Timer().toNowISO(FORMAT_DATE_RANGE),
      endDateRange: Timer().toNowISO(FORMAT_DATE_RANGE),
    };

    this.transactionsReport = initFilters;
    return initFilters;
  };

  async getPersistedData() {
    return await getPersistedStore(this);
  }

  updateFilter = (newFilters = {}) => {
    this.transactionsReport = {
      ...this.transactionsReport,
      ...newFilters,
    };
  };

  async resetStore() {
    await clearPersistedStore(this);
    this.isFirstLoad = true;
    this.transactionsReport = this.getDefaultFilters();
  }
}
