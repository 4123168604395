import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { daysOfTheWeek } from '../../../Utils/constants';
import { Button } from '../../../ds';
import { CoordinatesErrors } from '../StoreBulkConfirmation';
import { ReactComponent as PencilIcon } from '../res/pencil-icon.svg';
import InfoLabel from './InfoLabel';
import { NAME_STORE_ID_FIELD } from '../../StoreCreateEdit/StoreFields';
import { StoreFieldErrors } from '../../StoreCreateEdit/StoreFieldErrors';

const DetailsCard = ({data, onEditForm}) => {
  const {t} = useTranslation();
  const {
    errors,
    address_attributes,
    store_schedule_attributes,
    phone_number,
    external_store_id,
  } = data;

  const extNumber = address_attributes?.ext_number || '-';
  const street = address_attributes?.street || '-';
  const full_street = street + ' #' + extNumber;
  const externalStoreMessage = StoreFieldErrors[errors?.external_store_id?.[0]]?.message;

  return (
    <div className='details-container'>
      <div className='details'>
        <div>
          <div className='store-name'>
            <InfoLabel
              label={ NAME_STORE_ID_FIELD.label }
              text={ external_store_id || '-' }
              hasError={ errors?.external_store_id?.length > 0 }
              message={ externalStoreMessage }
            />
          </div>
          <br/>
          <p className='k-ds-heading-03'>Dirección de la sucursal</p>
          <div className='details-row'>
            <div className='details-column'>
              <InfoLabel
                label='Calle y número exterior'
                text={ full_street }
                hasError={
                  errors?.address_attributes?.street?.length > 0 ||
                  errors?.address_attributes?.ext_number?.length > 0
                }
              />
              <InfoLabel
                label='Número interior'
                text={ address_attributes.inner_number || '-' }
                hasError={ errors?.address_attributes?.inner_number?.length > 0 }
              />
              <InfoLabel
                label='Código Postal'
                text={ address_attributes.zip_code ?? '-' }
                hasError={
                  errors?.address_attributes?.zip_code?.length > 0 ||
                  errors?.address_attributes?.[1]?.zip_code?.length > 0
                }
              />
              <InfoLabel
                label='Centro Comercial'
                text={ address_attributes?.mall_name || '-' }
              />

              <InfoLabel
                label='Teléfono de sucursal'
                text={ phone_number || '-' }
                hasError={ errors?.phone_number?.length > 0 }
                message={ t(`stores.${ errors?.phone_number?.[0] }`, {
                  defaultValue: t('stores.must be filled'),
                }) }
              />
            </div>
            <div className='details-column'>
              <InfoLabel
                label='Colonia o barrio'
                text={ address_attributes.neighborhood || '-' }
                hasError={ errors?.address_attributes?.neighborhood?.length > 0 }
              />
              <InfoLabel
                label='Municipio o Alcaldía'
                text={ address_attributes.city || '-' }
                hasError={ errors?.address_attributes?.city?.length > 0 }
              />
              <InfoLabel
                label='Estado'
                text={ address_attributes.state }
                hasError={ errors?.address_attributes?.state?.length > 0 }
              />
            </div>
          </div>
        </div>

        <div>
          <p className='k-ds-heading-03'>Horario de atención</p>
          <div className='details-row'>
            <div className='details-column-schedule'>
              <div className='schedule'>
                { Object.keys(daysOfTheWeek).map((day, key) => {
                  const openTime =
                    store_schedule_attributes?.schedule?.[day]?.[0]?.open ?? '';
                  const closeTime =
                    store_schedule_attributes?.schedule?.[day]?.[0]?.closed ??
                    '';
                  const activeTime =
                    store_schedule_attributes?.schedule?.[day]?.[0]?.active ??
                    true;

                  const openTimeXtra =
                    store_schedule_attributes?.schedule?.[day]?.[1]?.open ?? '';
                  const closeTimeXtra =
                    store_schedule_attributes?.schedule?.[day]?.[1]?.closed ??
                    '';
                  return (
                    <div key={ key }>
                      <div className='details-schedule'>
                        <div className='day-schedule'>{ daysOfTheWeek[day] }</div>

                        { activeTime ? (
                          <div className='line-times-schedule'>
                            <div
                              className={ cn('time-schedule', {
                                ['active']: openTime && closeTime,
                              }) }
                            >
                              { openTime } - { closeTime }{ ' ' }
                            </div>
                            { openTimeXtra && closeTimeXtra ? (
                              <>
                                -
                                <div
                                  className={ cn('time-schedule', {
                                    ['active']: openTimeXtra && closeTimeXtra,
                                  }) }
                                >
                                  { openTimeXtra } - { closeTimeXtra }{ ' ' }
                                </div>
                              </>
                            ) : null }
                          </div>
                        ) : (
                          <div className='close-day'>Cerrado</div>
                        ) }
                      </div>
                    </div>
                  );
                }) }
              </div>

              { Object.keys(store_schedule_attributes?.schedule)?.length === 0 &&
                store_schedule_attributes?.schedule.constructor === Object && (
                  <div className='message-error max-witdh'>
                    Es necesario que tengas al menos un horario de atención
                    disponible para guardar la sucursal
                  </div>
                ) }
            </div>
          </div>
        </div>
      </div>

      <ul className='message-warning'>
        { errors?.address_attributes?.coordinates?.includes(
          CoordinatesErrors[0]
        ) ? (
          <li>
            Se recibieron 2 tiendas con la direccion duplicada o su direccion
            genera las mismas coordenadas
          </li>
        ) : (
          ''
        ) }
        { errors?.address_attributes?.coordinates?.includes(
          CoordinatesErrors[1]
        ) ? (
          <li>Ya existe una tienda con la misma direccion/coordenadas</li>
        ) : (
          ''
        ) }
        { errors?.address_attributes?.coordinates?.includes(
          'invalid address'
        ) ? (
          <li>
            No se pudieron generar las coordenadas con los datos de direccion
            provistos
          </li>
        ) : (
          ''
        ) }

        { errors?.name?.includes('already used store name') ? (
          <li>Existe un nombre de tienda ya utilizado</li>
        ) : (
          ''
        ) }
      </ul>
      <div>
        <Button type='link' onClick={ onEditForm } className='custom-button'>
          <PencilIcon/> Editar información
        </Button>
      </div>
    </div>
  );
};

DetailsCard.propTypes = {
  data: PropTypes.object,
  onEditForm: PropTypes.func,
};

export default DetailsCard;
