import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { validateDecimal } from '../../Utils/formValidations';
import { numberWithCommas } from '../../Utils/formats';
import isValidTranslation from '../../Utils/isValidTranslation';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { useLoaderContext } from '../../contexts/Loader/LoaderContext';
import { Button, Card, Divider, Modal, Space, TextInput } from '../../ds';
import { getPayment, requestRefund } from '../../services/Application';
import BackPageButton from '../BackPageButton';
import Header from '../Header';
import RadioButton from '../RadioButton';
import {
  REFUND_CREATE_FAIL,
  REFUND_ALREADY_PAID,
  INACTIVE_MERCHANT_ERROR,
} from '../../Utils/constants';
import ErrorModal from '../ErrorModal/ErrorModal';
import './paymentReimbursement.scss';
import AnalyticsEvent, {
  AnalyticsList,
} from '../../services/analytics/AnalyticsEvent';
import {
  DISABLED_PAYMENTS_DESCRIPTION,
  DISABLED_PAYMENTS_TITLE,
} from '../../Utils/payment_constants';
import CancelInProgress from './CancelInProgress/CancelInProgress';

export const REIMBURSEMENT_TOTAL = 'TOTAL';
const REIMBURSEMENT_PARTIAL = 'PARTIAL';

function PaymentReimbursement() {
  const isTracked = useRef(false);
  const { paymentId } = useParams();
  const [payment, setPayment] = React.useState(null);
  const [merchantId, setMerchantId] = useState();
  const viewport = useDeviceContextViewport();
  const { setIsLoaderOpen } = useLoaderContext();
  const [cancelInProgress, setCancelInProgress] = useState(false);
  const [modalSettings, setModalSettings] = useState({
    isOpen: false,
    message: '',
  });
  const [reimbursementType, setReimbursementType] = React.useState(null);
  const [reimbursementTotal, setReimbursementTotal] = React.useState('');
  const router = useHistory();
  const reimbursementReason = 'merchant_refund';
  const { t } = useTranslation();
  const translationPrefix = 'reimbursement.';
  const [refundCreateFailModal, setRefundCreateFailModal] = useState(false);
  const [refundInactiveMerchantErrorOpen, setRefundInactiveMerchantErrorOpen] =
    useState(false);

  useEffect(() => {
    setIsLoaderOpen(true);
    getPayment(paymentId)
      .then(response => {
        setPayment(response.data);
      })
      .catch(err => {
        AnalyticsEvent().errorTrack({
          errorType: 'payment-reimbusement-get-payment-error',
          errorDescription: err,
        });
        console.log(err);
      })
      .finally(() => setIsLoaderOpen(false));
  }, []);

  useEffect(() => {
    if (payment?.available_amount_to_refund) {
      setReimbursementTotal(payment?.available_amount_to_refund ?? '');
    }
    if (payment?.merchant_id) {
      setMerchantId(payment.merchant_id);
    }
  }, [payment]);

  useEffect(() => {
    if (reimbursementType === REIMBURSEMENT_PARTIAL) {
      return;
    }
    setReimbursementTotal(payment?.available_amount_to_refund ?? '');
  }, [reimbursementType]);

  useEffect(() => {
    if (!isTracked.current) {
      AnalyticsEvent().pageView({ page: AnalyticsList.paymentReimbursement });
      isTracked.current = true;
    }
  }, []);

  function setValidAmount(value) {
    if (validateDecimal(value)) {
      const amount = value === '0' ? '' : value;
      setReimbursementTotal(amount);
    }
  }

  const fetchRequestRefund = (paymentId, amount, reason) => {
    setCancelInProgress(true);

    const store =
      payment & payment.store ? payment.store : { name: '', store_id: '' };

    AnalyticsEvent().cancellationSelected({
      storeName: store.name,
      storeId: store.store_id,
      paymentId: paymentId,
      funnelStep: 'Payment Details',
      reimbursementType: reimbursementType,
    });

    const refund =
      reimbursementType === REIMBURSEMENT_TOTAL
        ? {
            payment_id: paymentId,
            reason: reason,
            merchant_id: merchantId,
          }
        : {
            payment_id: paymentId,
            amount: amount,
            reason: reason,
            merchant_id: merchantId,
          };

    requestRefund(refund)
      .then(response => {
        if (response.status === 'success') {
          AnalyticsEvent().cancellationConfirmed({
            storeName: store.name,
            storeId: store.store_id,
            paymentId: paymentId,
            funnelStep: 'Payment Details',
            reimbursementType: reimbursementType,
          });

          setModalSettings({
            isOpen: true,
            message: 'La orden ha sido cancelada',
          });
        }

        setTimeout(function () {
          setCancelInProgress(false);
          router.goBack();
        }, 1000);
      })
      .catch(error => {
        const responseMessage = error?.response?.data?.message;
        const responseCode = error?.response?.data?.code;

        AnalyticsEvent().errorTrack({
          errorType: 'payment-reimbusement-request-refund-error',
          errorDescription: responseMessage,
        });

        if (responseCode === INACTIVE_MERCHANT_ERROR) {
          setRefundInactiveMerchantErrorOpen(true);
          setIsLoaderOpen(false);
          setCancelInProgress(false);
          return;
        }

        if (
          responseMessage === REFUND_CREATE_FAIL ||
          responseMessage === REFUND_ALREADY_PAID
        ) {
          setRefundCreateFailModal(true);
        } else {
          router.push(`/payments/${paymentId}/reimbursement`);
          let message = 'Ocurrió un error, ¿Podrías intentarlo de nuevo?';
          const responseMessageTranslation = responseMessage
            ? t(translationPrefix + responseMessage, { nsSeparator: false })
            : '';
          if (
            responseMessageTranslation &&
            isValidTranslation(translationPrefix, responseMessageTranslation)
          )
            message = responseMessageTranslation;

          setCancelInProgress(false);
          setModalSettings({ isOpen: true, message });
          router.goBack();
        }

        setCancelInProgress(false);
      });
  };

  const handleCloseModal = () => {
    setModalSettings({ isOpen: false, message: '' });
  };

  return (
    <>
      <BackPageButton />
      {modalSettings.isOpen && (
        <Modal
          theme='dark'
          className='modal-size'
          cardType='secondary'
          {...{ isOpen: modalSettings.isOpen }}
        >
          <Space direction='vertical'>
            <div>
              <svg
                className='left-position'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                onClick={handleCloseModal}
              >
                <path
                  d='M11.364 10.95L16.314 6L17.728 7.414L12.778 12.364L17.728 17.314L16.314 18.728L11.364 13.778L6.414 18.728L5 17.314L9.95 12.364L5 7.414L6.414 6L11.364 10.95Z'
                  fill='#18223D'
                />
              </svg>
              <div className='k-ds-body-02 padding-y'>
                {modalSettings.message}
              </div>
              <Button onClick={handleCloseModal}>Entendido</Button>
            </div>
          </Space>
        </Modal>
      )}
      <Divider className='k-ds-m-top-sp-09' margin='spacing-07' />
      <Header
        title='Cancelación de transacción'
        subtitle='Selecciona el tipo de cancelación:'
      />
      <Space
        direction={
          viewport.isTablet | viewport.isDesktop ? 'horizontal' : 'vertical'
        }
        className='radio-option-container k-ds-m-bottom-sp-09'
        justify='space-between'
      >
        <Card
          className={`radio-option ${reimbursementType === REIMBURSEMENT_TOTAL ? 'selected' : ''}`}
          onClick={() => setReimbursementType(REIMBURSEMENT_TOTAL)}
        >
          <Space
            direction='horizontal'
            align='center'
            className='k-ds-m-top-sp-05'
          >
            <RadioButton
              checked={reimbursementType === REIMBURSEMENT_TOTAL}
              onChange={() => {}}
            />
            <div className='k-ds-heading-03'>Cancelación total</div>
          </Space>
        </Card>
        <Card
          className={`radio-option ${reimbursementType === REIMBURSEMENT_PARTIAL ? 'selected' : ''}`}
          onClick={() => setReimbursementType(REIMBURSEMENT_PARTIAL)}
        >
          <Space
            direction='horizontal'
            align='center'
            className='k-ds-m-top-sp-05'
          >
            <RadioButton
              checked={reimbursementType === REIMBURSEMENT_PARTIAL}
              onChange={() => {}}
            />
            <div className='k-ds-heading-03'>Cancelación parcial</div>
          </Space>
        </Card>
      </Space>
      {reimbursementType && (
        <Space direction='vertical'>
          {reimbursementType === REIMBURSEMENT_TOTAL && (
            <>
              <div className='k-ds-heading-03'>Monto total a cancelar</div>
              <div className='k-ds-body-02'>
                <b className='total-amount'>
                  {' '}
                  ${numberWithCommas(payment?.available_amount_to_refund ?? '')}
                </b>
              </div>{' '}
            </>
          )}
          {reimbursementType === REIMBURSEMENT_PARTIAL && (
            <>
              <div className='k-ds-heading-03'>Monto a cancelar</div>
              <TextInput
                className='total-amount-input'
                label='Agrega la cantidad cancelada por los productos devueltos:'
                placeholder='$0.00 MXN'
                value={reimbursementTotal}
                onChange={e => setValidAmount(e.target.value)}
              />{' '}
            </>
          )}
        </Space>
      )}
      <Divider margin='spacing-07' />
      <Space
        direction={
          viewport.isTablet | viewport.isDesktop ? 'horizontal' : 'vertical'
        }
        className='k-ds-m-bottom-sp-09'
        justify='space-between'
      >
        <Space
          direction='vertical'
          className='k-ds-body-01 terms-item k-ds-m-rigth-sp-09'
        >
          <div className='k-ds-heading-02'>Condiciones de la cancelación</div>
          <ol className='terms-list-container'>
            <li>
              Se tendrán los primeros 90 días del año en curso para cancelar
              transacciones hechas en el año anterior.
            </li>
            <li className='k-ds-m-vertical-sp-01'>
              La cancelación se podrá realizar hasta 90 días después de la
              compra.
            </li>
            <li>
              El préstamo no debe ser liquidado por el cliente o cancelado
              anteriormente, de lo contrario, el comercio deberá realizar la
              cancelación de acuerdo a sus políticas de reembolso.
            </li>
            <li>
              El monto a cancelar no puede superar el monto de la transacción.
            </li>
          </ol>
        </Space>
        <Space direction='vertical' className='k-ds-body-01 terms-item'>
          <div className='k-ds-heading-02'>Proceso de la cancelación</div>
          <ol className='terms-list-container'>
            <li>El comercio solicita la cancelación.</li>
            <li className='k-ds-m-vertical-sp-01'>
              {' '}
              Kueski hará la revisión y aprobación inmediatamente.
            </li>
            <li className='k-ds-m-bottom-sp-01'>
              Se actualizará el estatus en el historial de ventas.
            </li>
            <li>El descuento se realizará en el siguiente pago de capital.</li>
          </ol>
        </Space>
      </Space>
      <Space
        direction={
          viewport.isTablet | viewport.isDesktop ? 'horizontal' : 'vertical'
        }
        align={viewport.isTablet | viewport.isDesktop ? 'start' : 'center'}
      >
        <Button
          disabled={!reimbursementType || reimbursementTotal === ''}
          onClick={() =>
            fetchRequestRefund(
              paymentId,
              reimbursementTotal,
              reimbursementReason,
              merchantId
            )
          }
        >
          Solicitar cancelación
        </Button>
        <Button type='simple' onClick={() => router.goBack()}>
          No cancelar
        </Button>
      </Space>
      <CancelInProgress isOpen={cancelInProgress} />
      <ErrorModal
        heading=''
        description={
          <div>
            {
              'Para cancelar esta transacción que ya fue liquidada por el cliente, envía ID de pago, monto y tipo de cancelación (parcial/total) a '
            }
            <a
              href='mailto:cancelacioneskueskipay@kueski.com'
              className='k-ds-text-color-interactive-primary'
            >
              cancelacioneskueskipay@kueski.com
            </a>
            .
          </div>
        }
        defaultOpened={refundCreateFailModal}
        close={setRefundCreateFailModal}
        showClose
      />
      <ErrorModal
        heading={DISABLED_PAYMENTS_TITLE}
        description={<div>{DISABLED_PAYMENTS_DESCRIPTION}</div>}
        defaultOpened={refundInactiveMerchantErrorOpen}
        close={setRefundInactiveMerchantErrorOpen}
        showClose
      />
    </>
  );
}

export default PaymentReimbursement;
