import React, { useEffect, useRef, useState } from 'react';
import { isFeatureEnabled } from '../../../Utils/Feature/feature_flag';
import ImageCarousel from '../../../components/Carousel/ImageCarousel';
import HomeCard from '../../../components/HomeCard/HomeCard';
import { useDeviceContextViewport } from '../../../contexts/Device/DeviceContext';
import CardVideo from './CardVideo';
import styles from './Home.module.scss';
import ModalNotInternet from './ModalNoInternet';
import ModalViewer from './ModalView';
import SearchByPeriod from './SearchByPeriod/SelectPeriod';
import { ReactComponent as PaymentCodeIcon } from './res/paymentCode.svg';
import { getHomeAssets } from '../../../services/Application';
import PaymentSummary, {
  Period,
} from './../../../components/PaymentSummary/PaymentSummary';
import Timer from '../../../Utils/Timer';
import { getPayments } from '../../../services/Application';
import { useLoaderContext } from '../../../contexts/Loader/LoaderContext';
import { currencyFormatter } from '../../../Utils/formats';
import { Link } from 'react-router-dom';
import { useGetMerchant } from '../../../Utils/useGetMerchant';
import Section from './Section';
import DiscoverSection from './DiscoverSection';
import { ReactComponent as HelpSupportIcon } from './res/phone-icon.svg';
import AnalyticsEvent, {
  AnalyticsList,
} from '../../../services/analytics/AnalyticsEvent';

const isHomePageEnabled = isFeatureEnabled('home_page');

const SALES = [
  {
    title: 'Ventas totales:',
    content: '',
    amount: '',
  },
];

const TRANSACTION_CANCEL = [
  {
    title: 'Cancelaciones:',
    content: '',
    amount: '',
  },
];

const INITIAL_SUMMARY_PAYMENT = {
  payments_total: 0,
  payments_count: 0,
  cancelations_total: 0,
  cancelations_count: 0,
};

function Home() {
  const isTracked = useRef(false);
  const merchantInfo = useGetMerchant();
  const hasInStoreChannel = !!merchantInfo?.channels?.find(
    channel => channel.channel === 'instore' && channel.active
  );
  const { setIsLoaderOpen } = useLoaderContext();
  const viewport = useDeviceContextViewport();
  const isMobileOnly = viewport.width <= 929;
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isOnline, setIsOnline] = useState(true);
  const [lastSync, setLastSync] = useState();
  const [filters, setFilters] = useState({
    startDate: Timer().toNowISO(),
    endDate: Timer().toNowISO(),
  });
  const [summaryState, setSummaryState] = useState(INITIAL_SUMMARY_PAYMENT);
  const [assetVideos, setAssetVideos] = useState([]);
  const [assetImages, setAssetImages] = useState([]);

  useEffect(() => {
    if (!isTracked.current) {
      AnalyticsEvent().pageView({ page: AnalyticsList.home });
      AnalyticsEvent().homePage();
      isTracked.current = true;
    }
    const handleOnlineStatus = () => {
      setIsOnline(window.navigator.onLine);
    };

    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOnlineStatus);

    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
    };
  }, []);

  useEffect(() => {
    if (!isOnline) {
      setIsOpen(false);
      setModalIsOpen(true);
    } else {
      getHomeAssets(merchantInfo)
        .then(response => {
          const { data } = response;
          const videos = data.filter(item => item.asset_type === 'video');
          const images = data.filter(item => item.asset_type === 'image');
          setAssetVideos(videos);
          setAssetImages(images);
        })
        .catch(err => {
          AnalyticsEvent().errorTrack({
            errorType: 'get-home-assets-error',
            errorDescription: err,
          });

          console.log(err);
        });
    }
  }, [isOnline]);

  useEffect(() => {
    async function fetch() {
      const paymentHistory =
        JSON.parse(window.localStorage.getItem('paymentHistory')) || {};
      const localLastSync = Timer().diff(
        paymentHistory.lastSync,
        Timer().toNowISO(),
        'hours'
      );
      if (paymentHistory && localLastSync.hours < 24) {
        setFilters(paymentHistory.filters);
        setSummaryState(paymentHistory.summary);
        setLastSync(Timer().toLocal(paymentHistory.lastSync, 'd/M/yyyy-hh:mm'));
      } else {
        await updateSummaryPayment({
          startDate: Timer().toNowISO(),
          endDate: Timer().toNowISO(),
        });
      }
    }

    fetch();
  }, []);

  const handleOpen = (asset = null) => {
    setSelectedVideo(asset);
    setIsOpen(true);
  };

  const handleClose = () => {
    setSelectedVideo(null);
    setIsOpen(false);
  };

  const getImageCarouselItems = mode => {
    const allImages = [];
    assetImages.forEach(asset => {
      asset.media
        .filter(
          item => item.media_type === 'image' && item.media_category === mode
        )
        ?.forEach(item => {
          allImages.push(
            <img
              key={item.media_id}
              src={item.media_url}
              alt={`Image banner ${item.media_id}`}
              className={styles.banner}
            />
          );
        });
    });
    return allImages;
  };

  const getVideoCarouselItems = mode => {
    return assetVideos.map(asset => {
      const firstImageMedia = asset.media.filter(
        item => item.media_type === 'image'
      )?.[0];
      if (!firstImageMedia) return null;
      return (
        <CardVideo
          key={`${firstImageMedia.media_id}_${mode}`}
          path={firstImageMedia.media_url}
          onClick={() => {
            AnalyticsEvent().videoClicked();
            handleOpen(asset);
          }}
          title={asset.title}
          description={asset.description}
        />
      );
    });
  };

  const updateSummaryPayment = async ({ startDate, endDate }) => {
    try {
      setIsLoaderOpen(true);
      const localStartDate = Timer().toLocal(startDate);
      const localEndDate = Timer().toLocal(endDate);
      const response = await getPayments(
        '',
        '',
        1,
        20,
        localStartDate,
        localEndDate
      );
      window.localStorage.setItem(
        'paymentHistory',
        JSON.stringify({
          filters: { startDate, endDate },
          lastSync: Timer().toNowISO(),
          summary: response.data.summary,
        })
      );

      setSummaryState(response.data.summary);
    } catch (e) {
      console.error(e);
      setSummaryState(INITIAL_SUMMARY_PAYMENT);
    } finally {
      setIsLoaderOpen(false);
      setLastSync(Timer().toNowFormat('d/M/yyyy-hh:mm'));
    }
  };

  if (!isHomePageEnabled) return null;

  return (
    <div className={styles.container}>
      <ModalNotInternet
        isOpen={modalIsOpen}
        handleClose={() => setModalIsOpen(false)}
      />
      <ModalViewer
        assetSelected={selectedVideo}
        allAssets={assetVideos}
        isOpen={isOpen}
        onRequestClose={handleClose}
      />
      <div className={styles.welcomeBanner}>
        <div className={styles.greeting}>¡Hola!</div>
        <span className={styles.message}>
          Aquí encontrarás actualizaciones, noticias y recursos diseñados para
          ayudarte a sacar el máximo provecho de tu comercio.
        </span>
      </div>
      <Section
        title='Resumen del Reporte de transacciones'
        hint='Consulta la información general de las ventas de tu comercio'
      />

      <div className={`k-ds-width-full ${styles.summary}`}>
        <div className={`k-ds-width-full ${styles.period}`}>
          <SearchByPeriod
            defaultValue={filters}
            className={styles.selectByPeriod}
            onChange={dates => {
              updateSummaryPayment(dates);
              setFilters(dates);
            }}
          />
          <div className='k-ds-heading-01'>
            Datos actualizados al momento: {lastSync}
          </div>
        </div>
        <div className={styles.paymentBoxSummary}>
          <PaymentSummary
            period={
              <Period startDate={filters.startDate} endDate={filters.endDate} />
            }
            sales={SALES.map(item => {
              return {
                title: item.title,
                content: currencyFormatter(summaryState.payments_total),
                amount: summaryState.payments_count.toLocaleString(),
              };
            })}
            cancellations={TRANSACTION_CANCEL.map(item => {
              return {
                title: item.title,
                content: currencyFormatter(summaryState.cancelations_total),
                amount: summaryState.cancelations_count.toLocaleString(),
              };
            })}
          />
        </div>
        <div
          className={`k-ds-width-full k-ds-heading-02 ${styles.paymentSummaryLink}`}
        >
          Para mayor detalle, consulta tu{' '}
          <Link to='/payments' className='link-button'>
            Historial de ventas
          </Link>
        </div>
      </div>

      <Section
        title='Accesos rápidos'
        hint='Navega con facilidad a las funciones esenciales de tu comercio.'
        justifyContent={!hasInStoreChannel ? 'center' : ''}
      >
        {hasInStoreChannel && (
          <HomeCard
            isLink
            isQuickAccess
            path='/orders/create-by-code'
            text='Cobrar con Código de pago'
          >
            <PaymentCodeIcon />
          </HomeCard>
        )}
        {hasInStoreChannel && (
          <HomeCard
            isLink
            isQuickAccess
            path='/orders/create-by-qr'
            text='Cobrar con Código QR'
          >
            <PaymentCodeIcon />
          </HomeCard>
        )}
        <HomeCard isLink isQuickAccess path='/help' text='Contacto'>
          <div className={styles.helpIcon}>
            <HelpSupportIcon />
          </div>
        </HomeCard>
        <HomeCard isLink isQuickAccess path='/faq' text='Preguntas frecuentes'>
          <PaymentCodeIcon />
        </HomeCard>
      </Section>

      <DiscoverSection
        items={getImageCarouselItems(isMobileOnly ? 'mobile' : 'desktop')}
        isMobileOnly={isMobileOnly}
      />

      {isMobileOnly && (
        <div className={styles.infoMobile}>
          <strong>Renovamos las opciones de nuestro menú:</strong>
          <ul>
            <li>
              Ahora, las órdenes de pago cambiaron por{' '}
              <strong>Canales de cobro.</strong>
            </li>
            <li>
              Descubre el <strong>Código de Pago y el Código QR</strong>, tus
              formas sencillas de cobrar con Kueski Pay.
            </li>
            <li>
              El historial de órdenes ahora se llama{' '}
              <strong>Historial de cobros.</strong>
            </li>
          </ul>
        </div>
      )}
      <hr className={styles.solidDivider} />

      <Section
        title='¡Explora Kueski Pay!'
        hint='Accede a videos tutoriales y conoce todas las funcionalidades en el Portal.'
      />
      <ImageCarousel
        className='carouselPadding'
        slidesToShow={isMobileOnly ? 1.1 : 3}
        speed={25000}
        items={getVideoCarouselItems('desktop')}
      />
      <div className={styles.modalViewer}>
        <span onClick={() => handleOpen(null)}>Ver todos los vídeos</span>
      </div>
    </div>
  );
}

export default Home;
