import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Auth from '../../Utils/Auth/auth';
import { isFeatureEnabled } from '../../Utils/Feature/feature_flag';
import { validateNumerical } from '../../Utils/formValidations';
import {
  getDateString,
  getDateTime,
  hyphenEveryFourChars,
} from '../../Utils/formats';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { useLoaderContext } from '../../contexts/Loader/LoaderContext';
import {
  Button,
  Pagination,
  PaginationMobile,
  Space,
  TabGroup,
  TabItem,
  Tag,
} from '../../ds';
import { getOrders } from '../../services/Application';
import { Message } from '../DS/Message';
import { SearchBar } from '../DS/SearchBar';
import { Select } from '../DS/Select';
import EmptySearchResults from '../EmptySearchResults/index';
import PaymentOrderHistoryFilter from '../PaymentOrderHistoryFilter/PaymentOrderHistoryFilter';
import { TdResponsive, ThResponsive, TrCollapsable } from '../ResponsiveTable';
import { ResponsiveTable } from '../ResponsiveTable/ResponsiveTable';
import ToolBar from '../ToolBar/ToolBar';
import { ReactComponent as FilterIconMobile } from './filter-icon-mobile.svg';
import { ReactComponent as FilterIcon } from './filter-icon.svg';
import { ReactComponent as NoPendingOrders } from './no-pendingorders-icon.svg';
import './paymentOrderHistory.scss';
import { ReactComponent as RefreshIconMobile } from './refresh-icon-mobile.svg';
import { ReactComponent as RefreshIcon } from './refresh-icon.svg';
import AnalyticsEvent, {
  AnalyticsList,
} from '../../services/analytics/AnalyticsEvent';
const keyForAllStores = 'allStores';

const statusColorTag = {
  AUTORIZADO: 'teal',
  CANCELADO: 'red',
  EXPIRADO: 'purple',
  PENDIENTE: 'orange',
  'NO AUTORIZADO': 'grey',
  'N/A': 'grey',
};
// const statusMerchantColorTag = {
//   ACEPTADO: 'teal-outline',
//   CANCELADO: 'red-outline',
//   RECHAZADO: 'red-outline',
//   PENDIENTE: 'orange-outline',
//   EXPIRADO: 'purple',
//   '-': 'grey',
// };

const tableHeaders = {
  paymentId: 'ID de pago',
  storeName: 'Sucursal',
  amount: 'Monto',
  createdAt: 'Fecha de cobro',
  kueskiAuthorization: 'Autorización de Kueski',
  storeAuthorization: 'Confirmación del comercio',
  details: 'Detalles',
};

function PaymentOrderHistory() {
  const autoAcceptOrderEnabled = isFeatureEnabled('auto_accept_order');
  const viewport = useDeviceContextViewport();
  const limit = 20;
  const user = Auth.userData();
  if (!user || !user.stores) {
    return '';
  }
  const hasMoreThanOneStore = user.stores.length > 1;
  const history = useHistory();
  const [searchState, setSearchState] = useState('');
  const [searchBarIconModeOpen, setSearchBarIconModeOpen] = useState(false);

  const [searchBy, setSearchBy] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const pendingTab = 'pending';
  const allTab = 'all';

  const [activeTab, setActiveTab] = useState(
    autoAcceptOrderEnabled ? allTab : pendingTab
  );
  const [expandedItem, setExpandedItem] = useState('');

  const [pendingCount, setPendingCount] = useState(0);
  const [stores, setStores] = React.useState([]);
  const [storeId, setStoreId] = React.useState('');

  const [orders, setOrders] = useState([]);
  const [pagination, setPagination] = useState({
    total_pages: 1,
    total_items: 0,
    actual_page: 1,
  });

  const [isRefresh, setIsRefresh] = useState(false);
  const [lastSync, setLastSync] = useState('');
  const [filterBarOpen, setFilterBarOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const isTracked = useRef(false);
  const { setIsLoaderOpen } = useLoaderContext();

  const statusFilter = filters.statusFilter || [];
  const statusActiveFilters = statusFilter.filter(status => status.checked);
  const creationDateActiveFilters =
    filters.startDate && filters.endDate
      ? {
          startDate: filters.startDate,
          endDate: filters.endDate,
        }
      : undefined;

  const fetchOrders = (
    storeId,
    searchBy,
    activeTab,
    currentPage,
    limit,
    filterParams
  ) => {
    setIsLoaderOpen(true);
    setExpandedItem('');
    const searchStoreId = storeId === keyForAllStores ? null : storeId;
    getOrders(
      searchStoreId,
      searchBy,
      activeTab === pendingTab ? 'pending' : '',
      currentPage,
      limit,
      filterParams
    )
      .then(response => {
        setIsLoaderOpen(false);
        setOrders(response.data.orders);
        setPagination(response.data.pagination);
        if (activeTab === pendingTab) {
          setPendingCount(response.data.pagination.total_items);
        }
      })
      .catch(error => {
        AnalyticsEvent().errorTrack({
          errorType: 'payment-hisotory-get-orders-error',
          errorDescription: error,
        });
        setIsLoaderOpen(false);
        console.log(error);
      })
      .finally(() => {
        setLastSync(getDateTime(new Date()));
      });
  };

  useEffect(() => {
    if (!isTracked.current) {
      AnalyticsEvent().pageView({ page: AnalyticsList.paymentsOrderHistory });
      isTracked.current = true;
    }
  }, []);

  useEffect(() => {
    const filterParams = { statusActiveFilters, creationDateActiveFilters };
    fetchOrders(storeId, searchBy, activeTab, currentPage, limit, filterParams);
  }, [searchBy, currentPage, activeTab, limit, storeId, filters]);

  useEffect(() => {
    if (!isRefresh) {
      return;
    }

    fetchOrders(storeId, searchBy, activeTab, currentPage, limit);
    setIsRefresh(false);
  }, [isRefresh]);

  useEffect(() => {
    if (hasMoreThanOneStore) {
      const defaultStore = {
        text: 'Todas las Sucursales',
        value: keyForAllStores,
      };
      setStores(
        [defaultStore].concat(
          user.stores.map(store => ({
            text: store.name,
            value: store.store_id,
          }))
        )
      );
      setStoreId(defaultStore.value);
    }
  }, []);

  function goDetails(path) {
    history.push(path);
    const contentArea = document.getElementById('content-area');
    contentArea.scrollTop = 0;
  }

  function search(search) {
    setActiveTab(allTab);
    const searchNoHyphens = search.split('-').join('');
    setCurrentPage(1);
    setSearchBy(searchNoHyphens);
  }

  function setValidSearch(value) {
    const valueNoHyphens = value.split('-').join('');
    if (validateNumerical(valueNoHyphens)) {
      let searchValue = hyphenEveryFourChars(valueNoHyphens);
      const lastHyphenIndex = value.lastIndexOf('-');
      const hyphenIsOnEveryFour =
        (lastHyphenIndex - Math.floor((valueNoHyphens.length - 1) / 4)) % 4 ===
        0;

      if (
        lastHyphenIndex !== -1 &&
        lastHyphenIndex === value.length - 1 &&
        hyphenIsOnEveryFour
      ) {
        searchValue = `${searchValue}-`;
      }
      setSearchState(searchValue);
    }
  }

  function clearSearch() {
    setSearchState('');
    setSearchBy('');
  }

  const PaginationComponent = viewport.isMobile ? PaginationMobile : Pagination;

  const searchBarWidth = viewport.isMobile ? '100%' : '437px';
  const searchBarIconMode = viewport.isMobile && !viewport.isSmallTablet;

  const RefreshButton = () => {
    return (
      <Button type='link' size='small' onClick={() => setIsRefresh(true)}>
        <div className='filter-icon'>
          <RefreshIcon className='k-ds-m-rigth-sp-03' />
          {searchBarIconModeOpen ? null : <span>&nbsp;Actualizar</span>}
        </div>
        <RefreshIconMobile className='custom-update-icon' />
      </Button>
    );
  };

  const getEmptySearchResults = () => {
    if (activeTab === pendingTab && !searchBy) {
      return (
        <div className='no-pending-orders'>
          <EmptySearchResults
            header='No tienes órdenes por aceptar'
            subHeader=''
            icon={<NoPendingOrders />}
          />
        </div>
      );
    }
    return (
      <EmptySearchResults
        subHeader='¿Puedes intentarlo de otra manera o seleccionar una sucursal diferente?'
        clearAction={() => clearSearch()}
      />
    );
  };

  function HandleStoreChange(newStoreId) {
    setCurrentPage(1);
    setSearchBarIconModeOpen(false);
    setStoreId(newStoreId);
  }

  function handleActiveTabChange(tab) {
    setActiveTab(tab);
    setSearchBarIconModeOpen(false);
  }

  const TimeoutWarning = () => {
    return (
      !autoAcceptOrderEnabled && (
        <>
          <div className='k-ds-heading-02 k-ds-m-bottom-sp-06'>
            Revisa los pagos que tienes pendiente por aceptar o se cancelarán
            después de 2 horas.
          </div>
          <Message
            className='k-ds-m-bottom-sp-05'
            message='Tu cliente no puede sacar los productos de la tienda si no has aceptado la orden de compra.'
            type='warning'
          />
        </>
      )
    );
  };

  const { isMobile } = viewport;
  const storeSelectorColumnsClass = isMobile ? 'one-column' : 'two-columns';

  const onFilterChange = filters => {
    setSearchBy('');
    setSearchState('');
    setFilters(filters);
    setFilterBarOpen(false);
  };

  const isAnyFilterActive =
    statusActiveFilters.length > 0 || creationDateActiveFilters;

  const TagsContainer = () => {
    return (
      <Space className='tags-container'>
        {statusActiveFilters.map((status, index) => (
          <Tag
            key={index}
            color='orange-outline'
            className='k-ds-m-right-sp-04 tag'
            onClick={() => setFilterBarOpen(true)}
          >
            ESTATUS: {status.label}
          </Tag>
        ))}
        {creationDateActiveFilters && (
          <Tag
            color='orange-outline'
            className='k-ds-m-right-sp-04 tag'
            onClick={() => setFilterBarOpen(true)}
          >
            FECHA DE CREACIÓN:{' '}
            {getDateString(creationDateActiveFilters.startDate)} -{' '}
            {getDateString(creationDateActiveFilters.endDate)}
          </Tag>
        )}
      </Space>
    );
  };

  return (
    <div className='k-ds-width-full payment-order-history'>
      <div className='header'>Historial de cobros</div>
      {!viewport.isMobile ? (
        <div className='k-ds-subheading-02'>
          Consulta el estado de las cobros de tus clientes
        </div>
      ) : null}
      {stores.length > 0 && (
        <div className='store-select-dropdown'>
          <div className={storeSelectorColumnsClass}>
            <Select
              type='text'
              className='store-select'
              label='Selecciona una sucursal'
              placeholder='Elige una opción'
              required
              name='sucursal'
              options={stores}
              value={storeId}
              onChange={val => HandleStoreChange(val)}
            />
          </div>
        </div>
      )}
      {!autoAcceptOrderEnabled && (
        <TabGroup className='k-ds-m-top-sp-09 payment-history-pending-tab-group'>
          <TabItem
            style={{ width: '50%', alignItems: 'center' }}
            selected={activeTab === pendingTab}
            onClick={() => handleActiveTabChange(pendingTab)}
          >
            <div>
              Órdenes por aceptar
              {pendingCount > 0 && (
                <div className='k-ds-m-left-sp-06 payment-history-pending-tag'>
                  {pendingCount}
                </div>
              )}
            </div>
          </TabItem>
          <TabItem
            style={{ width: '50%', alignItems: 'center' }}
            selected={activeTab === allTab}
            onClick={() => handleActiveTabChange(allTab)}
          >
            Otras órdenes
          </TabItem>
        </TabGroup>
      )}
      <Space
        direction='horizontal'
        justify='space-between'
        className='action-header'
      >
        <SearchBar
          placeholder='Buscar por ID de pago'
          search={searchState}
          setSearch={setValidSearch}
          onSearch={() => search(searchState)}
          clearSearch={() => clearSearch()}
          width={searchBarWidth}
          iconMode={searchBarIconMode}
          iconModeOpen={searchBarIconModeOpen}
          setIconModeOpen={() => {
            setSearchBarIconModeOpen(!searchBarIconModeOpen);
          }}
        />
        <Space className='order-filters'>
          {isFeatureEnabled('filter_payment_order_history') && (
            <Button
              type='link'
              size='small'
              onClick={() => setFilterBarOpen(true)}
            >
              <div className='filter-icon'>
                <FilterIcon className='k-ds-m-right-sp-03' /> Filtrar
              </div>
              <FilterIconMobile className='k-ds-m-right-sp-03 filter-icon-mobile' />
            </Button>
          )}
          <RefreshButton />
        </Space>
      </Space>
      {isAnyFilterActive && (
        <Space
          align='center'
          justify='space-between'
          className='k-ds-m-top-sp-06 k-ds-m-bottom-sp-07 order-filter-tags'
        >
          <Space className='tags-info k-ds-width-full'>
            <Space justify='space-between'>
              <Space className='k-ds-m-bottom-sp-06 applied-filters'>
                <span className='k-ds-body-01'> Filtros aplicados:</span>
              </Space>
              <Space className='k-ds-body-01 k-ds-m-top-sp-03 filter-result'>
                Mostrando: {orders.length || 0} resultados
              </Space>
            </Space>
            <TagsContainer />
          </Space>
          <Space className='k-ds-body-01 k-ds-m-top-sp-03 filter-result-mobile'>
            Mostrando: {orders.length || 0} resultados
          </Space>
        </Space>
      )}
      <ToolBar isOpen={filterBarOpen} onClose={() => setFilterBarOpen(false)}>
        <PaymentOrderHistoryFilter
          filters={filters}
          onFilterChange={filters => onFilterChange(filters)}
        />
      </ToolBar>
      {activeTab === 'pending' && <TimeoutWarning />}
      <ResponsiveTable
        type='b2b'
        className='k-ds-width-full payment-order-history-table'
      >
        <thead>
          <tr>
            <th>{tableHeaders.paymentId}</th>
            <ThResponsive devices='desktop tablet mobile'>
              {tableHeaders.storeName}
            </ThResponsive>
            <th>{tableHeaders.amount}</th>
            <ThResponsive devices='desktop mobile'>
              {tableHeaders.createdAt}
            </ThResponsive>
            <th>{tableHeaders.kueskiAuthorization}</th>
            <th>{tableHeaders.details}</th>
          </tr>
        </thead>
        <tbody>
          {orders.map(item => {
            const isExpanded = expandedItem === item.order_id;
            const kueskiStatus = item.kueski_authorization;
            return (
              <React.Fragment key={item.order_id}>
                <tr aria-expanded={isExpanded} className='row-hover'>
                  <TdResponsive dataTitle={tableHeaders.paymentId}>
                    {hyphenEveryFourChars(item.payment_id || '')}
                  </TdResponsive>
                  <TdResponsive
                    devices='desktop tablet mobile'
                    dataTitle={tableHeaders.storeName}
                  >
                    {item.store_name}
                  </TdResponsive>
                  <TdResponsive
                    noWrap
                    dataTitle={tableHeaders.amount}
                  >{`$ ${item.total}`}</TdResponsive>
                  <TdResponsive
                    devices='desktop mobile'
                    dataTitle={tableHeaders.createdAt}
                  >
                    {item.created_at_formatted}
                  </TdResponsive>
                  <td data-title={tableHeaders.kueskiAuthorization}>
                    <Tag color={statusColorTag[kueskiStatus]}>
                      {kueskiStatus}
                    </Tag>
                  </td>
                  <TdResponsive
                    type='toggle'
                    devices='tablet'
                    isCollapsed={!isExpanded}
                    dataTitle={tableHeaders.details}
                    onClick={() =>
                      setExpandedItem(isExpanded ? '' : item.order_id)
                    }
                  >
                    <button
                      onClick={() =>
                        goDetails(`/orders/${item.order_id}/${item.store_id}`)
                      }
                      className='link-button'
                    >
                      {activeTab === pendingTab
                        ? 'Gestionar Orden'
                        : 'Ver detalles'}
                    </button>
                  </TdResponsive>
                </tr>
                <TrCollapsable isCollapsed={!isExpanded} devices='tablet'>
                  <TdResponsive
                    type='header'
                    dataTitle={tableHeaders.createdAt}
                    colSpan={4}
                  >
                    {item.created_at_formatted}
                  </TdResponsive>
                  <td style={{ maxWidth: '3rem' }}>
                    <button
                      onClick={() =>
                        goDetails(`/orders/${item.order_id}/${item.store_id}`)
                      }
                      className='link-button'
                    >
                      {activeTab === pendingTab
                        ? 'Gestionar Orden'
                        : 'Ver detalles'}
                    </button>
                  </td>
                </TrCollapsable>
              </React.Fragment>
            );
          })}
        </tbody>
      </ResponsiveTable>
      {orders.length > 0 ? (
        <PaginationComponent
          totalPages={pagination.total_pages}
          totalElements={pagination.total_items}
          maxVisibleNumbers={6}
          currentPage={pagination.actual_page}
          elementsPerPage={limit}
          changePage={page => setCurrentPage(page)}
        />
      ) : (
        getEmptySearchResults()
      )}
      <Space
        className='k-ds-m-top-sp-09 k-ds-heading-01'
        id='last-sync'
        direction='horizontal'
        justify='end'
      >
        Última actualización: {lastSync}
      </Space>
    </div>
  );
}

export default PaymentOrderHistory;
