import { makeAutoObservable, runInAction } from 'mobx';

const redirectSeconds = 10;

export class PaymentValidationModalStore {
  redirectSeconds = redirectSeconds;
  interval = null;
  timerRunning = false;

  constructor() {
    makeAutoObservable(this);
  }

  clearTimer = () => {
    clearInterval(this.interval);
    this.redirectSeconds = redirectSeconds;
    this.timerRunning = false;
  };

  startTimer = () => {
    if (this.timerRunning) return;
    this.timerRunning = true;
    this.redirectSeconds = redirectSeconds;
    this.interval = setInterval(() => {
      runInAction(() => {
        this.redirectSeconds -= 1;
      });
    }, 1000);
  };
}
