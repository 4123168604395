import React, { useMemo, useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import SearchByOrderId from './components/SearchByOrderId/SearchByOrdeId';
import SummaryInfo from './summary//SummaryInfo';
import ReportPeriodTable from './table/ReportPeriodTable';
import MerchantPaymentsService from '../../services/MerchantPaymentsService';
import { ReportByPeriodColumns } from './table/TransactionReportColums';
import { useLoaderContext } from '../../contexts/Loader/LoaderContext';
import styles from './styles.module.scss';
import { observer } from 'mobx-react-lite';
import SelectShedule from './components/SelectShedule/SelectShedule';
import Brand from '../ReportFilters/Brand/Brand';
import MultipleChoice from './../DS/Select/MultipleChoice/MultipleChoice';
import { useGetMerchants } from './../../Utils/useGetMerchants';
import { MERCHANT_TRANSACTION_TYPE_FILTER } from './../../Utils/constants';
import getChannelByRole from './../../Utils/getChannelByRole';
import useQueryPaymentReport from './hooks/useQueryPaymentReport';
import useQueryPaymentSchedules from './hooks/useQueryPaymentSchedules';
import useQueryPaymentSummary from './hooks/useQueryPaymentSummary';
import Timer from '../../Utils/Timer';

const formatSchedules = { start: 'd \'de\' MMMM', end: 'd \'de\' MMMM \'del\' y' };

const initShedule = {
  rangeEnd: '',
  rangeStart: '',
  paymentScheduleId: 0,
  scheduleName: '',
};

const ReportByPeriod = () => {
  const queryClient = useQueryClient();
  const { setIsLoaderOpen } = useLoaderContext();
  const channelOptions = getChannelByRole();
  const merchants = useGetMerchants();
  const columns = useMemo(() => ReportByPeriodColumns, []);
  const [selectedSchedule, setSelectedSchedule] = useState(initShedule);

  const queryPaymentSchedules = useQueryPaymentSchedules();
  const queryPaymentSummary = useQueryPaymentSummary();
  const queryPaymentReport = useQueryPaymentReport();

  const handleOnCleanFilters = () => {
    queryClient.resetQueries();
    setSelectedSchedule(initShedule);
    queryPaymentSummary.initFilters();
    queryPaymentReport.initFilters();
  };

  const handleChangePageSize = pageSize => {
    queryPaymentReport.changePageSize(pageSize);
  };

  const handleChangePage = page => {
    queryPaymentReport.changePage(page);
  };

  const handleOnDownloadReport = async () => {
    try {
      setIsLoaderOpen(true);
      await MerchantPaymentsService().exportPaymentReport({
        paymentScheduleId: queryPaymentReport.filters.paymentScheduleId,
        transactionType: queryPaymentReport.filters.transactionType,
        channel: queryPaymentReport.filters.channel,
        orderId: queryPaymentReport.filters.orderId,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoaderOpen(false);
    }
  };

  const handleOnSeatchOrderId = orderId => {
    queryPaymentReport.updateFilters({
      ...queryPaymentReport.filters,
      orderId: orderId,
    });
  };

  useEffect(() => {
    const { data } = queryPaymentSchedules.queryResult;

    if (!data || !data.dateSchedules || data.dateSchedules.length === 0) {
      setSelectedSchedule(initShedule);
      queryPaymentSummary.initFilters();
      queryPaymentReport.initFilters();
      return;
    }

    const scheduleData = data.dateSchedules[0].dateSchedules[0];
    const selectedSchedule = {
      ...scheduleData,
      scheduleName: `${Timer().toLocal(scheduleData.rangeStart, formatSchedules.start)} - ${Timer().toLocal(scheduleData.rangeEnd, formatSchedules.end)}`,
    };

    setSelectedSchedule(selectedSchedule);
    queryPaymentReport.initFilters({
      paymentScheduleId: scheduleData.paymentScheduleId,
      merchantId: queryPaymentSchedules.filters.merchantId,
    });
    queryPaymentSummary.updateFilters({
      paymentScheduleId: scheduleData.paymentScheduleId,
    });
  }, [queryPaymentSchedules.filters, queryPaymentSchedules.queryResult.data]);

  return (
    <div className={styles.containerReport}>
      <div className={styles.filters}>
        <div className='k-ds-heading-02'>Filtrar por:</div>

        {merchants.length > 0 ? (
          <div className={styles.brandFilter}>
            <Brand
              value={queryPaymentSchedules.filters.merchantId}
              onChange={merchantId => {
                queryPaymentSchedules.updateFilters({
                  ...queryPaymentSchedules.filters,
                  merchantId,
                });
              }}
            />
          </div>
        ) : null}

        <div className={styles.filter}>
          <SelectShedule
            inputClassName='filter-by-period'
            helper='Consulta hasta 3 meses atrás.'
            value={selectedSchedule}
            groupOptions={queryPaymentSchedules.queryResult.data.dateSchedules}
            onChange={schedule => {
              setSelectedSchedule(schedule);
              queryPaymentSummary.updateFilters({
                paymentScheduleId: schedule.paymentScheduleId,
              });
              queryPaymentReport.updateFilters({
                ...queryPaymentReport.filters,
                paymentScheduleId: schedule.paymentScheduleId,
              });
            }}
          />
        </div>

        <div className={styles.filter}>
          <MultipleChoice
            className='filter-by-channel'
            type='Canal'
            value={channelOptions.filter(item =>
              queryPaymentReport.filters.channel.includes(item.value)
            )}
            options={channelOptions}
            onChange={selectedChannels => {
              queryPaymentReport.updateFilters({
                ...queryPaymentReport.filters,
                channel: selectedChannels.map(item => item.value),
              });
            }}
          />
        </div>

        <div className={styles.filter}>
          <MultipleChoice
            className='filter-by-transaction'
            type='Transacción'
            options={MERCHANT_TRANSACTION_TYPE_FILTER}
            value={MERCHANT_TRANSACTION_TYPE_FILTER.filter(item =>
              queryPaymentReport.filters.transactionType.includes(item.value)
            )}
            onChange={selectedTransactionType => {
              queryPaymentReport.updateFilters({
                ...queryPaymentReport.filters,
                transactionType: selectedTransactionType.map(
                  item => item.value
                ),
              });
            }}
          />
        </div>
      </div>

      <div className={styles.summaryInfo}>
        <SummaryInfo
          scheduleName={selectedSchedule.scheduleName}
          {...queryPaymentSummary.queryResult.data}
        />
      </div>

      <SearchByOrderId
        value={queryPaymentReport.filters.orderId}
        onSearch={handleOnSeatchOrderId}
      />

      <div className={styles.table}>
        <ReportPeriodTable
          data={queryPaymentReport.queryResult.data.transactions}
          scheduleName={selectedSchedule ? selectedSchedule.scheduleName : ''}
          columns={columns}
          options={{
            onCleanFilter: handleOnCleanFilters,
            onChangePageSize: handleChangePageSize,
            onChangePage: handleChangePage,
            onDownloadReport: handleOnDownloadReport,
            totalElements: queryPaymentReport.queryResult.data.total_records,
            totalPages: queryPaymentReport.queryResult.data.total_pages,
            currentPage: queryPaymentReport.filters.page
              ? queryPaymentReport.filters.page
              : 1,
          }}
        />
      </div>
    </div>
  );
};

export default observer(ReportByPeriod);
