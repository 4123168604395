import { Table } from '../../ds';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { daysOfTheWeek, hoursOfTheDay } from '../../Utils/constants';
import { ReactComponent as CloseIcon } from '../../common/assets/close-icon.svg';
import { Select } from '../../components/DS/Select';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import Checkbox from '../Checkbox';
import SectionHeading from '../SectionHeading';

const StoreScheduleElement = ({
  day,
  isActive = false,
  isTouched,
  openHour = '',
  closeHour = '',
  extraOpenHour = '',
  extraCloseHour = '',
  onChanged,
}) => {
  const viewport = useDeviceContextViewport();
  const toSelectElement = value => ({ value, text: `${value} hrs` });
  const hours = hoursOfTheDay
    .slice(0, hoursOfTheDay.length - 1)
    .map(toSelectElement);
  const isMounted = useRef(false);

  const [open, setOpen] = useState(openHour);
  const [closed, setClosed] = useState(closeHour);
  const [extraOpen, setExtraOpen] = useState(extraOpenHour);
  const [extraClosed, setExtraClosed] = useState(extraCloseHour);
  const [active, setActive] = useState(isActive);
  const [closeHours, setCloseHours] = useState([]);
  const [extraHours, setExtraHours] = useState([]);
  const [extraCloseHours, setExtraCloseHours] = useState([]);
  const [showExtraHours, setShowExtraHours] = useState(extraOpenHour !== '');
  const [hoverSelected, setHoverSelected] = useState(false);

  const setHoursBasedOnIndex = hourIndex =>
    hoursOfTheDay
      .slice(hourIndex + 1, hoursOfTheDay.length)
      .map(toSelectElement);

  useEffect(() => {
    if (open !== openHour) setOpen(openHour);
  }, [openHour]);

  useEffect(() => {
    if (closed !== closeHour) setClosed(closeHour);
  }, [closeHour]);

  useEffect(() => {
    setCloseHours(setHoursBasedOnIndex(hoursOfTheDay.indexOf(open)));
  }, [open]);

  useEffect(() => {
    setExtraHours(setHoursBasedOnIndex(hoursOfTheDay.indexOf(closed)));
  }, [closed]);

  useEffect(() => {
    setExtraCloseHours(setHoursBasedOnIndex(hoursOfTheDay.indexOf(extraOpen)));
  }, [extraOpen]);

  const handleSchedule = ({ active, open, closed, extraOpen, extraClosed }) => {
    if (!isMounted.current) {
      return;
    }
    if (onChanged) {
      const dayLowerCase = day.toLowerCase();
      const hours = [
        {
          active,
          open: active ? open : '',
          closed: active ? closed : '',
          isCompleted: open !== '' && closed !== '',
          isTouched,
        },
      ];
      if (showExtraHours) {
        hours.push({ active: true, open: extraOpen, closed: extraClosed });
      }
      const daySchedule = { [dayLowerCase]: hours };
      onChanged(daySchedule, dayLowerCase);
    }
  };

  useEffect(() => {
    isMounted.current = true;
  }, []);

  return (
    <>
      <tr className={active ? '' : 'row-separator-border'}>
        <td className='checkbox-cell'>
          <Checkbox
            defaultChecked={active}
            onChange={event => {
              setActive(event.target.checked);
              handleSchedule({
                active: event.target.checked,
                open,
                closed,
                extraOpen,
                extraClosed,
              });
            }}
            filled={true}
          ></Checkbox>
        </td>
        <td className='day-cell'> {daysOfTheWeek[day]} </td>
        <td className={`time-input-cell ${active ? 'isActive' : 'isInactive'}`}>
          <Select
            className='time-select'
            placeholder='Hora de apertura'
            options={hours}
            type='outline'
            value={open}
            onChange={value => {
              setOpen(value);
              handleSchedule({
                active,
                open: value,
                closed,
                extraOpen,
                extraClosed,
              });
            }}
            disabled={!active}
          />
        </td>
        <td className='time-separator-cell'> a </td>
        <td
          className={`time-input-cell last ${
            active ? ' isActive' : 'isInactive'
          }`}
        >
          <Select
            className='time-select'
            placeholder='Hora de cierre'
            options={closeHours}
            type='outline'
            value={closed}
            onChange={value => {
              setClosed(value);
              handleSchedule({
                active,
                open,
                closed: value,
                extraOpen,
                extraClosed,
              });
            }}
            disabled={!active || !open}
          />
        </td>
        <td className='spacer-cell omit-border' />
      </tr>
      {active && (
        <tr
          className={`add-schedule ${
            showExtraHours ? '' : 'row-separator-border'
          }`}
        >
          <td className='empty-cell'></td>
          <td className='empty-cell'></td>
          <td className='add-schedule-cell' colSpan='3'>
            {showExtraHours ? (
              <p className='add-schedule-cell-title'>Horario adicional:</p>
            ) : (
              <button
                className='link-button add-schedule-link'
                onClick={() => {
                  setShowExtraHours(true);
                  setHoverSelected(false);
                }}
              >
                Agregar otro horario
              </button>
            )}
          </td>
          <td className='spacer-cell omit-border' />
        </tr>
      )}
      {active && showExtraHours && (
        <tr
          className={`row-separator-border extra-schedule ${
            hoverSelected && !viewport.isMobile ? 'hover-selected' : ''
          }`}
        >
          <td className='empty-cell'>
            <div className='cell-bg-shadow'></div>
          </td>
          <td className='empty-cell'>
            <div className='cell-bg-shadow'></div>
          </td>
          <td
            className={`time-input-cell ${active ? 'isActive' : 'isInactive'}`}
          >
            <div className='cell-bg-shadow'></div>{' '}
            <Select
              className='time-select'
              placeholder='Hora de apertura'
              options={extraHours}
              type='outline'
              value={extraOpen}
              onChange={value => {
                setExtraOpen(value);
                handleSchedule({
                  active,
                  open,
                  closed,
                  extraOpen: value,
                  extraClosed,
                });
              }}
              disabled={!closed}
            />
          </td>
          <td className='time-separator-cell'>
            <div className='cell-bg-shadow'></div>
            <span>a</span>
          </td>
          <td
            className={`time-input-cell last ${
              active ? ' isActive' : 'isInactive'
            }`}
          >
            <div className='cell-bg-shadow'></div>{' '}
            <div className='input-wrp'>
              <Select
                className='time-select'
                placeholder='Hora de cierre'
                options={extraCloseHours}
                type='outline'
                value={extraClosed}
                onChange={value => {
                  setExtraClosed(value);
                  handleSchedule({
                    active,
                    open,
                    closed,
                    extraOpen,
                    extraClosed: value,
                  });
                }}
                disabled={!extraOpen}
              />
              <button
                className='link-button'
                onClick={() => setShowExtraHours(false)}
                onMouseEnter={() => setHoverSelected(true)}
                onMouseLeave={() => setHoverSelected(false)}
              >
                <CloseIcon className='close-icon' />
                <span className='close-icon-text'>Eliminar</span>
              </button>
            </div>
          </td>
          <td className='spacer-cell omit-border'></td>
        </tr>
      )}
    </>
  );
};

StoreScheduleElement.propTypes = {
  day: PropTypes.string.isRequired,
  openHour: PropTypes.string,
  closeHour: PropTypes.string,
  extraOpenHour: PropTypes.string,
  extraCloseHour: PropTypes.string,
  onChanged: PropTypes.func,
  isActive: PropTypes.bool,
  isTouched: PropTypes.bool,
};

const isValidSchedule = schedule => {
  const elements = Object.keys(schedule).map(day => schedule[day]);
  return (
    elements.length > 0 &&
    elements.every(element =>
      element.reduce((prev, curr) => {
        return prev && curr.open !== '' && curr.closed !== '';
      }, true)
    )
  );
};

const removeInactiveDays = schedule => {
  const clonedSchedule = JSON.parse(JSON.stringify(schedule));
  const inactiveDays = Object.keys(clonedSchedule)
    .map(day =>
      clonedSchedule[day][0].active === false &&
      (clonedSchedule[day][0].open === '' ||
        clonedSchedule[day][0].closed === '')
        ? day
        : ''
    )
    .filter(e => e !== '');

  inactiveDays.forEach(day => {
    delete clonedSchedule[day];
  });

  return clonedSchedule;
};

const StoreSchedule = ({ schedule, onChange, isNew = false }) => {
  const [scheduleState, setScheduleState] = useState(schedule ?? {});
  useEffect(() => {
    if (onChange) {
      const cleanSchedule = removeInactiveDays(scheduleState);
      onChange(cleanSchedule, isValidSchedule(cleanSchedule));
    }
  }, [scheduleState]);

  const onChangeScheduleElement = (schedule, day) => {
    if (schedule[day][0]?.isTouched) {
      const newSchedule = { ...scheduleState, ...schedule };
      setScheduleState(newSchedule);
      return;
    }

    let fmtSchedule = {};
    Object.keys(daysOfTheWeek).map(keyDay => {
      if (schedule[day][0].isCompleted) {
        let arraySchedule = JSON.parse(JSON.stringify(schedule[day]));
        arraySchedule[0]['isTouched'] = true;
        fmtSchedule[keyDay.toLowerCase()] = arraySchedule;
      } else {
        fmtSchedule[keyDay.toLowerCase()] = schedule[day];
      }
    });

    setScheduleState(fmtSchedule);
  };

  const standarizeHourOption = hour => {
    if (hour === '' || hour === undefined) return '';
    const arrayColons = hour.split(':');
    return [arrayColons[0], arrayColons[1]].join(':');
  };

  const dataMap = useMemo(() => {
    const keysMap = Object.keys(daysOfTheWeek);
    const mapSchedule = keysMap.map(day => {
      const daySchedule = scheduleState[day.toLowerCase()];

      let active = false;
      const openHour =
        daySchedule && daySchedule.length > 0
          ? standarizeHourOption(daySchedule[0].open)
          : '';

      const closeHour =
        daySchedule && daySchedule.length > 0
          ? standarizeHourOption(daySchedule[0].closed)
          : '';

      const extraOpenHour =
        daySchedule && daySchedule.length > 1
          ? standarizeHourOption(daySchedule[1].open)
          : '';

      const extraCloseHour =
        daySchedule && daySchedule.length > 1
          ? standarizeHourOption(daySchedule[1].closed)
          : '';

      if (!daySchedule && isNew) {
        active = true;
      } else {
        active =
          daySchedule &&
          daySchedule.length > 0 &&
          (daySchedule[0].active === 'true' ||
            daySchedule[0].active === true) &&
          daySchedule[0].open < daySchedule[0].closed;
      }

      return {
        day,
        active,
        openHour,
        closeHour,
        extraOpenHour,
        extraCloseHour,
        isTouched: isNew ? daySchedule?.[0]?.isTouched : true,
      };
    });

    return mapSchedule;
  }, [scheduleState]);

  return (
    <>
      <section className='store-create-edit-section'>
        <SectionHeading
          title='Horario de atención'
          subtitle='Selecciona los días en los que la sucursal estará abierta y los horarios de atención'
        />
        <Table
          type='b2b'
          size='small'
          className='open-hours-table k-ds-width-full'
        >
          <tbody>
            <tr className='row-separator-border'>
              <td className='empty-cell' colSpan='5'></td>
              <td className='spacer-cell omit-border' />
            </tr>
            {dataMap.map(
              ({
                day,
                active,
                openHour,
                extraOpenHour,
                closeHour,
                extraCloseHour,
                isTouched,
              }) => {
                return (
                  <StoreScheduleElement
                    key={day}
                    day={day}
                    isActive={active}
                    isTouched={isTouched}
                    openHour={openHour}
                    closeHour={closeHour}
                    extraOpenHour={extraOpenHour}
                    extraCloseHour={extraCloseHour}
                    onChanged={onChangeScheduleElement}
                  />
                );
              }
            )}
          </tbody>
        </Table>
      </section>
    </>
  );
};

StoreSchedule.propTypes = {
  schedule: PropTypes.object,
  onChange: PropTypes.func,
  isNew: PropTypes.bool,
};

export default StoreSchedule;
