import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { ReactComponent as CollapseIcon } from './collapse-icon.svg';
import { ReactComponent as ExpandIcon } from './expand-icon.svg';

const TdStyled = styled.td`
  text-align: center;
`;

const ToggleButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  text-decoration: none;
`;

const TdToggleWithFallback = ({
  devices,
  children,
  isCollapsed,
  onToggleClick,
  dataTitle,
  ...props
}) => {
  const { type } = useDeviceContextViewport();

  if (!devices?.split(' ').includes(type)) {
    return (
      <td {...props} data-title={dataTitle}>
        {children}
      </td>
    );
  }

  return (
    <TdStyled data-title={dataTitle} {...props} style={{ maxWidth: '3rem' }}>
      <ToggleButton onClick={onToggleClick}>
        {isCollapsed ? <ExpandIcon /> : <CollapseIcon />}
      </ToggleButton>
    </TdStyled>
  );
};

TdToggleWithFallback.propTypes = {
  children: PropTypes.node,
  devices: PropTypes.string,
  dataTitle: PropTypes.string,
  isCollapsed: PropTypes.bool,
  onToggleClick: PropTypes.func,
};

export default TdToggleWithFallback;
