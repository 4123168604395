import { Divider } from '../../ds';
import React from 'react';
import { ReactComponent as FacebookLogo } from '../../components/Footer/ic_fb.svg';
import { ReactComponent as InstagramLogo } from '../../components/Footer/ic_ig.svg';
import { ReactComponent as InLogo } from '../../components/Footer/ic_in.svg';
import { ReactComponent as TwitterLogo } from '../../components/Footer/ic_tw.svg';
import { ReactComponent as YoutubeLogo } from '../../components/Footer/ic_yt.svg';
import { ReactComponent as KueskiLogo } from '../../components/Footer/im_logo.svg';
import { ReactComponent as BuroLogo } from '../../components/Footer/im_logo_buro.svg';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import './footer.scss';

const Footer = () => {
  const viewport = useDeviceContextViewport();
  const termsLink = 'https://kueski.com/terminosdeuso';
  const policiesLink = 'https://kueski.com/politicas';
  const buroLink = 'http://www.buro.gob.mx';
  const zendeskLink = 'https://kueski.zendesk.com/hc/es/articles/360025014334';
  return (
    <footer className=''>
      <div className='container'>
        {viewport && viewport.isMobile && !viewport.isTablet ? (
          <>
            <div className='contact k-ds-width-full'>
              <div className='follow-text k-ds-body-02'>Síguenos:</div>
              <div className='kueski-info'>
                <ul className='footer-social'>
                  <li>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://mobile.twitter.com/kueskipay'
                    >
                      <TwitterLogo viewBox='0 0 5 16' />
                    </a>
                  </li>
                  <li>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://www.youtube.com/c/Kueski'
                    >
                      <YoutubeLogo viewBox='0 0 5 16' />
                    </a>
                  </li>
                  <li>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://m.facebook.com/kueskipay'
                    >
                      <FacebookLogo viewBox='0 0 5 16' />
                    </a>
                  </li>
                  <li>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://www.instagram.com/kueskipay/'
                    >
                      <InstagramLogo viewBox='0 0 5 16' />
                    </a>
                  </li>
                  <li>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://www.linkedin.com/company/kueski'
                    >
                      <InLogo viewBox='0 0 5 16' />
                    </a>
                  </li>
                </ul>
                <div className='links-container k-ds-width-full'>
                  <div className='links1'>
                    <ul>
                      <li>
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href='https://kueski.com/unidadespecializada'
                        >
                          {' '}
                          Atención a usuarios
                        </a>
                      </li>
                      <li>
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href='https://kueski.com/contacto'
                        >
                          {' '}
                          Contacto
                        </a>
                      </li>
                      <li>
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href='https://kueski.zendesk.com/hc/es/articles/115003107434-Mi-cuenta-est%C3%A1-en-una-agencia-de-cobranza-C%C3%B3mo-me-puedo-poner-en-contacto-con-ellos-'
                        >
                          Despacho de Cobranza
                        </a>
                      </li>
                      <li>
                        <a
                          className='cat'
                          target='_blank'
                          rel='noreferrer'
                          href='https://kueski.com/cat'
                        >
                          <strong>CAT y Comisiones</strong>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='links2 k-ds-m-left-sp-05'>
                    <ul>
                      <li>
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href='https://kueski.com/'
                        >
                          Kueski Company
                        </a>
                      </li>
                      <li>
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href='https://kueski.com/politicas'
                        >
                          Aviso de privacidad
                        </a>
                      </li>
                      <li>
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href='https://kueski.com/terminosdeuso'
                        >
                          Términos y condiciones
                        </a>
                      </li>
                      <li>
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href='https://kueski.com/articulos#articulo87d'
                        >
                          Disposiciones legales
                        </a>
                      </li>
                      <li>
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href='https://kueski.com/careers'
                        >
                          Trabaja con nosotros
                        </a>
                      </li>
                      <li>
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href='https://kueski.com/press'
                        >
                          Prensa
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='kueski-logo-container'>
                  <KueskiLogo className='kueski-logo' />
                  <div>
                    Av. Américas 1297, Piso 03-15,&nbsp; Col. Circunvalación
                    Américas, Guadalajara, Jalisco, México. C.P.&nbsp; 44630.
                    Kueski 2023 ® Todos los Derechos Resevados
                    <br />
                    <br />
                  </div>
                </div>
              </div>
              <div className='disclaimer'>
                <div>
                  <p>
                    Sujeto a la previa aprobación. El tiempo de respuesta aplica
                    una vez que hayas terminado de enviar completa tu solicitud.
                    Nuestra capacidad de respuesta está sujeta a la previa
                    aprobación del financiamiento en horarios de lunes a viernes
                    de 8:00 a.m. a 8:00 p.m. No aplica en fines de semana o días
                    festivos. De conformidad con lo dispuesto por el Artículo
                    87-J de la Ley General de Organizaciones y Actividades
                    Auxiliares del Crédito, ‘KUESKI’, Sociedad Anónima Promotora
                    de Inversión de Capital Variable, Sociedad Financiera de
                    Objeto Múltiple, Entidad No Regulada, no requiere de
                    autorización de la Secretaria de Hacienda y Crédito Público
                    para su constitución ni para la celebración del presente
                    contrato y está sujeta a la supervisión o vigilancia de la
                    Comisión Nacional Bancaria y de Valores, únicamente en
                    materia de prevención y detección de Operaciones con
                    Recursos de Procedencia Ilícita, Terrorismo y Terrorismo
                    Internacional, de conformidad a lo establecido en el
                    artículo 56 de la Ley antes citada. Kueski es una marca
                    registrada en México. El uso de este sitio web implica la
                    aceptación de los términos y condiciones, así como del aviso
                    de privacidad de ‘KUESKI’, Sociedad Anónima Promotora de
                    Inversión de Capital Variable, Sociedad Financiera de Objeto
                    Múltiple, Entidad No Regulada.
                  </p>
                  <div className='buro'>
                    <BuroLogo className='footer--buro k-ds-m-right-sp-03' />
                    <p>
                      El Buró de Entidades Financieras contiene información de
                      KUESKI SAPI DE CV SOFOM ENR sobre nuestro desempeño frente
                      a los usuarios, por la prestación de productos y
                      servicios. Te invitamos a consultarlo también en la página
                      http://www.buro.gob.mx o a través de
                      https://kueski.zendesk.com/hc/es/articles/360025014334
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='contact'>
              <div className='kueski-info'>
                <KueskiLogo className='kueski-logo' />
                <div>
                  Av. Américas 1297, Piso 03-15,&nbsp; Col. Circunvalación
                  Américas, Guadalajara, Jalisco, México. C.P.&nbsp; 44630
                  <br />
                  <br />
                </div>
                <div>
                  Kueski 2023 ® Todos los Derechos Resevados
                  <br />
                  <br />
                </div>
                <ul className='footer-social'>
                  <li>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://www.linkedin.com/company/kueski'
                    >
                      <InLogo />
                    </a>
                  </li>
                  <li>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://m.facebook.com/kueskipay'
                    >
                      <FacebookLogo />
                    </a>
                  </li>
                  <li>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://mobile.twitter.com/kueskipay'
                    >
                      <TwitterLogo />
                    </a>
                  </li>
                  <li>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://www.instagram.com/kueskipay/'
                    >
                      <InstagramLogo />
                    </a>
                  </li>
                  <li>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://www.youtube.com/c/Kueski'
                    >
                      <YoutubeLogo />
                    </a>
                  </li>
                </ul>
              </div>
              <div className='links1'>
                <h3>Kueski</h3>
                <ul>
                  <li>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://kueski.com/'
                    >
                      Kueski Company
                    </a>
                  </li>
                  <li>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://kueski.com/politicas'
                    >
                      Aviso de privacidad
                    </a>
                  </li>
                  <li>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://kueski.com/terminosdeuso'
                    >
                      Términos y condiciones
                    </a>
                  </li>
                  <li>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://kueski.com/articulos#articulo87d'
                    >
                      Disposiciones legales
                    </a>
                  </li>
                  <li>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://kueski.com/careers'
                    >
                      Trabaja con nosotros
                    </a>
                  </li>
                  <li>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://kueski.com/press'
                    >
                      Prensa
                    </a>
                  </li>
                  <li>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://kueskipay.com/blog/'
                    >
                      Blog
                    </a>
                  </li>
                </ul>
              </div>
              <div className='links2'>
                <h3>Ayuda</h3>
                <ul>
                  <li>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://kueskipay.com/preguntas-frecuentes'
                    >
                      Preguntas frecuentes
                    </a>
                  </li>
                  <li>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://kueski.com/unidadespecializada'
                    >
                      {' '}
                      Atención a usuarios
                    </a>
                  </li>
                  <li>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://kueski.zendesk.com/hc/es/articles/115003107434-Mi-cuenta-est%C3%A1-en-una-agencia-de-cobranza-C%C3%B3mo-me-puedo-poner-en-contacto-con-ellos-'
                    >
                      Despacho de Cobranza
                    </a>
                  </li>
                </ul>
                <a
                  className='cat'
                  target='_blank'
                  rel='noreferrer'
                  href='https://kueski.com/cat'
                >
                  <h2>CAT y Comisiones</h2>
                </a>
              </div>
            </div>
            <Divider />
            <div className='disclaimer'>
              <div>
                <p>
                  Sujeto a la previa aprobación. El tiempo de respuesta aplica
                  una vez que hayas terminado de enviar completa tu solicitud.
                  Nuestra capacidad de respuesta está sujeta a la previa
                  aprobación del financiamiento en horarios de Lunes a Viernes
                  de 8:00 am a 8:00 pm. No aplica en fines de semana o días
                  festivos.
                </p>
                <p>
                  De conformidad con lo dispuesto por el Artículo 87-J de la Ley
                  General de Organizaciones y Actividades Auxiliares del
                  Crédito, ‘KUESKI’, Sociedad Anónima Promotora de Inversión de
                  Capital Variable, Sociedad Financiera de Objeto Múltiple,
                  Entidad No Regulada, no requiere de autorización de la
                  Secretaria de Hacienda y Crédito Público para su constitución
                  ni para la celebración del presente contrato, y está sujeta a
                  la supervisión o vigilancia de la Comisión Nacional Bancaria y
                  de Valores, únicamente en materia de prevención y detección de
                  Operaciones con Recursos de Procedencia Ilícita, Terrorismo y
                  Terrorismo Internacional, de conformidad a lo establecido en
                  el artículo 56 de la Ley antes citada.
                </p>
                <p>
                  Kueski es una marca registrada en México.El uso de este sitio
                  web implica la aceptación de los{' '}
                  <a target='_blank' rel='noreferrer' href={termsLink}>
                    términos y condiciones
                  </a>
                  , así como del{' '}
                  <a target='_blank' rel='noreferrer' href={policiesLink}>
                    aviso de privacidad
                  </a>{' '}
                  de ‘KUESKI’, Sociedad Anónima Promotora de Inversión de
                  Capital Variable, Sociedad Financiera de Objeto Múltiple,
                  Entidad No Regulada.
                </p>
                <div className='buro'>
                  <BuroLogo className='footer--buro' />
                  <div>
                    El Buró de Entidades Financieras contiene información de
                    KUESKI SAPI DE CV SOFOM ENR sobre nuestro desempeño frente a
                    los Usuarios, por la prestación de productos y servicios. Te
                    invitamos a consultarlo también en la página{' '}
                    <a target='_blank' rel='noreferrer' href={buroLink}>
                      http://www.buro.gob.mx
                    </a>{' '}
                    ó a través de{' '}
                    <a target='_blank' rel='noreferrer' href={zendeskLink}>
                      https://kueski.zendesk.com/hc/es/articles/360025014334
                    </a>
                    .
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </footer>
  );
};

export default Footer;
