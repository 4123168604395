import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Space } from '../../../ds';
import KDS from '../../../ds/lib/k-ds-foundation';

const StyledSelectLineDiv = styled.div`
  position: relative;
  ${KDS.setColorTokenWithFallback(
  'border: 1px solid ',
  'interactive-alternate'
)}
  border-radius: 8px;
  padding-right: 5px;

  select {
    border-width: 0px;
    box-sizing: border-box;
    padding: 14px;
    background-color: transparent;

    ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}

    cursor: pointer;

    &.placeholder {
      ${KDS.setColorTokenWithFallback('color: ', 'text-secondary')}
    }

    &:focus {
      outline-width: 0;
    }

    .show {
      transform: rotateX(180deg);
    }

    &.disabled {
      cursor: default;

      ${KDS.setColorTokenWithFallback(
  'border: 1px solid ',
  'background-alternate'
)}
      ${KDS.setColorTokenWithFallback('color: ', 'text-placeholder')}

      svg path {
        ${KDS.setColorTokenWithFallback('fill: ', 'background-alternate')}
      }
    }
  }
`;

const SelectLine = ({
  className,
  name,
  form,
  label,
  placeholder,
  options,
  disabled,
  errorMessage,
  optional,
  helper,
  value,
  ...moreProps
}) => {
  const hasError = Boolean(errorMessage);
  const { required } = moreProps;

  const props = { ...moreProps, hasError };

  const selectOptions = options || [];
  const optionsData = selectOptions.map(option => {
    const optionLabel = option?.text ?? option?.label;
    let id = optionLabel.toLowerCase().replace(/ /g, '_');
    id = `${name || ''}_${id}`;
    return {
      id,
      text: optionLabel,
      value: option.value,
    };
  });

  return (
    <Space size='small' direction='vertical' className={className} {...props}>
      <Space
        size='small'
        justify='space-between'
        className='k-ds-width-full'
        direction='horizontal'
      >
        <label
          className='k-ds-heading-02 k-ds-text-color-primary'
          htmlFor={name}
        >
          {label}
          {required ? (
            <span className='k-ds-text-color-error'>{' *'}</span>
          ) : null}
        </label>
        <span className='k-ds-label-01 k-ds-text-color-interactive-secondary'>
          {optional}
        </span>
      </Space>
      <StyledSelectLineDiv hasError={hasError}>
        <select
          className='k-ds-body-02'
          defaultValue={value}
          disabled={disabled}
        >
          <option
            key={placeholder}
            id={placeholder}
            name={name}
            disabled
            value=''
            form={form}
          >
            {placeholder}
          </option>
          {selectOptions.length > 0
            ? optionsData.map(option => (
              <option
                key={option.id}
                id={option.id}
                name={name}
                value={option.value}
                form={form}
              >
                {option?.label ?? option?.text}
              </option>
            ))
            : null}
        </select>
        {helper || hasError ? (
          <span className='helper-error k-ds-caption-01'>
            {errorMessage || helper}
          </span>
        ) : null}
      </StyledSelectLineDiv>
    </Space>
  );
};

SelectLine.propTypes = {
  className: PropTypes.string,
  children: PropTypes.array,
  type: PropTypes.string,
  name: PropTypes.string,
  form: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.string,
  errorMessage: PropTypes.string,
  optional: PropTypes.string,
  helper: PropTypes.string,
};

export { SelectLine };
