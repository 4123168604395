import PropTypes from 'prop-types';
import React from 'react';
import TableCustom from './../Table/TableCustom';
import DownloadReport from './button/DownloadReport';
import {
  StyledReportTable,
  StyledHead,
  StyledPeriod,
  StyledDownloadPeriodContent,
} from './styled';

const CustomReportTable = ({
  table,
  reportName = '',
  period = null,
  options = {},
  onDownloadReport = () => null,
}) => {
  return (
    <StyledReportTable>
      <StyledHead>
        <StyledPeriod>{period}</StyledPeriod>
        <StyledDownloadPeriodContent>
          <DownloadReport
            reportName={reportName}
            onDownloadReport={onDownloadReport}
          />
        </StyledDownloadPeriodContent>
      </StyledHead>
      <TableCustom table={table} {...options} />
    </StyledReportTable>
  );
};

CustomReportTable.propTypes = {
  onDownloadReport: PropTypes.func,
  table: PropTypes.any.isRequired,
  period: PropTypes.node.isRequired,
  reportName: PropTypes.string,
  sticky: PropTypes.string,
  options: PropTypes.any,
};

export default CustomReportTable;
