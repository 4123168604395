import styled, { css } from 'styled-components';
import React from 'react';
import { Space } from '../space/index';
import KDS from '../../lib/k-ds-foundation';
import { ReactComponent as LeftArrow } from './icon-left-arrow.svg';
import { ReactComponent as RightArrow } from './icon-right-arrow.svg';

const ButtonPage = styled.button`
  border: 0;
  width: 2.125rem;
  height: 2.125rem;

  ${KDS.setColorWithFallback('background: ', 'transparent')}

  ${KDS.setTypographyWithFallback({
    'font-family': 'font-secondary',
    'font-weight': 'font-medium',
    'font-size': 'text-sm',
    'letter-spacing': 'tracking-wide',
    'line-height': 'snug',
  })}
  ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}

  ${(props) => props.showCursor && css`
    cursor: pointer;

    &:hover {
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary-hover')}
      text-decoration: none;
    }
  `}

  ${(props) => props.isActive && css`
    ${KDS.setSpacingWithFallback('border-radius: ', 'spacing-01')};
    ${KDS.setColorTokenWithFallback('background: ', 'interactive-primary-light-bg')}
    ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary')}
  `}

  ${(props) => props.isHidden && css`
    display: none;
  `}
`;

const ButtonArrow = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;

  background: transparent;
  cursor: pointer;

  ${KDS.setTypographyWithFallback({
    'font-family': 'font-secondary',
    'font-weight': 'font-medium',
    'font-size': 'text-sm',
    'letter-spacing': 'tracking-wide',
    'line-height': 'snug',
  })}

  &:hover {
    ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary-hover')}
    svg path {
      ${KDS.setColorTokenWithFallback('fill: ', 'interactive-primary-hover')}
    }
  }

  > :first-child {
    margin-right: 26px;
  }

  ${(props) => props.isInactive && css`
    ${KDS.setColorTokenWithFallback('color: ', 'interactive-alternate')}

    svg path {
      ${KDS.setColorTokenWithFallback('fill: ', 'interactive-alternate')}
    }
  `}
`;

const PaginationB2B = (props) => {
  // Require maxiVisibleNumbers to be 5 or greater (due to fixed first, last and '...' elements)
  const {
    totalPages,
    totalElements,
    maxVisibleNumbers,
    currentPage,
    changePage,
    elementsPerPage,
    className,
  } = props;

  if (totalPages === 1) return (<> </>);

  let elements = Array.from({ length: totalPages }, (_, i) => i + 1);
  const middleElement = Math.floor(maxVisibleNumbers / 2);
  const nLeftElements = maxVisibleNumbers % 2 ? middleElement - 2 : middleElement - 3;
  const nRightElements = middleElement - 2;

  function prevPage() {
    if (currentPage > 1) changePage(currentPage - 1);
  }

  function nextPage() {
    if (currentPage < totalPages) changePage(currentPage + 1);
  }

  elements = elements.map((element) => (
    {
      key: `page-${element}`,
      visible: true,
      page: element,
    }
  ));

  const moreThanVisible = totalPages > maxVisibleNumbers;

  elements.splice(totalPages - 1, 0, { page: '...', visible: moreThanVisible, key: 'left...' });
  elements.splice(1, 0, { page: '...', visible: moreThanVisible, key: 'right...' });

  if (moreThanVisible) {
    if (currentPage <= middleElement) {
      elements[1].visible = false;
      let i = maxVisibleNumbers - 1;
      for (;i < elements.length - 2; i += 1) {
        elements[i].visible = false;
      }
    } else if ((totalPages - currentPage) < middleElement) {
      elements[elements.length - 2].visible = false;
      let i = 2;
      for (;i < totalPages - (maxVisibleNumbers - 3); i += 1) {
        elements[i].visible = false;
      }
    } else {
      let i = 2;
      for (;i < currentPage - nLeftElements; i += 1) {
        elements[i].visible = false;
      }
      i = currentPage + 1 + nRightElements;
      for (;i < elements.length - 2; i += 1) {
        elements[i].visible = false;
      }
    }
  }

  const currentPageFirst = (currentPage - 1) * elementsPerPage + 1;
  let currentPageLast = currentPageFirst + elementsPerPage - 1;
  if (currentPageLast > totalElements) currentPageLast = totalElements;

  const leftArrowInactive = currentPage === 1;
  const rightArrowInactive = currentPage === totalPages;

  return (
    <Space direction="horizontal" justify="space-between" align="center" className={className}>
      <div>
        <span className="k-ds-subheading-01 k-ds-text-color-primary">
          {`${currentPageFirst}-${currentPageLast} de ${totalElements} resultados`}
        </span>
      </div>
      <Space direction="horizontal" align="center">
        <ButtonArrow type="button" onClick={() => prevPage()} isInactive={leftArrowInactive} aria-hidden="true">
          <LeftArrow />
          <span> Anterior </span>
        </ButtonArrow>

        {elements.map((element) => {
          const { key, page, visible } = element;
          if (page === '...') {
            return (
              <ButtonPage key={key} isHidden={!visible}>
                {page}
              </ButtonPage>
            );
          }

          const isActive = page === currentPage;

          return (
            <ButtonPage
              key={key}
              onClick={() => changePage(page)}
              showCursor
              isActive={isActive}
              isHidden={!visible}
              aria-hidden="true"
            >
              {page}
            </ButtonPage>
          );
        })}
        <ButtonArrow type="button" onClick={() => nextPage()} isInactive={rightArrowInactive} aria-hidden="true">
          <span> Siguiente </span>
          <RightArrow />
        </ButtonArrow>
      </Space>
    </Space>
  );
};

export {
  PaginationB2B,
};
