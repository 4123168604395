import React from 'react';
import { Card } from '../../ds';
import PropTypes from 'prop-types';

const TransactionSummary = ({items_count, totalFormatted}) => {
  return <Card className='payment-generated-summary'>
    <div className='k-ds-heading-02 k-ds-m-bottom-sp-04'>
      Resumen de la transacción
    </div>
    <div className='summary-row'>
      <div className='k-ds-subheading-01'>Nº de productos:</div>
      <div className='k-ds-heading-01'>
        {items_count}
      </div>
    </div>
    <div className='summary-row'>
      <div className='k-ds-heading-01'>Monto total:</div>
      <div className='k-ds-heading-01'>{`$${totalFormatted} m.n.`}</div>
    </div>
  </Card>
}

TransactionSummary.propTypes = {
  items_count: PropTypes.number,
  totalFormatted: PropTypes.string
};

export default TransactionSummary;
