/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDebounce } from 'use-debounce';
import { Space } from './../../../../ds';
import Checkbox from './../../../Checkbox/Checkbox';
import { SearchBar } from './../../SearchBar';
import NoResults from './NoResults';
import { ReactComponent as IconArrowDown } from './icon-arrow-down.svg';
import { StyledSelectOutline, StyledButton, HiddenDivInput } from './styled';

// Gneral Focus Hook
const UseFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};

const CustomGroup = ({
  className,
  onChange,
  onBlur,
  disabled,
  type = '',
  groupOptions = [],
  defaultValue = {},
  value = {
    label: '',
    value: '',
  },
  ...props
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [ignoreBlur, setIgnoreBlur] = useState(false);
  const [inputHiddenRef, setInputHiddenFocus] = UseFocus();
  const [selected, setSelected] = useState(false);
  const [searchText, setText] = useState('');
  const [debouncedText] = useDebounce(searchText, 500);
  const [notFound, setNotFound] = useState(false);
  const totalElements = useRef(0);
  const [selectedItem, setSelectedItem] = useState({
    label: '',
    value: '',
  });

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, []);

  const onSelectClick = () => {
    if (disabled) return;

    setIsActive(!isActive);
    setInputHiddenFocus();
  };

  const onOptionClick = element => {
    if (onChange) {
      onChange(element);
    }

    setSelectedItem(element);
    setSelected(true);
    setIsActive(false);
    setIgnoreBlur(false);
  };

  const handleBlur = () => {
    if (ignoreBlur) return;
  };

  const handleOnSeach = value => {
    setText(value);
  };

  useEffect(() => {
    if (!isActive && !isFirstRender) {
      if (onBlur) onBlur();
    }
  }, [isActive]);

  const showClass = isActive && !disabled ? 'show' : '';

  useEffect(() => {
    const group = groupOptions
      .sort((itemOne, itemTwo) => {
        return itemOne.label.localeCompare(itemTwo.label);
      })
      .reduce((acc, item) => {
        const groupName = item.label[0];

        if (!acc[groupName]) {
          acc[groupName] = {
            label: groupName,
            options: [item],
          };
        } else {
          acc[groupName].options.push(item);
        }

        return acc;
      }, {});

    if (value) {
      setSelectedItem(value);
    }

    setOptions(Object.values(group));
  }, []);

  useEffect(() => {
    totalElements.current = 0;
    const filter = options.reduce((accumulator, currentValue) => {
      const { label, options } = currentValue;
      const filterItems = options.filter(item =>
        item.label.toLowerCase().includes(debouncedText.toLowerCase())
      );
      totalElements.current = totalElements.current + options.length;

      if (filterItems.length > 0) {
        return [...accumulator, { label, options: filterItems }];
      }

      return accumulator;
    }, []);

    setNotFound(filter.length === 0);
    setFilteredOptions(filter);
  }, [debouncedText, options]);

  useEffect(() => {
    setSelectedItem(value);
  }, [value]);

  return (
    <Space size='x-large' direction='vertical' className={className} {...props}>
      <StyledSelectOutline
        className='k-ds-width-full k-ds-body-01'
        disabled={disabled}
        showPlaceholder={!selectedItem.value}
        active={isActive}
      >
        <HiddenDivInput ref={inputHiddenRef} type='radio' onBlur={handleBlur} />
        <Space
          size='medium'
          justify='space-between'
          className={`k-ds-width-full selector ${selected ? 'selected' : ''}`}
          onClick={onSelectClick}
          direction='horizontal'
          align='center'
          aria-hidden
        >
          <div className='control'>
            <div className='control-text'>
              <span className='k-ds-heading-02'>{`${type}:`}</span>
              <span className='k-ds-body-02 k-ds-m-left-sp-02'>
                {selectedItem.label}
              </span>
            </div>
          </div>
          <IconArrowDown className={`arrow-icon ${showClass}`} />
        </Space>
        <div className={`options-group ${showClass}`}>
          {totalElements.current >= 12 ? (
            <div className='k-ds-m-bottom-sp-06'>
              <SearchBar
                placeholder='Buscar marca'
                setSearch={handleOnSeach}
                search={searchText}
                minSearch={3}
                hideButton
                alternative
              />
            </div>
          ) : null}
          <div className='group-option'>
            {filteredOptions.map(items => (
              <div key={items.label}>
                <div className='k-ds-heading-02'>{items.label}</div>
                <div className='list-option'>
                  {items.options.map((element, index) => (
                    <div
                      className={`option ${element.value === selectedItem.value ? 'selected' : ''}`}
                      key={`${element.label}-${element.value}`}
                      onMouseDown={() => setIgnoreBlur(true)}
                      onClick={() => onOptionClick(element)}
                    >
                      <div className='k-ds-body-01 text'>{element.label}</div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            {notFound ? (
              <NoResults onCleanFilter={() => handleOnSeach('')} />
            ) : null}
          </div>
        </div>
      </StyledSelectOutline>
    </Space>
  );
};

CustomGroup.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  groupOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.any,
        })
      ),
    })
  ),
  value: PropTypes.any,
  defaultValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
  }),
};

export default CustomGroup;
