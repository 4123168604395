import React  from 'react';
import { Button } from '../../ds';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import './button-actions.scss';

const ButtonActionsOverlay = styled.div`
    position: fixed;
    bottom: 0;
    background-color: white;
    height: 7.25rem;
    width: 100%;
    left: 15rem;
    // drop upper shadow
    box-shadow: 0 -5px 6px 0 rgba(0, 0, 0, 0.1);
    `;

const ButtonActionsWrapper = styled.div`
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    height: 114px;
    width: 100%;
    bottom: 0;
    
    button {
        height: 50px;
    }
`;

const ButtonActionsContainer = styled.div`
    min-width: 80rem;
    position: relative;
`;

const ButtonActions = ({ continueAction, disableContinueAction, showContinueAction = true, cancelAction, ...moreProps }) => {
  return <ButtonActionsWrapper className='button-actions'>
    <ButtonActionsOverlay className='button-actions-overlay'/>
    <ButtonActionsContainer {...moreProps}>
      {showContinueAction && (
        <Button
          type='primary'
          size='small'
          disabled={disableContinueAction}
          onClick={() => continueAction && continueAction()}>
          Continuar
        </Button>
        )}
      <Button
        type='simple'
        size='small'
        onClick={() => cancelAction && cancelAction()}
      >
        Cancelar
      </Button>
    </ButtonActionsContainer>
  </ButtonActionsWrapper>
};

ButtonActions.propTypes = {
  continueAction: PropTypes.func,
  cancelAction: PropTypes.func,
  paymentMode: PropTypes.string,
  disableContinueAction: PropTypes.bool,
  showContinueAction: PropTypes.bool,
};

export default ButtonActions;
