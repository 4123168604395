import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '../../ds';
import BackPageButton from '../BackPageButton';

function GetBackSection(props) {
  if (!props.render) {
    return null;
  }

  return (
    <>
      <BackPageButton
        redirectUri={props.redirectUri}
        callback={props.callback}
      />
      <Divider margin='layout-03' />
    </>
  );
}

GetBackSection.propTypes = {
  render: PropTypes.bool.isRequired,
  redirectUri: PropTypes.string,
  callback: PropTypes.func,
};

export default GetBackSection;
