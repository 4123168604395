import {
  Avatar,
  Button,
  Pagination,
  PaginationMobile,
  Space,
  Tag,
} from '../../ds';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Auth from '../../Utils/Auth/auth';
import { canEmployeeEdit } from '../../Utils/roles';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { useLoaderContext } from '../../contexts/Loader/LoaderContext';
import {
  getMerchantRoles,
  getStoreById,
  getStoreUsers,
} from '../../services/Application';
import GetBackSection from '../GetBackSection';
import Header from '../Header';
import { ResponsiveTable } from '../ResponsiveTable/ResponsiveTable';
import { ReactComponent as AddIcon } from './add-line.svg';
import './storeUsers.scss';
import AnalyticsEvent from '../../services/analytics/AnalyticsEvent';

const pageSize = 20;

function StoreUsers() {
  const { storeId } = useParams();
  const [currentPage, setCurrentPage] = React.useState(1);
  const viewport = useDeviceContextViewport();
  const orderDetailsDirection = viewport.isMobile ? 'vertical' : 'horizontal';
  const orderDetailsAlign = viewport.isMobile ? 'stretch' : 'start';

  const userService = useStoreUsers(storeId, currentPage);

  const [storeNameState, setStoreNameState] = useState('');

  const getStore = () => {
    getStoreById(storeId)
      .then(response => {
        const { store } = response.data;
        setStoreNameState(store?.name);
      })
      .catch(err => {
        AnalyticsEvent().errorTrack({
          errorType: 'store-users-get-store-error',
          errorDescription: err,
        });
        console.log(err);
      });
  };

  useEffect(() => {
    getStore();
  }, []);

  return (
    <div className='k-ds-width-full store-detail-users k-ds-text-color-primary k-ds-m-top-sp-06'>
      <GetBackSection render />
      <Space
        direction={orderDetailsDirection}
        size='large'
        justify='space-around'
        align={orderDetailsAlign}
        className='k-ds-width-full'
      >
        <Header title={'Usuarios asignados en la sucursal'} />
        <Space className='add-user-button-container k-ds-width-full'>
          <AddUserButton redirectUri='/user/new' />
        </Space>
      </Space>
      <TableData
        users={userService.users}
        pagination={userService.pagination}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        storeName={storeNameState}
      />
    </div>
  );
}

function useStoreUsers(storeId, page) {
  const [storeService, setStoreService] = React.useState({
    pagination: {
      total_items: 0,
      total_pages: 0,
      current_page: 0,
    },
    users: [],
  });

  const { setIsLoaderOpen } = useLoaderContext();
  const fetchStoreUsers = () => {
    setIsLoaderOpen(true);
    getStoreUsers({ storeId, page, pageSize, showUsersStore: false })
      .then(response => {
        const { pagination, users } = response.data;
        setStoreService({
          pagination: pagination,
          users: users,
        });
      })
      .catch(err => {
        AnalyticsEvent().errorTrack({
          errorType: 'store-users-get-store-users-error',
          errorDescription: err,
        });
        console.log(err);
      })
      .finally(() => setIsLoaderOpen(false));
  };

  React.useEffect(() => {
    fetchStoreUsers();
  }, [page]);
  return storeService;
}

function AddUserButton(props) {
  const router = useHistory();

  return (
    <div>
      <Button
        type='primary'
        size='small'
        horizontalPadding='2rem'
        verticalPadding='1rem'
        onClick={() => router.push(props.redirectUri)}
      >
        <AddIcon className='k-ds-m-rigth-sp-03' />
        Agregar usuario
      </Button>
    </div>
  );
}

AddUserButton.propTypes = {
  redirectUri: PropTypes.string.isRequired,
};

function TableData(props) {
  if (props.users?.length === 0) {
    return null;
  }

  const viewport = useDeviceContextViewport();
  const PaginationComponent = viewport.isMobile ? PaginationMobile : Pagination;

  return (
    <>
      {props.storeName && (
        <Space
          direction='horizontal'
          className='k-ds-width-full k-ds-m-bottom-sp-04'
        >
          <p>
            <span className='k-ds-subheading-01'>Sucursal:</span>
            <span className='k-ds-heading-02 k-ds-m-left-sp-02'>
              {props.storeName}
            </span>
          </p>
        </Space>
      )}
      <ResponsiveTable
        type='b2b'
        className='k-ds-width-full k-ds-m-bottom-lyt-04'
      >
        <TableDataItems users={props.users} />
      </ResponsiveTable>
      <PaginationComponent
        totalPages={props.pagination.total_pages}
        totalElements={props.pagination.total_items}
        maxVisibleNumbers={10}
        currentPage={props.pagination.actual_page}
        elementsPerPage={pageSize}
        changePage={page => props?.setCurrentPage(page)}
      />
    </>
  );
}

TableData.propTypes = {
  users: PropTypes.arrayOf(Object).isRequired,
  pagination: PropTypes.object,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  storeName: PropTypes.string,
};

function getUserInitials(firstName, lastName) {
  return firstName.length === 0 || lastName === 0
    ? '??'
    : firstName[0] + lastName[0];
}

function setSpaceSuffix(...values) {
  return values?.length > 0 ? values.join(' ') : '';
}

function TableDataItems(props) {
  const router = useHistory();
  const { merchant, employee } = Auth.userData();
  if (!merchant) {
    return '';
  }
  const merchantId = merchant.merchant_id;
  const [loggedEmployee, setLoggedEmployee] = React.useState({
    employee_id: '',
    employee_code: '',
  });
  const employeeRole = employee?.role?.name;

  const fetchEmployeeData = () => {
    setLoggedEmployee({
      employee_code: employee.employee_code,
      employee_id: employee.employee_id.toString(), // id is stored as number within local storage
    });
  };
  const [merchantRoles, setMerchantRoles] = React.useState([]);

  useEffect(() => {
    fetchEmployeeData();
  }, []);

  useEffect(() => {
    getMerchantRoles(merchantId)
      .then(response => {
        const { data } = response;
        setMerchantRoles(data?.roles);
      })
      .catch(err => {
        AnalyticsEvent().errorTrack({
          errorType: 'store-users-get-merchant-roles-error',
          errorDescription: err,
        });
        console.log(err);
      });
  }, []);

  const tableHeaders = {
    name: 'Nombre',
    user: 'Usuario',
    role: 'Rol',
    salesman_code: 'Código de vendedor',
    status: 'Estatus',
    edit: 'Editar',
  };

  return (
    <>
      <thead>
        <tr>
          <th>{tableHeaders.name}</th>
          <th>{tableHeaders.user}</th>
          <th>{tableHeaders.role}</th>
          <th>{tableHeaders.salesman_code}</th>
          <th>{tableHeaders.status}</th>
          <th>{tableHeaders.edit}</th>
        </tr>
      </thead>
      <tbody>
        {props.users.map(user => {
          let userRole = user?.role?.role_name;
          let canEdit = canEmployeeEdit(merchantRoles, employeeRole, userRole);
          return (
            <tr key={user.merchant_user_id} className='row-hover'>
              <td data-title={tableHeaders.name}>
                <div className='table-user-name'>
                  <Avatar
                    size='medium'
                    type='initials'
                    initials={getUserInitials(user.first_name, user.last_name)}
                    className='avatar-item'
                  />
                  <div>
                    {`${setSpaceSuffix(
                      user.first_name,
                      user.last_name,
                      user.maiden_name
                    )}`}
                  </div>
                </div>
              </td>
              <td data-title={tableHeaders.user}>{user.email}</td>
              <td data-title={tableHeaders.role}>{user.role.description}</td>
              <td data-title={tableHeaders.salesman_code}>{user.user_code}</td>
              <td data-title={tableHeaders.status}>
                <StatusTag status={user?.status} />
              </td>
              <td data-title={tableHeaders.edit}>
                {canEdit && (
                  <Button
                    type='link'
                    size='small'
                    verticalPadding='0'
                    horizontalPadding='0'
                    onClick={() =>
                      router.push(`/users/${user.merchant_user_id}/edit`)
                    }
                    disabled={
                      loggedEmployee.employee_id === user.merchant_user_id &&
                      employeeRole !== 'general_admin'
                    }
                  >
                    Editar
                  </Button>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </>
  );
}

TableDataItems.propTypes = {
  users: PropTypes.arrayOf(Object).isRequired,
};

function StatusTag(props) {
  if (props.status === 'active') {
    return <Tag color='cyan'>ACTIVO</Tag>;
  }
  return (
    <Tag color={props.status === 'deleted' ? 'grey' : 'red'}>
      {props.status === 'deleted' ? 'ELIMINADO' : 'INACTIVO'}
    </Tag>
  );
}

StatusTag.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StoreUsers;
