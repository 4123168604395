import React from 'react';
import './SectionHeading.scss';
import PropTypes from 'prop-types';

function SectionHeading(props) {
  return (
    <>
      {props.title && <h2 className='section-heading-title'>{props.title}</h2>}
      {props.subtitle && (
        <h3 className='section-heading-subtitle'>{props.subtitle}</h3>
      )}
    </>
  );
}

SectionHeading.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default SectionHeading;
