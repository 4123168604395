import styled from 'styled-components';
import { ButtonPrimary } from './ButtonPrimary';
import KDS from '../../lib/k-ds-foundation';

const ButtonPrimaryAlternate = styled(ButtonPrimary)`
  &:enabled {
    &:active{
      box-shadow: none;
    }
    &:focus {
      ${KDS.setColorTokenWithFallback('background: ', 'interactive-primary-hover')}
      border: 0;
    }
  }

  &:disabled {
    ${KDS.setColorTokenWithFallback('background: ', 'background-alternate')}
    ${KDS.setColorTokenWithFallback('color: ', 'interactive-alternate')}
  }
`;

export {
  ButtonPrimaryAlternate,
};
