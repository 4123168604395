import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styles from './Tooltip.module.scss';
import { ReactComponent as InterrogativeIcon } from './res/interrogativeIcon.svg';

const Tooltip = ({ text, message }) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={styles.container}
    >
      <div className={styles.text}>{text}</div>
      <InterrogativeIcon
        className={isTooltipVisible ? styles.hoverIcon : null}
      />
      {isTooltipVisible && (
        <>
          <div className={styles.message}>{message}</div>
          <svg
            className={styles.beakDown}
            width='24'
            height='18'
            viewBox='0 0 24 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M0.265775 2.48633C-0.489369 1.3813 0.45456 1.0162e-06 1.96485 9.45081e-07L22.0352 0C23.5454 -7.11172e-08 24.4894 1.38129 23.7342 2.48633L13.6991 17.1712C12.9439 18.2763 11.0561 18.2763 10.3009 17.1712L0.265775 2.48633Z'
              fill='#F5F6FB'
            />
          </svg>
        </>
      )}
    </div>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default Tooltip;
