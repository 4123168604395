import { ErrorMessage } from '@hookform/error-message';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Controller,
  FormProvider,
  useFormContext,
  useForm as useReactHookForm,
} from 'react-hook-form';
import { SelectSearchable } from '../../ds/components/select/SelectSearchable';
import styles from './Form.module.scss';

export const useForm = ({ mode = 'onTouched', ...params }) => {
  return useReactHookForm({
    mode,
    ...params,
  });
};

export const RadioButton = ({ name, value, label }) => {
  const { control } = useFormContext();

  return (
    <div className={styles.field}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <label>
            <input
              type='radio'
              value={value}
              checked={field.value === value}
              onChange={e => field.onChange(e.target.value)}
            />
            {label}
          </label>
        )}
      />
    </div>
  );
};

export const FormPassword = ({ name, label }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className={styles.passwordContainer}>
      <FormInput
        type={showPassword ? 'text' : 'password'}
        name={name}
        label={label}
      />
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        onClick={togglePasswordVisibility}
      >
        {showPassword ? (
          <path
            d='M9.99999 2.5C14.4933 2.5 18.2317 5.73333 19.0158 10C18.2325 14.2667 14.4933 17.5 9.99999 17.5C5.50666 17.5 1.76833 14.2667 0.984161 10C1.76749 5.73333 5.50666 2.5 9.99999 2.5ZM9.99999 15.8333C11.6996 15.833 13.3486 15.2557 14.6774 14.196C16.0061 13.1363 16.9357 11.6569 17.3142 10C16.9344 8.34442 16.0041 6.86667 14.6755 5.80835C13.3469 4.75004 11.6986 4.17377 9.99999 4.17377C8.30141 4.17377 6.65307 4.75004 5.32447 5.80835C3.99588 6.86667 3.06563 8.34442 2.68583 10C3.06424 11.6569 3.99389 13.1363 5.32261 14.196C6.65134 15.2557 8.30044 15.833 9.99999 15.8333ZM9.99999 13.75C9.00543 13.75 8.05161 13.3549 7.34834 12.6516C6.64508 11.9484 6.24999 10.9946 6.24999 10C6.24999 9.00544 6.64508 8.05161 7.34834 7.34835C8.05161 6.64509 9.00543 6.25 9.99999 6.25C10.9946 6.25 11.9484 6.64509 12.6516 7.34835C13.3549 8.05161 13.75 9.00544 13.75 10C13.75 10.9946 13.3549 11.9484 12.6516 12.6516C11.9484 13.3549 10.9946 13.75 9.99999 13.75ZM9.99999 12.0833C10.5525 12.0833 11.0824 11.8638 11.4731 11.4731C11.8638 11.0824 12.0833 10.5525 12.0833 10C12.0833 9.44747 11.8638 8.91756 11.4731 8.52686C11.0824 8.13616 10.5525 7.91667 9.99999 7.91667C9.44746 7.91667 8.91756 8.13616 8.52685 8.52686C8.13615 8.91756 7.91666 9.44747 7.91666 10C7.91666 10.5525 8.13615 11.0824 8.52685 11.4731C8.91756 11.8638 9.44746 12.0833 9.99999 12.0833Z'
            fill='#18223D'
          />
        ) : (
          <path
            d='M14.9017 16.0809C13.436 17.0105 11.7356 17.5028 9.99999 17.5001C5.50666 17.5001 1.76833 14.2667 0.984161 10.0001C1.34252 8.05899 2.31885 6.2858 3.76749 4.94506L1.15999 2.34006L2.33916 1.16089L18.8383 17.6609L17.6592 18.8392L14.9008 16.0809H14.9017ZM4.94583 6.12505C3.81333 7.15471 3.02443 8.50737 2.68583 10.0001C2.94607 11.1388 3.46855 12.201 4.21166 13.1022C4.95478 14.0034 5.89802 14.7187 6.96629 15.1911C8.03456 15.6636 9.19837 15.8801 10.3651 15.8235C11.5318 15.7669 12.6691 15.4387 13.6867 14.8651L11.9967 13.1751C11.2772 13.6282 10.4252 13.8235 9.58019 13.7288C8.73522 13.634 7.94753 13.255 7.3463 12.6537C6.74508 12.0525 6.36602 11.2648 6.2713 10.4199C6.17657 9.57489 6.37181 8.72281 6.82499 8.00339L4.94583 6.12505ZM10.7617 11.9401L8.05999 9.23839C7.91172 9.61584 7.87682 10.0284 7.95958 10.4253C8.04235 10.8223 8.23918 11.1865 8.52593 11.4733C8.81268 11.76 9.17688 11.9569 9.57387 12.0396C9.97087 12.1224 10.3834 12.0875 10.7608 11.9392L10.7617 11.9401ZM17.3392 13.8267L16.1467 12.6351C16.7037 11.8411 17.1003 10.946 17.3142 10.0001C17.0877 9.00816 16.6619 8.07268 16.0626 7.25047C15.4634 6.42826 14.7032 5.73649 13.8282 5.21723C12.9533 4.69796 11.9819 4.36203 10.9731 4.22986C9.96431 4.09769 8.93917 4.17204 7.95999 4.44839L6.64499 3.13339C7.68416 2.72506 8.81666 2.50006 9.99999 2.50006C14.4933 2.50006 18.2317 5.73339 19.0158 10.0001C18.7605 11.3881 18.1865 12.698 17.3392 13.8267ZM9.76916 6.25672C10.2996 6.22394 10.8309 6.30428 11.3279 6.4924C11.825 6.68053 12.2763 6.97215 12.6521 7.34793C13.0279 7.72371 13.3195 8.17508 13.5076 8.6721C13.6958 9.16913 13.7761 9.70046 13.7433 10.2309L9.76833 6.25672H9.76916Z'
            fill='#18223D'
          />
        )}
      </svg>
    </div>
  );
};

export const FormInput = ({
  name,
  label,
  type = 'text',
  hint = '',
  isOptional = false,
  ...props
}) => {
  const { register, formState, getFieldState } = useFormContext();

  const { invalid } = getFieldState(name, formState);

  return (
    <div className={styles.field}>
      <div className={styles.fieldHeader}>
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
        {isOptional && <span className={styles.optional}>Opcional</span>}
      </div>
      <input
        {...register(name)}
        {...props}
        type={type}
        id={name}
        className={cn(styles.inputText, {
          [styles.error]: invalid,
        })}
      />
      {!invalid && hint && <span className={styles.hint}>{hint}</span>}
      <ErrorMessage
        errors={formState.errors}
        name={name}
        render={prop => {
          const { message } = prop;
          if (message) {
            return <span className={styles.errorMessage}> {message} </span>;
          }
        }}
      />
    </div>
  );
};

export const FormSelect = ({
  isOptional,
  hint = '',
  isSearchable = false,
  name,
  label,
  options,
  transform = { input: v => v, output: e => e },
}) => {
  const { control, formState, getFieldState } = useFormContext();
  const { invalid } = getFieldState(name, formState);

  const childField = React.Children.only(
    <SelectSearchable
      name={name}
      isSearchable={isSearchable}
      options={options}
    />
  );
  return (
    <div className={styles.field}>
      <div className={styles.fieldHeader}>
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
        {isOptional && <span className={styles.optional}>Opcional</span>}
      </div>
      <Controller
        name={name}
        control={control}
        defaultValue={childField.props.defaultValue ?? null}
        render={({ field }) => {
          return React.cloneElement(childField, {
            placeholder: 'Elige una opción',
            onChange: e => field.onChange(transform.output(e)),
            value: transform.input(field.value),
            hasError: invalid,
            ref: field.ref,
          });
        }}
      />
      {!invalid && hint && <span className={styles.hint}>{hint}</span>}
      <ErrorMessage
        errors={formState.errors}
        name={name}
        render={prop => {
          const { message } = prop;
          if (message) {
            return <span className={styles.errorMessage}> {message} </span>;
          }
        }}
      />
    </div>
  );
};

export const FormPhone = ({ name, label, type = 'text', ...props }) => {
  const { register, formState, getFieldState } = useFormContext();

  const { invalid } = getFieldState(name, formState);

  return (
    <div className={styles.field}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <input
        {...register(name, {
          pattern: {
            value: /^\(\d{2}\) \d{4} \d{4}$/,
            message: 'Invalid phone number format',
          },
        })}
        {...props}
        type={type}
        id={name}
        className={cn(styles.inputText, {
          [styles.error]: invalid,
        })}
      />
      <ErrorMessage
        errors={formState.errors}
        name={name}
        render={({ message }) => {
          if (message) {
            return <span className={styles.errorMessage}> {message} </span>;
          }
        }}
      />
    </div>
  );
};

const Form = ({ onSubmit, methods, children, className = '' }) => {
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className={className}>
        {children}
      </form>
    </FormProvider>
  );
};

export default Form;

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  methods: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isOptional: PropTypes.bool,
  type: PropTypes.string,
  hint: PropTypes.string,
};

RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

FormPhone.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
};

FormPassword.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

FormSelect.propTypes = {
  transform: PropTypes.any,
  isSearchable: PropTypes.bool,
  isOptional: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  hint: PropTypes.string,
};
