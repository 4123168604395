/* eslint-disable react/prop-types */
import styled from 'styled-components';
import React from 'react';
import { ReactComponent as IconSuccess } from './icon-success.svg';
import KDS from '../../../ds/lib/k-ds-foundation';

const StyledMessageError = styled.div`
  display: flex;
  align-items: flex-start;

  border-radius: 16px;
  box-sizing: border-box;
  padding: 16px;
  border: 1px solid #8bc34a;

  ${KDS.setColorWithFallback('background: ', 'white')}

  .icon {
    min-width: 18px;
  }

  .message {
    overflow: hidden;
    ${KDS.setSpacingWithFallback('margin-left: ', 'spacing-04')};
    overflow-wrap: break-word;
  }
`;

const MessageSuccess = ({ message, ...moreProps }) => (
  <StyledMessageError {...moreProps}>
    <IconSuccess className='icon' />
    <div className='message k-ds-label-01'>{message}</div>
  </StyledMessageError>
);

export { MessageSuccess };
