import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, Prompt } from 'react-router-dom';

import { IdleTimeoutManager } from 'idle-timer-manager';
import Auth from '../../../Utils/Auth/auth';
import { useAuth } from '../../../Utils/Auth/use-auth';
import { isFeatureEnabled } from '../../../Utils/Feature/feature_flag';
import { ACCOUNT_MANAGER, SECTION_REPORT_NAME } from '../../../Utils/constants';
import { useGetMerchant } from '../../../Utils/useGetMerchant';
import { FaqHelper } from '../../../components/Faq/FaqModal';
import IdleModal from '../../../components/IdleModal/IdleModal';
import MerchantModal from '../../../components/MerchantSelectModal/MerchantModal';
import Logout from '../../../components/Navbar/LogoutIcon';
import { ReactComponent as KueskiPayIcon } from '../../../components/Navbar/kueski-pay-icon.svg';
import { ReactComponent as UserIcon } from '../../../components/Navbar/user-line.svg';
import CategoriesContextProvider from '../../../contexts/Categories/CategoriesContext';
import { useDeviceContextViewport } from '../../../contexts/Device/DeviceContext';
import { usePermissionContext } from '../../../contexts/Role/PermissionContext';
import {
  ContentArea,
  Divider,
  NavButton,
  NavButtonGroup,
  Shell,
  SideNav,
  Space,
} from '../../../ds';
import ModalPaymentUpdate from '../../routes/ModalPaymentUpdate';
import { merchant_image, paymentCode } from '../../routes/ScopeRoutes';
import { hasChannelAccess, hasPermission } from '../../routes/index';
import { ReactComponent as CloseIcon } from './icon-close.svg';
import { ReactComponent as MenuIcon } from './icon-menu.svg';
import './layout.scss';
import { ReactComponent as MerchantIcon } from './res/merchant-icon.svg';
import AnalyticsEvent from '../../../services/analytics/AnalyticsEvent';

const timeoutToEndSession =
  process.env.REACT_APP_INACTIVITY_SESSION_TIMEOUT || 1800;
const timeOutToShowMessage = 1620; // 1800 - 1620 = 180s = 3m

const Layout = ({ menus, user, children }) => {
  const auth = useAuth();
  const merchantInfo = useGetMerchant();

  const { permission_scopes, merchant, employee } = Auth.userData();

  const { openModalRoot, openPaymentUpgrade } = usePermissionContext();
  const [openIdlemModal, setOpenIdleModal] = React.useState(false);
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const [isTimeOut, setIsTimeOut] = useState(false);

  const [navigationMenu, setNavigationMenu] = useState();
  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  function activeMenu() {
    setIsSideNavOpen(false);
  }

  useEffect(() => {
    setCurrentPath(window.location.pathname);
  }, [window.location.pathname]);

  if (!navigationMenu) {
    const queryNavigationMenu = document.querySelector('.navigation-menu');
    if (queryNavigationMenu) {
      setNavigationMenu(queryNavigationMenu);
    }
  }

  const secondsToEndSession = timeoutToEndSession - timeOutToShowMessage;
  const pathsToShowFaq = ['payments', 'orders'];
  const showFaq = pathsToShowFaq.some(
    path => window?.location?.href?.indexOf(path) > -1
  );

  const showMerchantOption = useMemo(() => {
    return employee?.role?.name === ACCOUNT_MANAGER;
  }, [employee]);

  useEffect(() => {
    const modalManager = new IdleTimeoutManager({
      // eslint-disable-next-line no-undef
      timeout: timeOutToShowMessage,
      onExpired: () => {
        modalManager.clear();
        setOpenIdleModal(true);
      },
    });

    return () => {
      modalManager.clear();
    };
  });

  const mobileSideNavClassName = isSideNavOpen
    ? 'mobile-side-nav'
    : 'hide-component';
  const viewport = useDeviceContextViewport();
  const { isMobile, isTablet, isSmallTablet } = viewport;
  const sideNavWidth = isTablet ? '14.5rem' : '15rem';
  const contentAreaPadding = useMemo(() => {
    if (isSmallTablet) return '1rem 2.5rem 2.5rem 2.5rem';
    if (isMobile) return '1rem 1.5rem 2.5rem';

    return '3rem 3rem 2rem 3rem';
  }, [isMobile, isSmallTablet]);

  const merchantChannels = merchant?.channels || [];
  const isMerchantImageEnabled =
    merchant_image &&
    hasPermission(permission_scopes, merchant_image.module) &&
    isFeatureEnabled(merchant_image.module) &&
    hasChannelAccess(merchant_image.routes[0].channels, merchantChannels);

  const menuFixed = useMemo(() =>
    menus.map(item => {
      if (
        item.text === SECTION_REPORT_NAME &&
        item.links[0].text !== 'Comercio' &&
        showMerchantOption
      ) {
        item.links.unshift({
          path: '/merchant-panel',
          text: 'Comercio',
          showMerchantOption: showMerchantOption,
        });
      }
      return item;
    })
  );

  return (
    <Shell>
      <div className='k-ds-height-full nav-menu-bar'>
        {openPaymentUpgrade &&
        hasChannelAccess(paymentCode.routes[0].channels, merchantChannels) ? (
          <ModalPaymentUpdate />
        ) : null}
        {openModalRoot && <MerchantModal />}
        {isMobile || isTablet ? (
          <Space
            className='mobile-side-nav-controller'
            justify='space-between'
            direction='horizontal'
            align='center'
          >
            <MenuIcon
              className='pointer'
              aria-hidden
              onClick={() => setIsSideNavOpen(true)}
            />
            <KueskiPayIcon />
          </Space>
        ) : null}
        <SideNav
          className={
            isMobile || isTablet
              ? `navigation-menu ${mobileSideNavClassName} menu-sh`
              : 'navigation-menu'
          }
          width={sideNavWidth}
        >
          <Space
            direction='vertical'
            justify='space-between'
            className='k-ds-height-full'
          >
            <div className='flex-centered'>
              {!isMobile && !isTablet ? (
                <div className='k-ds-m-top-sp-08' style={{ width: 192 }}>
                  <KueskiPayIcon />
                  {isMerchantImageEnabled ? (
                    <Space
                      direction='vertical'
                      className='k-ds-m-top-lyt-01'
                      align='center'
                    >
                      <Link
                        to={merchant_image.routes[0].path}
                        className='a k-ds-heading-02'
                        key={merchant_image.routes[0].path}
                      >
                        <NavButton
                          className='nav-button'
                          icon={merchant_image.icon}
                          iconSelected={merchant_image.iconSelected}
                          selected={
                            merchant_image.routes[0].path === currentPath
                          }
                          onClick={() =>
                            activeMenu(merchant_image.routes[0].path)
                          }
                        >
                          <div className='k-ds-heading-02'>
                            {merchantInfo?.name}
                          </div>
                        </NavButton>
                      </Link>
                    </Space>
                  ) : (
                    <div className='k-ds-heading-02 k-ds-m-bottom-sp-01 k-ds-m-top-sp-03'>
                      {merchantInfo?.name}
                    </div>
                  )}
                  <Divider color='inverse-background' thickness='1px' />
                </div>
              ) : (
                <>
                  <CloseIcon
                    className='pointer k-ds-m-top-sp-05 k-ds-m-left-sp-06 align-self-start'
                    aria-hidden
                    onClick={() => setIsSideNavOpen(false)}
                  />
                  <div className='k-ds-m-top-sp-08' style={{ width: 192 }}>
                    {isMerchantImageEnabled ? (
                      <Space
                        direction='vertical'
                        className='k-ds-m-top-lyt-01'
                        align='center'
                      >
                        <Link
                          to={merchant_image.routes[0].path}
                          className='a k-ds-heading-02'
                          key={merchant_image.routes[0].path}
                        >
                          <NavButton
                            className='nav-button'
                            icon={merchant_image.icon}
                            iconSelected={merchant_image.iconSelected}
                            selected={
                              merchant_image.routes[0].path === currentPath
                            }
                            onClick={() =>
                              activeMenu(merchant_image.routes[0].path)
                            }
                          >
                            <div className='k-ds-heading-02'>
                              {merchantInfo?.name}
                            </div>
                          </NavButton>
                        </Link>
                      </Space>
                    ) : (
                      <div className='k-ds-heading-02 k-ds-m-bottom-sp-01 k-ds-m-top-sp-03'>
                        {merchantInfo?.name}
                      </div>
                    )}
                    <Divider color='inverse-background' thickness='1px' />
                  </div>
                </>
              )}
            </div>

            <div className='custom-flex-centered'>
              {menuFixed.map(menu => {
                return (
                  <NavButtonGroup
                    key={menu.text}
                    title={menu.text}
                    className={
                      menu.text && 'k-ds-m-top-lyt-01 k-ds-m-bottom-lyt-01'
                    }
                  >
                    {menu.links.map(link => {
                      if (link.showMerchantOption) {
                        return (
                          <Link
                            key={link.path}
                            to={link.path}
                            className='a k-ds-m-top-sp-04 k-ds-heading-02'
                          >
                            <NavButton
                              className='nav-button'
                              icon={
                                <MerchantIcon className='icon-unselected' />
                              }
                              iconSelected={<MerchantIcon />}
                              selected={link.path == currentPath}
                              onClick={() => {
                                activeMenu(link.path);
                              }}
                            >
                              {link.text}
                            </NavButton>
                          </Link>
                        );
                      }
                      return (
                        <Link
                          to={link.path}
                          className='a k-ds-m-top-sp-04 k-ds-heading-02'
                          key={link.path}
                        >
                          <NavButton
                            className='nav-button'
                            icon={link.icon}
                            iconSelected={link.iconSelected}
                            selected={link.path == currentPath}
                            onClick={() => {
                              activeMenu(link.path);
                            }}
                          >
                            {link.text}
                          </NavButton>
                        </Link>
                      );
                    })}
                  </NavButtonGroup>
                );
              })}
            </div>
            <Space direction='vertical'>
              <Space
                direction='vertical'
                className='k-ds-m-top-lyt-01'
                align='center'
              >
                <Link to={'/me'} className='a k-ds-heading-02' key={'/me'}>
                  <NavButton
                    className='nav-button'
                    icon={<UserIcon />}
                    id='user-profile-link'
                    iconSelected={<UserIcon className='svg-selected' />}
                    selected={'/me' == currentPath}
                    onClick={() => {
                      AnalyticsEvent().profileDetails({ branch: '' });
                      activeMenu('/me');
                    }}
                  >
                    {Object.keys(user)?.length > 0 && user?.employeeName()}
                  </NavButton>
                </Link>
              </Space>
              <Space className='k-ds-m-sp-06' direction='vertical'>
                <Divider color='inverse-background' thickness='1px' />
                <Space
                  className='k-ds-heading-01'
                  direction='horizontal'
                  align='center'
                >
                  <Logout />
                </Space>
              </Space>
            </Space>
          </Space>
        </SideNav>
        <ContentArea id='content-area' padding={contentAreaPadding}>
          <div className='max-width-content-area layout-bottom-padding'>
            <CategoriesContextProvider>{children}</CategoriesContextProvider>
          </div>
        </ContentArea>
        <Prompt
          message={(location, action) => {
            if (action === 'PUSH' && isTimeOut) return auth.logout();
            if (action === 'POP' && isTimeOut) return auth.logout();

            return isTimeOut ? auth.logout() : true;
          }}
        />
        <IdleModal
          isOpen={openIdlemModal}
          header={'¿Sigues aquí?'}
          idleSecTimer={secondsToEndSession} // in seconds
          onLogout={() => auth.logout()}
          setIsOpen={setOpenIdleModal}
          isTimeOut={isTimeOut}
          setIsTimeOut={setIsTimeOut}
        />

        {showFaq && <FaqHelper />}
      </div>
    </Shell>
  );
};

Layout.propTypes = {
  menus: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
};

export default Layout;
