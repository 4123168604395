export const ORIGIN_CHANNEL = [
  { value: 'all', label: 'Todos' },
  { value: 'instore', label: 'En tienda' },
  { value: 'online', label: 'En línea' },
];

export const NATIONALITY = [
  { value: 'MEX', label: 'Mexicano' },
  { value: 'EXT', label: 'Extranjero' },
];
