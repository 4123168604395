import Auth from '../../Utils/Auth/auth';

export const initTutorialStore = ({ modules = [] }) => {
  const tutorial = JSON.parse(localStorage.getItem('tutorial')) || {};

  modules.forEach(module => {
    if (Object.keys(tutorial).length > 0 && tutorial[module]) {
      tutorial[module].visited = false;
      return;
    }

    tutorial[module] = {
      number: 0,
      lastStep: '',
      visited: false,
    };
  });

  localStorage.setItem('tutorial', JSON.stringify(tutorial));
};

export const getTutorial = module => {
  const { tutorial } = Auth.userData();

  return tutorial[module] || {};
};

export const updateTutorial = ({ module = '', content = {} }) => {
  const { tutorial } = Auth.userData();

  if (!tutorial[module]) {
    return;
  }

  tutorial[module] = content;
  localStorage.setItem('tutorial', JSON.stringify(tutorial));
};
