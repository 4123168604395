import styled from 'styled-components';
import KDS from '../../lib/k-ds-foundation';

const ToastBase = styled.div`
    ${KDS.setSpacingWithFallback('padding-top: ', 'spacing-03')};
    ${KDS.setSpacingWithFallback('padding-bottom: ', 'spacing-03')};
    ${KDS.setSpacingWithFallback('margin-bottom: ', 'spacing-03')};
    ${KDS.setColorWithFallback('background: ', 'white')};

    text-align: center;
    border-radius: 16px;
    z-index: 1;
    right: 30px;
    animation: fadein 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;

    .toast-message {
        ${KDS.setSpacingWithFallback('padding-right: ', 'spacing-06')};

        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
    }

    .toast-icon {
        ${KDS.setSpacingWithFallback('padding-right: ', 'spacing-05')};
        ${KDS.setSpacingWithFallback('padding-left: ', 'spacing-06')};
    }

    .toast-close {
        ${KDS.setSpacingWithFallback('padding-right: ', 'spacing-06')};
        ${KDS.setColorWithFallback('background: ', 'transparent')}

        margin: 0px;
        border: none;
        &:hover {
            cursor: pointer;
        }
    }

    @keyframes fadein {
        from {
            right: 0;
            opacity: 0;
        }
        to {
            right: 30px;
            opacity: 1;
        }
    }
`;

export {
  ToastBase,
};
