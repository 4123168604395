import cn from 'classnames';
import 'matchmedia-polyfill';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styles from './ImageCarousel.module.scss';

const CustomPrevArrow = ({ onClick, isDisabled }) => (
  <div
    onClick={onClick}
    className={cn(styles.customArrow, styles.prev, {
      [styles.cursor]: !isDisabled,
    })}
  >
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2051_3374)'>
        <path
          d='M10.828 12.0007L15.778 16.9507L14.364 18.3647L8 12.0007L14.364 5.63672L15.778 7.05072L10.828 12.0007Z'
          fill={isDisabled ? '#B4BACA' : '#09121F'}
        />
      </g>
      <defs>
        <clipPath id='clip0_2051_3374'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </div>
);

const CustomNextArrow = ({ onClick, isDisabled }) => (
  <div
    className={cn(styles.customArrow, styles.next, {
      [styles.cursor]: !isDisabled,
    })}
    onClick={onClick}
  >
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2051_3369)'>
        <path
          d='M13.1722 12.0007L8.22217 7.05072L9.63617 5.63672L16.0002 12.0007L9.63617 18.3647L8.22217 16.9507L13.1722 12.0007Z'
          fill={isDisabled ? '#B4BACA' : '#09121F'}
        />
      </g>
      <defs>
        <clipPath id='clip0_2051_3369'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </div>
);

const ImageCarousel = ({
  items = [],
  autoplay = false,
  dots = false,
  loop = false,
  speed = 3000,
  slidesToShow = 1,
  className = '',
}) => {
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots,
    speed: 500,
    infinite: loop,
    arrows: false,
    slidesToShow,
    className,
    slidesToScroll: 1,
    autoplay,
    autoplaySpeed: speed,
    beforeChange: (_, next) => setCurrentSlide(next),
  };

  const goToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  return (
    <div className={styles.container}>
      <CustomPrevArrow
        onClick={goToPrevSlide}
        isDisabled={currentSlide === 0}
      />
      <Slider ref={sliderRef} {...settings}>
        {items.map(image => image)}
      </Slider>
      <CustomNextArrow
        onClick={goToNextSlide}
        isDisabled={currentSlide === items.length - slidesToShow}
      />
    </div>
  );
};

CustomPrevArrow.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

CustomNextArrow.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

ImageCarousel.propTypes = {
  items: PropTypes.array.isRequired,
  dots: PropTypes.bool,
  loop: PropTypes.bool,
  autoplay: PropTypes.bool,
  speed: PropTypes.number,
  slidesToShow: PropTypes.number,
  className: PropTypes.string,
};

export default ImageCarousel;
