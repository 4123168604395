import React from 'react';
import './RadioButton.scss';
import PropTypes from 'prop-types';

function RadioButton(props) {
  return (
    <div
      className={
        props.className
          ? `main-radio-button-container ${props.className}`
          : 'main-radio-button-container'
      }
    >
      <label className='radio-button-label'>
        <input
          id='radio-button'
          type='radio'
          name={props.name}
          checked={props.checked}
          onChange={props.onChange}
          value={props.value}
        ></input>
        <span
          className={props.checked ? 'checked-radio' : 'unchecked-radio'}
        ></span>
        {props.label}
      </label>
    </div>
  );
}

RadioButton.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
};

export default RadioButton;
