import PropTypes from 'prop-types';
import React from 'react';
import { Space, Tag } from '../../ds';

function StateSection({ title, isActive }) {
  const status = isActive === 'true';
  return (
    <Space className='k-ds-width-full k-ds-m-bottom-sp-06' direction='vertical'>
      <div className='k-ds-heading-04 k-ds-m-bottom-sp-04'>{title}</div>
      <div className='k-ds-heading-02'>
        Estatus:{' '}
        <Tag color={status ? 'cyan' : 'red'}>
          {status ? 'ACTIVA' : 'INACTIVA'}
        </Tag>
      </div>
    </Space>
  );
}

StateSection.propTypes = {
  isActive: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default StateSection;
