import * as yup from 'yup';

const NOT_EMPTY_FIELD = 'No puede quedar vacío';
export const ValidationSchema = yup.object().shape({
  name: yup.string().required(NOT_EMPTY_FIELD),
  lastName: yup.string().required(NOT_EMPTY_FIELD),
  secondLastName: yup.string().required(NOT_EMPTY_FIELD),
  contactEmail: yup.string().required(NOT_EMPTY_FIELD).email('Correo invalido'),
  phoneNumber: yup
    .string()
    .required(NOT_EMPTY_FIELD)
    .matches(/^\d{10}$/, 'El número de teléfono debe tener 10 dígitos'),
  // .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Número de teléfono inválido'),
  password: yup
    .string()
    .required(NOT_EMPTY_FIELD)
    .min(8, '8 caracteres como mínimo')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]*$/,
      'El formato no es válido'
    ),
  confirmPassword: yup
    .string()
    .required(NOT_EMPTY_FIELD)
    .oneOf([yup.ref('password'), null], 'Las contraseñas tienen que coincidir'),
});
