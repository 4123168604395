import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './HomeCard.module.scss';
import AnalyticsEvent from '../../services/analytics/AnalyticsEvent';

const HomeCard = ({
  children,
  text,
  path = '',
  isLink = false,
  isQuickAccess = false,
  iconClassName = '',
}) => {
  const history = useHistory();

  const handleRedirect = () => {
    if (isQuickAccess) {
      AnalyticsEvent().quickAccessClicked();
    }

    if (!isLink) return;

    history.push(path);
  };

  return (
    <div
      style={{ cursor: 'pointer' }}
      onClick={handleRedirect}
      className={styles.link}
    >
      <div className={styles.container}>
        <div className={`${styles.iconStyle} ${iconClassName}`}>{children}</div>
        <div className={styles.text}>{text}</div>
      </div>
    </div>
  );
};

HomeCard.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  isLink: PropTypes.bool.isRequired,
  path: PropTypes.string,
  isQuickAccess: PropTypes.bool,
  iconClassName: PropTypes.string,
};

export default HomeCard;
