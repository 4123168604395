import PropTypes from 'prop-types';
import React from 'react';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { Space } from '../../ds';
import BadgeStatus from '../BadgeStatus/BadgeStatus';
import ImportUserForm from '../ImportUserForm';
import { ReactComponent as DownChevronIcon } from './chevron-down.svg';
import { ReactComponent as UpChevronIcon } from './chevron-up.svg';
import { ReactComponent as DeleteIcon } from './delete-icon.svg';
import './importUsersFile.scss';

function UserToUpload(props) {
  const {
    isDuplicated,
    userData,
    onDelete,
    onUpdate,
    isExpanded,
    onClick,
    stores,
  } = props;
  const viewport = useDeviceContextViewport();
  const isMobileOnly = viewport.width <= 585 || viewport.isMobileOnly;

  function ControlBlock() {
    return (
      <Space
        direction='horizontal'
        className='actions-container'
        justify='space-between'
      >
        <BadgeStatus
          isInfoCompleted={userData.is_valid}
          isInfoDuplicated={isDuplicated}
        />
        <Space>
          <Space justify='center' className='delete-container'>
            <DeleteIcon onClick={handleOnDelete} />
          </Space>
          <Space
            justify='center'
            className='chevron-container'
            onClick={handleOnClick}
          >
            {isExpanded ? <UpChevronIcon /> : <DownChevronIcon />}
          </Space>
        </Space>
      </Space>
    );
  }

  return (
    userData && (
      <Space className='new-user-row k-ds-width-full'>
        <Space direction='vertical' className='k-ds-width-full'>
          <Space
            className='user-data k-ds-m-bottom-sp-04'
            justify='space-between'
            direction={isMobileOnly ? 'vertical' : 'horizontal'}
            onClick={handleOnClick}
          >
            {isMobileOnly && <ControlBlock />}
            <Space direction='vertical' className='k-ds-width-full'>
              <div
                className={`${
                  isExpanded
                    ? 'k-ds-heading-05 expanded-name'
                    : 'k-ds-heading-04'
                } k-ds-m-bottom-sp-04`}
              >
                {userData.first_name +
                  ' ' +
                  userData.last_name +
                  ' ' +
                  userData.maiden_name}{' '}
              </div>
              {!isExpanded && (
                <Space
                  direction={viewport.isMobile ? 'vertical' : 'horizontal'}
                  className='role-store-container'
                >
                  <div className='k-ds-body-02 k-ds-m-right-sp-09 role'>
                    Rol:{' '}
                    <span className='k-ds-body-01 font-weight-bold'>
                      {userData.role_name || 'N/A'}
                    </span>{' '}
                  </div>
                  <div
                    className={
                      viewport.isMobile
                        ? 'k-ds-body-02 stores-label'
                        : 'k-ds-body-02'
                    }
                  >
                    Sucursal Asignada:{' '}
                    <span className='k-ds-body-01 font-weight-bold'>
                      {userData.store_name || 'N/A'}
                    </span>{' '}
                  </div>
                </Space>
              )}
            </Space>
            {!isMobileOnly && <ControlBlock />}
          </Space>
          {isExpanded && (
            <ImportUserForm
              userData={userData}
              onUpdate={updateUser}
              stores={stores}
            />
          )}
        </Space>
      </Space>
    )
  );

  function handleOnDelete() {
    onDelete(userData);
  }

  function updateUser(updatedUser) {
    onUpdate(updatedUser);
  }

  function handleOnClick() {
    onClick();
  }
}

UserToUpload.propTypes = {
  isDuplicated: PropTypes.bool,
  userData: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  stores: PropTypes.array,
};

export default UserToUpload;
