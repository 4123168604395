import React, { useState, useEffect, useContext } from 'react';
import { getCategories } from '../../services/Application';
import PropTypes from 'prop-types';

const CategoriesDefault = {
  areCategoriesLoaded: false,
  categories: [],
  getAndSaveCategories: () => {},
};
const CategoriesContext = React.createContext(CategoriesDefault);

const useCategoriesContext = () => {
  const context = useContext(CategoriesContext);
  return context;
};

const CategoriesContextProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [areCategoriesLoaded, setAreCategoriesLoaded] = useState(false);

  const getAndSaveCategories = () => {
    getCategories()
      .then(response => {
        setCategories(response.data);
        setAreCategoriesLoaded(true);
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAndSaveCategories();
  }, []);
  return (
    <CategoriesContext.Provider
      value={{ areCategoriesLoaded, categories, getAndSaveCategories }}
    >
      {children}
    </CategoriesContext.Provider>
  );
};

const getCategoryDescription = (categories, categoryCode) => {
  const category = categories.find(category => category.code === categoryCode);
  if (category) {
    return category.description;
  }
  return categoryCode;
};

CategoriesContextProvider.propTypes = {
  children: PropTypes.any,
};

export default CategoriesContextProvider;
export { useCategoriesContext, getCategoryDescription };
