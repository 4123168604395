import { Button, Modal, Space } from '../../ds';
import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ErrorIcon } from './errorIcon.svg';
import './StoreCreateEditComponent.scss';

const StoreCreateError = ({ isOpen = false, header, content, buttonText, close }) => {
  return <Modal
    className='store-create-error'
    theme='dark'
    cardType='secondary'
    onClose={() => close()}
    onOverlayClick={() => close()}
    isOpen={isOpen}
    showClose
  >
    <div className='k-ds-width-full text-center'>
      <ErrorIcon />
      <div className='k-ds-heading-05 k-ds-m-top-sp-05'>{ header  }</div>
      <div className='k-ds-m-top-sp-06 k-ds-body-02'>{ content }</div>
      <Space
        justify='center'
        direction='horizontal'
        className='k-ds-m-top-sp-07 k-ds-m-bottom-sp-05'
      >
        <Button onClick={() => close()}>{ buttonText }</Button>
      </Space>
    </div>
  </Modal>
}

StoreCreateError.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  header: PropTypes.string,
  content: PropTypes.any,
  buttonText: PropTypes.string,
  close: PropTypes.func.isRequired,
};

export default StoreCreateError;
