import styled from 'styled-components';
import React from 'react';
import { Button } from '../button/index';
import KDS from '../../lib/k-ds-foundation';

/* eslint-disable */
const StyledTextCopy = styled.div`
  display: inline-block;

  border-radius: 8px;
  box-sizing: border-box;
  padding: 6px 16px;

  ${KDS.setColorWithFallback('background: ', 'white')};

  .text-to-copy {
    /* k-ds-caption-01 */
    ${KDS.setTypographyWithFallback({
      'font-family': 'font-secondary',
      'font-weight': 'font-medium',
      'font-size': 'text-xs',
      'letter-spacing': 'tracking-wide',
      'line-height': 'relaxed',
    })}

    ${KDS.setColorWithFallback('color: ', 'deep-purple-500')};
  }

  .button-text {
    ${KDS.setPrimitiveWithFallback('margin-left: ', 'spacing', 'spacing-06')};

    ${KDS.setFontSizeWithFallback('text-xs')};
  }
`;
/* eslint-enable */

async function copyTextToClipboard(textToCopy) {
  const el = document.createElement('textarea');
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  el.value = textToCopy;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

const TextCopyB2B = ({
  textToCopy,
  buttonText,
  maxChars,
  ...moreProps
}) => {
  const displayButtonText = buttonText || 'Copiar';
  let shownTextToCopy = textToCopy;
  if (maxChars) {
    shownTextToCopy = `${shownTextToCopy.slice(0, maxChars)}...`;
  }

  return (
    <StyledTextCopy {...moreProps}>
      <span className="text-to-copy">{shownTextToCopy}</span>
      <Button
        type="primary-alternate"
        className="button-text"
        size="small"
        verticalPadding="4px"
        horizontalPadding="12px"
        onClick={() => copyTextToClipboard(textToCopy)}
      >
        {displayButtonText}
      </Button>
    </StyledTextCopy>
  );
};

export {
  TextCopyB2B,
};
