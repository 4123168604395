import { useEffect } from 'react';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import MerchantPaymentsService from '../../../services/MerchantPaymentsService';
import { useLoaderContext } from '../../../contexts/Loader/LoaderContext';
import { useGetMerchants } from './../../../Utils/useGetMerchants';
import { ACCOUNT_MANAGER } from './../../../Utils/constants';
import Auth from './../../../Utils/Auth/auth';
import { useStore } from '../../../services/store';
import { useGetMerchant } from './../../../Utils/useGetMerchant';

export const useQueryTransactionReport = () => {
  const { setIsLoaderOpen } = useLoaderContext();
  const { employee } = Auth.userData();
  const merchants = useGetMerchants();
  const merchantSelected = useGetMerchant();
  const allowMerchantId =
    merchants.length > 0 || employee.role.name === ACCOUNT_MANAGER;

  const {
    merchantTransactionsReport: {
      getPersistedData,
      transactionsReport,
      updateFilter,
      onInitMerchant,
      initFilter,
    },
  } = useStore();

  useEffect(() => {
    async function init() {
      const persistDtata = await getPersistedData();

      if (!persistDtata || persistDtata.isFirstLoad) {
        onInitMerchant({
          merchantId: allowMerchantId ? merchantSelected.merchant_id : '',
        });
      }
    }

    init();
  }, []);

  const fetchTransactions = async ({ searchTerm, merchantId, ...rest }) => {
    setIsLoaderOpen(true);

    let parameters = allowMerchantId ? { ...rest, merchantId } : rest;

    if (searchTerm) {
      parameters = { searchTerm: searchTerm, merchantId: rest.merchantId };
    }

    const respose =
      await MerchantPaymentsService().getPaymentsMovementsAggregate(parameters);

    setIsLoaderOpen(false);
    return respose;
  };

  const queryResult = useQuery({
    queryKey: ['transactionReport', transactionsReport],
    queryFn: async () => fetchTransactions(transactionsReport),
    placeholderData: keepPreviousData,
  });

  const onInitFilters = () => {
    initFilter({
      merchantId: allowMerchantId ? merchantSelected.merchant_id : '',
    });
  };

  const onUpdateFilters = newFilters => {
    updateFilter({
      ...newFilters,
      page: 1,
      pageSize: 15,
    });
  };

  const onChangePageSize = pageSize => {
    updateFilter({ pageSize: Number(pageSize) });
  };

  const onChangePage = page => {
    updateFilter({ page: Number(page) });
  };

  return {
    filters: transactionsReport,
    updateFilters: onUpdateFilters,
    initFilters: onInitFilters,
    changePage: onChangePage,
    changePageSize: onChangePageSize,
    queryResult: queryResult,
  };
};
