import PropTypes from 'prop-types';
import React from 'react';
import { forwardRef } from 'react';
import './Checkbox.scss';

const Checkbox = forwardRef(function CheckboxCustom(
  { name, defaultChecked, onChange, filled, ...rest },
  ref
) {
  return (
    <label>
      <input
        ref={ref}
        className='checkbox-input'
        type='checkbox'
        name={name}
        defaultChecked={defaultChecked}
        onChange={onChange}
        {...rest}
      ></input>
      <span
        className={`${filled ? 'filled' : ''} ${defaultChecked ? 'checked-checkbox' : 'unchecked-checkbox'}`}
      ></span>
    </label>
  );
});

Checkbox.propTypes = {
  name: PropTypes.string,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
  filled: PropTypes.bool,
};

export default Checkbox;
