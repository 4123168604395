import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import styles from './CountDown.module.scss';

const CountdownTimer = ({
  time,
  messageCounter,
  messageBtnReset,
  onBtnReset,
}) => {
  const [seconds, setSeconds] = useState(time % 60);
  const [minutes, setMinutes] = useState(Math.floor(time / 60));
  const [isRunning, setIsRunning] = useState(true);

  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  useEffect(() => {
    setSeconds(time % 60);
    setMinutes(Math.floor(time / 60));
    setIsRunning(true);
  }, [time]);

  useEffect(() => {
    if (isRunning && (seconds > 0 || minutes > 0)) {
      const intervalId = setInterval(() => {
        if (seconds > 0) {
          setSeconds(prevSeconds => prevSeconds - 1);
        } else if (minutes > 0) {
          setMinutes(prevMinutes => prevMinutes - 1);
          setSeconds(59);
        } else {
          setIsRunning(false);
        }
      }, 1000);

      return () => clearInterval(intervalId);
    } else if (isRunning) {
      setIsRunning(false);
    }
  }, [seconds, minutes, isRunning]);

  function handleReset() {
    if (onBtnReset) onBtnReset();
  }

  return (
    <div>
      {isRunning && (
        <span className={styles.messageCounter}>
          {messageCounter} {formattedMinutes}:{formattedSeconds}
        </span>
      )}
      {!isRunning && (
        <button className={styles.btnLink} onClick={handleReset}>
          {messageBtnReset}
        </button>
      )}
    </div>
  );
};

CountdownTimer.propTypes = {
  time: PropTypes.number,
  messageCounter: PropTypes.string.isRequired,
  messageBtnReset: PropTypes.string,
  onBtnReset: PropTypes.func,
};

const CountDownt = memo(CountdownTimer);

export default CountDownt;
