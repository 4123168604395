import PropTypes from 'prop-types';
import React from 'react';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { Button, Modal, Space } from '../../ds';
import { Select } from '../DS/Select';
import { StoreTag } from '../ImportUserForm/UserCrudForm';
import './addStoresModal.scss';
import { ReactComponent as CloseIcon } from './close-icon.svg';

function AddStoresModal(props) {
  const { isModalopen, close, stores, storesToAdd, onRemoveTag, onAdd } = props;
  const viewport = useDeviceContextViewport();
  const isMobileOnly = viewport.isMobile && !viewport.isSmallTablet;

  return isModalopen ? (
    <Modal
      className='add-stores-modal'
      theme='dark'
      cardType='secondary'
      onClose={handleClose}
      onOverlayClick={handleClose}
      {...{ isOpen: isModalopen }}
    >
      <div className='k-ds-width-full'>
        <Space
          direction='horizontal'
          className='k-ds-m-bottom-sp-05 close-container'
        >
          <div className='k-ds-width-full k-ds-m-left-sp-04 k-ds-m-top-sp-04 k-ds-heading-05'>
            Agregar sucursal
          </div>
          <Button
            className='close-button'
            type='link'
            size='small'
            onClick={handleClose}
          >
            <CloseIcon />
          </Button>
        </Space>
        <Space className='store-container k-ds-m-top-sp-08 k-ds-m-bottom-sp-06 k-ds-width-full'>
          <Select
            placeholder='Sucursales'
            label='Selecciona o escribe la sucursal'
            className='k-ds-width-full'
            onChange={addStore}
            id='store-to-add'
            name='store-to-add'
            options={stores}
            type='text'
          />
        </Space>
        <Space className='k-ds-m-bottom-sp-09 tags-container-modal'>
          {storesToAdd.map((storeId, index) => {
            return (
              index != 0 && (
                <StoreTag
                  storeName={getStoreName(storeId)}
                  key={index}
                  onClick={() => handleRemove(storeId)}
                />
              )
            );
          })}
        </Space>
        <Space
          className='k-ds-width-full'
          direction={isMobileOnly ? 'vertical' : 'horizontal'}
        >
          <Button
            type='primary-alternate'
            className='k-ds-m-rigth-sp-02 k-ds-heading-01 k-ds-p-sp-04'
            verticalPadding='1rem'
            size='small'
            onClick={handleClose}
          >
            Aceptar
          </Button>
        </Space>
      </div>
    </Modal>
  ) : null;

  function handleClose() {
    close();
  }

  function addStore(storeId) {
    onAdd(storeId);
  }

  function getStoreName(storeId) {
    return stores.find(store => store.id === storeId)?.text || '';
  }

  function handleRemove(storeId) {
    onRemoveTag(storeId);
  }
}

AddStoresModal.propTypes = {
  isModalopen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  stores: PropTypes.array,
  storesToAdd: PropTypes.array,
  onAdd: PropTypes.func,
  onRemoveTag: PropTypes.func,
};

export default AddStoresModal;
