import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useAuth } from '../../../../Utils/Auth/use-auth';
import Checkbox from '../../../../components/Checkbox';
import CountDown from '../../../../components/CountDown';
import { useLoaderContext } from '../../../../contexts/Loader/LoaderContext';
import { usePermissionContext } from '../../../../contexts/Role/PermissionContext';
import { Button, Space, TextInput } from '../../../../ds';
import BlockedAccountMessage from './BlockedAccountMessage';
import styles from './OTPVerification.module.scss';

const SeeMore = () => {
  const [showContent, setShowContent] = useState(false);

  const toggleContent = () => {
    setShowContent(!showContent);
  };

  return (
    <div className={styles.termsContainer}>
      <div>
        Declaro expresamente a Kueski, S.A.P.I. de C.V., SOFOM E.N.R., que haré
        buen uso de la información y funciones de los comercios.
      </div>
      <div
        className={cn({
          [styles.hideMessage]: !showContent,
          [styles.showMessage]: showContent,
        })}
      >
        <p>
          Declaro que conozco la naturaleza y alcance de la información que
          puede estar comprometida con los comercios afiliados a Kueski que
          administraré.
        </p>
        Por último declaro que estoy consciente y acepto que este documento
        quede bajo custodia de Kueski, S.A.P.I. de C.V., SOFOM E.N.R., para
        efectos de control y cumplimiento.
      </div>
      <button className={styles.btnLink} onClick={toggleContent}>
        {showContent ? 'Ver menos' : 'Ver más'}
      </button>
    </div>
  );
};

const OTPVerification = ({ otp, onCancel, loginData, handleRedirect }) => {
  const { phone_segment = '00', countdown = 0 } = otp;
  const { loadPermissions } = usePermissionContext();

  const { setIsLoaderOpen } = useLoaderContext();
  const auth = useAuth();

  const [otpData, setOtpData] = useState({
    code: '',
    terms: false,
  });

  const [countDownVal, setCountDownVal] = useState(countdown);
  const [error, setError] = useState('');
  const [isBlocked, setIsBlocked] = useState(false);

  const isDisableSave = useMemo(
    () => !(otpData.terms && otpData.code !== ''),
    [otpData]
  );

  const handleChange = event => {
    const isChecked = event.target.checked;
    setOtpData({ ...otpData, terms: isChecked });
  };
  const handleOnChange = event => {
    const code = event.target.value;
    setOtpData({ ...otpData, code });
  };

  const handleVerification = () => {
    const loginWithOtp = { ...loginData, otp: otpData.code };
    setIsLoaderOpen(true);
    auth.login(loginWithOtp).then(response => {
      setIsLoaderOpen(false);
      if (response?.error) {
        if (response?.data.code === 'unauthorized-otp-blocked')
          setIsBlocked(true);
        setError(response.error);
        return;
      }

      loadPermissions();
      handleRedirect(response);
    });
  };

  const handleCancel = () => {
    if (onCancel) onCancel();
  };

  const handleResetOtp = () => {
    setIsLoaderOpen(true);
    auth.login(loginData).then(response => {
      setCountDownVal(response?.error?.countdown ?? 0);
      setIsLoaderOpen(false);
    });

    return true;
  };

  if (isBlocked) {
    return <BlockedAccountMessage onAccept={handleCancel} message={error} />;
  }

  return (
    <div className='greeting'>
      <div className='verification'>
        <div className='k-ds-heading-05'>Verificación de seguridad</div>

        <div className='message-verification'>
          Antes de ingresar al portal de comercios, introduce el NIP que se
          envió por <span className='bold'>Whatsapp</span> a tu número celular{' '}
          <span className='bold'>** **** {phone_segment}</span>
        </div>

        <TextInput
          placeholder='Introduce NIP'
          errorMessage={error}
          onChange={handleOnChange}
        />

        <CountDown
          time={countDownVal}
          messageCounter='Reenviar en'
          messageBtnReset='Reenviar'
          onBtnReset={handleResetOtp}
        />

        <Space direction='horizontal'>
          <Checkbox
            filled
            defaultChecked={otpData.terms}
            onChange={handleChange}
          />
          <SeeMore />
        </Space>

        <div className='k-ds-m-top-sp-07'>
          <Button
            type='primary-alternate'
            className='k-ds-m-rigth-sp-02'
            horizontalPadding='2.5rem'
            verticalPadding='1rem'
            size='small'
            onClick={handleVerification}
            disabled={isDisableSave}
          >
            Ingresar
          </Button>
          <Button type='simple' size='small' onClick={handleCancel}>
            Cancelar
          </Button>
        </div>
      </div>
    </div>
  );
};

OTPVerification.propTypes = {
  otp: PropTypes.object,
  otpData: PropTypes.object,
  setOtpData: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  loginData: PropTypes.object,
  handleRedirect: PropTypes.func,
};

export default OTPVerification;
