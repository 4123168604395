import styled, { css } from 'styled-components';
import KDS from '../../lib/k-ds-foundation';

/* eslint-disable */
const TabItem = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  border: 0px;
  ${KDS.setColorTokenWithFallback('border-bottom: 2px solid ', 'border')}
  ${KDS.setSpacingWithFallback('padding: ','spacing-04', 'spacing-06')};

  ${KDS.setColorWithFallback('background:', 'transparent')}
  cursor: pointer;

  ${KDS.setColorTokenWithFallback('color: ', 'text-secondary')}
  /* k-ds-heading-02 + font-normal */
  ${KDS.setTypographyWithFallback({
    'font-family': 'font-primary',
    'font-weight': 'font-normal',
    'font-size': 'text-base',
    'letter-spacing': 'tracking-wide',
    'line-height': 'normal',
  })}

  &:enabled {
    &:hover {
      ${KDS.setColorTokenWithFallback('border-bottom: 2px solid ', 'interactive-alternate')}
      ${KDS.setColorTokenWithFallback('background: ', 'background-primary')}
    }

    &:active:not(focus) {
      ${(props) => props.hasFocus && css`
        ${KDS.setColorTokenWithFallback('border: 1px solid ', 'inverse-background')}
      `}
      box-sizing: border-box;

      ${KDS.setColorWithFallback('background:', 'transparent')}

      ${KDS.setTypographyWithFallback({
        'font-weight': 'font-bold',
      })}
      ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}
    }

    &:focus {
      ${(props) => props.hasFocus && css`
        ${KDS.setColorTokenWithFallback('border: 1px solid ', 'inverse-background')}
        box-sizing: border-box;

        ${KDS.setColorWithFallback('background:', 'transparent')}
      `}
    }
  }

  &:disabled {
    opacity: 0.5;

    cursor: default;
  }

  ${(props) => props.selected && css`
    ${KDS.setColorTokenWithFallback('border-bottom: 2px solid ', 'interactive-primary')}

    ${KDS.setTypographyWithFallback({
      'font-weight': 'font-bold',
    })}
    ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}
  `}
`;
/* eslint-enabled */

const TabGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  &:after {
    content: "";

    display: flex;
    flex-grow: 1;
    align-self: flex-end;

    min-width: 0px;

    ${KDS.setColorTokenWithFallback('border-bottom: 2px solid ', 'border')}
  }
`;

export {
  TabGroup, TabItem,
};
