import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from '../../ds';
import PaymentAwait from './PaymentAwait';
import styles from './paymentAwait.module.scss';

const ModalRedirect = props => {
  return (
    <Modal
      isOpen
      className={styles['payment-modal-redirect']}
      theme='dark'
      cardType='secondary'
    >
      <div className='k-ds-width-full'>
        <PaymentAwait orderPath={props.path} />
      </div>
    </Modal>
  );
};

ModalRedirect.propTypes = {
  path: PropTypes.string,
};

export default ModalRedirect;
