import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as GlassIcon } from './glass_icon.svg';
import { Button } from './../../../../ds';
import { StyledNoResults } from './styled';

const NoResults = ({ onCleanFilter }) => {
  return (
    <StyledNoResults>
      <GlassIcon className='not-found-icon' />
      <div className='k-ds-heading-03'>Sin resultados</div>
      <div className='k-ds-body-01'>
        Por favor, verifica tu búsqueda o intenta con otra.
      </div>

      <Button type='link' onClick={onCleanFilter} className='button-clear'>
        Limpiar búsqueda
      </Button>
    </StyledNoResults>
  );
};

NoResults.propTypes = {
  onCleanFilter: PropTypes.func,
};

export default NoResults;
