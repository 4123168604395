import {
  flexRender as TableFlexRender,
  getCoreRowModel as TableGetCoreRowModel,
  useReactTable as TableUseReactTable,
  getExpandedRowModel as TableGetExpandedRowModel,
  createColumnHelper as TableCreateColumnHelper,
} from '@tanstack/react-table';

export const flexRender = TableFlexRender;
export const getCoreRowModel = TableGetCoreRowModel;
export const useReactTable = TableUseReactTable;
export const getExpandedRowModel = TableGetExpandedRowModel;
export const createColumnHelper = TableCreateColumnHelper;
