export const getLocalStorage = key => window.localStorage?.getItem(key);

export const useGetMerchant = () => {
  const merchantSelected = getLocalStorage('merchantSelected');
  const merchantDefault = getLocalStorage('merchant');

  if (merchantSelected) {
    return JSON.parse(merchantSelected);
  }

  return JSON.parse(merchantDefault);
};
