import styled, { css } from 'styled-components';
import KDS from '../../lib/k-ds-foundation';

const PRESET_GAP_SIZES = {
  'x-small': 'spacing-02',
  'small': 'spacing-03',
  'medium': 'spacing-05',
  'large': 'spacing-06',
  'x-large': 'spacing-07',
};

const MAP_ALIGN_ITEMS = {
  'start': 'flex-start',
  'end': 'flex-end',
  'center': 'center',
  'baseline': 'baseline',
};

const MAP_JUSTIFY_CONTENT = {
  'start': 'flex-start',
  'end': 'flex-end',
  'center': 'center',
  'space-between': 'space-between',
  'space-around': 'space-around',
};

const StyledSpace = styled.div`
  ${(props) => props.display && css`
    display: ${props.display};
  `}
  ${(props) => props.align && css`
    align-items: ${props.align};
  `}
  ${(props) => props.justify && css`
    justify-content: ${props.justify};
  `}

  ${(props) => props.width && css`
    width: ${props.width};
  `}

  ${(props) => props.withAutoMargin && css`
    &>:not(:first-child) {
      ${(props) => props.horizontalGap && css`
        ${KDS.setSpacingWithFallback('margin-left: ', props.horizontalGap)};
      `}
      ${(props) => props.verticalGap && css`
        ${KDS.setSpacingWithFallback('margin-top: ', props.verticalGap)};
      `}
    }
  `}
  
  

  ${(props) => props.direction === 'vertical' && css`
    flex-direction: column;
  `}
`;

const Space = ({
  className, withAutoMargin = true, children, inline, size, align, justify, ...moreProps
}) => {
  const { direction } = moreProps;
  const defualtSize = 'small';

  const gapSize = PRESET_GAP_SIZES[size] || PRESET_GAP_SIZES[defualtSize];
  const alignItems = MAP_ALIGN_ITEMS[align];
  const justifyContent = MAP_JUSTIFY_CONTENT[justify];

  let display = 'flex';
  if (inline) {
    display = 'inline-flex';
  }

  let props = {
    ...moreProps,
    align: alignItems,
    justify: justifyContent,
    display,
  };

  switch (direction) {
    case 'horizontal':
      props = { ...props, horizontalGap: gapSize };
      break;
    case 'vertical':
    default:
      props = { ...props, verticalGap: gapSize };
      break;
  }
  return (
    <StyledSpace
      withAutoMargin={withAutoMargin}
      className={className}
      {...props}
    >
      {children}
    </StyledSpace>
  );
};

export {
  Space
};

