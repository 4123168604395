/* eslint-disable react/prop-types */
import React from 'react';
import { MessageInformation } from './MessageInformation';
import { MessageWarning } from './MessageWarning';
import { MessageError } from './MessageError';
import { MessageSuccess } from './MessageSuccess';

const MAP_MESSAGE_TYPE = {
  information: MessageInformation,
  warning: MessageWarning,
  error: MessageError,
  success: MessageSuccess,
};

const Message = ({ type, ...moreProps }) => {
  const defaultMessageType = 'information';
  const Component =
    MAP_MESSAGE_TYPE[type] || MAP_MESSAGE_TYPE[defaultMessageType];

  return <Component {...moreProps} />;
};

export { Message };
