import { date, object, string } from 'yup';

export const defaultSchemaHeaders = {
  0: 'Apellido',
  1: 'Segundo Apellido',
  2: 'Correo',
  3: 'Teléfono Celular',
  4: 'CURP',
  5: 'Rol',
  6: 'Sucursal',
};

export const schemaKeys = {
  storeName: 'Nombre de la tienda',
  external_store_id: 'Número de la tienda',
  street: 'Calle',
  ext: 'Número exterior',
  suite: 'Número interior',
  zip: 'Código postal',
  neighborhood: 'Colonia o barrio',
  city: 'Municipio o alcaldía',
  state: 'Estado',
  phone: 'Teléfono de sucursal',
  mall: 'Centro comercial',
  open_mon: 'Apertura lunes',
  open_tue: 'Apertura martes',
  open_wen: 'Apertura miércoles',
  open_thu: 'Apertura jueves',
  open_fri: 'Apertura viernes',
  open_sat: 'Apertura sábado',
  open_sun: 'Apertura domingo',

  close_mon: 'Cierre lunes',
  close_tue: 'Cierre martes',
  close_wen: 'Cierre miércoles',
  close_thu: 'Cierre jueves',
  close_fri: 'Cierre viernes',
  close_sat: 'Cierre sábado',
  close_sun: 'Cierre domingo',

  timezone: 'Zona horaria',
};

export const storeSchema = object({
  'Zona horaria': string().required(),
  [schemaKeys.storeName]: string().required('El nombre es requerido'),
  [schemaKeys.street]: string().required('Campo requerido'),
  [schemaKeys.ext]: string().required(),
  [schemaKeys.suite]: string().required(),
  [schemaKeys.zip]: string()
    .required()
    .matches(/^[0-9]+$/)
    .min(5)
    .max(5),
  [schemaKeys.neighborhood]: string().required(),
  [schemaKeys.city]: string().required(),
  [schemaKeys.state]: string().required(),

  [schemaKeys.open_mon]: date().nullable(),
  [schemaKeys.close_mon]: date().nullable(),

  [schemaKeys.open_tue]: date().nullable(),
  [schemaKeys.close_tue]: date().nullable(),

  [schemaKeys.open_wen]: date().nullable(),
  [schemaKeys.close_wen]: date().nullable(),
  // .min(ref(schemaKeys.open_wen)).nullable(),

  [schemaKeys.open_thu]: date().nullable(),
  [schemaKeys.close_thu]: date().nullable(),

  [schemaKeys.open_fri]: date().nullable(),
  [schemaKeys.close_fri]: date().nullable(),

  [schemaKeys.open_sat]: date().nullable(),
  [schemaKeys.close_sat]: date().nullable(),

  [schemaKeys.open_sun]: date().nullable(),
  [schemaKeys.close_sun]: date().nullable(),
});
