import { Button, Modal, Space } from '../../ds';
import PropTypes from 'prop-types';
import React from 'react';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { ReactComponent as OrderCancelIcon } from './order-cancel-icon.svg';

const CancelPaymentModal = ({
  isOpen,
  header,
  content,
  acceptButtonText,
  rejectButtonText,
  setIsOpenCancel,
  onAccept,
}) => {
  const viewport = useDeviceContextViewport();
  const modalSvgClassName = viewport.isMobile ? 'svg-mobile' : '';
  const modalHeaderTypography = viewport.isMobile
    ? 'k-ds-heading-02'
    : 'k-ds-heading-05';
  const modalBodyTypography = viewport.isMobile
    ? 'text-w-400-s-12'
    : 'text-w-400-s-16';
  const modalButtonSize = viewport.isMobile ? 'small' : 'large';
  const modalButtonClassName = viewport.isMobile
    ? 'button-mobile'
    : 'button-lg';
  return (
    <Modal
      className='modal'
      theme='dark'
      cardType='secondary'
      onClose={() => setIsOpenCancel(false)}
      onOverlayClick={() => setIsOpenCancel(false)}
      {...{ isOpen: !!isOpen }}
    >
      <div className='flex-centered k-ds-width-full'>
        <OrderCancelIcon className={modalSvgClassName} />
        <div className={`${modalHeaderTypography} k-ds-m-top-sp-08`}>
          {header}
        </div>
        <div
          className={`max-width-full text-center ${modalBodyTypography} k-ds-m-top-sp-05`}
        >
          {content}
        </div>
        <Space direction='horizontal' className='k-ds-m-top-sp-07'>
          <Button
            size={modalButtonSize}
            className={modalButtonClassName}
            onClick={() => onAccept()}
          >
            {acceptButtonText}
          </Button>

          <Button
            size={modalButtonSize}
            className={modalButtonClassName}
            type='secondary-alternate'
            onClick={() => setIsOpenCancel(false)}
          >
            {rejectButtonText}
          </Button>
        </Space>
      </div>
    </Modal>
  );
};

CancelPaymentModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpenCancel: PropTypes.func,
  header: PropTypes.string,
  content: PropTypes.string,
  acceptButtonText: PropTypes.string,
  rejectButtonText: PropTypes.string,
  onAccept: PropTypes.func,
};

export default CancelPaymentModal;
