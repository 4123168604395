import PropTypes from 'prop-types';
import React from 'react';
import {
  canBeEmpty,
  phoneInputCleanFormatted,
  validateCurp,
  validateEmailField,
  validatePhoneNumber,
} from '../../Utils/formValidations';
import { Space, TextInput } from '../../ds';
import { validateNameInput } from './MutateUser';

const UserDataSection = props => {
  const handlePhoneBlur = (props, event) => {
    const inputValue = event.target.value;
    const formattedInputValue = phoneInputCleanFormatted(inputValue);
    props.setFormData({
      ...props.formData,
      ...{ cellphone: formattedInputValue },
    });
  };

  return (
    <div>
      <div className='k-ds-heading-04 k-ds-m-top-sp-09 k-ds-m-bottom-sp-07'>
        Datos del usuario
      </div>
      <Space
        direction={props.viewport.isMobile ? 'vertical' : 'horizontal'}
        justifyContent='space-between'
        className='field-container'
      >
        <TextInput
          required
          placeholder='Jose Luis'
          label='Nombre:'
          className='text-field'
          value={props.formData.firstname}
          onChange={e =>
            validateDataOnChange(props, e.target.name, {
              firstname: e.target.value,
            })
          }
          onBlur={e =>
            validateDataOnChange(props, e.target.name, {
              firstname: e.target.value,
            })
          }
          id='first_name'
          name='first_name'
          errorMessage={props.formDataErrors.firstname}
        />
        <TextInput
          placeholder='JLLV770222MMSJNR00'
          label='CURP:'
          className='text-field'
          value={props.formData.curp}
          onChange={e =>
            validateDataOnChange(props, e.target.name, {
              curp: e.target.value,
            })
          }
          onBlur={e =>
            validateDataOnChange(props, e.target.name, {
              curp: e.target.value,
            })
          }
          id='curp'
          name='curp'
          errorMessage={props.formDataErrors.curp}
        />

        <TextInput
          required
          placeholder='Lopéz'
          label='Apellido paterno'
          className='text-field'
          value={props.formData.lastname}
          onBlur={e =>
            validateDataOnChange(props, e.target.name, {
              lastname: e.target.value,
            })
          }
          onChange={e =>
            validateDataOnChange(props, e.target.name, {
              lastname: e.target.value,
            })
          }
          id='last_name'
          name='last_name'
          errorMessage={props.formDataErrors.lastname}
        />
        <TextInput
          required
          placeholder='hola@ejemplo.com'
          label='Correo electrónico'
          value={props.formData.email}
          className='text-field'
          disabled={props.isEdit}
          onBlur={e =>
            validateDataOnChange(props, e.target.name, {
              email: e.target.value,
            })
          }
          onChange={e =>
            validateDataOnChange(props, e.target.name, {
              email: e.target.value,
            })
          }
          id='email'
          name='email'
          errorMessage={props.formDataErrors.email}
        />
      </Space>
      <Space
        direction={props.viewport.isMobile ? 'vertical' : 'horizontal'}
        justifyContent='space-between'
        className='field-container'
      >
        <TextInput
          placeholder='González'
          label='Apellido materno'
          value={props.formData.maidenName}
          className='text-field'
          onBlur={e =>
            validateDataOnChange(props, e.target.name, {
              maidenName: e.target.value,
            })
          }
          onChange={e =>
            validateDataOnChange(props, e.target.name, {
              maidenName: e.target.value,
            })
          }
          id='maiden_name'
          name='maiden_name'
          errorMessage={props.formDataErrors.maidenName}
        />

        <TextInput
          placeholder='551 234 5678'
          label='Teléfono celular:'
          value={props.formData.cellphone}
          className='text-field'
          onBlur={e => handlePhoneBlur(props, e)}
          onChange={e =>
            validateDataOnChange(props, e.target.name, {
              cellphone: e.target.value,
            })
          }
          id='cellphone'
          name='cellphone'
          errorMessage={props.formDataErrors.cellphone}
        />
      </Space>
    </div>
  );
};

const validateDataOnChange = (props, field, val) => {
  props.setFormData({ ...props.formData, ...val });

  switch (field) {
    case 'first_name':
      props.setFormDataErrors({
        ...props.formDataErrors,
        ...{ firstname: validateNameInput(val.firstname) },
      });
      break;
    case 'last_name':
      props.setFormDataErrors({
        ...props.formDataErrors,
        ...{ lastname: validateNameInput(val.lastname) },
      });
      break;
    case 'maiden_name':
      props.setFormDataErrors({
        ...props.formDataErrors,
        ...{
          maidenName: canBeEmpty(
            val.maidenName,
            validateNameInput(val.maidenName)
          ),
        },
      });
      break;
    case 'curp':
      props.setFormDataErrors({
        ...props.formDataErrors,
        ...{
          curp: canBeEmpty(
            val.curp,
            validateCurp(val.curp, 'Este campo tiene un formato inválido')
          ),
        },
      });
      break;
    case 'email':
      props.setFormDataErrors({
        ...props.formDataErrors,
        ...{
          email: validateEmailField(val.email, 'Correo electrónico inválido'),
        },
      });
      break;

    case 'cellphone':
      props.setFormDataErrors({
        ...props.formDataErrors,
        ...{
          cellphone: canBeEmpty(
            val.cellphone,
            validatePhoneNumber(val.cellphone, 'Deben ser 10 dígitos exactos')
          ),
        },
      });
      break;
  }
};

UserDataSection.propTypes = {
  viewport: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  formDataErrors: PropTypes.object.isRequired,
  setFormDataErrors: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
};

const UserSection = UserDataSection;

export default UserSection;
