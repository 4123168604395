import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Button, Space } from '../../ds';
import { ReactComponent as DeleteIcon } from './res/delete-icon.svg';
import { ReactComponent as FileIcon } from './res/file-icon.svg';

const FileUploaded = ({
  isMobileOnly,
  fileName,
  handleClose,
  handleUpload,
  handleDeleteSelection,
}) => {
  const direction = useMemo(() => {
    return isMobileOnly ? 'vertical' : 'horizontal';
  }, [isMobileOnly]);
  return (
    <Space direction='vertical'>
      <div className='k-ds-body-02 k-ds-m-bottom-sp-09'>
        Verifica que sea el archivo correcto
      </div>
      <Space
        className='file-container k-ds-m-bottom-sp-09'
        direction={direction}
      >
        <Space
          className={cn('file-name-container', {
            [' k-ds-m-bottom-sp-06 mobile-container']: isMobileOnly,
          })}
        >
          {<FileIcon className='k-ds-m-right-sp-02' />}
          <span className='truncate'>{fileName}</span>
        </Space>
        <DeleteIcon onClick={handleDeleteSelection} />
      </Space>
      <Space
        className='k-ds-width-full k-ds-m-bottom-sp-04'
        direction='horizontal'
      >
        <Button
          type='primary-alternate'
          size='large'
          className='k-ds-m-rigth-sp-02 create-button'
          onClick={handleUpload}
          // disabled={ !fileInputState.json }
        >
          Subir archivo
        </Button>
        <Button type='simple' size='small' onClick={handleClose}>
          Cancelar
        </Button>
      </Space>
    </Space>
  );
};

FileUploaded.propTypes = {
  isMobileOnly: PropTypes.bool.isRequired,
  fileName: PropTypes.string,
  handleClose: PropTypes.func,
  handleUpload: PropTypes.func,
  handleDeleteSelection: PropTypes.func,
};

export default FileUploaded;
