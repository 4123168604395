import { Space } from '../../ds';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './header.scss';

function Header(props) {
  const { alignChildCenter = false } = props;
  return (
    <Space
      className='k-ds-width-full header orientation'
      align={props.alignHorizontal ? 'center' : ''}
      justify='space-between'
    >
      <Space direction='vertical'>
        {props.title}
        <Subheader text={props.subtitle} />
      </Space>
      <div className={cn({ ['centerChild']: alignChildCenter })}>
        {props.children}
      </div>
    </Space>
  );
}

Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  alignHorizontal: PropTypes.bool,
  alignChildCenter: PropTypes.bool,
  children: PropTypes.element,
};

function Subheader(props) {
  if (!props.text) {
    return null;
  }

  return (
    <div className='k-ds-m-top-sp-05 k-ds-subheading-02 subheader'>
      {props.text}
    </div>
  );
}

Subheader.propTypes = {
  text: PropTypes.string,
};

export default Header;
