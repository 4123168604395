import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../common/assets/close-icon.svg';
import propTypes from 'prop-types';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 25.25rem;
  height: 100vh;
  @media (max-width: 480px) {
    width: 100%;
    height: 100%;
    left: 0;
  }
  -moz-box-shadow:
    0px 20px 25px rgba(0, 0, 0, 0.1),
    0px 10px 10px rgba(0, 0, 0, 0.04);
  -webkit-box-shadow:
    0px 20px 25px rgba(0, 0, 0, 0.1),
    0px 10px 10px rgba(0, 0, 0, 0.04);
  box-shadow:
    0px 20px 25px rgba(0, 0, 0, 0.1),
    0px 10px 10px rgba(0, 0, 0, 0.04);
  background-color: white;
  float: right;
  z-index: 1;
  svg {
    height: 1rem;
    width: 1rem;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: left;
  margin: 1.5rem;
  margin-top: 1rem;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  height: 100%;
`;

const CloseButton = styled.button`
  top: 1rem;
  background-color: white;
  border: none;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
  z-index: 2;
  margin-left: 2.5rem;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
`;

const ToolBar = ({ isOpen, children, onClose, className }) => {
  if (!isOpen) {
    return null;
  }
  return (
    <Overlay className={className}>
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>
      <FilterContainer>
        <div className='k-ds-width-full k-ds-height-full'>{children}</div>
      </FilterContainer>
    </Overlay>
  );
};

ToolBar.propTypes = {
  isOpen: propTypes.bool,
  onClose: propTypes.func,
  children: propTypes.node,
  className: propTypes.string,
};

export default ToolBar;
