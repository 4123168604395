import styled, { css } from 'styled-components';
import React, { useState } from 'react';
import { Space } from '../space/index';
import KDS from '../../lib/k-ds-foundation';
import { ReactComponent as EyeOffLineIcon } from './eye-off-line.svg';
import { ReactComponent as EyeLineIcon } from './eye-line.svg';

const StyledInputOutline = styled.input`
  ${KDS.setColorTokenWithFallback('border: 1px solid ', 'interactive-alternate')}
  box-sizing: border-box;
  padding-top: 14px;
  padding-bottom: 14px;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0px;
  ${KDS.setColorWithFallback('border-color: ', 'grey-300')}

  ${KDS.setColorWithFallback('background: ', 'transparent')}

  ${KDS.setColorTokenWithFallback('color: ', 'interactive-alternate')}

  &:enabled {
    ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}

    &:hover {
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-secondary')}

      ::placeholder {
        ${KDS.setColorTokenWithFallback('color: ', 'interactive-secondary')}
      }
    }

    &:focus-visible {
      outline: 0;
    }

    &:focus {
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary')}
      ${KDS.setColorTokenWithFallback('border-color: ', 'interactive-primary')}
      border-width: 1px;
      outline: 0;
    }

    ::placeholder {
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-secondary')}
    }

    ::-ms-reveal {
      display: none;
    }

    ${(props) => props.hasError && props.isFilled && css`
      ${KDS.setColorTokenWithFallback('border-bottom: 1px solid ', 'error')}
    `}
  }

  &:disabled {
    ${KDS.setColorTokenWithFallback('border-bottom: 1px solid ', 'background-alternate')}

    ::placeholder {
      ${KDS.setColorTokenWithFallback('color: ', 'text-placeholder')}
    }
  }
`;

const InputWrapper = styled.div`
  position: relative;

  .icon {
    position: absolute;
    ${KDS.setSpacingWithFallback('top: ', 'spacing-05')}
    ${KDS.setSpacingWithFallback('right: ', 'spacing-05')}
    ${KDS.setColorWithFallback('background: ', 'white')}

    cursor: pointer;
  }

  .helper-error {
    ${KDS.setColorTokenWithFallback('color: ', 'text-placeholder')}

    ${(props) => props.hasError && css`
      ${KDS.setColorTokenWithFallback('color: ', 'error')}
    `}
  }

  .required {
    position: absolute;
    top: 0;
    right: 0;
  }

  .label {
    position: absolute;
    top: -0.5rem;
    left: 0;
    transition: 0.5s;
    ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}
  }

  .as-placeholder {
    top: 0;
    padding-top: 14px;
    cursor: text;
    ${KDS.setColorTokenWithFallback('color: ', 'text-secondary')}
    ${(props) => props.disabled && css`
      ${KDS.setColorTokenWithFallback('color: ', 'text-placeholder')}
    `}
  }
`;

const InputLine = ({
  className, type, id, inputType, label, placeholder, value, onChange, onFocus, onBlur,
  disabled, errorMessage, helper, ...moreProps
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const hasError = Boolean(errorMessage);
  const isFilled = Boolean(value);
  const { required } = moreProps;

  const props = { ...moreProps, hasError, isFilled, disabled };

  const onFocusInput = (event) => {
    setHasFocus(true);
    if (onFocus) {
      onFocus(event);
    }
  };

  const onBlurInput = (event) => {
    setHasFocus(false);
    if (onBlur) {
      onBlur(event);
    }
  };

  const showHidePasswordIcon = () => {
    if (showPassword) {
      return <EyeLineIcon onClick={() => setShowPassword(!showPassword)} className="icon" />;
    }

    return <EyeOffLineIcon onClick={() => setShowPassword(!showPassword)} className="icon" />;
  };

  return (
    <Space size="small" direction="vertical" className={className}>
      <InputWrapper hasError={ hasError } disabled={disabled}>
        <label className={ (inputType === 'date' || isFilled || hasFocus) ? "label k-ds-label-01" : "label as-placeholder k-ds-heading-02"} htmlFor={id}>
          {label}
        </label>
        <Space size="small" justify="end" className="k-ds-width-full" direction="horizontal">
          <span className="required k-ds-label-01 k-ds-text-color-error">
            { required ? ' *' : '' }
          </span>
        </Space>
        <StyledInputOutline
          type={showPassword ? 'text' : inputType}
          id={id}
          value={value}
          onChange={onChange}
          onFocus={onFocusInput}
          onBlur={onBlurInput}
          className="k-ds-width-full k-ds-heading-02"
          placeholder={label ? '' : placeholder}
          {...props}
        />
        { inputType === 'password' ? showHidePasswordIcon() : []}
        {
          helper || hasError ? (
            <span className="helper-error k-ds-caption-01">
              {errorMessage || helper}
            </span>
          ) : null
        }
      </InputWrapper>
    </Space>
  );
};

export {
  InputLine,
};
