import PropTypes from 'prop-types';
import React from 'react';
import TdDefault from './TdDefault';
import TdToggle from './TdToggle';
import TdToggleWithFallback from './TdToggleWithFallback';
import TdWithHeader from './TdWithHeader';
import ThResponsive from './ThResponsive';
import TrCollapsable from './TrCollapsable';

const TdResponsiveType = {
  default: TdDefault,
  toggle: TdToggleWithFallback,
  toggle2: TdToggle,
  header: TdWithHeader,
};

const TdResponsive = ({
  type = 'default',
  devices = 'mobile tablet desktop',
  ...props
}) => {
  const Component = TdResponsiveType[type];
  return <Component {...Object.assign(props, { devices })} />;
};

TdResponsive.propTypes = {
  type: PropTypes.oneOf(['default', 'toggle', 'header', 'toggle2']),
  devices: PropTypes.string,
};

export { TdResponsive, TrCollapsable, ThResponsive };
