import React from 'react';
import { Card, Space, Tag, Button } from '../../ds';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowIcon } from './right-arrow-icon.svg';
import { useHistory } from 'react-router-dom';

const CampaignCard = ({ campaign_id, name, description, upcoming }) => {
  const history = useHistory();
  const goDetails = campaign_id => {
    history.push('campaigns/' + campaign_id);
    const contentArea = document.getElementById('content-area');
    contentArea.scrollTop = 0;
  };

  return (
    <Card
      className='k-ds-m-bottom-sp-06 campaign-card k-ds-width-full'
      shadow={true}
      onClick={() => goDetails(campaign_id)}
    >
      <Space
        justify='space-between'
        direction='horizontal'
        className='k-ds-width-full'
      >
        <Space direction='vertical'>
          <div className='k-ds-heading-02'>
            {name}
            {upcoming && (
              <Tag
                color='orange-outline'
                className='upcoming-tag k-ds-m-left-sp-08'
              >
                ¡PRÓXIMAMENTE!
              </Tag>
            )}
          </div>
          <div className='k-ds-body-01'>{description}</div>
        </Space>
        <Space className='campaign-detail-button-container'>
          <Space className='k-ds-width-full'>
            <Button
              type='link'
              className='campaign-detail-button k-ds-width-full'
              onClick={() => goDetails(campaign_id)}
            >
              Ver detalles
              <ArrowIcon className='k-ds-m-left-sp-03' />
            </Button>
          </Space>
        </Space>
      </Space>
    </Card>
  );
};

CampaignCard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  campaign_id: PropTypes.string.isRequired,
  upcoming: PropTypes.bool.isRequired,
};

export default CampaignCard;
