import React from 'react';
import { ReactComponent as CheckMail } from './revisa-correo.svg';
import { Button } from '../../../ds';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const PasswordRecovered = ({ handleSubmit }) => {
  const router = useHistory();
  const goToLogin = () => {
    router.push('/login');
  };
  return (
    <div className='login'>
      <div className='recover-greeting text-center'>
        <div className='flex-centered'>
          <CheckMail />
        </div>

        <div className='k-ds-heading-05'>Verifica tu correo electrónico</div>
        <p>
          Te hemos enviado las instrucciones necesarias para restablecer tu
          contraseña
        </p>
        <div className='k-ds-m-bottom-sp-09'>
          <Button type='link' size='small' onClick={handleSubmit}>
            Reenviar correo con instrucciones
          </Button>
        </div>

        <p className='k-ds-label-01 k-ds-m-top-sp-07 k-ds-m-bottom-sp-07'>
          ¿No recibiste ningún correo? Revisa en tu bandeja de{' '}
          <b>no deseados</b>, <br />o envíanos un correo a:{' '}
          <b>merchants@kueskipay.com</b> y trataremos de <br />
          responderte lo antes posible.
        </p>

        <div className='k-ds-m--07'></div>

        <Button
          className='k-ds-m-top-sp-07'
          borderWidth=''
          horizontalPadding=''
          size='small'
          type='primary'
          verticalPadding='16px'
          onClick={goToLogin}
        >
          Regresar a inicio de sesión
        </Button>
      </div>
    </div>
  );
};

PasswordRecovered.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default PasswordRecovered;
