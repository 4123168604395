import * as amplitude from '@amplitude/analytics-browser';
import Auth from './../../../Utils/Auth/auth';
import Timer from './../../../Utils/Timer';
import { cleanEmptyValues, isEmptyObject } from '../../../Utils/utils';
import { getUserProfile } from '../AnalyticsEvent';

const webMobileInteractionChannel = 'web_mobile';
const webDesktopInteractionChannel = 'web_desktop';

export const amplitudeParams = {
  eventTime: 'event_time',
  userId: 'user_id',
  userProfile: 'user_profile',
  email: 'email',
  merchantId: 'merchant_id',
  merchantName: 'merchant_name',
  merchantIndustry: 'merchant_industry',
  logInLocation: 'log_in_location',
  logInDevice: 'log_in_device',
  employeeId: 'employee_id',
  rol: 'rol',
  recurrence: 'recurrence',
  branchInCharge: 'branch_in_charge',
  storeInCharge: 'store_in_charge',
  orderType: 'order_type',
  branchName: 'branch_name',
  branchId: 'branch_id',
  storeId: 'store_id',
  storeName: 'store_name',
  paymentId: 'payment_id',
  paymentReference: 'payment_reference',
  totalPayment: 'total_payment',
  totalItems: 'total_items',
  paymentLink: 'payment_link',
  funnelStep: 'funnel_step',
  userAcepptancePayment: 'user_acepptance_payment',
  searchParameter: 'search_parameter',
  searchResults: 'search_results',
  inputType: 'input_type',
  errorType: 'error_type',
  errorDescription: 'error_description',
  queryId: 'query_id',
  stores: 'stores',
  reimbursementType: 'reimbursement_type',
  pageName: 'page_name',
  error: 'error',
};

const getLocalStorage = key => window.localStorage?.getItem(key);

const AmplitudeEventTrackerPropTypes = {
  instance: null,
  trackEvent: function (event) {
    amplitude.track(event.name, this.fullParams(event));
  },
  commonParams: function () {
    const merchnat = this.getMerchant();
    const employee = this.getEmployee();

    if (!isEmptyObject(employee) && employee.employee_id) {
      amplitude.setUserId(employee.employee_id);
    }

    let commonParams = {
      [amplitudeParams.employeeId]: employee.employee_id,
      [amplitudeParams.userProfile]: getUserProfile(employee.role.name),
      [amplitudeParams.eventTime]: Timer().toNowUTC(),
      [amplitudeParams.merchantId]: merchnat.merchant_id,
      [amplitudeParams.merchantName]: merchnat.name,
      [amplitudeParams.merchantIndustry]: '',
      [amplitudeParams.logInLocation]: location.origin,
      [amplitudeParams.logInDevice]: this.determineInteractionChannel(),
    };

    return commonParams;
  },
  determineInteractionChannel: function () {
    if (/Mobi/.test(navigator.userAgent)) {
      return webMobileInteractionChannel;
    } else {
      return webDesktopInteractionChannel;
    }
  },
  getMerchant: function () {
    try {
      const merchantSelected = getLocalStorage('merchantSelected');
      const merchantDefault = getLocalStorage('merchant');
      const merchant = JSON.parse(merchantSelected ?? merchantDefault);

      if (isEmptyObject(merchant)) {
        return { name: '', merchant_id: '' };
      }

      return merchant;
    } catch (error) {
      return { name: '', merchant_id: '' };
    }
  },
  getEmployee: function () {
    try {
      const { employee } = Auth.userData();

      if (isEmptyObject(employee)) {
        return {
          email: '',
          employee_id: '',
          role: { name: 'not_user_profile' },
        };
      }

      if (isEmptyObject(employee.role)) {
        return { ...employee, role: { name: 'not_user_profile' } };
      }

      const email = employee.email ? employee.email : '';
      return { ...employee, email: email };
    } catch (error) {
      return { email: '', employee_id: '', role: { name: '' } };
    }
  },
  fullParams: function (event) {
    const params = event && event.params ? event.params : {};
    return cleanEmptyValues({
      ...this.commonParams(),
      ...params,
    });
  },
};

export default function AmplitudeEventTracker() {
  return Object.create(AmplitudeEventTrackerPropTypes);
}
