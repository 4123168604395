/* eslint-disable react/prop-types */
import styled from 'styled-components';
import React from 'react';
import { ReactComponent as IconError } from './icon-error.svg';
import KDS from '../../../ds/lib/k-ds-foundation';

const StyledMessageError = styled.div`
  display: flex;
  align-items: flex-start;

  border-radius: 4px;
  box-sizing: border-box;
  padding: 16px 20px;
  border: 1px solid #d50000;

  ${KDS.setColorWithFallback('background: ', 'red-100')}

  .icon {
    min-width: 18px;
  }

  .message {
    overflow: hidden;
    ${KDS.setSpacingWithFallback('margin-left: ', 'spacing-04')};
    overflow-wrap: break-word;
  }
`;

const MessageError = ({ message, ...moreProps }) => (
  <StyledMessageError {...moreProps}>
    <IconError className='icon' />
    <div className='message k-ds-label-01'>{message}</div>
  </StyledMessageError>
);

export { MessageError };
