import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { formatDate, numberWithCommas } from '../../Utils/formats';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { useLoaderContext } from '../../contexts/Loader/LoaderContext';
import {
  Button,
  Card,
  Divider,
  Space,
  Table,
  TableMobile,
  Tag,
} from '../../ds';
import { getPayment } from '../../services/Application';
import GetBackSection from '../GetBackSection';
import Header from '../Header';
import NotFound from '../NotFound/NotFound';
import { ReactComponent as MerchantIcon } from './merchant-icon.svg';
import { useStore } from '../../services/store';
import { useGetMerchant } from './../../Utils/useGetMerchant';
import { useGetMerchants } from './../../Utils/useGetMerchants';
import './paymentDetail.scss';
import AnalyticsEvent, {
  AnalyticsList,
} from '../../services/analytics/AnalyticsEvent';

const tableHeaders = {
  refundId: 'ID de la cancelación',
  refundType: 'Tipo de cancelación',
  refundDate: 'Fecha de solicitud',
  refundAmount: 'Monto cancelado',
  refundResolutionDate: 'Fecha de resolución',
  refundStatus: 'Estatus',
};

function PaymentDetail() {
  const {
    merchantTransactionsReport: { transactionsReport },
  } = useStore();
  const merchants = useGetMerchants();

  const router = useHistory();
  const merchantSelected = useGetMerchant();
  const viewport = useDeviceContextViewport();
  const { paymentId } = useParams();
  const { isLoaderOpen, setIsLoaderOpen } = useLoaderContext();

  const [payment, setPayment] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const isTracked = useRef(false);
  const TableComponent = viewport.isMobile ? TableMobile : Table;
  const tableSize = viewport.isMobile ? 'small' : 'medium';

  useEffect(() => {
    if (!merchantSelected || !paymentId) {
      return;
    }

    setIsLoaderOpen(true);
    getPayment(paymentId)
      .then(response => {
        if (response.status !== 'success') {
          setNotFound(true);
          return;
        }
        setPayment(response.data);
      })
      .catch(error => {
        AnalyticsEvent().errorTrack({
          errorType: 'payment-detail-get-payment-error',
          errorDescription: error,
        });
        setPayment(null);
        setNotFound(true);
      })
      .finally(() => setIsLoaderOpen(false));
  }, []);

  useEffect(() => {
    if (!isTracked.current) {
      AnalyticsEvent().pageView({ page: AnalyticsList.paymentDetail });
      isTracked.current = true;
    }
  }, []);

  if (!merchantSelected) {
    return '';
  }

  if (isLoaderOpen || !payment) return 'Regresar';

  if (notFound) return <NotFound />;

  return (
    <>
      <GetBackSection redirectUri='/payments' render />
      <Header
        title='Detalle de cobro del cliente'
        subtitle='Aquí encontrarás el detalle de la orden creada.'
      >
        {payment?.available_amount_to_refund > 0 && (
          <Button
            className='reimbursement-button'
            verticalPadding='1rem'
            horizontalPadding='2rem'
            onClick={() => {
              if (payment) {
                AnalyticsEvent().transactionCancelled({
                  storeName: payment?.store ? payment?.store.name : '',
                  storeId: payment?.store ? payment?.store.store_id : '',
                  paymentId: payment.payment_id ? payment?.payment_id : '',
                  funnelStep: 'Payment Details',
                });
              }
              router.push(`/payments/${paymentId}/reimbursement`);
            }}
          >
            Solicitar cancelación
          </Button>
        )}
      </Header>
      <Space
        direction={viewport.isMobile ? 'vertical' : 'horizontal'}
        justify={viewport.isMobile ? 'space-between' : 'space-between'}
        className='k-ds-m-bottom-sp-07'
      >
        <Space
          direction='vertical'
          className={viewport.isMobile ? '' : 'k-ds-m-rigth-sp-09'}
        >
          <div className='payment-title k-ds-heading-03 k-ds-m-bottom-sp-05'>
            Datos del pago
          </div>
          <Space direction='horizontal' className='payment-info'>
            <Space
              direction='vertical'
              className={`payment-info-column ${
                viewport.isDesktop ? 'k-ds-m-rigth-sp-09' : 'k-ds-m-rigth-sp-06'
              }`}
            >
              <Space direction='vertical' className='k-ds-m-bottom-sp-04'>
                <div className='k-ds-body-01 payment-label'>ID de pago:</div>
                <div className='k-ds-heading-02'>{payment?.payment_id}</div>
              </Space>
              <Space direction='vertical' className='k-ds-m-bottom-sp-04'>
                <div className='k-ds-body-01 payment-label'>
                  Fecha y hora del pago:
                </div>
                <div className='k-ds-heading-02'>
                  {formatDate(payment?.created_at)}
                </div>
              </Space>
              {payment.channel === 'instore' && payment?.order_reference && (
                <Space direction='vertical' className='k-ds-m-bottom-sp-04'>
                  <div className='k-ds-body-01 payment-label'>
                    Referencia de orden:
                  </div>
                  <div className='k-ds-heading-02'>
                    {payment?.order_reference}
                  </div>
                </Space>
              )}
            </Space>
            <Space direction='vertical' className='payment-info-column'>
              {payment.channel === 'instore' && payment?.staff && (
                <Space
                  direction='vertical'
                  className={
                    viewport.isDesktop
                      ? 'k-ds-m-bottom-sp-04 k-ds-m-left-sp-09'
                      : 'k-ds-m-bottom-sp-04'
                  }
                >
                  <div className='k-ds-body-01 payment-label'>Vendedor:</div>
                  <div className='k-ds-heading-02'>{payment?.staff.name}</div>
                </Space>
              )}
              {payment.channel === 'instore' && payment?.store && (
                <Space
                  direction='vertical'
                  className={
                    viewport.isDesktop
                      ? 'k-ds-m-bottom-sp-04 k-ds-m-left-sp-09'
                      : 'k-ds-m-bottom-sp-04'
                  }
                >
                  <div className='k-ds-body-01 payment-label'>Sucursal:</div>
                  <div className='k-ds-heading-02'>{payment?.store.name}</div>
                </Space>
              )}
              {(payment.channel === 'online' ||
                payment.channel === 'ecommerce') && (
                <Space
                  direction='vertical'
                  className={
                    viewport.isDesktop
                      ? 'k-ds-m-bottom-sp-04 k-ds-m-left-sp-09'
                      : 'k-ds-m-bottom-sp-04'
                  }
                >
                  <div className='k-ds-body-01 payment-label'>
                    ID de la orden:
                  </div>
                  <div className='k-ds-heading-02'>{payment?.order_id}</div>
                </Space>
              )}
              <Space
                direction='vertical'
                className={
                  viewport.isDesktop
                    ? 'k-ds-m-bottom-sp-04 k-ds-m-left-sp-09'
                    : 'k-ds-m-bottom-sp-04'
                }
              >
                <div className='k-ds-body-01 payment-label'>Canal:</div>
                <div className='k-ds-heading-02'>
                  {payment.channel === 'online' ||
                  payment.channel === 'ecommerce'
                    ? 'En línea'
                    : 'En tienda'}
                </div>
              </Space>
            </Space>
          </Space>
        </Space>
        <Card shadow className='payment-card'>
          <Space
            direction='vertical'
            className='k-ds-m-vertical-sp-02 k-ds-m-horizontal-sp-03'
          >
            <Space
              direction='horizontal'
              align='center'
              className='k-ds-m-bottom-sp-04'
            >
              <MerchantIcon />
              <div className='k-ds-heading-03'>
                {merchants && merchants.length > 0
                  ? merchants.find(
                      item => item.value === transactionsReport.merchantId
                    ).label
                  : merchantSelected.name}
              </div>
            </Space>
            <Divider color='grey-900' thickness='0.5px' />
            <Space direction='vertical' className='k-ds-m-bottom-sp-04'>
              <div className='k-ds-heading-04 k-ds-m-bottom-sp-05'>
                Detalle de pago
              </div>
              <Space
                direction='horizontal'
                justify='space-between'
                className={
                  payment.refunded_amount > 0
                    ? 'k-ds-body-01 k-ds-m-bottom-sp-05'
                    : 'k-ds-body-01'
                }
              >
                <div>Monto Total:</div>
                <div>${numberWithCommas(payment?.total ?? '')}</div>
              </Space>
              {payment.refunded_amount > 0 &&
                payment.refunds.map(
                  (item, i) =>
                    item.refund_status === 'completed' && (
                      <Space
                        key={i}
                        direction='horizontal'
                        justify='space-between'
                        className='k-ds-body-01'
                      >
                        <div>Monto Cancelado:</div>
                        <div className='reimbursement-label'>
                          -${numberWithCommas(item.amount ?? '')}
                        </div>
                      </Space>
                    )
                )}
            </Space>
            <Divider color='grey-900' thickness='0.5px' />
            <Space
              direction='horizontal'
              justify='space-between'
              className='k-ds-heading-02'
            >
              <div>Total:</div>
              <div className='payment-total'>
                ${numberWithCommas(payment?.available_amount_to_refund ?? '')}
              </div>
            </Space>
          </Space>
        </Card>
      </Space>
      {payment.refunds.length > 0 && (
        <Space direction='vertical'>
          <Divider color='grey-900' thickness='0.5px' />
          <Space
            direction='vertical'
            className={
              viewport.isMobile ? '' : 'k-ds-m-rigth-sp-09 k-ds-m-bottom-sp-07'
            }
          >
            <div className='k-ds-heading-03 k-ds-m-top-sp-05 k-ds-m-bottom-sp-03'>
              Cancelaciones
            </div>
            <div className='k-ds-body-01 k-ds-m-top-sp-07'>
              Consulta el estado de las cancelaciones solicitadas.
            </div>
          </Space>
          <TableComponent
            type='b2b'
            size={tableSize}
            style={{ width: '100%' }}
            className='k-ds-m-bottom-lyt-04'
          >
            <thead>
              <tr>
                <th>{tableHeaders.refundId}</th>
                <th>{tableHeaders.refundType}</th>
                <th>{tableHeaders.refundDate}</th>
                <th>{tableHeaders.refundAmount}</th>
                <th>{tableHeaders.refundResolutionDate}</th>
                <th>{tableHeaders.refundStatus}</th>
              </tr>
            </thead>
            <tbody>
              {payment.refunds.map(item => (
                <tr key={item.refund_id}>
                  <td data-title={tableHeaders.refundId}>{item.refund_id}</td>
                  <td data-title={tableHeaders.refundType}>
                    {item.refund_type === 'total' ? 'Total' : 'Parcial'}
                  </td>
                  <td data-title={tableHeaders.refundDate}>
                    {formatDate(item.created_at)}
                  </td>
                  <td
                    data-title={tableHeaders.refundAmount}
                  >{`$ ${numberWithCommas(item.amount.toFixed(2))}`}</td>
                  <td data-title={tableHeaders.refundResolutionDate}>
                    {item.completed_at ? formatDate(item.completed_at) : '-'}
                  </td>
                  <td data-title={tableHeaders.refundStatus}>
                    {item.refund_status && item.refund_status === 'created' && (
                      <Tag color='grey'>{'SOLICITADA'}</Tag>
                    )}
                    {item.refund_status &&
                      item.refund_status === 'processing' && (
                        <Tag color='orange'>{'EN REVISIÓN'}</Tag>
                      )}
                    {item.refund_status &&
                      item.refund_status === 'completed' && (
                        <Tag color='green'>{'AUTORIZADA'}</Tag>
                      )}
                    {item.refund_status && item.refund_status === 'failed' && (
                      <Tag color='red'>{'RECHAZADO'}</Tag>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </TableComponent>
        </Space>
      )}
    </>
  );
}

export default PaymentDetail;
