import React from 'react';
import ErrorBoundary from '../../../components/Error/ErrorBoundary';
import PayOrderQrVersionSelector from './PayOrderQrVersionSelector';

const PayOrderByQRPage = () => {

  return (
    <ErrorBoundary>
      <PayOrderQrVersionSelector />
    </ErrorBoundary>
  );
}

export default PayOrderByQRPage;
