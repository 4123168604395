import styled, { css } from 'styled-components';
import React, { useState, useEffect } from 'react';
import { Space } from '../space/index';
import { ReactComponent as IconArrowDown } from './icon-arrow-down.svg';
import KDS from '../../lib/k-ds-foundation';

const StyledSelectOutline = styled.div`
  position: relative;

  .selected {
    ${KDS.setColorTokenWithFallback('border: 1px solid ', 'interactive-alternate')}
    border-radius: 8px;
    box-sizing: border-box;
    padding: 14px;

    ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}

    cursor: pointer;

    &.placeholder {
      ${KDS.setColorTokenWithFallback('color: ', 'text-secondary')}
    }

    .show {
      transform: rotateX(180deg);
    }

    &.disabled {
      cursor: default;

      ${KDS.setColorTokenWithFallback('border: 1px solid ', 'background-alternate')}
      ${KDS.setColorTokenWithFallback('color: ', 'text-placeholder')}

      svg path {
        ${KDS.setColorTokenWithFallback('fill: ', 'background-alternate')}
      }
    }
  }

  .options-group {
    position: absolute;

    z-index: 1;

    display: none;
    transform: translate(0, ${KDS.getSpacing('spacing-01')});
    transform: translate(0, var(--k-ds-spacing-01));

    overflow-y: auto;

    width: 100%;
    max-height: 186px;

    ${KDS.setColorWithFallback('background: ', 'white')}

    ${KDS.setColorTokenWithFallback('border: 1px solid ', 'interactive-alternate')}
    border-radius: 8px;
    box-sizing: border-box;

    ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}

    &.show {
      display: block;
    }
  }

  .option {
    input[type="radio"] {
      display: none;
    }

    label {
      display: block;
      padding: 14px;
      cursor: pointer;

      :hover {
        ${KDS.setColorTokenWithFallback('background: ', 'background-alternate')}
        ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary')}
      }
    }
  }

  .helper-error {
    position: absolute;
    top: 3.5rem;
    left: 0;
    right: 0;

    ${KDS.setColorTokenWithFallback('color: ', 'text-placeholder')}

    ${(props) => props.hasError && css`
      ${KDS.setColorTokenWithFallback('color: ', 'error')}
    `}
  }
`;

const SelectOutline = ({
  className, children, type, name, form, label, placeholder, options,
  value, onChange, onBlur, disabled, errorMessage, optional, helper, ...moreProps
}) => {
  const hasError = Boolean(errorMessage);
  const { required } = moreProps;

  const props = { ...moreProps, hasError };

  const [isActive, setIsActive] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, []);

  let valueText = '';
  const selectOptions = options || [];
  const optionsData = selectOptions.map((option) => {
    let id = option.text.toLowerCase().replace(/ /g, '_');
    id = `${name || ''}_${id}`;
    const isSelected = (option.value === value);
    if (isSelected) {
      valueText = option.text;
    }
    return {
      id, text: option.text, value: option.value, isSelected,
    };
  });

  const onSelectClick = () => {
    if (disabled) return;

    setIsActive(!isActive);
  };

  const onOptionClick = (optionValue) => {
    if (onChange) onChange(optionValue);
    setIsActive(false);
  };

  useEffect(() => {
    if (!isActive && !isFirstRender) {
      if (onBlur) onBlur();
    }
  }, [isActive]);

  const selectText = valueText || placeholder;
  const placeholderClass = valueText ? '' : 'placeholder';
  const showClass = (isActive && !disabled) ? 'show' : '';
  const disabledClass = disabled ? 'disabled' : '';

  return (
    <Space size="small" direction="vertical" className={className} {...props}>
      <Space size="small" justify="space-between" className="k-ds-width-full" direction="horizontal">
        <label className="k-ds-body-01 k-ds-text-color-primary" htmlFor={name}>
          {label}
          { required ? <span className="k-ds-text-color-error">{' *'}</span> : null }
        </label>
        <span className="k-ds-label-01 k-ds-text-color-interactive-secondary">{optional}</span>
      </Space>
      <StyledSelectOutline className="k-ds-width-full k-ds-body-01" hasError={ hasError }>
        <Space size="medium" justify="space-between" className={`k-ds-width-full selected ${placeholderClass} ${disabledClass}`} onClick={onSelectClick} direction="horizontal" align="center" aria-hidden>
          <span>{selectText}</span>
          <IconArrowDown className={`${showClass}`} />
        </Space>
        {selectOptions.length > 0 ?
          (
            <div className={`options-group ${showClass}`}>
              {optionsData.map((option) => (
                <div key={option.id} className="option">
                  <input type="radio" id={option.id} name={name} value={option.value} form={form} defaultChecked={option.isSelected} />
                    <label htmlFor={option.id} onClick={() => onOptionClick(option.value)} aria-hidden="true">
                      {option.text}
                    </label>
                  </div>
                ))
              }
            </div>
          ) : null
        }

        {
          helper || hasError ? (
            <span className="helper-error k-ds-caption-01">
              {errorMessage || helper}
            </span>
          ) : null
        }
      </StyledSelectOutline>
    </Space>
  );
};

export {
  SelectOutline,
};
