import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Auth from '../../Utils/Auth/auth';
import { ACCOUNT_MANAGER } from '../../Utils/constants';
import Carousel from '../../components/Carousel';
import Checkbox from '../../components/Checkbox/Checkbox';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { Modal } from '../../ds';
import ChannesMobile from './res/channels-mobile.png';
import CouponMobile from './res/coupon-mobile.png';
import CouponExample from './res/kueski-coupon.png';
import ChannelsExample from './res/payment-channels.png';

const CustomModal = styled(Modal)`
  overflow: auto;
`;

const Title = styled.div`
  color: #000;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.48px;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

const Container = styled.div`
  width: 300px;
  @media (min-width: 768px) {
    width: 474px;
  }
  max-height: calc(100vh - 70px);
  overflow: auto;
  padding: 0 16px;
`;

const InfoHeader = styled.div`
  color: var(--light-text-primary, #18223d);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: ${props => (props.bold ? 700 : 500)};
  line-height: 130%;
  letter-spacing: 0.28px;
`;

const List = styled.li`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.28px;
`;

const InfoContainer = styled.div`
  padding: 15px 0;
`;
const Ulist = styled.ul`
  padding-left: 20px;
`;

const Olist = styled.ol`
  padding: 10px 20px;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 282px;
  padding-top: 35px;
  padding-bottom: 44px;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @media (min-width: 768px) {
    width: 464px;
    height: 280px;
  }
`;

const StyledCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding-bottom: 30px;
`;

const BannersInfo = {
  0: (
    <div>
      <InfoHeader bold>Renovamos las opciones de nuestro menú:</InfoHeader>
      <Ulist>
        <List>
          Ahora, las órdenes de pago cambiaron por{' '}
          <strong>Canales de cobro</strong>.
        </List>
        <List>
          Descubre el <strong>Código de Pago y el Código QR</strong>, tus formas
          sencillas de cobrar con Kueski Pay.
        </List>
        <List>
          El historial de órdenes ahora se llama{' '}
          <strong>Historial de cobros</strong>.
        </List>
      </Ulist>
    </div>
  ),
  1: (
    <div>
      <InfoHeader>
        <strong>
          Ahora puedes autorellenar el Código de pago escaneando el QR desde el
          celular de tu cliente con tu POS:
        </strong>
      </InfoHeader>

      <Olist>
        <List>
          Accede a <strong>Código de pago</strong> en el Portal y solicita el QR
          al cliente.
        </List>
        <List>Escanea el QR con tu POS; se autocompletará el código.</List>
        <List>Cobra y espera la confirmación del cliente.</List>
        <List>¡Listo! Entrega los productos.</List>
      </Olist>
    </div>
  ),
  2: (
    <div>
      <InfoHeader>
        El <strong>Vale Kueski</strong> ahora es <strong>Código de pago</strong>
        . Conoce cómo usarlo:
      </InfoHeader>

      <Olist>
        <List>Ingresa los 6 dígitos que comparte el cliente.</List>
        <List>Genera el cobro y espera la confirmación del cliente.</List>
        <List>¡Listo! Ahora puedes entregar los productos.</List>
      </Olist>
    </div>
  ),
};

const ModalPaymentUpdate = () => {
  const { employee } = Auth.userData();
  const employeeRole = employee?.role?.name;

  const [current, setCurrent] = useState(0);
  const [isActive, setisActive] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(() => {
    if (employeeRole === ACCOUNT_MANAGER) return false;

    const cachedData = localStorage.getItem('payment-channels-modal');
    if (!cachedData) {
      return true;
    }
    const parsedCacheEntry = JSON.parse(cachedData);
    return parsedCacheEntry ? parsedCacheEntry.show : false;
  });

  const viewport = useDeviceContextViewport();
  const isMobileOnly = viewport.width < 768;

  useEffect(() => {
    if (current === 1 && !isActive) {
      setisActive(true);
    }
  }, [current, setisActive, isActive]);

  const handleUpdate = active => {
    setCurrent(active);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleOnClose = () => {
    if (isChecked) {
      localStorage.setItem(
        'payment-channels-modal',
        JSON.stringify({ show: false })
      );
    }

    setShowModal(false);
  };

  return (
    <CustomModal
      showClose
      className='modal'
      theme='dark'
      cardType='secondary'
      onClose={handleOnClose}
      {...{ isOpen: showModal }}
    >
      <Container>
        <Title>¡Descubre lo nuevo!</Title>

        <Carousel
          segTimer={20000}
          stopInEnd
          hideArrows
          handleUpdateParent={handleUpdate}
          banners={[
            <ImageContainer key={1}>
              {isMobileOnly ? (
                <img src={ChannesMobile} alt='Canales de cobro' />
              ) : (
                <img src={ChannelsExample} alt='Canales de cobro' />
              )}
            </ImageContainer>,
            <ImageContainer key={2}>
              {isMobileOnly ? (
                <img src={CouponMobile} alt='Kueski Cupon' />
              ) : (
                <img src={CouponExample} alt='Kueski Cupon' />
              )}
            </ImageContainer>,
          ]}
        />

        <InfoContainer>
          {isMobileOnly
            ? BannersInfo[current === 1 ? 2 : 0]
            : BannersInfo[current]}
        </InfoContainer>
        <StyledCheckboxWrapper>
          <Checkbox
            filled
            name='checkbox'
            defaultChecked={isChecked}
            onChange={handleCheckboxChange}
          />
          No volver a mostrar
        </StyledCheckboxWrapper>
      </Container>
    </CustomModal>
  );
};

export default ModalPaymentUpdate;
