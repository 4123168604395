import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { isFeatureEnabled } from '../../Utils/Feature/feature_flag';
import { Button, Space } from '../../ds';
import './addUserSelectionModal.scss';
import AnalyticsEvent from '../../services/analytics/AnalyticsEvent';

const AddUserSelection = ({
  isMobileOnly,
  goToAddUser,
  goToUploadUsers,
  downloadTemplate,
  handleClose,
}) => {
  const [isFirstView, setIsFirstView] = useState(true);
  return (
    <Space
      direction='vertical'
      align='center'
      className={classNames('selection-container', 'custom-container')}
    >
      <>
        {isFirstView ? (
          <div>
            <div className='k-ds-heading-04 k-ds-m-bottom-sp-09'>
              ¿Cuántos usuarios deseas crear?
            </div>

            <div className='button-option'>
              <Button
                className='button'
                type='outline'
                onClick={() => {
                  AnalyticsEvent().addOneUser();
                  goToAddUser();
                }}
              >
                1 usuario
              </Button>
              {isFeatureEnabled('import_users') && (
                <Button
                  className='button'
                  type='outline'
                  onClick={() => {
                    AnalyticsEvent().addMultipleUsers();
                    setIsFirstView(false);
                  }}
                >
                  Varios usuarios a la vez
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div>
            <div className='k-ds-heading-04 k-ds-m-bottom-sp-09'>
              ¿Ya cuentas con la plantilla .xlsx para agregar a tus usuarios?
            </div>
            <div className='button-option'>
              <Button
                className='button'
                type='outline'
                onClick={goToUploadUsers}
              >
                Sí, adjuntar archivo
              </Button>
              <Button
                className='button'
                type='outline'
                onClick={downloadTemplate}
              >
                No, descargar plantilla
              </Button>
            </div>
          </div>
        )}
      </>
      <Space
        direction='vertical'
        className={
          isMobileOnly
            ? 'k-ds-body-01 k-ds-m-bottom-sp-05 close-container'
            : 'k-ds-body-02 k-ds-m-bottom-sp-06 close-container'
        }
      >
        <Button
          type='link'
          size='small'
          onClick={handleClose}
          className='close-button'
        >
          Cancelar
        </Button>
      </Space>
    </Space>
  );
};

AddUserSelection.propTypes = {
  isMobileOnly: PropTypes.bool.isRequired,
  goToAddUser: PropTypes.func,
  goToUploadUsers: PropTypes.func,
  downloadTemplate: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
};

export default AddUserSelection;
