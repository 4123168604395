import ImageCarousel from '../../../components/Carousel/ImageCarousel';
import React from 'react';
import Section from './Section';
import PropTypes from 'prop-types';
import styles from './Home.module.scss';

const DiscoverSection = ({ items, isMobileOnly }) => {
  if (!items.length) {
    return null;
  }

  return (
    <>
      <hr className={styles.solidDivider} />
      <Section
        title='¡Descubre lo nuevo!'
        hint='Conoce las últimas actualizaciones y cambios en el Portal.'
      />

      <ImageCarousel
        autoplay
        slidesToShow={isMobileOnly ? 1.1 : 1}
        items={items}
        speed={25000}
        dots
      />
    </>
  );
};

DiscoverSection.propTypes = {
  items: PropTypes.array.isRequired,
  isMobileOnly: PropTypes.bool,
};

export default DiscoverSection;
