import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '../../../ds';
import { ReactComponent as RefreshIcon } from '../refresh-icon.svg';

const RefreshButton = ({ handleRefresh }) => {
  return (
    <Button
      type='link'
      size='small'
      horizontalPadding='0px'
      verticalPadding='0px'
      className='k-ds-m-left-lyt-04'
      onClick={() => handleRefresh(Date.now())}
    >
      <RefreshIcon className='k-ds-m-right-sp-03' />
      Actualizar
    </Button>
  );
};

RefreshButton.propTypes = {
  handleRefresh: PropTypes.func.isRequired,
};

export default RefreshButton;
