import styled from 'styled-components';
import { Button } from '../../../ds';

export const DownloadLink = styled(Button)`
  padding: 8px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  justify-self: end;
  align-items: center;

  .text-button {
    margin-left: 4px;
  }
`;
