import { useQuery } from '@tanstack/react-query';
import MerchantPaymentsService from '../../../services/MerchantPaymentsService';
import { useLoaderContext } from '../../../contexts/Loader/LoaderContext';
import Timer from '../../../Utils/Timer';
import { getBankName, currencyFormatter } from '../../../Utils/formats';
import { useStore } from '../../../services/store';

const PAYMENT_FREQUENCY = {
  MonthlyOn24: '24 de cada mes',
  BiWeeklyOnMondayAndThursday: 'Lunes y jueves de cada semana',
  WeeklyOnMonday: 'Todos los lunes',
  WeeklyOnTuesday: 'Todos los martes',
  WeeklyOnWednesday: 'Todos los miercoles',
  WeeklyOnThursday: 'Todos los jueves',
  WeeklyOnFriday: 'Todos los viernes',
  WeeklyOnSaturday: 'Todos los sábados',
};

const buildPaymentInfo = ({
  payment_frequency,
  payment_date,
  disbursement_information,
  next_payment_date,
}) => {
  const paymentInfo = {
    paymentFrequency: payment_frequency
      ? PAYMENT_FREQUENCY[payment_frequency]
      : '-',
    paymentDate: payment_date
      ? Timer().toUTC(payment_date, 'EEEE d / MMMM / y')
      : '-',
    disbursementSender: '-',
    disbursementRecipient: '-',
    nextPaymentDate: next_payment_date
      ? Timer().toUTC(next_payment_date, 'EEEE d / MMMM / y')
      : '-',
  };

  if (disbursement_information) {
    const { sender, recipient } = disbursement_information;
    paymentInfo.disbursementSender = sender
      ? `${sender.account_last_digits ? `** ${sender.account_last_digits}` : ''} - ${getBankName(sender.bank_code)}`
      : '';
    paymentInfo.disbursementRecipient = recipient
      ? `${recipient.account_last_digits ? `** ${recipient.account_last_digits}` : ''} - ${getBankName(recipient.bank_code)}`
      : '-';
  }

  return paymentInfo;
};

const buildPaymentDetails = ({
  payments_total,
  payments_commission,
  payments_subtotal,
  refunds_count,
  refunds_total,
  disbursement_amount,
}) => {
  return {
    paymentsTotal: payments_total
      ? `${currencyFormatter(payments_total)} m.n.`
      : '-',
    paymentsCommission: payments_commission
      ? `${currencyFormatter(payments_commission)} m.n.`
      : '-',
    paymentsSubtotal: payments_subtotal
      ? `${currencyFormatter(payments_subtotal)} m.n.`
      : '-',
    cancellations: refunds_count || 0,
    refundsTotal: refunds_total
      ? `${currencyFormatter(refunds_total)} m.n.`
      : '-',
    disbursementAmount: disbursement_amount
      ? `${currencyFormatter(disbursement_amount)} m.n.`
      : '-',
  };
};

const useQueryPaymentSummary = () => {
  const { setIsLoaderOpen } = useLoaderContext();
  const {
    merchantReportPayments: {
      paymentSummary,
      updatePaymentSummaryFilter,
      initPaymentSummaryFilter,
    },
  } = useStore();

  const fetchSchedulePaymentSummary = async filters => {
    setIsLoaderOpen(true);
    const response =
      await MerchantPaymentsService().getSummaryPaymentSchedule(filters);

    setIsLoaderOpen(false);
    return {
      ...buildPaymentInfo(response),
      ...buildPaymentDetails(response.details),
    };
  };

  const queryResult = useQuery({
    queryKey: ['paymentSummary', paymentSummary],
    queryFn: async () => fetchSchedulePaymentSummary(paymentSummary),
    initialData: {
      paymentFrequency: '-',
      paymentDate: '-',
      disbursementSender: '-',
      disbursementRecipient: '-',
      nextPaymentDate: '-',
      paymentsTotal: '-',
      paymentsCommission: '-',
      paymentsSubtotal: '-',
      cancellations: 0,
      refundsTotal: '-',
      disbursementAmount: '-',
    },
    enabled: !!paymentSummary.paymentScheduleId,
  });

  const onInitFilters = () => {
    initPaymentSummaryFilter();
  };

  const onUpdateFilters = newFilters => {
    updatePaymentSummaryFilter(newFilters);
  };

  return {
    filters: paymentSummary,
    initFilters: onInitFilters,
    updateFilters: onUpdateFilters,
    queryResult: queryResult,
  };
};

export default useQueryPaymentSummary;
