import { Card, Divider, Space, Tag } from '../../ds';
import propTypes from 'prop-types';
import React from 'react';
import { isFeatureEnabled } from '../../Utils/Feature/feature_flag';
import { currencyFormatter } from '../../Utils/formats';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';

const PaymentIndicator = ({ title, value, transactionCount, type }) => {
  const coinFormattedValue = currencyFormatter(value);
  const commaSeparatedTransactionCount = transactionCount.toLocaleString();
  const typeClass =
    type === 'negative' ? ' tag-summary-negative' : ' tag-summary-positive';
  return (
    <>
      <Card type='secondary' className='k-ds-width-full'>
        <span className='k-ds-heading-04'>{title}</span>
        <Divider margin='spacing-06' className='k-ds-m-top-sp-03' />
        <Space direction='horizontal' justify='space-between' align='center'>
          <Space
            direction='vertical'
            justify='space-between'
            className='k-ds-width-full'
          >
            <div className='k-ds-heading-03'>Total en M.N.</div>
          </Space>
          <Tag type='default' className={'tag-summary' + typeClass}>
            {coinFormattedValue}
          </Tag>
        </Space>
        <Space
          direction='horizontal'
          justify='space-between'
          align='center'
          className='k-ds-m-top-sp-06'
        >
          <span className='k-ds-heading-03'>Número de transacciones</span>
          <Tag type='default' className='tag-summary'>
            {commaSeparatedTransactionCount}
          </Tag>
        </Space>
      </Card>
    </>
  );
};

PaymentIndicator.propTypes = {
  title: propTypes.string,
  value: propTypes.number,
  transactionCount: propTypes.number,
  tagColor: propTypes.string,
  type: propTypes.oneOf(['positive', 'negative']),
};

const PaymentSummary = ({ startDate, endDate, lastSync, summary }) => {
  const viewport = useDeviceContextViewport();
  return (
    isFeatureEnabled('payment_summary') && (
      <>
        <Space
          direction='horizontal'
          justify='space-between'
          align='center'
          className='k-ds-m-top-sp-08'
        >
          <div className='k-ds-heading-02'>
            Resumen del {startDate ? startDate : startDate}{' '}
            {endDate ? 'al ' + endDate : ''}
          </div>
          <div className='k-ds-caption-01'>
            Última actualización: {lastSync}
          </div>
        </Space>
        <Space
          direction={
            viewport.isMobile && !viewport.isSmallTablet
              ? 'vertical'
              : 'horizontal'
          }
          justify='space-between'
          className='payment-summary k-ds-m-top-sp-06'
        >
          <PaymentIndicator
            title='Ventas'
            value={summary.payments_total}
            transactionCount={summary.payments_count}
          />
          <PaymentIndicator
            title='Cancelaciones'
            value={summary.cancelations_total}
            transactionCount={summary.cancelations_count}
            type='negative'
          />
        </Space>
      </>
    )
  );
};

PaymentSummary.propTypes = {
  startDate: propTypes.string,
  endDate: propTypes.string,
  lastSync: propTypes.string,
  summary: propTypes.object,
};

export default PaymentSummary;
