import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Space, Button } from '../../../ds';
import PhoneInputOutline from '../../DS/PhoneInput/PhoneInputOutline';
import CodeInput from '../CodeInput';
import Collapsible from '../Collapsible/Collapsible';
import TransactionProgress from '../TransactionProgress/TransactionProgress';
import SuccessTransaction from '../SuccessTransaction/SuccessTransaction';
import './oneTimePasscodeVerification.scss';
import { useForm, useController } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import OneTimePasscodeVerificationSchema from './OneTimePasscodeVerificationSchema';
import { validateOffering, orderVerdict } from '../../../services/Application';
import Error from './../Error/Error';
import { goPage } from '../../../Utils/pages';
import { useHistory } from 'react-router-dom';
import { useGetMerchant } from '../../../Utils/useGetMerchant';
import { createConsumer } from '@rails/actioncable';
import { getStage } from '../../../Utils/environment';
import { isFeatureEnabled } from '../../../Utils/Feature/feature_flag';
import AnalyticsEvent, {
  AnalyticsEventDictionary,
} from '../../../services/analytics/AnalyticsEvent';

export const MAX_ONE_TIME_PASSCODE_LENGTH = 8;
export const OLD_MAX_ONE_TIME_PASSCODE_LENGTH = 6;

const PaymentChannelName = 'PaymentChannel';
const initialOTPToken = {
  phoneNumber: '',
  token: '',
};

const OneTimePasscodeVerification = ({
  orderId,
  paymentId,
  selectedStoreId,
  orderGeneratedService,
}) => {
  const history = useHistory();
  const [IsOpen, setIsOpen] = useState(false);
  const isOpenCollapsible = useRef(false);
  const [isOpenError, setIsOpenError] = useState(false);
  const isOtpOfflineV2Enabled = isFeatureEnabled('otp_offline_v2');
  const ONE_TIME_PASSCODE_LENGTH = isOtpOfflineV2Enabled
    ? MAX_ONE_TIME_PASSCODE_LENGTH
    : OLD_MAX_ONE_TIME_PASSCODE_LENGTH;

  const [errorTitle, setErrorTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [hasVerdict, setHasVerdict] = useState(false);

  const [successTransaction, setSuccessTransaction] = useState(false);
  const merchant = useGetMerchant();

  useEffect(() => {
    const { status } = orderGeneratedService;
    if (status && status !== 'PENDIENTE') {
      setHasVerdict(true);
      setErrorTitle('Transacción procesada');
      setErrorMessage('Esta transacción ya fue procesada.');
      setIsOpenError(true);
    }
  }, [orderGeneratedService]);

  useEffect(() => {
    if (!paymentId) return;
    const stage = getStage();
    let host = process.env.REACT_APP_API_PRODUCTION;
    if (stage === 'development') {
      host = process.env.REACT_APP_API_STAGING;
    }
    host = `${host}/api/payment_channel`;

    const cable = createConsumer(host);
    const channel = cable.subscriptions.create(
      {
        channel: PaymentChannelName,
        payment_id: paymentId,
      },
      {
        connected: () => {
          console.log('Connected to the channel');
        },
        disconnected: () => {
          console.log('Disconnected from the channel');
        },
        rejected: () => {
          console.log('Rejected from the channel');
        },
        received(data) {
          console.log('Received data');
          onVerdict(data.verdict);
        },
      }
    );

    return () => {
      cable.subscriptions.remove(channel);
      cable.disconnect();
    };
  }, [paymentId]);

  const {
    handleSubmit,
    control,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: initialOTPToken,
    reValidateMode: 'onChange',
    mode: 'all',
    resolver: zodResolver(OneTimePasscodeVerificationSchema),
  });

  const onVerdict = verdict => {
    setHasVerdict(true);
    switch (verdict) {
      case 'approved':
        setIsOpen(false);
        setIsOpenError(false);
        setSuccessTransaction(true);
        break;
      case 'expired':
        setErrorTitle('Tiempo expirado');
        setErrorMessage(
          'La transacción ha excedido el tiempo límite permitido.'
        );
        setIsOpenError(true);
        break;
      case 'canceled':
        setErrorTitle('Transacción cancelada');
        setErrorMessage('El cliente canceló la compra desde su celular.');
        setIsOpenError(true);
        break;
      default:
        setErrorTitle('Transacción no aprobada');
        setErrorMessage(
          'El cliente debe consultar su cuenta de Kueski Pay para más detalles.'
        );
        setIsOpenError(true);
        break;
    }
  };

  const phoneController = useController({ name: 'phoneNumber', control });
  const tokeController = useController({ name: 'token', control });

  const onProcessOrder = async formData => {
    setIsOpen(true);

    AnalyticsEvent().inStore({
      eventId:
        AnalyticsEventDictionary.merchantPortalChargedQrOrderCreatedViewConfirmButtonClicked,
    });

    validateOffering({
      merchant_id: merchant.merchant_id,
      merchant_rfc: merchant.merchant_rfc,
      amount: Number(orderGeneratedService.total),
      payment_id: paymentId,
      phone_number: formData.phoneNumber,
      otp: formData.token,
    }).catch(error => {
      const errorCode = error.response?.data?.code ?? error.data?.code;
      AnalyticsEvent().errorTrack({
        errorType: 'one-time-passcode-validate-offering-error',
        errorDescription: errorCode,
      });
      AnalyticsEvent().inStoreError({
        error: errorCode,
        errorDescription: JSON.stringify(error.response || error),
      });

      switch (errorCode) {
        case '231003':
        case '231005':
          setErrorTitle('Datos incorrectos');
          setErrorMessage(
            'Los datos proporcionados no coinciden o son incorrectos. Pide al cliente que los verifique e ingresa los correctos.'
          );
          break;
        default:
          setErrorTitle('No pudimos completar la transacción');
          setErrorMessage(
            'Tuvimos problemas de comunicación con el servicio. Puedes solicitar al cliente que lo intente de nuevo.'
          );
          break;
      }
      setIsOpenError(true);
      setIsOpen(false);
    });
  };

  const cancelOrder = async () => {
    const { staffId } = orderGeneratedService;
    const data = {
      staff_id: staffId,
      store_id: selectedStoreId,
      verdict: 'reject',
    };

    orderVerdict(orderId, data)
      .then(() => {
        goPage('/orders/create-by-qr', history);
      })
      .catch(error => {
        AnalyticsEvent().errorTrack({
          errorType: 'one-time-passcode-order-veredict-error',
          errorDescription: error,
        });
        console.error(error.response);
      })
      .finally(() => {
        setIsOpen(false);
      });
  };

  const closeModal = () => {
    goPage('/orders/create-by-qr', history);
    setIsOpen(false);
  };

  const Title = () => (
    <div className='one-time-passcode-title'>
      <span className='k-ds-heading-02'>
        Ingresar número de celular y código de verificación.
      </span>
    </div>
  );

  const handleOnOpenCollapsible = isOpen => {
    const phoneInput = document.getElementById('phoneNumber');
    isOpenCollapsible.current = isOpen;
    isOpen && phoneInput ? phoneInput.focus() : null;
  };

  return (
    <>
      <Card className='k-ds-width-full k-ds-m-top-sp-06 one-time-passcode-verification'>
        <div className='k-ds-subheading-03 k-ds-m-top-sp-03'>
          Si el cliente no tiene conexión a internet, te mostrará un código de
          verificación para validar la transacción.
        </div>
        <div className='k-ds-m-top-sp-06'>
          <Collapsible onOpen={handleOnOpenCollapsible} title={<Title />}>
            <form>
              <PhoneInputOutline
                id='phoneNumber'
                className='k-ds-m-bottom-sp-06'
                helper='Solo números.'
                label='Número de celular'
                placeholder='Ingresa 10 dígitos'
                onChange={value => phoneController.field.onChange(value)}
                onBlur={() => {
                  trigger('phoneNumber');
                }}
                type='outline'
                errorMessage={
                  errors && errors.phoneNumber ? errors.phoneNumber.message : ''
                }
                required
                autoFocus
              />
              <div className={'opt-code-input'}>
                <Space
                  size='small'
                  justify='space-between'
                  className='k-ds-width-full'
                  direction='horizontal'
                >
                  <label className='k-ds-body-01 k-ds-text-color-primary'>
                    Código de verificación{' '}
                    <span className='k-ds-text-color-error'>{' *'}</span>
                  </label>
                </Space>
                <CodeInput
                  justify='space-between'
                  length={ONE_TIME_PASSCODE_LENGTH}
                  onChangeCode={value => {
                    isOpenCollapsible.current &&
                    value &&
                    value.length === ONE_TIME_PASSCODE_LENGTH
                      ? tokeController.field.onChange(value)
                      : null;
                  }}
                  onBlurForm={value => {
                    isOpenCollapsible.current &&
                    value &&
                    value.length < ONE_TIME_PASSCODE_LENGTH
                      ? tokeController.field.onChange(value)
                      : null;
                  }}
                  hasError={Boolean(
                    errors &&
                      errors.token &&
                      Object.keys(errors.token).length > 0
                  )}
                  validCode={false}
                />
                <div>
                  {errors && errors.token && errors.token.message ? (
                    <span className='k-ds-text-color-error k-ds-label-01 '>
                      {errors.token.message}
                    </span>
                  ) : (
                    <span className='k-ds-text-color-grey-700 k-ds-label-01 '>
                      {isOtpOfflineV2Enabled
                        ? 'Solo números (0-9)'
                        : 'Solo letras (A-Z) y números (0-9)'}
                    </span>
                  )}
                </div>
              </div>
              <Button
                type='primary-alternate'
                className='k-ds-m-top-sp-07 confirm'
                horizontalPadding='22px'
                verticalPadding='16px'
                size='small'
                onClick={handleSubmit(onProcessOrder)}
                disabled={!isValid}
              >
                <span style={{ width: '180px' }}> Confirmar </span>
              </Button>
            </form>
          </Collapsible>
        </div>
      </Card>
      <TransactionProgress
        isOpen={IsOpen}
        onClose={() => setIsOpen(false)}
        header='Transacción en curso'
        content='Espera un momento'
      />
      <SuccessTransaction
        isOpen={successTransaction}
        onClose={() => closeModal()}
        totalFormatted={orderGeneratedService.totalFormatted}
        itemsCount={orderGeneratedService.items_count}
        orderId={orderId}
        selectedStoreId={selectedStoreId}
        paymentId={paymentId}
      />
      <Error
        isOpen={isOpenError}
        onClose={() => {
          if (!hasVerdict) {
            cancelOrder();
            return;
          }
          goPage('/orders/create-by-qr', history);
        }}
        title={errorTitle}
        content={errorMessage}
        textButton={hasVerdict ? 'Salir' : 'Volver a intentar'}
        callback={() => {
          if (hasVerdict) {
            goPage('/orders/create-by-qr', history);
            return;
          }
          setIsOpenError(false);
        }}
      />
    </>
  );
};

OneTimePasscodeVerification.propTypes = {
  orderGeneratedService: PropTypes.object,
  orderId: PropTypes.string.isRequired,
  selectedStoreId: PropTypes.string.isRequired,
  paymentId: PropTypes.string.isRequired,
};

export default OneTimePasscodeVerification;
