import styled from 'styled-components';
import { ButtonDefaults } from './ButtonDefaults';
import KDS from '../../lib/k-ds-foundation';

const ButtonSecondary = styled(ButtonDefaults)`
  ${KDS.setColorTokenWithFallback('background: ', 'background-alternate')}
  ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}

  &:enabled {
    &:hover {
      ${KDS.setBoxShadowTokenWithFallback('shadow-button-hover')}
    }

    &:active:not(:focus) {
      ${KDS.setColorTokenWithFallback('border: 1px solid ', 'interactive-alternate')}
      box-shadow: none;
    }

    border: 2px solid transparent;

    &:focus-visible {
      ${KDS.setColorTokenWithFallback('border: 2px solid ', 'inverse-background')}
    }
  }

  &:disabled {
    ${KDS.setColorTokenWithFallback('color: ', 'text-placeholder')}
  }
`;

export {
  ButtonSecondary,
};
