import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { useDeviceContextViewport } from '../../../contexts/Device/DeviceContext';

export const HeaderBoxInfo = ({ title, subTitle }) => (
  <div className={styles.headerBoxInfo}>
    <div className='k-ds-heading-03'>{title}</div>
    <div className='k-ds-body-02'>{subTitle}</div>
  </div>
);

HeaderBoxInfo.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.any.isRequired,
};

export const RowBoxInfo = ({
  text,
  content,
  color = '',
  alternative = false,
}) => {
  const viewport = useDeviceContextViewport();

  const isMobileOnly = useMemo(
    () => viewport.isMobile && !viewport.isSmallTablet,
    [viewport]
  );

  if (isMobileOnly) {
    return (
      <div
        className={`${styles.rowBox} ${color ? styles[color] : ''} ${alternative ? styles.alternative : ''}`}
      >
        <div className={`k-ds-body-02 ${styles.text}`}>{text}</div>
        <div
          className={`${alternative ? '' : styles.rowContent}  k-ds-heading-02 `}
        >
          {content}
        </div>
      </div>
    );
  }

  return (
    <tr
      className={`${styles.rowBox} ${color ? styles[color] : ''} ${alternative ? styles.alternative : ''}`}
    >
      <td className={`k-ds-body-02 ${styles.text}`}>{text}</td>
      <td
        className={`${alternative ? '' : styles.rowContent}  k-ds-heading-02 `}
      >
        {content}
      </td>
    </tr>
  );
};

RowBoxInfo.propTypes = {
  text: PropTypes.any.isRequired,
  content: PropTypes.string.isRequired,
  color: PropTypes.string,
  alternative: PropTypes.bool,
};

const BoxInfo = ({ color, header, children }) => {
  return (
    <div className={`${styles.box} ${styles[color]}`}>
      {header ? (
        <>
          <HeaderBoxInfo
            title={header.title ? header.title : ''}
            subTitle={header.subTitle ? header.subTitle : ''}
          />
          <div className={styles.divider} />
        </>
      ) : null}
      <table className={styles.table}>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

BoxInfo.propTypes = {
  color: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  header: PropTypes.shape({
    title: PropTypes.string,
    subTitle: PropTypes.any,
  }),
};

export default BoxInfo;
