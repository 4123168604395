import React from 'react';
import { PaginationB2B } from './PaginationB2B';
import { PaginationPortal } from './PaginationPortal';

const MAP_PAGINATION_TYPE = {
  'portal': PaginationPortal,
  'b2b': PaginationB2B,
};

const Pagination = ({ type, ...moreProps }) => {
  const defaultPaginationType = 'b2b';

  // eslint-disable-next-line
  const PaginationComponent = MAP_PAGINATION_TYPE[type] || MAP_PAGINATION_TYPE[defaultPaginationType];

  return (<PaginationComponent {...moreProps} />);
};

export {
  Pagination
};

