import styled, { css } from 'styled-components';
import KDS from '../../../../ds/lib/k-ds-foundation';
import { Button, DateInput } from '../../../../ds';

export const StyledSelectOutline = styled.div`
  position: relative;

  .arrow-icon {
    fill: ${KDS.getColorToken('interactive-alternate')};
  }

  .selector {
    ${KDS.setColorTokenWithFallback(
  'border: 1px solid ',
  'interactive-alternate'
)}

    border-radius: 8px;
    box-sizing: border-box;
    padding: 14px;
    cursor: pointer;
    ${KDS.setColorTokenWithFallback('background-color: ', 'white')}
    ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}

    &.selected {
      ${KDS.setColorTokenWithFallback(
  'border: 1px solid ',
  'interactive-primary'
)}

      svg {
        ${KDS.setColorTokenWithFallback('fill: ', 'interactive-primary')}
      }
    }

    .control {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;

      span {
        line-height: 22.4px;
      }
    }

    .show {
      transform: rotateX(180deg);
    }

    ${props =>
    props.disabled &&
    css`
        cursor: not-allowed;

        ${KDS.setColorTokenWithFallback('background-color: ', 'grey-100')}
        ${KDS.setColorTokenWithFallback('border: 1px solid ', 'grey-300')}
      ${KDS.setColorTokenWithFallback('color: ', 'text-placeholder')}

      svg path {
          ${KDS.setColorTokenWithFallback('stroke: ', 'grey-500')}
        }
      `}

    ${props =>
    props.showPlaceholder &&
    css`
        ${KDS.setColorTokenWithFallback('color: ', 'text-secondary')}
      `}

    ${props =>
    props.active &&
    css`
        ${KDS.setColorTokenWithFallback(
      'border: 1px solid',
      'interactive-primary'
    )}
        .arrow-icon {
          fill: ${KDS.getColorToken('interactive-primary')};
        }
      `}
    }
  }

  .options-group {
    position: absolute;
    padding: 24px;
    z-index: 1;
    display: none;
    transform: translate(0, ${KDS.getSpacing('spacing-01')});
    transform: translate(0, var(--k-ds-spacing-01));
    overflow-y: auto;
    max-height: 250px;
    width: fit-content;

    ${KDS.setColorWithFallback('background: ', 'white')}

    ${KDS.setColorTokenWithFallback(
      'border: 1px solid ',
      'interactive-alternate'
    )}
    border-radius: 8px;
    box-sizing: border-box;

    ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}

    &.show {
      display: block;
    }
  }

  .option {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: fit-content;

    label {
      &:hover {
        cursor: pointer;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .helper-error {
    position: absolute;
    top: 3.5rem;
    left: 0;
    right: 0;

    ${KDS.setColorTokenWithFallback('color: ', 'text-placeholder')}

    ${props =>
    props.hasError &&
    css`
        ${KDS.setColorTokenWithFallback('color: ', 'error')}
      `}
  }
`;

export const HiddenDivInput = styled.input`
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  top: 50%;
  left: 50%;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  height: 34px;
  max-width: 100px;
`;

export const StyledDateInput = styled(DateInput)`
  width: 186px;
`;
