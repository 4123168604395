import { Button, Modal, Space } from '../../ds';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import './addStoreSelectionModal.scss';
import { ReactComponent as CloseIcon } from './close-icon.svg';
import FileStep from './FileStep';
import SelectionStep from './SelectionStep';

function AddStoreSelectionModal(props) {
  const { close, isOpen } = props;
  const [firstStep, setFirstStep] = useState(true);
  const CurrentStepContent = () => {
    if (firstStep)
      return (
        <SelectionStep
          onClick={advanceStep}
          onClose={handleClose}
          downloadTemplate={downloadTemplate}
        />
      );
    return (
      <FileStep onClose={handleClose} downloadTemplate={downloadTemplate} />
    );
  };

  return (
    isOpen && (
      <Modal
        className={'store-selection-modal'}
        theme='dark'
        cardType='secondary'
        onClose={handleClose}
        onOverlayClick={handleClose}
        {...{ isOpen: isOpen }}
      >
        <div className='k-ds-width-full'>
          <Space
            direction='horizontal'
            justify='end'
            className='k-ds-width-full'
          >
            <Button type='link' size='small' onClick={handleClose}>
              <CloseIcon />
            </Button>
          </Space>
          <CurrentStepContent />
        </div>
      </Modal>
    )
  );

  function handleClose() {
    setFirstStep(true);
    close();
  }

  function advanceStep() {
    setFirstStep(false);
  }

  function downloadTemplate() {
    const link = document.createElement('a');
    link.href = 'stores-template.xlsx';
    link.setAttribute('download', 'stores.xlsx');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }
}

AddStoreSelectionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default AddStoreSelectionModal;
