import PropTypes from 'prop-types';
import React, { useContext, useLayoutEffect, useState } from 'react';
import Device, { getDeviceInformation } from '../../Utils/Device/Device';

const DeviceContext = React.createContext(Device.defaults);

const useDeviceContext = () => {
  const context = useContext(DeviceContext);
  return context;
};

const useDeviceContextViewport = () => {
  const context = useContext(DeviceContext);
  return context.viewport;
};

const DeviceContextProvider = ({ children }) => {
  const [viewport, setViewport] = useState(Device.defaults);

  useLayoutEffect(() => {
    const updateViewportValues = () => {
      const viewportResized = getDeviceInformation({
        width: window.innerWidth,
      });
      setViewport(viewportResized);
    };
    window.addEventListener('resize', updateViewportValues);
    updateViewportValues();
    return () => window.removeEventListener('resize', updateViewportValues);
  }, []);

  return (
    <DeviceContext.Provider value={{ viewport }}>
      {children}
    </DeviceContext.Provider>
  );
};

DeviceContextProvider.propTypes = {
  children: PropTypes.any,
};

export default DeviceContext;
export { useDeviceContext, useDeviceContextViewport, DeviceContextProvider };
