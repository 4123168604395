import React from 'react';
import { SelectOutline } from './SelectOutline';
import { SelectLine } from './SelectLine';

const MAP_SELECT_TYPE = {
  'outline': SelectOutline,
  'line': SelectLine,
};

const Select = ({ type, ...moreProps }) => {
  const defaultSelectType = 'outline';
  const SelectComponent = MAP_SELECT_TYPE[type] || MAP_SELECT_TYPE[defaultSelectType];

  return (<SelectComponent {...moreProps} />);
};

export {
  Select,
};
