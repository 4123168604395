import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDeviceContextViewport } from '../../../contexts/Device/DeviceContext';
import { Modal, Space, Card, Button } from '../../../ds';
import IconSuccess from './icon-success.svg';
import { goPage } from '../../../Utils/pages';
import { useHistory } from 'react-router-dom';
import './successTransaction.scss';
import { hyphenEveryFourChars } from '../../../Utils/formats';

const SuccessTransaction = ({
  totalFormatted,
  itemsCount,
  orderId,
  selectedStoreId,
  isOpen,
  onClose = () => null,
  paymentId,
}) => {
  const viewport = useDeviceContextViewport();
  const history = useHistory();

  const isMobileOnly = useMemo(
    () => viewport.isMobile && !viewport.isSmallTablet,
    [viewport]
  );

  return (
    <Modal
      showClose
      className={cn('success-transaction', {
        ['mobile-modal']: isMobileOnly,
      })}
      theme='dark'
      cardType='secondary'
      onClose={onClose}
      onOverlayClick={onClose}
      {...{ isOpen: isOpen }}
    >
      <div className='k-ds-width-full'>
        <Space justify='center' direction='vertical' align='center'>
          <img width={80} height={80} src={IconSuccess} alt='loading...' />
        </Space>
        <Space className='k-ds-heading-05 k-ds-m-top-sp-06' justify='center'>
          Transacción exitosa
        </Space>
        <Space className='k-ds-heading-03 k-ds-m-top-sp-02' justify='center'>
          Ya puedes entregar los productos
        </Space>
        <Space className='k-ds-m-top-sp-07' justify='center'>
          <Card className='success-transaction-summary'>
            <Space justify='center'>
              <div>
                <div className='success-transaction-summary-row'>
                  <div className='k-ds-subheading-01'>ID de pago:</div>
                  <div className='k-ds-heading-01'>
                    {hyphenEveryFourChars(paymentId)}
                  </div>
                </div>
                <div className='success-transaction-summary-row'>
                  <div className='k-ds-subheading-01'>Nº de productos:</div>
                  <div className='k-ds-heading-01'>{itemsCount}</div>
                </div>
                <div className='success-transaction-summary-row'>
                  <div className='k-ds-heading-01'>Monto total:</div>
                  <div className='k-ds-heading-01'>{`$${totalFormatted} m.n.`}</div>
                </div>
              </div>
            </Space>
          </Card>
        </Space>
        <Space className='k-ds-m-top-sp-03' justify='center'>
          <Button
            type='primary-alternate'
            className='k-ds-m-top-sp-05'
            horizontalPadding='34.4px'
            verticalPadding='16px'
            size='small'
            onClick={() =>
              goPage(`/orders/${orderId}/${selectedStoreId}`, history)
            }
          >
            Ver detalles
          </Button>
        </Space>
      </div>
    </Modal>
  );
};

SuccessTransaction.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  orderId: PropTypes.string,
  selectedStoreId: PropTypes.string,
  totalFormatted: PropTypes.string,
  itemsCount: PropTypes.number,
  paymentId: PropTypes.string,
};

export default SuccessTransaction;
