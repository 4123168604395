import PropTypes from 'prop-types';
import React from 'react';
import styles from './Home.module.scss';
import { isFeatureEnabled } from '../../../Utils/Feature/feature_flag';

const CardVideo = ({ path, title, description, onClick }) => {
  const SHOW_DESCRIPTION_VIDEO = isFeatureEnabled(
    'home_page_description_videos'
  );

  return (
    <div>
      <div className={styles.videoCard} onClick={onClick}>
        <img className={styles.videoImage} src={path} />
        <div className={styles.iconPlay}>
          <svg
            width='60'
            height='60'
            viewBox='0 0 60 60'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M43.12 2H16.88C7.73333 2 2 8.47383 2 17.6384V42.3616C2 51.5262 7.70667 58 16.88 58H43.12C52.2933 58 58 51.5262 58 42.3616V17.6384C58 8.47383 52.2933 2 43.12 2Z'
              fill='#28FF81'
              fillOpacity='0.9'
              stroke='white'
              strokeWidth='3'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <g clipPath='url(#clip0_2051_2357)'>
              <path
                d='M44.752 30.8316L23.554 44.9636C23.4034 45.0639 23.2285 45.1213 23.0478 45.13C22.8671 45.1386 22.6875 45.0981 22.528 45.0127C22.3685 44.9274 22.2352 44.8003 22.1422 44.6452C22.0492 44.49 22.0001 44.3125 22 44.1316V15.8676C22.0001 15.6868 22.0492 15.5093 22.1422 15.3541C22.2352 15.199 22.3685 15.0719 22.528 14.9866C22.6875 14.9012 22.8671 14.8607 23.0478 14.8693C23.2285 14.8779 23.4034 14.9354 23.554 15.0356L44.752 29.1676C44.889 29.259 45.0013 29.3827 45.0789 29.5278C45.1566 29.673 45.1972 29.835 45.1972 29.9996C45.1972 30.1643 45.1566 30.3263 45.0789 30.4715C45.0013 30.6166 44.889 30.7403 44.752 30.8316Z'
                fill='white'
              />
            </g>
            <defs>
              <clipPath id='clip0_2051_2357'>
                <rect
                  width='48'
                  height='48'
                  fill='white'
                  transform='translate(6 6)'
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <div className={styles.videoCardDetailContainer}>
        <div className={styles.videoCardTitle}>{title}</div>
        {SHOW_DESCRIPTION_VIDEO ? (
          <div className={styles.videoCardDescription}>{description}</div>
        ) : null}
      </div>
    </div>
  );
};

export default CardVideo;

CardVideo.propTypes = {
  path: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};
