import React from 'react';
import styled, { css } from 'styled-components';
import KDS from '../../lib/k-ds-foundation';

/* eslint-disable */
const StyledTableB2B = styled.table`
marging-top: 12px;
  border-collapse: collapse;

  /* k-ds-label-01 */
  ${KDS.setTypographyWithFallback({
  'font-family': 'font-secondary',
  'font-weight': 'font-medium',
  'font-size': 'text-xs',
  'letter-spacing': 'tracking-wide',
  'line-height': 'relaxed',
})}

  ${KDS.setColorTokenWithFallback('color: ', 'text-primary')};
  text-align: left;
  vertical-align: middle;

  
  & > thead {
    position: sticky;
    top: -48px; 
    z-index: 1;
    @media (min-width: 1024px) and (max-width: 1279px) {
      top: -48px;
    }

    ${(props) => props.scrollingX && css`
    @media (min-width: 1280px) and (max-width: 1440px) {
      top: 0;
    }

    `}

    
    ${KDS.setColorTokenWithFallback('background: ', 'background-primary')};
  }

  & > thead > tr {
    ${KDS.setColorTokenWithFallback('background: ', 'background-primary')};

    & > th {
      max-width: 8rem;

      ${KDS.setTypographyWithFallback({
  'font-family': 'font-primary',
  'font-weight': 'font-bold',
  'font-size': 'text-sm',
  'line-height': 'snug',
})}

      &:not(:first-child) {
        z-index: 0;
        ${KDS.setSpacingWithFallback('padding: ', 'spacing-05', 'spacing-05')};
      }
      &:first-child {
        left: 0;
        // top: 0;
        z-index: 1;

        position: sticky;
        background: inherit;
        ${KDS.setSpacingWithFallback('padding: ', 'spacing-03', 'spacing-05', 'spacing-03', 'spacing-07')};
      }

      &:last-child {
        ${KDS.setSpacingWithFallback('padding: ', 'spacing-03', 'spacing-07', 'spacing-03', 'spacing-05')};
      }
    }
  }


  & tbody tr { 
    background: white;
    
    & > td:first-child {
      position: sticky;
      background: inherit;
      left: 0;
      z-index: 0;
    }
  }
  

  & > tbody > tr {
    border-bottom: 0.5px solid #B4BACA;

    & > td {
      ${(props) => props.paddingSize && css`
        &:not(:first-child) {
          ${KDS.setSpacingWithFallback('padding: ', props.paddingSize, 'spacing-04', props.paddingSize, 'spacing-04')};
        }
        &:first-child {
          ${KDS.setSpacingWithFallback('padding: ', props.paddingSize, 'spacing-04', props.paddingSize, 'spacing-06')};
        }
        &:last-child {
          ${KDS.setSpacingWithFallback('padding: ', props.paddingSize, 'spacing-06', props.paddingSize, 'spacing-05')};
        }
      `}

      ${(props) => props.scrollingX && css`
      @media (min-width: 1280px) and (max-width: 1440px) {
        white-space: nowrap;

      }
      `}

      & > a {
        ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary')};
        text-decoration: none;
      }
    }

    &:hover {
      background: #ebeef7;
    }
  }
`;
/* eslint-enable */

const TableB2B = ({ className, children, ...moreProps }) => (
  <StyledTableB2B className={className} {...moreProps}>
    {children}
  </StyledTableB2B>
);

export {
  TableB2B
};

