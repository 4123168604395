import camelCase from 'camelcase-keys';
import snakeCase from 'snakecase-keys';
import { bankCodeNames } from './constants';

export function keysToCamelCase(input) {
  return camelCase(input, { deep: true });
}

export function keysToSnakeCase(input, deep = true, exclude) {
  if (exclude) {
    return snakeCase(input, { deep, exclude });
  }

  return snakeCase(input, { deep });
}

function autoCompleteDecimalNumber(value, decimalNumbers) {
  if (!value) return '';

  const indexOfPoint = value.indexOf('.');
  if (indexOfPoint === -1) {
    return `${value}.${'0'.repeat(decimalNumbers)}`;
  }
  const currentDecimals = value.length - 1 - indexOfPoint;
  return `${value}${'0'.repeat(decimalNumbers - currentDecimals)}`;
}

function splitStringEveryCharAndSize(str, size, char) {
  const regex = new RegExp(`.{1,${size}}`, 'g');
  return str.match(regex)?.join(char) || '';
}

function hyphenEveryFourChars(str) {
  return splitStringEveryCharAndSize(str, 4, '-');
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function getUserInitials(firstName, lastName) {
  if (!firstName) return '';
  return lastName ? firstName[0] + lastName[0] : firstName[0];
}

const twoDigitOptions = {
  day: '2-digit',
  month: '2-digit',
  year: '2-digit',
};

function formatDate(input) {
  let date = new Date(input);
  return (
    date.toLocaleDateString('es-MX', twoDigitOptions) +
    ' - ' +
    date.toLocaleTimeString()
  );
}

function getDateString(dateString) {
  if (!dateString) return '';
  const date = new Date(dateString);
  const dateUtc = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

  return dateUtc.toLocaleDateString('es-MX', twoDigitOptions);
}

function currencyFormatter(value, locale = 'es-MX', currency = 'MXN') {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
  }).format(value);
}

const formatDateForInput = date => {
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().split('T')[0];
};

const getDateTime = currentDate => {
  let hours = currentDate.getHours();
  hours = hours >= 10 ? hours : `0${hours}`;
  let mins = currentDate.getMinutes();
  mins = mins >= 10 ? mins : `0${mins}`;
  return (
    currentDate.getDate() +
    '/' +
    (currentDate.getMonth() + 1) +
    '/' +
    currentDate.getFullYear() +
    ' - ' +
    hours +
    ':' +
    mins
  );
};

const toUtc = dateString => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

const secondsToHms = sec_num => {
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;
  var label = '';

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  if (seconds !== '00' && seconds !== '01') {
    label = 'segundos';
  }
  if (seconds === '01') {
    label = 'segundo';
  }
  if (minutes !== '00' && minutes !== '01') {
    label = 'minutos';
  }
  if (minutes === '01') {
    label = 'minuto';
  }
  if (hours !== '00' && hours !== '01') {
    label = 'horas';
  }
  if (hours === '01') {
    label = 'hora';
  }

  return hours + ':' + minutes + ':' + seconds + ' ' + label;
};

const getBankName = code => {
  if (!code) return '';

  return bankCodeNames[code] || '-';
};

const toPercentage = number => {
  return `${Number(number) * 100}%`;
};

export {
  hyphenEveryFourChars,
  splitStringEveryCharAndSize,
  autoCompleteDecimalNumber,
  numberWithCommas,
  getUserInitials,
  formatDate,
  getDateString,
  currencyFormatter,
  getDateTime,
  formatDateForInput,
  secondsToHms,
  toUtc,
  getBankName,
  toPercentage,
};
