import React from 'react';
import PropTypes from 'prop-types';
import { Button, Space, Card, Tag } from '../../ds';
import { Modal } from '../../ds';
import { ReactComponent as ArrowRight } from './ArrowRight.svg';
import { ReactComponent as CloseIcon } from './close-icon.svg';
import { useHistory } from 'react-router-dom';
import './selectionModal.scss';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
function SelectionModal(props) {
  const viewport = useDeviceContextViewport();
  let history = useHistory();
  const goUpload = type => {
    history.push('/logos/upload/' + type);
    const contentArea = document.getElementById('content-area');
    contentArea.scrollTop = 0;
  };

  return props.defaultOpened ? (
    <Modal
      className={
        viewport.isMobile && !viewport.isSmallTablet
          ? 'selection-modal k-ds-width-full k-ds-height-full'
          : 'selection-modal'
      }
      theme='dark'
      cardType='secondary'
      onClose={() => props.close()}
      onOverlayClick={() => props.close()}
      {...{ isOpen: props.defaultOpened ? true : false }}
    >
      <div className='k-ds-width-full'>
        <Space
          direction='horizontal'
          justify='end'
          className='k-ds-m-bottom-sp-02 k-ds-width-full'
        >
          <Button type='link' size='small' onClick={() => props.close()}>
            <CloseIcon />
          </Button>
        </Space>
        <Space
          direction='vertical'
          className='k-ds-m-top-sp-03 selection-container'
        >
          <div className='k-ds-m-left-sp-04 k-ds-m-bottom-sp-02 k-ds-subheading-03'>
            Elige una de las siguientes opciones:
          </div>
          <Tag color='orange' className='recommendation-tag'>
            ¡RECOMENDADO!
          </Tag>
          <Card shadow className='logo-card' onClick={() => goUpload('vector')}>
            <Space
              direction={
                viewport.isMobile && !viewport.isSmallTablet
                  ? 'vertical'
                  : 'horizontal'
              }
            >
              <Space
                direction='vertical'
                className='k-ds-m-vertical-sp-02 k-ds-m-vertical-sp-03'
              >
                <Space
                  direction='horizontal'
                  align='center'
                  className='k-ds-m-bottom-sp-04'
                >
                  <div className='k-ds-heading-03 card-text'>
                    Mi logotipo es un vector
                  </div>
                </Space>
                <div className='k-ds-m-right-sp-04'>
                  Elige esta opción si tu logotipo está vectorizado desde un
                  software de diseño gráfico y se puede exportar en .SVG.
                </div>
              </Space>
              <Space
                justify='space-around'
                direction='vertical'
                className='button-container k-ds-width-full'
              >
                <Button className='go-to-button' type='link' size='small'>
                  <div>
                    Seleccionar{' '}
                    <ArrowRight className='k-ds-m-left-sp-02 k-ds-p-top-sp-02' />
                  </div>
                </Button>
              </Space>
            </Space>
          </Card>
          <Card
            shadow
            className='logo-card selection-card'
            onClick={() => goUpload('image')}
          >
            <Space
              direction={
                viewport.isMobile && !viewport.isSmallTablet
                  ? 'vertical'
                  : 'horizontal'
              }
            >
              <Space
                direction='vertical'
                className='k-ds-m-vertical-sp-02 k-ds-m-vertical-sp-03'
              >
                <Space
                  direction='horizontal'
                  align='center'
                  className='k-ds-m-bottom-sp-04'
                >
                  <div className='k-ds-heading-03 card-text'>
                    Mi logotipo es una imágen
                  </div>
                </Space>
                <div className='k-ds-m-right-sp-04'>
                  Elige esta opción si tu logotipo es una imagen en formato .JPG
                  o .PNG. Procura que sea una imagen en alta calidad.
                </div>
              </Space>
              <Space
                justify='space-around'
                direction='vertical'
                className='button-container k-ds-width-full'
              >
                <Button className='go-to-button' type='link' size='small'>
                  <div>
                    Seleccionar{' '}
                    <ArrowRight className='k-ds-m-left-sp-02 k-ds-p-top-sp-02' />
                  </div>
                </Button>
              </Space>
            </Space>
          </Card>
        </Space>
      </div>
    </Modal>
  ) : null;
}

SelectionModal.propTypes = {
  defaultOpened: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default SelectionModal;
