import React, { useEffect, useLayoutEffect, useState } from 'react';
import Auth from '../../Utils/Auth/auth';
import initLogBehavior from '../../services/LogBehavior/LogBehavior';
import { Card } from '../../ds';
import Amount from './Amount/Amount';
import InfoCard from './InfoCard';
import SelectStores from './SelectStores';

import './payOrder.scss';
import InStoreFlowSelector from './InStoreFlowSelector';
import { useStore } from '../../services/store';
import { observer } from 'mobx-react-lite';

const initialPaymentData = {
  isModalOpen: false,
  paymentId: '',
  orderId: '',
  errors: '',
  isValid: false,
  amount: 0,
  tokenErrors: '',
  path: '',
  token: '',
};

const PayOrderByQRv2 = () => {
  const user = Auth.userData();
  if (!user) {
    return '';
  }

  const { qrCodeStore: { storeId, setStoreId, stores, selectedStore}} = useStore();
  const userLogBehavior = React.useRef('');
  const [location, setLocation] = useState({});
  const [paymentData, setPaymentData] = useState(initialPaymentData);
  const [paymentMode, setPaymentMode] = useState('');

  useLayoutEffect(() => {
    initLogBehavior(userLogBehavior);
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          setLocation({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          });
        },
        error => {
          console.error('Error getting location:', error.message);
        }
      );
    }
  }, []);

  const resetPaymentData = () => {
    setPaymentData(initialPaymentData);
  };

  return (
    <div className='button-actions-container'>
      <div className='k-ds-width-full pay-order'>
        <div className='pay-order-header k-ds-m-bottom-sp-07'>
          Cobrar por Código QR
        </div>
        <InfoCard />
        {stores.length > 0 && (
          <Card type='secondary' className='k-ds-width-full'>
            <div className='sub-header k-ds-m-bottom-sp-05'>Sucursal</div>
            <div>
              <SelectStores
                label='Selecciona una sucursal'
                placeholder='Elige una opción o escribe aquí'
                helper='El cobro se asignará a esta sucursal.'
                required
                name='sucursal'
                stores={stores}
                value={selectedStore}
                onChange={option => (option ? setStoreId(option.value) : null)}
              />
            </div>
          </Card>
        )}
        <InStoreFlowSelector onChange={mode => setPaymentMode(mode)} />
        <Amount
          storeid={storeId}
          location={location}
          logbehavior={userLogBehavior}
          paymentAmount={paymentData.amount}
          isPaymentValidated={paymentData.isValid}
          paymentMode={paymentMode}
          onClear={resetPaymentData}
        />
      </div>
    </div>
  );
};

export default observer(PayOrderByQRv2);
