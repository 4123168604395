import React from 'react';
import { ProvideAuth } from './Utils/Auth/use-auth';
import Routes from './containers/Routes';
import { DeviceContextProvider } from './contexts/Device/DeviceContext';
import { LoaderContextProvider } from './contexts/Loader/LoaderContext';
import { PermissionProvider } from './contexts/Role/PermissionContext';
import StoreContextProvider from './contexts/Store/StoreContext';
import { ToastContextProvider } from './contexts/Toast/ToastContext';
import { Settings } from 'luxon';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

Settings.defaultZone = 'America/Mexico_City';
Settings.defaultLocale = 'es-MX';

// buscar los scopes del usuario
// const scopes = [
//   'payment_logs',
//   'generate_orders',
//   'history_orders',
//   'campaigns',
// ];
// const configScopes = ['stores', 'users'];

const queryClient = new QueryClient();

function App() {
  // const { merchant } = Auth.userData();
  // const merchantChannels = merchant?.channels || [];
  // const { menus, routes } = myOwnRoutes(scopes, configScopes, merchantChannels);

  return (
    <QueryClientProvider client={queryClient}>
      <ProvideAuth>
        <StoreContextProvider>
          <PermissionProvider>
            <DeviceContextProvider>
              <LoaderContextProvider>
                <ToastContextProvider>
                  <Routes />
                </ToastContextProvider>
              </LoaderContextProvider>
            </DeviceContextProvider>
          </PermissionProvider>
        </StoreContextProvider>
      </ProvideAuth>
    </QueryClientProvider>
  );
}

export default App;
