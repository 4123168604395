import { Button, Modal, Space } from '../../ds';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { usePermissionContext } from '../../contexts/Role/PermissionContext';
import { useToastContext } from '../../contexts/Toast/ToastContext';
import { getMerchantsByTerm, getStoresLight } from '../../services/Application';
import { SearchBar } from '../DS/SearchBar';
import MerchantListing from './MerchantListing';
import styles from './MerchantModal.module.scss';
import { useStore } from '../../services/store';
import { useQueryClient } from '@tanstack/react-query';

const ModalMerchant = () => {
  const toast = useToastContext();
  const queryClient = useQueryClient();
  const { merchantTransactionsReport } = useStore();
  const { setOpenModalRoot } = usePermissionContext();
  const navigate = useHistory();

  const [merchantTerm, setMerchantTerm] = useState('');
  const [notFoundRst, setNotFoundRst] = useState(false);
  const [resultData, setResultData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [merchantSelected, setMerchantSelected] = useState(null);

  const handleSearch = () => {
    if (merchantTerm === '') {
      toast('Ingresa nombre del comercio o id', 'warning');
      return;
    }
    setNotFoundRst(false);
    setIsLoading(true);
    getMerchantsByTerm(merchantTerm)
      .then(response => {
        const { data, status } = response;
        if (status !== 'success') {
          toast('Hubo un error al solicitar la información', 'error');
          return;
        }

        if (data.merchants.length === 0) {
          setNotFoundRst(true);
          setResultData([]);

          return;
        }
        setResultData(data.merchants);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangeInput = value => {
    setMerchantTerm(value);
  };

  const handleClean = () => {
    setMerchantTerm('');
    setResultData([]);
    setMerchantSelected(null);
  };

  const handleChange = value => {
    setMerchantSelected(value);
  };

  const saveMerchantSelected = async () => {
    await merchantTransactionsReport.resetStore();
    queryClient.removeQueries();
    const existMerchantSelected = JSON.parse(
      window.localStorage.getItem('merchantSelected')
    );

    const merchantFind = resultData.find(
      item => item.merchant_id === merchantSelected
    );

    if (typeof merchantFind === 'undefined') {
      setMerchantSelected(null);
      toast('tuvimos un error vuelve a interlo', 'warning');
      return;
    }

    await getStoresLight(merchantFind.merchant_id).then(response => {
      localStorage.setItem('stores', JSON.stringify(response.data.stores));
    });

    localStorage.setItem('merchantSelected', JSON.stringify(merchantFind));
    setOpenModalRoot(false);

    if (!existMerchantSelected) {
      navigate.push({
        pathname: '/merchant-panel',
      });
    }
  };

  const handleClose = () => {
    const merchantSelected = JSON.parse(
      window.localStorage.getItem('merchantSelected')
    );

    if (!merchantSelected) {
      toast('Para continuar, selecciona un comercio.', 'warning');
      return;
    }

    setOpenModalRoot(false);
  };

  return (
    <div className={styles.container}>
      <Modal
        theme='dark'
        cardType='secondary'
        className={styles.customModal}
        {...{ isOpen: true }}
      >
        <Space className='k-ds-width-full' direction='vertical'>
          <div className={styles.headerText}>
            Busca el comercio que deseas gestionar
          </div>

          <div className={styles.subTitleHeader}>
            Si buscas por nombre de comercio, es necesario que no tenga espacios
            entre palabras.
          </div>
          <SearchBar
            placeholder='Nombre o id del comercio'
            className='search-bar'
            search={merchantTerm}
            setSearch={handleChangeInput}
            onSearch={handleSearch}
            clearSearch={handleClean}
            minSearch={3}
            isLoading={isLoading}
          />

          <MerchantListing
            data={resultData}
            handleChange={handleChange}
            merchantSelected={merchantSelected}
          />
          <div className={styles.results}>
            {notFoundRst ? (
              <span className={styles.notfound}>
                No se encontró ningún resultado con ese nombre. ¿Lo puedes
                volver a intentar?
              </span>
            ) : (
              `${resultData.length} resultados`
            )}
          </div>

          <div className={styles['custom-action-buttons']}>
            <Button
              type='primary-alternate'
              className='k-ds-m-rigth-sp-02'
              horizontalPadding='2.5rem'
              verticalPadding='1rem'
              size='small'
              onClick={saveMerchantSelected}
              disabled={merchantSelected === null || merchantSelected === ''}
            >
              Aceptar
            </Button>
            <Button type='simple' size='small' onClick={handleClose}>
              Cancelar
            </Button>
          </div>
        </Space>
      </Modal>
    </div>
  );
};

export default ModalMerchant;
