import styled from 'styled-components';
import { ButtonDefaults } from './ButtonDefaults';
import KDS from '../../lib/k-ds-foundation';

const ButtonLink = styled(ButtonDefaults)`
  ${KDS.setColorWithFallback('background: ', 'transparent')}
  ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary')}
  box-shadow: none;
  border: 0;

  &:enabled {
    &:hover {
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary-hover')}
      text-decoration: underline;
      ${KDS.setColorTokenWithFallback('text-decoration-color: ', 'interactive-primary-hover')}
    }

    &:active {
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary-hover')}
      text-decoration: underline;
      ${KDS.setColorTokenWithFallback('text-decoration-color: ', 'interactive-primary-hover')}
    }
  }

  &:disabled {
    ${KDS.setColorTokenWithFallback('color: ', 'interactive-alternate')}
  }
`;

export {
  ButtonLink,
};
