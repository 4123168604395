import Auth from '../Utils/Auth/auth';
import fetchHTTP from '../Utils/fetchHTTP';
import { getLocalStorage } from '../Utils/useGetMerchant';
import ApiResponses from './dummy_api_responses.json';

const saveLogbehavior = ({ userId, log_behavior_data }) => {
  return fetchHTTP('/api/protected/log', {
    method: 'POST',
    data: {
      user_id: userId,
      log_behavior: log_behavior_data,
    },
  });
};

const getCategories = () => {
  return fetchHTTP('/api/protected/stores/categories', {
    method: 'GET',
  });
};

const createOrder = order => {
  return fetchHTTP('/api/protected/orders', {
    method: 'POST',
    data: order,
  });
};

const getOrderGenerated = (orderId, storeId) => {
  return getOrderDetail(orderId, storeId);
};

const getOrderDetail = (orderId, storeId) => {
  return fetchHTTP(`/api/protected/orders/${orderId}?store_id=${storeId}`, {
    method: 'GET',
  });
};

const getOrders = (
  storeId,
  search,
  action,
  page = 1,
  limit = 20,
  filterParams = {}
) => {
  /* TODO Remove console.log and add filters to request getOrders */
  const filternoused = filterParams ? '' : '';
  const storeQuery = storeId ? `store_id=${storeId}` : '';
  const searchQuery = search ? `&search=${search}` : '';
  const storeStatusQuery = action ? `&order_action=${action}` : '';
  return fetchHTTP(
    `/api/protected/orders?${storeQuery}${storeStatusQuery}${searchQuery}&page=${page}&limit=${limit}${filternoused}`,
    {
      method: 'GET',
    }
  );
};

const orderVerdict = (orderId, data) => {
  return fetchHTTP(`/api/protected/orders/${orderId}`, {
    method: 'PATCH',
    data,
  });
};

const getStoresLight = merchantId => {
  return fetchHTTP('/api/protected/stores', {
    params: {
      disable_pagination: true,
      light_response: true,
      merchant_id: merchantId,
    },
    method: 'GET',
  });
};

const getStores = (
  search,
  page = 1,
  limit = 9,
  filterParams = {},
  getAllStores = false
) => {
  const storeName = search && search.length > 4 ? search : null;
  const skipPagination = getAllStores;
  return fetchHTTP('/api/protected/stores', {
    params: {
      store_name: storeName,
      page,
      limit,
      store_id: filterParams['storeId'],
      active: filterParams['active'],
      merchant_id: filterParams['merchant_id'],
      skip_pagination: skipPagination,
    },
    method: 'GET',
  });
};

const getStoreById = storeId => {
  return fetchHTTP(`/api/protected/stores/${storeId}`, {
    method: 'GET',
  });
};

const getStoreInsightsById = storeId => {
  return getStoreUsers({ storeId, page: 1, limit: 1, showUsersStore: false });
};

const getStoreUsers = ({
  storeId,
  page = 1,
  limit = 20,
  showUsersStore = true,
}) => {
  return fetchHTTP(
    `/api/protected/merchant_users?store_ids[]=${storeId}&page=${page}&limit=${limit}&show_users_without_stores=${showUsersStore}`,
    {
      method: 'GET',
    }
  );
};

const getMerchantUsers = (search, page = 1, limit = 9, filterParams = {}) => {
  /* TODO Remove console.log and add filters to request getMerchantUsers */
  const { employee } = Auth.userData();

  if (
    ['store_admin', 'general_admin', 'account_manager'].includes(
      employee?.role?.name
    )
  ) {
    return fetchHTTP('/api/protected/merchant_users', {
      params: {
        search_data:
          search && search.length > 4 ? encodeURIComponent(search) : null,
        page,
        limit,
        merchant_id: filterParams?.['merchant_id'] ?? null,
      },
      method: 'GET',
    });
  }
};

const getMerchantRoles = async merchant_id => {
  const response = await fetchHTTP(
    `/api/protected/roles?merchant_id=${merchant_id}`,
    {
      method: 'GET',
    }
  );

  return response;
};

const getMerchantUser = user_id => {
  return fetchHTTP(`/api/protected/merchant_users/${user_id}`, {
    method: 'GET',
  });
};

const createMerchantUser = user => {
  return fetchHTTP('/api/protected/merchant_users', {
    method: 'POST',
    data: user,
  });
};

const getZipCodeInfo = zip_code => {
  return fetchHTTP(`/api/protected/stores/zipcodes?&zip_code=${zip_code}`, {
    method: 'GET',
  });
};

const createStore = store => {
  return fetchHTTP('/api/protected/stores', {
    method: 'POST',
    data: store,
  });
};

const updateStore = (store, id) => {
  return fetchHTTP(`/api/protected/stores/${id}`, {
    method: 'PUT',
    data: store,
  });
};

const updateMerchantUser = (userId, user) => {
  return fetchHTTP(`/api/protected/merchant_users/${userId}`, {
    method: 'PUT',
    data: user,
  });
};

function buildPaymentsQuery(
  searchBy,
  startDate,
  endDate,
  channel,
  paymentType
) {
  let searchQuery = searchBy ? `&search_term=${searchBy}` : '';
  if (startDate) {
    searchQuery += `&start_date_range=${startDate}`;
  }
  if (endDate) {
    searchQuery += `&end_date_range=${endDate}`;
  }
  if (channel) {
    searchQuery += `&channel=${channel}`;
  }
  if (paymentType) {
    searchQuery += `&payment_status=${paymentType}`;
  }
  return searchQuery;
}

const getPayments = (
  paymentType,
  searchBy,
  page = 1,
  limit = 20,
  startDate,
  endDate,
  channel
) => {
  let searchQuery = buildPaymentsQuery(
    searchBy,
    startDate,
    endDate,
    channel,
    paymentType
  );

  const merchantSelected =
    JSON.parse(getLocalStorage('merchantSelected')) ?? {};
  let merchantQuery = '';
  if (Object.keys(merchantSelected).length > 0) {
    merchantQuery = `&merchant_id=${merchantSelected.merchant_id}`;
  }

  return fetchHTTP(
    `/api/protected/merchant_sales/payments_movements_aggregates?${searchQuery}&order_by=created_at&page=${page}&limit=${limit}${merchantQuery}`,
    {
      method: 'GET',
    }
  );
};

const exportPayments = (paymentType, searchBy, startDate, endDate, channel) => {
  let searchQuery = buildPaymentsQuery(
    searchBy,
    startDate,
    endDate,
    channel,
    paymentType
  );

  const merchantSelected =
    JSON.parse(getLocalStorage('merchantSelected')) ?? {};
  let merchantQuery = '';
  if (Object.keys(merchantSelected).length > 0) {
    merchantQuery = `&merchant_id=${merchantSelected.merchant_id}`;
  }

  return fetchHTTP(
    `/api/protected/merchant_sales/payments_movements_aggregates/export?${searchQuery}&order_by=payment_created_at_desc${merchantQuery}`,
    {
      method: 'GET',
      responseType: 'blob',
    }
  ).then(res => {
    // noinspection JSCheckFunctionSignatures
    const url = window.URL.createObjectURL(res);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'payments.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  });
};

const getMerchantsByTerm = term => {
  return fetchHTTP(`/api/protected/merchants?search=${term}`, {
    method: 'GET',
  });
};

const getCampaigns = () => {
  return new Promise(resolve => {
    resolve(ApiResponses['/api/protected/campaigns']);
  });
};

const getCampaignById = campaignId => {
  console.log(campaignId);
  /*
  Uncomment when endpoint is ready
  return fetchHTTP(`/api/protected/campaigns/${campaignId}`, {
    method: 'GET'
  });*/
  return new Promise(resolve => {
    resolve(ApiResponses['/api/protected/campaigns/<campaign_id>']);
  });
};

const getCampaignDirectoryDownloadZip = directoryId => {
  console.log(directoryId);
  /*
  Uncomment when endpoint is ready
  return fetchHTTP(`/api/protected/campaigns/download/zip/${directoryId}`, {
    method: 'GET'
  });*/
  return new Promise(resolve => {
    resolve(
      ApiResponses['/api/protected/campaigns/download/zip/<directory_id>']
    );
  });
};

const getAssetDownload = assetId => {
  console.log(assetId);
  /*
  Uncomment when endpoint is ready
  return fetchHTTP(`/api/protected/campaigns/download/asset/${assetId}`, {
    method: 'GET'
  });*/
  return new Promise(resolve => {
    resolve(ApiResponses['/api/protected/campaigns/download/asset/<asset_id>']);
  });
};

const getPayment = paymentId => {
  return fetchHTTP(`/api/protected/merchant_sales/payments/${paymentId}`, {
    method: 'GET',
  });
};

const requestRefund = refund => {
  return fetchHTTP('/api/protected/merchant_sales/refunds', {
    method: 'POST',
    data: refund,
  });
};

const requestOrderRefund = refund => {
  return fetchHTTP('/api/protected/refunds', {
    method: 'POST',
    data: refund,
  });
};

const getMerchantImages = merchantId => {
  console.log(merchantId);
  /*
  Uncomment when endpoint is ready
  return fetchHTTP(`/api/protected/merchant_image/${merchantId}`, {
    method: 'GET'
  });*/
  return new Promise(resolve => {
    resolve(ApiResponses['/api/protected/merchant_image/<merchant_id>']);
  });
};

const createMerchantImage = merchantImage => {
  console.log(merchantImage);
  /*
  Uncomment when endpoint is ready
  return fetchHTTP('/api/protected/merchant_image', {
    method: 'POST',
    data: merchantImage,
  });*/
  return new Promise(resolve => {
    resolve(ApiResponses['/api/protected/merchant_image/<action>']);
  });
};

const updateMerchantImage = merchantImage => {
  console.log(merchantImage);
  /*
  Uncomment when endpoint is ready
  return fetchHTTP('/api/protected/merchant_image', {
    method: 'POST',
    data: merchantImage,
  });*/
  return new Promise(resolve => {
    resolve(ApiResponses['/api/protected/merchant_image/<action>']);
  });
};

const deleteMerchantImage = merchantImage => {
  console.log(merchantImage);
  /*
  Uncomment when endpoint is ready
  return fetchHTTP('/api/protected/merchant_image', {
    method: 'DELETE',
    data: merchantImage.id,
  });*/
  return new Promise(resolve => {
    resolve(ApiResponses['/api/protected/merchant_image/<action>']);
  });
};

const requestUsersValidation = users => {
  return fetchHTTP('/api/protected/merchant_users/bulk/validate', {
    method: 'POST',
    data: users,
  });
};

const requestImportUsersCreation = users => {
  return fetchHTTP('/api/protected/merchant_users/bulk', {
    method: 'POST',
    data: users,
  });
};

const requestPaymentIdValidation = (paymentId, merchantId) => {
  return fetchHTTP(
    `/api/protected/orders/validate_payment?payment_id=${paymentId}&merchant_id=${merchantId}`,
    {
      method: 'GET',
    }
  );
};

const requestKueskiCouponValidation = kueskiCoupon => {
  return fetchHTTP(`/api/protected/orders/payment_vouchers/${kueskiCoupon}`, {
    method: 'GET',
  });
};

const bulkValidateStore = data => {
  return fetchHTTP('/api/protected/stores/bulk/validate', {
    method: 'POST',
    data,
  });
};

const createBulkStore = data => {
  return fetchHTTP('/api/protected/stores/bulk', {
    method: 'POST',
    data,
  });
};

const getMallList = cities => {
  return fetchHTTP('/api/protected/malls', {
    method: 'GET',
    params: { cities },
  });
};

const getHomeAssets = merchant => {
  let channel = '';
  const hasOnlineChannel = !!merchant?.channels?.find(
    channel => channel.channel === 'online' && channel.active
  );
  const hasInStoreChannel = !!merchant?.channels?.find(
    channel => channel.channel === 'instore' && channel.active
  );

  if (hasOnlineChannel && !hasInStoreChannel) {
    channel = 'online';
  } else if (!hasOnlineChannel && hasInStoreChannel) {
    channel = 'ecommerce';
  }

  return fetchHTTP('/api/v1/protected/assets', {
    method: 'GET',
    params: { channel: channel },
  });
};

const validateOffering = payload => {
  return fetchHTTP('/api/v1/protected/validate_offering', {
    method: 'POST',
    data: payload,
  });
};

export {
  bulkValidateStore,
  createBulkStore,
  createMerchantImage,
  createMerchantUser,
  createOrder,
  createStore,
  deleteMerchantImage,
  exportPayments,
  getAssetDownload,
  getCampaignById,
  getCampaignDirectoryDownloadZip,
  getCampaigns,
  getCategories,
  getMallList,
  getMerchantImages,
  getMerchantRoles,
  getMerchantUser,
  getMerchantUsers,
  getMerchantsByTerm,
  getOrderDetail,
  getOrderGenerated,
  getOrders,
  getPayment,
  getPayments,
  getStoreById,
  getStoreInsightsById,
  getStoreUsers,
  getStores,
  getStoresLight,
  getZipCodeInfo,
  orderVerdict,
  requestImportUsersCreation,
  requestKueskiCouponValidation,
  requestOrderRefund,
  requestPaymentIdValidation,
  requestRefund,
  requestUsersValidation,
  saveLogbehavior,
  updateMerchantImage,
  updateMerchantUser,
  updateStore,
  getHomeAssets,
  validateOffering,
};
