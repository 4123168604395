import React, { useEffect, useRef } from 'react';
//import Tab from '../Tab';
import ReportByPeriod from './ReportByPeriod';
import { ReactComponent as IconQuestion } from './../Tutorial/question-icon.svg';
// import { reportPaymentTutorialSteps } from './tutorial/steps';
import styles from './styles.module.scss';
import { reportPaymentTutorialSteps } from './tutorial/steps';
import { useTutorial } from '../Tutorial/useTutorial';
import '../Tutorial/tutorial.scss';
import { useGetMerchants } from '../../Utils/useGetMerchants';
import AnalyticsEvent, {
  AnalyticsList,
} from '../../services/analytics/AnalyticsEvent';

const MerchantReportPayments = () => {
  const isTracked = useRef(false);
  const merchants = useGetMerchants();
  const tourInstance = useTutorial({
    steps: reportPaymentTutorialSteps,
    enableMultiBrand: merchants.length > 1,
  });

  const handleStartTutorial = () => {
    tourInstance.start();
    const element = document.querySelector('.faq-helper');
    element.style.display = 'none';
  };

  useEffect(() => {
    if (!isTracked.current) {
      AnalyticsEvent().pageView({ page: AnalyticsList.merchantReportPayments });
      isTracked.current = true;
    }
  }, []);

  useEffect(() => {
    tourInstance.on('start', () => {
      const element = document.querySelector('.faq-helper');
      element.style.display = 'none';
    });

    tourInstance.initTutorial({ module: 'merchantPaymentReport' });

    return () => {
      tourInstance.off('start', () => {
        const element = document.querySelector('.faq-helper');
        element.style.display = 'none';
      });
    };
  }, []);

  return (
    <div className={styles.containerView}>
      <div className={styles.header}>
        <div className={styles.titlePage}>
          Reporte de pagos a comercio
          <IconQuestion
            id='activate-tutorial'
            className={`activate-tutorial ${styles.iconQuestionMark}`}
            onClick={handleStartTutorial}
          />
        </div>
        <div className={styles.helper}>
          Detalle de pagos de Kueski a tu comercio.
        </div>
      </div>
      <ReportByPeriod />

      {/*       <Tab tabs={ [
        {
          title: 'Resumen por periodo',
          component: isSuccess ?
            <ReportByPeriod
              paymentDateSchedules={ data.dateSchedules }
              defaultDateSchedules={ data.dateSchedules.length > 0 ? data.dateSchedules[0].options[0] : null }
            /> : <div/>
        },
        {
          title: 'Resumen por mes',
          component: isSuccess ?
            <ReportByMonth
              monthSchedules = { data.monthSchedules }
              defaultValue={ data.monthSchedules.length > 0? data.monthSchedules[0] : null }
            /> : <div/>
        }
      ] }
      /> */}
    </div>
  );
};

export default MerchantReportPayments;
