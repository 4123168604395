import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { MAX_BULK_ROWS, excelFileType } from '../../Utils/constants';
import { getJsonFromExcel, validateExcelFile } from '../../Utils/excelUtils';
import { useGetMerchant } from '../../Utils/useGetMerchant';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { useToastContext } from '../../contexts/Toast/ToastContext';
import { Button, Space } from '../../ds';
import { bulkValidateStore, getMallList } from '../../services/Application';
import { schemaKeys, storeSchema } from '../../services/storesSchemaValidation';
import LoaderModal from '../LoaderModal/LoaderModal';
import './addStoreSelectionModal.scss';

import { phoneSpaceFormatted } from '../../Utils/formValidations';
import UploadZone from '../UploadZone/UploadZone';
import { ReactComponent as DeleteIcon } from './delete-icon.svg';
import { ReactComponent as FileIcon } from './file-icon.svg';

const sheetName = 'alta de sucursales';
const timeZones = [
  'Guadalajara',
  'Monterrey',
  'Mazatlan',
  'Chihuahua',
  'Mexico City',
];

const fixTime = time => {
  return ('0' + time).slice(-2);
};

const resolvingTimeZone = timeZone => {
  if (timeZone === '' || timeZone === null) return timeZones[0];

  if (timeZones.includes(timeZone)) return timeZone;

  return timeZones[0];
};

const resolveSchedule = (item, day, open, close) => {
  const openSchedule = item?.[open] || null;
  const closeSchedule = item?.[close] || null;

  if (openSchedule === null || closeSchedule === null) return {};

  return {
    [day]: [
      {
        active: true,
        open:
          fixTime(openSchedule.getUTCHours()) +
          ':' +
          fixTime(openSchedule.getUTCMinutes()),
        closed:
          fixTime(closeSchedule.getUTCHours()) +
          ':' +
          fixTime(closeSchedule.getUTCMinutes()),
      },
    ],
  };
};
function FileStep(props) {
  const { onClose } = props;
  const navigate = useHistory();
  const viewport = useDeviceContextViewport();
  const initialValuesFileInput = {
    fileName: undefined,
    json: undefined,
    isUploadStage: false,
  };
  const [messageError, setMessageError] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [fileInputState, setFileInputState] = useState(initialValuesFileInput);
  const isMobileOnly = viewport.width <= 585;
  const addToast = useToastContext();
  const userMerchant = useGetMerchant();

  return (
    <Space
      direction='vertical'
      className={`${
        fileInputState.fileName
          ? 'upload-modal'
          : isMobileOnly
            ? 'mobile-modal'
            : ''
      } selection-container`}
    >
      {isCreating ? (
        <LoaderModal
          isOpen={isCreating}
          header='Estamos validando tus sucursales, no actualices la ventana.'
          content='Esto puede tardar unos momentos...'
        />
      ) : (
        <div>
          <div className='k-ds-heading-05 k-ds-m-bottom-sp-05'>
            Subir archivo
          </div>
          {fileInputState.fileName ? (
            <Space direction='vertical'>
              <div className='k-ds-body-02 k-ds-m-bottom-sp-09'>
                Verifica que sea el archivo correcto
              </div>
              <Space
                className='file-container k-ds-m-bottom-sp-09'
                direction={isMobileOnly ? 'vertical' : 'horizontal'}
              >
                <Space
                  className={
                    isMobileOnly
                      ? 'file-name-container k-ds-m-bottom-sp-06'
                      : 'file-name-container'
                  }
                >
                  {<FileIcon className='k-ds-m-right-sp-02' />}
                  <span className='truncate'>{fileInputState.fileName}</span>
                </Space>
                <Space
                  className={
                    isMobileOnly
                      ? 'delete-container k-ds-m-left-sp-07'
                      : 'delete-container'
                  }
                >
                  <DeleteIcon onClick={handleDeleteSelection} />
                </Space>
              </Space>
              <Space
                className='k-ds-width-full k-ds-m-bottom-sp-07'
                direction='horizontal'
              >
                <Button
                  type='primary-alternate'
                  size='large'
                  className='k-ds-m-rigth-sp-02 create-button'
                  onClick={handleUpload}
                  disabled={!fileInputState.json || isCreating}
                >
                  Subir archivo
                </Button>
                <Button type='simple' size='small' onClick={handleClose}>
                  Cerrar
                </Button>
              </Space>
            </Space>
          ) : (
            <UploadZone
              messageError={messageError}
              handleFilesChange={handleFilesChange}
            />
          )}
        </div>
      )}
    </Space>
  );

  function handleClose() {
    setFileInputState(initialValuesFileInput);
    onClose();
  }

  async function handleUpload() {
    let stores = [];
    let malls = [];
    const merchantId = userMerchant.merchant_id;

    setIsCreating(true);

    const cities = fileInputState.json
      .map(item => {
        let fileCity = item?.[schemaKeys.city]?.toString();
        if (item?.[schemaKeys.state]?.toString() === 'Ciudad de México') {
          fileCity = item?.[schemaKeys.state]?.toString();
        }

        return fileCity;
      })
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      });

    await getMallList(cities).then(response => {
      malls = response.data;
    });

    fileInputState.json.map(item => {
      const mallInfo = malls.filter(
        mallItem =>
          mallItem.name.toUpperCase() === item?.[schemaKeys.mall]?.toUpperCase()
      );

      const store = {
        merchant_id: merchantId,
        name: item[schemaKeys.storeName],
        store_type: 'physical',
        platform: 'kueskipay',
        timezone: resolvingTimeZone(item?.[schemaKeys.timezone] || null),
        active: 'true',
        phone_number: phoneSpaceFormatted(
          item?.[schemaKeys.phone]?.toString() || ''
        ),
        external_store_id: item?.[schemaKeys.external_store_id]?.toString() || null,
        address_attributes: {
          street: item?.[schemaKeys.street]?.toString() || '',
          ext_number: item?.[schemaKeys.ext]?.toString() || '',
          inner_number: item?.[schemaKeys.suite]?.toString() || '',
          zip_code: item?.[schemaKeys.zip]?.toString(),
          neighborhood: item?.[schemaKeys.neighborhood]?.toString() || '',
          city: item?.[schemaKeys.city]?.toString() || '',
          state: item?.[schemaKeys.state]?.toString() || '',
          mall_id: mallInfo?.[0]?.mall_id ?? '',
        },
        store_schedule_attributes: {
          schedule: {
            ...resolveSchedule(
              item,
              'monday',
              schemaKeys.open_mon,
              schemaKeys.close_mon
            ),
            ...resolveSchedule(
              item,
              'tuesday',
              schemaKeys.open_tue,
              schemaKeys.close_tue
            ),
            ...resolveSchedule(
              item,
              'wednesday',
              schemaKeys.open_wen,
              schemaKeys.close_wen
            ),
            ...resolveSchedule(
              item,
              'thursday',
              schemaKeys.open_thu,
              schemaKeys.close_thu
            ),
            ...resolveSchedule(
              item,
              'friday',
              schemaKeys.open_fri,
              schemaKeys.close_fri
            ),
            ...resolveSchedule(
              item,
              'saturday',
              schemaKeys.open_sat,
              schemaKeys.close_sat
            ),
            ...resolveSchedule(
              item,
              'sunday',
              schemaKeys.open_sun,
              schemaKeys.close_sun
            ),
          },
        },
      };
      stores.push(store);
    });

    bulkValidateStore({
      stores,
    })
      .then(response => {
        if (response.status === 'success') {
          stores.map(store => {
            store['is_valid'] = true;
            store['unique'] = uuidv4();
          });
        }
      })
      .catch(error => {
        const errors = error?.response?.data?.errors?.stores ?? {};
        stores.map((store, indx) => {
          if (errors?.[indx]) {
            store['errors'] = errors[indx];
            store['is_valid'] = false;
            store['unique'] = uuidv4();
          } else {
            store['is_valid'] = true;
            store['unique'] = uuidv4();
          }
        });
      })
      .finally(() => {
        const finalStore = stores.map(item => {
          const mallInfo = malls.filter(
            mallItem => mallItem.mall_id === item?.address_attributes.mall_id
          );

          return {
            ...item,
            address_attributes: {
              ...item.address_attributes,
              mall_name: mallInfo?.[0]?.name ?? '',
            },
          };
        });

        props.onClose();
        setIsCreating(false);
        navigate.push({
          pathname: '/stores/bulk/confirmation',
          data: finalStore,
        });
      });
  }

  function handleDeleteSelection() {
    setFileInputState({
      ...fileInputState,
      json: undefined,
      fileName: undefined,
    });
  }

  async function handleFilesChange(files) {
    const file = files[0];

    if (file.type !== excelFileType) {
      addToast(
        'El archivo debe ser un archivo de Excel con formato .xlsx',
        'error'
      );
      handleClose();
      return;
    }

    const entries = Object.values(schemaKeys);
    const validFile = await validateExcelFile(
      file,
      entries,
      'alta de sucursales'
    );

    if (!validFile) {
      setMessageError('Verifica que sea el archivo correcto');
      return;
    }

    const json = await getJsonFromExcel(file, sheetName);

    if (json.length > MAX_BULK_ROWS) {
      setMessageError('El sistema acepta hasta 80 filas de datos por archivo.');
      return;
    }

    for (const store of json) {
      try {
        await storeSchema.validate(store, { abortEarly: false });
        // .then(() => { }).catch((err) => { throw err;});
        store['is_valid'] = true;
      } catch (e) {
        store['is_valid'] = false;
      }
    }

    setMessageError('');
    setFileInputState({
      ...fileInputState,
      fileName: file.name ?? '',
      json: json,
    });
  }
}

FileStep.propTypes = {
  onClose: PropTypes.func.isRequired,
  downloadTemplate: PropTypes.func.isRequired,
};

export default FileStep;
