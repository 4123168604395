import React, { useState } from 'react';
import { Divider, Button, Space } from '../../ds';
import './CampaignDirectories.scss';
import PropTypes from 'prop-types';
import CampaignContent from '../CampaignContent/CampaignContent';
import { ReactComponent as FolderIcon } from './Folder.svg';
import { ReactComponent as DownloadIcon } from './Download.svg';
import { ReactComponent as UpChevronIcon } from './UpChevron.svg';
import { ReactComponent as DownChevronIcon } from './DownChevron.svg';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { PathLine } from './PathLine';
import { useLoaderContext } from '../../contexts/Loader/LoaderContext';
import { getCampaignDirectoryDownloadZip } from '../../services/Application';
import ErrorModal from '../ErrorModal/ErrorModal';

const CampaignDirectories = props => {
  const { setIsLoaderOpen } = useLoaderContext();
  const [expandedItem, setExpandedItem] = useState('');
  const viewport = useDeviceContextViewport();
  const [errorModalState, setErrorModalState] = React.useState(false);
  const [operationError, setOperationError] = React.useState({
    title: undefined,
    description: undefined,
  });

  function handleDownload(directoryName, directoryId) {
    setIsLoaderOpen(true);
    getCampaignDirectoryDownloadZip(directoryId)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data.src]));
        const link = document.createElement('a');
        link.href = url;
        let date = new Date();
        let timeStamp = date.toLocaleString();
        link.setAttribute('download', `${directoryName}_${timeStamp}.zip`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        setOperationError({
          title: 'Error en la descarga',
          description:
            'Ocurrió un error al descargar el archivo. ¿Puedes intentarlo de nuevo?',
        });
        console.error(error);
        return setErrorModalState(true);
      })
      .finally(() => {
        setIsLoaderOpen(false);
      });
  }

  function closeErrorModal() {
    setErrorModalState(false);
  }

  return (
    <>
      <div className='campaign-directories-list'>
        {props.directories.map((directory, index) => {
          const isExpanded = expandedItem === directory.directory_id;
          return (
            <React.Fragment key={index}>
              <Space direction='horizontal'>
                <PathLine
                  className='k-ds-m-left-sp-08 path-line'
                  height='auto'
                />
                <Space
                  direction='vertical'
                  className='dir-item k-ds-width-full'
                >
                  <Divider className='dir-divider' />
                  <Space
                    direction='horizontal'
                    justify='space-between'
                    className='dir-container'
                    onClick={() =>
                      setExpandedItem(isExpanded ? '' : directory.directory_id)
                    }
                  >
                    <Space
                      className='dir-container-info k-ds-width-full'
                      onClick={() =>
                        setExpandedItem(
                          isExpanded ? '' : directory.directory_id
                        )
                      }
                    >
                      <Space
                        direction='vertical'
                        className='k-ds-m-rigth-sp-09 k-ds-width-full'
                      >
                        <Space
                          justify='space-between'
                          className='dir-info-container'
                        >
                          <Space
                            direction={
                              viewport.isMobile && !viewport.isSmallTablet
                                ? 'vertical'
                                : 'horizontal'
                            }
                            className='dir-info'
                          >
                            <Space direction='horizontal'>
                              <div className='k-ds-body-01 k-ds-m-right-sp-05'>
                                <FolderIcon className='folder-icon' />
                              </div>
                              <div className='k-ds-body-01 dir-name'>
                                {directory.name}
                                {viewport.isDesktop
                                  ? ' (' + directory.totalFiles + ')'
                                  : ''}
                              </div>
                            </Space>
                            <div
                              className={
                                viewport.isSmallTablet
                                  ? 'k-ds-body-01 dir-size-text tablet-margin'
                                  : 'k-ds-body-01 dir-size-text'
                              }
                            >
                              Tamaño: {directory.totalSize}
                            </div>
                          </Space>
                          <Space className='k-ds-m-right-sp-07 download-button-container'>
                            <Button
                              type='link'
                              size='small'
                              onClick={() =>
                                handleDownload(
                                  directory.name,
                                  directory.directory_id
                                )
                              }
                            >
                              <DownloadIcon className='k-ds-m-right-sp-04' />{' '}
                              Descargar .zip
                            </Button>
                          </Space>
                        </Space>
                      </Space>
                    </Space>
                    <Space className='dir-container-chevron'>
                      <Space
                        className='chevron-details'
                        onClick={() =>
                          setExpandedItem(
                            isExpanded ? '' : directory.directory_id
                          )
                        }
                      >
                        {isExpanded ? <UpChevronIcon /> : <DownChevronIcon />}
                      </Space>
                    </Space>
                  </Space>
                  {isExpanded && (
                    <Space className='bg-white expanded-dir-item'>
                      <Space
                        justify='space-between'
                        className='dir-item k-ds-width-full'
                      >
                        <Space className='dir-info k-ds-width-full'>
                          <Space className='k-ds-m-left-sp-04 k-ds-width-full'>
                            <CampaignContent
                              content={directory.content}
                              simplifiedStyle
                              isLastLevel={false}
                            />
                          </Space>
                        </Space>
                      </Space>
                    </Space>
                  )}
                </Space>
              </Space>
            </React.Fragment>
          );
        })}
      </div>
      <ErrorModal
        defaultOpened={errorModalState}
        close={closeErrorModal}
        heading={operationError.title}
        description={operationError.description}
      />
    </>
  );
};

CampaignDirectories.propTypes = {
  directories: PropTypes.array.isRequired,
};

export default CampaignDirectories;
