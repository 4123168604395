import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Space, TextInput } from '../../../ds';
import KDS from '../../../ds/lib/k-ds-foundation';
import { ReactComponent as IconArrowDown } from './icon-arrow-down.svg';

const StyledTextSelect = styled.div`
  position: relative;

  .icon {
    position: absolute;
    top: 2.6rem;
    right: 1rem;
    pointer-events: none;
  }
  .icon.show {
    transform: rotateX(180deg);
  }

  .options-group {
    ${KDS.setFontFamilyWithFallback('font-secondary')}
    position: absolute;

    z-index: 2;

    display: none;
    transform: translate(0, ${KDS.getSpacing('spacing-01')});

    overflow-y: auto;

    width: 100%;
    max-height: 186px;

    ${KDS.setColorWithFallback('background: ', 'white')}

    ${KDS.setColorTokenWithFallback(
      'border: 1px solid ',
      'interactive-alternate'
    )}
    border-radius: 8px;
    box-sizing: border-box;

    ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}

    &.show {
      display: block;
    }
  }

  .option {
    span {
      display: block;
      padding: 14px;
      cursor: pointer;

      :hover,
      &.preselected {
        ${KDS.setColorTokenWithFallback('background: ', 'background-alternate')}
        ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary')}
      }
    }
  }
`;

const TextSelect = ({
  className,
  label,
  placeholder,
  helper,
  options,
  onChange,
  value,
  disabled = false,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [manuallySelected, setManuallySelected] = useState(-1);
  const optionsCntRef = useRef(null);
  const [ignoreBlur, setIgnoreBlur] = useState(false);

  const selectOpts = options.map(suggestion => {
    return {
      id: suggestion.text.toLowerCase().replace(/ /g, '_'),
      text: suggestion.text,
      value: suggestion.value,
      isSelected: suggestion.value === value,
      ref: useRef(null),
    };
  });

  const setSearchTextBasedOnValue = () => {
    const preSelectedOption = selectOpts.filter(option => option.isSelected);
    if (preSelectedOption.length > 0) {
      setSelectedOption(preSelectedOption[0]);
    }
  };

  const onSelectClick = () => {
    if (disabled) return;
    setIsActive(!isActive);
  };

  const onOptionClick = suggestion => {
    setSelectedOption(suggestion);
    if (onChange) onChange(suggestion.value);
    setIsActive(false);
  };

  const handleSearchTextChange = e => {
    if (isActive === false) {
      setIsActive(true);
    }
    setSearchText(e.target.value);
  };

  const handleBlur = () => {
    if (ignoreBlur) return;

    if (selectedOption && selectedOption.text !== searchText) {
      setSearchText(selectedOption.text);
    }

    if (isActive) {
      setIsActive(false);
    }
  };

  const checkForMatches = () => {
    const filteredOptions = selectOpts
      .map((option, idx) => {
        return { originalIndex: idx, data: option };
      })
      .filter(option =>
        option.data.text.toLowerCase().startsWith(searchText.toLowerCase())
      )
      .map(option => option.originalIndex);

    if (filteredOptions.length === 0) {
      setManuallySelected(-1);
      return;
    }

    setManuallySelected(parseInt(filteredOptions[0]));
  };

  const handleKeyDown = e => {
    if (['ArrowUp', 'ArrowDown', 'Enter', 'Escape'].includes(e.key)) {
      e.preventDefault();
      setIsActive(true);
      switch (e.key) {
        case 'ArrowUp':
          if (manuallySelected > -1) setManuallySelected(manuallySelected - 1);
          break;
        case 'ArrowDown':
          if (manuallySelected < selectOpts.length - 1)
            setManuallySelected(manuallySelected + 1);
          break;
        case 'Enter':
          if (
            manuallySelected > -1 &&
            manuallySelected <= selectOpts.length - 1
          ) {
            onOptionClick(selectOpts[manuallySelected]);
            setIsActive(false);
          }
          break;
        case 'Escape':
          setIsActive(false);
          break;
      }
    }
  };

  const scrollOptionsDiv = top => {
    optionsCntRef.current.scrollTo({
      top: top,
    });
  };

  useEffect(() => {
    setSearchTextBasedOnValue();
  }, [value, options]);

  useEffect(() => {
    if (searchText === '') {
      setManuallySelected(-1);
    } else if (isActive) {
      checkForMatches();
    }
  }, [searchText]);

  useEffect(() => {
    if (manuallySelected === -1) return;
    scrollOptionsDiv(selectOpts[manuallySelected].ref.current.offsetTop);
  }, [manuallySelected]);

  useEffect(() => {
    if (selectedOption === null) return;
    setSearchText(selectedOption.text);
  }, [selectedOption]);

  const showClass = isActive ? 'show' : '';
  return (
    <Space size='small' direction='vertical' className={className}>
      <StyledTextSelect
        onClick={onSelectClick}
        onBlur={handleBlur}
        isActive={isActive}
      >
        <TextInput
          disabled={disabled}
          className='actual-text'
          label={label}
          onChange={handleSearchTextChange}
          helper={helper}
          placeholder={placeholder}
          type='outline'
          value={searchText}
          required
          name='searchText'
          autoComplete='off'
          onKeyDown={handleKeyDown}
        />
        <IconArrowDown className={`icon ${showClass}`} />
        <div className={`options-group ${showClass}`} ref={optionsCntRef}>
          {selectOpts.map((option, idx) => (
            <div key={option.id} className='option'>
              <span
                onMouseDown={() => setIgnoreBlur(true)}
                onClick={e => {
                  e.stopPropagation();
                  onOptionClick(option);
                }}
                aria-hidden='true'
                ref={option.ref}
                className={idx === manuallySelected ? 'preselected' : ''}
              >
                {option.text}
              </span>
            </div>
          ))}
        </div>
      </StyledTextSelect>
    </Space>
  );
};

TextSelect.propTypes = {
  className: PropTypes.string,
  form: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  optional: PropTypes.string,
  helper: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export { TextSelect };
