import React from 'react';
import { Space } from '../../ds';
import PropTypes from 'prop-types';
import { Card } from '../../ds';
import { ReactComponent as StoreIcon } from './storeIcon.svg';

const StoreCard = ({
  store_id,
  name,
  status,
  address,
  goToDetail,
  getStatusTag,
}) => {
  return (
    <Card className='store-card'>
      <Space direction='horizontal' justify='space-between' align='center'>
        <Space align='center'>
          <Space
            direction='horizontal'
            align='center'
            justify='center'
            className='store-icon-container'
          >
            <StoreIcon className='store-icon' />
          </Space>
          <Space
            direction='horizontal'
            align='center'
            justify='center'
            className='k-ds-m-left-sp-03'
          >
            <span className='store-name'>{name}</span>
          </Space>
        </Space>
        <Space direction='vertical' align='start' justify='top'>
          {getStatusTag(status)}
        </Space>
      </Space>

      <Space direction='vertical' className='k-ds-m-vertical-sp-07'>
        <span className='store-address'>
          <span>
            {address.street} {address.ext_number},
          </span>
          <br />
          <span>
            {address.neighborhood}, C.P. {address.zip_code}
          </span>
          <br />
          <span>{address.city}</span>
        </span>
      </Space>
      <Space direction='horizontal'>
        <button
          className='link-button'
          onClick={() => goToDetail(`/stores/${store_id}`)}
        >
          Ver detalle
        </button>
      </Space>
    </Card>
  );
};

StoreCard.propTypes = {
  name: PropTypes.string,
  status: PropTypes.string,
  address: PropTypes.object,
  goToDetail: PropTypes.func.isRequired,
  getStatusTag: PropTypes.func.isRequired,
  store_id: PropTypes.string,
};

export default StoreCard;
