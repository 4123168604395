import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';

const TdStyled = styled.td`
  ${props => (props.noWrap ? 'white-space: nowrap;' : '')}
  ${props => (props.wordBreakAll ? 'word-break: break-all;' : '')}
`;

const TdDefault = ({ children, dataTitle, devices, ...props }) => {
  const { type } = useDeviceContextViewport();
  if (!devices?.split(' ').includes(type)) {
    return null;
  }
  return (
    <TdStyled data-title={dataTitle} {...props}>
      {children}
    </TdStyled>
  );
};

TdDefault.propTypes = {
  children: PropTypes.node,
  devices: PropTypes.string,
  dataTitle: PropTypes.string,
};

export default TdDefault;
