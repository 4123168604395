import React, { useState } from 'react';
import { Space, Card, Button } from '../../ds';
import './logoWelcome.scss';
import { ReactComponent as EditLogo } from './LogoEdit.svg';
import { ReactComponent as ChevronRightLogo } from './ChevronRight.svg';
import { ReactComponent as DevicesImage } from './Devices.svg';
import { useLoaderContext } from '../../contexts/Loader/LoaderContext';
import { getMerchantImages } from '../../services/Application';
import Auth from '../../Utils/Auth/auth';
import SelectionModal from './SelectionModal';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';

const LogoWelcome = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const { setIsLoaderOpen } = useLoaderContext();
  const { merchant } = Auth.userData();
  if (!merchant) {
    return '';
  }
  const merchantId = merchant.merchant_id;
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const defaultSrc = require('./Default.svg').default;
  const viewport = useDeviceContextViewport();

  const emptyFile = {
    name: undefined,
    src: require('./Devices.svg').default,
  };

  const [monoLogoState, setMonoLogoState] = useState(emptyFile);
  const [colorLogoState, setColorLogoState] = useState(emptyFile);

  React.useEffect(() => {
    setIsLoaderOpen(true);
    setIsLoading(true);
    getMerchantImages(merchantId)
      .then(response => {
        setMonoLogoState(response.data.monocromatic);
        setColorLogoState(response.data.color);
      })
      .catch(err => console.log(err))
      .finally(() => {
        setIsLoaderOpen(false);
        setIsLoading(false);
      });
  }, []);

  function handleSubmit() {
    setOpenConfirmModal(true);
  }

  return (
    <>
      {(!monoLogoState && !colorLogoState) || isLoading ? (
        <>
          <Space
            direction='vertical'
            className='k-ds-height-full k-ds-m-top-sp-09'
            justify='space-between'
          >
            <Space
              direction='horizontal'
              justify='space-between'
              className='action-header logo-welcome k-ds-m-top-sp-03'
            >
              <Card
                shadow
                className={
                  viewport.isMobile && !viewport.isSmallTablet
                    ? 'logo-card card-mobile-max-w'
                    : 'logo-card'
                }
                onClick={handleSubmit}
              >
                <Space direction='vertical'>
                  <Space
                    direction='horizontal'
                    justify='space-between'
                    align='center'
                    className='k-ds-m-bottom-sp-04 k-ds-width-full'
                  >
                    <Space>
                      <EditLogo></EditLogo>
                      <div className='k-ds-heading-03 k-ds-m-left-sp-06 card-text'>
                        Agregar logotipo
                      </div>
                    </Space>
                    <Space className='k-ds-m-left-sp-08'>
                      <Button
                        className='back-button'
                        type='link'
                        size='small'
                        onClick={handleSubmit}
                      >
                        <ChevronRightLogo></ChevronRightLogo>
                      </Button>
                    </Space>
                  </Space>
                  <div className='card-text-description k-ds-m-right-sp-07'>
                    El logotipo es una pieza clave para que los clientes te
                    reconozcan. Nuestra recomendación es que sea único,
                    comprensible y de buena calidad.
                  </div>
                </Space>
              </Card>
            </Space>
            <Space className='devices-container'>
              <DevicesImage />
            </Space>
          </Space>
        </>
      ) : (
        <>
          {(monoLogoState || colorLogoState) && !isLoading && (
            <>
              <Space
                direction='vertical'
                justify='space-between'
                className='action-header logo-welcome'
              >
                <div className='k-ds-body-02 k-ds-m-bottom-sp-07'>
                  Tus archivos:
                </div>
                <Space direction='horizontal' className='preview-box'>
                  <div className='k-ds-m-right-sp-09 preview-card-mono'>
                    <div className='k-ds-body-02 k-ds-m-bottom-sp-06'>
                      Logotipo <strong>en negro o monocromático.</strong>
                    </div>
                    <div className='logo-card-preview'>
                      <div className='logo-info k-ds-m-top-sp-08 k-ds-m-bottom-sp-04'>
                        <img
                          className='logo-preview k-ds-m-bottom-sp-08'
                          src={
                            monoLogoState.src ? monoLogoState.src : defaultSrc
                          }
                          alt='Monocromatic Logo'
                        />
                        <span className='k-ds-caption-01 logo-file-name'>
                          {monoLogoState.name}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='k-ds-body-02 k-ds-m-bottom-sp-06 preview-card-color'>
                      Logotipo <strong>a color.</strong>
                    </div>
                    <div className='logo-card-preview'>
                      <div className='logo-info k-ds-m-top-sp-08 k-ds-m-bottom-sp-04'>
                        <img
                          className='logo-preview k-ds-m-bottom-sp-08'
                          src={
                            colorLogoState.src ? colorLogoState.src : defaultSrc
                          }
                          alt='Color Logo'
                        />
                        <span className='k-ds-caption-01 logo-file-name'>
                          {colorLogoState.name}
                        </span>
                      </div>
                    </div>
                  </div>
                </Space>
              </Space>
              <Button
                size='small'
                className='k-ds-m-top-sp-09 action-button'
                type='primary'
                horizontalPadding='3rem'
                verticalPadding='1rem'
                onClick={handleSubmit}
              >
                Administrar mis logotipos
              </Button>
            </>
          )}
        </>
      )}
      <SelectionModal
        defaultOpened={openConfirmModal}
        close={() => setOpenConfirmModal(false)}
      />
    </>
  );
};

export default LogoWelcome;
