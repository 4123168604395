import styled from 'styled-components';
import { ButtonDefaults } from './ButtonDefaults';
import KDS from '../../lib/k-ds-foundation';

const ButtonGhost = styled(ButtonDefaults)`
  ${KDS.setColorWithFallback('background: ', 'transparent')}
  ${KDS.setColorTokenWithFallback('color: ', 'text-secondary')}
  box-shadow: none;

  &:enabled {
    &:hover {
      ${KDS.setColorTokenWithFallback('background: ', 'background-alternate')}
    }

    &:active {
      ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}
    }

    border: 2px solid transparent;

    &:focus-visible {
      ${KDS.setColorTokenWithFallback('background: ', 'background-alternate')}
      ${KDS.setColorTokenWithFallback('border: 2px solid ', 'inverse-background')}
      ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}
    }
  }

  &:disabled {
    ${KDS.setColorTokenWithFallback('color: ', 'text-placeholder')}
  }
`;

export {
  ButtonGhost,
};
