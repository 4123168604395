import { Button, Modal, Space } from '../../ds';
import React from 'react';
import { ReactComponent as CancelIcon } from './cancelIcon.svg';
import './confirmModal.scss';
import { ReactComponent as WarningIcon } from './warningIcon.svg';

import PropTypes from 'prop-types';

const getIcon = mode => {
  switch (mode) {
    case 'Warning':
      return <WarningIcon className='k-ds-m-top-sp-06 k-ds-m-bottom-sp-08' />;
    case 'Cancel':
      return (
        <div className='k-ds-m-top-sp-06 k-ds-m-bottom-sp-07 icon-container'>
          <CancelIcon />
        </div>
      );
    default:
      return null;
  }
};

const ConfirmModal = ({
  isOpen = false,
  setIsOpen = () => {},
  mode = 'Warning',
  header,
  content,
  cancelButtonText = 'No, regresar',
  confirmButtonText = 'Sí, cancelar',
  onConfirm = () => {},
  onCancel = () => {},
  onClose = () => {},
}) => {
  const closeModal = () => {
    setIsOpen(false);
    onClose();
  };

  const cancelModal = () => {
    setIsOpen(false);
    onCancel();
  };

  const confirmModal = () => {
    setIsOpen(false);
    onConfirm();
  };

  return (
    <>
      <Modal
        theme='dark'
        cardType='secondary'
        isOpen={isOpen}
        onClose={closeModal}
        onOverlayClick={closeModal}
        className='confirm-modal'
      >
        <Space
          justify='center'
          direction='vertical'
          align='center'
          className='k-ds-m-horizontal-sp-09'
        >
          {getIcon(mode)}
          <div className='k-ds-heading-05 k-ds-m-bottom-sp-05'>{header}</div>
          <div className='k-ds-subheading-02 k-ds-m-bottom-sp-07 content'>
            {content}
          </div>
          <Space direction='horizontal' className='k-ds-m-bottom-sp-06'>
            <Button horizontalPadding='3rem' onClick={confirmModal}>
              {confirmButtonText}
            </Button>
            <Button
              type='secondary-alternate'
              horizontalPadding='3rem'
              onClick={cancelModal}
            >
              {cancelButtonText}
            </Button>
          </Space>
        </Space>
      </Modal>
    </>
  );
};

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func.isRequired,
  header: PropTypes.string,
  content: PropTypes.string,
  mode: PropTypes.oneOf(['Warning', 'Cancel']),
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
};

export default ConfirmModal;
