import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as GlassIcon } from './res/glass_icon.svg';
import { Button } from '../../ds';
import { StyledNoResults } from './styled';

const NoResults = ({ onCleanFilter }) => {
  return (
    <StyledNoResults>
      <GlassIcon />
      <div className='k-ds-heading-03'>Sin resultados</div>
      <div className='k-ds-body-01'>
        Sin coincidencias para este filtro, intenta usando otro.
      </div>

      <Button type='link' onClick={onCleanFilter}>
        Limpiar filtro
      </Button>
    </StyledNoResults>
  );
};

NoResults.propTypes = {
  onCleanFilter: PropTypes.func,
};

export default NoResults;
