import React from 'react';
import { useHistory } from 'react-router';
import { Button, Space } from '../../ds';
import NotFoundImage from './not-found.svg';
import HttpStatus404Image from './404.svg';
import Text from './text.json';
import './notfound.scss';

function NotFound() {
  const history = useHistory();
  const redirectToHome = () => {
    history.push('/');
  };
  return (
    <div className='not-found-page'>
      <img src={NotFoundImage} alt='Not Found' />
      <Space direction='vertical' className='container'>
        <img src={HttpStatus404Image} alt='404' />
        <span className='description font-weight-bold'>{Text.description}</span>
        <span>
          <Button size='large' onClick={redirectToHome}>
            Volver al inicio
          </Button>
        </span>
      </Space>
    </div>
  );
}

export default NotFound;
