import * as z from 'zod';
import {
  isOnlyDigits,
  validateAlphaNumeric,
} from '../../../Utils/formValidations';
import {
  MAX_ONE_TIME_PASSCODE_LENGTH,
  OLD_MAX_ONE_TIME_PASSCODE_LENGTH,
} from './OneTimePasscodeVerification';
import { isFeatureEnabled } from '../../../Utils/Feature/feature_flag';

const isOtpOfflineV2Enabled = isFeatureEnabled('otp_offline_v2');
const ONE_TIME_PASSCODE_LENGTH = isOtpOfflineV2Enabled
  ? MAX_ONE_TIME_PASSCODE_LENGTH
  : OLD_MAX_ONE_TIME_PASSCODE_LENGTH;

const errorText = isOtpOfflineV2Enabled
  ? 'Solo números (0-9)'
  : 'Solo letras (A-Z) y números (0-9)';

const OneTimePasscodeVerificationSchema = z.object({
  phoneNumber: z
    .string({
      required_error: 'Campo obligatorio.',
      invalid_type_error: 'Este campo es invalido.',
    })
    .length(10, 'Este campo es invalido.')
    .refine(value => isOnlyDigits(value), 'Número incorrecto o no existe'),
  token: z
    .string({
      required_error: 'Código incorrecto.',
      invalid_type_error: 'Este campo es invalido.',
    })
    .length(ONE_TIME_PASSCODE_LENGTH, 'Este campo es invalido.')
    .refine(
      value =>
        isOtpOfflineV2Enabled
          ? isOnlyDigits(value)
          : validateAlphaNumeric(value),
      errorText
    ),
});

export default OneTimePasscodeVerificationSchema;
