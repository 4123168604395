import Auth from '../../Utils/Auth/auth';
import {
  ACCOUNT_MANAGER,
  GENERAL_ADMIN,
  SALES_ROLE,
  STORE_ADMIN,
} from '../../Utils/constants';
import { JsonUtil } from '../../Utils/jsonUtil';
import { isEmptyObject } from '../../Utils/utils';
import AmplitudeEventTracker, {
  amplitudeParams,
} from './amplitude/AmplitudeEventTracker';

export const AnalyticsEventDictionary = {
  logInComplete: 'log_in_completed',
  passwordForgot: 'password_forgot',
  passwordRecovery: 'password_recovery_resent',
  profileDetails: 'profile_details_viewed',
  homePage: 'home_viewed',
  quickAccessClicked: 'quick_access_clicked',
  videoClicked: 'videos_clicked',
  orderCreated: 'new_order_created',
  codeQRCreadted: 'qr_code_created',
  paymentCodeCreated: 'payment_code_created',
  orderPaymentViewed: 'order_payment_viewed',
  transactionCancelled: 'merchant_transaction_cancelled',
  cancellationSelected: 'cancellation_selected',
  cancellationConfirmed: 'cancellation_confirmed',
  paymentRecordViewed: 'payment_record_viewed',
  paymentRecordExported: 'payment_record_exported',
  informationSearched: 'information_searched',
  addUser: 'add_users_selected',
  addOneUser: 'one_user_selected',
  addMultipleUsers: 'multiple_user_selected',
  errorTrack: 'show_error',
  verifyStatus: 'verify_status',
  screenTime: 'screen_time_view',
  pageView: 'page_viewed',
  merchantPortalChargeQrViewLoaded: 'merchant_portal_charge_qr_view_loaded',
  merchantPortalChargeQrGenerateOrderButtonClicked:
    'merchant_portal_charge_qr_generate_order_button_clicked',
  merchantPortalChargeQROrderCreatedViewLoaded:
    'merchant_portal_charge_qr_order_created_view_loaded',
  merchantPortalChargeQROrderCreatedViewCopyUrlButtonClicked:
    'merchant_portal_charge_qr_order_created_view_copy_url_button_clicked',
  merchantPortalChargedQrOrderCreatedViewConfirmButtonClicked:
    'merchant_portal_charged_qr_order_created_view_confirm_button_clicked',
  merchantPortalQRBasedOrderCreatedViewCancelButtonClicked:
    'merchant_portal_QR_based_order_created_view_cancel_button_clicked',
  merchantPortalChargedQROrderSuccesViewLoaded:
    'merchant_portal_charged_qr_order_succes_view_loaded',
  merchantPortalChargedQROrderFailureViewLoaded:
    'merchant_portal_charged_qr_order_failure_view_loaded',
};

export const AnalyticsList = {
  logIn: 'log_in',
  passwordRecover: 'password_recover',
  home: 'home',
  transactionReport: 'transaction_report',
  merchantReportPayments: 'payments_report_payments',
  paymentsOrderHistory: 'payments_order_history',
  instorePaymentCode: 'instore_payment_code',
  instorePaymentQR: 'instore_payment_qr',
  instoreGenerateQR: 'instore_generated_qr',
  paymentOrderDetail: 'payment_order_detail',
  paymentDetail: 'payment_detail',
  paymentReimbursement: 'payment_reimbursement',
  storeManagement: 'store_management',
  storeNew: 'store_new',
  storeEdit: 'store_edit',
  userManagement: 'user_management',
  userEdit: 'user_edit',
  userNew: 'user_new',
  recurrentFaq: 'recurrent_faq',
  customerService: 'customer_service',
  userInformation: 'user_information',
};

export const getUserProfile = role => {
  switch (role) {
    case GENERAL_ADMIN:
      return 'General';
    case STORE_ADMIN:
      return 'Admin';
    case ACCOUNT_MANAGER:
      return 'Manager';
    case SALES_ROLE:
      return 'Seller';
    default:
      return 'not_user_profile';
  }
};

const getStores = () => {
  try {
    const { stores } = Auth.userData();

    if (isEmptyObject(stores)) {
      return [];
    }

    return stores;
  } catch (error) {
    return { name: '', merchant_id: '' };
  }
};

export default function AnalyticsEvent() {
  return {
    pageView: function ({ page }) {
      console.log('pageView');
      AmplitudeEventTracker().trackEvent({
        name: AnalyticsEventDictionary.pageView,
        params: {
          [amplitudeParams.pageName]: page,
        },
      });
    },
    logInComplete: async function () {
      AmplitudeEventTracker().trackEvent({
        name: AnalyticsEventDictionary.logInComplete,
        params: {
          [amplitudeParams.stores]: getStores().map(store => store.store_id),
        },
      });
    },
    passwordForgot: function () {
      AmplitudeEventTracker().trackEvent({
        name: AnalyticsEventDictionary.passwordForgot,
      });
    },
    passwordRecovery: function () {
      AmplitudeEventTracker().trackEvent({
        name: AnalyticsEventDictionary.passwordRecovery,
      });
    },
    profileDetails: function ({ branch }) {
      AmplitudeEventTracker().trackEvent({
        name: AnalyticsEventDictionary.profileDetails,
        params: {
          [amplitudeParams.branchInCharge]: branch,
        },
      });
    },
    homePage: async function () {
      await AmplitudeEventTracker().trackEvent({
        name: AnalyticsEventDictionary.homePage,
      });
    },
    quickAccessClicked: function () {
      AmplitudeEventTracker().trackEvent({
        name: AnalyticsEventDictionary.quickAccessClicked,
      });
    },
    videoClicked: function () {
      AmplitudeEventTracker().trackEvent({
        name: AnalyticsEventDictionary.videoClicked,
      });
    },
    orderCreated: function () {
      AmplitudeEventTracker().trackEvent({
        name: AnalyticsEventDictionary.orderCreated,
        params: {
          [amplitudeParams.orderType]: 'Monto',
        },
      });
    },
    codeQRCreadted: function () {
      AmplitudeEventTracker().trackEvent({
        name: AnalyticsEventDictionary.codeQRCreadted,
      });
    },
    paymentCodeCreated: function () {
      AmplitudeEventTracker().trackEvent({
        name: AnalyticsEventDictionary.paymentCodeCreated,
      });
    },
    orderPaymentViewed: function ({
      storeName,
      storeId,
      paymentId,
      paymentReference,
      totalPayment,
      totalItems,
      paymentLink,
    }) {
      AmplitudeEventTracker().trackEvent({
        name: AnalyticsEventDictionary.orderPaymentViewed,
        params: {
          [amplitudeParams.orderType]: 'Monto',
          [amplitudeParams.storeName]: storeName,
          [amplitudeParams.storeId]: storeId,
          [amplitudeParams.paymentId]: paymentId,
          [amplitudeParams.paymentReference]: paymentReference,
          [amplitudeParams.totalPayment]: totalPayment,
          [amplitudeParams.totalItems]: totalItems,
          [amplitudeParams.paymentLink]: paymentLink,
        },
      });
    },
    transactionCancelled: function ({
      storeName,
      storeId,
      paymentId,
      funnelStep,
    }) {
      AmplitudeEventTracker().trackEvent({
        name: AnalyticsEventDictionary.transactionCancelled,
        params: {
          [amplitudeParams.orderType]: 'Monto',
          [amplitudeParams.storeName]: storeName,
          [amplitudeParams.storeId]: storeId,
          [amplitudeParams.funnelStep]: funnelStep,
          [amplitudeParams.paymentId]: paymentId,
        },
      });
    },
    cancellationSelected: function ({
      storeName,
      storeId,
      funnelStep,
      paymentId,
      reimbursementType,
    }) {
      AmplitudeEventTracker().trackEvent({
        name: AnalyticsEventDictionary.cancellationSelected,
        params: {
          [amplitudeParams.reimbursementType]: reimbursementType,
          [amplitudeParams.storeName]: storeName,
          [amplitudeParams.orderType]: 'Monto',
          [amplitudeParams.storeId]: storeId,
          [amplitudeParams.paymentId]: paymentId,
          [amplitudeParams.funnelStep]: funnelStep,
        },
      });
    },
    cancellationConfirmed: function ({
      storeId,
      storeName,
      funnelStep,
      paymentId,
      reimbursementType,
    }) {
      AmplitudeEventTracker().trackEvent({
        name: AnalyticsEventDictionary.cancellationConfirmed,
        params: {
          [amplitudeParams.orderType]: 'Monto',
          [amplitudeParams.storeId]: storeId,
          [amplitudeParams.storeName]: storeName,
          [amplitudeParams.funnelStep]: funnelStep,
          [amplitudeParams.paymentId]: paymentId,
          [amplitudeParams.reimbursementType]: reimbursementType,
        },
      });
    },
    paymentRecordViewed: function () {
      AmplitudeEventTracker().trackEvent({
        name: AnalyticsEventDictionary.paymentRecordViewed,
      });
    },
    paymentRecordExported: function () {
      AmplitudeEventTracker().trackEvent({
        name: AnalyticsEventDictionary.paymentRecordExported,
      });
    },
    informationSearched: function ({ searchResults, funnelStep, inputType }) {
      AmplitudeEventTracker().trackEvent({
        name: AnalyticsEventDictionary.informationSearched,
        params: {
          [amplitudeParams.searchResults]: searchResults,
          [amplitudeParams.funnelStep]: funnelStep,
          [amplitudeParams.inputType]: inputType,
        },
      });
    },
    addUser: function () {
      AmplitudeEventTracker().trackEvent({
        name: AnalyticsEventDictionary.addUser,
      });
    },
    addOneUser: function () {
      AmplitudeEventTracker().trackEvent({
        name: AnalyticsEventDictionary.addOneUser,
      });
    },
    addMultipleUsers: function () {
      AmplitudeEventTracker().trackEvent({
        name: AnalyticsEventDictionary.addMultipleUsers,
      });
    },
    errorTrack: function ({ errorType, errorDescription }) {
      AmplitudeEventTracker().trackEvent({
        name: AnalyticsEventDictionary.errorTrack,
        params: {
          [amplitudeParams.errorType]: errorType,
          [amplitudeParams.errorDescription]: JSON.stringify(
            JsonUtil().parseCircularJSON(errorDescription)
          ),
        },
      });
    },
    inStore: function ({ eventId }) {
      AmplitudeEventTracker().trackEvent({
        name: eventId,
      });
    },
    inStoreError: function ({ error, errorDescription }) {
      AmplitudeEventTracker().trackEvent({
        name: AnalyticsEventDictionary.merchantPortalChargedQROrderFailureViewLoaded,
        params: {
          [amplitudeParams.error]: error,
          [amplitudeParams.errorDescription]: errorDescription,
        },
      });
    },
  };
}
