/* eslint-disable no-unused-vars */
/* eslint-disable quotes */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const reimbursement_error_messages_es = require('./locales/es/reimbursement_error_messages.json');
const refund_error_messages_es = require('./locales/es/refund_error_messages.json');
const order_error_messages_es = require('./locales/es/order_error_messages.json');
const import_users_validations_es = require('./locales/es/import_users_validations.json');
const bulk_storesvalidations_es = require('./locales/es/import_users_validations.json');

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      es: {
        translation: {
          reimbursement: reimbursement_error_messages_es,
          refund: refund_error_messages_es,
          order: order_error_messages_es,
          users: import_users_validations_es,
          stores: bulk_storesvalidations_es,
        },
      },
    },
    lng: 'es', // if you're using a language detector, do not define the lng option
    fallbackLng: 'es',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
