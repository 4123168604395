import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { Table, TableMobile } from '../../ds';

const ResponsibleTableWrapper = styled.div`
  tr[aria-expanded='true'] {
    background-color: #fafafa;
    border-bottom: none;
  }
  tr {
    padding-bottom: 20px !important;
    margin-bottom: 20px;
  }

  ${props =>
    props.scrollingX &&
    css`
      @media (min-width: 1280px) and (max-width: 1440px) {
        overflow-x: auto;
        max-height: calc(100vh - 90px);
        margin-bottom: 40px;
      }
    `}
`;

const ResponsiveTable = ({ children, ...props }) => {
  const viewport = useDeviceContextViewport();
  const TableComponent = viewport.isMobile ? TableMobile : Table;
  const tableSize = viewport.isMobile ? 'small' : 'large';
  return (
    <ResponsibleTableWrapper className='hovered' scrollingX={props.scrollingX}>
      <TableComponent {...props} size={tableSize}>
        {children}
      </TableComponent>
    </ResponsibleTableWrapper>
  );
};

ResponsiveTable.propTypes = {
  children: PropTypes.node.isRequired,
  scrollingX: PropTypes.bool,
};

export { ResponsiveTable };
