export const canEmployeeEdit = (roles, employeeRole, userRole) => {
  if (!roles) return false;

  const employeeRoleObject = roles.filter(r => r.role_name === employeeRole)[0];
  const userRoleObject = roles.filter(r => r.role_name === userRole)[0];

  return (
    employeeRoleObject &&
    userRoleObject &&
    employeeRoleObject.role_hierarchy < userRoleObject.role_hierarchy
  );
};
