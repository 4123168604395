import styled, { css } from 'styled-components';
import KDS from '../../lib/k-ds-foundation';

const ButtonDefaults = styled.button`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${(props) => props.verticalPadding && props.horizontalPadding && css`
    padding: ${props.verticalPadding} ${props.horizontalPadding};
  `}

  border-radius: 32px;
  border: 0;

  box-sizing: border-box;
  ${KDS.setBoxShadowTokenWithFallback('shadow-button-default')}
  transition-duration: 300ms;

  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`;

export {
  ButtonDefaults,
};
