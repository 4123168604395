import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { SelectSearchable } from '../../ds/components/select/SelectSearchable';
import { Space } from '../../ds';
import './selectStore.scss';
import { observer } from 'mobx-react-lite';

const Option = ({ children, ...props }) => {
  return (
    <components.Option className='select-store-options k-ds-body-01' {...props}>
      {children}
    </components.Option>
  );
};

Option.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  isSelected: PropTypes.bool,
};

const Placeholder = ({ children, ...props }) => {
  return (
    <components.Placeholder {...props}>
      <span className='select-store-single-value k-ds-body-01'>{children}</span>
    </components.Placeholder>
  );
};

Placeholder.propTypes = {
  children: PropTypes.node,
};

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <span className='select-store-single-value k-ds-body-01'>{children}</span>
  </components.SingleValue>
);

SingleValue.propTypes = {
  children: PropTypes.node,
};

const MenuList = props => {
  return (
    <components.MenuList className='select-store-menu-list' {...props}>
      {props.children}
    </components.MenuList>
  );
};

MenuList.propTypes = {
  children: PropTypes.node,
};

const Control = ({ children, ...props }) => {
  const value = props.getValue();
  return (
    <components.Control
      className={`select-store-control ${value && value.length > 0 ? 'selected' : ''}`}
      {...props}
    >
      {children}
    </components.Control>
  );
};

Control.propTypes = {
  children: PropTypes.node,
  isSelected: PropTypes.bool,
  getValue: PropTypes.func,
};

const Menu = props => {
  return (
    <components.Menu className='select-store-menu' {...props}>
      {props.children}
    </components.Menu>
  );
};

Menu.propTypes = {
  children: PropTypes.node,
};

const SelectStores = ({
  label,
  required = false,
  className = '',
  stores = [],
  onChange = () => null,
  helper,
  hasError = false,
  errorMessage = '',
  placeholder = '',
  name = '',
  value = null
}) => {
  const storeOptions = stores.map(store => ({
    label: store.name,
    value: store.store_id,
  }));

  return (
    <Space
      size='small'
      direction='vertical'
      className={`select-store ${className}`}
    >
      {label && (
        <Space
          size='small'
          justify='space-between'
          className='k-ds-width-full'
          direction='horizontal'
        >
          <label
            className='k-ds-body-01 k-ds-text-color-primary'
            htmlFor={name}
          >
            {label}
            {required ? (
              <span className='k-ds-text-color-error'>{' *'}</span>
            ) : null}
          </label>
        </Space>
      )}
      <div>
        <SelectSearchable
          name={name}
          options={storeOptions}
          components={{
            Option: Option,
            Placeholder: Placeholder,
            SingleValue: SingleValue,
            Control: Control,
            MenuList: MenuList,
            Menu: Menu,
          }}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
        />
        {helper ? (
          <div className='helper-store k-ds-caption-01'>{helper}</div>
        ) : null}
        {hasError ? (
          <div className='helper-store helper-store-error k-ds-caption-01'>
            {errorMessage}
          </div>
        ) : null}
      </div>
    </Space>
  );
};

SelectStores.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  helper: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  stores: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.any,
      store_id: PropTypes.any,
    })
  ),
  value: PropTypes.object,
};

export default observer(SelectStores);
