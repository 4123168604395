import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { Space } from '../../ds';

const TdStyled = styled.td`
  ${props => (props.noWrap ? 'white-space: nowrap;' : '')}
  ${props => (props.wordBreakAll ? 'word-break: break-all;' : '')}
`;

TdStyled.propTypes = {
  noWrap: PropTypes.bool,
};

const TdWithHeader = ({ children, dataTitle, devices, ...props }) => {
  const { type } = useDeviceContextViewport();
  if (!devices?.split(' ').includes(type)) {
    return null;
  }
  return (
    <TdStyled data-title={dataTitle} {...props}>
      <Space direction='vertical' justify='start'>
        <div className='k-ds-label-bold'>{dataTitle}</div>
        <div className='k-ds-m-right-sp-06'>{children ?? '-'}</div>
      </Space>
    </TdStyled>
  );
};

TdWithHeader.propTypes = {
  children: PropTypes.node,
  dataTitle: PropTypes.string,
  devices: PropTypes.string,
};

export default TdWithHeader;
