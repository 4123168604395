import { Button, Modal, Space } from '../../ds';
import PropTypes from 'prop-types';
import React, { useContext, useMemo, useState } from 'react';
import { useToastContext } from '../../contexts/Toast/ToastContext';
import { Select } from '../DS/Select';
import { ReactComponent as CloseIcon } from './res/close-icon.svg';

import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import TagComponent from './BusinesComponents/TagStore';
import './BusinessSection.scss';
import { MutateUserContext } from './MutateUser';

const ModalAddStore = props => {
  const viewport = useDeviceContextViewport();
  const { setFormData } = useContext(MutateUserContext);
  const { merchantStores = [], closeModal } = props;
  const [extraStores, setExtraStores] = useState(() => {
    return props.storesSaved;
  });
  const toast = useToastContext();

  const isMobileOnly = useMemo(
    () => viewport.isMobile && !viewport.isSmallTablet,
    [viewport]
  );

  const addStore = storeValue => {
    const existValue = extraStores.findIndex(el => el === storeValue);

    if (existValue !== -1) {
      toast('la sucursal ya se encuentra seleccionada', 'warning');
      return;
    }

    setExtraStores(prev => [...prev, storeValue]);
  };

  const removeStore = index => {
    const arrayRemove = (arr, value) => {
      return arr.filter(function (ele) {
        return ele != value;
      });
    };

    setExtraStores(arrayRemove(extraStores, index));
  };

  const addStores = () => {
    setFormData(prev => ({ ...prev, stores: extraStores || [] }));
    closeModal();
  };

  return (
    <Modal
      className={
        isMobileOnly
          ? 'mobile-modal-store user-selection-modal '
          : 'user-selection-modal'
      }
      theme='dark'
      cardType='secondary'
      isOpen
    >
      <div className='k-ds-width-full'>
        <Space direction='horizontal' justify='end' className='k-ds-width-full'>
          <Button type='simple' size='small' onClick={closeModal}>
            <CloseIcon />
          </Button>
        </Space>
        <div className='k-ds-heading-04 k-ds-m-bottom-sp-07'>
          Agregar sucursal
        </div>

        <Select
          required
          placeholder='Seleccionar'
          label='Selecciona o escribe la sucursal:'
          onChange={addStore}
          id='stores'
          name='stores'
          options={merchantStores}
          type='text'
        />

        <div className='rowStores'>
          {extraStores.map(item => (
            <TagComponent
              key={item}
              storeId={item}
              merchantStores={merchantStores}
              remove={() => removeStore(item)}
            />
          ))}
        </div>

        <Space
          direction='horizontal'
          justify='end'
          className='k-ds-width-full k-ds-m-vertical-sp-07  '
        >
          <Button onClick={addStores}>Agregar</Button>
        </Space>
      </div>
    </Modal>
  );
};

ModalAddStore.propTypes = {
  merchantStores: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  storesSaved: PropTypes.array,
};

export default ModalAddStore;
