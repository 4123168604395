import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Auth from '../../Utils/Auth/auth';
import {
  GENERAL_ADMIN,
  MAX_BULK_ROWS,
  excelFileType,
} from '../../Utils/constants';
import { getJsonFromExcel, validateExcelFile } from '../../Utils/excelUtils';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { useToastContext } from '../../contexts/Toast/ToastContext';
import { Button, Modal, Space } from '../../ds';
import { requestUsersValidation } from '../../services/Application';
import userExportSchema, {
  defaultSchemaHeaders,
} from '../../services/userExportSchema';

import './addUserSelectionModal.scss';
import { ReactComponent as CloseIcon } from './res/close-icon.svg';

import { ReactComponent as GreenIcon } from './res/green-icon.svg';

import cn from 'classnames';
import { filterRoles } from '../../Utils/filterRoles';
import { phoneSpaceFormatted } from '../../Utils/formValidations';
import { useGetMerchant } from '../../Utils/useGetMerchant';
import LoaderModal from '../LoaderModal/LoaderModal';
import UploadZone from '../UploadZone/UploadZone';
import FileUploaded from './FileUploaded';
import AddUserSelection from './FirstSection';
import { downloadTemplate, getHeaders, translatedErrors } from './utils';
import AnalyticsEvent from '../../services/analytics/AnalyticsEvent';

const initialValuesFileInput = {
  fileName: undefined,
  json: undefined,
  isUploadStage: false,
};

const translationPrefix = 'users.';
function AddUserSelectionModal(props) {
  const { roles, rolesNames } = props;
  let history = useHistory();
  const addToast = useToastContext();
  const viewport = useDeviceContextViewport();

  const [fileInputState, setFileInputState] = useState(initialValuesFileInput);
  const [messageError, setMessageError] = useState('');
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const isMobileOnly = useMemo(
    () => viewport.isMobile && !viewport.isSmallTablet,
    [viewport]
  );

  const handleFilesChange = async files => {
    setIsLoadingFile(true);
    const file = files[0];

    if (file.type !== excelFileType) {
      addToast(
        'El archivo debe ser un archivo de Excel con formato .xlsx',
        'error'
      );
      setIsLoadingFile(false);
      props.close();
      return;
    }

    const schemaKeys = userExportSchema?._nodes ?? defaultSchemaHeaders;
    const validFile = await validateExcelFile(file, schemaKeys);

    if (!validFile) {
      setMessageError('Verifica que sea el archivo correcto.');
      setIsLoadingFile(false);
      return;
    }

    const json = await getJsonFromExcel(file);
    if (json.length > MAX_BULK_ROWS) {
      setMessageError('El sistema acepta hasta 80 filas de datos por archivo.');
      setIsLoadingFile(false);
      return;
    }
    for (const user of json) {
      try {
        await userExportSchema.validate(user, { abortEarly: false });
        user['is_valid'] = true;
      } catch (e) {
        user['is_valid'] = false;
      }
    }

    setTimeout(() => {
      setFileInputState({
        ...fileInputState,
        fileName: file.name ?? '',
        json: json,
      });
      setMessageError('');
      setIsLoadingFile(false);
    }, 1000);
  };

  const UploadUsers = () => {
    const { employee = {}, stores = [] } = Auth.userData();
    const { t } = useTranslation();
    const merchantRoles = filterRoles(roles, employee.role, '');

    const userMerchant = useGetMerchant();
    const merchantId = userMerchant.merchant_id;

    const isMobileOnly = viewport.width <= 585;

    const merchantStores =
      stores.map(store => ({
        store_name: store.name.toLowerCase(),
        store_id: store.store_id,
      })) ?? undefined;

    return (
      <div>
        {isLoadingFile || isUploading ? (
          <LoaderModal
            isOpen
            customLoader={
              isUploading ? (
                <div className='centerCustomIcon'>
                  <GreenIcon />
                </div>
              ) : null
            }
            header='Estamos cargando el archivo, no actualices la ventana.'
            content='Esto puede tardar unos momentos...'
          />
        ) : (
          <>
            <Space
              direction='horizontal'
              justify='end'
              className='k-ds-width-full'
            >
              <Button
                type='link'
                className='custom-exit-button'
                size='small'
                onClick={handleClose}
              >
                <CloseIcon />
              </Button>
            </Space>
            <Space
              direction='vertical'
              className={`${
                fileInputState.fileName
                  ? 'upload-modal'
                  : isMobileOnly
                    ? 'mobile-modal'
                    : ''
              } selection-container`}
            >
              <div className='k-ds-heading-05 k-ds-m-bottom-sp-05'>
                Subir archivo
              </div>
              {fileInputState.fileName ? (
                <FileUploaded
                  fileName={fileInputState.fileName}
                  isMobileOnly={isMobileOnly}
                  handleClose={handleClose}
                  handleUpload={handleUpload}
                  handleDeleteSelection={handleDeleteSelection}
                />
              ) : (
                <UploadZone
                  messageError={messageError}
                  handleFilesChange={handleFilesChange}
                />
              )}
            </Space>
          </>
        )}
      </div>
    );

    function handleUpload() {
      setIsUploading(true);

      const schemaHeaders = getHeaders();

      let users = [];

      fileInputState.json.map((userInfo, index) => {
        let user = {
          temporal_id: index,
          merchant_id: merchantId,
          first_name:
            typeof userInfo[schemaHeaders.name] === 'string'
              ? userInfo[schemaHeaders.name]
              : '',
          last_name:
            typeof userInfo[schemaHeaders.lastName] === 'string'
              ? userInfo[schemaHeaders.lastName]
              : '',
          maiden_name:
            typeof userInfo[schemaHeaders.secondLastName] === 'string'
              ? userInfo[schemaHeaders.secondLastName]
              : '',
          email:
            typeof userInfo[schemaHeaders.email] === 'string'
              ? userInfo[schemaHeaders.email].toLowerCase()
              : '',
          user_code: '',
          role_name: '',
          role_id: '',
          store_name: '',
          stores: [],
          phone_number: phoneSpaceFormatted(
            userInfo[schemaHeaders.phone]?.toString() || ''
          ),
          curp:
            typeof userInfo[schemaHeaders.UPRC] === 'string'
              ? userInfo[schemaHeaders.UPRC]
              : '',
          is_valid: userInfo.is_valid,
          errors: userInfo.errors || [],
        };

        const rolesFound = merchantRoles?.find(
          role => role.text === userInfo[schemaHeaders.role]
        );

        const roleName = rolesFound?.name === GENERAL_ADMIN;
        const roleId = rolesFound?.value;

        if (roleId && !roleName) {
          user.role_id = roleId.toString();
          user.role_name = userInfo[schemaHeaders.role];
        } else {
          user.role_name = '';
          user.is_valid = false;
        }
        const storeId = merchantStores?.find(
          store =>
            store.store_name ===
            userInfo[schemaHeaders.storeName]?.toLowerCase()
        )?.store_id;

        if (storeId) {
          let stores = user.stores;
          stores.push(storeId);
          user.stores = stores;
          user.store_name = userInfo[schemaHeaders.storeName];
        } else {
          user.store_name = '';
          user.is_valid = false;
        }
        users.push(user);
      });

      const data = {
        users: users,
      };

      requestUsersValidation(data)
        .then(response => {
          if (response.status === 'success') {
            users.map(user => {
              user['is_valid'] = true;
            });
          }
        })
        .catch(error => {
          const errors = error?.response?.data?.errors?.users || [];

          AnalyticsEvent().errorTrack({
            errorType: 'add-user-upload-file-error',
            errorDescription: error,
          });

          users.map((user, index) => {
            if (errors[index]) {
              user['is_valid'] = false;
              user['errors'] = translatedErrors(
                t,
                translationPrefix,
                errors[index]
              );
            }
          });
        })
        .finally(() => {
          setIsUploading(false);
          props.close();
          if (users) goPage('/users/import', users);
        });
    }
  };

  const handleDeleteSelection = () => {
    setFileInputState({
      ...fileInputState,
      json: undefined,
      fileName: undefined,
    });
  };

  const handleClose = () => {
    setFileInputState(initialValuesFileInput);
    props.close();
  };

  return (
    props.defaultOpened && (
      <Modal
        className={cn('user-selection-modal', {
          ['mobile-modal']: isMobileOnly,
        })}
        theme='dark'
        cardType='secondary'
        onClose={handleClose}
        onOverlayClick={handleClose}
        {...{ isOpen: props.defaultOpened }}
      >
        <div className='k-ds-width-full'>
          {fileInputState && fileInputState.isUploadStage ? (
            <UploadUsers />
          ) : (
            <AddUserSelection
              isMobileOnly={isMobileOnly}
              goToAddUser={goToAddUser}
              goToUploadUsers={goToUploadUsers}
              downloadTemplate={() => downloadTemplate(rolesNames)}
              handleClose={handleClose}
            />
          )}
        </div>
      </Modal>
    )
  );

  function goToAddUser() {
    goPage('/user/new');
  }

  function goToUploadUsers() {
    setFileInputState({
      ...fileInputState,
      isUploadStage: true,
    });
  }

  function goPage(path, data) {
    history.push({
      pathname: path,
      data: data,
    });
    const contentArea = document.getElementById('content-area');
    contentArea.scrollTop = 0;
  }
}

AddUserSelectionModal.propTypes = {
  defaultOpened: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  rolesNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  roles: PropTypes.array.isRequired,
};

export default AddUserSelectionModal;
