import React, { useState } from 'react';
import ActivationAccount from './ActivationAccount';
import CompletedAccount from './CompletedAccount';
import FormAccount from './FormAccount';
import Layout from './Layout';

const CreateAccount = () => {
  const [step, setStep] = useState(0);
  const handleNextUpActivation = () => setStep(1);
  const handleNextUpCompleted = () => setStep(2);

  return (
    <Layout hideArrowBack={step === 2}>
      {step === 0 && <FormAccount handleNextStep={handleNextUpActivation} />}

      {step === 1 && (
        <ActivationAccount handleNextStep={handleNextUpCompleted} />
      )}

      {step === 2 && <CompletedAccount />}
    </Layout>
  );
};

export default CreateAccount;
