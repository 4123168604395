import { useEffect, useState } from 'react';
import { usePermissionContext } from '../../contexts/Role/PermissionContext';
import { OTP_REQUIRED, loginErrors } from '../constants';
import { useQueryClient } from '@tanstack/react-query';

import Auth from './auth';
import AnalyticsEvent from '../../services/analytics/AnalyticsEvent';
import { useStore } from '../../services/store';

export const useProvideAuth = () => {
  const { merchantTransactionsReport } = useStore();
  const queryClient = useQueryClient();
  const [user, setUser] = useState(null);
  const { loadPermissions, clearPermissions, setHotjarIdentified } =
    usePermissionContext();

  const getEmployeeInfo = (data, loginData) => {
    if (data && data.employee && loginData && loginData.email) {
      return {
        ...data.employee,
        email: loginData.email,
      };
    }

    return data.employee;
  };

  const login = async loginData => {
    try {
      const response = await Auth.login(loginData);
      Auth.saveAuthInfo({
        ...response,
        employee: getEmployeeInfo(response, loginData),
      });
      setUser(response);
      loadPermissions();
      merchantTransactionsReport.resetStore();
      AnalyticsEvent().logInComplete();
      return response;
    } catch (error) {
      if (error?.response?.data?.message === OTP_REQUIRED) {
        AnalyticsEvent().errorTrack({
          errorType: OTP_REQUIRED,
          errorDescription: error.response.data,
        });

        return { error: error.response.data };
      }

      AnalyticsEvent().errorTrack({
        errorType: 'login-error',
        errorDescription: error,
      });

      return {
        error:
          loginErrors[error.code] ||
          'Encontramos un error.\n¿Lo puedes volver a intentar?',
        data: error,
      };
    }
  };

  const passwordRecover = recoverData => {
    return Auth.passwordRecover(recoverData)
      .then(response => {
        return response;
      })
      .catch(error => {
        AnalyticsEvent().errorTrack({
          errorType: 'passwordRecover-error',
          errorDescription: error,
        });

        console.log(error);
        return { error: 'Ocurrio un error: intente de nuevo' };
      });
  };

  const logout = () => {
    setHotjarIdentified(false);
    return Auth.logout()
      .then(() => {
        queryClient.removeQueries();
        clearPermissions();
        setUser(false);
      })
      .catch(error => {
        AnalyticsEvent().errorTrack({
          errorType: 'logout-error',
          errorDescription: error,
        });
      });
  };

  useEffect(() => {
    if (Auth.userLoggedIn()) {
      setUser(Auth.userData());
    } else {
      setUser(false);
    }
  }, []);

  return {
    user,
    login,
    logout,
    passwordRecover,
  };
};
