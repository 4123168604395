import styled from 'styled-components';
import React from 'react';
import { ReactComponent as IconInformation } from './icon-information.svg';
import { ReactComponent as IconClose } from './icon-close.svg';
import KDS from '../../lib/k-ds-foundation';
import { ToastBase } from './ToastBase';

const StyledToastInformation = styled(ToastBase)`
  ${KDS.setColorTokenWithFallback('border: 2px solid ', 'text-link-primary')};
`;

const ToastInformation = ({
  message,
  onClick,
  ...moreProps
}) => (
  <StyledToastInformation {...moreProps}>
    <IconInformation className="toast-icon" />
    <p className="k-ds-heading-01 toast-message">{message}</p>
    <button type="button" className="toast-close" onClick={onClick}>
      <IconClose />
    </button>
  </StyledToastInformation>
);

export {
  ToastInformation,
};
