import { useEffect } from 'react';

export default function ScrollToTop() {
  useEffect(() => {
    const contentArea = document.getElementById('content-area');
    contentArea.scrollTo(0, 0);
  }, []);

  return null;
}
