import cn from 'classnames';
import React from 'react';
import styles from './banners.module.scss';
import Banner from './res/image1.png';

const Banner1 = () => {
  return (
    <div className={styles.banner1Container}>
      <div className={styles.imageContainer}>
        <img src={Banner} />
      </div>
      <div className={styles.infoContainer}>
        <div className={cn(styles.header, styles.infoPadding)}>
          Aumenta tus ventas y llega a más clientes
        </div>
        <div className={styles.infoText}>
          Acepta pagos sin tarjeta de crédito. Olvídate de los riesgos y
          contracargos en tu tienda en línea o física.
        </div>
      </div>
    </div>
  );
};

export default Banner1;
