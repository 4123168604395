import * as amplitude from '@amplitude/analytics-browser';
import { isProductionStage } from '../../../Utils/environment';

const notApiKeyString = 'not-api-key-configured';
const defaultAmplitudeLogLevel = '2';
const developmentAplitudeLevel = '4';

let isProduction = isProductionStage();

const AmplitudeInitializerProptypes = {
  initialized: false,
  run: function () {
    if (this.initialized) {
      return;
    }

    const envAmplitudeKey = isProduction
      ? process.env['REACT_APP_AMPLITUDE_API_KEY_PRODUCTION']
      : process.env['REACT_APP_AMPLITUDE_API_KEY'];

    const currentLogLevel = Number.parseInt(
      process.env['REACT_APP_AMPLITUDE_LOG_LEVEL'] || defaultAmplitudeLogLevel
    );

    const amplitudeApiKey = envAmplitudeKey || notApiKeyString;
    amplitude.init(amplitudeApiKey, {
      defaultTracking: false,
      logLevel: isProduction
        ? currentLogLevel
        : Number(developmentAplitudeLevel),
    });

    this.initialized = true;
  },
};

export default function AmplitudeInitializer() {
  return Object.create(AmplitudeInitializerProptypes);
}
