import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Space } from '../../ds';
import { Modal } from '../../ds';
import './downloadCsvModal.scss';
import { ReactComponent as CloseIcon } from './close-icon.svg';
import { ReactComponent as SuccessIcon } from './success-icon.svg';
import { ReactComponent as ErrorIcon } from './error-icon.svg';
import { ReactComponent as DownloadIcon } from './Download.svg';
import LoaderBlack from '../../containers/pages/Layout/loader-black-2x.gif';
import { itemsToCsv } from '../../Utils/Export/csv';
import { formatDate } from '../../Utils/formats';

const DownloadCsvModal = props => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [csvToDownload, setCsvToDownload] = useState(undefined);
  const [items, setItems] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [percentageArray, setPercentageArray] = useState([]);
  const [link, setLink] = useState(document.createElement('a'));

  useEffect(() => {
    if (props?.content?.length > 0) setItems(props.content);
  }, [props.content]);

  useEffect(() => {
    try {
      if (props.defaultOpened) {
        const { csv, temporalPercentageArray } = itemsToCsv(items);
        setPercentageArray(temporalPercentageArray);
        setCsvToDownload(csv);
      }
    } catch (error) {
      console.log(error);
      setHasError(true);
      stopLoading();
    }
  }, [props.defaultOpened]);

  function stopLoading() {
    setPercentage(100);
    setIsLoading(false);
  }

  useEffect(() => {
    if (!isLoading && !hasError) handleDownload();
  }, [isLoading]);

  useEffect(() => {
    if (percentage >= 100) stopLoading();
  }, [percentage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (percentageArray.length > 0 && isLoading) {
        const actual = percentageArray.shift();
        setPercentage(actual);
        const arrayUpdated = percentageArray.filter(item => item !== actual);
        setPercentageArray(arrayUpdated);
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [percentageArray]);

  function onClose() {
    setIsLoading(true);
    setHasError(false);
    setCsvToDownload(undefined);
    setPercentage(0);
    setPercentageArray([]);
    setItems([]);
    setLink(null);
    props.close();
  }

  function handleDownload() {
    link.id = 'download-csv';
    link.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(csvToDownload)
    );
    link.setAttribute(
      'download',
      `${props.filename}_${formatDate(new Date())}${props.format}`
    );
    document.body.appendChild(link);
    document.querySelector('#download-csv').click();
    link.remove();
  }

  const modalInformation = isLoading ? (
    <>
      <Space direction='vertical' align='center'>
        <img width={200} src={LoaderBlack} alt='loading...' />
        <Space className='k-ds-heading-06 k-ds-m-bottom-sp-06'>
          {percentage.toFixed()}%
        </Space>
        <Space className='k-ds-body-02'>Estamos creando tu reporte...</Space>
      </Space>
    </>
  ) : !isLoading && !hasError ? (
    <>
      <Space direction='vertical' align='center'>
        <SuccessIcon className='k-ds-m-top-sp-09 k-ds-m-bottom-sp-05' />
        <Space className='k-ds-heading-06 k-ds-m-bottom-sp-09 success-message'>
          Reporte creado con éxito
        </Space>
        <Space className='k-ds-body-02 k-ds-m-bottom-sp-05'>
          ¿Tu reporte no se descargó automáticamente?
        </Space>
        <Button
          className='k-ds-m-bottom-sp-05'
          type='link'
          size='small'
          onClick={() => handleDownload()}
        >
          <DownloadIcon className='k-ds-m-right-sp-04' /> Descargar reporte
        </Button>
      </Space>
    </>
  ) : !isLoading && hasError ? (
    <>
      <Space direction='vertical' align='center'>
        <ErrorIcon className='k-ds-m-top-sp-09 k-ds-m-bottom-sp-09' />
        <Space className='k-ds-heading-06 k-ds-m-bottom-sp-06 success-message'>
          El reporte no pudo ser creado
        </Space>
        <Space className='k-ds-body-02 k-ds-m-bottom-sp-06 error'>
          Ocurrió un problema al crear el reporte,
          <br />
          ¿Podrías volver a intentarlo?
        </Space>
      </Space>
    </>
  ) : (
    <></>
  );

  return props.defaultOpened ? (
    <Modal
      className='download-csv-modal'
      theme='dark'
      cardType='secondary'
      onClose={() => props.close()}
      {...{ isOpen: props.defaultOpened ? true : false }}
    >
      <div className='k-ds-width-full'>
        {(props.title || hasError || !isLoading) && (
          <Space direction='horizontal' className='close-container'>
            <Space className='k-ds-width-full k-ds-m-left-sp-04 k-ds-m-top-sp-04'>
              {props.title}
            </Space>
            <Button
              className='close-button'
              type='link'
              size='small'
              onClick={() => onClose()}
            >
              <CloseIcon />
            </Button>
          </Space>
        )}
        <Space className='content-container'>{modalInformation}</Space>
      </div>
    </Modal>
  ) : null;
};

DownloadCsvModal.propTypes = {
  defaultOpened: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  filename: PropTypes.string,
  content: PropTypes.any,
  format: PropTypes.string,
  title: PropTypes.string,
};

export default DownloadCsvModal;
