import PropTypes from 'prop-types';
import React, { memo, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useAuth } from '../../../Utils/Auth/use-auth';
import { isFeatureEnabled } from '../../../Utils/Feature/feature_flag';
import {
  validateEmailField,
  validatePasswordField,
} from '../../../Utils/formValidations';
import { useLoaderContext } from '../../../contexts/Loader/LoaderContext';
import { usePermissionContext } from '../../../contexts/Role/PermissionContext';
import { Button, PasswordInput, TextInput } from '../../../ds';
import { ReactComponent as AlarmIcon } from './alarm.svg';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AnalyticsEvent, {
  AnalyticsList,
} from '../../../services/analytics/AnalyticsEvent';

const termsLink = 'https://kueski.com/terminosdeuso';
const policiesLink = 'https://kueski.com/politicas';
const passwordRecoverLink = '/passwordrecover';
const isCaptchaEnabled = isFeatureEnabled('captcha');

const Form = ({ handleRedirect, handleErrors, loginData, setLoginData }) => {
  // eslint-disable-next-line no-undef
  const auth = useAuth();
  const history = useHistory();
  const recaptchaRef = useRef();
  const isTracked = useRef(false);

  const [loginErrors, setLoginError] = useState({
    form: '',
    email: '',
    password: '',
  });

  const { setIsLoaderOpen } = useLoaderContext();
  const { loadPermissions } = usePermissionContext();

  // const submitDisabled = () => {
  //   const { email, password } = loginErrors;
  //   const noErrorMessages =
  //     Object.values({ email, password }).filter((v) => v !== '').length === 0;
  //   const AllFieldsFilled =
  //     Object.values(loginData).filter((v) => v.length > 0).length ===
  //     Object.keys(loginData).length;
  //   return !(noErrorMessages && AllFieldsFilled);
  // };

  const handleSubmit = e => {
    e.preventDefault();

    const emptyEmail = loginData.email === '';
    const emptyPass = loginData.password === '';

    if (emptyEmail || emptyPass) {
      setLoginError({
        ...loginErrors,
        email: emptyEmail
          ? 'Este campo no puede quedar vacío.\n¿Lo puedes completar?'
          : '',
        password: emptyPass
          ? 'Este campo no puede quedar vacío.\n¿Lo puedes completar?'
          : '',
        form: '',
      });
      return;
    }

    if (isCaptchaEnabled && loginData.g_recaptcha_response === '') {
      setLoginError({
        ...loginErrors,
        form: 'completa el captcha',
      });
      return;
    }

    setIsLoaderOpen(true);
    auth.login(loginData).then(response => {
      setIsLoaderOpen(false);
      if (response?.error) {
        recaptchaRef?.current?.reset();
        if (handleErrors(response)) {
          return;
        }
        return setLoginError({ ...loginErrors, form: response.error });
      }

      loadPermissions();
      handleRedirect(response);
    });
  };

  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const handleEmailChange = e => {
    const email = e.target.value;
    setLoginData({ ...loginData, email: email ? email.trim() : '' });
  };

  const handlePasswordChange = e => {
    const password = e.target.value;
    setLoginData({ ...loginData, password: password });
  };

  const emailValidation = formValue => {
    if (formValue.length === 0) {
      setLoginError({
        ...loginErrors,
        email: 'Este campo no puede quedar vacío.\n¿Lo puedes completar?',
        form: '',
      });
      return;
    }

    let email = validateEmailField(
      formValue,
      'Asegúrate de que tu correo tenga el siguiente formato: hola@ejemplo.com'
    );
    setLoginError({ ...loginErrors, email: email, form: '' });
  };

  const passwordValidation = formValue => {
    if (formValue.length === 0) {
      setLoginError({
        ...loginErrors,
        password: 'Este campo no puede quedar vacío.\n¿Lo puedes completar?',
        form: '',
      });
    }

    let password = validatePasswordField(formValue, '6 caracteres mínimo');
    setLoginError({ ...loginErrors, password: password, form: '' });
  };

  const onCaptchaChange = captchaValue => {
    setLoginData({ ...loginData, g_recaptcha_response: captchaValue || '' });
  };

  const onPasswordRecover = () => {
    history.push(passwordRecoverLink);
  };

  useEffect(() => {
    if (!isTracked.current) {
      AnalyticsEvent().pageView({ page: AnalyticsList.logIn });
      isTracked.current = true;
    }
  }, []);

  return (
    <div className='greeting'>
      <div className='card-link'>
        <AlarmIcon className='custom-svg' />
        <p>
          ¿Quieres Kueski Pay para tu comercio?{' '}
          <a target='_blank' rel='noreferrer' className='link' href='/benefits'>
            Regístrate aquí.
          </a>
        </p>
      </div>
      <div className='container-form'>
        <div className='k-ds-heading-06'>¡Hola!</div>
        <p>Inicia sesión para continuar</p>
        <form className='login-form k-ds-m-top-sp-07' onSubmit={handleSubmit}>
          <TextInput
            className={
              'k-ds-width-full' && loginErrors.form ? 'border-error' : ''
            }
            errorMessage={loginErrors.email}
            label='Correo electrónico'
            onChange={handleEmailChange}
            onBlur={e => emailValidation(e.target.value)}
            placeholder='correo@ejemplo.com.mx'
            type='outline'
            value={loginData.email}
          />
          <PasswordInput
            className={
              'k-ds-width-full' && loginErrors.email
                ? 'k-ds-m-top-sp-07'
                : 'k-ds-m-top-sp-05' && loginErrors.form
                  ? 'border-error'
                  : ''
            }
            label='Contraseña'
            onChange={handlePasswordChange}
            onBlur={e => passwordValidation(e.target.value)}
            placeholder='&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;'
            value={loginData.password}
            type='outline'
          />
          <div className='k-ds-width-full k-ds-m-top-sp-03 password-message-container'>
            {loginErrors.password !== '' && (
              <div className='k-ds-caption-01 login-error-message'>
                {' '}
                {loginErrors.password}{' '}
              </div>
            )}
            <div
              className={
                loginErrors.password !== ''
                  ? 'login-forgot-pass login-error-message'
                  : 'login-forgot-pass k-ds-width-full'
              }
            >
              <a
                href='#'
                onClick={() => onPasswordRecover()}
                className='k-ds-heading-01'
              >
                ¿Olvidaste tu contraseña?
              </a>
            </div>
          </div>
          {loginErrors.form !== '' && (
            <div className='k-ds-width-full k-ds-m-top-sp-06 k-ds-m-bottom-sp-03'>
              <div className='k-ds-heading-01 login-error-message'>
                {loginErrors.form}
              </div>
            </div>
          )}
          {isCaptchaEnabled ? (
            <ReCAPTCHA
              className='k-ds-m-top-sp-08 k-ds-m-bottom-sp-04'
              ref={recaptchaRef}
              sitekey={recaptchaSiteKey}
              hl='es-419'
              onChange={onCaptchaChange}
            />
          ) : null}
          <Button
            className='k-ds-m-top-sp-05'
            borderWidth=''
            horizontalPadding=''
            size='small'
            type='primary'
            verticalPadding='16px'
            onClick={handleSubmit}
          >
            Iniciar sesión
          </Button>
        </form>
        <div className='terms k-ds-m-top-sp-05'>
          Al iniciar sesión, aceptas los
          <Button
            className='terms'
            type='link'
            size='small'
            onClick={() => window.open(termsLink, '_blank')}
          >
            Términos del Servicio
          </Button>
          y
          <Button
            className='terms'
            type='link'
            size='small'
            onClick={() => window.open(policiesLink, '_blank')}
          >
            Aviso de Privacidad
          </Button>
          de Kueski.
        </div>
      </div>
    </div>
  );
};

Form.propTypes = {
  handleRedirect: PropTypes.func.isRequired,
  handleErrors: PropTypes.func.isRequired,
  loginData: PropTypes.object.isRequired,
  setLoginData: PropTypes.func.isRequired,
};

const FormLogin = memo(Form);

export default FormLogin;
