import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import Auth from '../../Utils/Auth/auth';
import myOwnRoutes from '../../containers/routes/index';

const scope_channels = ['payment_code', 'generate_orders'];

const scope_history = [
  'merchant_transactions',
  'history_orders',
  'merchants_payments',
];

const configScopes = ['stores', 'users', 'campaigns'];

const defaultContext = {
  loadPermissions: () => {},
  getPermission: () => {},
  menus: [],
  routes: [],
  openModalRoot: false,
  openPaymentUpgrade: false,
  hotjarIdentified: false,
  setOpenModalRoot: () => {},
  setOpenPaymentUpgrade: () => {},
  clearPermissions: () => {},
  setHotjarIdentified: () => {},
};

const PermissionContext = React.createContext(defaultContext);

const PermissionProvider = ({ children }) => {
  const [permissionScopes, setPermissionScopes] = useState([]);
  const [menus, setMenus] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [openModalRoot, setOpenModalRoot] = useState(false);
  const [openPaymentUpgrade, setOpenPaymentUpgrade] = useState(false);
  const [hotjarIdentified, setHotjarIdentified] = useState(false);

  const { merchant } = Auth.userData();
  const merchantChannels = merchant?.channels || [];

  const getPermission = module => {
    const permission = permissionScopes?.find(
      scope => scope.module_name === module
    );
    const hasScope = scope => {
      return permission.scopes.includes(scope);
    };
    if (!permission) return null;
    return { ...permission, hasScope };
  };

  useEffect(() => {
    if (Auth.userLoggedIn()) resolvePermissions(permissionScopes);
  }, [permissionScopes]);

  useEffect(() => {
    loadPermissions();
  }, []);

  const loadPermissions = () => {
    const { permission_scopes } = Auth.userData();
    if (!Auth.userLoggedIn()) return;

    resolvePermissions(permission_scopes);
    setPermissionScopes(permission_scopes);
  };

  const clearPermissions = () => {
    setPermissionScopes([]);
    setMenus([]);
    setRoutes([]);
    setHotjarIdentified(false);
  };

  const resolvePermissions = permissions => {
    if (!Auth.userLoggedIn()) return;

    const { menus, routes } = myOwnRoutes(
      scope_channels,
      scope_history,
      configScopes,
      permissions,
      merchantChannels
    );
    setMenus(menus);
    setRoutes(routes);
    return;
  };

  return (
    <PermissionContext.Provider
      value={{
        openModalRoot,
        hotjarIdentified,
        openPaymentUpgrade,
        setOpenPaymentUpgrade,
        setOpenModalRoot,
        getPermission,
        loadPermissions,
        clearPermissions,
        setHotjarIdentified,
        menus,
        routes,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

PermissionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const usePermissionContext = () => {
  const context = React.useContext(PermissionContext);
  if (context === undefined) {
    throw new Error(
      'usePermissionContext must be used within a PermissionProvider'
    );
  }
  return context;
};

export { PermissionProvider, usePermissionContext };
