import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDeviceContextViewport } from '../../../contexts/Device/DeviceContext';
import { TextCopy, Modal, Space } from '../../../ds';
import UserPhone from './icon-phone-user.jpg';
import './PaymentLinkShareModal.scss';

const PaymentLinkShareModal = ({ isOpen, paymentUrl, onClose = () => null }) => {
  const viewport = useDeviceContextViewport();

  const isMobileOnly = useMemo(
    () => viewport.isMobile && !viewport.isSmallTablet,
    [viewport]
  );

  const maxChars = viewport.isMobile ? Math.ceil(viewport.width / 25) : 0;

  return (
    <Modal
      showClose
      className={cn('share-payment-link', {
        ['mobile-modal']: isMobileOnly,
      })}
      theme='dark'
      cardType='secondary'
      onClose={onClose}
      onOverlayClick={onClose}
      {...{ isOpen: isOpen }}
    >
      <div className='k-ds-width-full'>
        <Space
          direction='vertical'
          className='k-ds-body-02'
          justify='center'
          align='center'
        >
          <img
            className='k-ds-m-bottom-sp-05'
            width={70}
            src={UserPhone}
            alt='phone-user'
          />
          <div className='k-ds-heading-05'>
            Comparte el enlace para continuar la compra
          </div>
        </Space>
        <Space className='k-ds-body-02 k-ds-m-top-sp-07' justify='center'>
          Puedes compartir el enlace con el cliente por correo electrónico, SMS
          o WhatsApp. Si el cliente no tiene conexión a internet, no podrás usar
          este método.
        </Space>
        <Space className='k-ds-width-full k-ds-m-top-sp-09' justify='center'>
          <TextCopy
            className='share-link-copy k-ds-caption-01'
            buttonText='Copiar'
            textToCopy={paymentUrl}
            maxChars={maxChars}
            type='b2b'
          />
        </Space>
      </div>
    </Modal>
  );
};

PaymentLinkShareModal.propTypes = {
  paymentUrl: PropTypes.string.isRequired,
  maxChars: PropTypes.any,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default PaymentLinkShareModal;
