import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button } from '../../ds';
import { ReactComponent as BackArrowIcon } from './arrow-left-line.svg';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import './backPageButton.scss';

function BackPageButton({ redirectUri = '', callback }) {
  const router = useHistory();
  const viewport = useDeviceContextViewport();
  const { isMobile, isTablet } = viewport;
  const [isHovering, setIsHovering] = React.useState(false);

  function goBackToStores() {
    if (callback) {
      callback();
      return;
    }

    if (redirectUri) {
      router.push(redirectUri);
      return;
    }
    router.goBack();
  }

  return (
    <Button
      type='simple'
      className={`back-page-button ${isMobile || isTablet ? 'k-ds-m-top-sp-06' : ''}`}
      horizontalPadding='0px'
      verticalPadding='0px'
      onClick={goBackToStores}
      onMouseOver={() => setIsHovering(true)}
      onMouseOut={() => setIsHovering(false)}
    >
      <BackArrowIcon
        className='k-ds-m-right-sp-04'
        fill={isHovering ? '#3A22A4' : '#18223D'}
      />
      Regresar
    </Button>
  );
}

BackPageButton.propTypes = {
  redirectUri: PropTypes.string,
  callback: PropTypes.func,
};

export default BackPageButton;
