import React, { useState, useEffect } from 'react';
import { Card, Space } from '../../ds';
import { useParams } from 'react-router-dom';
import { useLoaderContext } from '../../contexts/Loader/LoaderContext';
import { getUser } from '../../mockData';
import { getOrderGenerated } from '../../services/Application';
import RenderBarcode from '../RenderBarcode';
import './paymentGeneratedByQR.scss';
import { ReactComponent as Clock } from './icon-clock.svg';
import { goPage } from '../../Utils/pages';
import Error from './Error/Error';
import GetBackSection from './../GetBackSection';
import { useHistory } from 'react-router-dom';
import { orderVerdict } from '../../services/Application';
import {
  notApplicableOrderStaffId,
  OFFLINE_MODE,
  ONLINE_MODE,
} from '../../Utils/constants';
import ScrollToTop from './../ScrollToTop/ScrollToTop';
import PaymentLinkShare from './PaymentLinkShare';
import TransactionSummary from './TransactionSummary';
import ButtonActions from './ButtonActions';
import OneTimePasscodeVerification from './OneTimePasscodeVerification/OneTimePasscodeVerification';
import OrderStatusHandler from './OrderStatusHandler';
import NotFound from '../NotFound';
import Auth from '../../Utils/Auth/auth';
import AnalyticsEvent from '../../services/analytics/AnalyticsEvent';
import { useStore } from '../../services/store';

const INIT_ORDER_GENERATED_SERVICE = {
  order_reference: '',
  total: '',
  items_count: 0,
  paymentId: '',
  paymentUrl: '',
  paymentQR: '',
  totalFormatted: '',
  status: '',
};

const TRANSACTION_STEPS = {
  QR_CODE: 'qr_code',
  OTP: 'otp',
};

const PaymentGeneratedByQRv2 = () => {
  const user = getUser();
  const { stores } = Auth.userData();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isReturnModalOpen, setIsReturnModalOpen] = useState();
  const [unauthorized, setUnauthorized] = useState(false);
  const [transactionStep, setTransactionStep] = useState(
    TRANSACTION_STEPS.QR_CODE
  );
  const [transactionProgressOpen, setTransactionProgressOpen] = useState(false);

  const { setIsLoaderOpen } = useLoaderContext();

  const [orderGeneratedService, setOrderGeneratedService] = useState(
    INIT_ORDER_GENERATED_SERVICE
  );
  const { orderId, paymentMode } = useParams();
  const { qrCodeStore: { storeId, reload }} = useStore();
  const selectedStoreId = storeId ? storeId : user.storeId();

  useEffect(() => {
    if (stores && !stores.find(store => store.store_id === storeId)) {
      setUnauthorized(true);
      return;
    }

    setIsLoaderOpen(true);
    getOrderGenerated(orderId, selectedStoreId)
      .then(response => {
        setUnauthorized(false);
        const { order } = response.data;
        setOrderGeneratedService({
          staffId:
            order.staff_id !== notApplicableOrderStaffId ? order.staff_id : '',
          order_reference: order.order_reference,
          total: order.total,
          totalFormatted: order.total_formatted,
          items_count: order.items_count,
          paymentId: order.payment_attributes.payment,
          paymentUrl: order.payment_attributes.payment_url,
          paymentQR: order.payment_attributes.payment_url_qr,
          status: order.kueski_authorization,
        });
        setIsLoading(false);
        setIsLoaderOpen(false);
      })
      .catch(
        error => {
          AnalyticsEvent().errorTrack({
            errorType: 'payment-generate-by-qr-generate-order-error',
            errorDescription: error,
          });
          setUnauthorized(true);
          console.log(error);
        } //TODO
      )
      .finally(() => {
        setIsLoaderOpen(false);
      });
  }, []);

  const getBackSection = async () => {
    if (transactionStep === TRANSACTION_STEPS.OTP) {
      setTransactionStep(TRANSACTION_STEPS.QR_CODE);
      return;
    }
    setIsReturnModalOpen(true);
  };

  const cancelOrder = () => {
    const { staffId } = orderGeneratedService;
    const data = {
      staff_id: staffId,
      store_id: selectedStoreId,
      verdict: 'reject',
    };

    orderVerdict(orderId, data)
      .then(() => {
        reload();
        goPage('/orders/create-by-qr', history);
      })
      .catch(error => {
        reload();
        AnalyticsEvent().errorTrack({
          errorType: 'payment-generate-by-qr-v2-order-verdict-error',
          errorDescription: error,
        });
      })
      .finally(() => setIsLoaderOpen(false));
  };

  if (unauthorized) {
    return <NotFound />;
  }

  if (isLoading) return '';

  return (
    <div className='button-actions-container'>
      <ScrollToTop />
      <GetBackSection callback={() => getBackSection()} render />
      <OrderStatusHandler
        orderGeneratedService={orderGeneratedService}
        selectedStoreId={selectedStoreId}
        transactionOnProgress={transactionProgressOpen}
        setTransactionProgress={setTransactionProgressOpen}
        orderId={orderId}
        cancelOrder={() => setIsCancelModalOpen(true)}
      />
      <div className='k-ds-width-full payment-generated'>
        <div className='header'>Cobrar por Código QR</div>

        <div className='payment-generated-content-v2 k-ds-m-top-sp-07'>
          {transactionStep === TRANSACTION_STEPS.QR_CODE && (
            <div className='k-ds-width-full'>
              <Card className='k-ds-width-full'>
                <div className='k-ds-heading-03'>
                  Muestra el código QR al cliente
                </div>
                <div className='k-ds-subheading-02'>
                  Permítele al cliente escanear este código para continuar la
                  compra en su celular.
                </div>

                <div className='payment-generated-payment k-ds-width-full k-ds-m-top-sp-06'>
                  <RenderBarcode
                    className='payment-generated-qr'
                    barcode={orderGeneratedService.paymentQR}
                  />

                  {paymentMode === ONLINE_MODE && (
                    <PaymentLinkShare
                      paymentUrl={orderGeneratedService.paymentUrl}
                    />
                  )}
                </div>
                {paymentMode === ONLINE_MODE && (
                  <Space align='center' className='k-ds-m-top-sp-06'>
                    <Clock className='k-ds-m-right-sp-03' />
                    <div className='payment-generated-offline-helper'>
                      <span className='k-ds-subheading-01'>
                        Una vez que el cliente escanee el código QR, espera a
                        que se actualice tu pantalla automáticamente.
                      </span>
                    </div>
                  </Space>
                )}
                {paymentMode === OFFLINE_MODE && (
                  <div className='k-ds-subheading-01 k-ds-width-full text-center k-ds-m-top-sp-08'>
                    Una vez que el cliente escanee el código QR, presiona{' '}
                    <b>Continuar.</b>
                  </div>
                )}
              </Card>
            </div>
          )}

          {transactionStep === TRANSACTION_STEPS.OTP && (
            <OneTimePasscodeVerification
              orderGeneratedService={orderGeneratedService}
              selectedStoreId={selectedStoreId}
              orderId={orderId}
              cancelOrder={() => {
                setIsCancelModalOpen(true);
              }}
              setIsTransactionInProgress={setTransactionProgressOpen}
              paymentId={orderGeneratedService.paymentId}
            />
          )}

          <TransactionSummary
            items_count={orderGeneratedService.items_count}
            totalFormatted={orderGeneratedService.totalFormatted}
          />

          {transactionStep === TRANSACTION_STEPS.QR_CODE && (
            <ButtonActions
              cancelAction={() => setIsCancelModalOpen(true)}
              showContinueAction={paymentMode === OFFLINE_MODE}
              continueAction={() => setTransactionStep(TRANSACTION_STEPS.OTP)}
              paymentMode={paymentMode}
            />
          )}
        </div>
        <Error
          isOpen={isCancelModalOpen}
          onClose={() => setIsCancelModalOpen(false)}
          title='¿Deseas cancelar?'
          content='Al cancelar, deberás iniciar de nuevo el proceso con el cliente.'
          textButton='Cancelar'
          callback={() => cancelOrder()}
        />
        <Error
          isOpen={isReturnModalOpen}
          onClose={() => setIsReturnModalOpen(false)}
          title='¿Quieres regresar?'
          content='Al regresar, se cancelará el cobro y deberás iniciar de nuevo el proceso con el cliente.'
          textButton='Cancelar'
          callback={() => cancelOrder()}
        />
      </div>
    </div>
  );
};

export default PaymentGeneratedByQRv2;
