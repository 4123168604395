import React from 'react';
import { TableMobileB2B } from './TableMobileB2B';

const defaultSize = 'medium';
const defaultType = 'b2b'; // May change

const MAP_PADDING_SIZE = {
  'small': 'spacing-03',
  'medium': 'spacing-05',
  'large': 'spacing-06',
};

const MAP_TABLE_TYPE = {
  'b2b': TableMobileB2B,
};

const TableMobile = ({
  className, children, type, size, ...moreProps
}) => {
  const TableMobileComponent = MAP_TABLE_TYPE[type] || MAP_TABLE_TYPE[defaultType];
  const paddingSize = MAP_PADDING_SIZE[size] || MAP_PADDING_SIZE[defaultSize];
  const props = { ...moreProps, paddingSize };

  return (
    <TableMobileComponent className={className} {...props}>
      { children }
    </TableMobileComponent>
  );
};

export {
  TableMobile,
};
