import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Modal, Space } from '../../ds';
import Faq from './Faq';
import { ReactComponent as ChevronUpIcon } from './chevron-up.svg';
import { ReactComponent as CloseIcon } from './close-icon.svg';
import './faqModal.scss';
import { ReactComponent as KueskiPayIcon } from './kueski-pay.svg';

const FaqModal = ({ isOpen, onClose }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      theme='dark'
      cardType='secondary'
      onClose={handleClose}
      className='faq-modal'
      {...{ isOpen: !!isOpen }}
    >
      <Space
        direction='vertical'
        className='k-ds-m-top-sp-07 k-ds-m-bottom-sp-05'
      >
        <Space align='end' className='close-container'>
          <Button
            className='close-button'
            type='link'
            size='small'
            onClick={handleClose}
          >
            <CloseIcon />
          </Button>
        </Space>
        <Faq isModal />
        <Space
          direction='horizontal'
          align='center'
          justify='space-around'
          className='k-ds-m-top-sp-06'
        >
          <Button onClick={handleClose} className='k-ds-m-sp-08'>
            Cerrar
          </Button>
        </Space>
      </Space>
    </Modal>
  );
};
FaqModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export const FaqHelper = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      {!isModalOpen && (
        <Space className='faq-helper' onClick={() => setIsModalOpen(true)}>
          <Space direction='vertical' className='faq-helper-text'>
            <div className='k-ds-heading-01'>
              ¿Dudas acerca de{' '}
              <KueskiPayIcon width='70px' className='kueski-icon' /> ?{' '}
              <ChevronUpIcon className='k-ds-m-left-sp-05' />
            </div>
          </Space>
        </Space>
      )}
      <FaqModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onOver
      />
    </>
  );
};

export default FaqModal;
