import PropTypes from 'prop-types';
import React from 'react';
import '../BusinessSection.scss';
import { useFormatStores } from '../hooks/useFormatStore';
import { ReactComponent as CloseIcon } from '../res/close-icon.svg';

const TagComponent = ({ storeId, merchantStores, remove }) => {
  const formattedStores = useFormatStores(merchantStores);

  return (
    <div className='tag-label'>
      {formattedStores[storeId]}
      <CloseIcon onClick={remove} />
    </div>
  );
};

TagComponent.propTypes = {
  storeId: PropTypes.string.isRequired,
  merchantStores: PropTypes.array.isRequired,
  remove: PropTypes.func.isRequired,
};

export default TagComponent;
