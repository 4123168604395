import styled, { css } from 'styled-components';

export const PathLine = styled.div`
  width: 1px;
  border-left: 2px solid var(--k-ds-color-interactive-primary-light-bg);
  font-weight: lighter;
  position: relative;
  filter: brightness(110%);
  top: -1rem;
  ${props =>
    props.top &&
    css`
      top: ${props.top};
    `}
  ${props =>
    props.height &&
    css`
      min-height: ${props.height};
    `}
}
`;
