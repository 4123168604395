import styled from 'styled-components';
import { ButtonOutline } from './ButtonOutline';
import KDS from '../../lib/k-ds-foundation';

const ButtonSecondaryAlternate = styled(ButtonOutline)`
  &:enabled {
    &:hover {
      ${KDS.setColorWithFallback('background: ', 'transparent')}
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary-active')}
      ${KDS.setBoxShadowTokenWithFallback('shadow-button-hover')}
    }

    &:active:not(:focus-visible) {
      ${KDS.setBoxShadowTokenWithFallback('shadow-button-hover')}
    }
    &:focus-visible {
      ${KDS.setColorWithFallback('background: ', 'transparent')}
      ${KDS.setColorTokenWithFallback('border-color: ', 'interactive-primary')}
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary')}
    }
  }

  &:disabled {
    ${KDS.setColorWithFallback('background: ', 'transparent')}
    ${KDS.setColorTokenWithFallback('border-color: ', 'interactive-alternate')}
    ${KDS.setColorTokenWithFallback('color: ', 'interactive-alternate')}
  }
`;

export {
  ButtonSecondaryAlternate,
};
