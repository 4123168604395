import PropTypes from 'prop-types';
import React from 'react';
import { Divider, SearchBar, Space } from '../../ds';
import PaymentHistorySearchFilters from './PaymentHistorySearchFilters';
import AdvancedSearchButton from './res/AdvancedSearchButton';

const PaymentHistoryFilters = ({
  advancedSearchOpen,
  setAdvancedSearchOpen,
  viewport,
  searchState,
  setSearchType,
  searchBarWidth,
  filters,
  search,
  onFilterChange,
}) => {
  return (
    <div
      className={
        'search-box' + (advancedSearchOpen ? ' search-box-expanded' : '')
      }
    >
      <Space
        direction={
          viewport.isMobile && !viewport.isSmallTablet
            ? 'vertical'
            : 'horizontal'
        }
        justify='space-between'
        className='k-ds-width-full'
      >
        <Space
          direction='vertical'
          className='search-action-header k-ds-width-full'
        >
          <SearchBar
            placeholder='Buscar por ID de pago o referencia'
            search={searchState}
            setSearch={setSearchType}
            onSearch={() => search(searchState)}
            width={searchBarWidth}
            className={
              !advancedSearchOpen
                ? 'k-ds-width-full'
                : 'k-ds-width-full disabled'
            }
            disabled={advancedSearchOpen}
          />
          <Space>
            {advancedSearchOpen && (
              <p className='search-caption'>
                *Se eliminarán los filtros de la búsqueda avanzada si cambias a
                la búsqueda por ID de pago.
              </p>
            )}
          </Space>
        </Space>
        <Space
          className={
            viewport.isDesktop || viewport.isTablet
              ? 'k-ds-m-top-sp-05 advanced-search-container'
              : 'advanced-search-container'
          }
        >
          <AdvancedSearchButton handleOpen={setAdvancedSearchOpen} />
        </Space>
      </Space>
      {advancedSearchOpen && (
        <div className='k-ds-m-top-sp-07'>
          <Divider />
          <PaymentHistorySearchFilters
            filters={filters}
            onChange={onFilterChange}
            onCancel={() => setAdvancedSearchOpen(false)}
          />
        </div>
      )}
    </div>
  );
};

PaymentHistoryFilters.propTypes = {
  viewport: PropTypes.any,
  filters: PropTypes.object,
  setSearchType: PropTypes.func.isRequired,
  advancedSearchOpen: PropTypes.bool,
  setAdvancedSearchOpen: PropTypes.func,
  searchState: PropTypes.string,
  searchBarWidth: PropTypes.string,
  search: PropTypes.func,
  onFilterChange: PropTypes.func,
};

export default PaymentHistoryFilters;
