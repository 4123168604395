import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../ds';
import styles from './createaccount.module.scss';

const CompletedAccount = () => {
  const router = useHistory();

  return (
    <div className={styles.completedContainer}>
      <svg
        width='80'
        height='80'
        viewBox='0 0 80 80'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M80 39.9999C80 62.0913 62.0914 79.9998 40 79.9998C17.9086 79.9998 0 62.0913 0 39.9999C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 39.9999Z'
          fill='url(#paint0_linear_193_3171)'
        />
        <path
          d='M56.8184 28.9117L33.8842 51.9778C33.806 52.0564 33.6787 52.0564 33.6005 51.9778L24.3193 42.6433'
          stroke='#0E1214'
          strokeWidth='10'
          strokeLinecap='round'
        />
        <defs>
          <linearGradient
            id='paint0_linear_193_3171'
            x1='3.33333'
            y1='11.1069'
            x2='99.816'
            y2='56.488'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#95FF4F' />
            <stop offset='0.493056' stopColor='#38EF5F' />
            <stop offset='1' stopColor='#02CCCD' />
          </linearGradient>
        </defs>
      </svg>
      <div className={styles.content}>
        <div className={styles.title}>Teléfono verificado</div>
        <div className={styles.message}>
          Dirígete al Portal para continuar el proceso de afiliación.
        </div>
      </div>
      <Button
        type='primary-alternate'
        className='k-ds-width-full'
        horizontalPadding='2.5rem'
        verticalPadding='1rem'
        size='small'
        onClick={() => router.push('/me')}
      >
        Ir al Portal
      </Button>
    </div>
  );
};

export default CompletedAccount;
