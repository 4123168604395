import { Space } from '../../ds';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import Header from '../Header';
import { ReactComponent as FaqIcon01 } from './assets/faq-01.svg';
import { ReactComponent as FaqIcon02 } from './assets/faq-02.svg';
import { ReactComponent as FaqIcon03 } from './assets/faq-03.svg';
import { ReactComponent as FaqIcon04 } from './assets/faq-04.svg';
import { ReactComponent as FaqIcon06 } from './assets/faq-06.svg';
import { ReactComponent as FaqIcon07 } from './assets/faq-07.svg';
import './faq.scss';
import FaqContainer from './FaqContainer';
import { ReactComponent as KueskiPayIcon } from './kueski-pay.svg';
import AnalyticsEvent, {
  AnalyticsList,
} from '../../services/analytics/AnalyticsEvent';

const FaqItem = props => {
  const { icon, text } = props;
  return (
    <Space className='faq-item'>
      {icon}
      <div className='k-ds-body-01 faq-text'>{text}</div>
    </Space>
  );
};

FaqItem.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.string.isRequired,
};

const Faq = props => {
  const isTracked = useRef(false);
  const { isModal } = props;
  const viewport = useDeviceContextViewport();
  const isVertical = isModal || viewport.isMobile;

  useEffect(() => {
    if (!isTracked.current) {
      AnalyticsEvent().pageView({ page: AnalyticsList.recurrentFaq });
      isTracked.current = true;
    }
  }, []);

  return (
    <Space direction='vertical'>
      <Header
        title='Preguntas frecuentes'
        subtitle='Consulta las preguntas frecuentes acerca del método de pago KueskiPay.'
      />
      <Space
        direction={isVertical ? 'vertical' : 'horizontal'}
        className='faq-page'
      >
        <FaqContainer />
        <Space
          className={isVertical ? 'more-info info-vertical' : 'more-info'}
          direction='vertical'
        >
          <Space direction={isVertical ? 'horizontal' : 'vertical'}>
            <div
              className={
                isVertical
                  ? 'k-ds-heading-04 k-ds-m-top-sp-02'
                  : 'k-ds-heading-04'
              }
            >
              ¿Cómo cobrar con
            </div>
            <Space className='kueski-container'>
              <KueskiPayIcon className='kueski-icon' />
              <div className='k-ds-heading-04'> ?</div>
            </Space>
          </Space>
          <FaqItem icon={<FaqIcon01 />} text='Genera una orden de cliente.' />
          <FaqItem
            icon={<FaqIcon02 />}
            text='Comparte el código QR o enlace con el cliente.'
          />
          <FaqItem
            icon={<FaqIcon03 />}
            text='Permite que el cliente continúe el proceso de aprobación en su celular.'
          />
          <FaqItem
            icon={<FaqIcon04 />}
            text='Una vez aprobado, busca el ID de pago en el portal de comercios.'
          />
          <FaqItem icon={<FaqIcon06 />} text='Concilia la compra en tu POS.' />
          <FaqItem
            icon={<FaqIcon07 />}
            text='¡Listo! Puedes entregar el producto al cliente.'
          />
        </Space>
      </Space>
    </Space>
  );
};

Faq.propTypes = {
  isModal: PropTypes.bool,
};

export default Faq;
