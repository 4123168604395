import React from 'react';
import { isFeatureEnabled } from '../../../Utils/Feature/feature_flag';
import { useStore } from '../../../services/store';
import { allowedQrv2Store } from '../../../Utils/utils';
import PaymentGeneratedByQR from '../../../components/PaymentGeneratedByQR/PaymentGeneratedByQR';
import PaymentGeneratedByQRv2 from '../../../components/PaymentGeneratedByQRv2/PaymentGeneratedByQRv2';
import { observer } from 'mobx-react-lite';

const payOrderByQrV2Enabled = isFeatureEnabled('qr_offline_v2');

const PaymentGeneratedByQrVersionSelector = () => {
  const { qrCodeStore: { storeId }} = useStore();

  const enableV2 = payOrderByQrV2Enabled && allowedQrv2Store.includes(storeId);

  return enableV2 ? <PaymentGeneratedByQRv2 /> : <PaymentGeneratedByQR />;
}

export default observer(PaymentGeneratedByQrVersionSelector);
