import styled from 'styled-components';
import React from 'react';
import { ReactComponent as IconSuccess } from './icon-success.svg';
import { ReactComponent as IconClose } from './icon-close.svg';
import KDS from '../../lib/k-ds-foundation';
import { ToastBase } from './ToastBase';

const StyledToastSuccess = styled(ToastBase)`
  ${KDS.setColorTokenWithFallback('border: 2px solid ', 'successful')};
`;

const ToastSuccess = ({
  message,
  onClick,
  ...moreProps
}) => (
  <StyledToastSuccess {...moreProps}>
    <IconSuccess className="toast-icon" />
    <p className="k-ds-heading-01 toast-message">{message}</p>
    <button type="button" className="toast-close" onClick={onClick}>
      <IconClose />
    </button>
  </StyledToastSuccess>
);

export {
  ToastSuccess,
};
