import { useEffect, useState } from 'react';
import { getStoreById, getStoreInsightsById } from '../../services/Application';
import { useGetMerchant } from '../../Utils/useGetMerchant';
import AnalyticsEvent from '../../services/analytics/AnalyticsEvent';

export function useKStore(storeId, setIsLoaderOpen) {
  const merchantInfo = useGetMerchant();
  const [storeService, setStoreService] = useState(null);
  const [showNotFound, setShowNotFound] = useState(false);

  const getStore = () => {
    setIsLoaderOpen(true);
    setShowNotFound(false);
    getStoreById(storeId)
      .then(response => {
        const { store } = response.data;

        if (
          !store ||
          !store.merchant_id ||
          !merchantInfo ||
          store.merchant_id !== merchantInfo.merchant_id
        ) {
          setShowNotFound(true);
          return;
        }

        setStoreService(store);
      })
      .catch(error => {
        AnalyticsEvent().errorTrack({
          errorType: 'use-store-get-store-id-error',
          errorDescription: error,
        });
        setShowNotFound(true);
      })
      .finally(() => setIsLoaderOpen(false));
  };

  useEffect(getStore, []);

  return {
    storeService: storeService,
    showNotFound: showNotFound,
  };
}

export function useStoreUserInsights(storeId) {
  const [storeService, setStoreService] = useState([]);

  const getStore = () => {
    getStoreInsightsById(storeId)
      .then(response => {
        const { summary } = response.data;
        setStoreService(summary);
      })
      .catch(err => {
        AnalyticsEvent().errorTrack({
          errorType: 'use-store-get-store-error',
          errorDescription: err,
        });
        console.log(err);
      });
  };

  useEffect(getStore, []);

  return storeService;
}
