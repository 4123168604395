import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { ReactComponent as ExportIcon } from '../../common/assets/excel-icon.svg';
import { Button } from '../../ds';
import { Message } from '../DS/Message';
import DropZone from '../DropZone';
import styles from './UploadZone.module.scss';
import { ReactComponent as UploadDropIcon } from './res/upload-drop-icon.svg';
import { ReactComponent as UploadIcon } from './res/upload-icon.svg';

const UploadZone = ({ messageError, handleFilesChange }) => {
  const dropZoneRef = useRef(null);

  const [isDragActive, setIsDragActive] = useState(false);

  const openFileInput = () => {
    dropZoneRef.current.openFileInput();
  };

  return (
    <div>
      <div className='k-ds-body-02 k-ds-m-bottom-sp-06'>
        Arrastra o adjunta tu archivo de{' '}
        <span className='font-weight-bold'>Excel en formato .xlsx.</span>
      </div>
      <Message
        type='information'
        className='k-ds-m-bottom-sp-05'
        message={
          <div className='k-ds-heading-01'>
            <div className='k-ds-m-bottom-sp-03'>
              ¿Cómo puedo exportar mi documento de Excel a formato .xlsx?
            </div>
            <div className='k-ds-m-top-sp-06'>
              En tu documento, selecciona Archivo &gt; Descargar &gt; Microsoft
              Excel (.xlsx).
            </div>
          </div>
        }
      />
      <DropZone
        ref={dropZoneRef}
        onDragActiveChange={setIsDragActive}
        onFilesChange={handleFilesChange}
      >
        <div
          className={
            isDragActive
              ? styles['selection-drop-zone-content-active']
              : cn(styles['selection-drop-zone-content'], {
                  ['border-error']: messageError.length !== 0,
                })
          }
        >
          <UploadIcon className='k-ds-m-bottom-sp-07' />
          <div className='k-ds-heading-01'>Arrastra tu archivo .xlsx aquí</div>
          {messageError.length !== 0 && (
            <p className='errorMaxRowAllowed'>{messageError}</p>
          )}
          <div className='subMessageDropZone'>80 filas máximo por archivo</div>
          <UploadDropIcon className={styles['upload-drop-icon']} />
        </div>
      </DropZone>
      <div className={styles['selection-upload-container']}>
        <Button type='link' onClick={openFileInput}>
          <ExportIcon className='k-ds-m-right-sp-03' />
          Adjuntar archivo
        </Button>
      </div>
    </div>
  );
};

UploadZone.propTypes = {
  messageError: PropTypes.string,
  handleFilesChange: PropTypes.func,
};

export default UploadZone;
