import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(3px);

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 2;
  background-color: rgba(0, 0, 0, .2);
`;

const LoaderImage = styled.img`
  height: 340px;
`;

const Loader = ({
    isOpen= false,
  src,
}) => {
  if (!isOpen) {
    return <></>;
  }
  return (
    <>
      <StyledOverlay>
        <LoaderImage src={src} alt="Loading..." />
      </StyledOverlay>
    </>
  );
};

Loader.propTypes = {
  isOpen: PropTypes.bool,
  src: PropTypes.string.isRequired,
};

export { Loader };
