import { useHistory } from 'react-router-dom';
import { useLoaderContext } from '../../contexts/Loader/LoaderContext';
import { useToastContext } from '../../contexts/Toast/ToastContext';
import { Button, Space } from '../../ds';
import {
  createMerchantUser,
  updateMerchantUser,
} from '../../services/Application';

import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { ACCOUNT_MANAGER, GENERAL_ADMIN } from '../../Utils/constants';
import {
  phoneSpaceFormatted,
  validateErrorMessagesFromObject,
  validateIfFormIsEmpty,
} from '../../Utils/formValidations';
import { MutateUserContext } from './MutateUser';
import AnalyticsEvent from '../../services/analytics/AnalyticsEvent';

const isGeneralAdminByAMRole = (role, roles, roleByForm) => {
  const roleByFormData =
    roles.filter(
      item => item.role_id === roleByForm && item.role_name === GENERAL_ADMIN
    ).length > 0;

  return roleByFormData && role.name === ACCOUNT_MANAGER;
};
const SubmitSection = props => {
  const router = useHistory();
  const { role, roles } = useContext(MutateUserContext);
  const { setIsLoaderOpen } = useLoaderContext();
  const addToast = useToastContext();

  const isAMCreatingGeneralAdming = useMemo(() => {
    const { formData } = props;
    return isGeneralAdminByAMRole(role, roles, formData.role);
  }, [role, roles, props.formData]);

  const checkResponseData = error => {
    const code = error.response?.data?.code;
    if (!code) {
      return;
    }
    switch (code) {
      case 'unique-field-taken':
        props.setFormDataErrors({
          ...props.formDataErrors,
          ...{
            email: 'El correo electrónico que ingresaste ya está registrado',
          },
        });
        break;
    }
  };

  const saveUser = () => {
    setIsLoaderOpen(true);
    const { formData, merchantId } = props;

    const resolveStores = () => {
      if (isAMCreatingGeneralAdming) return [];

      if (formData?.store === '') {
        return [];
      }

      return [formData.store, ...formData.stores];
    };

    const user = {
      first_name: formData.firstname,
      last_name: formData.lastname,
      maiden_name: formData.maidenName,
      email: formData.email,
      user_code: formData.userCode,
      role_id: formData.role,
      curp: formData.curp,
      phone_number: phoneSpaceFormatted(formData?.cellphone || ''),
      stores: [...resolveStores()],
      status: props?.formData.status ?? 'active',
      merchant_id: merchantId,
    };
    if (props.isEdit) {
      updateMerchantUser(props.userId, user)
        .then(() => {
          router.goBack();
          addToast('¡El cambio se ha guardado con éxito!', 'success');
        })
        .catch(err => {
          AnalyticsEvent().errorTrack({
            errorType: 'user-update-merchant-user-error',
            errorDescription: err,
          });
          checkResponseData(err);
        })
        .finally(() => {
          setIsLoaderOpen(false);
        });
      return;
    }

    createMerchantUser(user)
      .then(() => {
        router.goBack();
      })
      .catch(err => {
        AnalyticsEvent().errorTrack({
          errorType: 'user-create-merchant-user-error',
          errorDescription: err,
        });
        checkResponseData(err);
      })
      .finally(() => {
        setIsLoaderOpen(false);
      });
  };

  const getSkippedItems = () => {
    const skippedXstore = 1;
    const skippedUserCode = 1;
    const skippedAMCreatingGeneral = isAMCreatingGeneralAdming ? 1 : 0;

    // if (props.isEdit) {
    //   const skipCode = props.formData.userCode !== '' ? 0 : 1;
    //   return skipCode + skippedAMCreatingGeneral + skippedXstore + 2;
    // }

    const skippedCurp = props.formData.curp === '' ? 1 : 0;
    const skippedTel = props.formData.cellphone === '' ? 1 : 0;
    const skippedMidName = props.formData.maidenName === '' ? 1 : 0;

    return (
      skippedCurp +
      skippedTel +
      skippedXstore +
      skippedAMCreatingGeneral +
      skippedUserCode +
      skippedMidName
    );
  };

  return (
    <Space className='k-ds-width-full k-ds-m-top-sp-08'>
      <Button
        type='primary-alternate'
        className='k-ds-m-rigth-sp-02'
        horizontalPadding='2.5rem'
        verticalPadding='1rem'
        size='small'
        onClick={() => saveUser()}
        disabled={
          !validateIfFormIsEmpty(props.formData, getSkippedItems()) ||
          validateErrorMessagesFromObject(props.formDataErrors)
        }
      >
        Guardar
      </Button>
      <Button
        type='simple'
        size='small'
        onClick={() => props.setOpenModal(true)}
      >
        Cancelar
      </Button>
    </Space>
  );
};

SubmitSection.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  formDataErrors: PropTypes.object.isRequired,
  isEdit: PropTypes.bool.isRequired,
  userId: PropTypes.string,
  merchantId: PropTypes.string,
  setOpenModal: PropTypes.func,
  setFormDataErrors: PropTypes.func.isRequired,
};

export default SubmitSection;
