import React from 'react';
import './emptySearchResults.scss';
import PropTypes from 'prop-types';
import { ReactComponent as SearchIcon } from './ic_search.svg';

const defaultHeader = 'Sin resultados';
const defaultSubHeader =
  'No encontramos lo que buscas, ¿puedes intentarlo de otra manera o probar con un ID diferente?';

const EmptySearchResults = ({
  clearAction,
  header = defaultHeader,
  subHeader = defaultSubHeader,
  icon,
}) => {
  return (
    <div className='empty-search-results'>
      {icon || <SearchIcon />}
      {header && <h2 className='empty-search-results-header'>{header}</h2>}
      {subHeader && (
        <div className='empty-search-results-subheader'>
          <p>{subHeader}</p>
        </div>
      )}
      {clearAction && (
        <div>
          <button className='link-button' onClick={clearAction}>
            Borrar la búsqueda
          </button>
        </div>
      )}
    </div>
  );
};

EmptySearchResults.propTypes = {
  clearAction: PropTypes.func,
  header: PropTypes.string,
  subHeader: PropTypes.string,
  icon: PropTypes.element,
};

export default EmptySearchResults;
