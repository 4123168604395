import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
// import { ReactComponent as Marker } from './res/marker.svg';
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';

// const CustomMarker = () => <Marker />;

export const geocodeZipcode = zipcode => {
  const KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;

  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${zipcode}&key=${KEY}`;

  return fetch(url)
    .then(response => response.json())
    .then(data => {
      if (data.status === 'OK') {
        const { lat, lng } = data.results[0].geometry.location;

        return { lat, lng };
      }
    });
};

function MapContainer(props) {
  const {
    disabledMap = false,
    markerCoord = null,
    customZoom = 14,
    onUpdateCoords = () => {},
    centerMap,
    customHeight = '455px',
    customWidth = '100%',
  } = props;

  const prevCenter = useRef(null);
  const [, setZoom] = useState(customZoom);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [customCenter, setCustomCenter] = useState({
    lat: 20.6975777,
    lng: -103.3750228,
  });

  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    if (markerCoord === null) {
      setMarkerPosition(null);
      return;
    }
    const splitCoordinates = markerCoord.split(',');
    setZoom(16);
    setCustomCenter({
      lat: parseFloat(splitCoordinates[0]),
      lng: parseFloat(splitCoordinates[1]),
    });
    setMarkerPosition({
      lat: parseFloat(splitCoordinates[0]),
      lng: parseFloat(splitCoordinates[1]),
    });
  }, [markerCoord]);

  useEffect(() => {
    if (prevCenter !== centerMap && centerMap !== null) {
      setCustomCenter(centerMap);
      setZoom(16);
      prevCenter.current = centerMap;
    }
  }, [centerMap]);

  const handleMapClick = event => {
    if (disabledMap) return;
    const { lat, lng } = event.latLng.toJSON();
    setMarkerPosition({ lat, lng });
    setCustomCenter({ lat, lng });
    onUpdateCoords([lat, lng]);
  };

  const mapOptions = {
    draggable: !disabledMap, // Disable dragging the map
    clickable: !disabledMap, // Disable clicking on the map
    zoomControl: true, // Enable zoom control
    mapTypeControl: false, // Hide the map type control (satellite view option)
    fullscreenControl: false, // Enable fullscreen control
  };

  const handleLoad = () => {
    setMapLoaded(true);
  };

  return (
    <div
      style={{
        height: customHeight,
        width: customWidth,
      }}
    >
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
        onLoad={handleLoad}
      >
        {mapLoaded && (
          <GoogleMap
            mapContainerStyle={{ height: customHeight }}
            center={customCenter}
            zoom={14}
            onClick={handleMapClick}
            options={mapOptions}
          >
            {markerPosition && <MarkerF position={markerPosition} />}
          </GoogleMap>
        )}
      </LoadScript>
      {/* <GoogleMapReact
        bootstrapURLKeys={ { key: process.env.REACT_APP_GOOGLE_MAPS_KEY } }
        center={ customCenter }
        zoom={ zoom }
        onClick={ handleMarker }
        yesIWantToUseGoogleMapApiInternals
        options={ {
          disableDefaultUI: disabledMap,
          draggableCursor: 'default',
        } }
      >
        {markerPosition === null && !isMounted ? null : (
          <markerPosition lat={ markerPosition.lat } lng={ markerPosition.lng } />
        )}
      </GoogleMapReact> */}
    </div>
  );
}

MapContainer.propTypes = {
  disabledMap: PropTypes.bool,
  customZoom: PropTypes.number,
  markerCoord: PropTypes.any,
  zipCode: PropTypes.number,
  onUpdateCoords: PropTypes.func,
  centerMap: PropTypes.object,
  customHeight: PropTypes.string,
  customWidth: PropTypes.string,
  google: PropTypes.any,
};

export default MapContainer;
