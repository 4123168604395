import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isFeatureEnabled } from '../../Utils/Feature/feature_flag';
import {
  validateAlphaNumericHyphenAndUnderscore,
  validateNumerical,
} from '../../Utils/formValidations';
import {
  formatDate,
  getDateString,
  hyphenEveryFourChars,
  numberWithCommas,
} from '../../Utils/formats';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { useLoaderContext } from '../../contexts/Loader/LoaderContext';
import { Pagination, PaginationMobile, Space, Tag } from '../../ds';
import { exportPayments, getPayments } from '../../services/Application';
import EmptySearchResults from '../EmptySearchResults/index';
import Header from '../Header';
import { TdResponsive, ThResponsive, TrCollapsable } from '../ResponsiveTable';
import { ResponsiveTable } from '../ResponsiveTable/ResponsiveTable';
import Tooltip from '../Tooltip/Tooltip';
import PaymentHistoryFilters from './PaymentHistoryFilters';
import PaymentSummary from './PaymentSummary';
import './paymentHistory.scss';
import ExportButton from './res/ExportButton';
import RefreshButton from './res/RefreshButton';
import AnalyticsEvent from '../../services/analytics/AnalyticsEvent';

// const headerSetting = [
//   { ordenId: 'ID de orden' },
//   { storeId: 'ID de tienda' },
//   { paymentId: 'ID de pago' },
//   { order_reference: 'Referencia' },
//   { soldAt: 'Fecha de venta' },
//   { amount: 'Monto total' },
//   { origin: 'Canal' },
//   { storeName: 'Sucursal', hideOn: 'desktop' },
//   { refundStatus: 'Cancelaciones', hideOn: 'desktop' },
//   { details: 'Detalles', hideOn: 'desktop' },
// ];
const limit = 20;

const tableHeaders = {
  ordenId: 'ID de orden',
  storeId: 'ID de tienda',
  paymentId: 'ID de pago',
  order_reference: 'Referencia',
  soldAt: 'Fecha de venta',
  amount: 'Monto total',
  origin: 'Canal',
  storeName: 'Sucursal',
  refundStatus: 'Cancelaciones',
  details: 'Detalles',
};

const refundStatusTag = payment => {
  if (payment.status === 'success' && payment.refunded_amount > 0)
    return <Tag color='orange'>PARCIAL</Tag>;

  if (payment.status === 'refunded') return <Tag color='orange'>TOTAL</Tag>;

  return '-';
};

const getDateTime = currentDate => {
  let hours = currentDate.getHours();
  hours = hours >= 10 ? hours : `0${hours}`;
  let mins = currentDate.getMinutes();
  mins = mins >= 10 ? mins : `0${mins}`;
  return (
    currentDate.getDate() +
    '/' +
    (currentDate.getMonth() + 1) +
    '/' +
    currentDate.getFullYear() +
    ' - ' +
    hours +
    ':' +
    mins
  );
};

function PaymentHistory() {
  let history = useHistory();
  const { setIsLoaderOpen } = useLoaderContext();
  const viewport = useDeviceContextViewport();

  const [orders, setOrders] = useState([]);
  const [expandedItem, setExpandedItem] = useState('');
  const [isRefresh, setIsRefresh] = useState('');
  const [lastSync, setLastSync] = useState('');
  const [searchState, setSearchState] = useState('');
  const [searchBy, setSearchBy] = useState('');
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [isAlphanumericSearch, setIsAlphanumericSearch] = useState(false);
  const [advancedSearchOpen, setAdvancedSearchOpen] = useState(false);

  const PaginationComponent = viewport.isMobile ? PaginationMobile : Pagination;
  const searchBarWidth = '100%';

  const [pagination, setPagination] = useState({
    total_pages: 1,
    total_items: 0,
    actual_page: 1,
  });
  const [summaryState, setSummaryState] = useState({
    payments_total: 0,
    payments_count: 0,
    cancelations_total: 0,
    cancelations_count: 0,
  });

  const fetchOrders = (
    paymentType,
    searchBy,
    currentPage,
    limit,
    startDate,
    endDate,
    channel
  ) => {
    setIsLoaderOpen(true);
    getPayments(
      paymentType,
      searchBy,
      currentPage,
      limit,
      startDate,
      endDate,
      channel
    )
      .then(response => {
        setIsLoaderOpen(false);
        setOrders(response.data.payments);
        setPagination(response.data.pagination);
        setSummaryState(response.data.summary);
        // setIndexCol(2);
      })
      .catch(error => {
        AnalyticsEvent().errorTrack({
          errorType: 'payment-history-get-payments-error',
          errorDescription: error,
        });
        setIsLoaderOpen(false);
      })
      .finally(() => {
        setLastSync(getDateTime(new Date()));
      });
  };

  const exportPaymentsExcel = () => {
    const { startDate, endDate, channel, paymentType } = filters;
    setIsLoaderOpen(true);
    exportPayments(paymentType, searchBy, startDate, endDate, channel).finally(
      () => {
        setIsLoaderOpen(false);
      }
    );
  };

  useEffect(() => {
    const { startDate, endDate, channel, paymentType } = filters;
    fetchOrders(
      paymentType,
      searchBy,
      currentPage,
      limit,
      startDate,
      endDate,
      channel,
      isAlphanumericSearch
    );
  }, [searchBy, filters, currentPage, isRefresh]);

  function goDetails(path) {
    history.push(path);
    const contentArea = document.getElementById('content-area');
    contentArea.scrollTop = 0;
  }

  function cleanFilters() {
    setCurrentPage(1);
    setFilters({});
    setAdvancedSearchOpen(false);
  }

  function search(search) {
    if (isAlphanumericSearch) {
      cleanFilters();
      setSearchBy(search);
      return;
    }
    const searchNoHyphens = search.split('-').join('');
    cleanFilters();
    setSearchBy(searchNoHyphens);
  }

  function setSearchType(value) {
    const valueNoHyphens = value.split('-').join('');
    if (valueNoHyphens && validateNumerical(valueNoHyphens)) {
      setIsAlphanumericSearch(false);
    } else if (validateAlphaNumericHyphenAndUnderscore(value)) {
      setIsAlphanumericSearch(true);
    }
    setSearchState(value);
  }

  function clearSearch() {
    setSearchState('');
    setSearchBy('');
  }

  const onFilterChange = filters => {
    setCurrentPage(1);
    setSearchBy('');
    setSearchState('');
    setFilters(filters);
    window.localStorage.setItem('saleHistory', JSON.stringify(filters));
  };

  useEffect(() => {
    const saleHistory =
      JSON.parse(window.localStorage.getItem('saleHistory')) || {};
    const hasFilter = Object.keys(saleHistory).length > 0;
    setAdvancedSearchOpen(hasFilter);
    setFilters(saleHistory);
  }, []);

  return (
    <div className='k-ds-width-full payment-history'>
      <Header
        title='Historial de ventas'
        subtitle='Consulta el reporte de ventas en tu comercio'
      />
      <PaymentHistoryFilters
        searchBarWidth={searchBarWidth}
        advancedSearchOpen={advancedSearchOpen}
        setAdvancedSearchOpen={setAdvancedSearchOpen}
        viewport={viewport}
        searchState={searchState}
        setSearchType={setSearchType}
        filters={filters}
        search={search}
        onFilterChange={onFilterChange}
      />

      <PaymentSummary
        summary={summaryState}
        lastSync={lastSync}
        startDate={getDateString(
          filters.startDate ? filters.startDate : new Date()
        )}
        endDate={getDateString(filters.endDate)}
      />
      {viewport.isMobile && !viewport.isSmallTablet && (
        <Space
          justify='space-around'
          className='k-ds-width-full k-ds-m-top-sp-09'
        >
          <ExportButton handleExport={exportPaymentsExcel} />
          <RefreshButton handleRefresh={setIsRefresh} />
        </Space>
      )}
      <Space
        direction='horizontal'
        justify='space-between'
        align='center'
        className={
          !viewport.isMobile || viewport.isSmallTablet
            ? 'action-header k-ds-m-bottom-sp-05'
            : 'action-header'
        }
      >
        <div className='k-ds-heading-01'>
          Mostrando: {pagination.total_items} resultados
        </div>
        {(!viewport.isMobile || viewport.isSmallTablet) && (
          <div className='action-bar'>
            <ExportButton handleExport={exportPaymentsExcel} />
            <RefreshButton handleRefresh={setIsRefresh} />
          </div>
        )}
      </Space>
      <ResponsiveTable
        type='b2b'
        scrollingX
        className='payment-history-list k-ds-width-full k-ds-m-bottom-lyt-04 '
      >
        <thead>
          <tr>
            <th>
              <Tooltip
                text={tableHeaders.ordenId}
                message='Número único de la transacción'
              />
            </th>
            <th>
              <Tooltip
                text={tableHeaders.storeId}
                message='Número único de la tienda que pertenece al comercio'
              />
            </th>
            <th>
              <Tooltip
                text={tableHeaders.paymentId}
                message='Número único para aclaraciones con Kueski'
              />
            </th>
            <th>{tableHeaders.order_reference}</th>
            <ThResponsive noWrap>{tableHeaders.soldAt}</ThResponsive>
            <ThResponsive noWrap devices='desktop mobile'>
              {tableHeaders.amount}
            </ThResponsive>
            <ThResponsive devices='desktop mobile'>
              {tableHeaders.origin}
            </ThResponsive>
            <ThResponsive devices='desktop mobile'>
              {tableHeaders.storeName}
            </ThResponsive>
            <ThResponsive devices='desktop mobile'>
              {tableHeaders.refundStatus}
            </ThResponsive>
            <ThResponsive devices='tablet'>{tableHeaders.details}</ThResponsive>
          </tr>
        </thead>
        <tbody>
          {orders.map(item => {
            const isExpanded = expandedItem === item.payment_id;
            const channelOrigin = ['online', 'ecommerce'].includes(item.channel)
              ? 'En línea'
              : 'En tienda';
            const formatCreatedAt = formatDate(item.payment_created_at);
            const formatTotal =
              numberWithCommas(item?.total?.toFixed(2)) || '-';

            const ButtonDetails = () => (
              <button
                onClick={() => goDetails(`/payments/${item.payment_id}`)}
                className='link-button'
              >
                {item.order_id || '-'}
              </button>
            );

            const ButtonToogle = () => (
              <TdResponsive
                type='toggle2'
                devices='tablet'
                isCollapsed={!isExpanded}
                dataTitle={tableHeaders.details}
                onClick={() =>
                  setExpandedItem(isExpanded ? '' : item.payment_id)
                }
              />
            );

            return (
              <React.Fragment key={item.payment_id}>
                {!isExpanded && (
                  <tr key={item.payment_id} aria-expanded={isExpanded}>
                    <TdResponsive noWrap data-title={tableHeaders.ordenId}>
                      <ButtonDetails />
                    </TdResponsive>
                    <TdResponsive noWrap data-title={tableHeaders.x}>
                      {hyphenEveryFourChars(item.store_id || '-')}
                    </TdResponsive>
                    <TdResponsive noWrap data-title={tableHeaders.paymentId}>
                      {hyphenEveryFourChars(item.payment_id || '-')}
                    </TdResponsive>
                    <TdResponsive
                      noWrap
                      data-title={tableHeaders.order_reference}
                      className='word-break-all'
                    >
                      {item.order_reference || '-'}
                    </TdResponsive>
                    <TdResponsive noWrap data-title={tableHeaders.soldAt}>
                      {formatCreatedAt}
                    </TdResponsive>
                    <TdResponsive
                      noWrap
                      devices='desktop mobile'
                      data-title={tableHeaders.amount}
                    >
                      $ {formatTotal}
                    </TdResponsive>
                    <TdResponsive
                      noWrap
                      devices='desktop mobile'
                      data-title={tableHeaders.origin}
                    >
                      {channelOrigin}
                    </TdResponsive>
                    <TdResponsive
                      devices='desktop mobile'
                      data-title={tableHeaders.storeName}
                    >
                      {item.store_name || '-'}
                    </TdResponsive>
                    <TdResponsive
                      devices='desktop mobile'
                      data-title={tableHeaders.refundStatus}
                    >
                      {refundStatusTag(item)}
                    </TdResponsive>
                    <ButtonToogle />
                  </tr>
                )}

                <TrCollapsable
                  isCollapsed={!isExpanded}
                  devices='tablet'
                  noBorderBottom
                >
                  <TdResponsive
                    type='header'
                    noWrap
                    dataTitle={tableHeaders.ordenId}
                  >
                    <ButtonDetails />
                  </TdResponsive>
                  <TdResponsive
                    type='header'
                    noWrap
                    dataTitle={tableHeaders.storeId}
                  >
                    {hyphenEveryFourChars(item.payment_id || '-')}
                  </TdResponsive>
                  <TdResponsive
                    type='header'
                    noWrap
                    dataTitle={tableHeaders.paymentId}
                  >
                    {hyphenEveryFourChars(item.payment_id || '-')}
                  </TdResponsive>
                  <TdResponsive
                    type='header'
                    dataTitle={tableHeaders.order_reference}
                  >
                    {item.order_reference || '-'}
                  </TdResponsive>
                  <TdResponsive
                    type='header'
                    noWrap
                    dataTitle={tableHeaders.soldAt}
                  >
                    {formatCreatedAt}
                  </TdResponsive>
                  <ButtonToogle />
                </TrCollapsable>
                <TrCollapsable isCollapsed={!isExpanded} devices='tablet'>
                  <TdResponsive
                    type='header'
                    wordBreakAll={false}
                    dataTitle={tableHeaders.amount}
                  >
                    {formatTotal}
                  </TdResponsive>

                  <TdResponsive
                    type='header'
                    wordBreakAll={false}
                    dataTitle={tableHeaders.origin}
                  >
                    {channelOrigin}
                  </TdResponsive>
                  <TdResponsive
                    type='header'
                    wordBreakAll={false}
                    dataTitle={tableHeaders.storeName}
                  >
                    {item.store_name || '-'}
                  </TdResponsive>
                  <TdResponsive
                    type='header'
                    colSpan={3}
                    dataTitle={tableHeaders.refundStatus}
                  >
                    {refundStatusTag(item)}
                  </TdResponsive>
                </TrCollapsable>
              </React.Fragment>
            );
          })}
        </tbody>
      </ResponsiveTable>

      {orders.length > 0 ? (
        <PaginationComponent
          totalPages={pagination.total_pages}
          totalElements={pagination.total_items}
          maxVisibleNumbers={6}
          currentPage={pagination.actual_page}
          elementsPerPage={limit}
          changePage={page => setCurrentPage(page)}
        />
      ) : (
        <EmptySearchResults clearAction={() => clearSearch()} />
      )}
      {!isFeatureEnabled('payment_summary') && (
        <Space
          className='k-ds-m-top-sp-09 k-ds-heading-01'
          id='last-sync'
          direction='horizontal'
          justify='end'
        >
          Última actualización: {lastSync}
        </Space>
      )}
    </div>
  );
}

export default PaymentHistory;
