export const NAME_STORE_FIELD = {
  name: 'name',
  required: true,
  label: 'Nombre de la sucursal',
};

export const NAME_STORE_ID_FIELD = {
  name: 'external_store_id',
  required: false,
  label: 'Número de la tienda',
  placeholder: 'ej. A- 1234',
  maxChars: 100
};

export const EDIT_STORE_ID_FIELD = {
  name: 'edit-external_store_id',
  label: 'Editar número',
};

export const PHONE_NUMBER_FIELD = {
  name: 'phone_number',
  required: false,
  label: 'Teléfono de sucursal',
};

export const MALL_STORE_FIELD = {
  name: 'mall_id',
  required: false,
  label: 'Seleccionar centro comercial',
};

export const ADDRESS_STORE_FIELDS = [
  {
    name: 'street',
    required: true,
    label: 'Calle',
  },
  {
    name: 'ext_number',
    required: true,
    label: 'Número exterior',
  },
  {
    name: 'inner_number',
    required: false,
    label: 'Número interior',
  },
];
