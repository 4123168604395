import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Auth from '../../Utils/Auth/auth';
import {
  ACCOUNT_MANAGER,
  GENERAL_ADMIN,
  SALES_ROLE,
  salesmanRoleLabel,
} from '../../Utils/constants';
import { filterRoles } from '../../Utils/filterRoles';
import {
  canBeEmpty,
  phoneInputCleanFormatted,
  phoneSpaceFormatted,
  validateCurp,
  validateEmailField,
  validateErrorMessagesFromObject,
  validatePhoneNumber,
} from '../../Utils/formValidations';
import { useGetMerchant } from '../../Utils/useGetMerchant';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { Button, Select, Space, Tag, TextInput } from '../../ds';
import { getMerchantRoles } from '../../services/Application';
import AddStoresModal from '../AddStoresModal/AddStoresModal';
import { validateNameInput } from '../MutateUser/MutateUser';
import { ReactComponent as AddIcon } from './add-icon.svg';
import './userCrudForm.scss';
import AnalyticsEvent from '../../services/analytics/AnalyticsEvent';

export const StoreTag = props => {
  const { storeName, onClick } = props;

  return (
    <Tag color='orange' className='filter-tag k-ds-m-right-sp-03'>
      {storeName}
      <Space
        onClick={handleOnClick}
        className='k-ds-m-left-sp-05 remove-store-tag font-weight-bold'
      >
        <svg
          width='10'
          height='10'
          viewBox='0 0 10 10'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M5.00048 4.05732L8.30048 0.757324L9.24315 1.69999L5.94315 4.99999L9.24315 8.29999L8.30048 9.24266L5.00048 5.94266L1.70048 9.24266L0.757812 8.29999L4.05781 4.99999L0.757812 1.69999L1.70048 0.757324L5.00048 4.05732Z'
            fill='#FF6F00'
          />
        </svg>
      </Space>
    </Tag>
  );

  function handleOnClick() {
    onClick();
  }
};

const isGeneralAdminByAMRole = (role, roles, roleByForm) => {
  const roleByFormData =
    roles.filter(
      item => item.role_id === roleByForm && item.role_name === GENERAL_ADMIN
    ).length > 0;

  return roleByFormData && role.name === ACCOUNT_MANAGER;
};

const UserCrudForm = props => {
  const { userData, stores, onSaveUser, closeEdit } = props;

  const viewport = useDeviceContextViewport();
  const isMobileOnly = viewport.isMobile && !viewport.isSmallTablet;

  const { employee } = Auth.userData();
  const merchantInfo = useGetMerchant();

  const merchantId = merchantInfo.merchant_id;
  const role = employee.role;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user, setUser] = useState(userData);
  const [formErrors, setFormErrors] = useState(user.errors);

  const rolesByMerchantId = useMerchantRoles(merchantId);

  const merchantRoles = useMemo(
    () => filterRoles(rolesByMerchantId, role, null),
    [rolesByMerchantId, role]
  );

  const merchantStores = useMemo(() => filterStores(stores), [stores]);

  const { t } = useTranslation();

  const hideStoreSection = isGeneralAdminByAMRole(
    role,
    rolesByMerchantId,
    user.role_id
  );

  const skipStore = hideStoreSection ? false : user.stores.length === 0;

  const disabledButtonForm =
    validateErrorMessagesFromObject(formErrors) || !user.role_id || skipStore;

  const handlePhoneBlur = event => {
    const inputValue = event.target.value;
    const formattedInputValue = phoneInputCleanFormatted(inputValue);
    setUser({ ...user, ...{ phone_number: formattedInputValue } });
  };

  if (!merchantRoles) return null;

  return (
    <>
      <Space className='user-crud-form k-ds-width-full' direction='vertical'>
        <div className='k-ds-heading-04 k-ds-m-bottom-sp-07'>
          ¿Cuál es el ROL que el usuario va a realizar en la tienda?
        </div>

        <Space direction='horizontal' className='role-container'>
          <div className='text-field'>
            <Select
              required
              placeholder='Seleccionar'
              label='Rol de usuario'
              value={user.role_id}
              onChange={addRole}
              id='role'
              name='role'
              options={merchantRoles}
            />
            {!user.role_id && (
              <div className='k-ds-caption-01 error-caption'>
                Es necesario completar este dato
                {formErrors.role_id[0]
                  ? formErrors.role_id[0]
                  : 'Es necesario completar este dato'}
              </div>
            )}
          </div>

          <TextInput
            placeholder='Escribe aquí'
            label='Código o número de empleado único:'
            helper='Este código será el identificador de cada empleado.'
            className='text-top-0 text-field'
            value={user.userCode}
            onChange={e =>
              validateDataOnChange(e.target.name, {
                user_code: e.target.value,
              })
            }
            onBlur={e =>
              validateDataOnChange(e.target.name, {
                user_code: e.target.value,
              })
            }
            id='user_code'
            name='user_code'
            errorMessage={formErrors.userCode}
          />
        </Space>
        <Space direction='vertical' className='user-container k-ds-width-full'>
          <div className='k-ds-heading-04 k-ds-m-bottom-sp-07'>
            Datos del usuario
          </div>
          <Space
            direction={isMobileOnly ? 'vertical' : 'horizontal'}
            className='k-ds-width-full'
            justify='space-between'
          >
            <Space direction='vertical' className='k-ds-width-full'>
              <TextInput
                required
                placeholder='José'
                label='Nombre(s)'
                className={
                  isMobileOnly
                    ? 'text-field full-field'
                    : 'text-field field-m-right'
                }
                value={user.first_name}
                onChange={e =>
                  validateDataOnChange(e.target.name, {
                    first_name: e.target.value,
                  })
                }
                onBlur={e =>
                  validateDataOnChange(e.target.name, {
                    first_name: e.target.value,
                  })
                }
                id='first_name'
                name='first_name'
                errorMessage={formErrors.first_name}
              />
              <TextInput
                required
                placeholder='Lopéz'
                label='Apellido Paterno'
                className={
                  isMobileOnly
                    ? 'text-field full-field'
                    : 'text-field field-m-right'
                }
                value={user.last_name}
                onBlur={e =>
                  validateDataOnChange(e.target.name, {
                    last_name: e.target.value,
                  })
                }
                onChange={e =>
                  validateDataOnChange(e.target.name, {
                    last_name: e.target.value,
                  })
                }
                id='last_name'
                name='last_name'
                errorMessage={formErrors.last_name}
              />
              <TextInput
                placeholder='Vega'
                label='Apellido Materno'
                className={
                  isMobileOnly
                    ? 'text-field full-field'
                    : 'text-field field-m-right'
                }
                value={user.maiden_name}
                onBlur={e =>
                  validateDataOnChange(e.target.name, {
                    maiden_name: e.target.value,
                  })
                }
                onChange={e =>
                  validateDataOnChange(e.target.name, {
                    maiden_name: e.target.value,
                  })
                }
                id='maiden_name'
                name='maiden_name'
                errorMessage={formErrors.maiden_name}
              />
            </Space>
            <Space
              direction='vertical'
              className='k-ds-width-full right-fields'
            >
              <TextInput
                placeholder='JLLV770222MMSJNR00'
                label='CURP'
                className={
                  isMobileOnly
                    ? 'text-field full-field'
                    : 'text-field field-m-left'
                }
                value={user.curp}
                onBlur={e =>
                  validateDataOnChange(e.target.name, { curp: e.target.value })
                }
                onChange={e =>
                  validateDataOnChange(e.target.name, { curp: e.target.value })
                }
                id='curp'
                name='curp'
                errorMessage={formErrors.curp}
              />
              <TextInput
                required
                placeholder='correo@ejemplo.com'
                label='Correo electrónico'
                className={
                  isMobileOnly
                    ? 'text-field full-field'
                    : 'text-field field-m-left'
                }
                value={user.email}
                onBlur={e =>
                  validateDataOnChange(e.target.name, { email: e.target.value })
                }
                onChange={e =>
                  validateDataOnChange(e.target.name, { email: e.target.value })
                }
                id='email'
                name='email'
                errorMessage={formErrors.email}
              />
              <TextInput
                placeholder='551 234 5678'
                label='Teléfono celular'
                className={
                  isMobileOnly
                    ? 'text-field full-field'
                    : 'text-field field-m-left'
                }
                value={user.phone_number}
                onBlur={handlePhoneBlur}
                onChange={e =>
                  validateDataOnChange(e.target.name, {
                    phone_number: e.target.value,
                  })
                }
                id='phone_number'
                name='phone_number'
                errorMessage={formErrors.phone_number}
              />
            </Space>
          </Space>
        </Space>
        {hideStoreSection ? null : (
          <Space direction='vertical' className='store-container'>
            <div className='k-ds-heading-04 k-ds-m-bottom-sp-07'>
              Sucursales asignadas
            </div>
            <Space className='k-ds-width-full orientation'>
              <Space direction='vertical' className='select-container'>
                <Select
                  required
                  placeholder='Seleccionar'
                  label='Sucursal asignada'
                  className='select'
                  value={user?.stores?.[0]}
                  onChange={insertStoreToList}
                  id='store'
                  name='store'
                  options={merchantStores}
                />
                {!user?.stores?.[0] && (
                  <div className='k-ds-caption-01 error-caption'>
                    {formErrors?.stores?.[0]
                      ? t('users.' + formErrors?.stores?.[0])
                      : 'Es necesario completar este dato'}
                  </div>
                )}
              </Space>
              {user?.stores &&
                user?.stores?.length > 0 &&
                user?.role_name !== salesmanRoleLabel && (
                  <Space
                    justify='center'
                    onClick={openModal}
                    className={`${
                      isMobileOnly ? 'button-top-margin' : ''
                    } add-stores-button k-ds-heading-02`}
                  >
                    <AddIcon
                      className={'k-ds-m-right-sp-05 no-margin-left-mobile'}
                    />
                    Agregar más sucursales
                  </Space>
                )}
            </Space>
          </Space>
        )}

        <Space className='k-ds-m-bottom-sp-09 tags-container'>
          {user?.role_name !== SALES_ROLE &&
            user?.stores?.map((storeId, index) => {
              return (
                index != 0 && (
                  <StoreTag
                    storeName={
                      merchantStores.find(store => store.id === storeId)?.text
                    }
                    key={index}
                    onClick={() => removeStore(storeId)}
                  />
                )
              );
            })}
        </Space>
        <Space
          className='k-ds-m-bottom-sp-03 k-ds-width-full'
          direction='horizontal'
          justify='start'
        >
          <Button
            type='primary-alternate'
            className='k-ds-m-rigth-sp-02 create-button'
            horizontalPadding='1.125rem'
            verticalPadding='1rem'
            size='small'
            onClick={handleSubmit}
            disabled={disabledButtonForm}
          >
            Guardar cambios
          </Button>
          <Button
            type='simple'
            size='small'
            horizontalPadding='1.375rem'
            verticalPadding='1rem'
            onClick={handleCancel}
          >
            Cancelar
          </Button>
        </Space>
      </Space>
      <AddStoresModal
        isModalopen={isModalOpen}
        close={closeModal}
        onRemoveTag={removeStore}
        onAdd={addStoreToList}
        stores={merchantStores}
        storesToAdd={user?.stores}
      />
    </>
  );

  function handleSubmit() {
    const finalUser = {
      ...user,
      phone_number: phoneSpaceFormatted(user?.phone_number || ''),
      is_valid: true,
      errors: [],
    };
    setUser(finalUser);
    onSaveUser(finalUser);
    closeEdit();
  }

  function handleCancel() {
    setUser(userData);
    closeEdit();
  }

  function useMerchantRoles(merchantId) {
    const [merchantRoles, setMerchantRoles] = useState([]);

    useEffect(() => {
      getMerchantRoles(merchantId)
        .then(response => {
          const { data } = response;
          setMerchantRoles(data?.roles);
        })
        .catch(err => {
          AnalyticsEvent().errorTrack({
            errorType: 'user-import-get-merchant-roles-error',
            errorDescription: err,
          });
          console.log(err);
        });
    }, []);

    return merchantRoles;
  }

  function validateDataOnChange(field, val) {
    setUser({ ...user, ...val });
    switch (field) {
      case 'first_name':
        setFormErrors({
          ...formErrors,
          ...{
            first_name: validateNameInput(val.first_name, 'Nombre inválido'),
          },
        });
        break;
      case 'last_name':
        setFormErrors({
          ...formErrors,
          ...{
            last_name: validateNameInput(
              val.last_name,
              'Apellido Paterno inválido'
            ),
          },
        });
        break;
      case 'maiden_name':
        setFormErrors({
          ...formErrors,
          ...{
            maiden_name: canBeEmpty(
              val.maiden_name,
              validateNameInput(val.maiden_name, 'Apellido Materno inválido')
            ),
          },
        });
        break;

      case 'email':
        setFormErrors({
          ...formErrors,
          ...{
            email: validateEmailField(val.email, 'Correo Electrónico inválido'),
          },
        });
        break;
      case 'curp':
        setFormErrors({
          ...formErrors,
          ...{
            curp: canBeEmpty(
              val.curp,
              validateCurp(val.curp, 'Este campo tiene un formato inválido')
            ),
          },
        });
        break;
      case 'phone_number':
        setFormErrors({
          ...formErrors,
          ...{
            phone_number: canBeEmpty(
              val.phone_number,
              validatePhoneNumber(
                val.phone_number,
                'Deben ser 10 dígitos exactos'
              )
            ),
          },
        });
        break;
    }
  }

  function filterStores(stores) {
    return stores
      .sort((a, b) => (a.name > b.name) - (a.name < b.name))
      .map(store => {
        return {
          value: store.store_id,
          text: store.name,
          id: store.store_id,
        };
      });
  }

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  function addRole(roleId) {
    const roleErrors = {
      ...formErrors,
      role_id: '',
    };

    const roleSelected = merchantRoles.find(
      role => role.value === roleId.toString()
    );

    const isGenAdmin = roleSelected.name === GENERAL_ADMIN;

    const newUser = {
      ...user,
      stores: isGenAdmin ? [] : user.stores,
      role_name: roleSelected?.text,
      role_id: roleId,
      errors: roleErrors,
    };

    setUser(newUser);

    setFormErrors(roleErrors);

    setUser(newUser);
    onSaveUser(newUser);
  }

  function insertStoreToList(storeId) {
    let storesToAdd = user?.stores;
    const storeName = merchantStores.find(store => store.id === storeId)?.text;

    if (storesToAdd.includes(storeId)) {
      const index = storesToAdd.indexOf(storeId);
      storesToAdd.splice(index, 1);
    }

    storesToAdd.splice(0, 1, storeId);

    const storeErrors = {
      ...formErrors,
      stores: '',
    };

    const newUser = {
      ...user,
      store_name: storeName,
      stores: storesToAdd,
      errors: storeErrors,
    };

    setFormErrors(storeErrors);

    setUser(newUser);
    onSaveUser(newUser);
  }

  function addStoreToList(storeId) {
    let storesToAdd = user?.stores;

    if (!storesToAdd.includes(storeId)) {
      storesToAdd.push(storeId);

      setUser({
        ...user,
        stores: storesToAdd,
      });
    }
  }

  function removeStore(storeId) {
    let storesToAdd = user?.stores;

    if (storesToAdd.includes(storeId)) {
      const index = storesToAdd.indexOf(storeId);
      storesToAdd.splice(index, 1);

      setUser({
        ...user,
        stores: storesToAdd,
      });
    }
  }
};

StoreTag.propTypes = {
  storeName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

UserCrudForm.propTypes = {
  userData: PropTypes.object.isRequired,
  closeEdit: PropTypes.func,
  onSaveUser: PropTypes.func,
  stores: PropTypes.array,
};

export default UserCrudForm;
