import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDeviceContextViewport } from '../../../contexts/Device/DeviceContext';
import { Modal, Space } from '../../../ds';
import LoaderBlue from './loader-blue.gif';
import './cancelProgress.scss';

const CancelInProgress = ({ isOpen }) => {
  const viewport = useDeviceContextViewport();

  const isMobileOnly = useMemo(
    () => viewport.isMobile && !viewport.isSmallTablet,
    [viewport]
  );

  return (
    <Modal
      className={cn('cancel-in-progress', {
        ['mobile-modal']: isMobileOnly,
      })}
      theme='dark'
      cardType='secondary'
      {...{ isOpen: isOpen }}
    >
      <div className='cancel-in-progres-content k-ds-width-full'>
        <div>
          <Space justify='center' direction='vertical' align='center'>
            <img src={LoaderBlue} alt='loading...' />
          </Space>
          <Space direction='vertical' justify='center' align='center'>
            <div className='k-ds-heading-05'>Cancelación en proceso</div>
          </Space>
          <Space
            className='k-ds-body-01 k-ds-m-top-sp-03 cancel-in-progress-body'
            align='center'
            justify='center'
          >
            El estado de la transacción se actualizará en unos minutos. Si no
            ves los cambios, actualiza la página.
          </Space>
        </div>
      </div>
    </Modal>
  );
};

CancelInProgress.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default CancelInProgress;
