import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  newAlphaNumericValidation,
  phoneInputCleanFormatted,
  phoneSpaceFormatted,
  validateMaxChars,
  validatePhoneNumber,
} from '../../../Utils/formValidations';
import { useToastContext } from '../../../contexts/Toast/ToastContext';
import { Button, Divider, Space, TextInput } from '../../../ds';
import { bulkValidateStore, getMallList } from '../../../services/Application';
import { Select } from '../../DS/Select';
import MapContainer, { geocodeZipcode } from '../../Maps/MapsContainer';
import StoreSchedule from '../../StoreCreateEdit/StoreSchedule';
import ZipCodeForm from '../../ZipCodeForm/ZipCodeForm';
import styles from './FormEditStore.module.scss';
import { NAME_STORE_ID_FIELD } from '../../StoreCreateEdit/StoreFields';
import AnalyticsEvent from '../../../services/analytics/AnalyticsEvent';

const messageError = 'Este campo no puede quedar vacío.';

const resolverError = (t, error) => {
  if (!error.length) return '';
  const translatedMsg = t(`stores.${error}`, { defaultValue: messageError });
  return translatedMsg;
};

const FormEditStore = ({ data, updateStores, closeForm }) => {
  const toast = useToastContext();
  const { t } = useTranslation();

  const [centerMap, setCenterMap] = useState(null);
  const [scheduleIsValid, setScheduleIsValid] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [zipCodeInfoValid, setZipCodeInfoValid] = useState(false);
  const [currentZipCode, setCurrentZipCode] = useState('');

  const [mallList, setMallList] = useState([]);
  const [formData, setFormData] = useState(() => {
    if (!data) return {};

    return {
      ...data,
      phone_number: phoneInputCleanFormatted(data?.phone_number || ''),
    };
  });

  const [formErrors, setFormErrors] = useState({
    name: resolverError(t, data?.errors?.name?.[0] ?? ''),
    street: data?.errors?.address_attributes?.street?.length
      ? messageError
      : '',
    ext_number: data?.errors?.address_attributes?.ext_number?.length
      ? messageError
      : '',

    inner_number: data?.errors?.address_attributes?.inner_number?.length
      ? messageError
      : '',
    phone_number: resolverError(t, data?.errors?.phone_number?.[0] || ''),
  });

  const {
    name,
    phone_number,
    address_attributes,
    store_schedule_attributes,
    external_store_id,
  } = formData || {};

  useEffect(() => {
    if (address_attributes.city === '') return;

    let cityToSearch = address_attributes.city;

    if (address_attributes.state === 'Ciudad de México') {
      cityToSearch = address_attributes.state;
    }

    getMallList([cityToSearch]).then(response => {
      setMallList(
        response?.data?.map(mall => {
          return {
            value: mall.mall_id,
            text: mall.name,
            city: mall.city,
          };
        }) ?? []
      );
    });
  }, [address_attributes.city]);

  const handleInputName = (event, name) => {
    const { value = '' } = event.target;

    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddress = event => {
    const { name, value = '' } = event.target;
    setFormData(prev => ({
      ...prev,
      address_attributes: { ...address_attributes, [name]: value },
    }));
  };

  const onScheduleChange = (schedule, isValid) => {
    setFormData({
      ...formData,
      store_schedule_attributes: {
        schedule: schedule,
      },
    });
    setScheduleIsValid(isValid);
  };

  const clearCoordinates = () => {
    setFormData({
      ...formData,
      address_attributes: {
        ...formData.address_attributes,
        coordinates: null,
      },
    });
  };

  const onZipCodeChange = (zipCodeInfo, valid) => {
    setFormData({
      ...formData,
      address_attributes: {
        ...formData.address_attributes,
        zip_code: zipCodeInfo.zipCode,
        neighborhood: zipCodeInfo.neighborhood,
        city: zipCodeInfo.city,
        state: zipCodeInfo.state,
      },
    });
    setZipCodeInfoValid(valid);

    if (zipCodeInfo.zipCode.length >= 5) {
      if (zipCodeInfo.zipCode !== currentZipCode) {
        clearCoordinates();
        setCurrentZipCode(zipCodeInfo.zipCode);
      }

      geocodeZipcode(
        `${zipCodeInfo.zipCode} ${zipCodeInfo.city} ${zipCodeInfo.state}`
      ).then(response => setCenterMap(response));
    }
  };

  const handlePhoneBlur = event => {
    const inputValue = event.target.value;
    const formattedInputValue = phoneInputCleanFormatted(inputValue);

    setFormData({ ...formData, ...{ phone_number: formattedInputValue } });
    handleBlur(event);
  };

  const handleBlur = event => {
    const { name, value = '', required } = event.target;

    if (!required && value === '') {
      return;
    }

    if (required && value === '') {
      setFormErrors({
        ...formErrors,
        [name]: messageError,
      });
      return;
    }

    let error = '';
    switch (name) {
      case 'name': {
        ////^[áéíóúÁÉÍÓÚA-Za-z0-9\s\\+]*$/
        error = newAlphaNumericValidation(value)
          ? ''
          : 'El nombre solo debe contener caracteres alfanuméricos, espacios y guiones.';
        break;
      }
      case 'street': {
        error = newAlphaNumericValidation(value)
          ? ''
          : 'Este campo solo debe contener caracteres alfanuméricos, espacios y guiones.';
        break;
      }
      case 'ext_number': {
        error = newAlphaNumericValidation(value)
          ? ''
          : 'Este campo no permite caracteres especiales';
        break;
      }
      case 'inner_number': {
        error = newAlphaNumericValidation(value)
          ? ''
          : 'Este campo solo debe contener caracteres alfanuméricos, espacios y guiones.';
        break;
      }

      case 'phone_number': {
        error = validatePhoneNumber(value, 'Deben ser 10 dígitos exactos');
        break;
      }

      case 'external_store_id': {
        error = newAlphaNumericValidation(value)
          ? ''
          : 'Este campo no permite caracteres especiales';
        if (error === '')
          error = validateMaxChars(value, NAME_STORE_ID_FIELD.maxChars);
        break;
      }
    }

    setFormErrors({ ...formErrors, [name]: error });
  };

  const onSubmit = () => {
    if (!zipCodeInfoValid) {
      toast('Es necesario que revises los datos del código postal', 'error');
      return;
    }

    const schdle = store_schedule_attributes?.schedule || {};
    const totalSchedule = Object.keys(schdle);
    const closedDays = Object.keys(schdle).filter(
      item => schdle[item]?.[0].active === false
    );

    if (
      (store_schedule_attributes?.schedule === 0 &&
        store_schedule_attributes.schedule.constructor === Object) ||
      closedDays.length === totalSchedule.length
    ) {
      setScheduleIsValid(false);
      toast('Es necesario que revises los horario de atención', 'error');
      return;
    }

    let cordinates = {};

    if (address_attributes?.coordinates) {
      cordinates = { coordinates: address_attributes?.coordinates };
    }

    let store = [
      {
        merchant_id: formData.merchant_id,
        name: formData.name,
        store_type: 'physical',
        platform: 'kueskipay',
        timezone: 'Guadalajara',
        active: 'true',
        external_store_id: formData.external_store_id,
        phone_number: phoneSpaceFormatted(formData?.phone_number),
        address_attributes: {
          street: address_attributes?.street,
          ext_number: address_attributes?.ext_number,
          inner_number: address_attributes?.inner_number,
          zip_code: address_attributes?.zip_code,
          neighborhood: address_attributes?.neighborhood,
          city: address_attributes?.city,
          state: address_attributes?.state,
          mall_id: address_attributes.mall_id,
          ...cordinates,
        },

        store_schedule_attributes: {
          ...store_schedule_attributes,
        },
      },
    ];

    setIsValidating(true);
    bulkValidateStore({
      stores: store,
    })
      .then(response => {
        if (response.status === 'success') {
          store.map(store => {
            store['is_valid'] = true;
            store['unique'] = formData.unique;
          });
        }
      })
      .catch(error => {
        const errors = error.response.data.errors.stores ?? {};
        AnalyticsEvent().errorTrack({
          errorType: 'edit-store-validation-error',
          errorDescription: errors,
        });
        store.map((store, indx) => {
          if (errors[indx]) {
            store['errors'] = errors[indx];
            store['is_valid'] = false;
            store['unique'] = formData.unique;
          }
        });
      })
      .finally(() => {
        const finalStore = store.map(item => {
          const mallInfo = mallList.filter(
            mallItem => mallItem.value === item?.address_attributes.mall_id
          );

          return {
            ...item,
            address_attributes: {
              ...item.address_attributes,
              mall_name: mallInfo?.[0]?.text ?? '',
            },
          };
        });

        toast('Se ha actualizado la información de la sucursal');
        setIsValidating(false);

        updateStores(finalStore[0]);
      });
  };

  const handleChnageMall = mallId => {
    setFormData({
      ...formData,
      address_attributes: {
        ...address_attributes,
        mall_id: mallId,
      },
    });
  };

  const handleUpdateCoord = coordinates => {
    setFormData({
      ...formData,
      address_attributes: {
        ...address_attributes,
        coordinates: coordinates?.toString() ?? null,
      },
    });
  };

  return (
    <Space className={styles.container} direction='vertical'>
      <p className='k-ds-heading-03'>
        ¿Cuál es el nombre completo de la sucursal?
      </p>

      <div className={styles.column}>
        <TextInput
          required
          label='Nombre de la sucursal:'
          className='form-item'
          type='outline'
          placeholder='Antara Polanco Fashion Hall Local A'
          name='name'
          value={name}
          errorMessage={formErrors['name']}
          onChange={e => handleInputName(e, 'name')}
          onBlur={handleBlur}
        />
        <TextInput
          type='outline'
          className='form-item'
          name={NAME_STORE_ID_FIELD.name}
          errorMessage={formErrors[NAME_STORE_ID_FIELD.name]}
          required={NAME_STORE_ID_FIELD.required}
          label={NAME_STORE_ID_FIELD.label}
          placeholder={NAME_STORE_ID_FIELD.placeholder}
          value={external_store_id}
          onChange={e => handleInputName(e, NAME_STORE_ID_FIELD.name)}
          onBlur={handleBlur}
        />
      </div>

      <Divider className={styles.customDivider} />

      <p className='k-ds-heading-03'>Dirección de la sucursal</p>
      <div className={styles.column}>
        <TextInput
          required
          label='Calle:'
          className='form-item'
          placeholder='Avenida Ejército Nacional'
          type='outline'
          name='street'
          errorMessage={formErrors['street']}
          value={address_attributes?.street}
          onChange={handleAddress}
          onBlur={handleBlur}
        />
        <TextInput
          required
          label='Número exterior:'
          className='form-item'
          type='number'
          placeholder='843'
          name='ext_number'
          errorMessage={formErrors['ext_number']}
          value={address_attributes?.ext_number}
          onChange={handleAddress}
          onBlur={handleBlur}
        />
      </div>

      <div className={classNames(styles.column, styles.customColum)}>
        <TextInput
          label='Número interior / Local:'
          className='form-item'
          placeholder='32'
          name='inner_number'
          errorMessage={formErrors['inner_number']}
          value={address_attributes?.inner_number}
          onChange={handleAddress}
          onBlur={handleBlur}
        />
        <ZipCodeForm
          onChange={onZipCodeChange}
          errors={data?.errors?.address_attributes || {}}
          zipCode={address_attributes.zip_code}
          city={address_attributes.city}
          state={address_attributes.state}
          neighborhood={address_attributes.neighborhood}
        />
        <TextInput
          label='Teléfono de sucursal:'
          className='form-item'
          type='outline'
          placeholder='55 55012 0394'
          name='phone_number'
          value={phone_number}
          errorMessage={formErrors['phone_number']}
          onChange={e => handleInputName(e, 'phone_number')}
          onBlur={handlePhoneBlur}
        />
      </div>

      <p className='k-ds-heading-03'>
        ¿En qué centro comercial se encuentra la sucursal?
      </p>

      <div className={styles.column}>
        <Select
          name='mall_id'
          label='Seleccionar centro comercial'
          className='form-item'
          value={formData.address_attributes.mall_id}
          options={mallList}
          onChange={handleChnageMall}
          placeholder='Seleccionar'
          type='outline'
        />
      </div>

      <MapContainer
        onUpdateCoords={handleUpdateCoord}
        markerCoord={formData?.address_attributes?.coordinates}
        centerMap={centerMap}
      />

      <Divider className={styles.customDivider} />

      <StoreSchedule
        isNew={
          Object.keys(data?.store_schedule_attributes.schedule).length === 0
        }
        onChange={onScheduleChange}
        schedule={store_schedule_attributes.schedule}
      />

      <div className='table-warning-container'>
        {!scheduleIsValid && (
          <span className='empty-week-warning k-ds-text-color-red-700'>
            Es necesario que tengas al menos un horario de atención disponible
            para guardar la sucursal
          </span>
        )}
      </div>

      <section className='store-create-edit-section button-section'>
        <Button
          type='primary-alternate'
          onClick={onSubmit}
          disabled={isValidating}
        >
          Guardar
        </Button>
        <Button type='ghost' onClick={closeForm}>
          Cancelar
        </Button>
      </section>
    </Space>
  );
};

FormEditStore.propTypes = {
  data: PropTypes.object.isRequired,
  updateStores: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
};

export default FormEditStore;
