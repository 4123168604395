import React, { useEffect, useRef } from 'react';
import { Divider, Space } from '../../../ds';
import './helpPage.scss';
import Text from './text.json';
import AnalyticsEvent, {
  AnalyticsList,
} from '../../../services/analytics/AnalyticsEvent';
import { ReactComponent as Phone } from './phone-fill.svg';
import { ReactComponent as Whatsapp } from './whatsapp-fill.svg';
import { ReactComponent as PinLocation } from './pin-location.svg';
import { ReactComponent as Online } from './online.svg';
import { ReactComponent as Instore } from './merchantStore-instore.svg';

function CustomerService() {
  const isTracked = useRef(false);

  useEffect(() => {
    if (!isTracked.current) {
      AnalyticsEvent().pageView({ page: AnalyticsList.customerService });
      isTracked.current = true;
    }
  }, []);

  return (
    <div className='help-page'>
      <div className='support-header'>
        <div className='support-header-mobile-title'>{Text.mobile_header}</div>
        <div className='support-header-desktop-title'>{Text.heading7}</div>
        <div className='k-ds-body-02'>{Text.subHeading}</div>
      </div>

      <Space direction='vertical' className='support-container'>
        <div className='support-title'>{Text.businessHeading}</div>

        <div className='support-item'>
          <div className='support-scope-item'>
            <Instore className='icon' />
            <div>
              <div className='support-store-name'>
                <span className='support-name'>Tienda Física</span>
              </div>
              <div className='support'>
                <a
                  href={`https://api.whatsapp.com/send?phone=${Text.businessPhoneClick}`}
                >
                  <div className='support-whatsapp'>
                    <Whatsapp className='icon' />
                    {Text.businessPhone}
                  </div>
                </a>
                <div className='support-phone'>
                  <Phone className='icon' />
                  {Text.businessPhone}
                </div>
              </div>

              <div className='support-email'>{Text.businessEmail}</div>

              <div className='support-schedule'>
                <div className='support-schedule-day'>{Text.schedule}</div>
                <div className='support-schedule-hours'>
                  {Text.scheduleHours}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='support-item'>
          <div className='support-scope-item'>
            <Online className='icon' />
            <div>
              <div className='support-store-name'>
                <span className='support-name'>Tienda en línea</span>
              </div>
              <div className='support'>
                <a
                  href={`https://api.whatsapp.com/send?phone=${Text.businessPhoneOnlineClick}`}
                >
                  <div className='support-whatsapp'>
                    <Whatsapp className='icon' />
                    {Text.businessPhoneOnline}
                  </div>
                </a>
                <div className='support-phone'>
                  <Phone className='icon' />
                  {Text.businessPhoneOnline}
                </div>
              </div>
              <div className='support-email'>{Text.businessEmailOnline}</div>
              <div className='support-schedule'>
                <div className='support-schedule-day'>{Text.schedule}</div>
                <div className='support-schedule-hours'>
                  {Text.scheduleHours}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Space>

      <div className='support-item'>
        <div className='support-title'>{Text.clientHeading}</div>
        <div className='support-scope-item'>
          <PinLocation className='icon' />
          <div>
            <div className='support-scope'>{Text.scope}</div>
            <div className='support-phone'>
              <Phone className='icon' />
              {Text.clientPhone}
            </div>
            <div className='support-email'>{Text.clientEmail}</div>
            <div className='support-schedule'>
              <div className='support-schedule-day'>{Text.schedule}</div>
              <div className='support-schedule-hours'>
                {Text.schedule24Hours}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Divider className='footer-items-divider' />

      <div className='footer-items-container'>
        <div className='footer-item'>
          <a
            href='https://kueski.com/terminosdeuso'
            className='k-ds-text-color-interactive-primary k-ds-m-right-sp-02'
          >
            Términos y Condiciones
          </a>
          y
          <a
            href='https://kueski.com/politicas'
            className='k-ds-text-color-interactive-primary k-ds-m-left-sp-02'
          >
            Aviso de Privacidad
          </a>
        </div>
        <div className='footer-item company-name'>
          KUESKI S.A.P.I. DE C.V. SOFOM, E.N.R.
        </div>
      </div>
    </div>
  );
}

export default function HelpPage() {
  return <CustomerService />;
}
