import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { daysOfTheWeek } from '../../Utils/constants';
import { useUserRole } from '../../Utils/customHooks';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { useLoaderContext } from '../../contexts/Loader/LoaderContext';
import { Button, Card, Space } from '../../ds';
import { getMallList } from '../../services/Application';
import GetBackSection from '../GetBackSection';
import Header from '../Header';
import MapContainer from '../Maps/MapsContainer';
import NotFound from '../NotFound';
import StateSection from '../StateSection';
import { ReactComponent as EditIcon } from './edit-line.svg';
import { ReactComponent as StoreIcon } from './store-icon.svg';
import './storeDetail.scss';
import { useKStore, useStoreUserInsights } from './useStoreHook';
import { ReactComponent as UserIcon } from './user-icon.svg';

function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) return '-';

  const cleanedNumber = phoneNumber.replace(/\D/g, '');
  const formattedNumber = cleanedNumber.replace(
    /(\d{3})(\d{3})(\d{4})/,
    '$1 $2 $3'
  );
  return <a href={`tel:${formattedNumber}`}>{formattedNumber}</a>;
}

const getCoodinates = store => {
  if (!store) return null;

  return `${store?.address_attributes?.coordinates?.x}, ${store?.address_attributes?.coordinates?.y}`;
};

function StoreDetail() {
  const { storeId } = useParams();
  const userRole = useUserRole();
  const viewport = useDeviceContextViewport();
  const { isLoaderOpen, setIsLoaderOpen } = useLoaderContext();
  const storeUserInsights = useStoreUserInsights(storeId);

  const kStore = useKStore(storeId, setIsLoaderOpen);

  const orderDetailsDirection = viewport.isMobile ? 'vertical' : 'horizontal';
  const orderDetailsAlign = viewport.isMobile ? 'stretch' : 'start';

  const { storeService, showNotFound } = useMemo(() => {
    return { ...kStore };
  }, [kStore]);

  const coordinates = getCoodinates(storeService);

  if (isLoaderOpen) return '';

  return storeService ? (
    <>
      <div className='k-ds-width-full store-detail k-ds-text-color-primary'>
        <GetBackSection
          redirectUri='/stores'
          render={userRole === 'general_admin'}
        />
        <Space
          direction={orderDetailsDirection}
          size='large'
          justify='space-around'
          align={orderDetailsAlign}
          className='k-ds-width-full'
        >
          <Header title='Detalle de sucursal' />
          <Space className='edit-store-button-container k-ds-width-full'>
            <EditButton redirectUri={`/stores/${storeId}/edit`} />
          </Space>
        </Space>
        <Space
          className='store-card-container k-ds-width-full'
          direction={viewport.isMobile ? 'vertical' : 'horizontal'}
        >
          <StoreCard store={storeService} />
          <StoreUsersCard
            cardText={'Usuarios'}
            storeId={storeId}
            insights={storeUserInsights}
          />
        </Space>
        <div className='k-ds-m-vertical-sp-09'>
          <MapContainer
            disabledMap
            centerMap={null}
            markerCoord={coordinates}
            customHeight='400px'
          />
        </div>

        <StateSection
          title={'Estatus de la sucursal'}
          isActive={storeService?.active}
        />

        <div className='k-ds-heading-04 k-ds-m-bottom-sp-06'>
          Teléfono de sucursal:{' '}
          <span>{formatPhoneNumber(storeService?.phone_number)}</span>
        </div>

        <StoreScheduleSection store={storeService} />
      </div>
    </>
  ) : (
    showNotFound && <NotFound />
  );
}

function EditButton(props) {
  const router = useHistory();
  const [isHovering, setIsHovering] = React.useState(false);

  return (
    <Button
      type='outline'
      size='small'
      onClick={() => router.push(props.redirectUri)}
      onMouseOver={() => setIsHovering(true)}
      onMouseOut={() => setIsHovering(false)}
    >
      <EditIcon
        className='k-ds-m-rigth-sp-03'
        fill={isHovering ? '#FFFFFF' : '#0075FF'}
      />
      Editar
    </Button>
  );
}

EditButton.propTypes = {
  redirectUri: PropTypes.string.isRequired,
};

function StoreCard(props) {
  const [mallSelected, setMallSelected] = useState(null);

  const { store } = props;
  if (!props.store) {
    return null;
  }

  useEffect(() => {
    getMallList([store.address_attributes.city]).then(response => {
      setMallSelected(
        response.data.find(
          item => item.mall_id === store.address_attributes.mall_id
        )
      );
    });
  }, []);

  return (
    <Space direction={'horizontal'} className='store-card-container-item'>
      <Card className='store-card k-ds-m-right-sp-04'>
        <Space
          direction='vertical'
          className='store-card-container-icon'
          align='center'
          justify='center'
        >
          <Space
            className='store-card-icon'
            direction='vertical'
            align='center'
          >
            <StoreIcon />
          </Space>
        </Space>
      </Card>
      {store.address_attributes && (
        <Space
          direction='vertical'
          className='k-ds-subheading-02'
          justify='center'
        >
          <div className='k-ds-heading-03 k-ds-m-top-sp-03 k-ds-m-bottom-sp-02 header-bolder'>
            {store.name}
          </div>
          <div>{mallSelected?.name} </div>

          <div>{store.address_attributes.street}</div>
          <div>
            {store.address_attributes.neighborhood}
            {`, C.P. ${store.address_attributes.zip_code}`}
          </div>
          <div>{store.address_attributes.city}</div>
        </Space>
      )}
    </Space>
  );
}

StoreCard.propTypes = {
  store: PropTypes.object.isRequired,
};

function StoreUsersCard(props) {
  const router = useHistory();

  return (
    <Space direction={'horizontal'} className='store-card-container-item'>
      <Card className='store-card k-ds-m-right-sp-04'>
        <Space
          direction='vertical'
          className='store-card-container-icon'
          align='center'
          justify='center'
        >
          <Space
            className='store-card-icon store-user-card-icon'
            direction='vertical'
            align='center'
          >
            <UserIcon />
          </Space>
        </Space>
      </Card>
      <Space
        direction='vertical'
        className='k-ds-subheading-02'
        justify='center'
      >
        <div className='k-ds-heading-03 k-ds-m-top-sp-03 k-ds-m-bottom-sp-04 header-bolder'>
          Usuarios por rol
        </div>
        {
          // show first 3 roles only to avoid design issues
          props.insights?.slice(0, 3)?.map(insight => {
            return (
              <div
                key={insight.role_name}
              >{`${insight.role_name}: ${insight.count}`}</div>
            );
          })
        }
        <div>
          <Button
            type='link'
            horizontalPadding='0px'
            verticalPadding='1rem'
            onClick={() => router.push(`/stores/${props.storeId}/users`)}
          >
            Ver detalles
          </Button>
        </div>
      </Space>
    </Space>
  );
}

StoreUsersCard.propTypes = {
  storeId: PropTypes.string.isRequired,
  insights: PropTypes.arrayOf(PropTypes.object),
};

function StoreScheduleSection(props) {
  if (!props.store) {
    return null;
  }

  const weekdaysItems = [];
  for (let key in daysOfTheWeek) {
    weekdaysItems.push(
      <li className='k-ds-subheading-02' key={key}>
        <span className='store-schedule-item'>{daysOfTheWeek[key]}</span>
        <StoreScheduleItem
          filteredSchedules={props.store.store_schedule_attributes?.schedule}
          itemKey={key}
        />
      </li>
    );
  }

  return (
    <Space className='k-ds-width-full' direction='vertical'>
      <div className='k-ds-heading-04'>Horario de atención</div>
      <div>
        <ul className='store-schedule-container'>{weekdaysItems}</ul>
      </div>
    </Space>
  );
}

StoreScheduleSection.propTypes = {
  store: PropTypes.object.isRequired,
};

const sanitizeScheduleTime = schedule =>
  schedule?.length <= 5 ? schedule : schedule.slice(0, 5);

function StoreScheduleItem(props) {
  const closedSpan = <span>Cerrado</span>;
  if (
    !props.filteredSchedules ||
    !props.itemKey ||
    !props.filteredSchedules[props.itemKey] ||
    props.filteredSchedules[props.itemKey]?.length === 0
  ) {
    return closedSpan;
  }
  const schedule = props.filteredSchedules[props.itemKey][0];
  const extraSchedule =
    props.filteredSchedules[props.itemKey].length > 1
      ? props.filteredSchedules[props.itemKey][1]
      : null;
  if (!schedule.active) return closedSpan;
  return (
    <span>
      {sanitizeScheduleTime(schedule.open)} a{' '}
      {sanitizeScheduleTime(schedule.closed)}{' '}
      {extraSchedule
        ? `y ${sanitizeScheduleTime(
            extraSchedule.open
          )} a ${sanitizeScheduleTime(extraSchedule.closed)}`
        : ''}
    </span>
  );
}

StoreScheduleItem.propTypes = {
  itemKey: PropTypes.string.isRequired,
  filteredSchedules: PropTypes.object,
};

export default StoreDetail;
