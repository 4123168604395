import { useState, useEffect, useRef } from 'react';
import { isProductionStage } from './environment';

const useEffectAfterFirstRender = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

function useIsProductionStage() {
  const stage = useState(isProductionStage());
  return stage;
}

function useUserRole() {
  return 'general_admin';
}

export { useEffectAfterFirstRender, useIsProductionStage, useUserRole };
