import React, { useEffect, useState } from 'react';
import { Pagination, PaginationMobile, Space } from '../../ds';
import Header from '../Header';
import { useLoaderContext } from '../../contexts/Loader/LoaderContext';
import { getCampaigns } from '../../services/Application';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import EmptySearchResults from '../EmptySearchResults';
import CampaignCard from './CampaignCard';
import './Campaigns.scss';
import { getDateTime } from '../../Utils/formats';

const Campaigns = () => {
  const limit = 10;
  const viewport = useDeviceContextViewport();
  const { setIsLoaderOpen } = useLoaderContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({
    total_pages: 1,
    total_items: 0,
    actual_page: 1,
  });
  const [lastSync, setLastSync] = useState('');
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    setIsLoaderOpen(true);

    getCampaigns(currentPage, limit)
      .then(response => {
        setCampaigns(response.data.campaigns);
        setPagination(response.data.pagination);
      })
      .catch(console.error)
      .finally(() => {
        setIsLoaderOpen(false);
        setLastSync(getDateTime(new Date()));
      });
  }, []);

  const PaginationComponent = viewport.isMobile ? PaginationMobile : Pagination;

  const CampaignGrid = () => {
    return (
      <div className='campaign-grid'>
        {campaigns.map(campaign => {
          return (
            <CampaignCard
              key={campaign.campaign_id}
              name={campaign.name}
              description={campaign.description}
              campaign_id={campaign.campaign_id}
              upcoming={campaign.upcoming}
            />
          );
        })}
      </div>
    );
  };

  return (
    <div className='campaigns'>
      <div className='k-ds-width-full'>
        <Space
          direction='horizontal'
          justify='space-around'
          align='left'
          className='k-ds-width-full'
        >
          <Header
            title='Campañas'
            subtitle='A continuación se muestran las campañas vigentes en Kueski, consúlta los detalles y descarga los archivos necesarios.'
          ></Header>
        </Space>
        <Space
          direction='horizontal'
          justify='space-around'
          className='k-ds-m-bottom-sp-06'
        >
          <CampaignGrid />
        </Space>
        {campaigns.length > 0 ? (
          campaigns.length > 10 && (
            <div className='store-list-pagination'>
              <PaginationComponent
                totalPages={pagination.total_pages}
                totalElements={pagination.total_items}
                maxVisibleNumbers={6}
                currentPage={pagination.actual_page}
                elementsPerPage={limit}
                changePage={page => setCurrentPage(page)}
              />
            </div>
          )
        ) : (
          <EmptySearchResults />
        )}
        <Space
          className='k-ds-m-top-sp-09 k-ds-heading-01'
          id='last-sync'
          direction='horizontal'
          justify='end'
        >
          Última Datos actualizados al momento: {lastSync}
        </Space>
      </div>
    </div>
  );
};

export default Campaigns;
