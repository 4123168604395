import PropTypes from 'prop-types';
import React from 'react';
import findByType from '../../../Utils/findByType';
import Footer from '../../../components/Footer';
import { ReactComponent as KueskiPayLogo } from '../../../components/Navbar/kueski-pay-logo.svg';
import { useDeviceContext } from '../../../contexts/Device/DeviceContext';
import { ReactComponent as LoginGraphic } from './login-graphic.svg';

const Mobile = () => null;
const Desktop = () => null;

const LoginLayout = ({ children = null, showLogo = true }) => {
  const { viewport } = useDeviceContext();
  const renderMobile = () => {
    const mobileElement = findByType(children, Mobile);
    if (!mobileElement) return renderDesktop();
    return <>{mobileElement.props.children}</>;
  };

  const renderDesktop = () => {
    const desktopElement = findByType(children, Desktop);
    if (!desktopElement) return null;
    return <>{desktopElement.props.children}</>;
  };

  return (
    <>
      <div className='login-views'>
        {showLogo && (
          <div className='welcome'>
            <div className='k-ds-m-left-sp-07 welcome-logo'>
              <KueskiPayLogo />
            </div>
            <p className='welcome-text k-ds-m-vertical-sp-06 k-ds-m-horizontal-sp-07'>
              Aumenta tus ventas hasta en un 40% y haz que nuevos clientes
              conozcan tu marca con Kueski Pay.
              <a
                target='_blank'
                rel='noreferrer'
                className='link'
                href='https://www.kueskipay.com/para-ti/que-es-kueski-pay'
              >
                Conoce más.
              </a>
            </p>
            <div className='login-graphic-wrapper'>
              <LoginGraphic className='login-graphic k-ds-width-full' />
            </div>
          </div>
        )}
        {!viewport.isDesktop && renderMobile()}
        {viewport.isDesktop && renderDesktop()}
      </div>
      <Footer />
    </>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
  showLogo: PropTypes.bool,
};

LoginLayout.Mobile = Mobile;
LoginLayout.Desktop = Desktop;

export default LoginLayout;
