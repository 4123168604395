import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Auth from '../../Utils/Auth/auth';
import { isFeatureEnabled } from '../../Utils/Feature/feature_flag';
import { getErrorMessageTranslation } from '../../Utils/formValidations';
import { getDateString } from '../../Utils/formats';
import { canEmployeeEdit } from '../../Utils/roles';
import { ReactComponent as GridViewIconSelected } from '../../common/assets/gridViewIcon-selected.svg';
import { ReactComponent as GridViewIcon } from '../../common/assets/gridViewIcon.svg';
import { ReactComponent as ListViewIconSelected } from '../../common/assets/listViewIcon-selected.svg';
import { ReactComponent as ListViewIcon } from '../../common/assets/listViewIcon.svg';
import { ReactComponent as PlusSign } from '../../common/assets/plus-sign.svg';
import { useDeviceContextViewport } from '../../contexts/Device/DeviceContext';
import { useLoaderContext } from '../../contexts/Loader/LoaderContext';
import {
  Avatar,
  Button,
  Pagination,
  PaginationMobile,
  Space,
  Table,
  TableMobile,
  Tag,
} from '../../ds';
import { getMerchantRoles, getMerchantUsers } from '../../services/Application';
import AddUserSelectionModal from '../AddUserSelectionModal/AddUserSelectionModal';
import { Message } from '../DS/Message';
import { SearchBar } from '../DS/SearchBar';
import EmptySearchResults from '../EmptySearchResults';
import Header from '../Header';
import MerchantNameSelected from '../MerchantNameSelected/MerchantNameSelected';
import ToolBar from '../ToolBar/ToolBar';
import UsersFilter from '../UsersFilter/UsersFilter';
import UserCard from './UserCard';
import { ReactComponent as ExportIconMobile } from './export-icon-mobile.svg';
import { ReactComponent as ExportIcon } from './export-icon.svg';
import { ReactComponent as FilterIconMobile } from './filter-icon-mobile.svg';
import { ReactComponent as FilterIcon } from './filter-icon.svg';
import './users.scss';
import AnalyticsEvent, {
  AnalyticsList,
} from '../../services/analytics/AnalyticsEvent';

const getStatusTag = status => {
  const { text, color } = useMemo(() => {
    if (status === 'active') {
      return {
        text: 'ACTIVO',
        color: 'teat',
      };
    }

    if (status === 'inactive') {
      return {
        text: 'INACTIVO',
        color: 'red',
      };
    }

    return {
      text: status,
      color: 'orange',
    };
  }, [status]);

  return (
    <Tag className='store-tag' color={color}>
      {text}
    </Tag>
  );
};

const tableHeaders = {
  name: 'Nombre',
  userName: 'Correo electrónico',
  role: 'Rol',
  storeName: 'Sucursal',
  status: 'Estatus',
  actions: 'Editar',
};
const orderDetailsDirection = 'horizontal';

const UserManagement = () => {
  let history = useHistory();

  const { t } = useTranslation();
  const { setIsLoaderOpen } = useLoaderContext();
  const viewport = useDeviceContextViewport();

  const [isListMode, setIsListMode] = useState(true);
  const [errorSearch, setErrorSearch] = useState(false);
  const [filterBarOpen, setFilterBarOpen] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  // const [paramsData, setParamsData] = useState({
  //   count: null,
  //   newCreated: false,
  // });
  const isTracked = useRef(false);
  const [filters, setFilters] = useState({});
  const [searchState, setSearchState] = useState('');
  const [searchBy, setSearchBy] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({
    total_pages: 1,
    total_items: 0,
    actual_page: 1,
  });
  const errors = getErrorsList(history?.location?.data ?? []);

  const limit = useMemo(() => (isListMode ? 20 : 18), [isListMode]);
  const searchBarWidth = useMemo(
    () => (viewport.isMobile ? '100%' : '437px'),
    [viewport.isMobile]
  );
  const TableComponent = useMemo(
    () => (viewport.isMobile ? TableMobile : Table),
    [viewport.isMobile]
  );
  const PaginationComponent = useMemo(
    () => (viewport.isMobile ? PaginationMobile : Pagination),
    [viewport.isMobile]
  );
  const tableSize = useMemo(
    () => (viewport.isMobile ? 'small' : 'medium'),
    [viewport.isMobile]
  );

  const { merchant, employee } = Auth.userData();

  useEffect(() => {
    if (!isTracked.current) {
      AnalyticsEvent().pageView({ page: AnalyticsList.userManagement });
      isTracked.current = true;
    }
  }, []);

  if (!merchant) {
    return '';
  }

  // useEffect(() => {
  //   const queryParams = new URLSearchParams(history?.location?.search);

  //   if (queryParams.has('created') || queryParams.has('count')) {
  //     setParamsData({
  //       count: queryParams.get('count'),
  //       newCreated: queryParams.get('created'),
  //     });
  //     queryParams.delete('count');
  //     queryParams.delete('created');
  //     history.replace({
  //       search: queryParams.toString(),
  //     });
  //   }
  // }, []);

  const merchantId = merchant.merchant_id;
  const employeeRole = employee?.role?.name;

  const statusFilter = filters.statusFilter || [];
  const loggedFilter = filters.loggedFilter || [];
  const roleFilter = filters.roleFilter || [];
  const statusActiveFilters = statusFilter.filter(status => status.checked);
  const loggedActiveFilters = loggedFilter.filter(log => log.checked);
  const roleActiveFilters = roleFilter.filter(role => role.checked);
  const creationDateActiveFilters =
    filters.startDate && filters.endDate
      ? {
          startDate: filters.startDate,
          endDate: filters.endDate,
        }
      : undefined;
  const translationPrefix = 'users.';

  const fetchStores = (searchBy, currentPage, limit, filterParams) => {
    setIsLoaderOpen(true);
    getMerchantUsers(searchBy, currentPage, limit, filterParams)
      .then(response => {
        setUsers(response.data.users);
        setPagination(response.data.pagination);
      })
      .finally(() => {
        setIsLoaderOpen(false);
      });
  };

  useEffect(() => {
    const merchantInfo = JSON.parse(localStorage?.getItem('merchantSelected'));

    const filterParams = {
      statusActiveFilters,
      loggedActiveFilters,
      roleActiveFilters,
      creationDateActiveFilters,
      merchant_id: merchantInfo?.merchant_id,
    };
    fetchStores(searchBy, currentPage, limit, filterParams);
  }, [searchBy, currentPage, filters]);

  const search = search => {
    const isValid = search.length === 0 || search.length >= 5;
    setErrorSearch(!isValid);
    if (isValid) {
      setCurrentPage(1);
      setSearchBy(search);
    }
  };

  const setValidSearch = value => {
    setSearchState(value);
  };

  const clearSearch = () => {
    setSearchState('');
    setSearchBy('');
  };

  const goDetails = path => {
    history.push(path);
    const contentArea = document.getElementById('content-area');
    contentArea.scrollTop = 0;
  };

  const [merchantRoles, setMerchantRoles] = React.useState([]);
  const [merchantRoleNames, setMerchantRoleNames] = React.useState([]);

  useEffect(() => {
    getMerchantRoles(merchantId).then(response => {
      const { data } = response;
      setMerchantRoles(data?.roles);
      setMerchantRoleNames(data?.roles.map(role => role.role_description));
    });
  }, []);

  const UsersTable = () => {
    return (
      <>
        <TableComponent
          type='b2b'
          size={tableSize}
          style={{ width: '100%' }}
          className='k-ds-m-bottom-lyt-04 k-ds-m-top-sp-06'
        >
          <thead>
            <tr>
              {Object.keys(tableHeaders).map((key, index) => {
                return <th key={index}>{tableHeaders[key]}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {users.map(user => {
              let userRole = user?.role?.role_name;
              let canEdit = canEmployeeEdit(
                merchantRoles,
                employeeRole,
                userRole
              );
              return (
                <tr key={user.merchant_user_id} className='row-hover'>
                  <td className='avatar'>
                    <Avatar
                      type='initials'
                      size='medium'
                      initials={user.first_name[0] + user.last_name[0]}
                    />
                    {user.first_name} {user.last_name}
                  </td>
                  <td>{user.email}</td>
                  <td>{user.role.description}</td>
                  <td>{user.stores[0]?.name}</td>
                  <td>{getStatusTag(user.status)}</td>
                  <td>
                    {canEdit && (
                      <Button
                        type='link'
                        size='small'
                        onClick={() =>
                          goDetails(`/users/${user.merchant_user_id}/edit`)
                        }
                      >
                        Editar
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </TableComponent>
      </>
    );
  };

  const UsersGrid = () => {
    return (
      <div className='user-grid'>
        {users?.map(user => {
          let userRole = user?.role?.role_name;
          let canEdit = canEmployeeEdit(merchantRoles, employeeRole, userRole);

          return (
            <UserCard
              key={user.merchant_user_id}
              user_id={user.merchant_user_id}
              email={user.email}
              first_name={user.first_name}
              last_name={user.last_name}
              role_name={user.role.description}
              store_name={user.stores[0]?.name || ''}
              status={user.status}
              user_code={user.user_code}
              goToEdit={goDetails}
              getStatusTag={getStatusTag}
              canEdit={canEdit}
            />
          );
        })}
      </div>
    );
  };

  const onFilterChange = filters => {
    setSearchBy('');
    setSearchState('');
    setFilters(filters);
    setFilterBarOpen(false);
  };

  const isAnyFilterActive =
    statusActiveFilters.length > 0 ||
    loggedActiveFilters.length > 0 ||
    roleActiveFilters.length > 0 ||
    creationDateActiveFilters;

  const TagsContainer = () => {
    return (
      <Space className='tags-container'>
        {statusActiveFilters.map((status, index) => (
          <Tag
            key={index}
            color='orange-outline'
            className='k-ds-m-right-sp-04 tag'
            onClick={() => setFilterBarOpen(true)}
          >
            ESTATUS: {status.label}
          </Tag>
        ))}
        {loggedActiveFilters.map((log, index) => (
          <Tag
            key={index}
            color='orange-outline'
            className='k-ds-m-right-sp-04 tag'
            onClick={() => setFilterBarOpen(true)}
          >
            USUARIO LOGGEADO: {log.label}
          </Tag>
        ))}
        {roleActiveFilters.map((rol, index) => (
          <Tag
            key={index}
            color='orange-outline'
            className='k-ds-m-right-sp-04 tag'
            onClick={() => setFilterBarOpen(true)}
          >
            ROL: {rol.label}
          </Tag>
        ))}
        {creationDateActiveFilters && (
          <Tag
            color='orange-outline'
            className='k-ds-m-right-sp-04 tag'
            onClick={() => setFilterBarOpen(true)}
          >
            FECHA DE CREACIÓN:{' '}
            {getDateString(creationDateActiveFilters.startDate)} -{' '}
            {getDateString(creationDateActiveFilters.endDate)}
          </Tag>
        )}
      </Space>
    );
  };

  function OpenModal() {
    AnalyticsEvent().addUser();
    setOpenConfirmModal(true);
  }

  function handleClose() {
    setOpenConfirmModal(false);
  }

  return (
    <>
      <div className='user-list'>
        <div className='k-ds-width-full'>
          <Space
            direction={orderDetailsDirection}
            size='large'
            justify='space-around'
            className='k-ds-width-full'
          >
            <Header
              title='Configuración de usuarios'
              subtitle='Consulta la lista de usuarios'
              alignChildCenter
            >
              <Button
                size='small'
                type='primary'
                horizontalPadding='4rem'
                className='action-button'
                verticalPadding='1rem'
                onClick={OpenModal}
              >
                <PlusSign className='user-plus-icon' />
                Agregar usuarios
              </Button>
            </Header>
          </Space>

          <MerchantNameSelected />

          {/* {paramsData.newCreated === 'true' && paramsData.count && (
            <Message
              type='warning'
              className='k-ds-m-bottom-sp-05'
              message={ <div className='k-ds-heading-01'>
                <div className=''>
                  Tienes {paramsData.count} usuarios en proceso de creación y
                  validación de cuentas.
                </div>
              </div> }
            />
          )} */}

          {errors && errors.length > 0 && (
            <Message
              type='warning'
              className='k-ds-m-bottom-sp-05'
              message={
                <div className='k-ds-heading-01'>
                  <div className='k-ds-m-bottom-sp-03'>!Importante!</div>
                  <div className='k-ds-m-top-sp-06'>
                    Algunos usuarios no pudieron ser creados por alguna de las
                    siguientes razones:
                    <ul>
                      {errors.map((error, index) => {
                        return (
                          <li key={index}>
                            {getErrorMessageTranslation(
                              t,
                              translationPrefix,
                              error
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              }
            />
          )}
          <Space
            direction='horizontal'
            size='large'
            align='center'
            justify='space-around'
          >
            <Space direction='vertical' className='flex-grow-1'>
              <SearchBar
                className='k-ds-m-vertical-sp-07'
                placeholder='Buscar'
                search={searchState}
                setSearch={setValidSearch}
                onSearch={() => search(searchState)}
                clearSearch={() => clearSearch()}
                width={searchBarWidth}
                helper='Puedes buscar por nombre, apellido o correo electrónico'
                errorMessage={
                  errorSearch
                    ? 'Necesitas escribir al menos cinco caracteres para realizar una búsqueda.'
                    : ''
                }
              />
            </Space>
            <div className='flex-grow-1'></div>
          </Space>
          <Space
            direction='horizontal'
            align='center'
            justify='space-between'
            className='k-ds-m-top-sp-06 k-ds-m-bottom-sp-04 user-filters'
          >
            <Space
              direction='horizontal'
              align='stretch'
              justify='space-around'
            >
              {isListMode ? (
                <>
                  <ListViewIconSelected />
                  <GridViewIcon
                    onClick={() => setIsListMode(false)}
                    className='gird-icon'
                  />
                </>
              ) : (
                <>
                  <ListViewIcon onClick={() => setIsListMode(true)} />
                  <GridViewIconSelected className='gird-icon' />
                </>
              )}
            </Space>
            <Space>
              {isFeatureEnabled('filter_stores') && (
                <Button type='link' onClick={() => setFilterBarOpen(true)}>
                  <div className='filter-icon'>
                    <FilterIcon className='k-ds-m-right-sp-04' /> Filtrar
                  </div>
                  <FilterIconMobile className='k-ds-m-right-sp-04 filter-icon-mobile' />
                </Button>
              )}
              {isFeatureEnabled('export_stores') && (
                <Button
                  className='export-button'
                  type='link'
                  onClick={() => {}}
                >
                  <div className='export-icon'>
                    <ExportIcon className='k-ds-m-right-sp-04' /> Exportar a
                    excel
                  </div>
                  <ExportIconMobile className='export-icon-mobile' />
                </Button>
              )}
            </Space>
          </Space>
          {isAnyFilterActive && (
            <Space
              align='center'
              justify='space-between'
              className='k-ds-m-top-sp-06 k-ds-m-bottom-sp-07 user-filter-tags'
            >
              <Space className='tags-info k-ds-width-full'>
                <Space justify='space-between'>
                  <Space className='k-ds-m-bottom-sp-06 applied-filters'>
                    <span className='k-ds-body-01'> Filtros aplicados:</span>
                  </Space>
                  <Space className='k-ds-body-01 k-ds-m-top-sp-03 filter-result'>
                    Mostrando: {users.length || 0} resultados
                  </Space>
                </Space>
                <TagsContainer />
              </Space>
              <Space className='k-ds-body-01 k-ds-m-top-sp-03 filter-result-mobile'>
                Mostrando: {users.length || 0} resultados
              </Space>
            </Space>
          )}
          <ToolBar
            isOpen={filterBarOpen}
            onClose={() => setFilterBarOpen(false)}
          >
            <UsersFilter
              filters={filters}
              onFilterChange={filters => onFilterChange(filters)}
            />
          </ToolBar>
          {isListMode ? <UsersTable /> : <UsersGrid />}
          {users.length > 0 ? (
            <div className='user-list-pagination'>
              <PaginationComponent
                totalPages={pagination.total_pages}
                totalElements={pagination.total_items}
                maxVisibleNumbers={6}
                currentPage={pagination.actual_page}
                elementsPerPage={limit}
                changePage={page => setCurrentPage(page)}
              />
            </div>
          ) : (
            <EmptySearchResults clearAction={() => clearSearch()} />
          )}
        </div>
      </div>
      <AddUserSelectionModal
        defaultOpened={openConfirmModal}
        roles={merchantRoles}
        close={handleClose}
        rolesNames={merchantRoleNames}
      />
    </>
  );

  function getErrorsList(errorsParam) {
    let errorsList = [];
    Object.keys(errorsParam).map(key => {
      const errosList = errorsParam[key];
      return Object.keys(errosList).map(attribute => {
        return errosList[attribute].map(error => {
          if (!errorsList.includes(error)) errorsList.push(error);
        });
      });
    });
    return errorsList;
  }
};

export default UserManagement;
