import styled, { css } from 'styled-components';
import React, { useState } from 'react';
import { Space } from '../space/index';
import KDS from '../../lib/k-ds-foundation';
import { ReactComponent as EyeOffLineIcon } from './eye-off-line.svg';
import { ReactComponent as EyeLineIcon } from './eye-line.svg';
import { forwardRef } from 'react';

const StyledInputOutline = styled.input`
  ${KDS.setColorTokenWithFallback('border: 1px solid ', 'interactive-alternate')}
  border-radius: 8px;
  box-sizing: border-box;
  padding: 14px;

  ${KDS.setColorWithFallback('background: ', 'transparent')}

  ${KDS.setColorTokenWithFallback('color: ', 'interactive-alternate')}

  &:enabled {
    ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}

    &:hover {
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-secondary')}

      ::placeholder {
        ${KDS.setColorTokenWithFallback('color: ', 'interactive-secondary')}
      }
    }

    &:focus-visible {
      outline: 0;
    }

    &:focus {
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary')}
      ${KDS.setColorTokenWithFallback('border-color: ', 'interactive-primary')}
      border-width: 1px;
    }

    ::placeholder {
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-secondary')}
    }

    ::-ms-reveal {
      display: none;
    }

    ${(props) => props.hasError && props.isFilled && css`
      ${KDS.setColorTokenWithFallback('border: 1px solid ', 'error')}
    `}
  }

  &:disabled {
    ${KDS.setColorTokenWithFallback('border: 1px solid ', 'background-alternate')}

    ::placeholder {
      ${KDS.setColorTokenWithFallback('color: ', 'text-placeholder')}
    }
  }
`;

const InputWrapper = styled.div`
  position: relative;

  .icon {
    position: absolute;
    ${KDS.setSpacingWithFallback('top: ', 'spacing-05')}
    ${KDS.setSpacingWithFallback('right: ', 'spacing-05')}
    ${KDS.setColorWithFallback('background: ', 'white')}

    cursor: pointer;
  }

  .helper-error {
    position: absolute;
    top: 3.5rem;
    left: 0;
    right: 0;

    ${KDS.setColorTokenWithFallback('color: ', 'text-placeholder')}

    ${(props) => props.hasError && css`
      ${KDS.setColorTokenWithFallback('color: ', 'error')}
    `}
  }
`;

const InputOutline = forwardRef(function CustomInputOutline ({
  className, type, id, inputType, label, value, onChange,
  errorMessage, optional, helper, enabledId = false, ...moreProps
}, ref) {
  const [showPassword, setShowPassword] = useState(false);
  const hasError = Boolean(errorMessage);
  const isFilled = Boolean(value);
  const { required } = moreProps;
  const idInput = enabledId ? { id: id } : {};
  const props = { ...moreProps, hasError, isFilled };

  const labelOrRequired = (
    <Space size="small" justify="space-between" className="k-ds-width-full" direction="horizontal">
      <label className="k-ds-body-01 k-ds-text-color-primary" htmlFor={id}>
        {label}
        { required ? <span className="k-ds-text-color-error">{' *'}</span> : null }
      </label>
      <span className="k-ds-label-01 k-ds-text-color-interactive-secondary">{optional}</span>
    </Space>
  );

  const showHidePasswordIcon = () => {
    if (showPassword) {
      return <EyeLineIcon onClick={() => setShowPassword(!showPassword)} className="icon" />;
    }

    return <EyeOffLineIcon onClick={() => setShowPassword(!showPassword)} className="icon" />;
  };

  return (
    <Space size="small" direction="vertical" className={className}>
      {
        label || required ? (
          labelOrRequired
        ) : null
      }
      <InputWrapper hasError={ hasError }>
        <StyledInputOutline
          ref={ref}
          type={showPassword ? 'text' : inputType}
          value={value}
          onChange={onChange}
          className="k-ds-width-full k-ds-body-01"
          {...idInput}
          {...props}
        />
        { inputType === 'password' ? showHidePasswordIcon() : []}
        {
          helper || hasError ? (
            <span className="helper-error k-ds-caption-01">
              {errorMessage || helper}
            </span>
          ) : null
        }
      </InputWrapper>
    </Space>
  );
});

export {
  InputOutline,
};
