import cn from 'classnames';
import { forwardRef } from "react";
import Select, { components } from 'react-select';

const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <div>
        <div style={{ background: props.value }}></div>
        <div>
          {children}
        </div>
      </div>
    </components.Option>
  )
}

export const SelectSearchable = forwardRef(({
  className, children, type, name, form, label, options, hasError = false,
  isDisabled = false, isLoading = false, isClearable = false, isSearchable = true,
  defaultValue, disabled, errorMessage, optional, helper, ...moreProps
}, ref) => {


  return (
    <Select
      ref={ref}
      className={cn('searchable-container', {
        ['react-select-error']: hasError
      })}
      classNamePrefix="searchable"
      defaultValue={defaultValue}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isClearable={isClearable}
      isSearchable={isSearchable}
      name={name}
      components={{ Option: Option }}
      options={options}
      noOptionsMessage={() => 'Sin opciones'}
      {...moreProps}
    />
  )
})
