import React from 'react';
import styles from './banners.module.scss';
import Babylon from './res/babylon.png';
import Doto from './res/doto.png';
import Linio from './res/linio.png';
import Samsung from './res/samsung.png';
import Sundar from './res/sundar.png';
import Viva from './res/viva.png';

const Banner2 = () => {
  return (
    <div className={styles.banner2Container}>
      <div className={styles.infoContainer}>
        <div className={styles.header}>
          Miles de comercios afiliados en línea y tienda física
        </div>
        <div className={styles.infoText}>
          <ul>
            <li>Vende en línea y en tienda física.</li>
            <li>Recibe notificaciones y promociones exclusivas.</li>
            <li>Consulta y gestiona la información de tu cuenta.</li>
          </ul>
        </div>
      </div>
      <div className={styles.imageGrid}>
        <img src={Doto} />
        <img src={Babylon} />
        <img src={Linio} />
        <img src={Viva} />
        <img src={Sundar} />
        <img src={Samsung} />
      </div>
    </div>
  );
};

export default Banner2;
