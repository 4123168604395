import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Pagination as Paginationds, Space } from '../../ds';
import { StyledPagination, StyledSelect } from './styled';

const PAGES_FILTER = [
  { label: '15', value: '15' },
  { label: '20', value: '20' },
  { label: '25', value: '25' },
];

const Pagination = ({
  className = '',
  onChangePageSize = () => null,
  onChangePage,
  options,
  ...rest
}) => {
  const [pageSize, setPageZise] = useState(PAGES_FILTER[0].value);

  const handleOnChangePageSize = e => {
    onChangePageSize(e);
    setPageZise(e);
  };

  const handelChangePage = e => {
    onChangePage(e);
  };

  const { totalElements = 0, totalPages = 0, currentPage = 0 } = options;

  return (
    <StyledPagination className={className}>
      <Space
        direction='horizontal'
        align='center'
        className='k-ds-subheading-01 k-ds-text-color-primary'
      >
        <StyledSelect
          className='sizePagination'
          options={PAGES_FILTER}
          value={pageSize}
          onChange={handleOnChangePageSize}
          alternative
        />
        {`de ${totalElements} resultados`}
      </Space>

      <Paginationds
        type='portal'
        totalPages={totalPages}
        currentPage={currentPage}
        maxVisibleNumbers={6}
        onPageChange={handelChangePage}
        {...rest}
      />
    </StyledPagination>
  );
};

Pagination.propTypes = {
  className: PropTypes.string,
  onChangePageSize: PropTypes.func,
  onChangePage: PropTypes.func,
  options: PropTypes.shape({
    totalElements: PropTypes.number,
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
  }),
};

export default Pagination;
