import { Button, Space } from '../../ds';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { usePermissionContext } from '../../contexts/Role/PermissionContext';
import { getMerchantUsers, getStores } from '../../services/Application';
import { useGetMerchant } from '../../Utils/useGetMerchant';
import styles from './MerchantHome.module.scss';
import { ReactComponent as ArrowIcon } from './res/arrow-icon.svg';
import { ReactComponent as StoreIcon } from './res/stores-icon.svg';
import { ReactComponent as UserIcon } from './res/users-icon.svg';

const PanelCard = ({ icon, title, counter = '', goTo }) => {
  return (
    <Button className={styles.card} type='ghost' onClick={goTo}>
      <div className={styles.title}>
        {icon}
        {title} {counter !== '' ? `(${counter})` : null}
      </div>

      <ArrowIcon />
    </Button>
  );
};

PanelCard.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  counter: PropTypes.string,
  goTo: PropTypes.func,
};

const MerchantHome = () => {
  const navigate = useHistory();
  const { setOpenModalRoot } = usePermissionContext();
  const [counters, setCounter] = useState({ users: 0, stores: 0 });

  const merchantInfo = useGetMerchant();
  const { name: merchantName, merchant_id: merchantId } = merchantInfo;
  const handleChange = useCallback(() => {
    setOpenModalRoot(true);
  }, [setOpenModalRoot]);

  useEffect(() => {
    Promise.allSettled([
      getStores(null, 1, 1, { merchant_id: merchantId }),
      getMerchantUsers(null, 1, 1, { merchant_id: merchantId }),
    ]).then(res => {
      const counterStores =
        res?.[0]?.status === 'fulfilled'
          ? res?.[0]?.value?.data?.pagination?.total_items
          : 0;

      const counterUsers =
        res?.[1]?.status === 'fulfilled'
          ? res?.[1]?.value?.data?.pagination?.total_items
          : 0;

      setCounter({ stores: counterStores, users: counterUsers });
    });
  }, [merchantId]);

  return (
    <Space direction='vertical' className={styles.container}>
      <div className={styles.header}>
        <div className={styles.merchantName}>{merchantName}</div>
        <div className={styles.rightSide}>
          <div className={styles.identifier}>
            <div className={styles.text}>
              Id del comercio:{' '}
              <span className={styles.textBold}>{merchantId}</span>
            </div>
            <div className={styles.divider} />
            <div className={styles.status}> Activo</div>
          </div>
        </div>
      </div>

      <div className={styles.panel}>
        <PanelCard
          title='Sucursales'
          icon={<StoreIcon />}
          counter={counters.stores.toString()}
          goTo={() => navigate.push('/stores')}
        />

        <PanelCard
          title='Usuarios'
          icon={<UserIcon />}
          counter={counters.users.toString()}
          goTo={() => navigate.push('/users')}
        />

        {/* <PanelCard
          title='Historial de movimientos'
          icon={ <BoxesIcon /> }
          goTo={ () => {} }
        /> */}
      </div>

      <div className={styles.sectionDivider} />
      <div className={styles.operationBox}>
        <div className={styles.subTitle}>¿Deseas consultar otro comercio?</div>

        <Button
          type='primary-alternate'
          size='large'
          className={cn('k-ds-m-rigth-sp-02', styles.changeMerchant)}
          onClick={handleChange}
        >
          <StoreIcon />
          Cambiar de comercio
        </Button>
      </div>
    </Space>
  );
};

export default MerchantHome;
