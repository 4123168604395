import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Space } from '../../ds';
import styles from './BadgeStatus.module.scss';

function BadgeStatus({ isInfoDuplicated, isInfoCompleted }) {
  const { className, label } = useMemo(() => {
    if (isInfoCompleted && !isInfoDuplicated) {
      return {
        className: styles['completed'],
        label: 'Completo',
      };
    }

    if (isInfoCompleted && isInfoDuplicated) {
      return {
        className: styles['duplicate'],
        label: 'Duplicado',
      };
    }

    return {
      className: styles['failed'],
      label: 'Incompleto',
    };
  }, [isInfoDuplicated, isInfoCompleted]);

  return (
    <div
      className={cn('k-ds-caption-01', styles['status-container'], {
        [className]: true,
      })}
    >
      <Space justify='center'>{label}</Space>
    </div>
  );
}

BadgeStatus.propTypes = {
  isInfoDuplicated: PropTypes.bool,
  isInfoCompleted: PropTypes.bool,
};

export default BadgeStatus;
