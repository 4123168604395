import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import KDS from '../../../ds/lib/k-ds-foundation';
import { ReactComponent as ClearSearchIcon } from './clear-search-icon.svg';
import { ReactComponent as IconSearch } from './icon-search.svg';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  background: transparent;
  width: 20px;
  height: 19px;
  border-radius: 50%;
`;

const StyledClearIcon = styled(ClearSearchIcon)`
  position: absolute;
  width: 0;
  height: 0;
  padding: 2px;
  fill: var(--k-ds-color-grey-700);
  right: 120px;
  border-radius: 50%;
  background-color: var(--k-ds-color-background-primary);
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
  ${props =>
    props.visible &&
    css`
      display: block;
      width: 15px;
      height: 15px;
      opacity: 1;
    `}
`;

const StyledDiv = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
  position: relative;
  background: white;
  border: 1px solid
    ${props =>
      props.hasError
        ? KDS.getColorToken('error')
        : KDS.getColorToken('interactive-alternate')};
  border-radius: 50px;
  padding-left: 14px;

  ${props =>
    props.alternative
      ? css`
          height: 40px;
          background: #f5f6fb;
          border: none;
        `
      : css`
          background: white;
        `}

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}

  svg {
    flex-shrink: 0;
    fill: #b4baca;
  }

  .helper {
    position: absolute;
    top: 3.5rem;
    left: 0;
    right: 0;
    ${KDS.setColorTokenWithFallback('color: ', 'text-placeholder')}
    &.error {
      ${KDS.setColorTokenWithFallback('color: ', 'error')}
    }
  }

  &.icon-mode {
    width: 35px;
    overflow: hidden;
    transition:
      width 0.3s ease-in-out,
      background-color 0.3s;
    background-color: var(--k-ds-color-deep-purple-500);
    svg {
      fill: #fff;
    }
    &.open {
      ${props =>
        (props.width &&
          css`
            width: ${props.width};
          `) ||
        css`
          width: 100%;
        `}
      background-color: var(--k-ds-color-background-primary);
      svg {
        fill: #b4baca;
      }
    }
  }

  &:focus-within {
    .lens {
      fill: black;
    }
    border: 1px solid
      ${props =>
        props.hasError
          ? KDS.getColorToken('error')
          : KDS.getColorToken('interactive-primary')};
  }
`;

const StyledInput = styled.input`
  display: flex;
  flex-grow: 1;
  border: 0;
  width: 100%;

  ${KDS.setColorWithFallback('background: ', 'transparent')}
  ${KDS.setColorTokenWithFallback('color: ', 'interactive-alternate')}

  &:enabled {
    ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}

    &:hover {
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-secondary')}
      ::placeholder {
        ${KDS.setColorTokenWithFallback('color: ', 'interactive-secondary')}
      }
    }

    &:focus-visible {
      outline: 0;
    }

    &:focus {
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary')}
    }

    ::placeholder {
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-alternate')}
    }
  }

  &:disabled {
    ::placeholder {
      ${KDS.setColorTokenWithFallback('color: ', 'text-placeholder')}
    }
  }
`;

const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border: 0;
  border-radius: 50px;
  padding: 14px 32px;

  ${KDS.setColorTokenWithFallback('background: ', 'interactive-primary')}
  cursor: pointer;

  ${KDS.setTypographyWithFallback({
    'font-family': 'font-primary',
    'font-weight': 'font-bold',
    'font-size': 'text-sm',
    'letter-spacing': 'tracking-wide',
    'line-height': 'snug',
  })}
  font-style: normal;
  text-align: center;
  ${KDS.setColorTokenWithFallback('color: ', 'text-alternate')}

  &:enabled {
    &:hover {
      ${KDS.setColorTokenWithFallback(
        'background: ',
        'interactive-primary-hover'
      )}
    }

    &:active:not(focus) {
      ${KDS.setColorTokenWithFallback(
        'background: ',
        'interactive-primary-hover'
      )}
      border: 0;
    }
  }

  &:disabled {
    ${KDS.setColorTokenWithFallback('background: ', 'background-alternate')}
    cursor: default;

    ${KDS.setColorTokenWithFallback('color: ', 'interactive-alternate')}
  }
`;

// Gneral Focus Hook
const UseFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};

const SearchBar = ({
  className,
  search,
  setSearch,
  placeholder,
  disabled,
  onSearch,
  helper,
  errorMessage,
  iconMode,
  iconModeOpen,
  setIconModeOpen,
  clearSearch,
  isLoading = false,
  disabledButton = false,
  hideButton = false,
  minSearch = 1,
  ...moreProps
}) => {
  const disabledProp = disabled ? { disabled: true } : {};
  const hasError = Boolean(errorMessage);
  const [css, setCss] = useState('');
  const [inputRef, setInputFocus] = UseFocus();

  const handleClick = () => {
    if (iconMode && !iconModeOpen) {
      if (setIconModeOpen) setIconModeOpen();

      setInputFocus();
    }
  };

  const handleClearSearch = () => {
    if (clearSearch) clearSearch();
  };

  const handleKeyDown = e => {
    if (['Enter', 'Escape'].includes(e.key)) {
      e.preventDefault();
      switch (e.key) {
        case 'Enter': {
          if (search !== '' && search.length >= minSearch) onSearch();
          break;
        }
        case 'Escape': {
          handleClearSearch();
          break;
        }
      }
    }
  };

  useEffect(() => {
    const classes = [];
    if (className) {
      classes.push(className);
    }
    if (iconMode) {
      classes.push('icon-mode');
      if (iconModeOpen) {
        classes.push('open');
      }
    }

    setCss(classes.join(' '));
  }, [iconMode, iconModeOpen]);

  return (
    <StyledDiv className={css} hasError={hasError} {...moreProps}>
      <IconSearch className='lens' onClick={handleClick} />
      <StyledInput
        ref={inputRef}
        className='k-ds-body-01'
        value={search}
        placeholder={placeholder}
        onChange={e => (setSearch ? setSearch(e.target.value) : null)}
        onKeyDown={handleKeyDown}
        disabled={isLoading}
        {...disabledProp}
      />
      {isLoading ? null : (
        <StyledClearIcon
          visible={clearSearch && search !== '' ? 1 : undefined}
          onClick={handleClearSearch}
        />
      )}
      {!hideButton ? (
        <StyledButton
          disabled={isLoading || search.length < minSearch || disabledButton}
          onClick={onSearch}
          {...disabledProp}
        >
          {isLoading ? <Spinner /> : 'Buscar'}
        </StyledButton>
      ) : null}
      {helper || hasError ? (
        <span className={`helper k-ds-caption-01 ${hasError ? 'error' : ''}`}>
          {errorMessage || helper}
        </span>
      ) : null}
    </StyledDiv>
  );
};

SearchBar.propTypes = {
  className: PropTypes.string,
  search: PropTypes.string,
  setSearch: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onSearch: PropTypes.func,
  helper: PropTypes.string,
  errorMessage: PropTypes.string,
  iconMode: PropTypes.bool,
  iconModeOpen: PropTypes.bool,
  setIconModeOpen: PropTypes.func,
  clearSearch: PropTypes.func,
  isLoading: PropTypes.bool,
  minSearch: PropTypes.number,
  disabledButton: PropTypes.bool,
  hideButton: PropTypes.bool,
};

export { SearchBar };
