import React from 'react';
import { Space, Avatar } from '../../ds';
import PropTypes from 'prop-types';
import { Card } from '../../ds';
import UserAutoscrollSpan from './UserAutoscrollSpan';

const UserCard = ({
  user_id,
  first_name,
  last_name,
  user_code,
  email,
  store_name,
  role_name,
  status,
  getStatusTag,
  goToEdit,
  canEdit,
}) => {
  return (
    <Card className='user-card'>
      <Space direction='horizontal' justify='space-between'>
        <Space direction='vertical' align='left' justify='center'>
          <div className='avatar'>
            <Avatar type='initials' initials={first_name[0] + last_name[0]} />
          </div>
        </Space>
        <Space direction='horizontal' align='center' justify='start'>
          {getStatusTag(status)}
        </Space>
      </Space>
      <Space direction='vertical' align='start' justify='start'>
        <span className={`user-name ${status}`}>
          {first_name} {last_name}
        </span>
      </Space>

      <Space direction='vertical' className={`user-data ${status}`}>
        <span className='user-data-email-section'>
          <span className='user-data-label'>Usuario: </span>
          <UserAutoscrollSpan text={email} />
        </span>
        <span>
          <span className='user-data-label'>Rol: </span>
          <span>{role_name}</span>
        </span>
        <span>
          <span className='user-data-label'>Sucursal: </span>
          <span>{store_name}</span>
        </span>
        <span>
          <span className='user-data-label'>Código de vendedor: </span>
          <span>{user_code}</span>
        </span>
      </Space>
      <Space direction='horizontal' className='user-edit-link'>
        {canEdit && (
          <button
            className='link-button'
            onClick={() => goToEdit(`/users/${user_id}/edit`)}
          >
            Editar
          </button>
        )}
      </Space>
    </Card>
  );
};

UserCard.propTypes = {
  user_id: PropTypes.string.isRequired,
  user_code: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  store_name: PropTypes.string.isRequired,
  role_name: PropTypes.string.isRequired,
  getStatusTag: PropTypes.func.isRequired,
  goToEdit: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export default UserCard;
