import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Space } from '../../../ds';
import KDS from '../../../ds/lib/k-ds-foundation';
import { ReactComponent as IconArrowDown } from './icon-arrow-down.svg';

const StyledSelectOutline = styled.div`
  position: relative;

  .arrow-icon {
    fill: ${KDS.getColorToken('interactive-alternate')};
  }
  .selector {
    ${KDS.setColorTokenWithFallback(
      'border: 1px solid ',
      'interactive-alternate'
    )}
    border-radius: 8px;
    box-sizing: border-box;
    padding: 14px;
    cursor: pointer;
    ${KDS.setColorTokenWithFallback('background-color: ', 'white')}
    ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}

    &.selected {
      ${KDS.setColorTokenWithFallback(
        'border: 1px solid ',
        'interactive-primary'
      )}

      svg {
        ${KDS.setColorTokenWithFallback('fill: ', 'interactive-primary')}
      }
    }

    .show {
      transform: rotateX(180deg);
    }

    ${props =>
      props.disabled &&
      css`
        cursor: not-allowed;

        ${KDS.setColorTokenWithFallback('background-color: ', 'grey-100')}
        ${KDS.setColorTokenWithFallback('border: 1px solid ', 'grey-300')}
      ${KDS.setColorTokenWithFallback('color: ', 'text-placeholder')}

      svg path {
          ${KDS.setColorTokenWithFallback('stroke: ', 'grey-500')}
        }
      `}

    ${props =>
      props.showPlaceholder &&
      css`
        ${KDS.setColorTokenWithFallback('color: ', 'text-secondary')}
      `}

    ${props =>
      props.active &&
      css`
        ${KDS.setColorTokenWithFallback(
          'border: 1px solid',
          'interactive-primary'
        )}
        .arrow-icon {
          fill: ${KDS.getColorToken('interactive-primary')};
        }
      `}
    }
  }

  .options-group {
    position: absolute;

    z-index: 1;

    display: none;
    transform: translate(0, ${KDS.getSpacing('spacing-01')});
    transform: translate(0, var(--k-ds-spacing-01));

    overflow-y: auto;

    width: 100%;
    max-height: 186px;

    ${KDS.setColorWithFallback('background: ', 'white')}

    ${KDS.setColorTokenWithFallback(
      'border: 1px solid ',
      'interactive-alternate'
    )}
    border-radius: 8px;
    box-sizing: border-box;

    ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}

    &.show {
      display: block;
    }
  }

  .option {
    input[type='radio'] {
      display: none;
    }

    label {
      display: block;
      padding: 14px;
      cursor: pointer;

      :hover {
        ${KDS.setColorTokenWithFallback('background: ', 'background-alternate')}
        ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary')}
      }
    }
  }

  .helper-error {
    position: absolute;
    top: 3.5rem;
    left: 0;
    right: 0;

    ${KDS.setColorTokenWithFallback('color: ', 'text-placeholder')}

    ${props =>
      props.hasError &&
      css`
        ${KDS.setColorTokenWithFallback('color: ', 'error')}
      `}
  }
`;

const HiddenDivInput = styled.input`
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  top: 50%;
  left: 50%;
`;

// Gneral Focus Hook
const UseFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};

const SelectOutline = ({
  className,
  name,
  form,
  label,
  placeholder,
  options,
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  optional,
  helper,
  alternative = false,
  ...moreProps
}) => {
  const hasError = Boolean(errorMessage);
  const { required } = moreProps;
  const props = { ...moreProps, hasError };

  const [isActive, setIsActive] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [ignoreBlur, setIgnoreBlur] = useState(false);
  const [inputHiddenRef, setInputHiddenFocus] = UseFocus();
  const [selected, setSelected] = useState(value ? true : false);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, []);

  let valueText = '';
  const selectOptions = options || [];
  const optionsData = selectOptions.map(option => {
    const optionLabel = option?.text ?? option?.label;
    let id = optionLabel.toLowerCase().replace(/ /g, '_');
    id = `${name || ''}_${id}`;
    const isSelected = option.value === value;
    if (isSelected) {
      valueText = optionLabel;
    }
    return {
      id,
      text: optionLabel,
      value: option.value,
      isSelected,
    };
  });

  const onSelectClick = () => {
    if (disabled) return;

    setIsActive(!isActive);
    setInputHiddenFocus();
  };

  const onOptionClick = optionValue => {
    if (onChange) onChange(optionValue);
    setSelected(true);
    setIsActive(false);
    setIgnoreBlur(false);
  };

  const handleBlur = () => {
    if (ignoreBlur) return;

    if (isActive) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    if (!isActive && !isFirstRender) {
      if (onBlur) onBlur();
    }
  }, [isActive]);

  const selectText = valueText || placeholder;
  const showClass = isActive && !disabled ? 'show' : '';

  return (
    <Space size='small' direction='vertical' className={className} {...props}>
      {label && (
        <Space
          size='small'
          justify='space-between'
          className='k-ds-width-full'
          direction='horizontal'
        >
          <label
            className='k-ds-body-01 k-ds-text-color-primary'
            htmlFor={name}
          >
            {label}
            {required ? (
              <span className='k-ds-text-color-error'>{' *'}</span>
            ) : null}
          </label>
          <span className='k-ds-label-01 k-ds-text-color-interactive-secondary'>
            {optional}
          </span>
        </Space>
      )}
      <StyledSelectOutline
        className='k-ds-width-full k-ds-body-01'
        hasError={hasError}
        disabled={disabled}
        showPlaceholder={valueText === ''}
        active={isActive}
      >
        <HiddenDivInput
          ref={inputHiddenRef}
          type='radio'
          onBlur={handleBlur}
          onFocus={() => setIsActive(true)}
        />
        <Space
          size='medium'
          justify='space-between'
          className={`k-ds-width-full selector ${alternative && selected ? 'selected' : ''}`}
          onClick={onSelectClick}
          direction='horizontal'
          align='center'
          aria-hidden
        >
          <span>{selectText}</span>
          <IconArrowDown className={`arrow-icon ${showClass}`} />
        </Space>
        {selectOptions.length > 0 ? (
          <div className={`options-group ${showClass}`}>
            {optionsData.map((option, index) => (
              <div key={option.id + '_' + index} className='option'>
                <input
                  type='radio'
                  id={option.id}
                  name={name}
                  value={option.value}
                  form={form}
                  defaultChecked={option.isSelected}
                />
                <label
                  htmlFor={option.id}
                  onMouseDown={() => setIgnoreBlur(true)}
                  onClick={() => onOptionClick(option.value)}
                  aria-hidden='true'
                >
                  {option?.text ?? option?.label}
                </label>
              </div>
            ))}
          </div>
        ) : null}
        {helper || hasError ? (
          <span className='helper-error k-ds-caption-01'>
            {errorMessage || helper}
          </span>
        ) : null}
      </StyledSelectOutline>
    </Space>
  );
};

SelectOutline.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  form: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  optional: PropTypes.string,
  helper: PropTypes.string,
  alternative: PropTypes.bool,
};

export { SelectOutline };
