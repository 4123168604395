export const StoreFieldErrors = {
  'External store Error: Each pair of external_store_id and merchant_id should be unique': {
    field: 'external_store_id',
    message: 'Este número ya ha sido utilizado, ingresa uno diferente.',
    toast: 'Este número de tienda ya ha sido utilizado, ingresa uno diferente.',
    showModal: true,
  },
  'Error: Each pair of external_store_id and merchant_id should be unique': {
    field: 'external_store_id',
    message: 'Este número ya ha sido utilizado, ingresa uno diferente.',
    showModal: true,
  },
  'must be null or size cannot be greater than 100': {
    field: 'external_store_id',
    message: 'Excede el máximo de caracteres.',
  },
}
