import * as yup from 'yup';

const NOT_EMPTY_FIELD = 'No puede quedar vacío';

export const validationBusinessSchema = yup.object().shape({
  business_name: yup.string().required(NOT_EMPTY_FIELD),
  brand: yup.string().required(NOT_EMPTY_FIELD),
  street: yup.string().required(NOT_EMPTY_FIELD),
  personType: yup
    .string()
    .oneOf(['legal_person', 'natural_person'])
    .required(NOT_EMPTY_FIELD),
  tax_regime: yup.object().nonNullable().required(NOT_EMPTY_FIELD),
  zip_code: yup
    .string()
    .required(NOT_EMPTY_FIELD)
    .min(5, 'Formato invalido: max 5 dígitos')
    .max(5, 'Formato invalido: max 5 dígitos'),
  city: yup.object().required(NOT_EMPTY_FIELD),
  state: yup.object().required(NOT_EMPTY_FIELD),
  neighborhood: yup.object().required(NOT_EMPTY_FIELD),
  inner_number: yup.string(),
  ext_number: yup.string().required(NOT_EMPTY_FIELD),
  channel: yup.object().shape({
    value: yup.string().required(NOT_EMPTY_FIELD), // You can customize the error message
  }),
  websites: yup
    .array()
    .of(
      yup.object().shape({
        url: yup
          .string()
          .url('Por favor incluye: http:// o https://')
          .required(NOT_EMPTY_FIELD),
      })
    )
    .min(1, 'At least one website is required')
    .max(5, 'Maximum of 5 websites allowed'),
  rfc: yup
    .string()
    .matches(
      /^[A-Z]{4}\d{6}[A-Z0-9]{3}$/,
      'RFC debe tener el formato XXXX123456XXX'
    )
    .required(NOT_EMPTY_FIELD),
});

export const validationBankSchema = yup.object().shape({
  clabe: yup.string().required(NOT_EMPTY_FIELD).max(18, 'Max 18 dígitos'),
  bank: yup.string().required(NOT_EMPTY_FIELD),
  bankTitular: yup.string().required(NOT_EMPTY_FIELD),
  bankmailtitular: yup.string().email().required(NOT_EMPTY_FIELD),
});
