import styled, { css } from 'styled-components';
import { ButtonDefaults } from './ButtonDefaults';
import KDS from '../../lib/k-ds-foundation';

const ButtonOutline = styled(ButtonDefaults)`
  ${KDS.setColorWithFallback('background: ', 'transparent')}
  ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary')}
  box-shadow: none;

  ${(props) => props.borderWidth && css`
    ${KDS.setColorTokenWithFallback(`border: ${props.borderWidth} solid `, 'interactive-primary')}
  `}

  &:enabled {
    &:hover {
      ${KDS.setColorTokenWithFallback('background: ', 'interactive-primary')}
      ${KDS.setColorTokenWithFallback('color: ', 'text-alternate')}
    }

    &:active:not(:focus-visible) {
      ${KDS.setColorWithFallback('background: ', 'transparent')}
      ${KDS.setColorTokenWithFallback('border-color: ', 'interactive-primary-active')}
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary-active')}
    }

    &:focus-visible {
      ${(props) => props.borderWidth && css`
        ${KDS.setColorTokenWithFallback(`border: ${props.borderWidth} solid `, 'inverse-background')}
      `}
    }
  }

  &:disabled {
    opacity: 0.5;
  }
`;

export {
  ButtonOutline,
};
