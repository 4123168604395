import styled, { css } from 'styled-components';
import React from 'react';
import { Space } from '../space/index';
import KDS from '../../lib/k-ds-foundation';
import { ReactComponent as LeftArrow } from './icon-left-arrow.svg';
import { ReactComponent as RightArrow } from './icon-right-arrow.svg';

const ButtonArrow = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  ${KDS.setSpacingWithFallback('padding: ', 'spacing-04', 'spacing-04', 'spacing-04', 'spacing-04')}

  background: transparent;
  cursor: pointer;

  ${KDS.setTypographyWithFallback({
    'font-family': 'font-secondary',
    'font-weight': 'font-medium',
    'font-size': 'text-sm',
    'letter-spacing': 'tracking-wide',
    'line-height': 'snug',
  })}

  &:hover {
    ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary-hover')}
    svg path {
      ${KDS.setColorTokenWithFallback('fill: ', 'interactive-primary-hover')}
    }
  }

  ${(props) => props.isInactive && css`
    ${KDS.setColorTokenWithFallback('color: ', 'interactive-alternate')}

    svg path {
      ${KDS.setColorTokenWithFallback('fill: ', 'interactive-alternate')}
    }
  `}
`;

const PaginationMobileB2B = (props) => {
  // Require maxiVisibleNumbers to be 5 or greater (due to fixed first, last and '...' elements)
  const {
    totalPages,
    totalElements,
    currentPage,
    changePage,
    elementsPerPage,
    className,
  } = props;

  if (totalPages === 1) return (<> </>);

  function prevPage() {
    if (currentPage > 1) changePage(currentPage - 1);
  }

  function nextPage() {
    if (currentPage < totalPages) changePage(currentPage + 1);
  }

  const currentPageFirst = (currentPage - 1) * elementsPerPage + 1;
  let currentPageLast = currentPageFirst + elementsPerPage - 1;
  if (currentPageLast > totalElements) currentPageLast = totalElements;

  const leftArrowInactive = currentPage === 1;
  const rightArrowInactive = currentPage === totalPages;

  return (
    <Space direction="horizontal" size="medium" align="center" className={className}>
      <ButtonArrow type="button" onClick={() => prevPage()} isInactive={leftArrowInactive} aria-hidden="true">
        <LeftArrow />
      </ButtonArrow>
      <span className="k-ds-heading-01 k-ds-text-color-primary">
        {`${currentPageFirst}-${currentPageLast} de ${totalElements}`}
      </span>
      <ButtonArrow type="button" onClick={() => nextPage()} isInactive={rightArrowInactive} aria-hidden="true">
        <RightArrow />
      </ButtonArrow>
    </Space>
  );
};

export {
  PaginationMobileB2B,
};
