import React from 'react';
import styled, { css } from 'styled-components';
import KDS from '../../lib/k-ds-foundation';
import { Space } from '../space/index';

/* eslint-disable */
const StyledNavButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 228px;
  ${KDS.setSpacingWithFallback('padding: ','spacing-04', 'spacing-05')};

  /* k-ds-heading-02 */
  ${KDS.setTypographyWithFallback({
    'font-family': 'font-primary',
    'font-weight': 'font-bold',
    'font-size': 'text-base',
    'letter-spacing': 'tracking-wide',
    'line-height': 'normal',
  })}
  font-style: normal;

  border-radius: 16px;
  border: 0;

  span {
    ${KDS.setSpacingWithFallback('margin-left: ','spacing-05')};
  }

  cursor: pointer;

  ${KDS.setColorWithFallback('background: ', 'transparent')}
  ${KDS.setColorTokenWithFallback('color: ', 'text-primary')}

  &:enabled {
    &:hover {
      ${KDS.setColorTokenWithFallback('background: ', 'background-btn-active')}
    }

    &:active:not(focus) {
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary')}
    }
  }

  &:disabled {
    ${KDS.setColorTokenWithFallback('color: ', 'text-placeholder')}
    cursor: default;
  }

  ${(props) => props.selected && css`
    ${KDS.setColorTokenWithFallback('background: ', 'background-btn-active')}
    ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary')}
    cursor: default;
  `}
`;
/* eslint-enable */

const NavButton = ({
  className, children, icon, iconSelected, ...moreProps
}) => {
  const { selected } = moreProps;

  let iconToRender = icon;
  if (selected && iconSelected) {
    iconToRender = iconSelected;
  }

  return (
    <StyledNavButton
      className={className}
      {...moreProps}
    >
      { iconToRender }
      <span>
        {children}
      </span>
    </StyledNavButton>
  );
};

/* eslint-disable */
const StyledNavButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${KDS.setColorWithFallback('background: ', 'transparent')}

  .group-title {
    align-self: flex-start;
    /* k-ds-heading-02 */
    ${KDS.setTypographyWithFallback({
      'font-family': 'font-primary',
      'font-weight': 'font-bold',
      'font-size': 'text-xs',
      'letter-spacing': 'tracking-wide',
      'line-height': 'snug',
    })}
    font-style: normal;
    ${KDS.setColorTokenWithFallback('color: ', 'interactive-secondary')}

    ${KDS.setSpacingWithFallback('margin: ', 0, 0, 'spacing-04', 'spacing-06')};
  }
`;
/* eslint-enable */

const NavButtonGroup = ({
  className, children, title, ...moreProps
}) => (
  <StyledNavButtonGroup
    className={className}
    {...moreProps}
  >
    {
      title ? (
        <h2 className="group-title">{title}</h2>
      ) : null
    }
    <Space size="medium" direction="vertical" align="center">
      {children}
    </Space>
  </StyledNavButtonGroup>
);

export {
  NavButton, NavButtonGroup
};

