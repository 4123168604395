import * as z from 'zod';
import { orderProductsMaxLimit } from '../../../Utils/constants';
import { validateDecimal } from '../../../Utils/formValidations';
import { MAX_OTP_LENGTH } from '../../../Utils/constants';
const AmountSchema = z.object({
  quantity: z
    .string({
      required_error: 'Este campo es obligatorio.',
      invalid_type_error: 'Este campo es invalido.',
    })
    .refine(
      value => value !== '' && validateDecimal(value),
      'Este campo es invalido'
    )
    .refine(value => Number(value) >= 1, 'La cantidad mínima es 1')
    .refine(
      value => Number(value) <= orderProductsMaxLimit,
      `La cantidad máxima es ${orderProductsMaxLimit}`
    )
    .transform(value => Number(value)),
  amount: z
    .string({
      required_error: 'Este campo es obligatorio.',
      invalid_type_error: 'Este campo es invalido.',
    })
    .refine(value => {
      return Number(value) >= MAX_OTP_LENGTH;
    }, 'El monto mínimo de compra debe ser $60 m.n.'),
  reference: z.optional(
    z
      .string({
        required_error: 'Este campo es obligatorio.',
        invalid_type_error: 'Este campo es invalido.',
      })
      .trim()
      .toUpperCase()
      .refine(
        value => (value !== '' ? value.length >= 4 : true),
        'Mínimo 4 caracteres.'
      )
  ),
});

export default AmountSchema;
