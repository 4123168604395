import { Space, Table } from '../../ds';
import Fuse from 'fuse.js';
import React, { useState } from 'react';
import { SearchBar } from '../DS/SearchBar';
import { TdResponsive, TrCollapsable } from '../ResponsiveTable';
import faqContent from './faq-content.json';
import './faqContainer.scss';

const FaqContainer = () => {
  const [searchState, setSearchState] = useState({
    resultContent: faqContent,
    searchTerm: '',
  });
  const [expandedItem, setExpandedItem] = useState('');
  const options = {
    keys: ['question', 'answer'],
  };
  const fuse = new Fuse(faqContent, options);

  return (
    <Space className='k-ds-width-full faq-content' direction='vertical'>
      <Space direction='horizontal'>
        <SearchBar
          placeholder='Buscar...'
          className='search-bar'
          search={searchState.searchTerm}
          setSearch={updateSearchTerm}
          onSearch={handleSearch}
          clearSearch={clearSearch}
        />
      </Space>
      <div></div>
      <Space className='faq-container' direction='vertical'>
        <Table
          type='b2b'
          className='k-ds-width-full k-ds-m-top-sp-07 faq-table'
        >
          <tbody>
            {searchState.resultContent.map((faq, index) => {
              const isExpanded = expandedItem === index;
              return (
                <React.Fragment key={index}>
                  <tr
                    aria-expanded={isExpanded}
                    className={isExpanded ? 'expanded-tr' : 'not-expanded-tr'}
                  >
                    <TdResponsive
                      className='container-td'
                      onClick={() => setExpandedItem(isExpanded ? '' : index)}
                    >
                      <div key={index} className='faq'>
                        <div className='k-ds-heading-02'>{faq.question}</div>
                      </div>
                    </TdResponsive>
                    <TdResponsive
                      className='chevron-td'
                      type='toggle'
                      isCollapsed={!isExpanded}
                      onClick={() => setExpandedItem(isExpanded ? '' : index)}
                      devices='desktop tablet mobile'
                    ></TdResponsive>
                  </tr>
                  <TrCollapsable
                    className=''
                    isCollapsed={!isExpanded}
                    devices='desktop tablet mobile'
                    onClick={() => setExpandedItem(isExpanded ? '' : index)}
                  >
                    <td className='answer-td' colSpan='2' width='100%'>
                      <div className='k-ds-body-02'>{faq.answer}</div>
                      {faq?.subAnswer && (
                        <div className='k-ds-body-02'>{faq.subAnswer}</div>
                      )}
                    </td>
                  </TrCollapsable>
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
      </Space>
    </Space>
  );

  function updateSearchTerm(searchTerm) {
    setSearchState({
      ...searchState,
      searchTerm: searchTerm,
    });
  }

  function handleSearch() {
    const value = searchState.searchTerm;
    if (value) {
      const result = fuse.search(value);
      setSearchState({
        ...searchState,
        resultContent: result.map(item => item.item),
      });
    } else {
      setSearchState({
        ...searchState,
        resultContent: faqContent,
      });
    }
  }

  function clearSearch() {
    setSearchState({
      searchTerm: '',
      resultContent: faqContent,
    });
  }
};

export default FaqContainer;
