import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Space } from '../../ds';
import { useStore } from '../../services/store';
import { ReactComponent as RightArrow } from '../AddUserSelectionModal/right-arrow.svg';
import { ReactComponent as Loader } from './loader-gray.svg';
import styles from './paymentAwait.module.scss';

const PaymentAwait = ({ orderPath }) => {
  const {
    payOrderStore: { redirectSeconds, startTimer, clearTimer },
  } = useStore();
  const { push } = useHistory();

  useEffect(() => {
    startTimer();
  }, []);

  useEffect(() => {
    if (redirectSeconds < 0) {
      clearTimer();
      goToOrderDetail();
    }
  }, [redirectSeconds]);

  const goToOrderDetail = () => {
    push(orderPath ? orderPath : '/orders');
  };

  return (
    <Space className={styles.container} direction='vertical' align='center'>
      <Loader />
      <div className='k-ds-heading-05 text-center'>
        Espera a que el cliente confirme la compra en su aplicación de Kueski
      </div>
      <div className='k-ds-heading-04 text-center'>
        Serás redirigido al Detalle de cobro del cliente donde podrás ver el
        estatus de la venta.
      </div>
      <Space
        className='k-ds-width-full'
        direction='horizontal'
        justify='space-between'
        align='center'
      >
        <div className='k-ds-body-02 k-ds-text-color-secondary'>
          00:{redirectSeconds.toString().padStart(2, '0')} segundos
        </div>
        <div>
          {orderPath && (
            <Button
              className='k-ds-width-full'
              type='link'
              size='large'
              onClick={goToOrderDetail}
            >
              Ir a detalle de cobro <RightArrow className='k-ds-m-left-sp-03' />
            </Button>
          )}
        </div>
      </Space>
    </Space>
  );
};

PaymentAwait.propTypes = {
  orderPath: PropTypes.string,
};

export default observer(PaymentAwait);
