import { whatsNewModalStateItemKey } from '../constants';
import AuthService from '../../services/AuthService';
import { initTutorialStore } from './../../components/Tutorial/storage';
import { PERSIST_STORE_KEYS } from '../constants';

const Auth = {
  async login(loginData) {
    return await AuthService().logIn(loginData);
  },
  async passwordRecover(recoverData) {
    return await AuthService().passwordRecover(recoverData);
  },
  userLoggedIn() {
    // TODO: Decode and verify JWT
    const { token, employee, stores, merchant } = this.userData();
    return !!(token && employee && stores && merchant);
  },
  saveAuthInfo(data) {
    localStorage.setItem('token', JSON.stringify(data['token']));
    localStorage.setItem('employee', JSON.stringify(data['employee']));
    localStorage.setItem('stores', JSON.stringify(data['stores']));
    localStorage.setItem('merchant', JSON.stringify(data['merchant']));
    localStorage.setItem(
      'permission_scopes',
      JSON.stringify(data['permission_scopes'])
    );
    localStorage.setItem(
      'merchant_structure',
      JSON.stringify(data['merchant_structure'])
    );
    initTutorialStore({ modules: ['merchantPaymentReport'] });
  },
  userData() {
    try {
      return {
        token: JSON.parse(localStorage.getItem('token')),
        employee: JSON.parse(localStorage.getItem('employee')),
        stores: JSON.parse(localStorage.getItem('stores')),
        merchant: JSON.parse(localStorage.getItem('merchant')),
        merchantSelected: JSON.parse(localStorage.getItem('merchantSelected')),
        permission_scopes: JSON.parse(
          localStorage.getItem('permission_scopes')
        ),
        merchant_structure: JSON.parse(
          localStorage.getItem('merchant_structure')
        ),
        tutorial: JSON.parse(localStorage.getItem('tutorial')),
      };
    } catch (_) {
      return {};
    }
  },
  logout() {
    return new Promise((resolve, reject) => {
      try {
        localStorage.removeItem('token');
        localStorage.removeItem('employee');
        localStorage.removeItem('stores');
        localStorage.removeItem('merchant');
        localStorage.removeItem('merchantSelected');
        localStorage.removeItem('permission_scopes');
        localStorage.removeItem('merchant_structure');
        localStorage.removeItem(whatsNewModalStateItemKey);

        Object.values(PERSIST_STORE_KEYS).forEach(async key => {
          await localStorage.removeItem(key);
        });

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  },
};

export default Auth;
