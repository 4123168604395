import { makeAutoObservable } from 'mobx';

export class MutateUserStore {
  stores = [];
  constructor() {
    makeAutoObservable(this);
  }

  setStores = stores => {
    this.stores = stores;
  };

  get filteredStores() {
    return this.stores
      .slice()
      .sort((a, b) => (a.name > b.name) - (a.name < b.name))
      .map(store => {
        return {
          value: store.store_id,
          text: store.name,
          id: store.store_id,
        };
      });
  }
}
