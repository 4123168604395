import { Button, Space } from '../../ds';
import PropTypes from 'prop-types';
import React from 'react';
import RadioButton from '../RadioButton';
import styles from './MerchantModal.module.scss';

const MerchantListing = ({ merchantSelected, handleChange, data = [] }) => {
  if (data.length === 0) return null;
  return (
    <Space direction='vertical' className={styles['option-cards']}>
      {data.map((item, index) => {
        return (
          <Button
            type='link'
            key={item.merchant_id + '-' + index}
            className={styles['custom-card']}
            onClick={() => handleChange(item.merchant_id)}
          >
            <div className={styles.radioContainer}>
              <RadioButton
                size={20}
                name={item.merchant_id}
                value={item.merchant_id}
                checked={merchantSelected === item.merchant_id}
                onChange={event => handleChange(event.target.value)}
              />
              <div className={styles['merchant-name']}>{item.name}</div>
            </div>

            <div className={styles.identifier}>
              <span>Id:</span> {item.merchant_id}
            </div>
          </Button>
        );
      })}
    </Space>
  );
};

MerchantListing.propTypes = {
  merchantSelected: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
};

export default MerchantListing;
