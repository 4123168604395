import styled, { css } from 'styled-components';
import React from 'react';
import KDS from '../../lib/k-ds-foundation';

/* eslint-disable */
const StyledTableMobileB2B = styled.table`
  display: block;

  border-collapse: collapse;

  /* k-ds-label-01 */
  ${KDS.setTypographyWithFallback({
    'font-family': 'font-secondary',
    'font-weight': 'font-medium',
    'font-size': 'text-xs',
    'letter-spacing': 'tracking-wide',
    'line-height': 'relaxed',
  })}
  ${KDS.setColorTokenWithFallback('color: ', 'text-primary')};

  > thead, tbody, th, td, tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  & > thead > tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  & > tbody > tr {
    &:not(:last-child) {
      ${KDS.setColorTokenWithFallback('border-bottom: 0.5px solid ', 'interactive-alternate')};
    }

    ${(props) => props.paddingSize && css`
      &:not(:first-child) {
        ${KDS.setSpacingWithFallback('padding-top: ', props.paddingSize)};
      }
      &:not(:last-child) {
        ${KDS.setSpacingWithFallback('padding-bottom: ', props.paddingSize)};
      }
    `}

    & > td {
      ${(props) => props.paddingSize && css`
        ${KDS.setSpacingWithFallback('padding: ', props.paddingSize, 'spacing-03', props.paddingSize, 'spacing-03')};
      `}
    }

    & > td {
      min-height: 1rem;
    }

    & > td[data-title] {
       /* Behave  like a "row" */
      position: relative;

      border: none;
      padding-left: 52.5%;

      text-align:left;

      &:before {
        /* Label the data */
        content: attr(data-title);

        /* Now like a table header */
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;

        width: 44%;
        ${(props) => props.paddingSize && css`
          ${KDS.setSpacingWithFallback('padding: ', props.paddingSize, 'spacing-03', props.paddingSize, 'spacing-03')};
        `}

        ${KDS.setColorTokenWithFallback('background: ', 'background-primary')};

        ${KDS.setTypographyWithFallback({
          'font-family': 'font-primary',
          'font-weight': 'font-bold',
          'font-size': 'text-xs',
        })}
        text-align: left;
      }

      & > a {
        ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary')};
        text-decoration: none;
      }
    }
  }
`;
/* eslint-enable */

const TableMobileB2B = ({ className, children, ...moreProps }) => (
  <StyledTableMobileB2B className={className} {...moreProps}>
    { children }
  </StyledTableMobileB2B>
);

export {
  TableMobileB2B,
};
