/* eslint-disable no-undef */
import styled, { css } from 'styled-components';
import KDS from './../../ds/lib/k-ds-foundation';
import { Select } from './../DS/Select';

export const StyledTableContainer = styled.div`
  overflow-x: auto;
`;

export const StyledHeaderResize = styled.div`
  position: 'absolute';
  right: 0;
  top: 0;
  height: '100%';
  width: '5px';
  background: 'rgba(0, 0, 0, 0.5)';
  cursor: 'col-resize';
  user-select: 'none';
  touch-action: 'none';
  opacity: ${props => (props.$isResizing ? 1 : 0)};
  &:hover: {
    opacity: 1;
  }
`;

export const StyledTable = styled.table`
  border-collapse: separate;
  border-spacing: 0;

  /* k-ds-label-01 */
  ${KDS.setTypographyWithFallback({
    'font-family': 'font-secondary',
    'font-weight': 'font-medium',
    'font-size': 'text-xs',
    'letter-spacing': 'tracking-wide',
    'line-height': 'relaxed',
  })}

  ${KDS.setColorTokenWithFallback('color: ', 'text-primary')};
  text-align: left;
  vertical-align: middle;
`;

export const StyledThead = styled.thead`
  ${KDS.setColorTokenWithFallback('background: ', 'background-primary')};

  & > tr {
    ${KDS.setColorTokenWithFallback('background: ', 'background-primary')};

    & > th {
      vertical-align: baseline;
      ${KDS.setTypographyWithFallback({
        'font-family': 'font-primary',
        'font-weight': 'font-bold',
        'font-size': 'text-sm',
        'line-height': 'snug',
      })}

      &:not(:first-child) {
        ${KDS.setSpacingWithFallback('padding: ', 'spacing-03', 'spacing-03')};
      }

      &:first-child {
        background: inherit;
        ${KDS.setSpacingWithFallback(
          'padding: ',
          'spacing-03',
          'spacing-03',
          'spacing-03',
          'spacing-03'
        )};
      }

      &:last-child {
        ${KDS.setSpacingWithFallback(
          'padding: ',
          'spacing-03',
          'spacing-07',
          'spacing-03',
          'spacing-05'
        )};
      }
    }

    .sticky {
      background: inherit;
      position: sticky;
      left: 0;
      z-index: 1;
      border-right: 1px solid #b4baca;
    }
  }
`;

export const StyledTR = styled.tr`
  background: white;

  & > td:first-child {
    background: inherit;
  }

  .sticky {
    background: inherit;
    position: sticky;
    left: 0;
    z-index: 1;
    border-right: 1px solid #b4baca;
  }

  &:hover {
    background: #ebeef7;
  }

  & > td {
    border-bottom: 1px solid #b4baca;
    ${props =>
      props.paddingSize &&
      css`
        ${KDS.setSpacingWithFallback(
          'padding: ',
          props.paddingSize,
          props.paddingSize,
          props.paddingSize,
          props.paddingSize
        )};
      `}

    & > a {
      ${KDS.setColorTokenWithFallback('color: ', 'interactive-primary')};
      text-decoration: none;
    }
  }
`;
export const StyledNoResults = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 0;
`;
export const StyledPagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  div[class*='paginate-arrows'] {
    gap: 16px !important;
  }

  button[class*='page-link'] {
    &:hover {
      background-color: transparent;
      border: 1px solid #282c38;
      border-radius: 25px;
      color: #282c38;
    }
  }
`;

export const StyledSelect = styled(Select)`
  margin-right: 8px;

  div[class*='selector'] {
    padding: 6px !important;
  }

  svg[class*='arrow-icon'] {
    margin-left: 4px;
  }
`;
